import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from 'src/app/core/authentication/account.service';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { getSubdomain } from 'tldts';


@Component({
  selector: 'app-loginmenu',
  templateUrl: './loginmenu.component.html',
  styleUrls: ['./loginmenu.component.scss']
})
export class LoginmenuComponent implements OnInit {
  @Output() loginSuccess = new EventEmitter<any>()
    public loginDetails:any={};
    accessToken: string;
    userInfo: any = {};
  
    userService: UserServiceModal;
    public ViewSection = [];
    public SignupSetting = [];
    public guestUser = {};
    public issignin = true;
    public isguest = true;
    public showLoginSection: boolean = false;
    autoLogin: boolean = false;
    previousType: any;
  
    constructor(
      private router: Router,
      public accountService: AccountService,
      public user: UserService,
      private route: ActivatedRoute,
      public notificationService: NotificationService,
      public translate: TranslateService,
      public apiService: RestApiService,
      private cookieService: CookieService,
      public utilityService: UtilityService,
      public itemsService: ProductService,
      
      ) {
        this.userService = user.userServiceModal;
        // const cookieAcceptedStatus = this.cookieService.get('cookieAccepted') || "not-accepted";
        // this.cookieService.deleteAll('/');
        // if(cookieAcceptedStatus != "not-accepted") {
        //   this.cookieService.set("cookieAccepted", "true", undefined, "/")
        // }
      }
  
    ngOnInit() {
    }
  
    redirectToForgotPassword() {
      this.router.navigateByUrl('/ForgotPassword', { skipLocationChange: false });
  
    }
  

    loginFunction() {

      const loginrequest = {
        "LoginName": this.userInfo.LoginName,
        "Password": this.userInfo.Password,
        "RealTime": true,
      };

      this.apiService.Login(loginrequest).subscribe(
        {
          next: (result) => {
            if (result && result.status == 200 && result.body.Status != 1) {
              if (result.body && result.body.Data) {
                if (result.body.Data.RedirectUrl && result.body.Data?.Identity?.CustomerTypeID == 3) {
                  this.manageDistributorCustomerLogin(result);
                  return;
                } else if (result.body.Data.RedirectUrl) {
                  window.location.href = result.body.Data.RedirectUrl
                } else {
                  this.manageRetailCustomerLogin(result);
                }
              } else {
                this.notificationService.error('Error', 'Authentication Failed');
              }
            } else {
              this.notificationService.error('Error', 'Authentication Failed');
            }
          }, error: err => {
            this.notificationService.error('Error', 'Oops Something Went Wrong');
          },
          complete:() => {
            // Do stuff after completion
          }
      });

    }

    checkitem() {
      return (this.itemsService.selectedOrderItems.length > 0 || this.itemsService.selectedAutoOrderItems.length > 0);
    }
  
    private loginredirect(alias) {
      let newUrl: any;
      //const subdomain = getSubdomain(window.location.host);
      newUrl = `${location.origin}/${alias}/products`;
      // if (subdomain) {
      //   newUrl = window.location.href.replace(subdomain, this.userService.WebAlias);
      // }
      if(this.checkitem()){
        this.userService.checkItems = true;
        window.location.assign(newUrl.replace('login', 'review'));
      }
      else if (this.itemsService.selectedAutoOrderItems.length) {
        //this.userService.checkItems = true;
        window.location.assign(newUrl.replace('login', 'products'));
      } else {
        window.location.assign(newUrl.replace('login', 'products'));
      }
    }

    manageRetailCustomerLogin(result) {
      this.user.clearCustomerData();
      localStorage.setItem('isLoggedIn', 'true');
        this.loginSuccess.emit(true);
        let url = result.body.Data.RedirectUrl;
        this.loginDetails = result.body;

        const expiration = new Date();
        expiration.setMinutes(expiration.getMinutes() + 30);
        this.cookieService.set('X-Auth',(this.loginDetails.Data.access_token || ''), expiration, '/' );
        this.cookieService.set('authenticationExpirationDate', (expiration.toString()), expiration, '/');


        let PrefferedLanguage = null;

        if(result.body.Data?.Identity?.Language?.LanguageID) {
          PrefferedLanguage = result.body.Data?.Identity?.Language;
        }

        this.accountService.getCustomerData(PrefferedLanguage).then((data: any) => {
          if (data && data.Data) {
            localStorage.setItem('user', JSON.stringify(data.Data));
            this.userService.customerData = data.Data;
            this.userService.customerData.RedirectURL = url;
            this.userService.customerTypeID = this.userService.customerData.CustomerType;
            // if (this.previousType !== this.userService.customerTypeID) {
            //   this.userService.couponInfo.Allcoupons = [];
            //   this.autoshipConfigurationService.autoshipDate = '';
            //   this.userService.shipMethods = [];
            //   this.userService.selectedShippingMethod = 0;
            //   // this.userService.defaultShipMethodId='';
            //   this.autoshipConfigurationService.init();
            // }
            if (!this.utilityService.isEmptyObject(this.userService.customerData.DefaultShippingAddress)) {
              this.user.setShippingAddress();
            } else {
              this.userService.shippingAddress = new UserServiceModal().newshippingAddress;
            }

            /**
             */

            const sponsorID = this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID || 0;
            if(sponsorID > 0) {
              this.apiService.getSearchCustomerDetail(sponsorID)
              .subscribe((result) => {
                let { Customer, WebAlias } = result && result.Data;
                let EnrollerInfo = Customer, EnrollerWebAlias = WebAlias;
                
                // set enroller info
                if(EnrollerInfo) {
                  this.userService.enrollerInfo = Customer;
                }

                // continue webalias set process
                if (this.userService.customerData.WebAlias) {
                  this.apiService.validateWebAlias(this.userService.customerData.WebAlias).subscribe({
                    next: (res: any) => {
                    if (res.Data) {
                      this.userService.WebAlias = this.userService.customerData.WebAlias;
                      this.loginredirect(this.userService.WebAlias);
                    }
                  },error: (error) => {
                    if (error.Data && !error.Data.WebAlias) {
                      this.notificationService.error('Error', 'InvalidWebAlias');
                      return false;
                    }
                  },
                  complete:() => {
                    // Do stuff after completion
                    }
                  });
                } else {
                  if (EnrollerWebAlias) {
                    this.apiService.validateWebAlias(EnrollerWebAlias.WebAlias).subscribe({
                      next: (res: any) => {
                        if (res.Data) {
                          this.userService.WebAlias = EnrollerWebAlias.WebAlias;
                          // checkWebAliasService.checkWebAlias();
                          this.loginredirect(this.userService.WebAlias);
                        }
                      }, error:(error) => {
                        if (error.Data && !error.Data) {
                          this.notificationService.error('Error', 'InvalidWebAlias');
                          return false;
                        }
                      },
                      complete:() => {
                        // Do stuff after completion
                      }
                    });
                  }
                }
              })
            }


            /**
             */            

            // if (this.userService.customerData.WebAlias) {
            //     this.apiService.validateWebAlias(this.userService.customerData.WebAlias).subscribe({
            //       next: (res: any) => {
            //     if (res.Data) {
            //       this.userService.WebAlias = this.userService.customerData.WebAlias;
            //       this.loginredirect(this.userService.WebAlias);
            //     }
            //   },error: (error) => {
            //     if (error.Data && !error.Data.WebAlias) {
            //       this.notificationService.error('Error', 'InvalidWebAlias');
            //       return false;
            //     }
            //   },
            //     complete:() => {
            //       // Do stuff after completion
            //       }
            //     });
            // }
            // else if (this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID) {
            //     this.apiService.getSearchCustomerDetail(this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID).subscribe({
            //       next: (resp: any) => {
            //     if (resp.Data) {
            //           this.apiService.validateWebAlias(resp.Data.WebAlias.WebAlias).subscribe({
            //             next: (res: any) => {
            //         if (res.Data) {
            //           this.userService.WebAlias = resp.Data.WebAlias.WebAlias;
            //           // checkWebAliasService.checkWebAlias();
            //           this.loginredirect(this.userService.WebAlias);
            //         }
            //       }, error:(error) => {
            //         if (error.Data && !error.Data) {
            //           this.notificationService.error('Error', 'InvalidWebAlias');
            //           return false;
            //         }
            //       },
            //         complete:() => {
            //           // Do stuff after completion
            //             }
            //           });
            //     }
            //   }, error:(error) => {
            //     if (error.Data && !error.Data.WebAlias) {
            //       this.notificationService.error('Error', 'InvalidWebAlias');
            //       return false;
            //     }
            //   },
            //     complete:() => {
            //       // Do stuff after completion
            //       }
            //     });
            // }
          } else {
            this.notificationService.error('Error', 'OopsSomethingWentWrong');
          }
        }).catch((error) => {
          this.notificationService.error('Error', 'OopsSomethingWentWrong');
          console.error('ex', error);
        });
    }

    manageDistributorCustomerLogin(result) {
      window.location = result.body.Data.RedirectUrl;
    }
  }
  

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {
  @Input() htmlText: string='Testing Test';
  @Input() image: string='';
  @Input() customername: string='';
  // this property value is bound to a different property name
  // when this component is instantiated in a template.
  @Input() color:string='#620000';
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserServiceModal } from "src/app/core/modals/userservice.modal";
import { OrderService } from "src/app/core/services/order.service";
import { ProductService } from "src/app/core/services/product.service";
import { UserService } from "src/app/core/services/user.service";
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-rain-cash-wallet-dialog',
    templateUrl: './rain-cash-wallet-dialog.component.html',
    styleUrls: ['./rain-cash-wallet-dialog.component.scss']
  })
  export class RainCashWalletDialogComponent implements OnInit {
    
    public userService: UserServiceModal;
    public choosedProducts: Array<any> = [];
    public env:any;
    
    constructor(
      public dialogRef: MatDialogRef<RainCashWalletDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any, 
      private _productService: ProductService,
      private user: UserService,
      public orderService: OrderService
    ){
      this.userService = user.userServiceModal;
      this.env = environment;
    } 
    
    ngOnInit() {
      this.orderService.pointsPaymentCheckout.allItemsEligibleForRainCashAmount = this.orderService?.pointsPaymentCheckout.allItemsEligibleForRainCashAmount.length > 0 ? this.orderService?.pointsPaymentCheckout.allItemsEligibleForRainCashAmount : this._splitAllItemsByQuantity(this._productService.selectedOrderItems);
      this.choosedProducts = this.orderService?.pointsPaymentCheckout.finallyRainCashItems.length > 0 ? this.orderService?.pointsPaymentCheckout.finallyRainCashItems : this.orderService?.pointsPaymentCheckout?.allItemsEligibleForRainCashAmount.filter(item => item['isUsingRainCash'] == true);
      this.cashPointsCheckout.updatePoints();
    }

    _splitAllItemsByQuantity(itemsArray: Array<any>) {
      const spilittedItemsArray = [];
      let counter = 1;
      itemsArray.map((item) => {
        for (let index = 0; index < item.Quantity; index++) {
          item['PointItemID'] = counter;
          spilittedItemsArray.push({...item, Price: (item.Price / item.Quantity)});
          counter++;
        }
      });
      return spilittedItemsArray;
    }

    /*
        1 remainder = 0
        2 product price > remainder ( when checkbox is unchecked )
        3 
    */

    public cashPointsCheckout = {
      cashAmount: 0.00,
      shouldBeDisabled2: (productPrice:any, pointItemID:any) => {

        const selectedItem = this.choosedProducts.find(item => (item?.isUsingRainCash == true && item?.PointItemID == pointItemID));

        if(this.orderService?.pointsPaymentCheckout?.remainderAmount <= 0 && !selectedItem?.isUsingRainCash){
          this.cashPointsCheckout.updatePoints();
          return true;
        }
        
        if((productPrice > this.orderService?.pointsPaymentCheckout?.remainderAmount) && !selectedItem?.isUsingRainCash){
          this.cashPointsCheckout.updatePoints();
          return true;
        }

        if((this.orderService?.pointsPaymentCheckout?.remainderAmount - this.cashPointsCheckout.cashAmount) >= 0) {
          if((productPrice > (this.orderService?.pointsPaymentCheckout?.remainderAmount - this.cashPointsCheckout.cashAmount)) && !selectedItem?.isUsingRainCash) {
            this.cashPointsCheckout.updatePoints();
            return true;
          }
        }

        // If item is selected, then return ( because it's already using reain cash )
        
        if(selectedItem?.isUsingRainCash == true){
          console.log(`item no. ${pointItemID} is using Rain cash`)
          this.cashPointsCheckout.updatePoints();
          return false;
        }

        else if (!selectedItem?.isUsingRainCash) {

          console.log(`item no. ${pointItemID} is not using Rain cash`);

          /** -----------------------------------------------------------------------------------------------------
            Disable checkboxes if Final Remainder is already 0 [OR] productPrice is more than Final Remainder
          ----------------------------------------------------------------------------------------------------- */

          /** ---------------------------------        END         ------------------------------------------- */

          /** -----------------------------------------------------------------------------------------------------
            Disable checkboxes if Final Remainder is already 0 [OR] productPrice is more than Final Remainder
          
            Think of this case:-
            
            Remainder = $93;
            1 Product price = $90;
            2 Product price = $90;
            and, one item is selected, so could be used is = $90

            Remaining = $93 - $90 = $3,

            So, disable checkbox as in $3 balance, second $90 item can't be used.
          
            ----------------------------------------------------------------------------------------------------- */

          /** ---------------------------------        END         ------------------------------------------- */

          const modalRemainingAmount = (this.userService?.customerData?.UserCashAccount?.Balance - this.cashPointsCheckout.cashAmount);

          this.cashPointsCheckout.updatePoints();
          return parseFloat(productPrice) > modalRemainingAmount;
        }

      },
      updatePoints: () => {

        this.cashPointsCheckout.cashAmount = 0.00;
        this.choosedProducts.map((item:any) => {
          this.cashPointsCheckout.cashAmount += item.Price;
        });
        
        let cashAmount =  (this.cashPointsCheckout.cashAmount && !isNaN(this.cashPointsCheckout.cashAmount) && parseFloat(this.cashPointsCheckout.cashAmount.toString())) || 0.00;
        const cashBalance = this.userService.customerData?.UserCashAccount?.Balance
        const cartSubtotal =  this.orderService.calculateOrderResponse.SubTotal;

        // IF exceeds Subtotal
        if(cashAmount >= cartSubtotal && cartSubtotal <= cashBalance){
          cashAmount = cartSubtotal
        } else if (cashAmount >= cartSubtotal && cartSubtotal > cashBalance){
          cashAmount = cashBalance;
        }

        // IF exceeds Available
        if(cashAmount >= cashBalance && cartSubtotal <= cashBalance){
          cashAmount = cartSubtotal
        } else if (cashAmount >= cashBalance && cartSubtotal > cashBalance){
          cashAmount = cashBalance;
        }

        this.cashPointsCheckout.cashAmount = cashAmount;
        this.orderService.pointsPaymentCheckout.calculateRemainder();

      },
      accept: () => {
        this.orderService.pointsPaymentCheckout.cashAmountInput = this.cashPointsCheckout.cashAmount;

        // Assigning Final Rain Cash Selected Items
        this.orderService.pointsPaymentCheckout.finallyRainCashItems = this.choosedProducts; 

        // Disabling Credit Account Selection, if Rain Cash is selected
        // And, changing, Cash account status to true.
        if(this.orderService.pointsPaymentCheckout.cashAmountInput > 0){
          this.orderService.pointsPaymentCheckout.selectedAccounts.forEach(acc => {
            if(acc.selectionType == "credit") {
              acc.selectionStatus = false;
            } else if(acc.selectionType == "cash-account") {
              acc.selectionStatus = true;
            }
          });
        } else if(this.orderService.pointsPaymentCheckout.cashAmountInput <= 0){
          this.orderService.pointsPaymentCheckout.selectedAccounts.forEach(acc => (acc.selectionType == "cash-account") ? acc.selectionStatus = false : "");
        }

        this.orderService.pointsPaymentCheckout.calculateRemainder();
        this.dialogRef.close(this.cashPointsCheckout.cashAmount);

      }
    }

    handleProductSelection(pointItemID:number|string, product:any, $event:any) {
      this.orderService.pointsPaymentCheckout.calculateRemainder();
      
      let isCheckboxChecked: boolean = $event.checked;
      let isItemExist = this.choosedProducts.find(item => item?.PointItemID == pointItemID);
      
      if(isCheckboxChecked) { // Selecting Items
        if(!isItemExist){

          // Adding a key, to check if item is using rain cash:?
          this.orderService?.pointsPaymentCheckout?.allItemsEligibleForRainCashAmount.forEach(item => {
            if(item?.PointItemID == pointItemID) {
              item['isUsingRainCash'] = true;
              this.choosedProducts.push(item);
            }
          });
        }
      } 
      
      else { // De-selecting Items
        if(isItemExist){
          
          // Removing "isUsingRainCash" key, when removed:?
          this.orderService?.pointsPaymentCheckout?.allItemsEligibleForRainCashAmount.forEach(item => {
            if(item?.PointItemID == pointItemID) {
              delete item?.isUsingRainCash;
              // const { isUsingRainCash, ...otherDetails } = item;
              // item = otherDetails;
              let index = this.choosedProducts.findIndex(i => i?.PointItemID == pointItemID);
              this.choosedProducts.splice(index, 1);
            }
          });

        }
      }
      
      // Updating Points
      this.cashPointsCheckout.updatePoints();
      this.orderService.pointsPaymentCheckout.calculateRemainder();

    }
    
    ngOnDestroy(){
      this.cashPointsCheckout.cashAmount = 0.00;
    }
    
  }

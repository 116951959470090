import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper'
import $ from "jquery";
import { SeoService } from 'src/app/core/services/seo.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {


  public disabled: boolean = false;
  public isLogin: any;
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false
  };
  screenWidth: number;


  constructor(
    private translate: TranslateService,
     private titleService: Title,
     private seo:SeoService,
     public router: Router
     ) { 
    this.seo.setTags({
      title: 'Home', // Title
      titleSuffix: '- Rain', // Title Suffix
      description: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.', // Description
      image: 'assets/images/logo.png', // Image
      keywords: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.' // Keywords
    });
  }
  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1007) {
      $(".hideClass").hide();

    }
    else {
      $(".hideClass").show()

    }

    this.config = {
      loop: true,
      scrollbar:false,
      navigation: true,
      pagination:  {
        el: '.swiper-pagination',
        clickable: true
      }
    };
  }
  ngOnInit(): void {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Home') + ' - ' + text);
    });
    this.isLogin = localStorage.getItem("isLoggedIn");
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1007) {
      $(".hideClass").hide();
    }
    else {
      $(".hideClass").show()
    }

  }
  goToShop() 
  {
        this.router.navigate(['/products']);
  }
  goToAbout() 
  {
        this.router.navigate(['/pages/aboutus']);
  }
  goToLearn() 
  {
        this.router.navigate(['/pages/learn']);
  }
 goToEnroll()
 {
   this.router.navigate(['/enrollment'])
 }

}

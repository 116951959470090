import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfigService } from '../../../core/services/config.service';
import { NotificationService } from '../../../core/services/notification.service';
import { RestApiService } from '../../../core/http/restapi.service';
import { UserService } from '../../../core/services/user.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { FindEnrollerByIDComponent } from '../findenroller-byid/findenroller-byid.component';
import { getBaseLocation } from 'src/app/baseurl';



@Component({
  selector: 'app-find-enroller',
  templateUrl: './findenroller.component.html',
  styleUrls: ['./findenroller.component.scss']
})
export class FindEnrollerComponent implements  OnInit{
  userService: UserServiceModal;
  searchText: string = "";
  result: string = '';
  sponsorList: any
  constructor(public configService: ConfigService, public dialog: MatDialog, public apiService: RestApiService, public translate: TranslateService, public notificationService: NotificationService, public user: UserService, public dialogRef2: MatDialogRef<FindEnrollerByIDComponent>, public dialogRef: MatDialogRef<FindEnrollerByIDComponent>) {
    this.userService = user.userServiceModal;
  }

  ngOnInit() {
  }

  confirmDialog(): void {
    const message = `Are you sure you want to do this?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
    });
  }
  closeDialog() {
    this.dialogRef.close('close icon');
  }

  searchEnroller(searchTerm: string) {
    const req = {
      "searchTerm": searchTerm
    }
    this.apiService.searchAssociate(req).subscribe({
      next: data => {
        if (data.Status == 0 && data.Data) {
          _.each(data.Data, (obj)=>{
            return obj.FullName = obj.FullName?.trim();
          })
          this.sponsorList = data.Data || [];
          this.dialogRef.close();
          this.dialogRef2 = this.dialog.open(FindEnrollerByIDComponent, {
            data: this.sponsorList
          });
          this.dialogRef2.afterClosed().subscribe(data => {
            if (data) {
              const baseurl = getBaseLocation();
              sessionStorage.setItem('enrollerSet', 'true');
              location.href = (location.href.replace(baseurl, ('/' + data.selectedSponsor.WebAlias)));
            }
          })
        }
        else {
          this.notificationService.error("error", "We are Sorry ,No Record Found")
        }
      },
      error: err => {
        // Do stuff whith your error
        this.notificationService.error('error', 'We are Sorry ,No Record Found')
      },
      complete: () => {
        // Do stuff after completion
      }
    });
  }
}

import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { SignupComponent } from './modules/signup/signup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio'
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule} from '@angular/material/stepper';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HeaderComponent } from './modules/header/header.component';
import { TopHeaderComponent } from './modules/top-header/top-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './modules/login/login.component';
import { HttpClient, HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DefaultUrlSerializer, Router, UrlSerializer, UrlTree } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotTranslatedService } from './core/services/not-ranslated.service';
import { SideBarSummaryCartComponent } from './modules/sidebar-summary-cart/sidebar-summary-cart.component';
import { SharedModule } from './shared/model/shared.module';
import { FindEnrollerComponent } from './shared/model/findenroller/findenroller.component';
import { environment } from 'src/environments/environment';
import { ConfigService } from './core/services/config.service';
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor.service';
import { PersistentService } from './core/services/persistent.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiInterceptor } from './core/interceptors/http-interceptor.service';
import { AuthGuard } from './core/guards/auth.guard.service';
import { LoggedInAuthGuard } from './core/services/loggedin.guard.service';
import { BelowSliderComponent } from './modules/below-slider/below-slider.component';
import { ProductComponent } from './modules/shop/product/product.component';
import { ProductDetailsComponent } from './modules/shop/product-details/product-details.component';
import { ProductSearchPipe } from './core/pipes/product-search.pipe';
import { ProductOrderByPipe } from './core/pipes/order-by.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductCardComponent } from './modules/shop/product-card/product-card.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { BusinessKitComponent } from './shared/business-kit/business-kit.component';
import { ShippingInfoComponent } from './modules/shipping/shipping-info/shipping-info.component';
import { BillingInfoComponent } from './modules/shipping/billing-info/billing-info.component';
import { WebsiteInfoComponent } from './modules/shipping/website-info/website-info.component';
import { ShippingComponent } from './modules/shipping/shipping.component';
import { MarketSelectorComponent } from './shared/model/market-selector/market-selector.component';
import { ConfirmDialogComponent } from './shared/model/confirm-dialog/confirm-dialog.component';
import { ProductSearchDialogComponent } from './shared/model/productsearch/productsearch.component';
import { CreateAccountComponent } from './modules/create-account/create-account.component';
import { LoginmenuComponent } from './shared/loginmenu/loginmenu.component';
import { RevieworderComponent } from './shared/revieworder/revieworder.component';
import { CartComponent } from './modules/cart/cart.component';
import { MatDividerModule} from '@angular/material/divider';
import { SummaryCartDropdownComponent } from './modules/summarycartdropdown/summarycartdropdown.component';
import { MyLoaderComponent } from './shared/components/my-loader/my-loader.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FindEnrollerByIDComponent } from './shared/model/findenroller-byid/findenroller-byid.component';
import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { getBaseLocation } from './baseurl';
import { CartSideMenuComponent } from './modules/shop/cart-side-menu/cart-side-menu.component';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { LazyImgDirective } from './core/directive/lazy-img.directive';
import { CdkStepper } from '@angular/cdk/stepper';
import { MatBadgeModule} from '@angular/material/badge';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { ContactComponent} from './modules/pages/contact/contact.component'
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule, Title } from '@angular/platform-browser';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaymentfailComponent } from './modules/paymentfail/paymentfail.component';
import { ThankyouComponent } from './modules/thank-you/thank-you.component';
import { LimitToPipe } from './core/pipes/limit-to.pipe';
import { SharedService } from './core/services/shared.service';
import { ConfirmSubscribeComponent } from './shared/model/confirm-subscribe/confirm-subscribe.component';
import { LocalConfig } from 'src/assets/data/localconfig';
import { ClientSettings } from 'src/assets/data/clientsetting';
import { ReturnPolicyComponent } from './shared/model/returnpolicy/returnpolicy.component';
import { PersonalInfoComponent } from './modules/shipping/personal-info/personal-info.component';
import { QuickViewComponent } from './shared/model/quick-view/quick-view.component';
import { CartService } from './core/services/cart.service';
import { BankDepositComponent } from './shared/model/bankdeposit/bankdeposit.component';
import { LearnPageComponent } from './modules/pages/learn-page/learn-page.component';
import { RewardDialogComponent } from './shared/model/reward-dialog/reward-dialog.component';
import { UserService } from './core/services/user.service';
import { CardDetail, PersonalInfo, ShippingAddress, WebOffice, BillingAddress, UserServiceModal } from './core/modals/userservice.modal';
import { SellerAgreementComponent } from './shared/model/seller-agreement-dialog/seller-agreement-dialog.component';
import { customeCurrencyPipe } from './core/pipes/custom-currency.pipe';
import { ContactSupportPopupComponent } from './shared/model/contact-support-popup/contact-support-popup.component';
import { UserDetailComponent } from './modules/account/user-details/user-detail.component';
import { UpgradeComponent } from './modules/account/upgrade/upgrade.component';
import { UpgradeItemComponent } from './modules/account/upgrade-item/upgrade-item.component';
import { RainCashComponent } from './modules/account/rain-cash/rain-cash.component';
import { PaymentMethodsComponent } from './modules/account/payment-methods/payment-methods.component';
import { CreditCardsNewComponent } from './modules/account/payment-methods/new-credit-cards/credit-cards-new.component';
import { OrderHistoryComponent } from './modules/account/order-history/order-history.component';
import { ManageAutoOrderComponent } from './modules/account/manage-auto-order/manage-auto-order.component';
import { AddressesComponent } from './modules/account/addresses/addresses.component';
import { AutoOrderComponent } from './modules/account/auto-order-list/auto-order.component';
import { FilterPipe } from './core/pipes/filter.pipe';
import { NewAddressesComponent } from './modules/account/addresses/newAddress/new-address-component';
import { RainCashWalletDialogComponent } from './shared/model/rain-order-dialog/rain-cash-wallet-dialog.component';
declare global {
  interface Window {
    local_ID: string;
    selectedCountry: string;
  }
}
var local_ID = (sessionStorage.getItem('local_ID') || 'en');

import(
  /* webpackExclude: /\.d\.ts$/ */
  `../../node_modules/@angular/common/locales/${local_ID || window.local_ID}`
).then(
  module => registerLocaleData(module.default)
);

let userService: UserServiceModal;

const initializeConfig = (configService: ConfigService, cartsService: CartService, http: HttpClient, dailog: MatDialog, user: UserService) => {
  const promise2 = new Promise((resolve, reject) => {
    let subdomain = getBaseLocation();

    if (!subdomain) {
      subdomain = 'rainShop';
    }else{
      subdomain=subdomain.replace('/','');
    }
    configService.localSettings =LocalConfig;
    const showMarketSelector = () => {
      dailog.open(MarketSelectorComponent, {
        data: {},
        disableClose: true
      });
    }
    http.get(`${environment.apiUrl}api/Public/GetCustomerSite?webAlias=${subdomain}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    }).toPromise()
      .then((result: any) => {
        if(window.localStorage || window.sessionStorage){
          if (!sessionStorage.getItem('checkRefresh')) {
            console.log('clear');
            let sessionId;
            let exigosessionId;
            if(window.localStorage){
              sessionId = localStorage.getItem('sessionId') == 'null' ? '' : localStorage.getItem('sessionId');              
            }
            if(window.sessionStorage){
              exigosessionId = sessionStorage.getItem('exigosessionId') == 'null' ? '' : sessionStorage.getItem('exigosessionId');
            }
            localStorage.clear();
            cartsService.clearCart();
            if(window.localStorage){
              localStorage.setItem('sessionId', sessionId);              
            }
            if(window.sessionStorage){
              sessionStorage.setItem('exigosessionId', exigosessionId);
            }
            userService = user.userServiceModal;
            userService.cardDetail = {} as CardDetail;
            userService.personalInfo = {} as PersonalInfo;
            userService.shippingAddress = {} as ShippingAddress;
            userService.shipMethods = [];
            userService.webOffice = {} as WebOffice;
            userService.personalInfo = {} as PersonalInfo;
            userService.billingAddress = {} as BillingAddress;
            sessionStorage.setItem('checkRefresh', 'true');

            if(!sessionStorage.getItem('initalMarketSelectorPopupOpened')){
              configService.showInitialMarketSelector.subscribe(() => {
                showMarketSelector();
                sessionStorage.setItem('initalMarketSelectorPopupOpened', 'true');
              })
            }

          }
        }
        if (result?.Data && result?.Data?.CustomerStatusID == 1) {
          configService.setWebsiteInfo(result?.Data, false);
          return resolve(result?.Data);
        } else {
          http.get(`${environment.apiUrl}api/Public/GetCustomerSite?webAlias=rainShop`, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json; charset=utf-8'
            }),
            withCredentials: true
          }).toPromise().then((result1: any) => {
            if (result1?.Data && result1?.Data?.CustomerStatusID == 1) {
              configService.setWebsiteInfo(result1.Data, true);
              return resolve(result1?.Data);
            }
            return resolve(result1?.Data);
          });
        }
      });
  });
  const promise1 = new Promise((resolve, reject) => {
          configService.init(ClientSettings).then(() => {
            return resolve(ClientSettings);
          });
  });


  return async () => {
    const res = await Promise.all([promise2, promise1]);
    return res;
  };

};

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }

  constructor(private nts: NotTranslatedService) { }
}

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

  override parse(url: string): UrlTree {
    if(url.indexOf('token')>0){
      return super.parse(url);
    }
    return super.parse(url.toLowerCase());
  }
}
@NgModule({
  declarations: [
    AddressesComponent,
    NewAddressesComponent,
    FilterPipe,
    AutoOrderComponent,
    ManageAutoOrderComponent,
    OrderHistoryComponent,
    CreditCardsNewComponent,
    PaymentMethodsComponent,
    // RainCashComponent,
    // UpgradeItemComponent,
    // UpgradeComponent,
    // UserDetailComponent,
    AppComponent,
    HeaderComponent,
    TopHeaderComponent,
    HomeComponent,
    SignupComponent,
    LoginComponent,
    SideBarSummaryCartComponent,
    FindEnrollerComponent,
    FindEnrollerByIDComponent,
    ConfirmSubscribeComponent,
    BelowSliderComponent,
    ProductComponent,
    ProductCardComponent,
    ProductDetailsComponent,
    ProductSearchPipe,
    ProductOrderByPipe,
    LazyImgDirective,
    BusinessKitComponent,
    ShippingInfoComponent,
    BillingInfoComponent,
    WebsiteInfoComponent,
    ShippingComponent,
    MarketSelectorComponent,
    ConfirmDialogComponent,
    RewardDialogComponent,
    ProductSearchDialogComponent,
    CreateAccountComponent,
    StepperComponent,
    LoginmenuComponent,
    RevieworderComponent,
    CartComponent,
    SummaryCartDropdownComponent,
    MyLoaderComponent,
    FooterComponent,
    CartSideMenuComponent,
    CheckoutComponent,
    ContactComponent,
    LearnPageComponent,
    PaymentfailComponent,
    ThankyouComponent,
    LimitToPipe,
    ReturnPolicyComponent,
    PersonalInfoComponent,
    QuickViewComponent,
    BankDepositComponent,
    SellerAgreementComponent,
    ContactSupportPopupComponent,
    RainCashWalletDialogComponent
    // customeCurrencyPipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    MatRadioModule,
    AppRoutingModule,
    NgxImageZoomModule,
    SwiperModule,
    SharedModule,
    MatExpansionModule,
    MatGridListModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule ,
    MatButtonModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    NgxPaginationModule,
    MatTabsModule,
    MatRadioModule,
    MatStepperModule,
    MatPasswordStrengthModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatChipsModule,
    MatNativeDateModule,
    MatFormFieldModule,

     TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
        deps: [NotTranslatedService]
      }
    })
  ],
  exports: [
    MatExpansionModule,
    MatGridListModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatListModule,
    ProductSearchPipe,
    ProductOrderByPipe,
    LazyImgDirective,
    MatTabsModule,
    MatRadioModule,
    MatPasswordStrengthModule,
    FormsModule,
    ReactiveFormsModule,
    MarketSelectorComponent,
    ConfirmDialogComponent,
    RewardDialogComponent,
    ProductSearchDialogComponent,
    MatChipsModule,
    LimitToPipe,
    BankDepositComponent,
    customeCurrencyPipe
  ],
  providers: [
    ConfigService,
    { provide: APP_BASE_HREF, useFactory: getBaseLocation},
    { provide: LOCALE_ID, deps: [ConfigService],  useFactory: (ConfigService) => (window.local_ID), useValue: (sessionStorage.getItem('local_ID') || window.local_ID)},
    { provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    { provide: MatDialogRef, useValue: {}}, {provide: MAT_DIALOG_DATA, useValue: {}},
  CartService,
    { provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [ConfigService, CartService, HttpClient, MatDialog, UserService], multi: true },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    Title,
    PersistentService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, cookieService: CookieService) {
        return new ApiInterceptor(router, cookieService);
      },
      multi: true,
      deps: [Router, CookieService]
    },
    AuthGuard,
    LoggedInAuthGuard,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    CdkStepper,
    SharedService
  ],
  entryComponents: [CartSideMenuComponent, ConfirmSubscribeComponent, ReturnPolicyComponent, QuickViewComponent, RainCashWalletDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // return new TranslateHttpLoader(http,'/translation/','.json');
  return new TranslateHttpLoader(http,`${environment.apiUrl}api/Languages/GetTranslation/`,'');
}


<!-- auto-order-dialog**********(Cancel button)-->
<div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch" class="confirm-dialog">
  <div *ngIf="customdata?.autoOrderID" fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end center" class="p-r-10 cursor-pointer mt-10">
    <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
  </div>
  <div fxLayout="row" class="m-0 f-s-15" fxLayoutAlign="center space-around">
    <h2 mat-dialog-title class="m-b-0">
      <strong [textContent]="title"></strong>
    </h2>
  </div>
  <div fxLayout="row" fxLayoutAlign="flex-start space-around" *ngIf="customdata?.autoOrderID">
    <p class="f-s-18 p-20 mb-18" [textContent]="('AutoshipId' | translate) + ': ' + customdata.autoOrderID"></p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center space-around">
    <p class="f-s-18 p-20"*ngIf="message"[textContent]="message"></p>
  </div>
  <div fxLayout="column" class="ms-padding">
    <div fxFlex="50" fxLayout="row" mat-dialog-actions class="button m-0 m-b-10 pay-last-button"
      fxLayoutAlign="center center">
      <button mat-raised-button (click)="onConfirm()" class="btn-black">{{takeaction}}</button>
      <button mat-raised-button (click)="onDismiss()" class="btn-white">{{noaction}}</button>
    </div>
  </div>
</div>

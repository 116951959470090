export const ClientSettings={
  "ProductsColor": [
    {
      "ItemName": "Soul",
      "ItemCode": "2020000",
      "Color1": "#ED2A8F",
      "Color2": "#F23A84",
      "Color3": "#F86163",
      "Color4": "#FD913B",
      "Color5": "#D85A96",
      "BannerImage": "/assets/images/product/soul.png"
    },
    {
      "ItemName": "Core",
      "ItemCode": "3000101",
      "Color1": "#4DB264",
      "Color2": "#88CC62",
      "Color3": "#BAE164",
      "Color4": "#ECF767",
      "Color5": "#74BB8A",
      "BannerImage": "/assets/images/product/core.png"
    },
    {
      "ItemName": "SoulRed15",
      "ItemCode": "FG2455010101",
      "Color1": "#F8234A",
      "Color2": "#F73463",
      "Color3": "#F75688",
      "Color4": "#F87CAE",
      "Color5": "#DF5567",
      "BannerImage": "/assets/images/product/soulred.png"
    },
    {
      "ItemName": "PureBiotic",
      "ItemCode": "PURE-GroupMaster",
      "Color1": "#72CBC9",
      "Color2": "#65C8C9",
      "Color3": "#338EB4",
      "Color4": "#332F7D",
      "Color5": "#86C8C8",
      "BannerImage": "/assets/images/product/pure.png"
    },
    {
      "ItemName": "Bend",
      "ItemCode": "FG1393000101",
      "Color1": "#F89D5E",
      "Color2": "#F9A461",
      "Color3": "#FDBC69",
      "Color4": "#FBDD78",
      "Color5": "#F0AE70",
      "BannerImage": "/assets/images/product/bend.png"
    },
    {
      "ItemName": "XSeeds",
      "ItemCode": "FG9954010101",
      "Color1": "#3A5CAA",
      "Color2": "#5F4DA0",
      "Color3": "#7D439A",
      "Color4": "#A23688",
      "Color5": "#4659A1",
      "BannerImage": "/assets/images/product/xseeds.png"
    },
    {
      "ItemName": "Renu",
      "ItemCode": "FG9354010101",
      "Color1": "#2BA94F",
      "Color2": "#1EB085",
      "Color3": "#20B6A9",
      "Color4": "#18BDD6",
      "Color5": "#23B59F",
      "BannerImage": "/assets/images/product/renu.png"
    },
    {
      "ItemName": "form",
      "ItemCode": "FORM-GroupMaster",
      "Color1": "#322F7E",
      "Color2": "#313088",
      "Color3": "#344A9F",
      "Color4": "#3758A7",
      "Color5": "#38509C",
      "BannerImage": "/assets/images/product/form.png"
    },
    {
      "ItemName": "Viv3",
      "ItemCode": "FG9962010101",
      "Color1": "#F2CE80",
      "Color2": "#D7B184",
      "Color3": "#AF8395",
      "Color4": "#8452A1",
      "Color5": "#87619F",
      "BannerImage": "/assets/images/product/viv3.png"
    },
    {
      "ItemName": "Bryt",
      "ItemCode": "FG6255010101",
      "Color1": "#FFCD44",
      "Color2": "#FBAB3B",
      "Color3": "#F68636",
      "Color4": "#F15837",
      "Color5": "#E47444",
      "BannerImage": "/assets/images/product/bryt.png"
    },
    {
      "ItemName": "BalanceKit",
      "ItemCode": "NA-AO-BAL",
      "Color1": "#BADA8B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#C0D893",
      "SubscriptionItemCode":"",
      "BannerImage": "/assets/images/product/balancepack.png"
    },
    {
      "ItemName": "FitKit",
      "ItemCode": "NA-AO-FIT",
      "Color1": "#F4931E",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E89636",
      "SubscriptionItemCode":"",
      "BannerImage": "/assets/images/product/fitpack.png"
    },
    {
      "ItemName": "FocusKit",
      "ItemCode": "RI-AO-FOC",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E24F6C",
      "SubscriptionItemCode":"",
      "BannerImage": "/assets/images/product/focuspack.png"
    },
    {
      "ItemName": "Super Elite",
      "ItemCode": "NA-ESEP-21",
      "Color1": "#ED2A8F",
      "Color2": "#F23A84",
      "Color3": "#F86163",
      "Color4": "#FD913B",
      "Color5": "#D85A96",
      "SubscriptionItemCode":"2020000",
      "BannerImage": "/assets/images/product/SoulSubscriptionBanner.png"
    },
    {
      "ItemName": "Elite",
      "ItemCode": "NA-EELP-21",
      "Color1": "#ED2A8F",
      "Color2": "#F23A84",
      "Color3": "#F86163",
      "Color4": "#FD913B",
      "Color5": "#D85A96",
      "SubscriptionItemCode":"2020000",
      "BannerImage": "/assets/images/product/SoulSubscriptionBanner.png"
    },
    {
      "ItemName": "Pro: FIT",
      "ItemCode": "NA-PFIT-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E89636",
      "SubscriptionItemCode":"NA-AO-FIT",
      "BannerImage": "/assets/images/product/fitpack.png"
    },
    {
      "ItemName": "Pro: FOCUS",
      "ItemCode": "NA-PFOC-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E24F6C",
      "SubscriptionItemCode":"RI-AO-FOC",
      "BannerImage": "/assets/images/product/focuspack.png"
    },
    {
      "ItemName": "Pro: BALANCE",
      "ItemCode": "NA-PBAL-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#C0D893",
      "SubscriptionItemCode":"NA-AO-BAL",
      "BannerImage": "/assets/images/product/balancepack.png"
    },
    {
      "ItemName": "Builders: FIT",
      "ItemCode": "NA-BFIT-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E89636",
      "SubscriptionItemCode":"NA-AO-FIT",
      "BannerImage": "/assets/images/product/fitpack.png"
    },
    {
      "ItemName": "Builders: FOCUS",
      "ItemCode": "NA-BFOC-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#E24F6C",
      "SubscriptionItemCode":"RI-AO-FOC",
      "BannerImage": "/assets/images/product/focuspack.png"
    },
    {
      "ItemName": "Builders: BALANCE",
      "ItemCode": "NA-BBAL-21",
      "Color1": "#EF3F6B",
      "Color2": "",
      "Color3": "",
      "Color4": "",
      "Color5": "#C0D893",
      "SubscriptionItemCode":"NA-AO-BAL",
      "BannerImage": "/assets/images/product/balancepack.png"
    },
    {
      "ItemName": "BASIC",
      "ItemCode": "RI-1-EBAP",
      "Color1": "#ED2A8F",
      "Color2": "#F23A84",
      "Color3": "#F86163",
      "Color4": "#FD913B",
      "Color5": "#D85A96",
      "SubscriptionItemCode":"2020000",
      "BannerImage": "/assets/images/product/SoulSubscriptionBanner.png"
    },
    {
      "ItemName": "CONSTRUCTOR",
      "ItemCode": "PR-Enroll",
      "Color1": "#ED2A8F",
      "Color2": "#F23A84",
      "Color3": "#F86163",
      "Color4": "#FD913B",
      "Color5": "#D85A96",
      "SubscriptionItemCode":"2020000",
      "BannerImage": "/assets/images/product/SoulSubscriptionBanner.png"
    }
  ]
};
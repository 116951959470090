import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector : 'return-policy',
  templateUrl : './returnpolicy.component.html',
  styles : [`
    .policy-iframe{
      height : 594px;
    }
    p{
      margin: 10px;
      text-align: end;
      cursor: pointer;
    }
    img{
      max-width: 100%;
    }
  `]
})

export class ReturnPolicyComponent implements OnInit{
  constructor(
    public policydialogRef: MatDialogRef<ReturnPolicyComponent>,
  ){}
  ngOnInit(){

  }

  closePolicyDialog(){
    this.policydialogRef.close();
  }
}

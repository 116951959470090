import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../core/services/config.service';
import { NotificationService } from '../../../core/services/notification.service';
import { RestApiService } from '../../../core/http/restapi.service';
import { UserService } from '../../../core/services/user.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { FindEnrollerByIDComponent } from '../findenroller-byid/findenroller-byid.component';
import { QuickViewComponent } from '../quick-view/quick-view.component';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-confirm-subscribe',
  templateUrl: './confirm-subscribe.component.html',
  styleUrls: ['./confirm-subscribe.component.scss']
})
export class ConfirmSubscribeComponent {
  public isButtonEnable: boolean = false;
  public selection = new SelectionModel<any>(false, []);
  public SelectedSponsor: any = {};
  userService: UserServiceModal;
  searchText: string = "";
  result: string = '';
  itemCount: number = 0;
  selectedSponsor: any[];
  constructor(
    private router : Router,
    public route : ActivatedRoute,
    public configService: ConfigService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: RestApiService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public user: UserService,
    public dialogRef2: MatDialogRef<QuickViewComponent>,
    public confirmSubsRef: MatDialogRef<ConfirmSubscribeComponent>
    ) {
    console.log(data);
    this.userService = user.userServiceModal;
  }


  ngOnInit() {

  }

  optionSelect(option: boolean) {
    this.confirmSubsRef.close(option);
    //this.dialog.closeAll();
    console.log(option)
  }
  closeDialog(d) {
    console.log(d);
    console.log('learnmore')
    this.confirmSubsRef.close('learnmore');
    // this.dialog.closeAll();
  }

}

<app-breadcrumb [title]="'CHECKOUT'" [showCrumbs]="true"
[breadcrumb]="[{path1: 'Shopping'| translate, route1: '/products'},{path2: 'Checkout'|translate}]"></app-breadcrumb>
<div class="main">
<section *ngIf="!accountService.isLoggedIn()" fxLayout="row" fxLayoutAlign="start start" fxLayout.lt-md="column"
    [ngClass]="{'cartOpened': cartService.sidebarOpenStatus == true }" fxLayoutAlign.lt-md="start stretch" class="p-15">
    <div class="r-s xs-light-border-box" [ngClass]="{'mx-auto': cartService.sidebarOpenStatus == true }" fxFlex="80" fxFlex.sm="65" fxFlex.gt-sm="60" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-sm="column"
        fxLayoutAlign.lt-sm="center center" fxLayoutGap="10px">
        <span class="m-t-5 f-s-20 f-w-700">{{'ReferredBy' | translate}}: 
            <span class="f-s-20 f-w-700"
                [textContent]="userService.enrollerInfo.FullName">
            </span> 
            <!-- {{'SiteUrl' | translate}} 
            <a [attr.href]="env.siteUrl + userService.enrollerInfo.WebAlias">
                <span class="f-s-20 f-w-700"
                [textContent]="userService.enrollerInfo.WebAlias"></span>
            </a> -->
        </span>
        <button mat-button class="btn-b" [textContent]="('ChangeReferralId' | translate) | titlecase"
            (click)="openFindEnroller()"></button>
    </div>
</section>

<!-- Main Form section on left side whole -->
<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start stretch" class="p-15" >
    <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="60" [ngClass]="{'cartOpened': cartService.sidebarOpenStatus }">
        <h2 class="h2">1. {{'Account'| translate}} / {{'ShippingAddress'| translate}}</h2>
        <section class="b-top" fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="mt-5">
                <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="100"
                    class="w-100 margininput exigo-validate">
                    <mat-label translate="FirstName"></mat-label>
                    <input matInput validate noempty="true" [(ngModel)]="userService.shippingAddress.FirstName" autocomplete="given-name" name="fname">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="55" fxFlex.lt-md="100"
                    class="w-100 margininput exigo-validate">
                    <mat-label translate="LastName"></mat-label>
                    <input matInput validate noempty="true" [(ngModel)]="userService.shippingAddress.LastName" autocomplete="family-name"  name="lname">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100 exigo-validate" fxFlex="auto">
                    <mat-label translate="Address"></mat-label>
                    <input matInput VerifyAddress="true" (focusout)="verifyPhoneForOffer(userService.shippingAddress.Phone, userService.shippingAddress.Email, userService.shippingAddress.Street1)" validate noempty="true" [(ngModel)]="userService.shippingAddress.Street1"
                        name="streetaddress">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100"
                    class="w-100 margininput exigo-validate">
                    <mat-label translate="City"></mat-label>
                    <input VerifyAddress="true" matInput validate noempty="true" [(ngModel)]="userService.shippingAddress.City" name="city">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="100" class="w-100 margininput exigo-validate">
                    <mat-label translate="SelectState"></mat-label>
                    <mat-select [(ngModel)]="userService.shippingAddress.StateName" name="state" validate
                        validate-select="true" VerifyAddress="true" (selectionChange)="calculateOrder()">
                        <mat-option *ngFor="let region of allowedRegions" [value]="region.RegionCode">
                            {{region.RegionName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100" class="w-100 exigo-validate">
                    <mat-label translate="Zip"></mat-label>
                    <input matInput [attr.regexcountry]="userService.shippingAddress.CountryCode" regxvalidzip="true" VerifyAddress="true"
                        validate noempty="true" [(ngModel)]="userService.shippingAddress.PostalCode" name="zipcode"
                        (focusout)="calculateOrder()">
                </mat-form-field>

                <mat-form-field  appearance="outline" fxFlex="25" fxFlex.lt-md="100">
                    <mat-label translate="Country"></mat-label>
                    <mat-select [disabled]="true"
                        (selectionChange)="countrySelected($event)" [(ngModel)]="userService.shippingAddress.CountryCode"
                        VerifyAddress="true"  name="country" validate validate-select>
                        <mat-option *ngFor="let country of shippingCountries" [value]="country.CountryCode"
                        [textContent]="('Country_' + (country.CountryCode?.toUpperCase())) | translate"></mat-option>
                    </mat-select>
                </mat-form-field>
                <div id="verifyaddress" fxLayout="row" class="exigo-validate" fxLayoutAlign="start start" fxFlex="100"
                    *ngIf="userService.shippingAddress.CountryCode == 'US'">
                    <button mat-button sectiontype="VerifyAddress" validate trigger-check-section="true" noempty="true"
                        class="verifybtn" (success)="onVerifyAddress(userService.shippingAddress)" translate="VerifyAddress"></button>
                </div>
                <small class="reqText has-error help-block" [textContent]="'ErrorRequiredField' | translate"
                    *ngIf="userService.shippingAddress.CountryCode == 'US' && !userService.addressCheck && verifyAddressError"></small>
                <mat-form-field class="exigo-validate" appearance="outline" fxFlex="auto">
                    <mat-label translate="Email"></mat-label>
                    <input matInput validate noempty="true" email="true"
                        (focusout)="verifyEmail(userService.shippingAddress.Email)"
                        [(ngModel)]="userService.shippingAddress.Email" name="email">
                </mat-form-field>
                <mat-form-field class="exigo-validate" appearance="outline"  floatLabel="always" fxFlex="auto">
                    <mat-label  translate="MobilePhone"></mat-label>
                    <input matInput validate noempty="true" [(ngModel)]="userService.shippingAddress.Phone"
                       name="phone" (focusout)="verifyPhone('telephone')" [attr.placeholder]="'PhoneNumber' | translate" id="telephone">
                </mat-form-field>
                <!-- <div *ngIf="userService.IsshowPromotionOption" class="m-l-10 mt-10">
                    <span class="text-nonecase" [textContent]="('discount_msg' | translate) | titlecase"></span>
                    <a (click)="availDiscount()"><u class="f-w-800 m-l-5 help-block text-nonecase" [textContent]="('ClickHere' | translate) | titlecase"></u></a>
                </div>
                <div *ngIf="userService.promotionOffer && !userService.IsshowPromotionOption" class="m-l-10 mt-10">
                    <span class="text-nonecase" [textContent]="('discount_availed_already' | translate) | titlecase"></span>
                </div> -->
                <div fxLayout="row" class="m-t-15" fxLayoutAlign="flex-start center" fxFlex.lt-md="100">
                    <input type="checkbox" id="smsCheck" checked>
                    <label for="smsCheck" class="smsCheck" style="margin-bottom: 0 !important;">
                        &nbsp;Send me account updates, future discounts and promotions via TEXT MESSAGE (SMS)</label>
                </div>

                <!-- ----------------------------------------------------------------
                    Ship to other address
                ------------------------------------------------------------------- -->

                <div class="w-100 otherAddress">
                    <mat-checkbox [(ngModel)]="otherShippingAddress.checked">
                        <span class="checkbox-lable" translate
                            [textContent]="('ShipToOtherAddress' | translate)"
                        ></span>
                    </mat-checkbox>
                </div>
                
                <section *ngIf="otherShippingAddress.checked == true" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="mt-5">
                    <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="FirstName"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="otherShippingAddress.data.FirstName" autocomplete="given-name" name="fname">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="55" fxFlex.lt-md="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="LastName"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="otherShippingAddress.data.LastName" autocomplete="family-name"  name="lname">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100 exigo-validate" fxFlex="auto">
                        <mat-label translate="Address"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="otherShippingAddress.data.Street1"
                            name="streetaddress">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="City"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="otherShippingAddress.data.City" name="city">
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="100" class="w-100 margininput exigo-validate">
                        <mat-label translate="SelectState"></mat-label>
                        <mat-select [(ngModel)]="otherShippingAddress.data.StateName" name="state" validate
                            validate-select="true" (selectionChange)="calculateOrder()">
                            <mat-option *ngFor="let region of allowedRegions" [value]="region.RegionCode">
                                {{region.RegionName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100" class="w-100 exigo-validate">
                        <mat-label translate="Zip"></mat-label>
                        <input matInput [attr.regexcountry]="otherShippingAddress.data.CountryCode" regxvalidzip="true" validate noempty="true" [(ngModel)]="otherShippingAddress.data.PostalCode" name="zipcode" (focusout)="calculateOrder()">
                    </mat-form-field>
                    <mat-form-field  appearance="outline" fxFlex="25" fxFlex.lt-md="100">
                        <mat-label translate="Country"></mat-label>
                        <mat-select [disabled]="true" (selectionChange)="countrySelected($event)" [(ngModel)]="otherShippingAddress.data.CountryCode" name="country" validate validate-select>
                            <mat-option *ngFor="let country of shippingCountries" [value]="country.CountryCode"
                            [textContent]="('Country_' + (country.CountryCode?.toUpperCase())) | translate"></mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>

                <span *ngIf="itemsService.selectedAutoOrderItems?.length > 0 && otherShippingAddress.checked" class="text-muted mb-10" translate [textContent]="('ShipToOtherAddressAutoshipNote' | translate)"></span>

            </div>
        </section>
        <h2  class="h2" *ngIf="userService.shipMethods">2.{{'ShippingMethod'| translate}}</h2>
        <section class="b-top" fxLayout="column" fxLayoutAlign="center stretch" *ngIf="userService.shipMethods">
           <div class="shipping_radio_btn fit-con" fxLayout="row" fxLayoutAlign="start start"
                *ngFor="let shipmethod of userService.shipMethods">
                <input [ngClass]="{'hide-utah': shipmethod.Description.includes('Utah')}" type="radio" id="pickup-{{shipmethod.ShipMethodID}}" name="shippingMethods" [value]="shipmethod.ShipMethodID"
                    (click)="updateShipmethod(shipmethod.ShipMethodID)" [(ngModel)]="userService.selectedShippingMethod" />
                <label [ngClass]="{'hide-utah': shipmethod.Description.includes('Utah')}" for="pickup-{{shipmethod.ShipMethodID}}" style="padding-left: 5px;">
                    <span [textContent]="('ShipMethod_'+(shipmethod.ShipMethodID | removeComma) | translate: {Default: shipmethod.Description})"></span> -
                    <span [textContent]="selectCountry=='tr'? (shipmethod.ShippingAmount | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(shipmethod.ShippingAmount | currency: configService.selectedMarketConfig.CurrencySymbol)" class="text-muted"></span></label>
            </div>
        </section>
        <h2 class="h2" >3. {{'AccountInfo' | translate}}</h2>
        <section class="b-top" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start start"
            fxLayoutAlign.lt-md="center center">
            <mat-form-field fxFlex="100" appearance="outline" class="w-100 exigo-validate">
                <mat-label translate="Username"></mat-label>
                <input matInput (paste)="$event.preventDefault()" (focusout)="verifyUsername(userService.webOffice.UserName);"
                    type="text" validate noempty="true" [(ngModel)]="userService.webOffice.UserName" alphanumeric="true"
                    nospaceallow="true">
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column" fxLayoutAlign.lt-lg="start"
                fxLayoutGap="10px" fxFlex="100" style="width: 100%;">
                <div class="passField w-100" fxFlex="50" fxFlex.lt-md="100">
                    <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 15px" class="exigo-validate"
                        [color]="passwordComponent.color">
                        <mat-label translate="Password"></mat-label>
                        <input #password matInput name="password" validate noempty="true" regxvalid="true"
                            regxvalidvalue="((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})" [type]="showPass ? 'text' : 'password'"
                            nospaceallow="true" [(ngModel)]="userService.webOffice.Password" id="txtPassword" />
                    </mat-form-field>
                    <mat-password-strength class="green" #passwordComponent [password]="password.value"
                        style="margin-top: 1px;">
                    </mat-password-strength>

                    <div class="passCheck" style="margin-top: 1px" fxLayout="row" fxLayoutAlign="start center">
                        <input type="checkbox" name="passCheck" id="passCheck" [(ngModel)]="showPass" tabindex="-1">
                        <label for="passCheck" translate="ShowMyPassword"></label>
                    </div>
                </div>
                <mat-form-field fxFlex="50" fxFlex.lt-md="100" class="w-100 re-pass exigo-validate" appearance="outline">
                    <mat-label translate="ConfirmPassword"></mat-label>
                    <input matInput name="repassword" [type]="showPass ? 'text' : 'password'" validate password-match="true"
                        noempty="true" [(ngModel)]="userService.webOffice.ConfirmPassword" />
                </mat-form-field>
            </div>
        </section>

        <h2 class="h2">4. {{'PaymentInfo'| translate}}</h2>
        <section class="b-top payment-card-form" fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="margin: 10px;">
                <mat-checkbox id="sameAddress" [(ngModel)]="useSameBillingAddress" color="primary"
                    (change)="setBillingAddress($event)">
                    <span for="sameAddress" class="shipCheck" translate="UseSameBillingAddress"></span>
                </mat-checkbox>
            </div>
            <mat-form-field appearance="outline" fxFlex="auto" class="exigo-validate"
                *ngIf="paymentService.PaymentTypeResponse">
                <mat-label translate="SelectPaymentMethod"></mat-label>
                <mat-select (selectionChange)="paymentChange($event)" [(value)]="paymentService.SelectedPaymentTypes" validate
                    validate-select="true" paymentSection="true">
                    <mat-option [value]="addpayments?.PaymentTypeID"
                        *ngFor="let addpayments of paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods']"
                        (click)="payMethodSelectedId = addpayments.PaymentTypeID"
                        [textContent]="'PaymentType_' + (addpayments.PaymentTypeID | removeComma)" translate>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-hint class="text-color-r" *ngIf="isRedirectPaymentMethod()">*Card details will be entered on the next secure payment page.</mat-hint>
            <ng-container *ngIf="paymentService.SelectedPaymentTypes==1003 || paymentService.SelectedPaymentTypes==1001" class="kaxsdc" data-event='load'>
                <section class="kaxsdc" data-event='load'>
                    <input type="hidden" id="creditcard-token" name="creditcard-token" value="" />
                    <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />
                </section>
            </ng-container>
            <ng-container *ngIf="paymentService.SelectedPaymentTypes==1" class="kaxsdc" data-event='load'>
                <section class="kaxsdc" data-event='load'>
                    <input type="hidden" id="creditcard-token" name="creditcard-token" value="" />
                    <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />
                    <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="100" class="exigo-validate"
                        *ngIf="showBillForm">
                        <mat-label translate="NameOnCard"></mat-label>
                        <input type="text" validate noempty="true" matInput paymentSection="true"
                            [(ngModel)]="userService.cardDetail.CardName" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" id="cardiframe" class="exigo-validate cardiframe" fxFlex="62"
                        fxFlex.lt-md="100" *ngIf="showBillForm"  (click)="activateInput()">
                        <mat-label translate="CardNumber"></mat-label>
                        <input matInput cardNumber="true" type="hidden" maxlength="24"
                            [(ngModel)]="userService.cardDetail.CardNumber" />
                        <div id="cc-wrapper" class="cc-wrapper"></div>
                        <input type="hidden" class="creditcard-token" id="Token" />
                        <input type="hidden" class="creditcard-display" id="Display" />
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100"
                        class="w-100 margininput state exigo-validate" *ngIf="showBillForm">
                        <mat-label translate="ExpirationMonth"></mat-label>
                        <mat-select id="month" name="expirationmonth" [(ngModel)]="userService.cardDetail.CardExpirationMonth"
                            validate validate-select="true">
                            <mat-option *ngFor="let month of cardExpMonths"
                                (ngInit)="userService.cardDetail.CardExpirationMonth=cardExpMonths[0]?.value"
                                [value]="month.value" [textContent]="month.value + ' - ' + month.text"></mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate"
                        *ngIf="showBillForm">
                        <mat-label translate="ExpirationYear"></mat-label>
                        <mat-select [(ngModel)]="userService.cardDetail.CardExpirationYear" name="expireYear" validate
                            validate-select="true" paymentSection="true">
                            <mat-option [value]="year.value" *ngFor="let year of cardExpYears"
                                (ngInit)="userService.cardDetail.CardExpirationYear=cardExpYears[0]?.value">{{
                                year.text
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate"
                        *ngIf="showBillForm">
                        <mat-label translate="CVV"></mat-label>
                        <input type="text" validate inputonlynumber="true" paymentSection="true" noempty="true" matInput
                            [(ngModel)]="userService.cardDetail.CardCvv" maxlength="4" />
                    </mat-form-field>
                </section>
            </ng-container>
            <ng-container *ngIf="!useSameBillingAddress">
                <mat-form-field appearance="outline" class="exigo-validate" fxFlex="auto">
                    <mat-label translate="Country"></mat-label>
                    <mat-select (selectionChange)="billingcountrySelected($event)" name="country"
                        [(ngModel)]="userService.billingAddress.CountryCode" billingSection="true" validate
                        validate-select="true">
                        <mat-option [value]="country.CountryCode" *ngFor="let country of shippingCountries"
                            [textContent]="('Country_' + (country.CountryCode?.toUpperCase())) | translate"></mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="exigo-validate" fxFlex="auto">
                    <mat-label translate="StreetAddress"></mat-label>
                    <input type="text" billingSection="true" validate noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.Street1">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="City"></mat-label>
                    <input type="text" billingSection="true" validate noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.City">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="Select State"></mat-label>
                    <mat-select [(ngModel)]="userService.billingAddress.StateName" name="state" billingSection="true" validate
                        validate-select="true">
                        <mat-option *ngFor="let region of billingallowedRegions" [value]="region.RegionCode">{{
                            region.RegionName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="Zip"></mat-label>
                    <input type="text" billingSection="true" validate regxvalidzip="true"
                        [attr.regexcountry]="userService.billingAddress.CountryCode" noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.PostalCode" maxlength="9">
                </mat-form-field>

            </ng-container>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="80" fxFlex.lt-lg="100" style="margin-top: 15px;">
                <div class="field" fxFlexAlign="start" fxFlexAlign.lt-md="center" *ngIf="userService.shippingAddress.CountryCode == 'TR'">
                    <div class="mat-form-field exigo-validate checkbox">
                        <mat-checkbox color="primary" class="m-b-10 agreeLabel" validate checkbox-validate="true">
                            <span [innerHTML]="'EnrollmentAgreementsTrDesc' | translate"></span>
                        </mat-checkbox>
                    </div>
                    <div class="mat-form-field exigo-validate checkbox"
                        *ngIf="itemsService.selectedAutoOrderItems?.length > 0">
                        <mat-checkbox color="primary" class="m-b-10 agreeLabel" validate checkbox-validate="true">
                            <span [innerHTML]="'EnrollmentAutoshipAgreementsTrDesc' | translate"></span>
                        </mat-checkbox>
                    </div>
                    <div id="mat-checkbox-4">
                        <div class="mat-form-field exigo-validate checkbox" *ngIf="userService.shippingAddress.CountryCode == 'TR'">
                          <mat-checkbox color="primary" class="m-b-20 agreeLabel m-t-16" validate checkbox-validate="true" (change)="SaleAgreement('viewSaleAgreement')">
                            <div>
                              <span [innerHTML]="('directsellerAgreement_label' | translate) + ' '"></span><br>
                              <span class="s-c-b" [textContent]="('Click' | translate) + ' ' + ('Here' | translate)" (click)="openSellerAgreementDialog('distanceAgreement')" *ngIf="viewSaleAgreement"></span>
                            </div>
                          </mat-checkbox>
                      </div>
                    </div>
                    <div id="mat-checkbox-4">
                        <div class="mat-form-field exigo-validate checkbox" *ngIf="userService.shippingAddress.CountryCode == 'TR'">
                          <mat-checkbox color="primary" class="m-b-20 agreeLabel m-t-16" validate checkbox-validate="true" (change)="SaleAgreement('PreliminarySaleAgreement')">
                            <div>
                              <span [innerHTML]="('preliminaryAgreement_label' | translate) + ' '"></span><br>
                              <span class="s-c-b" [textContent]="('Click' | translate) + ' ' + ('Here' | translate)" (click)="openSellerAgreementDialog('preliminaryAgreement')" *ngIf="viewPreliminarySaleAgreement"></span>
                            </div>
                          </mat-checkbox>
                      </div>
                    </div>
                </div>
                <button fxFlexAlign.lt-md="center" id="checkoutPaybutton" class="btn-b"
                    *ngIf="(orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) > 0"
                    [textContent]="(paymentService.SelectedPaymentTypes==1 ? (('Pay' | translate) + (selectCountry=='tr'? ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | currency: configService.selectedMarketConfig.CurrencySymbol))):(selectedpaymentType?.IsRedirect ? ('ContinueToSecurePayment'  | translate ) : ('Continue' | translate)))"
                    validate trigger-check="true" (success)="fraudPrevention()"></button>
            </div>
            <div fxFlex="20" fxFlex.lt-md="100" fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.lt-md="start center"
                fxFlexAlign.lt-md="center" class="money-back">
                <span translate="ThirtyDayReturnPolicy"></span>
                <div class="d-circle-o">
                    <div class="d-circle-i"><span>30</span></div>
                </div>
                <a (click)="policyModelOpen()" translate="SeeDetails"></a>
            </div>
        </section>
    </div>
</div>

<!--This is the div that will gray out the contents behind the iframe and disable clicking on other items.-->
<div id="TokenEx3DS_Challenge_ParentElement" style="display:none; position:fixed; top:0; left:0; width:100vw; height:100vh; background: rgba(128, 128, 128, .5);">

    <!--This is the div that the challenge iframe will be created in.-->
    <div id="TokenEx3DS_Challenge_ChildElement" style="left: 50%;top: 50%;transform:translate(-50%, -50%); border: 2px solid black; position: absolute;"></div>

</div>


<!-- Related Products -->
<ng-template>
    <section [ngClass]="{'cartOpened': cartService.sidebarOpenStatus }" *ngIf="relatedProducts?.length > 0">
      <h2 class="rel-h" translate="OthersAlsoBuy"></h2>
      <mat-divider class="m-d-w"></mat-divider>
    
      <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="30px"
      fxLayoutAlign="space-between start" style="padding: 20px;" [ngClass]="{'related_products': cartService.sidebarOpenStatus }">
          <div class="item rel" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px"
          *ngFor="let item of relatedProducts" fxFlex="33" fxFlex.md="30" [ngClass]="{'rel_product': cartService.sidebarOpenStatus }">
              <div class="itemImage br" fxLayout="column" fxLayoutAlign="center center">
                  <span [textContent]="item.ItemDescription" class="text-1"></span>
                  <img [default]="'../.././../../assets/images/noimage.png'"
                  class="m-w-100" [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                      (click)="GoToProduct(item)" />
                  <button class="rel_btn" style="margin-top: 10px" (click)="quickView(item)" translate="QuickView">
                  </button>
              </div>
              <div class="br" fxLayout="column" fxLayoutAlign="flex-start center">
                  <div class="t-2-e prod-sub">{{'Related_Product_Title_{ItemCode}' |translate}}
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                      <div *ngIf="accountService.isLoggedUserRetailType()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                          <span [textContent]="selectedCountry=='tr' ? (item.PriceType1Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(item.PriceType1Value | currency: configService.selectedMarketConfig.CurrencySymbol)"
                              style="font-weight: 600"></span>
                          <button class="btn btn-b" (click)="addToCart('autoship', item)" translate="SubscribeToSave"></button>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                          <span
                              [textContent]="selectedCountry=='tr'? (item.PriceType2Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(item.PriceType2Value | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
                          <button class="btn btn-w" (click)="addToCart('order', item)" translate="OneTimeOrder"></button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
</ng-template>

</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit, OnDestroy{
  public userServiceModal:any;
  constructor(public user:UserService) { 
    this.userServiceModal=user.userServiceModal;

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    
  }

}

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, retry, catchError, tap } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as _ from 'lodash';
import { CustomerShippingAddress } from '../modals/autoship.modal';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  
  public loginStatus = new BehaviorSubject<boolean>(this.hasToken());
  // Http Options
  // httpOptions: any = {};
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.getheaders();
  }

  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('X-Auth')) {
      var token ="Bearer " +this.cookieService.get('X-Auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          "Authorization": token
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      };
    }
    return httpOptions;
  }
  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  isLoggedIn(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }
  
  public hasToken(): boolean {
    return this.cookieService.check('X-Auth');
  }
  //Public Controller

  validatePhone(country: string, phone: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/ValidatePhoneNumber/${country}/${phone}`, null,this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  validatePhoneForOffer(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/CheckMobileAvailable`, JSON.stringify(request),this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  sendOTPMessage(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/SendMessage`, JSON.stringify(request),this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  validateOTP(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/ValidateMobileCode`, JSON.stringify(request),this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ContactUs(stateCode: string, countryCode: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/ContactUs`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  VerifyEmail(email: any, newcustomerId: any = ''): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/VerifyEmail?email=${email}`+ (newcustomerId ? `&customerID=${newcustomerId}` : ''), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  VerifyUserName(username: string, customerid: any = ''): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/VerifyUserName?username=${username}`+ (customerid ? `&customerID=${customerid}` : ''), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  VerifyTaxId(stateCode: string, countryCode: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/VerifyTaxId`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  VerifyWebAlias(stateCode: string, countryCode: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/VerifyWebAlias`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  GetCountries(): Observable<any> {
    if (window.sessionStorage && sessionStorage.getItem('allowedCountries')) {
      const allowedCountries = new Observable((observer: any) => {
        var countries = JSON.parse(sessionStorage.getItem('allowedCountries') || '{}');
        if(countries && countries?.Data?.length > 0){
          countries.Data = countries.Data.filter((data) => { return (['VN', 'BN'].indexOf(data.CountryCode) < 0) });
          countries.Data = _.sortBy(countries.Data, 'CountryName');
          sessionStorage.setItem('allowedCountries', JSON.stringify(countries));
        }
        observer.next(countries);
        observer.complete();
      });
      return allowedCountries;

    }
    else {
      const response = this.http.get<any>(`${environment.apiUrl}api/Public/GetCountries`, this.getheaders())
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
      response.subscribe((result: any) => {
        if (result && result.Data) {
          result.Data = result.Data.filter((data) => { return (['VN', 'BN'].indexOf(data.CountryCode) < 0) });
          result.Data = _.sortBy(result.Data, 'CountryName');
        }
        if (window.sessionStorage) {
          sessionStorage.setItem('allowedCountries', JSON.stringify(result));
        }
      });
      return response;
    }
  }
  getMarketConfig(countryCode:string): Observable<any> {
    if (window.sessionStorage && sessionStorage.getItem('GetMarketConfig_'+countryCode)) {
      const marketConfig = new Observable((observer: any) => {
        observer.next(JSON.parse(sessionStorage.getItem('GetMarketConfig_'+countryCode) || '[]'));
        observer.complete();
      });
      return marketConfig;
    }
    else {
      const response = this.http.get<any>(`${environment.apiUrl}api/Public/GetMarketConfig?countryCode=${countryCode}`, this.getheaders())
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
      response.subscribe((result: any) => {
        if (window.sessionStorage) {
          sessionStorage.setItem('GetMarketConfig_'+countryCode, JSON.stringify(result));
        }
      });
      return response;
    }
  }

  getWebsiteInformation(webalias: string): Observable<any> {
    const getLanguages = this.getheaders();
    getLanguages['params'] = new HttpParams({ fromObject: { webalias: webalias } });
    return this.http.get<any>(`${environment.apiUrl}api/Public/GetCustomerSite`, getLanguages)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  GetRegions(countryCode: string): Observable<any> {
    let params = new HttpParams
    params = params.append('countrycode',countryCode)
    return this.http.get<any>(`${environment.apiUrl}api/Public/GetRegions`, {params: params})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  GetLanguages(countryCode: string): Observable<any> {
    const getLanguages = this.getheaders();
    getLanguages['params'] = new HttpParams({ fromObject: { countrycode: countryCode } });
    return this.http.get<any>(`${environment.apiUrl}api/Public/GetAllLanguages`, getLanguages)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  // Company  Controller
  getCompanyDetails(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Company/Details`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }



  VerifyAddress(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Public/VerifyAddress`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  // Customers Controller

  addCustomerRewardPoints(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/AddCustomerRewardPoints`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  calculateOrder(request: any): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}api/Orders/CalculateOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  createProspect(request: any): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}api/Customers/calculateOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  createCustomer(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/CreateCustomer`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createCustomerAutoshipOrder(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/CreateCustomerAutoshipOrder`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createCustomerOrder(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/CreateCustomerOrder`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  createCustomerOrderAndAutoship(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/CreateOrderAutoOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createCustomerServiceLogEntry(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/CreateCustomerServiceLogEntry`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  createPaymentMethod(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/CreatePaymentMethod`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deductCustomerRewardPoints(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/DeductCustomerRewardPoints`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  deleteCustomerAutoship(autoshipid: any): Observable<any> {

    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromString: 'autoshipId=' + autoshipid });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/DeleteAutoship`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteCustomer(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/DeleteCustomer`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deletePaymentMethod(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/DeletePaymentMethod`, '', requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomer(request): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomers`, request, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getSearchCustomerDetail(customerID: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}api/Customers/GetSearchCustomerDetail/${customerID}`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }


  getCustomerAutoships(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerAutoships`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerCountries(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers​/GetCustomerCountries`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerCustomFields(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerCustomFields`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerDownlineIds(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerDownlineIds`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerIDs(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers​/GetCustomerIDs`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerIDsbyFilter(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerIDsbyFilter`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerOrders(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerOrders`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerOrderDetail(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerOrderDetail`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerParties(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerParties`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerPartyTotals(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerPartyTotals`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerRewardPoints(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerRewardPoints`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerSavedPaymentMethods(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerSavedPaymentMethods`, '' ,this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerServices(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerServices`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerStats(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerStats`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomersByParameter(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomersByParameter`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomersDataPoints(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomersDataPoints`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomersToPlaceTo(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomersToPlaceTo`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getDataPointCategories(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetDataPointCategories`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getNotificationEvents(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetNotificationEvents`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPaymentMerchants(request: any): Observable<any> {
    
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/GetPaymentTypes`,null,requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  GetCustomerBilling(request: any): Observable<any> {
    // const requestquery = this.getheaders();
    // requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetCustomerBilling`,request,this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPaymentMethodIFrame(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetPaymentMethodIFrame`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPaymentMethodIFrameTempUser(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetPaymentMethodIFrameTempUser`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getUnplacedCustomers(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/GetUnplacedCustomers`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  placeNode(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers​/PlaceNode`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  resetPassword(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Customers/ResetPassword`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  searchAssociate(req: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/SearchAssociate?searchTerm=${req.searchTerm}`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  submitApplication(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/SubmitApplication`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  submitCheckout(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/SubmitCheckout`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  createOrder(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/CreateOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  CreateAutoOrder(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RecuringOrders/CreateAutoOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  AutoOrderCreateAutoOrder(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/AutoOrder/CreateAutoOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  UpdateAutoOrder(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/AutoOrder/UpdateAutoOrder`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  DeleteAutoOrder(autoshipId: number, isLastAutoOrder: boolean = false): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/AutoOrder/DeleteAutoOrder?id=${autoshipId}&isLastAutoOrder=${isLastAutoOrder}`, '', this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  submitEnrollmentForm(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/SubmitEnrollmentForm`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAutoShipDateandAmount(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Customers/UpdateAutoShipDateandAmount`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCustomer(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/UpdateCustomer`,JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateCustomerByParameter(): Observable<any> {
    return this.http.patch<any>(`${environment.apiUrl}​/api/Customers/UpdateCustomerByParameter`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  usernameAvailabilityCheck(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers​/UsernameAvailabilityCheck`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  sendWelComeEmail(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Customers/SendWelComeEmail`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Echo  Controller


  ping(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Echo​/Ping`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  pingDeep(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Echo​/PingDeep`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Office  Controller


  getMediaResources(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Office​/GetMediaResources`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getNotificationSettings(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Office​/GetNotificationSettings`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getTrainingDetailsandProgress(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Office​/GetTrainingDetailsandProgress`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  triggerForgotPasswordProcess(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Office/TriggerForgotPasswordProcess`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Orders  Controller


  cancelOrder(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/CancelOrder`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteAutoship(autoshipid: number): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromString: 'autoshipId=' + autoshipid });
    return this.http.post<any>(`${environment.apiUrl}api/Orders/DeleteAutoship`, '', requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  enqueueStats(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/EnqueueStats`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getAutoship(autoorderid: number): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromString: 'autoshipId=' + autoorderid });
    return this.http.post<any>(`${environment.apiUrl}api/Orders/GetAutoship`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getOrder(orderId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/GetOrder?orderId=${orderId}`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getOrders(req: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/GetOrders/${req.page}/${req.count}`, JSON.stringify(req.details), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  GetAutoOrders(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RecuringOrders/GetAutoOrders`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getOrderIds(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderIds`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getOrderIdsFilter(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderIdsFilter`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getOrderPackageDetails(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderPackageDetails`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getOrderRMAs(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderRMAs`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getOrderType(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderType`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getOrderTypes(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetOrderTypes`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPriceGroup(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetPriceGroup`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPriceGroups(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetPriceGroups`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getShippingMethod(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetShippingMethod`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getShippingMethods(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetShippingMethods`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getUnshippedPackages_Deprecated(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders​/GetUnshippedPackages_Deprecated`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  importExternalOrder(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/ImportExternalOrder`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  insertPayments(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/InsertPayments`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  refundPayment(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/RefundPayment`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  sendOrderReceipt(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/SendOrderReceipt`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setOrderShipped_Deprecated(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/SetOrderShipped_Deprecated`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setPackageReturned_Deprecated(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/SetPackageReturned_Deprecated`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  setPackageShipped_Deprecated(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/SetPackageShipped_Deprecated`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  splitPackage(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Orders/SplitPackage`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAutoship(autoshipid: number, request: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/UpdateAutoship/${autoshipid}`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updatePackageStatus(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/UpdatePackageStatus`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updatePackageStatusBatch(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Orders/UpdatePackageStatusBatch`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Products Controller


  deleteItem(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products/DeleteItem`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getItembyId(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetItembyId`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getItembySKU(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetItembySKU`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getItems(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products/GetItems`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getItemList(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Items/GetItemList`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getPackItems(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.get<any>(`${environment.apiUrl}api/Enrollment/GetPackItems`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getStarterKitItems(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.get<any>(`${environment.apiUrl}api/Enrollment/GetStarterPackItems`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getDynamicKitItems(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.get<any>(`${environment.apiUrl}api/Enrollment/GetDynamicKitItems`, requestquery)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getItemDetail(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Items/ItemDetail`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getSubscriptionItemDetail(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Items/SubscriptionItemDetail`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getRegions(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetRegions`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStockLevel(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetStockLevel`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStores(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetStores`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getSubCategories(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetSubCategories`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getWarehouses(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Products​/GetWarehouses`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getTopSellingProducts(request: any): Observable<any> {
    const getTopSellingrequest = this.getheaders();
    getTopSellingrequest['params'] = new HttpParams({ fromObject: request });
    return this.http.post<any>(`${environment.apiUrl}api/Products/GetTopSellingProducts`, getTopSellingrequest)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateStockLevel(): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}api/Products/UpdateStockLevel`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // RMAs  Controller


  createRMA(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs/CreateRMA`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  deleteRMA(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs​/DeleteRMA`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  getRMA(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs​/GetRMA`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getRMAs(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs​/GetRMAs`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  markRMAReceived(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs/MarkRMAReceived`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  markRMAShipped(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/RMAs/MarkRMAShipped`, JSON.stringify({}), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  // SSO  Controller


  getCustomerBySSOToken(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/SSO​/GetCustomerBySSOToken`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getAdminBySSOToken(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/SSO​/GetAdminBySSOToken`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerSSOURL(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/SSO​/GetCustomerSSOURL`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCustomerSSOURLByExternalId(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/SSO​/GetCustomerSSOURLByExternalId`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // Utilities  Controller


  clearCache(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Utilities/ClearCache`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getError(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Utilities​/GetError`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getStatus(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Utilities​/GetStatus`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  // Validate  Controller
  Login(request: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiUrl}api/Authentication/Login`, JSON.stringify(request), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      observe: 'response'
    })
      .pipe(
        retry(1),
        tap((resp: HttpResponse<any>) => {
          // if (resp.headers && resp.headers.get('X-Auth')) {
          //   this.cookieService.set('X-Auth', (resp.headers.get('X-Auth') || ''), new Date().getSeconds() + 10, '/');
          //   this.getheaders();
          // }
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  forgotPassword(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Authentication/ForgotPassword`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  Logout(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Validate/Logout`, '', this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  validateTaxId(taxId: string) {
    return this.http.post<any>(`${environment.apiUrl}api/Validate​/TaxId?taxId=${taxId}`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  validateWebAlias(webAlias: string) {
    return this.http.get<any>(`${environment.apiUrl}api/Public/GetCustomerSite?webAlias=${webAlias}`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCompanyCountryLanguages() {
    return this.http.post<any>(`${environment.apiUrl}api/Common/GetCompanyCountryLanguages`, this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getEnrollItems(request: any): Observable<any> {
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({ fromObject: request });
    return this.http.get<any>(`${environment.apiUrl}api/Enrollment/GetItems`, requestquery)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  ApplyCoupon(request: any): Observable<any>{
    const requestquery = this.getheaders();
    requestquery['params'] = new HttpParams({fromObject: request});
    return this.http.post<any>(`${environment.apiUrl}api/Coupon/ApplyCoupon`, '', requestquery)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  OrderComplete(request: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/OrderComplete?token=${request}`,null, this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetBankDepositInstructions(request: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}api/Enrollment/GetBankDepositInstructions?token=${request}`,null, this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  CreateSession(request: any): Observable<any>{
    return this.http.post<any>(`${environment.trackingapiUrl}api/Session/CreateSession`,JSON.stringify(request), this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  CreateExigoSession(request: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}api/Other/SetSession`,JSON.stringify(request), this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  CreateStats(request: any): Observable<any>{
    return this.http.post<any>(`${environment.trackingapiUrl}api/Stats/SetStats`,JSON.stringify(request), this.getheaders() )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  uploadFile(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Files/UploadFile`, JSON.stringify(request), this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  ValidateSSOToken(request: any, MethodName: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environment.apiUrl}api/Authentication/${MethodName}?token=${request}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      observe: 'response'
    })
      .pipe(
        retry(1),
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(this.handleError)
      );
  }

  // Addresses Controller
  GetAllAddresses(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Address/GetAllAddresses`, '' ,this.getheaders())
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  EditAddress(address: CustomerShippingAddress, makePrimary?: Boolean): Observable<any> {
    const url = `${environment.apiUrl}api/Address/EditAddress` + (makePrimary ? '?makePrimary=true' : '');
    return this.http.post<any>(url, JSON.stringify(address), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  AddAddress(address: CustomerShippingAddress, makePrimary?: Boolean): Observable<any> {
    const url = `${environment.apiUrl}api/Address/AddAddress` + (makePrimary ? '?makePrimary=true' : '');
    return this.http.post<any>(url, JSON.stringify(address), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  DeleteAddress(type: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Address/DeleteAddress?type=${type}`, '', this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  //Credit Cards

  AddCreditCard(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/PaymentMethods/AddCreditCard`, JSON.stringify(request), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  EditCreditCard(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/PaymentMethods/EditCreditCard`, JSON.stringify(request), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
  DeleteCreditCard(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/PaymentMethods/DeleteCreditCard`, JSON.stringify(request), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  UnsubscribeNotifications(): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Communications/OptOutCustomer`, '', this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  SubscribeNotifications(request: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}api/Communications/OptInCustomer`, JSON.stringify(request), this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetUserAvatar(customerId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}profiles/avatar/${customerId}/Large/false`, {responseType: 'blob'});
  }

  SetCustomerProfilePicture(request: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}api/Images/SetCustomerProfilePicture`, request, this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  GetCustomerPointAccounts(): Observable<any> {
    return this.http.post(`${environment.apiUrl}api/Points/GetCustomerPointAccounts`, '', this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetCustomerPointAccount(pointAccountID: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}api/Points/GetCustomerPointAccount?pointAccountID=${pointAccountID}`, '', this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  GetPointTransactionHistory(): Observable<any> {
    return this.http.post(`${environment.apiUrl}api/Points/GetPointTransactionHistory`, '', this.getheaders())
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
}

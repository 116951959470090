<app-breadcrumb [title]="('OrderConfirmation' | translate) | uppercase" class="order-confirm-crumbs"
    *ngIf="isEnrollment == 'false'"></app-breadcrumb>
<app-breadcrumb [title]="('OrderConfirmation' | translate) | lowercase" class="order-confirm-crumbs"
    *ngIf="isEnrollment == 'true'"></app-breadcrumb>
<section fxLayoutAlign="center center" fxLayout="column" class="container">
    <!-- ********************  for normal items ******************** -->
    <div class="order-confirm-container" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="isEnrollment == 'false'">
        <div class="banner-image" fxLayoutAlign="center center">
            <div [textContent]="'OrderComplete' | translate" class="banner-title"></div>
        </div>
        <div class="cta-headings" fxLayout="column" fxLayoutAlign="center center">
            <div class="big-title text-center" [textContent]="'OrderConfirmTitle' | translate"></div>
            <div class="subtitle text-center" [textContent]="'OrderConfirmSubtitle' | translate"></div>
        </div>
        <div *ngIf="(paymentService?.SelectedPaymentTypes == 13 || paymentService?.SelectedPaymentTypes == 1002)"
            fxLayoutAlign="center center" fxLayout="column">
            <div class="bank-deposit-details text-left">
                <span translate="" class="text-uppercase">Click </span>
                <span><a (click)="bankDepositModelOpen()" translate="" class="click_here text-uppercase"> Here
                    </a></span>
                <span translate="ClickHereToSeeBankDepositInstructions"></span>
            </div>
        </div>
        <div class="order-details" fxLayoutAlign="start center">
            <table class="table">
                <tr>
                    <th [textContent]="('Order' | translate) + '# : '"></th>
                    <a>
                        <td (click)="invoiceModelOpen('Order')" [textContent]="orderCompleteResponse?.OrderID"></td>
                    </a>
                </tr>
                <tr>
                    <th [textContent]="('Products' | translate) + ' : '"></th>
                    <td *ngFor="let detail of orderCompleteResponse?.Order?.Details">
                        {{ detail.ItemDescription }} <span *ngIf="orderCompleteResponse?.Order?.Details?.length > 1">,
                        </span>
                    </td>
                </tr>
                <tr>
                    <th [textContent]="('Quantity' | translate) + ' : '"></th>
                    <td [textContent]="(orderCompleteResponse?.Order?.Details?.length)"></td>
                </tr>
                <tr>
                    <th [textContent]="('Price' | translate) + ' : '"></th>
                    <td [textContent]="orderCompleteResponse?.Order?.Total"></td>
                </tr>
                <tr *ngIf="enrollmentservice.shippingAddress.CountryCode == 'TR'">
                    <th [textContent]="('DistanceAgreement' | translate) + ' : '"></th>
                    <a class="under_line">
                        <td class="click_here" [textContent]="('Click' | translate) + ' ' + ('Here' | translate)"
                            (click)="openSellerAgreementDialog('distanceAgreement')"></td>
                    </a>
                </tr>
                <tr *ngIf="enrollmentservice.shippingAddress.CountryCode == 'TR'">
                    <th [textContent]="('PreliminaryAgreement' | translate) + ' : '"></th>
                    <a class="under_line">
                        <td class="click_here" [textContent]="('Click' | translate) + ' ' + ('Here' | translate)"
                            (click)="openSellerAgreementDialog('preliminaryAgreement')"></td>
                    </a>
                </tr>
            </table>
        </div>
        <div class="donate-section" fxLayoutAlign="center center" fxLayout="column">
            <div class="big-title text-center" [textContent]="'DonateTitle' | translate"></div>
            <img src="../../../assets/images/donateImage.png" alt="" class="img-fluid">
            <a href="https://seedsforchangefoundation.com" [textContent]="'seedsforchangefoundation.com'"></a>
        </div>
    </div>

    <!-- ********************  for packs items ******************** -->

    <div class="pack-confirm-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="isEnrollment == 'true'">
        <div class="banner-image" fxLayoutAlign="start center">
            <div [innerHTML]="'WelcomeToRain' | translate" class="banner-title"></div>
        </div>
        <div class="cta-headings" fxLayout="column" fxLayoutAlign="center center">
            <div class="big-title text-center" [textContent]="'EnrollmentThankyou' | translate"></div>
            <div class="subtitle text-center" [textContent]="'EnrollmentThankyouDesc' | translate"></div>
        </div>
        <div *ngIf="(paymentService?.SelectedPaymentTypes == 13 || paymentService?.SelectedPaymentTypes == 1002)"
            fxLayoutAlign="center center" fxLayout="column">
            <div class="bank-deposit-details text-left">
                <span translate="Click" class="text-uppercase"></span>&nbsp;
                <span><a (click)="bankDepositModelOpen()" translate="Here"
                        class="click_here text-uppercase"></a></span>&nbsp;
                <span translate="ClickHereToSeeBankDepositInstructions"></span>
            </div>
        </div>
        <div class="order-details" fxLayoutAlign="start center">
            <table class="table">
                <tr>
                    <th [textContent]="('ReplicatedWebsiteUrl' | translate) + '# : '"></th>
                    <td [textContent]="env.siteUrl + enrollmentservice.webOffice.UserName"></td>
                </tr>
                <tr>
                    <th [textContent]="('Username' | translate) + ' : '"></th>
                    <td [textContent]="orderCompleteResponse?.Order?.CustomerID"></td>
                </tr>
                <tr>
                    <th [textContent]="('Distributerid' | translate) + ' : '"></th>
                    <td [textContent]="orderCompleteResponse?.Order?.CustomerID"></td>
                </tr>
                <tr>
                    <th [textContent]="('Order' | translate) + '# : '"></th>
                    <a>
                        <td (click)="invoiceModelOpen('Order')" [textContent]="orderCompleteResponse?.OrderID"></td>
                    </a>
                </tr>
                <tr *ngIf="orderCompleteResponse?.AutoOrderID > 0">
                    <th [textContent]="('Autoship' | translate) + '# : '"></th>
                    <a>
                        <td (click)="invoiceModelOpen('AutoOrder')" [textContent]="orderCompleteResponse?.AutoOrderID">
                        </td>
                    </a>
                </tr>
                <tr *ngIf="enrollmentservice.shippingAddress.CountryCode == 'TR'">
                    <th [textContent]="('DirectSellerAgreement' | translate) + ' : '"></th>
                    <a class="under_line">
                        <td class="click_here" [textContent]="('Click' | translate) + ' ' + ('Here' | translate)"
                            (click)="openSellerAgreementDialog('enrollAgreement')"></td>
                    </a>
                </tr>
            </table>
        </div>
    </div>
    <div fxHide>
        <app-seller-agreement-dialog [orderdata]="orderCompleteResponse"
            *ngIf="orderCompleteResponse?.OrderID > 0"></app-seller-agreement-dialog>
    </div>
</section>
<!-- <div id="view-order-complete">
    <div *ngIf="orderCompleteResponse.IsUsingAnAlternativePaymentMethod">
      <div *ngIf="orderCompleteResponse.IsUsingSimplePay">
          {{orderCompleteResponse.TransactionID}}
      </div>

      <div fxLayout="row" class="mb-4" fxLayoutAlign="center center">
          <div class="text-center">
            <p class="font-italic">Thank you for order</p>
          </div>
      </div>
  </div>
    <div class="d-print-none container">
      <div *ngIf="orderCompleteResponse.NeedsBankDepositInstructions">
            <div id="btnShowBankDepositInstructionsContainer" class="font-weight-bold mb-3">
               Instruction
            </div>
          </div>
    </div>

    <div class="content-wrap">
        <div class="container">
            <div class="table-responsive">
              <div *ngIf="orderCompleteResponse?.Order != null">


                    <table class="table">
                        <thead>
                        <tr class="thead-light">
                            <th>Company</th>
                            <th>Bill To</th>
                        </tr>
                        <tr>
                            <td>
                                Address1, Address2<br />
                                City, State, Zip<br />
                                <div class="mb-3"></div>
                                Company Email<br />
                                Company Phone
                            </td>
                            <td>
                               {{ orderCompleteResponse?.Order.Recipient.FirstName }} {{orderCompleteResponse?.Order.Recipient.LastName}}<br />
                               {{ orderCompleteResponse?.Order.Recipient.Address1}} {{orderCompleteResponse?.Order.Recipient.Address2}}<br />
                               {{ orderCompleteResponse?.Order.Recipient.City}}, {{orderCompleteResponse?.Order.Recipient.State}}, {{orderCompleteResponse?.Order.Recipient.Zip}}<br />
                               {{ orderCompleteResponse?.Order.Recipient.Email }} <br />
                               {{ orderCompleteResponse?.Order.Recipient.Phone }} <br />
                               <div class="mb-3">
                                <div class="payment" *ngFor="let payment of orderCompleteResponse?.Order.Payments">
                                  <div [ngSwitch]="payment.PaymentTypeID">
                                    <div *ngSwitchCase="payment.CreditCardEnding">{{payment.CreditCardNumber}}</div>
                                    <div *ngSwitchDefault></div>
                                    - {{ payment.Amount | currency: configService.selectedMarketConfig.CurrencySymbol }}
                                  </div>
                                </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                        <tbody>
                            <tr class="thead-light">
                                <th colspan="2">Invoice For <strong>#{{orderCompleteResponse?.Order.OrderID}}</strong></th>
                            </tr>
                            <tr class="italic" *ngFor="let i of orderCompleteResponse?.Order.Details">
                              <td>{{i.ItemDescription }} - {{i.Quantity}}</td>
                              <td>{{i.PriceTotal | currency: configService.selectedMarketConfig.CurrencySymbol}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                        <tr class="thead-light"><th colspan="2"></th></tr>
                        <tr>
                            <th class="text-right">Total CV </th>
                            <td class="text-left">{{orderCompleteResponse?.Order.CVTotal}}</td>
                        </tr>
                        <tr>
                            <th class="text-right">Total </th>
                            <td class="text-left">{{orderCompleteResponse?.Order.Subtotal | currency: configService.selectedMarketConfig.CurrencySymbol}}</td>
                        </tr>
                        <tr>
                            <th class="text-right">Shipping</th>
                            <td class="text-left">{{orderCompleteResponse?.Order.ShippingTotal | currency: configService.selectedMarketConfig.CurrencySymbol}}</td>
                        </tr>
                        <tr>
                            <th class="text-right">Tax</th>
                            <td class="text-left">{{orderCompleteResponse?.Order.TaxTotal | currency: configService.selectedMarketConfig.CurrencySymbol}}</td>
                        </tr>
                        <tr>
                            <th class="text-right">Order Total</th>
                            <td class="text-left">{{orderCompleteResponse?.Order.Total | currency: configService.selectedMarketConfig.CurrencySymbol}}</td>
                        </tr>
                        <tr *ngIf="orderCompleteResponse?.Order.Payments.length">
                          <th class="text-right">Balance Due</th>
                          <td class="text-left">{{orderCompleteResponse?.Order.Total - orderCompleteResponse?.Order.Payments.Amount}}</td>
                      </tr>
                    </tfoot>
                    </table>
              </div>
              <div *ngIf="orderCompleteResponse?.AutoOrder != null">
                <div class="mb-3"></div>
                  <h3>Autoship Details #{{orderCompleteResponse?.AutoOrder.AutoOrderID}}</h3>
                  <table class="table">
                      <tbody>
                          <tr class="thead-light">
                              <th>Frequency</th>
                              <th>NextRunDate</th>
                              <th>Total</th>
                          </tr>
                          <tr>
                              <td>
                                {{orderCompleteResponse?.AutoOrder.FrequencyTypeDescription}}<br />
                              </td>
                              <td>
                                {{orderCompleteResponse?.AutoOrder.NextRunDate | date }}<br />
                              </td>
                              <td>
                                {{orderCompleteResponse?.AutoOrder.Total | currency: configService.selectedMarketConfig.CurrencySymbol}}<br />
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>

            </div>

            <div class="d-print-none">
                <button (click)="print()" class="dark-btn" [textContent]="'Print Invoice'"></button>
            </div>
            <div class="mb-3"></div>
        </div>
    </div>

    <div id="BankDepositInstructionsModal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header d-print-none">
                    <a id="btnPrintBankDepositInstructions" class="btn btn-outline-primary"><i class="fa-print"></i> Print</a>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="orderCompleteResponse.NeedsBankDepositInstructions">
                 {{ orderCompleteResponse.bankDepositInstructionsResource}}
                </div>
            </div>
        </div>
    </div>
</div> -->
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Event, ResolveEnd, Router } from '@angular/router';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer/lib/ngx-extended-pdf-viewer.module';
import * as $ from 'jquery';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { CartService } from 'src/app/core/services/cart.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
@Component({
  selector: 'app-seller-agreement-dialog',
  templateUrl: './seller-agreement-dialog.component.html',
  styleUrls: ['./seller-agreement-dialog.component.scss'],
  // providers: [NgxExtendedPdfViewerModule]

})
export class SellerAgreementComponent implements OnInit {
  enrollmentservice: UserServiceModal;
  userService: UserServiceModal;
  userdata: any;
  TaxID: any;
  date: any;
  selectedLanguage: any;
  DistanceAgreement: boolean = false;
  PreliminaryAgreement: boolean = false;
  enrollAgreement: boolean = false;
  isEnrollment: string;
  type: any;
  @Input() orderdata:any;  
  constructor(
    public dialogRef: MatDialogRef<SellerAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public user: UserService,
    public enroll: UserService,
    public itemsService: ProductService,
    public cartService: CartService,
    public configService: ConfigService,
    private router: Router,
    public orderService: OrderService,
    private route: ActivatedRoute
  ) {
    // this.pdfSrc = data.url;
    // this.pdfSrc= 'https://rain-policies.s3.amazonaws.com/Rain+Partner+Terms+and+Conditions.pdf';
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'));
    this.date = new Date().toDateString();
    this.enrollmentservice = enroll.userServiceModal;
    this.userService = user.userServiceModal;
    this.type = data.type;
    if(data.orderdata){
      this.orderdata = data.orderdata;
    }

    if (this.type == "checkout") {
      if (data.agreementId == 'distanceAgreement' + '_' + this.selectedLanguage.CultureCode) {
        this.DistanceAgreement = true;
      }
      if (data.agreementId == 'preliminaryAgreement' + '_' + this.selectedLanguage.CultureCode) {
        this.PreliminaryAgreement = true;
      }
    }

    if(this.type == "enrollment") {
      if (data.agreementId == 'enrollAgreement' + '_' + this.selectedLanguage.CultureCode) {
         this.enrollAgreement = true;
      }
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    this.gotoTop();
    this.isEnrollment = JSON.parse(sessionStorage.getItem('isEnrollment'))
    
    if(this.isEnrollment == 'true') {
      this.enrollAgreement = true;
    }

    if(this.isEnrollment == 'false') {
      this.DistanceAgreement = true;
      this.PreliminaryAgreement = true;
    }
   
  }

  getItems(type: string) {
    if(this.itemsService[type == 'pack' ? 'selectedPacks' : (type == 'autoship' ? 'selectedAutoOrderItems' : 'selectedOrderItems')].length > 0){
      return this.itemsService[type == 'pack' ? 'selectedPacks' : (type == 'autoship' ? 'selectedAutoOrderItems' : 'selectedOrderItems')].filter(item => item.ItemCode != 'DiscountCode');
    }
    else{
      return this.orderdata?.Order?.Details;
    }
  }

  getQuantityModel(type, item): any {
    return this.cartService[type == 'pack' ? 'packQuantity' : (type == 'autoship' ? 'autoshipQuantity' : 'orderQuantity')];
  }

  matDialogClose() {
    this.dialogRef.close(true);
  }

  printPdf(evt) {

    if (this.data.agreementId == ('distanceAgreement' + '_' + this.selectedLanguage.CultureCode)) {
      var prtContent = $('#' + 'distanceAgreement' + '_' + this.selectedLanguage.CultureCode);
    }

    if (this.data.agreementId == ('preliminaryAgreement' + '_' + this.selectedLanguage.CultureCode)) {
      var prtContent = $('#' + 'preliminaryAgreement' + '_' + this.selectedLanguage.CultureCode);      
    }

    if (this.data.agreementId == ('enrollAgreement' + '_' + this.selectedLanguage.CultureCode)) {
      var prtContent = $('#' + 'enrollAgreement' + '_' + this.selectedLanguage.CultureCode);      
    }

    var WinPrint = window.open('', '');
    WinPrint.document.write(prtContent[0].innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
}

<div class="findenroller-byid-dialog">
  <div fxLayout="row" class="market-selector ms-padding">
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <h2 class="country-selection m-0" mat-dialog-title>Find Your Sponsor</h2>
      <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
    </div>
  </div>
  <hr class="mb-10">
  <h3 class="ms-padding">Search Results</h3>
  <div fxLayout="column" class="ms-padding section-scroll">
    <div *ngFor="let list of DIALOGDATA" fxFlex=100 fxLayout="row" class="ms-padding" fxLayout.lt-sm="column"
      fxLayoutAlign="spac stretch">
      <div fxFlex="30" class="sponsor_img">
        <img [src]="env.apiUrl +'profiles/avatar/'+list.CustomerID+'/Default/true'" alt="" class="img-fluid">
      </div>
      <div fxFlex="65" fxLayout="column" fxLayoutAlign="start start" class="sponsor_details">
        <p [textContent]="list.FullName + (list.FullName ? ', ': '') + 'ID #' + list.CustomerID"></p>
        <p [textContent]="list.MainCity + ',' + list.MainState"></p>
        <a [href]="list.ReplicatedSiteUrl" [textContent]="list.ReplicatedSiteUrl"></a>
        <button mat-stroked-button [textContent]="'CHOOSE'" (click)="setSponsor(list)"></button>
      </div>
    </div>
    <mat-card *ngIf="DIALOGDATA.length==0" class="m-b-20">
      <b  translate="SearchNotFound"></b>
    </mat-card>
  </div>
</div>
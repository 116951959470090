import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isLogin: any;
  constructor() { }

  ngOnInit(): void {
    this.isLogin = localStorage.getItem("isLoggedIn");
  }

}

<section class="features">
  <div fxLayout="column" class="py-5">
    <div fxLayoutAlign="center center">
      <p class="header" translate="Replicated_Home_ShopHeader"></p>
    </div>
  </div>
  <div fxLayout="row column" fxLayoutAlign="space-evenly center" class="padding bg-light">
    <div fxFlex.gt.sm="16" fxFlex.xs="50" fxLayout="column" fxLayoutAlign="center center"
      *ngFor="let image of ListImages">
      <img [src]="image.imageData" alt="{{image.text | translate}}"  [default]="image.imageData">
      <h4 class="text-center h4_text" translate="">{{image.text | translate}}</h4>
    </div>
  </div>
</section>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { CartService } from 'src/app/core/services/cart.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrderService } from 'src/app/core/services/order.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ValidateKeywordService } from 'src/app/core/services/validatekeyword.service';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import * as moment from 'moment';
import { MatDrawer } from '@angular/material/sidenav';
import { SharedService } from 'src/app/core/services/shared.service';
import { AccountService } from 'src/app/core/authentication/account.service';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-sidebar-summary-cart',
  templateUrl: './sidebar-summary-cart.component.html',
  styleUrls: ['./sidebar-summary-cart.component.scss']
})

export class SideBarSummaryCartComponent {
  @Input() stepperRef: MatStepper;
  userService: UserServiceModal;
  enrollmentservice: UserServiceModal;
  selectedPackType: any = {};
  public kitItems: any;
  public dynamickitItems: any;
  public shippingCountries:Array<any>=[];
  allowedRegions: any[] = [];
  quantity: number = 4;
  quantity_added: number = 0;
  showQtyBox: boolean[] = [];
  selectedvalue:Array<any>=[];
  multipackquantity:Array<any>=[];
  selectedProduct:Array<any>=[];
  data:Array<any>=[];
  totalqty:Array<any>=[];
  checkeligibilty = true;
  public selectedPacksChildren:Array<any>=[];
  @Input() cartdrawer: MatDrawer
  @ViewChild('picker') _autoshipDatePicker: MatDatepicker<Date>;

  env: any;
  crossClick = true;
  icon: boolean = false;
  lastQuantity: any;
  showPass = false;
  public Years:Array<any>=[];
  click() {
    this.icon = !this.icon;
  }

  public cardExpMonths:Array<any>=[];
  public cardExpYears:Array<any>=[];
  verifiedCoupon: string = '';
  locale: string = '';

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    public apiService: RestApiService,
    public notificationService: NotificationService,
    public validateKeyword: ValidateKeywordService,
    public router: Router,
    private dialog: MatDialog,
    public user: UserService,
    public companyService: CompanyService,
    public itemsService: ProductService,
    public cartService: CartService,
    public orderService: OrderService,
    public paymentService: PaymentService,
    public utilityService:UtilityService,
    public configService: ConfigService,
    public sharedService: SharedService,
    private accountService: AccountService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.Years=utilityService.birthYears();
    this.userService = this.enrollmentservice = user.userServiceModal;
    this.env = environment;
    this.cardExpYears=utilityService.cardExpYears();
    this.cardExpMonths=utilityService.cardExpMonths();
    // this.apiService.GetCountries().subscribe({
    //   next: data => {
    //     this.shippingCountries = data.Data;
        if (window.sessionStorage) {
          const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
          this.userService.shippingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
          this.enrollmentservice.shippingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
        }
    //   }
    // });
    this.locale = ((this.configService.commonData?.selectedLanguage?.CultureCode || 'en') + '-' + (this.configService.commonData?.selectedCountry || 'us'));
    this.dateAdapter.setLocale(this.locale);
  }
  public relatedProducts: Array<any> = [];
  selectedCountry = '';
  public countries:Array<any>=[];
  @Input() payMethodSelectedId: any;
  showBillForm = true;
  showBillAddress = true;
  payMethod = '';
  sameAddress = false;
  packPrice = 0;
  packItemsNoChildren: any[] = [];
  public KIT: any[] = [];
  barFill = [];
  showChild = [];
  freeShippingBar = 10;
  sub = 0;
  remAmt = 250;
  todaysDate = new Date();
  frequencyStartminDate = new Date();
  frequencyDate = {
    startDate: new Date(new Date(moment().add(1, 'days').format('YYYY-MM-DD'))),
    endDate: new Date(moment().add(1, 'month').format('YYYY-MM-DD'))
  }
  ngOnInit(): void {

    // Calculation Remainder
    this.orderService.pointsPaymentCheckout.calculateRemainder();

    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Shipping') + ' - ' + text);
    });
     // this.onCountryChanged();
    this.progressBarCheck();
    // Initialize countries array from rest api service
    // this.apiService.GetCountries().subscribe((data) => {
    //   this.countries = data.Data;
    // });
    this.selectedCountry = sessionStorage.getItem('selectedCountry') ||"US";
    //Regions of US
    this.apiService.GetRegions(this.selectedCountry?.toUpperCase()).subscribe((data) => {
      this.allowedRegions = data.Data;
      this.userService.defaultState = this.allowedRegions[0]?.RegionCode;
      this.userService.shippingAddress.StateName = this.allowedRegions.filter((state)=> { return state.RegionCode == this.userService.shippingAddress.StateName })[0]?.RegionCode || this.allowedRegions[0]?.RegionCode;
      localStorage.setItem('userService', JSON.stringify(this.userService));

      if((this.itemsService.selectedOrderItems.length + this.itemsService.selectedPacks.length) > 0) {
        this.orderService.calculateOrder();
      }
      if(this.itemsService.selectedAutoOrderItems.length > 0) {
        this.orderService.calculateAutoOrder();
      }
    });

    this.orderService.changeInOrder.subscribe((data)=>{
      this.progressBarCheck();
    })
  }

  barStyleObj = {'background-color': 'crimson'};
  childSpanContent = [];
  childtoShow = [];
  progressBarCheck() {
    this.sub = 0;
    this.remAmt = 250;
    this.getItems('order')?.forEach((item) => {
      this.sub += (item?.['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
    });
    // this.getItems('autoship').forEach((item) => {
    //   this.sub += (item?.['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
    // });
    this.sub >= 250 ? this.remAmt = 0 : this.remAmt = 250 - this.sub;
    this.freeShippingBar = 100 - ((this.remAmt / 250) * 100);
  }

  selectedCountryCode = "";
  countrySelected(e: any) {
    this.selectedCountryCode = e.value;

    
    this.apiService.GetRegions(this.selectedCountryCode.toUpperCase()).subscribe((data) => {
      this.allowedRegions = data.Data;
      this.user.userServiceModal.defaultState = this.allowedRegions[0]?.RegionCode;
    });

  }

  countPaymentMethods() {
    const paymentMethods = this.userService.paymentMethods;
    return paymentMethods ? paymentMethods.length : 0;
  }


  getQuantityModel(type, item): any {
    return this.cartService[type == 'pack' ? 'packQuantity' : (type == 'autoship' ? 'autoshipQuantity' : 'orderQuantity')];
  }

  getLastQuantity(type, item) {
    this.lastQuantity = this.getQuantityModel(type, item)[item.ItemCode];
  }

  checkQuantity(type, item) {
    const quantity = this.getQuantityModel(type, item)[item.ItemCode];
    if (!Number(quantity)) {
      this.cartService.removeFromCart(item, type == 'autoship', type == 'pack', true);
    } else {

      /** ------------------------------------------------
       * Resetting Points Calculation on Quantity changes
      ------------------------------------------------- */

      if(quantity != item.Quantity && type == 'order'){
        this.orderService.pointsPaymentCheckout.resetPointsCalculation();
      }

      /** ------------------------------------------------
       * Resetting Points Calculation on Quantity changes
      ------------------------------------------------- */

      item.Quantity = quantity;
      if (type == 'autoship') {
        localStorage.setItem('cart.autoship', JSON.stringify(this.itemsService.selectedAutoOrderItems));
        //this.orderService.calculateLocalAutoOrder();
        this.orderService.calculateAutoOrder();
      } else {
        localStorage.setItem((type == 'pack' ? 'cart.packs' : 'cart.order'),
          JSON.stringify(type == 'pack' ? this.itemsService.selectedPacks : this.itemsService.selectedOrderItems));
        //this.orderService.calculateLocalOrder();
        this.orderService.calculateOrder();
      }
    }
  }

  increaseQuantiy(type, item) {
    
    this.cartService.increaseQuantiy(item, type == 'autoship', type == 'pack');
    // this.progressBarCheck();
    }

  decreaseQuantiy(type, item) {
    this.cartService.decreaseQuantiy(item, type == 'autoship', type == 'pack');
    // this.progressBarCheck();
    }

  removeItem(item, type) {
    this.cartService.removeFromCart(item, type === 'autoship', type === 'pack', true);
    // this.progressBarCheck();
  }
  getItems(type: string) {
    return this.itemsService[type == 'pack' ? 'selectedPacks' : (type == 'autoship' ? 'selectedAutoOrderItems' : 'selectedOrderItems')].filter(item => item.ItemCode != 'DiscountCode');
  }
  getImage(item) {
    return item?.SmallImageUrl;
  }

  getDynamickitItems(itemId: any) {
    let country = sessionStorage.getItem('selectedCountry') || "us";
    let language = parseInt(sessionStorage.getItem('selectedLanguageID')) || 0;

    const request = {
      'itemCode': itemId,
      'country': country,
      'languageID': language
    }
    return this.apiService.getDynamicKitItems(request).subscribe({
      next: (result) => {
        if (parseInt(result.Status, 10) === 0) {
          this.dynamickitItems = result.Data;
        }
        else {
          this.kitItems = [];
        }
      }, error: (error) => {
        
      }
    });
  }


  @ViewChild('coupon') EnteredCoupon;
  verifyCoupon(coupon:string){
    if (this.verifiedCoupon != ''){
      this.notificationService.error('Error', 'YouAlreadyAppliedACoupon')
    } else if ( coupon === ''){
      this.notificationService.error("Error", "ApplyCoupon")
    } else{
      const req = {
        'couponcode' : coupon,
        'country': this.configService.commonData.selectedCountry || 'us',
        'priceType':  1
      }
      this.apiService.ApplyCoupon(req).subscribe({ next: data => {
        if(data.Data && data.Status == 0 ){
          this.verifiedCoupon = coupon;
          this.notificationService.success('success','CouponApplied');
          this.userService.couponInfo.promoCode = coupon;
          this.calculateOrder();
        } else {
          this.notificationService.error('Error', 'CouponIsNotValid')
        }
      }, error:(error) => {
        
      }
    })
    }
  }

  removeCoupon(option){
    this.verifiedCoupon = '';
    this.EnteredCoupon.nativeElement.value = '';
    this.userService.couponInfo.promoCode = '';
    this.notificationService.error("Error", "Coupon_Removed");
    this.userService.couponInfo.promoCode = '';
    this.calculateOrder();
  }

  calculateOrder(){
    this.userService.couponInfo.promoCode = this.verifiedCoupon;
    this.orderService.calculateOrder();
  }

  removeChild(i)
  {
    this.multipackquantity[i]=1;
     this.showChild[i] = false;
     this.barFill[i] = 10;
  }
  increaseChildQuantity(i)
  {
    this.multipackquantity[i]= this.multipackquantity[i]-1;
    this.showChild[i] = this.multipackquantity[i]>1;
    this.barFill[i] = this.multipackquantity[i]*25;
    if(this.barFill[i]==25)
    {
      this.barFill[i]=10;
    }
  }
  decreaseChildQuantity(i)
  {
    this.multipackquantity[i]= this.multipackquantity[i]+1;
     this.showChild[i] = this.multipackquantity[i]>1;
     this.barFill[i] = this.multipackquantity[i]*25;
  }
  goToCheckout(){
    if(this.itemsService.selectedPacks.length>0){
      this.router.navigate(["/enrollment/checkout"]);
      this.cartService.checkoutPaymentButton = false;
    }
    else{
      this.cartService.checkoutPaymentButton = true;
      if(this.accountService.isLoggedIn()) {
      this.router.navigate(["/review"]);
      } 
      else {
        this.router.navigate(["/checkout"]);
      }
    }
  }

  submitCheckout(){
    if(location.pathname.includes("review")){
      this.sharedService.sendReviewCheckout();
    } else {
      this.sharedService.sendSubmitCheckout();
    }
  }
  submitApplication(){
    this.sharedService.sendEnrollmentApplication();
  }
  getGroupItemPrice(item) {
    if (item != null && item.GroupMembers?.length > 0) {
      let filterItemPrice=0;
      item.GroupMembers.find((f: any) => {
        if (f.Item.ItemCode == item.ItemCode) {
          filterItemPrice= (f.Item['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
        }
      });
      return filterItemPrice;
    } else {
      return (item['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
    }
  }
  businessEntityClicked() {
    
    this.userService.personalInfo.showbusinessEntity = !this.userService.personalInfo.showbusinessEntity;
    if (this.userService.personalInfo.showbusinessEntity === true) {
      setTimeout(()=>{
      const target = $("#BusinessEntity");
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - 200,
        },
        300
      );
    },100);
    }
  }
  spouseOptionsClicked() {
    
    this.userService.personalInfo.showPartnerEntity = !this.userService.personalInfo.showPartnerEntity;
    this.user.showSpouceEntity.emit(this.userService.personalInfo.showPartnerEntity)
    if (this.userService.personalInfo.showPartnerEntity === true) {
      setTimeout(()=>{
      const target = $("#SpouseEntity");
      $("html, body").animate(
        {
          scrollTop: $(target).offset().top - 200,
        },
        300
      );
    },100);
    }
  }

  openDatePicker(){
    this._autoshipDatePicker && this._autoshipDatePicker.open();
  }

  FilterDates(date: Date) {
    const DatesToSkipped = ["28", "29", "30", "31"];
    if(DatesToSkipped.indexOf(new Date(date).getDate().toString()) > -1) {
      return false;
    }
    return true;
  }

  cancelAllowed(packItem) {

    // If Starter Kit
    if(this.itemsService.StarterKitItemsArray.findIndex(kitItemCode => kitItemCode == packItem.ItemCode) > -1) {
      return false;
    }

    return true;

  }

}


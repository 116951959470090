<app-breadcrumb [title]="'ContactUs'" [showCrumbs]="false"></app-breadcrumb>
<section>
    <div class="main" fxLayout="column" fxLayoutAlign="center none">
        <div class="content" fxLayout="column" fxLayoutAlign="space-around start" class="container mt-3">
            <div class="continents  " fxFlex="22" fxFlexOffset="3" fxLayout="column" fxLayoutAlign="space-evenly start" >
                <div class="countries" fxLayout="row wrap" fxFlex="layout-wrap" fxLayoutAlign="start stretch">
                    <div fxLayout="column" fxLayoutAlign="start start" fxFlex="100" >
                        <h1>North America Offices</h1>
                    </div>
                    <div class="country" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="33" fxFlex.md="32"
                        fxFill fxFlex.sm="48" fxFlex.xs="95">
                        <h5>United States</h5>
                        <p class="para">
                            <b>Rain International HQ</b><br />
                            1365 Business Park Dr Orem, UT 84058<br />
                            <b>Corporate Office:</b> 801-724-6606<br />
                            <b>Fax:</b> 855-265-7859<br />
                            <b>Office Hours:</b> Monday - Friday: 9am - 5pm MST<br />
                            <b>Customer Support:</b> 855-724-6606<br />
                            <b>Customer Support Hours:</b> Monday - Friday: 7am- 6pm Saturday: 10am- 2pm
                            MST<br />
                            <b translate="Email"></b> : <a href="mailto:support@rainintl.com">support@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxLayoutAlign="start  stretch" fxFlex="33" fxFlex.md="32"
                        fxFlex.sm="48" fxFlex.xs="95">
                        <h5>Mexico</h5>
                        <p class="para">
                            Av Armando Birlain Schaffler #2001. Mezanine, Centro Sur 76090 Queretaro QTO,
                            Mexico<br />
                            <b>Customer Support:</b>+52 558 526 4914<br />
                            <b>Office Hours:</b> Monday - Friday 7am - 7pm Tuesday open till 8pm Saturday: 10am
                            - 2pm
                            MST<br />
                            <b translate="Email"></b> : <a href="mailto:serviciomx@rainintl.com">serviciomx@rainintl.com</a><br />
                        </p>
                    </div>
                </div>
            </div>


            <div class="continents"  fxFlex="22"  fxLayout="column" fxLayoutAlign="space-evenly start">
                <h1>Asia Offices</h1>
                <div class="countries" fxLayout="row wrap" fxLayoutAlign="start stretch">
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Singapore</h5>
                        <p class="para">
                            2 Kallang Ave #06-10 CT Hub 329407 Singapore <br />
                            <b translate="PhoneNumber"></b> :  +65 6385 7249<br />
                            <b translate="Email"></b> : <a href="mailto:sgsupport@rainintl.com">sgsupport@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Philippines</h5>
                        <p class="para">
                            <b>Address:</b> RS-24 Rockwell Business Center Sheridan, Sheridan, Mandaluyong, 1554
                            Metro
                            Manila, Philippines<br />
                            <b translate="PhoneNumber"></b> :  +632 8634-9347<br />
                            <b>CS Lines:</b> +63 998-996-7248 &amp; +63 917-709-5648<br />
                            <b translate="Email"></b> : <a href="mailto:phsupport@rainintl.com">phsupport@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Taiwan</h5>
                        <p class="para">
                            9F, No.181, Fuxing N. Rd., Songshan Dist.,
                            Taipei City 105403 Taiwan (R.O.C.)<br />
                            105403 台北市松山區復興北路181號9樓<br />
                            <b translate="PhoneNumber"></b> :  +886227148909 (toll free)<br />
                            <b>Fax:</b> +886227189959<br />
                            <b translate="Email"></b> : <a href="mailto:twsupport@rainintl.com">twsupport@rainintl.com</a><br />
                        </p>
                    </div>

                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Malaysia</h5>
                        <p class="para">
                            Rosyvelle Worldwide Sdn Bhd (1132685-M)<br />
                            Unit 4-08-01, Level 8, Block 4, VSQ &#64; PJ City Centre<br />
                            Jalan Utara, 48200 Petaling Jaya, Selangor, Malaysia<br />
                            <b translate="PhoneNumber"></b> :  +603 3210 0660<br />
                            <b translate="Email"></b> : <a href="mailto:mysupport@rainintl.com">mysupport@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Hong Kong</h5>
                        <p class="para">
                            Unit 1015, Peninsula Centre, 67 Mody Road, Tsim Sha Tsui, Kowloon Hong Kong<br />
                            香港九龍尖東麼地道67號半島中心1015室 <br />
                            <b translate="PhoneNumber"> / 電話:</b> +852 3707 1600 <br />
                            <b translate="Email"> / 電郵:</b> <a href="mailto:hksupport@rainintl.com">hksupport@rainintl.com</a>
                            <br />
                        </p>
                    </div>
                </div>
            </div>


            <div class="continents"  fxFlex="22"  fxLayout="column" fxLayoutAlign="space-evenly start">
                <h1>Africa Offices</h1>
                <div class="countries" fxLayout="row wrap" fxLayoutAlign="start stretch">
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Nigeria</h5>
                        <p class="para">
                            6 Tola Adewunmi Street, TAAT Estate, Maryland, Lagos<br />
                            <b translate="PhoneNumber"></b> :  +234 809 441 1900<br />
                            <b translate="PhoneNumber"></b> :  +234 708 970 6016<br />
                            <b translate="PhoneNumber"></b> :  +234 812 173 8381<br />
                            <b>Office Hours:</b> Monday - Friday:9am to 6pm Saturday: 10am to 2pm<br />
                            <b translate="Email"></b> : <a
                                href="mailto:rainsuccessnigeria@rainintl.com">rainsuccessnigeria@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Ghana-Tema</h5>
                        <p class="para">
                            Near Jehovah Witness Church, Lashibi, Tema, Greater Accra, Ghana<br />
                            <b translate="PhoneNumber"></b> :  +232 59 488 8481<br />
                            <b>Office Hours:</b> Monday - Friday:8am to 5pm<br />
                            <b translate="Email"></b> : <a
                                href="mailto:rainsuccessghanaltd@rainintl.com">rainsuccessghanaltd@rainintl.com</a><br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Ghana-Accra</h5>
                        <p class="para">
                            Accra Annex, Number 7 Anyemi Kpakpa Rd, Asylum Down Roundabout, Beside Amasan TV (ATV),
                            Accra
                            (Digital Address: GA-048-2497)<br />
                            <b translate="PhoneNumber"></b> :  +232 59 488 8481<br />
                            <b>Office Hours:</b> Monday - Friday:8am to 5pm<br />
                            <b translate="Email"></b> : <a
                                href="mailto:rainsuccessghanaltd@rainintl.com">rainsuccessghanaltd@rainintl.com</a><br />
                        </p>
                    </div>

                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Namibia</h5>
                        <p class="para">
                            142 Jan Jonker road Ausspanplatz , Windhoek, Namibia<br />
                            <b>Pickup Hours:</b> Monday - Thursday 8am to 5pm, Friday 9am to 6pm, Every 2nd Saturday
                            10am to 1pm<br />
                            <b translate="PhoneNumber"></b> :  264 813248869<br />
                            <b translate="Email"></b> : <a href="mailto:admin.nam@rainafricahub.com">admin.nam@rainafricahub.com</a>
                            <br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Botswana</h5>
                        <p class="para">
                            MVA Building Ground floor Fairground Office Park Plot 48367, Gaborone Botswana<br />
                            <b>Pickup Hours:</b> Monday- Thursday 9am to 5pm, Friday 10am to 6pm, Every 2nd Saturday 9am
                            to 1pm<br />
                            <b translate="PhoneNumber"></b> :  267 74702917<br />
                            <b translate="Email"></b> : <a href="mailto:admin.bots@rainafricahub.com">admin.bots@rainafricahub.com</a>
                            <br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>South Africa</h5>
                        <p class="para">
                            175 Queens Street Kensington Johannesburg 2092<br />
                            <b>Pickup Hours:</b> Monday - Thursday 10am to 5:32pm, Friday 10am to 6pm, Saturday 9am to
                            1pm<br />
                            <b translate="PhoneNumber"></b> :  27 738486721, 27 710428032<br />
                            <b translate="Email"></b> : <a href="mailto:admin.za@rainafricahub.com">admin.za@rainafricahub.com</a>
                            <br />
                        </p>
                    </div>
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h4>Courier Instructions for: </h4>
                        <h5>Botswana, Namibia and South Africa</h5>
                        <p class="bold_para">
                            <b>1. Send via email your order number.<br />
                                2. The address of the person receiving the order<br />
                                3. The phone number of the person receiving the order<br />
                                4. Proof of payment for the courier which 165 rands for 5 boxes or 10kgs. This is
                                anywhere in south africa.<br />
                            </b>
                        </p>
                </div>
                </div>
            </div>


            <div class="continents"  fxFlex="22" fxLayout="column" fxLayoutAlign="space-evenly start">
                <h1>Europe Offices</h1>
                <div class="countries" fxLayout="row wrap" fxLayoutAlign="start stretch">
                    <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>United Kingdom</h5>
                        <p class="para">
                            <b translate="PhoneNumber"></b> : +44 207 660 1513<br />
                            <b>Office Hours:</b> Call Center Open Monday to Friday 2:00 pm to 2:00 am, Saturday 5:00 pm
                            to 9:00 pm <br />
                            <b translate="Email"></b> : <a href="mailto:unitedkingdom@rainintl.com">unitedkingdom@rainintl.com</a>
                            <br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Hungary</h5>
                        <p class="para">
                            <b>Address:</b> Istenhegyi út 40, Budapest, Hungary, 1126<br />
                            <b translate="PhoneNumber"></b> :  +36 1 800 9363<br />
                            <b>Office Hours:</b> Call Center Monday to Friday 09:00 am to 5:00 pm (CET) <br />
                            <b translate="Email"></b> : <a href="mailto:ugyfelszolgalat@rainintl.com">ugyfelszolgalat@rainintl.com</a>
                            <br />
                            <b>Telegram:</b> <a href="https://t.me/RainNewsRP_HUN">RainNewsRP_HUN</a><br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Romania</h5>
                        <p class="para">
                            <b translate="PhoneNumber"></b> :  +40326326693, +3618009362 <br />
                            <b>Call Center Hours:</b> Monday to Friday 10:00 am to 6:00 pm (EET)<br />
                            <b translate="Email"></b> : <a href="mailto:serviciiclienti@rainintl.com">serviciiclienti@rainintl.com</a>
                            <br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Slovakia</h5>
                        <p class="para">
                            <b translate="PhoneNumber"></b> :  +3618009364<br />
                            <b>Customer Support:</b> Monday to Friday 09:00 am to 5:00 pm (CET)<br />
                            <b translate="Email"></b> : <a href="mailto:slovakia@rainintl.com">slovakia@rainintl.com</a><br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Croatia</h5>
                        <p class="para">
                            <b>Customer Support Hours:</b> Monday to Friday 10:00 am to 6:00 pm (CET)<br />
                            <b translate="PhoneNumber"></b> :  +385 91 610 6006<br />
                            <b translate="Email"></b> : <a href="mailto:croatia@rainintl.com">croatia@rainintl.com</a>
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Slovenia</h5>
                        <p class="para">
                            <b>Customer Support Hours:</b> Monday to Friday 10:00 am to 6:00 pm (CET) <br />
                            <b translate="PhoneNumber"></b> :  +3618009361<br />
                            <b translate="Email"></b> : <a href="mailto:slovenia@rainintl.com">slovenia@rainintl.com</a><br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Serbia</h5>
                        <p class="para">
                            <b>Corporate Office:</b><br />
                            Rain Soul International d.o.o.<br />
                            Ulica Novosadskog Sajma 7-9<br />
                            Novi Sad 2950<br />
                            <b>Customer Support Hours:</b> Monday to Friday 10:00 am to 6:00 pm (CET)<br />
                            <b translate="PhoneNumber"></b> :  +38121548555<br />
                            <b translate="Email"></b> : <a href="mailto:serbia@rainintl.com">serbia@rainintl.com </a>
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Bosnia and Herzegovina</h5>
                        <p class="para">
                            <b>Corporate Office:</b><br />
                            Rain International d.o.o. BiH<br />
                            Aleja Bosne Srebrene 125<br />
                            71210 Sarajevo<br />
                            <b>Customer Support Hours:</b> Monday to Friday 10:00 am to 6:00 pm (CET)<br />
                            <b translate="PhoneNumber"></b> :  +387 33 870 443, +387 33 863 953<br />
                            <b translate="Email"></b> : <a href="mailto:bih@rainintl.com">bih@rainintl.com</a>
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Austria, Germany, Switzerland</h5>
                        <p class="para">
                            <b>Customer Support Hours:</b> Monday to Friday 10:00 am to 6:00 pm (CET)<br />
                            <b *ngIf="false"><b translate="PhoneNumber"></b> :  +49067219889570, +49015228598510<br /></b>
                            <b>Telegram:</b> <a href="https://t.me/rainintldach">rainintldach</a><br />
                            <b translate="Email"></b> : <a href="mailto:germany@rainintl.com">germany@rainintl.com</a>
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Ukraine</h5>
                        <p class="para">
                            Kyiv, Antonivycha 25v<br />
                            <b>Corporate Office:</b> +38096 096 81 34 <br />
                            <b>Office Hours:</b> Tuesday - Friday 12:00 pm to 8:00 pm Saturday 11:00 am to 6:00 pm
                            (CET)<br />
                            <b>Customer Support:</b> +38096 096 81 34 <br />
                            <b>Customer Support Hours:</b> Monday - Friday 12:00 pm to 8:00 pm (CET) <br />
                            <b translate="Email"></b> : <a href="mailto:ukraine@rainintl.com">ukraine@rainintl.com</a><br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Russia</h5>
                        <p class="para">
                            ООО «РЕЙН ИНТЕРНАЦИОНАЛ»<br />
                            OGRN 1187748863217, INN 979569282<br />

                            <b>Corporate Office:</b> 124489, Russia, Moscow, Tverskaya street, 18, bld. 1, floor 6,
                            office 622 <br />
                            <b>Office Hours:</b> Tuesday - Friday 9:00 am to 6:00 pm<br />
                            <b>Customer Support:</b> +79164429574 <br />
                            <b>Customer Support Hours:</b> Monday - Friday 12:00 pm to 8:00 pm (CET) <br />
                            <b translate="Email"></b> : <a href="mailto:russiacorp@rainintl.com">russiacorp@rainintl.com</a><br />
                        </p>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>Italy</h5>
                        <p class="para">
                            Rain International S.R.L Via A. Sabatini,<br>
                             n° 32/f
                            51010 Massa e Cozzile (PT) - Italia.<br />
                            Partita IVA e C.F.: IT09326610966
                            Capitale Sociale <br />
                            € 10.000,00 I.V. Reg. Imprese PT 09326610966<br />
                            <b translate="PhoneNumber"></b> :  +39 348 557 6760<br />
                            <b translate="Email"></b> : <a href="mailto:italy@rainintl.com">italy@rainintl.com</a>
                    </div>
                     <div class="country" fxLayout="column" fxFlex=" 33" fxFlex.md=" 32" fxFlex.sm=" 48" fxFlex.xs="95">
                        <h5>The Netherlands, Belgium, Ireland, Norway, Sweden, Denmark, Finland
                        </h5>
                        <p class="para">
                            <b>Rain International B.V Hensmoveien 11, 3516 Hønefoss. Norway</b><br />
                            <b>Office Hours:</b> Monday to Friday 08:00 am to 04:00 pm (CET) <br />
                            <b>Call Center Hours:</b> Monday to Friday 01:00 pm to 04:00 pm (CET)<br />
                            <b translate="Email"></b> : <a href="mailto:netherlands@rainintl.com">netherlands@rainintl.com</a><br />
                            <b translate="Email"></b> : <a href="mailto:norway@rainintl.com">norway@rainintl.com</a><br />
                            <b translate="PhoneNumber"></b> :  +47 47798000<br />
                        </p>
                    </div>

                </div>
            </div>


        </div>

    </div>
</section>

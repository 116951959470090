<div class="fixed">
  <ng-container *ngIf="!accountService.isLoggedIn()">
    <app-top-header></app-top-header>
  </ng-container>
  <app-header></app-header>
</div>
<div [ngClass]="!accountService.isLoggedIn() ? 'm-140' : 'm-100'">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<app-my-loader></app-my-loader>


<!-- cookie popup -->
<div class="cookie-box" fxLayout="column" *ngIf="isCookieSelected == false">
  <h1 class="cookie-title" [textContent]="'CookieDeclaration_Title' | translate"></h1>
  <p class="cookie-body" [textContent]="'CookieDeclaration_Body' | translate"></p>
  <div fxLayoutAlign="start center">
    <button mat-stroked-button class="cookie-btn" translate="Accept" (click)="cookiesAccepted()"></button>
  </div>
</div>

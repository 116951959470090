import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private cookieService: CookieService,
    public user: UserService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {
     //console.log('Im IN AUth Gaurd');
    if (this.cookieService.get('X-Auth')) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']).then(() => {
      if (window.sessionStorage && window.localStorage) {
        window.localStorage.removeItem('user');
        const cookieAcceptedStatus = this.cookieService.get('cookieAccepted') || "not-accepted";
        this.cookieService.deleteAll('/');
        if(cookieAcceptedStatus != "not-accepted") {
          this.cookieService.set("cookieAccepted", "true", undefined, "/")
        }
        sessionStorage.clear();
        window.localStorage.clear();
        this.user.init();
      }
      window.location.reload();
    });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | Observable<boolean> | Promise<boolean> {
      //console.log('Im IN AUth Gaurd canActivateChild');
    return this.canActivate(route, state);

  }
}

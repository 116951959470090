import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/authentication/account.service';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { CartService } from 'src/app/core/services/cart.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string = 'Home';
  @Input() breadcrumb: Array<{}> = [
    { path1: '', route1: '' },
    { path2: '', route2: '' },
    { path3: '', route3: '' },
  ];
  @Input() showCrumbs: boolean;
  @Input() showAccountMenu: boolean = false;
  userService: UserServiceModal;
  constructor(
    public apiService: RestApiService,
    public cartService: CartService,
    public router: Router,
    public sharedSevices: SharedService,
    public accountService: AccountService,
    public user: UserService
  ) {
    this.userService = user.userServiceModal;
  }

  ngOnInit(): void {}
  userAccountsDetail(type: any) {
    this.router.navigate([type]);
    if (type == 'accounts/shipping') {
    }
  }

  navigateLink(path: string) {
    switch (path) {
      case 'settings':
        this.router.navigate(['/accounts/settings']);
        break;
      case 'orders':
        this.router.navigate(['/accounts/orders']);
        break;
      case 'subscription':
        this.router.navigate(['/accounts/auto-order-list']);
        break;
      case 'address':
        this.router.navigate(['/accounts/address']);
        break;
      case 'avatar':
        this.router.navigate(['/accounts/avatar']);
        break;
      case 'paymentmethods':
        this.router.navigate(['/accounts/paymentmethods']);
        break;
      case 'notifications':
        this.router.navigate(['/accounts/notifications']);
        break;
      case 'rewards':
        this.router.navigate(['/accounts/rewards']);
        break;
      case 'logout':
        this.accountService.logout();
        break;
      default:
        break;
    }
  }
}
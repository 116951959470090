
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { UserService } from 'src/app/core/services/user.service';
export class ConfirmDialogModel {
  constructor(public title: string, public message: string,public noaction?: string, public takeaction?: string, public customdata?: any) {
  }
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  userService: UserServiceModal;
  title: string;
  message: string;
  noaction: string;
  takeaction: string;
  customdata: any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,public user: UserService,
  ) {
    this.userService = user.userServiceModal;
    this.title = data.title;
    this.message = data.message;
    this.noaction = data.noaction;
    this.takeaction = data.takeaction;
    this.customdata = data?.customdata;
    console.log('item',this.customdata);
  }

  ngOnInit() {

  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

<div class="WordSection1">
    <div fxLayout="row" class="ms-padding">
        <div fxFlex="100" fxLayoutAlign="space-between center">
            <mat-icon class="close-icon f-s-30 cursor-pointer" (click)="printPdf($event)" mat-button>print</mat-icon>
            <mat-icon mat-button mat-dialog-close class="close-icon cursor-pointer"
                (click)="matDialogClose()">close</mat-icon>
        </div>
    </div>
    <div id="enrollAgreement_en" #enrollAgreement_en
        [hidden]="!(selectedLanguage.CultureCode=='en' && enrollAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>DIRECT SELLER AGREEMENT</strong></p>
            <p>This agreement is signed by and between {{enrollmentservice.shippingAddress.FirstName}}
                {{enrollmentservice.shippingAddress.LastName}} residing at {{enrollmentservice.shippingAddress.Street1}}
                {{enrollmentservice.shippingAddress.Street2}} {{enrollmentservice.shippingAddress.City}}
                {{enrollmentservice.shippingAddress.StateName}} {{enrollmentservice.shippingAddress.CountryCode}} with
                the Turkish ID number/Trade Registry Number {{enrollmentservice.webOffice.TaxID}} (Hereinafter to be
                shortly referred to
                as the “Rain Partner”) and Rain International Beslenme Anonim Şirketi, residing at Necip Fazıl
                Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul
                (Hereinafter to be shortly referred to as the “Rain Türkiye”) regarding to be a direct seller of the
                Rain Türkiye with the following terms and conditions on {{date}}. Rain International Policies and
                Procedures and the Rain International Compensation Plan, both of which are incorporated into and
                made a part of this agreement shall be collectively referred to as the “Agreement”. Rain Türkiye, a
                Turkish Corporation and a member firm of the Rain International global organization and with this
                agreement, Rain Türkiye has the right to sell Rain International products in terms of this agreement
                and other Rain International policies. So, the policies, procedures and all of the rules of the Rain
                International which are not in conflict with compulsory regulations of Turkish law will be valid and
                in force in the relationship between parties.</p>
            <p>For avoidance of the doubt, this agreement will be valid with the approval of the Rain Türkiye. In
                case Rain Türkiye will approve or not approve the application at its sole discretion. </p>
            <p>Rain Türkiye and Rain Partner shall hereinafter be shortly referred to collectively as the “Parties”.
            </p>
            <p>
                01. Rain Partner understands that as a Rain Türkiye Rain Partner:
                a. Rain Partner has the right to offer for sale Rain International products and services in
                accordance with these Terms and Conditions.
                b. Rain Partner has the right to enroll persons in Rain Türkiye (with approval of Rain Trükiye).
                c. If qualified, Rain Partner has the right to earn commissions pursuant to the Rain International
                Marketing and Compensation Plan

                Rain Partner understands and undertakes that, s/he will comply with the compulsory regulations of
                Turkish Law and provide to draw off-premises contract and preliminary information form properly
                which can be found in the annex of this agreement with the end consumer and deliver these documents
                to the end consumer in conformity with the law while s/he is offering for sale Rain International
                products. Otherwise, s/he will be liable for all kind of damages and administrative fines which Rain
                Türkiye may incur.
            </p>
            <p>02. Rain Partner agrees to present the Rain International Marketing and Compensation Plan and Rain
                International products and services as set forth in official Rain International literature. Rain
                Partner agrees that s/he may not re-label, repackage, or otherwise alter any Product, nor will s/he
                sell any Rain International Product under any other name.</p>
            <p>03. Rain Partner agrees that as a Rain Türkiye Rain Partner s/he is an independent contractor, and
                not an employee, partner, legal representative, or franchisee of Rain Türkiye. Within the framework
                of the Agreement, Rain Partner does not take instructions, s/he free to plan his/her business
                activities, if any, s/he can choose working hours and preferred sales methods. Rain Partner agrees
                that s/he will be solely responsible for paying all expenses incurred by himself/herself, including
                but not limited to travel, food, lodging, secretarial, office, long distance telephone and other
                expenses. RAIN PARTNER UNDERSTAND THAT S/HE SHALL NOT BE TREATED AS AN EMPLOYEE OF RAIN TÜRKİYE FOR
                ANY TAX PURPOSES OR ANY OTHER PURPOSES. Rain Türkiye is not responsible for withholding, and shall
                not withhold or deduct from his/her bonuses and commissions, if any, taxes of any kind.</p>
            <p>04. Rain Partner has carefully read and agree to comply with the Rain International Policies and
                Procedures and the Rain International Compensation Plan, both of which are incorporated into and
                made a part of these Terms and Conditions (these three documents shall be collectively referred to
                as the “Agreement”). The Policies and Procedures and/or Marketing and Compensation Plan are posted
                at www.rainintl.com/policies, and are also in Rain International Business Kit, and in Rain
                International Back Office. </p>
            <p>Rain Partner understands that s/he must be in good standing, and not in violation of the Agreement,
                to be eligible for bonuses or commissions from Rain Türkiye. Rain Partner understands that the
                Agreement may be amended at the sole discretion of Rain Türkiye, and s/he agrees to abide by all
                such amendments. Notification of amendments shall be posted on the Rain Türkiye website or in Rain
                Türkiye Back Office. Amendments shall become effective thirty (30) days after publication, but any
                such amendment shall not apply retroactively to conduct that occurred prior to the effective date of
                the amendment. The continuation of his/her Rain Türkiye business or his/her acceptance of bonuses or
                commissions after the effective date of any amendment shall constitute his/her acceptance of any and
                all amendments.</p>
            <p>05. The term of this Agreement is one year (subject to prior cancellation as provided in the Policies
                and Procedures). If Rain Partner fail to annually renew his/her Rain Türkiye business, or if it is
                canceled or terminated for any reason, Rain Partner understands that s/he will permanently lose all
                rights as a Rain Partner. Rain Partner shall not be eligible to sell Rain International products and
                services nor shall be eligible to receive commissions, bonuses, or other income resulting from the
                activities of his/her former downline sales organization. In the event of cancellation, termination
                or nonrenewal, s/he waives all rights s/he have, including but not limited to property rights, to
                his/her former downline organization and to any bonuses, commissions or other remuneration derived
                through the sales and other activities of his/her former downline organization. Rain Türkiye
                reserves the right to terminate all Rain Partner Agreements upon 30 days’ notice if Rain Türkiye
                elects to: (1) cease business operations; (2) dissolve as a business entity; or (3) terminate
                distribution of its products and/or services via direct selling channels. Rain Partner may cancel
                this Agreement at any time, and for any reason, upon written notice to Rain Türkiye. Rain Türkiye
                may cancel this Agreement for any reason upon 30 days advance written notice to Rain Partner.</p>
            <p>06. Rain Partner may not assign any rights or delegate his/her duties under the Agreement without the
                prior written consent of Rain Türkiye. Any attempt to transfer or assign the Agreement without the
                express written consent of Rain Türkiye renders the Agreement voidable at the option of Rain Türkiye
                and may result in termination of his/her business.</p>
            <p>07. Rain Partner understands that if s/he fail to comply with the terms of the Agreement, Rain
                Türkiye may, at its discretion, impose upon him/her disciplinary action as set forth in the Policies
                and Procedures. If s/he is in breach, default or violation of the Agreement at termination, s/he
                shall not be entitled to receive any further bonuses or commissions, whether or not the sales for
                such bonuses or commissions have been completed. Rain Partner agree that Rain Türkiye may deduct,
                withhold, set-off, or charge to any form of payment s/he have previously authorized, any amounts
                s/he owe or is indebted to Rain Rain Türkiye.</p>
            <p>08. Rain Türkiye, its parent or affiliated companies, directors, officers, shareholders, employees,
                assigns, and agents (collectively referred to as “affiliates”), shall not be liable for, and Rain
                Partner release and hold harmless Rain Türkiye and Rain International and its affiliates from, all
                claims for consequential and exemplary damages for any claim or cause of action relating to the
                Agreement. </p>
            <p>Rain Partner further agree to release and hold harmless Rain Türkiye and its affiliates from all
                liability arising from or relating to: (a) his/her breach of this Agreement; (b) the promotion or
                operation of his/her Rain business and any activities related to it (including but not limited to,
                the presentation of Rain products or the Rain International Marketing and Compensation Plan, the
                operation of a motor vehicle, the lease of meeting or training facilities, etc.), and agrees to
                indemnify Rain Türkiye for any liability, damages, fines, penalties, or other awards arising from
                any unauthorized conduct that s/he undertakes in operating his/her business.</p>
            <p>09. The Agreement, in its current form and as amended by Rain Türkiye at its discretion, constitutes
                the entire contract between Rain Türkiye and Rain Partner. Any promises, representations, offers, or
                other communications not expressly set forth in the Agreement are of no force or effect.</p>
            <p>10. Any waiver by Rain Türkiye of any breach of the Agreement must be in writing and signed by an
                authorized officer of Rain Türkiye. Waiver by Rain Türkiye of any breach of the Agreement by Rain
                Partner shall not operate or be construed as a waiver of any subsequent breach.</p>
            <p>11.For the avoidance of doubt, for each order of the Rain Partner, a distance agreement will be
                drafted between parties and the rights and obligations of that distance agreement will be prevail.
                If any provision of the Agreement is held to be invalid or unenforceable, such provision shall be
                reformed only to the extent necessary to make it enforceable, and the balance of the Agreement will
                remain in full force and effect. In case there will a conflict between the Agreement and compulsory
                legal rules (Law No. 6502 on the Protection of the Consumer, Regulation on Off-Premises Contract,
                Regulation on Distance Agreements etc.) which are in force in Türkiye or Distance Agreements which
                will be formed for each order, compulsory legal rules and terms and conditions of Distance
                Agreements will prevail. </p>
            <p>In the event the Rain Partner is a merchant real or legal person and/or if invoice for the product is
                issued in the name of merchant Rain Partner; Rain Partner is not entitled to take advantage of
                consumer rights and the provisions under the Contract and Law, which confers exclusive rights and
                means on consumers.</p>
            <p>12.This Agreement will be governed by and construed in accordance with the laws of the Republic of
                Türkiye without regard to principles of conflicts of laws. In the event of a dispute between a Rain
                Partner and Rain Türkiye arising from or relating to the Agreement, or the rights and obligations of
                either party, the parties shall attempt in good faith to resolve the dispute through nonbinding
                mediation as more fully described in the Policies and Procedures. Rain Türkiye shall not be
                obligated to engage in mediation as a prerequisite to disciplinary action against a Rain Partner.
            </p>
            <p>13. Notwithstanding the foregoing, either party may bring an action before the courts seeking a
                restraining order, temporary or permanent injunction, or other equitable relief to protect its
                intellectual property rights, including but not limited to customer and/or Rain Partner lists as
                well as other trade secrets, trademarks, trade names, patents, and copyrights. </p>
            <p>14. A participant in this multilevel marketing plan has a right to cancel, without a penalty and
                regardless of reason. Cancellation must be submitted in writing to the company. In case Rain Partner
                cancels or terminates this Agreement within 60 days starting from the date of involving the system,
                Rain Türkiye will accept return requests of the Rain Partner for unused and resalable products and
                return the paid amount within 14 days.</p>
            <p>15. If a Rain Partner wishes to bring an action against Rain Türkiye for any act or omission relating
                to or arising from the Agreement, such action must be brought within one year from the date of the
                alleged conduct giving rise to the cause of action. Failure to bring such action within such time
                shall bar all claims against Rain Türkiye for such act or omission. Rain Partner waives all claims
                that any other statutes of limitations apply.</p>
            <p>16. Rain Partner authorizes Rain Türkiye to use his/her name, photograph, personal story and/or
                likeness in advertising or promotional materials and waive all claims for remuneration for such use.
            </p>
            <p>17. A faxed copy of the Agreement shall be treated as an original in all respects.</p>
            <p>18. Rain Partner declares and guarantee Rain Türkiye that s/he, before signing the Agreement, has
                read and fully understood the entire Agreement.</p>
            <p>19. Rain Partner declares and guarantees Rain Türkiye that s/he fully capable of reading, speaking
                and understanding Turkish. Turkish is and will be the only language used between parties (including
                but not limited to: negotiating and concluding this Agreement, all commercial communication, and any
                mediation, arbitration or litigation). The Turkish language version of the Agreement shall govern.
            </p>
            <p>20. All written notices to the Company should be delivered to Necip Fazıl Mahallesi, Kemerdere Cami
                Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul</p>
            <p>21. Rain Partner consents to the processing of his/her personal data by Rain Türkiye, for carrying
                out his/her orders, for administrative purposes, for statistics, as well as for marketing purposes.
                Rain Türkiye will process any personal data in accordance with its Privacy Policy as published on
                its website (<a href="https://rain-policies.s3.amazonaws.com/RAIN+Privacy+Policy+Revised+2-1-2022.pdf"
                    target="_blank">https://rain-policies.s3.amazonaws.com/RAIN+Privacy+Policy+Revised+2-1-2022.pdf</a>).
            </p>
            <p>22. PREFERRED CUSTOMER ELECTION TO BECOME AN INDEPENDENT RAIN PARTNER: If Rain Partner is a Preferred
                Customer, s/he agrees and understands that if s/he subsequently elect to become an Independent Rain
                Partner, s/he will be sponsored by the Rain Partner who has enrolled him/her as a Preferred
                Customer. </p>
            <p>In the event that s/he desires to be sponsored by a different Rain Partner, s/he must submit a
                written request to the Company for a change of Sponsor prior to the submission of his/her
                Independent Rain Partner Application and Agreement. Changes of Sponsor are highly discouraged and
                rarely permitted. Unless Rain Partner receives written approval from Rain Türkiye to change his/her
                sponsor, his/her request will be deemed to be denied.</p>
            <p><br>ANNEX :</p>
            <p>
                - Template of Off-Premises Contract (Annex-1)
                <br>- Preliminary Information Form (Annex – 2)
            </p>
            <p></p>
            <p>
                <br><br>Rain Turkey
                <br>Rain International Beslenme A.Ş.
            </p>
            <p>
                <br><br>Rain Partner
                <br>{{enrollmentservice.shippingAddress.FirstName}} {{enrollmentservice.shippingAddress.LastName}}
            </p>
            <p><strong>ANNEX - 1</strong><br></p>
            <p style="text-align: center;"><strong>OFF-PREMISES CONTRACT</strong></p>
            <p>
                The Direct Seller is obliged to provide a copy of this Off-Premises Contract to the Purchaser at the
                time the agreement is concluded. A copy of the agreement remains with the Seller, a copy is given to
                the Purchaser, and the original with wet-ink signature is send at the address of RAIN INTERNATIONAL
                BESLENME JOINT STOCK COMPANY, Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D
                İç Kapı No: 7 Ümraniye/İstanbul. The Direct seller, must ensure that the Purchaser writes and signs
                the statements of “I received a copy of the Agreement by hand.”, and for agreements where the right
                of withdrawal can be exercised, “I have been informed about I have the right to withdraw within
                fourteen days.” in his own handwriting with agreement date.
                The subject of this Agreement is the sale of the goods with the following terms and conditions to
                the Purchaser by the Direct Seller, and the relevant Agreement has been signed in accordance with
                the provisions of the Law No. 6502 on the Protection of the Consumer and Regulation on Off-Premises
                Contract.
            </p>
            <p><strong>1. Parties</strong></p>
            <div class="row">
                <div class="col-6">
                    <p><strong>Information of Seller</strong></p>
                    <p>Title: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ</p>
                    <p>Mersis No: 0384051355400001</p>
                    <p>Address: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                        Ümraniye/İstanbul</p>
                    <p>Phone: 0 850 340 7880</p>
                    <p>E-mail: tr@rainintl.com</p>
                </div>
                <div class="col-6">
                    <p><strong>Information of Purchaser/Consumer</strong></p>
                    <p>Name-Surname / Title:</p>
                    <p>Address:</p>
                    <p>Phone:</p>
                    <p>E-mail:</p>
                </div>
            </div>
            <p>
                <strong>Information of Direct Seller: </strong>
                <br><strong>Name and Surname of Direct Seller:</strong>
                <br><strong>Number of Direct Seller:</strong>
                <br><strong>Address:</strong>
                <br><strong>Phone:</strong>
                <br><strong>E-mail:</strong>
            </p>
            <p><strong>2. PURCHASED PRODUCT(S) SUBJECT TO AGREEMENT </strong></p>
            <table style="border-collapse: collapse; text-align: center;" class="table table-bordered">
                <thead style="vertical-align:bottom;border-bottom-width:2px;">
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">PRODUCT NAME</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">BRAND</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">MODEL/SERIAL</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">NUMBER</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">UNIT PRICE</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">SUBTOTAL (VAT INCLUDED)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            RAIN SOUL Liquid Supplement Containing Black Seed, Raspberry and Grape Seed</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            RAIN SOUL RED
                            Liquid Supplement Containing Black Seed, Raspberry and Resveratrol
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            SHIPPING COST</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            colspan="5">TOTAL</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                </tbody>
            </table>
            <p><strong>3. NOTICE OF WITHDRAWAL</strong></p>
            <p style="font-size: 18px; border: 1px solid #000;"><strong>Purchaser has the right to withdrawal within
                    fourteen days from the date of conclusion of this agreement or the date of receipt of the goods
                    without giving any reason and without paying any penalty. Seller undertakes that not to ask
                    Purchaser to pay under any name or submit any document that puts Purchaser in debt in return for
                    the goods or services subject to the agreement within the withdrawal period, and that Seller
                    will take back the goods within fourteen days at the latest from the date on which the
                    withdrawal notification is received by Seller.</strong></p>
            <p>
                It will be sufficient for the Purchaser to send the notification that the right to withdraw has been
                exercised to the Seller in writing or by e-mail before the expiry of the right of withdrawal. While
                the exercising of the right of withdrawal, the Purchaser may use the form (Annex-1) attached to this
                Agreement or make an explicit statement of the withdrawal decision. The right of withdrawal period
                starts on the day when the Purchaser or the third party determined by the Purchaser receives the
                goods in the agreements for the delivery of goods. However, the Purchaser also may use his right of
                withdrawal only within the period from the conclusion of the agreement to the delivery of the goods.
                <br>In the determination of the right of withdrawal period;
            </p>
            <ul style="margin-left:1rem;">
                <li>For the goods that are the subject of a single order and delivered separately, the day on which
                    the Purchaser or the third party determined by the Purchaser receives the last good, </li>
                <li>For goods consisting of more than one piece, the day on which the Purchaser or the third party
                    determined by the Purchaser receives the last piece,</li>
                <li>For agreements where the goods are delivered regularly for a certain period of time, the day on
                    which the Purchaser or the third party determined by the Purchaser receives the first good is
                    taken as a basis.</li>
            </ul>
            <br>The delivery of the goods by the Seller to the carrier is not considered as a delivery to the
            Purchaser.
            <p></p>
            <p><strong>4. INFORMATION OF DELIVERY AND PAYMENT </strong></p>
            <p>
                Method and Plan of Payment:
                <br>Account Information for Payment:
                <br>Delivery Address:
                <br>Person to be Delivered:
                <br>Invoice Address:
                <br>Order Date:
                <br>Delivery Date:
                <br>Way of Delivery:
            </p>
            <p><strong>5. THE ADDRESS WITHDRAWAL NOTIFICATION WILL BE MADE</strong></p>
            <p>To Seller’s e-mail address of tr@rainintl.com or headquarters address Necip Fazıl Mahallesi,
                Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul.</p>
            <p><strong>6. EXCEPTIONS FOR WHICH THE RIGHT OF WITHDRAWAL IS NOT ALLOWED</strong></p>
            <p>
                Unless otherwise agreed by the parties, the Purchaser shall have no right to withdrawal in the
                following agreements:
                <br><strong>6.1.</strong> Agreements related to the products which is prepared in accordance with
                the consumer’s needs or personal requests,
                <br><strong>6.2.</strong> Agreements related to the delivery of goods that may deteriorate quickly
                or expire,
                <br><strong>6.3.</strong> Agreements for the delivery of products whose protective elements such as
                packaging, tape, seal, package have been opened after the delivery and their return is not suitable
                for health and hygiene,
                <br><strong>6.4.</strong> Agreements related to books, digital content and computer consumables
                presented in tangible environment in case protective elements such as packaging, tape, seal, package
                are opened after delivery of the goods,
                <br><strong>6.5.</strong> Agreements for services which are executed with the approval of the
                consumer before the expiration of right of withdrawal period.
            </p>
            <p><strong>7. LEGAL CONSEQUENCES OF DEBTOR’S DEFAULT </strong></p>
            <p>In the case of the Purchaser's default in paying the installments in the hire purchase agreement, the
                Seller reserves the right to demand the performance of the entire remaining debt. This right is
                exercised only if the Seller has fulfilled all its obligations, provided that the Purchaser's (a) at
                least two consecutive installments, which constitute at least one-tenth of the remaining debt; or
                (b) defaults on paying an installment constituting at least one quarter of the remaining debt. For
                the Seller to exercise this right, it is obligatory to give the Purchaser at least thirty-day
                written notice of maturity. Interest, commission and similar expenses are not taken into account in
                the calculation of the due installments. If the Seller reserves with a clause in an agreement the
                right to demand the performance of the entire remaining debt in case one or more of the installments
                are not paid, this right can only be exercised if the Seller has fulfilled all its obligations, the
                Purchaser defaults in paying at least two consecutive installments, and the total of the unpaid
                installment constitute at least one tenth of the sale price, it can be used in case of a notice of
                maturity, giving the Buyer thirty-day to pay the remaining debts.</p>
            <p><strong>8. OBLIGATIONS OF PURCHASER’S AND SELLER’S</strong></p>
            <p>Within the withdrawal period, the Purchaser shall not be responsible for the changes and
                deteriorations that occur if it is used in accordance with its operation, technical specifications
                and usage instructions. The Seller shall be responsible for any loss or damage that occurs until the
                goods are delivered to the Purchaser or a third party to be determined by the Purchaser other than
                the carrier. Except for the contracts in Article 6, the Seller shall have no right to ask the
                Purchaser to pay under any name or submit any document that puts the Purchaser in debt in return for
                the goods subject to the contract within the withdrawal period. If any amount is received from the
                Purchaser despite this prohibition, the received amount shall be returned to the Purchaser
                immediately. In addition, any document that burdens the Purchaser in debt shall not be binding for
                the Purchaser. The seller shall be obliged to take back the goods within 14 (Fourteen) days from the
                date on which the notification of withdrawal is received. Due to the transactions made by the
                Purchaser, only the registered share shall be issued separately, not exceeding the installment
                amount for each installment payment. Promissory notes issued in violation of the provisions of this
                article shall be deemed as invalid. As the Purchaser may pay the total amount owed in advance or may
                pay one or more undue installments. In both cases, the seller shall be obliged to make all the
                necessary interest and commission deductions according to the amount paid in cases where the Seller
                receives interest or commission. The Seller must ensure that write in the Buyer's own handwriting
                the phrase "I have received a copy of the Agreement by hand, and I have been informed about I have
                the right to withdraw within fourteen days." with the date of agreement. The Seller obliged to give
                the copy of the invoice or receipt, and the book of instructions if any, to the Buyer.</p>
            <p><strong>9. COMPETENT AUTHORITY TO APPLICATION WITH REGARD TO COMPLAINT OR OBJECTION</strong></p>
            <p>In case of conflict arising from this agreement, The Purchaser must file a complaint or an appeal
                against the purchased goods/services to the Consumer Arbitration Committee or the Consumer Court of
                the place where the goods are purchased or the place of residence, within the monetary limits
                determined by the Ministry in December every year and published in the Official Gazette.</p>
            <p><strong>10. STATEMENT OF PURCHASER</strong></p>
            <p>The Purchaser shall write the date of agreement and the following statements in the box above in his
                own handwriting in the box below:</p>
            <p>Agreement Date: </p>
            <p>"I have received a copy of the Agreement by hand, and I have been informed about I have the right to
                withdraw within fourteen days."</p>
            <p style="border: 1px solid #000; font-style: italic; min-height:120px;">
                It will be filled in handwriting by the Purchaser.
            </p>
            <p><strong>Annex-1- Sample Withdrawal Form</strong></p>
            <p>I delivered the above written product(s) in full and intact, by signing the warranty certificate and
                the book of instructions. / I will deliver the above written product(s) in full and intact, by
                signing the warranty certificate and the book of instructions.* </p>
            <p>
                <strong>DIRECT SELLER</strong>
                <br><strong>SIGNATURE</strong>
            </p>
            <p><br>I have received/I have been informed that I will receive the product(s) written above in full and
                intact, the invoice/receipt, and the book of instruction.** I have read the information in the
                agreement. I was informed about the basic characteristics of the goods subject to the agreement, the
                Seller's title and contact information, the total price of the products in Turkish Lira including
                taxes, and my right to withdrawal.</p>
            <p>
                <br><strong>CLIENT</strong>
                <br><strong>NAME-SURNAME </strong>
                <br><strong>SIGNATURE</strong>
            </p>
            <p>
                <strong>SAMPLE WITHDRAWAL FORM</strong><br>
                <strong>(This form will only be filled and sent while the right of withdrawal is
                    exercising.)</strong>
            </p>
            <p>
                <strong>-To whom: (This part, which will be filled by the Seller or Supplier, will include the name
                    or title, address, fax number if any, and e-mail address of the Seller or Supplier.)</strong>
                <br><strong>-With this form, I declare that I have exercised my right to withdraw from the agreement
                    regarding the sale of the goods below or the provision of services.</strong>
                <br><br><strong>-Agreement Date:</strong>
                <br><strong>-The date of delivery or time of performance of the goods or services:</strong>
                <br><strong>-Goods or services subject to the right of withdrawal:</strong>
                <br><strong>-Consumer’s Name/Surname:</strong>
                <br><strong>-Address of Consumer:</strong>
                <br><strong>-Consumer’s Signature: (Only if sent as paper)</strong>
                <br><strong>-Date:</strong>
                <br>____________________________________
            </p>
            <p>*If the delivery date and the agreement date are the same, cross out the statement “I will”. If the
                delivery date and the agreement date are different, cross out the statement “I have”.</p>
            <p> **If the delivery date and the agreement date are the same, cross out the statement “I have been
                informed that I will receive”. If the goods delivery date and the agreement date are different,
                cross out the statement “I have received”.</p>
            <p><strong>ANNEX - 2</strong></p>
            <p><strong>PRELIMINARY INFORMATION FORM</strong></p>
            <p>
                Hereby Preliminary Information Form has been shared by the Seller within the scope of the Regulation
                No. 6502 on Consumer Protection and Regulation on Off-Premises Contracts and contains information
                that must be provided before the performance of the Off- Premises Contract to be concluded with the
                SELLER.
                This Preliminary Information Form is an annex and an integral part of the Off-Premises Agreement to
                be concluded with the Seller whose information is given below.
            </p>
            <p><strong>Information On The Seller</strong></p>
            <p>
                Seller’s Corporate Name: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ
                <br>Seller’s Address: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı
                No: 7 Ümraniye/İstanbul
                <br>Seller Mersis No: 0384051355400001
                <br>Seller’s Phone: 0850 3407880
                <br>Seller’s E-mail: tr@rainintl.com
            </p>
            <p><strong>Information On The Buyer</strong></p>
            <p>
                Buyer’s Full Name/Title:
                <br>Buyer’s Address:
                <br>Buyer’s Phone:
                <br>Buyer’s E-mail:
            </p>
            <p><strong>INFORMATION ON PRODUCT AND PRICE</strong></p>
            <table style="border-collapse: collapse;" class="table table-bordered">
                <thead style=" vertical-align:bottom;border-bottom-width:2px;">
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Order Code</th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Product Name </th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Unit Price </th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Quantity</th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Product Price </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ________(____)</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                    </tr>
                </tbody>
            </table>
            <table style="border-collapse: collapse;" class="table table-bordered">
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Shipment Price</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Payment Method</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Discount/Coupon</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Total Price to Be Paid (Including VAT)</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                </tbody>
            </table>
            <p>
                <strong>Payment And Delivery Details</strong>
                <br>Total product price excluding cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Cargo fee:
                <br>Total product price including all taxes and cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Payment method:
                <br>Number of installments:
                <br>Received carrying charge:
                <br>Interest rate used in carrying charge: 0
                <br>Additional cost to be paid by the Buyer: 0
                <br>Delivery address: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Person to be delivered: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}

            </p>
            <p><strong>BUYER’S RIGHT OF WITHDRAWAL</strong></p>
            <p>The Buyer has the right to withdraw from the Agreement within fourteen days without giving any reason
                and without paying any penalty.</p>
            <p>The period of the right of withdrawal starts on the day the contract is established in contracts
                related to the performance of service,; it starts on the day the Buyer or the third party determined
                by the Buyer receives the goods in contracts for the delivery of goods,. However, the Buyer may use
                the right of withdrawal within the period from the conclusion of the Contract until the delivery of
                the goods.</p>
            <p>It is sufficient for the Buyer to send the notification regarding the exercise of the right of
                withdrawal to the Seller in writing or by sending an e-mail to tr@rainint.com before the right of
                withdrawal expires. In the exercise of the right of withdrawal, the Buyer may use the form attached
                to the Contract or make a clear statement of the withdrawal decision. The right of withdrawal period
                starts on the day when the Buyer or the third party determined by the Buyer receives the goods in
                the contracts for the delivery of goods. However, the Buyer may use his right of withdrawal within
                the period from the conclusion of the contract to the delivery of the goods.</p>
            <p>
                In determining the period of the right of withdrawal;
            </p>
            <ul style="margin-left:1rem;">
                <li>For goods that are the subject of a single order and delivered separately, the day on which the
                    Buyer or the third party determined by the Buyer receives the last good,</li>
                <li>For goods consisting of more than one piece, on the day when the Buyer or the third party
                    determined by the Buyer receives the last piece,</li>
                <li>
                    In contracts where the goods are delivered regularly for a certain period of time, the day on
                    which the Buyer or the third party determined by the Buyer receives the first good
                    is taken as a basis.
                </li>
            </ul>
            <br>The delivery of the goods by the Seller to the carrier is not considered as a delivery to the Buyer.
            <p></p>
            <p>Within the withdrawal period, the Buyer is not responsible for the changes and deteriorations that
                occur if the goods are used in accordance with its operation, technical specifications and usage
                instructions. The Seller is responsible for any loss or damage that occurs until the goods are
                delivered to the Buyer or a third party to be determined by the Buyer other than the carrier. Except
                for contracts where the right of withdrawal is possible, the Seller cannot ask the Buyer to make a
                payment under any name or to provide any document that puts the Buyer in debt in return for the
                goods subject to the contract within the withdrawal period. In the event that any price is received
                from the Buyer despite this prohibition, the received price shall be returned to the Buyer
                immediately. In addition, any document that puts the Buyer under debt shall be deemed as null and
                void for the Buyer. The seller is obliged to take back the goods within 14 (Fourteen) days from the
                date on which the notification of withdrawal is received. The Seller shall ensure that the Buyer
                signs the Contract and write in the Buyer's own handwriting the phrase "I have received a copy of
                the Contract by hand and I have been informed that I have the right to withdraw within fourteen
                days" on the place marked with the "agreement date" and that a copy of the contract is signed and
                deliver the copy of the invoice or receipt, the introduction and user manual, if any, to the Buyer.
            </p>
            <p><strong>THE ADDRESS WITHDRAWAL NOTIFICATION WILL BE MADE</strong></p>
            <p>To Seller’s e-mail address of tr@rainintl.com or headquarters address Necip Fazıl Mahallesi,
                Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul.</p>
            <p>
                <strong>EXCEPTIONS FOR WHICH THE RIGHT OF WITHDRAWAL IS NOT ALLOWED</strong>
                <br>Unless otherwise agreed by the parties, the Purchaser shall have no right to withdrawal in the
                following agreements:<br>
            </p>
            <ul style="margin-left:1rem;">
                <li>
                    Agreements related to the products which is prepared in accordance with the consumer’s needs or
                    personal requests,
                </li>
                <li>Agreements related to the delivery of goods that may deteriorate quickly or expire,</li>
                <li>Agreements for the delivery of products whose protective elements such as packaging, tape, seal,
                    package have been opened after the delivery and their return is not suitable for health and
                    hygiene,</li>
                <li>Agreements related to books, digital content and computer consumables presented in tangible
                    environment in case protective elements such as packaging, tape, seal, package are opened after
                    delivery of the goods,</li>
                <li>Agreements for services which are executed with the approval of the consumer before the
                    expiration of right of withdrawal period.</li>
            </ul>
            <p></p>
            <p><strong>COMPETENT AUTHORITY TO APPLICATION WITH REGARD TO COMPLAINT OR OBJECTION </strong></p>
            <p>In case of conflict arising from this agreement, The Purchaser must file a complaint or an appeal
                against the purchased goods/services to the Consumer Arbitration Committee or the Consumer Court of
                the place where the goods are purchased or the place of residence, within the monetary limits
                determined by the Ministry in December every year and published in the Official Gazette.</p>
        </div>
    </div>

    <div id="enrollAgreement_tr" #enrollAgreement_tr
        [hidden]="!(selectedLanguage.CultureCode=='tr' && enrollAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>DOĞRUDAN SATICI SÖZLEŞMESİ</strong></p>
            <p>Bu sözleşme {{enrollmentservice.shippingAddress.Street1 + ' ' +
                (enrollmentservice.shippingAddress.Street2? enrollmentservice.shippingAddress.Street2: '')}},
                {{enrollmentservice.shippingAddress.City}}, {{enrollmentservice.shippingAddress.StateName}}
                {{enrollmentservice.shippingAddress.CountryCode}} TR adresinde mukim,
                {{enrollmentservice.webOffice.TaxID}} TC kimlik numaralı/Ticaret Sicil Numaralı
                {{enrollmentservice.shippingAddress.FirstName}} {{enrollmentservice.shippingAddress.LastName}} (bundan
                sonra “Rain Ortağı” olarak anılacaktır) ve Necip
                Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul
                adresinde mukim Rain International Beslenme Anonim Şirketi (bundan sonra “Rain Türkiye” olarak
                anılacaktır) arasında Rain Türkiye’nin doğrudan satıcısı olma hususunda aşağıdaki koşul ve şartlarda
                27.01.2023 tarihinde imzalanmıştır. Bu sözleşmeye dahil olmuş ve bu sözleşmenin bir parçası olan Rain
                International Politika ve Prosedürleri ile Rain International Ücret Planı, birlikte “Sözleşme" olarak
                anılacaktır. Bir Türk firması, Rain International Küresel Organizasyon’unun bir üyesi ve bu sözleşmeyle
                Rain Ortağı olan Rain Türkiye, sözleşme koşulları ve diğer Rain International Politikalarına dayanarak
                Rain International ürünlerini satma hakkına sahiptir. Bu sebeple Rain International’ın, Türk hukukunun
                emredici kurallarıyla çelişmeyen/çatışmayan bütün politika, prosedür ve kuralları, taraflar arasındaki
                ilişkide geçerli ve yürürlükte olacaktır. </p>
            <p>Sözleşme, Rain Türkiye’nin onayı ile yürürlüğe girecek olup, başvurunun onaylanıp onaylanmaması tamamen
                Rain Türkiye’nin takdirindedir. </p>
            <p>Rain Türkiye ve Rain Ortağı bundan sonra beraber “Taraflar” olarak anılacaktır.</p>
            <p>
                01. Rain Ortağı, bir Rain Türkiye Ortağı olarak;

                A- Rain Ortağı’nın, Rain International ürün ve hizmetlerini satmak için bu Hüküm ve Koşullara uygun
                olarak teklif yapma hakkının olduğunu,
                B- Rain Ortağı’nın Rain Türkiye’ye üye kaydetme hakkının (Rain Türkiye’nin onayına bağlı olarak)
                olduğunu,
                C- Rain International Pazarlama ve Ücret Planı’na göre öngörülen şartları sağlaması halinde komisyon
                kazanmaya hakkı olduğunu, anladığını beyan eder.

                Rain Ortağı, Rain International ürünlerini pazarlarken Türk hukukunun emredici hükümlerine riayet
                edeceğini ve bu sözleşmenin ekinde bulunan iş yeri dışında kurulan satış sözleşmesinin ve ön
                bilgilendirme formunun hukuka uygun olarak nihai tüketiciye verilmesini sağlayacağını anlar ve taahhüt
                eder. Aksi Halde Rain Ortağı, Rain Türkiye’nin maruz kalabileceği bütün zarar ve idari cezalardan
                sorumlu olacaktır.
            </p>
            <p>02. Rain Ortağı, Rain International Pazarlama ve Ücret Planı’nı ve Rain International ürün ve
                hizmetlerini Rain International kaynaklarında tanımlandıkları gibi sunmayı kabul eder. Rain Ortağı
                herhangi bir Ürünü tekrar etiketlendirmeyeceğini, tekrar paketlendirmeyeceğini veya değiştirmeyeceğini
                ya da herhangi bir Rain International Ürünü’nü başka bir ad altında satmayacağını kabul eder. </p>
            <p>03. Rain Ortağı, Rain Türkiye Rain Ortağı olarak bağımsız bir sözleşme tarafı olduğunu ve Rain
                Türkiye’nin çalışanı, ortağı, yasal temsilcisi veya Rain Türkiye’nin lisans hakkı sahibi olmadığını
                kabul eder. Rain Ortağı bu Sözleşme çerçevesinde talimat almamaktadır, iş faaliyetlerini düzenlemekte
                özgürdür, iş saatlerini ve tercih ettiği satış yöntemlerini seçebilir. Rain Ortağı, seyahat, yemek,
                konaklama, sekreterlik, ofis, uzun mesafe telefon görüşmesi ve diğer masraflarla sınırlı olmamak
                kaydıyla kendi masraflarını karşılamaktan münferiden sorumlu olacaktır. RAIN ORTAĞI, HERHANGİ BİR
                VERGİSEL YAHUT BAŞKACA BİR AMAÇLA KENDİSİNE RAİN TÜRKİYE’NİN ÇALIŞANI OLARAK MUAMELE EDİLMEYECEĞİNİ
                ANLAR. Rain Türkiye, Rain Ortağı’nın herhangi bir vergi kesintisinin yapılmasından sorumlu değildir ve
                Rain Ortağı’nın bu neviden bir vergisel yükümlülüğü bulunması halinde herhangi bir vergiyi Rain
                Ortağı’nın bonus ödeme veya komisyonlarından düşmeyecek veya kesmeyecektir.</p>
            <p>04. Rain Ortağı, ikisi de bu Hüküm ve Koşullara dahil olmuş ve bu Hüküm ve Koşulların bir parçası olan
                Rain International Politika ve Prosedürlerini ve Rain Uluslararası Ücret Planı’nı (bahsi geçen üç belge
                beraber “Sözleşme” olarak anılacaktır) dikkatlice okumuş ve bu belgelere riayet etmeyi kabul etmiştir.
                Politika ve Prosedürler ve/veya Pazarlama ve Ücret Planı www.rainintl.com/policies sayfasında
                yayınlamıştır, aynı zamanda Rain International Business Kit ve Rain International İdari Ofis’inde
                mevcutturlar.</p>
            <p>Rain Ortağı, Rain Türkiye tarafından yapılacak bonus veya komisyon ödemelerine hak kazanmak için
                Sözleşme’ye uymayı ve Sözleşme’yi ihlal etmemesi gerektiğini anlar. Rain Ortağı, Sözleşme’de tamamen
                Rain Türkiye’nin takdirinde olacak şekilde tadil edilebileceğini anlar ve bu değişikliklere uyacağını
                kabul eder. Değişiklik bildirimleri Rain Türkiye web sayfasında veya Rain Türkiye İdari Ofis’inde
                yayınlanır. Değişiklikler yayınlanmalarını takiben 30 (otuz) gün içerisinde yürürlüğe konur ancak söz
                konusu değişiklikler yürürlük tarihinden önceki dönem için geriye yönelik etki yaratmaz. Rain Ortağı’nın
                Rain Türkiye faaliyetinin devam etmesi veya herhangi bir değişikliğin yürürlük tarihinden sonra bonus
                veya komisyon ödemesini kabul etmesi Rain Ortağı’nın bütün değişiklikleri kabul ettiğini gösterir.</p>
            <p>05. Bu Sözleşmenin süresi (Politika ve Prosedür metninde belirtildiği gibi erken fesih hariç olmak üzere)
                bir yıldır. Rain Ortağı Rain Türkiye faaliyetini yıllık olarak yenilemezse veya bahsi geçen faaliyet
                herhangi bir sebepten dolayı iptal edilir ya da feshedilirse Rain Ortağı olarak sahip olduğu bütün
                hakları daimi şekilde kaybedeceğini anlar. Rain Ortağı böyle bir durumda Rain International ürün ve
                hizmetlerini satmaya ve bonus, komisyon veya alt satış organizasyonunun faaliyetinden doğan başka bir
                gelire hak kazanamayacaktır. Rain Ortağı, iptal, fesih veya yenilememe halinde alt organizasyonundan
                doğan mülkiyet hakları da dahil fakat bununla sınırlı olmamak üzere bütün haklarından ve bonus, komisyon
                ya da satışlar ve eski alt işletmesinin faaliyetleri aracılığıyla elde edilmiş gelirlerinden feragat
                eder. Rain Türkiye: (1) faaliyetlerine son vermeye; (2) tüzel kişiliğini feshetmeye; ya da (3)
                ürünlerinin ve/veya hizmetlerinin doğrudan satış yöntemleriyle dağıtımını sonlandırmaya karar verdiği
                takdirde bütün Rain Ortak Sözleşmelerini 30 gün öncesinde bildirimde bulunmak koşuluyla feshetme hakkını
                saklı tutar. Rain Ortağı bu Sözleşmeyi Rain Türkiye’ye 30 gün öncesinde yazılı bildirimde bulunmak
                koşuluyla herhangi bir zaman ve herhangi bir nedenle feshedebilir. Rain Türkiye bu Sözleşmeyi Rain
                Ortağı’na 30 gün öncesinde yazılı bildirimde bulunmak koşuluyla herhangi bir sebepten dolayı
                feshedebilir.</p>
            <p>06. Rain Ortağı, Rain Türkiye’nin yazılı rızası olmadan Sözleşme’den doğan haklarını devredemez veya aynı
                Sözleşme’den doğan yükümlülükleri için başka bir kişiyi görevlendiremez. Rain Türkiye’nin açık ve yazılı
                rızası olmadan yapılan herhangi bir Sözleşmeyi devretme girişimi Rain Türkiye’nin tercihine bağlı olarak
                Sözleşme’nin feshini kabil kılar ve Rain Ortağı’nın faaliyetinin sonlandırılmasına yol açabilir.</p>
            <p>07. Rain Ortağı, Sözleşme’nin hükümlerine riayet edememesi durumunda, Rain Türkiye’nin takdir etmesi
                halinde, kendisine Politika ve Prosedürler’de ifade edilmiş disiplin işlemlerini uygulayabileceğini
                anlar. Rain Ortağı Sözleşme’nin feshine ihlali ya da hatası sebebiyle mahal verdiyse bonus veya komisyon
                satışlarının tamamlanmış olmasından bağımsız olarak ilave bonus veya komisyona hak kazanmayacaktır. Rain
                Ortağı, daha önceden onayladığı herhangi bir ödemenin veya borçlu olduğu herhangi bir bedelin Rain
                Türkiye tarafından tenzil edilebileceğini, durdurulabileceğini, mahsup edilebileceğini veya maliyetinin
                yansıtılabileceğini kabul eder. </p>
            <p>08. Rain Türkiye, ana veya bağlı şirketleri, yöneticileri, yetkilileri, hissedarları, çalışanları,
                devralanları ve temsilcileri (beraber “Bağlı Kişiler” olarak anılacaktır) bu Sözleşme’yle bağlantılı
                dolaylı zarar, cezai yaptırım veya dava sebebinden sorumlu değildir. </p>
            <p>Rain Ortağı; Rain Türkiye, Rain International ve Bağlı Kişileri bu Sözleşme’yle bağlantılı dolaylı zarar
                ve cezai yaptırımlarla ilgili herhangi bir şikayet veya dava sebebinden ari kılacaktır. </p>
            <p>Rain Ortağı ayrıca, Rain Türkiye ve Bağlı Kişileri: (a) Sözleşme’yi ihlalinden ; (b) kendi Rain
                işletmesininin tanıtım veya faaliyetleri veya (bunlarla sınırlı olmamak kaydıyla Rain ürünlerinin veya
                Rain International Pazarlama ve Mükafat Planı’nın tanıtımı, motorlu taşıt işletimi, antrenman veya
                toplantı tesisi kiralaması vb.) doğabilecek sorumluluklardan ari kılmayı ve Rain Türkiye açısından
                doğabilecek herhangi bir sorumluluk , zarar, ceza, para cezası ya da işletmesini yönetirken üstlendiği
                herhangi bir izinsiz davranıştan doğabilecek sorumluluk, ceza, para cezası ve diğer hükümler için Rain
                Türkiye’nin zararını tazmin etmeyi kabul eder.</p>
            <p>09. Sözleşme’nin şu anki, Rain Türkiye’nin takdirine bağlı olarak değiştirilmiş hali, Rain Türkiye ve
                Rain Ortağı arasındaki sözleşmenin tümünü oluşturur. Bu Sözleşmede açıkça ifade edilmemiş taahhüt,
                beyan, teklif veya başka bildiriler geçersizdir ve uygulanmayacaktır.</p>
            <p>10. Rain Türkiye’nin Sözleşmenin herhangi bir ihlalinden feragat işlemi yazılı olmalı ve Rain Türkiye’nin
                yetkili bir görevlisi tarafından imzalanmalıdır. Rain Türkiye’nin, Rain Ortağı’nın Sözleşme’yi
                ihlalinden doğan haklarından feragat etmesi, Rain Türkiye’nin sonraki ihlallerden doğan haklarından da
                feragat edeceği şeklinde anlaşılamaz. </p>
            <p>11. Herhangi bir şüpheye mahal vermemek adına, Rain Ortağı’nın her siparişinde taraflar arasında yeni bir
                mesafeli sözleşme kurulacaktır ve bu mesafeli sözleşmenin hak ve yükümlülükleri geçerli olacaktır. Eğer
                Sözleşme’nin herhangi bir hükmünün geçersiz veya uygulanamaz olduğu anlaşılırsa, bahsi geçen hüküm
                sadece hükmü uygulanabilir kılmak için yeterli ölçüde değiştirilecek ve Sözleşme’nin dengesi geçerli ve
                yürürlükte kalacaktır. Sözleşme ve Türkiye’de yürürlükte olan emredici hukuk kuralları (6502 sayılı
                Tüketicinin Korunması Hakkında Kanun, İş Yeri Dışında Kurulan Sözleşmeler Yönetmeliği, Mesafeli
                Sözleşmeler Yönetmeliği vb.) veya her sipariş üzerine kurulacak olan Mesafeli Sözleşmeler arasında bir
                çelişki olması halinde emredici hukuk kuralları ve Mesafeli Sözleşmelerin hüküm ve koşulları geçerli
                olacaktır. Rain Ortağı’nın gerçek veya tüzel kişi tacir olması halinde ve/veya ürün faturasının tacir
                Rain Ortağı adına kesilmesi halinde, Rain Ortağı tüketici haklarından ve münhasıran tüketicilere hak ve
                imkan tanıyan Sözleşme ve Kanun hükümlerinden yararlanamaz.</p>
            <p>12. İşbu Sözleşme’ye kanuni ihtilaflara bakılmaksızın Türkiye Cumhuriyeti kanunları uygulanacak ve
                Sözleşme bu kanunlar doğrultusunda yorumlanacaktır. Rain Ortağı ve Rain Türkiye arasında bu Sözleşme’den
                doğan, Sözleşme’yle veya her bir tarafın hak ve yükümlülükleriyle bağlantılı bir uyuşmazlık olması
                durumda, taraflar iyiniyetle, bağlayıcı olmayan ve daha fazlasının Politika ve Prosedürler’de tam olarak
                açıklandığı gibi bir arabuluculuk aracılığıyla çözüm bulma girişiminde bulunacaklardır. Rain Türkiye,
                Rain Ortağı’na karşı bir disiplin işleminde bulunmadan önce bir ön şart olarak arabuluculuğa başvurmak
                zorunda değildir. </p>
            <p>13. Yukarıda anlatılanlara karşın her bir taraf, bunlarla sınırlı olmamak üzere müşteri ve/veya Rain
                Ortağı listeleri, başka ticari sırlar, markalar, ticaret unvanları, patentler, telif hakları gibi fikri
                mülkiyet haklarını korumak için mahkemeden men emri, ihtiyati tedbir, haciz veya bunlara eş değer bir
                telafi talep edebilirler.</p>
            <p>14. Bu birden çok seviyeli pazarlama planında katılımcı olan kişi cezaya maruz kalmadan ve nedenine
                bakılmaksızın iptal etme hakkına sahiptir. İptalin şirkete yazılı olarak gönderilmesi gerekmektedir.
                Rain Ortağı, Sözleşmeyi sisteme dahil olmasından itibaren 60 gün içinde iptal veya feshederse Rain
                Türkiye, Rain Ortağı’nın kullanılmamış ve tekrardan satılabilir ürünleri için yapacağı iade taleplerini
                kabul edecek ve ürünler için ödenen bedeli 14 gün içinde iade edecektir.</p>
            <p>15. Rain Ortağı, Rain Türkiye aleyhine Sözleşme’yle bağlantılı veya Sözleşme’den kaynaklı herhangi bir
                fiil veya ihmal nedeniyle dava açarsa, söz konusu davanın, dava sebebi olarak iddia edilen davranış
                tarihinden itibaren bir yıl içerisinde açılması gerekmektedir. Dava bu süre içinde açılmazsa söz konusu
                fiil veya ihmal açısından bütün iddialar hükümsüz olacaktır. Rain Ortağı başka sınırlayıcı mevzuatların
                uygulanması yönündeki iddialarından vazgeçer.</p>
            <p>16. Rain Ortağı, Rain Türkiye’ye adını, fotoğrafını, kişisel hikayesini ve/veya suretini reklam ve
                tanıtım malzemelerinde kullanmasına dair izin verir ve söz konusu kullanım için bütün ücret hakkı
                taleplerinden feragat eder.</p>
            <p>17. Sözleşmenin fakslanmış bir kopyası her bakımdan orijinal gibi muamele görecektir.</p>
            <p>18. Rain Ortağı Sözleşme’yi imzalamadan önce, bütün Sözleşme’yi okuyup tam olarak anladığını Rain
                Türkiye’ye beyan ve taahhüt eder.</p>
            <p>19. Rain Ortağı, Türkçe’yi tam olarak okuyabildiği, konuşabildiği ve anlayabildiğini beyan ve taahhüt
                temin eder. Türkçe taraflar arasında (bunlarla sınırlı olmamakla beraber bu Sözleşme’nin pazarlığında ve
                akdedilmesinde, bütün ticari iletişimde ve bütün arabuluculuk, tahkim veya hukuki ihtilafta) kullanılan
                tek dildir ve tek dil olacaktır. Sözleşmenin Türkçe versiyonu esas alınacaktır.</p>
            <p>20. Şirket’e yapılacak bütün yazılı bildirimler Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt.
                Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul adresine teslim edilmelidir.</p>
            <p>21. Rain Ortağı kişisel verilerinin; siparişlerinin yerine getirilmesi amacıyla, idari amaçlarla,
                istatistikler için, ilaveten pazarlama maksatlarıyla Rain Türkiye tarafından işlenmesine izin verir.
                Rain Türkiye herhangi bir kişisel veriyi internet sayfasında (<a
                    href="https://rain-policies.s3.amazonaws.com/RAIN+Privacy+Policy+Revised+2-1-2022.pdf"
                    target="_blank">https://rain-policies.s3.amazonaws.com/RAIN+Privacy+Policy+Revised+2-1-2022.pdf</a>)
                yayınlanmış Gizlilik Politikası’na uygun şekilde işleyecektir. </p>
            <p>22. BAĞIMSIZ BİR RAİN ORTAĞI OLMAK İÇİN TERCİH EDİLEN /FAVORİ MÜŞTERİ SEÇİMİ: </p>
            <p>Rain Ortağı bir Favori Müşteri’yse, Bağımsız Rain Ortağı olarak seçildiğinde onu Favori Müşteri olarak
                kaydeden Rain Ortağı tarafından destekleneceğini anlar ve kabul eder.</p>
            <p>Favori Müşteri başka bir Rain Ortağı tarafından desteklenmek isterse, bu değişiklik için Şirket’e kendi
                Bağımsız Rain Ortağı Başvurusu ve Sözleşmesi’ni kabulünden önce yazılı bir talepte bulunması gerekir.
                Sponsor değiştirilmemesi şiddetle tavsiye edilir ve bu konuda nadiren izin verilir. Rain Ortağı,
                sponsorunu değiştirmek için Rain Türkiye’nin yazılı onayını almadıkça, talebinin reddedildiği kabul
                edilecektir.</p>
            <p><br>EK :</p>
            <p>
                - İş Yeri Dışında Kurulan Satış Sözleşmesi Taslağı (EK-1)
                <br>- Ön Bilgilendirme Formu (EK-2)
            </p>
            <p></p>
            <p>
                <br><br>Rain Türkiye
                <br>Rain International Beslenme A.Ş.
            </p>
            <p>
                <br><br>Rain Ortağı
                <br>{{enrollmentservice.shippingAddress.FirstName}} {{enrollmentservice.shippingAddress.LastName}}
            </p>
            <p><strong>EK - 1</strong><br></p>
            <p class="text-center"><strong>İŞYERİ DIŞINDA SATIŞ SÖZLEŞMESİ</strong></p>
            <p>
                Doğrudan Satıcı, sözleşme kurulduğu anda işbu İş Yeri Dışında Kurulan Sözleşme’nin bir örneğini Alıcı’ya
                vermek zorundadır. Doğrudan Satıcılar, 1 nüshası Doğrudan Satıcı’da kalmak, 1 nüshası Alıcı’ya verilmek
                ve ıslak imzalı orijinali RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ’nin Necip Fazıl Mahallesi,
                Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul adresine gönderilmek
                üzere sözleşme tarihi ile “Sözleşme’nin bir örneğini elden teslim aldım.” ve cayma hakkının
                kullanılabildiği sözleşmeler için “On dört gün içinde cayma hakkımın olduğu konusunda bilgilendirildim.”
                ifadelerini Alıcı’nın kendi el yazısı ile yazmasını ve imzalamasını sağlamak zorundadır.
                İşbu Sözleşme’nin konusu, aşağıda hüküm ve şartları yer alan malların Doğrudan Satıcı tarafından
                Alıcı’ya satışı olup, ilgili Sözleşme 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve İş Yeri
                Dışında Kurulan Sözleşmeler Yönetmeliği hükümleri doğrultusunda imzalanmıştır.
            </p>
            <p><strong>1. TARAFLARs</strong></p>
            <div class="row">
                <div class="col-6">
                    <p><strong>Satıcı Bilgileri</strong></p>
                    <p>Unvanı: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ</p>
                    <p>Mersis No: 0384051355400001</p>
                    <p>Adres: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                        Ümraniye/İstanbul</p>
                    <p>Telefon: 0 850 340 7880</p>
                    <p>E-posta: tr@rainintl.com</p>
                </div>
                <div class="col-6">
                    <p><strong>Alıcı/Tüketici Bilgileri</strong></p>
                    <p>Adı Soyadı / Unvanı:</p>
                    <p>Adresi:</p>
                    <p>Telefon:</p>
                    <p>E-posta:</p>
                </div>
            </div>
            <p>
                <strong>Doğrudan Satıcı Bilgileri : </strong>
                <br><strong>Doğrudan Satıcı Adı Soyadı:</strong>
                <br><strong>Doğrudan Satıcı No:</strong>
                <br><strong>Adres:</strong>
                <br><strong>Telefon:</strong>
                <br><strong>E-Posta:</strong>
            </p>
            <p><strong>2. SATILAN MAL/HİZMET BİLGİLERİ </strong></p>
            <table style="border-collapse: collapse;" class="table table-bordered">
                <thead>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">ÜRÜN ADI</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">MARKASI</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">MODELİ/SERİSİ</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">ADET</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">BİRİM FİYATI</th>
                        <th style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            scope="col">ARA TOPLAM (KDV DAHİL)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            RAIN SOUL Çörekotu Ahududu ve Üzüm Çekirdeğiİçeren Sıvı Takviye Edici Gıda</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            RAIN SOUL RED Çörekotu Yağı, Ahududu ve Resveratrol İçeren Sıvı Takviye Edici Gıda
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            KARGO BEDELİ</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                            colspan="5">TOPLAM</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        </td>
                    </tr>
                </tbody>
            </table>
            <p><strong>CAYMA BİLDİRİMİ</strong></p>
            <p style="font-size: 18px; border: 1px solid #000;"><strong>Bu sözleşmenin kurulduğu veya malın teslim
                    alındığı tarihten itibaren on dört gün içerisinde herhangi bir gerekçe göstermeksizin ve cezai şart
                    ödemeksizin cayma hakkınız bulunmaktadır. Cayma süresi içinde sözleşmeye konu mal veya hizmet
                    karşılığında herhangi bir isim altında ödeme yapmanızı veya borç altına sokan herhangi bir belge
                    vermenizi istemeyeceğimizi ve cayma bildiriminin tarafımıza ulaştığı tarihten itibaren en geç on
                    dört gün içerisinde malı geri almayı taahhüt ederiz.</strong></p>
            <p>
                Alıcı’nın, cayma hakkının kullanıldığına dair bildirimi cayma hakkı süresi dolmadan, yazılı olarak veya
                e-posta yolu ile Satıcı’ya yöneltmesi yeterlidir. Cayma hakkının kullanılmasında Alıcı bu Sözleşme’nin
                ekinde yer alan formu (Ek-1) kullanabileceği gibi cayma kararını bildiren açık bir beyanda da
                bulunabilir. Cayma hakkı süresi, mal teslimine ilişkin sözleşmelerde Alıcı’nın veya Alıcı tarafından
                belirlenen üçüncü kişinin malı teslim aldığı gün başlar. Ancak Alıcı sözleşmenin kurulmasından malın
                teslimine kadar olan süre içerisinde de cayma hakkını kullanabilir.
                <br>Cayma hakkı süresinin belirlenmesinde;
            </p>
            <ul style="margin-left:1rem;">
                <li>Tek sipariş konusu olup ayrı ayrı teslim edilen mallarda, Alıcı’nın veya Alıcı tarafından belirlenen
                    üçüncü kişinin son malı teslim aldığı gün, </li>
                <li>Birden fazla parçadan oluşan mallarda Alıcı’nın veya Alıcı tarafından belirlenen üçüncü kişinin son
                    parçayı teslim aldığı gün,</li>
                <li>Belirli bir süre boyunca malın düzenli tesliminin yapıldığı sözleşmelerde, Alıcı’nın veya Alıcı
                    tarafından belirlenen üçüncü kişinin ilk malı teslim aldığı gün esas alınır.</li>
            </ul>
            <br>Malın Satıcı tarafından taşıyıcıya teslimi, Alıcı’ya yapılan teslim olarak kabul edilmez.
            <p></p>
            <p><strong>4. TESLİMAT VE ÖDEME BİLGİLERİ </strong></p>
            <p>
                Ödeme Şekli ve Planı:
                <br>Ödemenin Yapılacağı Hesap Bilgileri:
                <br>Teslimat Adresi:
                <br>Teslim Edilecek kişi:
                <br>Fatura Adresi:
                <br>Sipariş Tarihi:
                <br>Teslimat tarihi:
                <br>Teslim şekli:
            </p>
            <p><strong>5. CAYMA BİLDİRİMİNİN YAPILACAGI AÇIK ADRES</strong></p>
            <p>Satıcı’nın tr@rainintl.com e-posta adresi veya Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt.
                Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul merkez adresi.</p>
            <p><strong>6. CAYMA HAKKININ ISTİSNALARI</strong></p>
            <p>
                Taraflarca aksi kararlaştırılmadıkça, Alıcı aşağıdaki sözleşmelerde cayma hakkını kullanamaz:
                <br><strong>6.1.</strong> Alıcı’nın istekleri veya kisisel ihtiyaçları dogrultusunda hazırlanan mallara
                iliskin sözlesmeler.
                <br><strong>6.2.</strong> Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine
                ilişkin sözleşmeler.
                <br><strong>6.3.</strong> Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış
                olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.
                <br><strong>6.4.</strong> Malın tesliminden sonra ambalajın açılmış olması halinde maddi ortamda sunulan
                dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.
                <br><strong>6.5.</strong> Cayma hakkı süresi sona ermeden önce, Alıcı’nın onayı ile ifasına başlanan
                hizmetlere ilişkin sözleşmeler.
            </p>
            <p><strong>7. BORÇLUNUN TEMERRÜDE DÜŞMESİNİN HUKUKİ SONUÇLARI </strong></p>
            <p>7.1. Taksitle satış sözleşmelerinde Alıcı’nın taksitleri ödemede temerrüde düşmesi durumunda, Satıcı,
                kalan borcun tümünün ifasını talep etme hakkını saklı tutmaktadır. Bu hak ancak Satıcı’nın bütün
                edimlerini ifa etmiş olması şartıyla Alıcı’nın (a) kalan borcun en az onda birini oluşturan ve birbirini
                izleyen en az iki taksiti; veya (b) kalan borcun en az dörtte birini oluşturan bir taksiti ödemede
                temerrüde düşmesi halinde kullanılabilir. Satıcı’nın bu hakkı kullanabilmesi için Alıcı’ya en az otuz
                gün süre vererek yazılı olarak muacceliyet uyarısında bulunması zorunludur. Muaccel kılınan taksitlerin
                hesaplanmasında faiz, komisyon ve benzeri masraflar dikkate alınmaz. Satıcı, taksitlerden birinin veya
                birkaçının ödenmemesi halinde kalan borcun tümünü ifasını talep etme hakkını sözleşmeye koyacağı bir
                şartla saklı tutmuşsa bu hak ancak, Satıcının bütün edimlerini ifa etmiş olması, Alıcı’nın birbirini
                izleyen en az iki taksiti ödemede temerrüde düşmesi ve ödenmeyen taksit toplamının satış bedelinin en az
                onda biri olması, Alıcı’ya kalan borçlarını ödemesi için 30 gün süre vererek muacceliyet uyarısında
                bulunulması halinde kullanılabilir.</p>
            <p><strong>8. ALICI’NIN VE SATICI’NIN YÜKÜMLÜLÜKLERİ</strong></p>
            <p>Alıcı cayma süresi içerisinde malı; işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir
                şekilde kullandığı takdirde meydana gelen değişiklik ve bozulmalardan sorumlu değildir. Satıcı, malın
                Alıcı ya da Alıcı’nın taşıyıcı dışında belirleyeceği üçüncü bir kişiye teslimine kadar oluşan kayıp ve
                hasarlardan sorumludur. Satıcı, Madde 6’da yer alan sözleşmeler hariç olmak üzere, cayma süresi
                içerisinde sözleşmeye konu mal karşılığında Alıcı’dan herhangi bir isim altında ödeme yapmasını veya
                Alıcı’yı borç altına sokan herhangi bir belge vermesini isteyemez. Bu yasağa rağmen Alıcı’dan herhangi
                bir bedel alınması durumunda, alınan bedel Alıcı’ya derhal iade edilir. Ayrıca Alıcı’yı borç altına
                sokan her türlü belge Alıcı yönünden geçersizdir. Satıcı cayma bildiriminin kendisine ulaştığı tarihten
                itibaren 14 (On dört) gün içerisinde malı geri almakla yükümlüdür. Alıcı’nın yapmış olduğu işlemler
                nedeniyle kıymetli evrak niteliğinde sadece nama yazılı ve her bir taksit ödemesi için taksit tutarını
                aşmayacak ve ayrı ayrı olacak şekilde senet düzenlenir. Bu madde hükümlerine aykırı olarak düzenlenen
                senetler Alıcı yönünden geçersizdir. Alıcı, borçlandığı toplam miktarı önceden ödeyebileceği gibi vadesi
                gelmemiş bir ya da birden çok taksit ödemesinde de bulunabilir. Her iki durumda da satıcı, faiz veya
                komisyon aldığı durumlarda ödenen miktara göre gerekli tüm faiz ve komisyon indirimini yapmakla
                yükümlüdür. Satıcı, Sözleşme'yi Alıcı’nın imzalamasını ve “sözleşme tarihi” ile işaretli yere
                “Sözleşme’nin bir örneğini elden teslim aldım ve on dört gün içinde cayma hakkımın olduğu konusunda
                bilgilendirildim” ibaresini Alıcı’nın kendi el yazısı ile yazmasını sağlamak ve sözleşmenin bir sureti
                ile birlikte fatura veya tesellüm makbuzunu, varsa tanıtma ve kullanma kılavuzunu Alıcı’ya vermek
                zorundadır.</p>
            <p><strong>9. ŞİKAYET VEYA İTİRAZ KONUSUNDA BAŞVURU YAPILACAK YETKİLİ MAKAM</strong></p>
            <p>Satın alınan mal/hizmet hakkında Alıcı’nın şikâyet veya itiraz başvurusunu, Bakanlıkça her yıl Aralık
                ayında belirlenen ve Resmi Gazete’de yayımlanan parasal sınırlar dahilinde malın satın alındığı veya
                ikametgahının bulunduğu yerin Tüketici Sorunları Hakem Heyetine veya Tüketici Mahkemesine yapması
                gerekmektedir.</p>
            <p><strong>10. ALICI BEYANI</strong></p>
            <p>Alıcı sözleşme tarihini ve aşağıdaki beyanları el yazısı ile yazacaktır:</p>
            <p>Sözleşme Tarihi: </p>
            <p>“Sözleşmenin bir örneğini elden teslim aldım ve On dört gün içinde cayma hakkımın olduğu konusunda
                bilgilendirildim.”</p>
            <p style="border: 1px solid #000; font-style: italic; min-height:120px;">
                El yazısı ile Alıcı tarafından doldurulacaktır.
            </p>
            <p><strong>EK-1- Örnek Cayma Formu</strong></p>
            <p>Yukarıda yazılı ürünü/ürünleri tam ve sağlam, garanti belgesini ve tanıtma-kullanma kılavuzunu
                imzalayarak teslim ettim/ edeceğim.* </p>
            <p>
                <strong>DOĞRUDAN SATICI</strong>
                <br><strong>İMZA</strong>
            </p>
            <p><br>Yukarıda yazılı ürünü/ürünleri tam ve sağlam, fatura/tesellüm makbuzunu, tanıtma-kullanma kılavuzunu
                teslim aldım/ teslim alacağım konusunda bilgilendirildim.**Sözleşmede yer alan bilgileri okudum.
                Sözleşme konusu ürünlerin temel nitelikleri, Satıcının unvanı ile iletişim bilgileri, ürünlerin Türk
                Lirası olarak vergiler dahil toplam fiyatı ve cayma hakkım konularında bilgilendirildim. Fiyat
                listesini/kataloğunu gördüm..</p>
            <p>
                <br><strong>MÜŞTERİ</strong>
                <br><strong>AD-SOYAD </strong>
                <br><strong>İMZA</strong>
            </p>
            <p>
                <strong>ÖRNEK CAYMA FORMU</strong><br>
                <strong>(Bu form, sadece sözleşmeden cayma hakkı kullanılmak istendiğinde doldurup
                    gönderilecektir.)</strong>
            </p>
            <p>
                <strong>-Kime: (Satıcı veya sağlayıcı tarafından doldurulacak olan bu kısımda satıcı veya sağlayıcının
                    adı veya unvanı, adresi, varsa faks numarası ve e-posta adresi yer alacaktır.)</strong>
                <br><strong>-Bu formla aşağıdaki malların satışına veya hizmetlerin sunulmasına ilişkin sözleşmeden
                    cayma hakkımı kullandığımı beyan ederim.</strong>
                <br><br><strong>-Sözleşme tarihi:</strong>
                <br><strong>-Mal veya hizmetin teslim veya ifa tarihi:</strong>
                <br><strong>-Cayma hakkına konu mal veya hizmet:</strong>
                <br><strong>-Tüketicinin adı soyadı:</strong>
                <br><strong>-Tüketicinin adresi:</strong>
                <br><strong>-Tüketicinin imzası: (Sadece kağıt üzerinde gönderilmesi halinde)</strong>
                <br><strong>-Tarih:</strong>
                <br>____________________________________
            </p>
            <p>*Mal teslim tarihi ile sözleşme tarihi aynı olduğu takdirde “edeceğim” ifadesinin üzerini çiziniz. Mal
                teslim tarihi ile sözleşme tarihi farklı olduğu takdirde “ettim” ifadesinin üzerini çiziniz. </p>
            <p>**Mal teslim tarihi ile sözleşme tarihi aynı olduğu takdirde “teslim alacağım konusunda bilgilendirildim”
                ifadesinin üzerini çiziniz. Mal teslim tarihi ile sözleşme tarihi farklı olduğu takdirde “teslim aldım”
                ifadesinin üzerini çiziniz.</p>
            <p><strong>EK - 2</strong></p>
            <p><strong>ÖN BİLGİLENDİRME FORMU</strong></p>
            <p>
                Doğrudan Satıcı ile Alıcı arasında kurulacak İş Yeri Dışında Kurulan Doğrudan Satış Sözleşmesi’nin
                (“Sözleşme” olarak anılacaktır) konusu, aşağıda hüküm ve şartları yer alan malların Doğrudan Satıcı
                tarafından Alıcı’ya satışı olup, ilgili ön bilgilendirme formu 6502 sayılı Tüketicinin Korunması
                Hakkında Kanun ve İş Yeri Dışında Kurulan Sözleşmeler Yönetmeliği’nin 5. ve diğer hükümleri
                doğrultusunda hazırlanmıştır.
                Ön Bilgilendirme Formu, aşağıda bilgileri verilmiş olan ve Doğrudan Satış Sözleşmesi’nin ayrılmaz bir
                parçası ve ekidir.
            </p>
            <div class="row">
                <div class="col-6">
                    <p><strong>Satıcı Bilgileri</strong></p>
                    <p>Unvanı: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ</p>
                    <p>Mersis No: 0384051355400001</p>
                    <p>Adres: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                        Ümraniye/İstanbul</p>
                    <p>Telefon: 0 850 340 7880</p>
                    <p>E-posta: tr@rainintl.com</p>
                </div>
                <div class="col-6">
                    <p><strong>Alıcı/Tüketici Bilgileri</strong></p>
                    <p>Adı Soyadı / Unvanı:</p>
                    <p>Adresi:</p>
                    <p>Telefon:</p>
                    <p>E-posta:</p>
                </div>
            </div>
            <p>
                <strong>Doğrudan Satıcı Bilgileri : </strong>
                <br><strong>Doğrudan Satıcı Adı Soyadı:</strong>
                <br><strong>Doğrudan Satıcı No:</strong>
                <br><strong>Adres:</strong>
                <br><strong>Telefon:</strong>
                <br><strong>E-Posta:</strong>
            </p>
            <p><strong>ÜRÜN VE FIYAT HAKKINDAKI BILGILER</strong></p>
            <table style="border-collapse: collapse;" class="table table-bordered">
                <thead>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Sipariş Kodu</th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Ürün Adı </th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Birim Fiyat </th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Miktar</th>
                        <th
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Ürün Bedeli </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ________(____)</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            ____________</td>
                    </tr>
                </tbody>
            </table>
            <table style="border-collapse: collapse;" class="table table-bordered">
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Nakliye Bedeli </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Ödeme Yöntemi </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            İndirim / Kupon</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            Toplam Ödenecek Bedel (KDV Dahil) </td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _______</td>
                    </tr>
                </tbody>
            </table>
            <p>
                <strong>ÖDEME VE TESLIMAT DETAYLARI </strong>
                <br>Kargo bedeli hariç toplam ürün fiyatı:
                <br>Kargo bedeli:
                <br>Vergiler ve kargo bedeli dahil toplam ürün ücreti:
                <br>Ödeme yöntemi:
                <br>Taksit sayısı:
                <br>Tahsil edilen taşıma bedeli:
                <br>Taşıma bedeline işleyecek faiz oranı:
                <br>ALICI tarafından ödenecek ek maliyet:
                <br>Teslimat adresi:
                <br>Teslim edilecek kişi:
                <br>Ürün(ler) ALICI’nın aşağıda belirtilmiş adresine teslim edilecektir.
            </p>
            <p><strong>ALICI’NIN CAYMA HAKKI </strong></p>
            <p>Alıcı, on dört gün içinde herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin Sözleşme’den
                cayma hakkına sahiptir.</p>
            <p>Cayma hakkı süresi, hizmet ifasına ilişkin sözleşmelerde sözleşmenin kurulduğu gün; mal teslimine ilişkin
                sözleşmelerde ise Alıcı’nın veya Alıcı tarafından belirlenen üçüncü kişinin malı teslim aldığı gün
                başlar. Ancak Alıcı Sözleşme’nin kurulmasından malın teslimine kadar olan süre içerisinde de cayma
                hakkını kullanabilir.</p>
            <p>Alıcı’nın, cayma hakkının kullanıldığına dair bildirimi cayma hakkı süresi dolmadan, yazılı olarak veya
                tr@rainint.com adresine atacağı bir e-posta ile Satıcı’ya yöneltmesi yeterlidir. Cayma hakkının
                kullanılmasında Alıcı Sözleşme’nin ekinde yer alan formu kullanabileceği gibi cayma kararını bildiren
                açık bir beyanda da bulunabilir. Cayma hakkı süresi, mal teslimine ilişkin sözleşmelerde Alıcı’nın veya
                Alıcı tarafından belirlenen üçüncü kişinin malı teslim aldığı gün başlar. Ancak Alıcı sözleşmenin
                kurulmasından malın teslimine kadar olan süre içerisinde de cayma hakkını kullanabilir. </p>
            <p>
                Cayma hakkı süresinin belirlenmesinde;
            </p>
            <ul style="margin-left:1rem;">
                <li>Tek sipariş konusu olup ayrı ayrı teslim edilen mallarda, Alıcı’nın veya Alıcı tarafından belirlenen
                    üçüncü kişinin son malı teslim aldığı gün,</li>
                <li>Birden fazla parçadan oluşan mallarda Alıcı’nın veya Alıcı tarafından belirlenen üçüncü kişinin son
                    parçayı teslim aldığı gün,</li>
                <li>
                    Belirli bir süre boyunca malın düzenli tesliminin yapıldığı sözleşmelerde, Alıcı’nın veya Alıcı
                    tarafından belirlenen üçüncü kişinin ilk malı teslim aldığı gün esas alınır.
                </li>
            </ul>
            <br>Malın Satıcı tarafından taşıyıcıya teslimi, Alıcı’ya yapılan teslim olarak kabul edilmez.
            <p></p>
            <p>Alıcı cayma süresi içerisinde malı; işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir
                şekilde kullandığı takdirde meydana gelen değişiklik ve bozulmalardan sorumlu değildir. Satıcı, malın
                Alıcı ya da Alıcı’nın taşıyıcı dışında belirleyeceği üçüncü bir kişiye teslimine kadar oluşan kayıp ve
                hasarlardan sorumludur. Satıcı, cayma hakkının söz konusu olmadığı sözleşmeler hariç olmak üzere, cayma
                süresi içerisinde sözleşmeye konu mal karşılığında Alıcı’dan herhangi bir isim altında ödeme yapmasını
                veya Alıcı’yı borç altına sokan herhangi bir belge vermesini isteyemez. Bu yasağa rağmen Alıcı’dan
                herhangi bir bedel alınması durumunda, alınan bedel Alıcı’ya derhal iade edilir. Ayrıca Alıcı’yı borç
                altına sokan her türlü belge Alıcı yönünden geçersizdir. Satıcı cayma bildiriminin kendisine ulaştığı
                tarihten itibaren 14 (On dört) gün içerisinde malı geri almakla yükümlüdür. Satıcı, Sözleşme'yi
                Alıcı’nın imzalamasını ve “sözleşme tarihi” ile işaretli yere “Sözleşme’nin bir örneğini elden teslim
                aldım ve on dört gün içinde cayma hakkımın olduğu konusunda bilgilendirildim” ibaresini Alıcı’nın kendi
                el yazısı ile yazmasını sağlamak ve sözleşmenin bir sureti ile birlikte fatura veya tesellüm makbuzunu,
                varsa tanıtma ve kullanma kılavuzunu Alıcı’ya vermek zorundadır.</p>
            <p><strong>CAYMA BİLDİRİMİNİN YAPILACAGI AÇIK ADRES</strong></p>
            <p>Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul
                (Cayma hakkının kullanılması sonrasında ürünlerin iade edileceği adres: Orta Mahallesi Burgaz Caddesi
                No:11 Tuzla/İstanbul - Hitittrans)</p>
            <p>
                <strong>CAYMA HAKKININ ISTİSNALARI</strong>
                <br>Taraflarca aksi kararlaştırılmadıkça, Alıcı aşağıdaki sözleşmelerde cayma hakkını kullanamaz:<br>
            </p>
            <ul style="margin-left:1rem;">
                <li>
                    Alıcı’nın istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.
                </li>
                <li>Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.</li>
                <li>Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi
                    sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.</li>
                <li>Malın tesliminden sonra ambalajın açılmış olması halinde maddi ortamda sunulan dijital içerik ve
                    bilgisayar sarf malzemelerine ilişkin sözleşmeler.</li>
                <li>Cayma hakkı süresi sona ermeden önce, Alıcı’nın onayı ile ifasına başlanan hizmetlere ilişkin
                    sözleşmeler.</li>
            </ul>
            <p></p>
            <p><strong>ŞİKAYET VEYA İTİRAZ KONUSUNDA BAŞVURU YAPILACAK YETKİLİ MAKAM </strong></p>
            <p>Satın alınan mal/hizmet hakkında Alıcı’nın şikâyet veya itiraz başvurusunu, Bakanlıkça her yıl Aralık
                ayında belirlenen ve Resmi Gazete’de yayımlanan parasal sınırlar dahilinde malın satın alındığı veya
                ikametgahının bulunduğu yerin Tüketici Sorunları Hakem Heyetine veya Tüketici Mahkemesine yapması
                gerekmektedir.</p>
        </div>
    </div>

    <div id="distanceAgreement_en" #distanceAgreement_en
        [hidden]="!(selectedLanguage.CultureCode=='en' && DistanceAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>DISTANCE SALE AGREEMENT</strong></p>
            <p><br><strong>1. PARTIES</strong></p>
            <p>This Distant Sale Agreement (<strong>“Agreement”</strong>) has been read, accepted signed by and between
                the following parties under the following terms and conditions.</p>
            <p><strong>SELLER</strong></p>
            <p>
                Name/ Surname / Title : RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ
                <br>Address : Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                Ümraniye/İstanbul
                <br>Mersis No :0384051355400001
                <br>Tel No : 0850 3407880
                <br>E-mail address : tr@rainintl.com
            </p>
            <p><strong>BUYER</strong></p>
            <p>
                Name/ Surname/ Title : {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}
                <br>Address : {{userService?.shippingAddress?.Street1}} {{userService?.shippingAddress?.Street2}}
                {{userService?.shippingAddress?.City}} {{userService?.shippingAddress?.StateName}}
                {{userService?.shippingAddress?.PostalCode}} {{userService?.shippingAddress?.CountryCode}}
                <br>Telephone : {{userService?.shippingAddress?.Phone}}
                <br>E-mail address : {{userService?.shippingAddress?.Email}}
            </p>
            <p>BUYER and SELLER shall be referred separately as Party and collectively as Parties.</p>
            <p>
            </p>
            <ul style="margin-left:1rem;">
                <li>
                    By accepting this contract via <a href="https://rainintl.com/">https://rainintl.com/</a> website,
                    the BUYER agrees that, <strong>PRE-INFORMATION FORM</strong> and this Agreement has been read
                    vigilantly and all provisions and the BUYER Rights has been understood and accepted without no
                    objection.
                </li>
                <li>
                    In the event the BUYER is a merchant real or legal person and/or if invoice for the product is
                    issued in the name of merchant BUYER; BUYER is not entitled to take advantage of consumer rights and
                    the provisions under the Contract and Law, which confers exclusive rights and means on consumers.
                </li>
            </ul>
            <p></p>
            <p><strong>2. SUBJECT OF THE AGREEMENT</strong></p>
            <p>The subject of this Agreement is to determine the rights and obligations of the Parties in accordance
                with the provisions of the <u>Turkish Law on the Protection of Consumer No 6502 and Regulation on
                    Distance Sales Contracts dated 27.10.2014</u> related to the sale and delivery of the Products
                specified in the following qualifications and sales price, which the BUYER has ordered in electronic
                environment through the website <a href="https://rainintl.com/">https://rainintl.com/</a>
                (<strong>“Website”</strong>).</p>
            <p>BUYER acknowledges and declares that; i) she/he has been well informed about the Product ii)
                specifications, sale price, payment methods, delivery conditions of Product; and iii) all pre
                informations and “withdrawal” right, besides; iv) she/he has been verified the pre informations on
                Website and made an order upon these informations. </p>
            <p><strong>3. PURCHASED PRODUCT(S) SUBJECT TO AGREEMENT</strong></p>
            <p>Specifications, quantity, type/model, color, sale price and delivery information of Products are as
                listed below. </p>
            <div *ngIf="getItems('order')?.length > 0">
                <table style="border-collapse: collapse;" class="table table-bordered">
                    <thead style="vertical-align:bottom;border-bottom-width:2px;">
                        <tr
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Order Code</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Product Name</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Unit Price</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Quantity</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Product Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of getItems('order')"
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="orderdata?.OrderID > 0 ? orderdata?.OrderID : ''">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="item.selectedItemName || item.ItemDescription"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(item['PriceType' + userService.customerTypeID + 'Value'] || item.PriceEach) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(getQuantityModel('order', item)[item.ItemCode] || item.Quantity)"></td>
                            <!-- {{item | json}} -->
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="((item['PriceType' + userService.customerTypeID + 'Value'] || item.PriceEach) * item.Quantity) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>
                <strong>Payment And Delivery Details</strong>
                <br>Total product price excluding cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Subtotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.ShippingTotal ?  orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Total product price including all taxes and cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Total || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Payment Method:
                <span>Credit/Debit Card</span>
                <br>
                Number of installments: 0
                <br>Received carrying charge: 0
                <br>Interest rate used in carrying charge: 0
                <br>Additional cost to be paid by the Buyer: 0
                <br>Delivery address: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Person to be delivered: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}

            </p>

            <p>The Product(s) will be delivered to the address of the BUYER stated below. Even if the BUYER will not be
                present at the delivery time, the SELLER will be deemed as it has performed its obligations hereunder
                fully and completely. Therefore, any and all losses and damages due to the late receipt of the Product
                by the BUYER, any delay in the delivery thereof attributable to the cargo company and/ or the return of
                the Product to the SELLER shall be solely incurred by the BUYER.</p>
            <p>In the case where the BUYER prefers to benefit from the AutoShip option, as it is stipulated under the
                AutoShip Terms and Conditions, until the BUYER notifies the SELLER regarding the cancellation of the
                AutoShip, the amount stated above shall be charged automatically from the BUYER’s subscribed credit card
                on the 26 day of each month and the amount of products shall be delivered to the BUYER’s subscribed
                delivery address on the 26 day of each month accordingly. </p>
            <p><u>Cargo Fee</u>: Cargo Fee is TRY {{(orderService.calculateOrderResponse.ShippingTotal ?
                orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0) ) | currency:
                configService.selectedMarketConfig.CurrencySymbol}} as stated above and shall be added to the Product
                Price and
                paid by the BUYER In addition to Product Price. The Delivery/Cargo fee shall be paid by the BUYER. The
                SELLER may not be held liable for any failure of delivery of the ordered product to the BUYER for any
                reason whatsoever which is not attributable to the SELLER.</p>
            <p>SELLER shall not be held liable if products are not delivered to within specified time and/or as required
                to the specified person due to fault or negligence of BUYER.</p>
            <p>Product(s) will be delivered to Delivery Address by cargo within latest 30 (thirty) days from the date
                order is received by the SELLER. </p>
            <p>PARTIES agree that alternative product with same quality and price can be delivered to the BUYER in case
                below stated terms are met by the SELLER:</p>
            <p>
                a) If it is understood that product under the contract cannot be delivered due to a valid ground.
                <br>b) BUYER is informed clearly and in a comprehensible way and his/her consent is taken.

            </p>
            <p>In case it becomes impossible to supply the product ordered, SELLER shall inform BUYER within 3 (three)
                days and returns the total amount to the BUYER within 14 (fourteen) days.</p>
            <p>After Pre-information Form is approved and Distance Sale Contract is concluded, if the product price is
                not paid for any reason or if bank payment is cancelled, SELLER will refrain from fulfilling its
                obligation to deliver the product until BUYER pays the product price and informs the SELLER accordingly.
            </p>
            <p><strong>4. FORCE MAJEURE</strong></p>
            <p>If the SELLER cannot deliver product/products under the contract on time due to force majeure conditions,
                weather conditions that prevent delivery, interruption of transportation and other extraordinary
                conditions, it shall inform the BUYER of such condition. In such case BUYER will be entitled to request
                (i) order to be cancelled, (ii) product/products under contract to be replaced with similar products, if
                any and/or (iii) delay of delivery time until force majeure condition/condition that prevents delivery
                ends. If the BUYER cancels the order, the total amount paid shall be fully paid in cash within 14
                (fourteen) days. For any payment made by the BUYER via credit card, price of product(s) will be refunded
                to the relevant bank within 3 (three) business days after order is cancelled by the BUYER. Transfer of
                such amount to the BUYER's bank accounts after refund of this amount to the bank depends entirely to the
                bank transaction time and BUYER understands and accepts that it will be impossible for SELLER to
                interfere in any possible delays and that SELLER shall not be held responsible for the time required to
                transfer the amount refunded to the credit card of the BUYER by the SELLER, to the bank account of the
                BUYER.</p>
            <p>5. PAYMENT METHOD and TERMS</p>
            <p>BUYER shall make payment in cash or by installment via credit card or bank card.</p>
            <p>It is stated that how many installments will be used to pay the amount of the order to be transmitted to
                your bank in the Page of ORDER SUMMARY. </p>
            <p>Your Bank may be applied high number of installments over you choose via special offers or provide
                installment postponing services. Such type of offers and services are in sole discretion of your bank
                and SELLER does not undertake any services and/offers in this context.</p>
            <p>In installment transactions the provisions of the contract concluded between the BUYER and the bank
                issued the relevant card shall apply. </p>
            <p>The payment dates for the credit card shall also be determined between the bank and the BUYER as per
                their agreement. The BUYER is entitled to follow the number of installments and payments thereof on the
                account summary to be sent by the bank.</p>
            <p>If the BUYER will fail to make any payment related to the transactions conducted with a credit card,
                he/she will pay the interest amount and be liable against the bank pursuant to the credit card contract
                concluded between him/her and the bank. In such case the Bank may use applicable legal remedies; request
                the refunding of any legal expenses and attorney fees from the BUYER, and the BUYER agrees to pay any
                losses and damages incurred by the SELLER due to the delayed performance of the debt by the BUYER, in
                any case.</p>
            <p>If relevant bank or finance institution does not disburse the product price to the SELLER after delivery
                of product due to unjust and illegal use of credit card of the BUYER by unauthorized persons, BUYER must
                return product delivered to it within 3 (three) business days. In such case, cargo expenses shall not be
                covered by the SELLER.</p>
            <p>If the BUYER will use its cancellation right, or if the Product subject to the Contract may not be
                supplied for any reason whatsoever or if it will be decided by the Arbitration Committee to return the
                price of the order to the Consumer, the return procedure of the amount to the credit card shall be as
                following:</p>
            <p>a) The Bank may perform the return in installments in line with the number of installments the BUYER
                preferred in the payment. </p>
            <p>b) In the event of return of the goods and services paid through a credit card, the SELLER may not return
                the collected amount in cash pursuant to its agreement with the bank. The return to the credit card will
                be conducted as defined above by the bank upon the return of the amount by the SELLER in single amount.
                In the payments performed through payment at the door or bank transfer/ EFT transfer, the return will be
                performed through bank transfer to the designated bank account of the Client (the account holder must be
                the person named in the invoice).</p>
            <p><strong>6. WITHDRAWAL RIGHT OF BUYER</strong></p>
            <p>BUYER has the right to withdraw from the contract by rejecting/returning product/products within 14
                (fourteen) days from the date product/products are received or delivered to third person/organization at
                the specified address and the products will be received back, <strong>PROVIDED THAT THE TRANSACTION AND
                    PRODUCTS ARE NOT IN CONTEXT OF ART. 6.1. AS NOT ALLOWED PRODUCTS FOR WITHRAWAL RIGHTS</strong>, as
                of the date withdrawal notice is served to the SELLER.</p>
            <p>To use right of withdrawal, within the above specified time following notice should be sent to the SELLER
                stating the intent to use this right;</p>
            <p>In writing to the SELLER's address at Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8
                D İç Kapı No: 7 Ümraniye/İstanbul <strong>(The product to be returned shall be posted to the : Orta
                    Mahallesi Burgaz Caddesi No:11 Tuzla/İstanbul – Hitittrans)</strong> or; By email sent to <a
                    href="mailto:tr@rainintl.com">tr@rainintl.com</a> address via sustainable data bearer or via Contact
                form on <a href="https://rainintl.com/">https://rainintl.com/</a> link)</p>
            <p>When the BUYER uses his/her right of withdrawal, following needs to be delivered to the SELLER;</p>
            <p>
                a) Invoice of the products delivered to the BUYER or the person assigned by the BUYER to receive the
                product.
                <br>b) Complete and damage-free products together with their box, package, standard accessories, if any.
            </p>
            <p>If the BUYER is a merchant real or legal person and/or if invoice for the product under the contract is
                issued in the name of merchant BUYER and if the BUYER wishes to return the product, any consumer rights
                being excluded, product must be returned attached with the return invoice to be issued then. (In the
                event the BUYER is a merchant real or legal person and/or if invoice for the product is issued in the
                name of merchant BUYER; BUYER is not entitled to take advantage of consumer rights and the provisions
                under the Contract and Law, which confers exclusive rights and means on consumers.)</p>
            <p>In the event that value of products returned by BUYER within the scope of right of withdrawal is
                decreased due to fault of the BUYER or is destroyed due to fault of the BUYER, BUYER has to indemnify
                SELLER against all loss in value or reimburse total value of products.</p>
            <p>If the BUYER uses his/her right of withdrawal, SELLER shall make necessary attempts to deposit the price
                of the product to the bank account or credit card of the BUYER within latest 14 (fourteen) days from the
                date of withdrawal notice is served to it.</p>
            <p>Product to be returned based on right of withdrawal shall be collected by the courier company from the
                address specified by the BUYER within latest 10 (ten) days as of the date of withdrawal notice is served
                to the SELLER. cargo fee of products returned to the SELLER without sending a withdrawal notice shall be
                covered by the BUYER. BUYER accepts to pay the cargo fee in such case.</p>
            <p><strong>7. PRODUCTS FOR WHICH RIGHT OF WITHDRAWAL IS NOT ALLOWED</strong></p>
            <p>BUYER shall have no right to Withdrawal on Agreements in the following conditions:</p>
            <ul style="margin-left:1rem;">
                <li>
                    Agreements for goods or services whose price varies depending on fluctuations in financial markets
                    and which are not under the control of the Seller or supplier,
                </li>
                <li>
                    Agreements related to the products which is prepared in accordance with the consumer’s needs or
                    personal requests,
                </li>
                <li>
                    Agreements related to the delivery of goods that may deteriorate quickly or expire,
                </li>
                <li>
                    Agreements for the delivery of products whose protective elements such as packaging, tape, seal,
                    package have been opened after the delivery and their return is not suitable for health and hygiene,
                </li>
                <li>
                    Agreements for goods that are mixed with other products after delivery and which cannot be separated
                    due to their nature,
                </li>
                <li>
                    Agreements related to books, digital content and computer consumables presented in tangible
                    environment in case protective elements such as packaging, tape, seal, package are opened after
                    delivery of the goods,
                </li>
                <li>
                    Agreements for the delivery of periodical publications such as newspapers and magazines, except
                    those provided under the subscription agreement,
                </li>
                <li>
                    Agreements related to accommodation, transportation of goods, car rental, food and beverage supply
                    and the evaluation of leisure time for entertainment or recreation purposes, which must be carried
                    out on a specific date or period,
                </li>
                <li>
                    Agreements for services performed instantly in electronic environment or for intangible products
                    delivered to consumers instantly,
                </li>
                <li>
                    Agreements for services which are executed with the approval of the consumer before the expiration
                    of right of withdrawal period.
                </li>
            </ul>
            <p><strong>8. REPORT ISSUANCE IN THE EVENT OF DELIVERY OF INCOMPLETE PRODUCTS</strong></p>
            <p>If there will be more than one product in the order of the BUYER, the SELLER will be entitled to send the
                consignment partially. The BUYER is obligated to control the number of the Products whether it fits with
                the number of the Products indicated on the invoice. The SELLER will issue a separate invoice for any
                partial consignment delivery. If the number of the products indicated on the applicable invoice are not
                contained in the consignment the BUYER is obligated to request from the cargo officer to issue a report
                for missing delivery. Otherwise, it shall be deemed as if the number of the Products specified on the
                invoice has been delivered to the BUYER completely and without any missing item.</p>
            <p><strong>9. SETTLEMENT OF DISPUTES AND COMPETENT JURISDICTION</strong></p>
            <p>The Consumer Arbitration Committees and the Consumer Courts of Republic of Turkey shall settle any
                dispute between the Parties related to the Agreement up to the value as declared by the Ministry of
                Customs and Trade (TR) according to Turkish Law.</p>
            <table style="border-collapse: collapse; text-align: center;" class="table table-bordered">
                <tbody>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            SELLER</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            BUYER</td>
                    </tr>
                    <tr
                        style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _________________________</td>
                        <td
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            _________________________</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div id="distanceAgreement_tr" #distanceAgreement_tr
        [hidden]="!(selectedLanguage.CultureCode=='tr' && DistanceAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>MESAFELİ SATIŞ SÖZLEŞMESİ</strong></p>
            <p><strong>1- TARAFLAR</strong></p>
            <p>Mesafeli Satış Sözleşmesi (“Sözleşme”) aşağıdaki şartlara uygun olarak taraflarca okunmuş, kabul edilmiş
                ve imzalanmıştır.</p>
            <p><strong>SATICI</strong></p>
            <p>
                Ticaret Unvanı: RAIN INTERNATIONAL BESLENME ANONİM ŞİRKETİ
                <br>Adres: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                Ümraniye/İstanbul
                <br>MERSİS numarası: 0384051355400001
                <br>Telefon numarası: 0850 3407880
                <br>E-mail : tr@rainintl.com
            </p>
            <p><strong>ALICI</strong></p>
            <p>
                Ad/Soyad/Unvan : {{userService?.shippingAddress?.FirstName}} {{userService?.shippingAddress?.LastName}}
                <br>Adres : {{userService?.shippingAddress?.Street1}} {{userService?.shippingAddress?.Street2}},
                {{userService?.shippingAddress?.City}}, {{userService?.shippingAddress?.StateName}},
                {{userService?.shippingAddress?.PostalCode}}, {{userService?.shippingAddress?.CountryCode}}
                <br>Telefon : {{userService?.shippingAddress?.phone}}
                <br>E-mail adresi : {{userService?.shippingAddress?.Email}}
            </p>
            <p>ALICI ve SATICI ayrı ayrı Taraf, hep beraber Taraflar olarak anılacaktır. </p>
            <p>ALICI, https://rainintl.com/ web sitesi (“Web Sitesi”) aracılığıyla işbu Sözleşme’yi kabul ederek,
                aşağıda yer alan ürün bedeli ve ek masrafları ödeme yükümlülüğü altına girdiğini, Ön Bilgilendirme
                Formu’nun ve işbu Sözleşme’nin dikkatlice okunmuş olduğunu ve herhangi bir itiraz olmaksızın bütün
                hükümlerin ve ALICI haklarının ve yükümlülüklerinin anlaşılmış olduğunu kabul eder.</p>
            <p>ALICI’nın gerçek veya tüzel kişi tacir olması ve/veya ürün için düzenlenen faturanın ALICI tacir adına
                düzenlenmesi durumunda; ALICI’nın, tüketici haklarından ve Kanun ile işbu Sözleşme tarafından
                tüketicilere tanınmış münhasır hak ve imkanlardan yararlanmaya hakkı yoktur.</p>
            <p><strong>2- SÖZLEŞMENİN KONUSU</strong></p>
            <p>Sözleşme’nin konusu, 6502 Sayılı Tarafların Tüketicinin Korunması Hakkında Kanun ve 27.10.2014 tarihli
                Mesafeli Sözleşmeler Yönetmeliği hükümleriyle uyumlu olarak, aşağıda nitelik ve satış fiyatları
                belirtilen ve ALICI’nın elektronik ortamda Web Sitesi aracılığıyla sipariş ettiği ürünlerin satış ve
                teslimatı ile ilgili, Tarafların hak ve yükümlülüklerini belirlemektir. </p>
            <p>ALICI; i) Ürün ii) Ürünün özellikleri, satış fiyatı, ödeme yöntemleri, Ürünün teslimat koşulları; iii)
                bütün ön bilgiler ve “cayma” hakkı hususlarında yeterli şekilde bilgilendirildiğini, ayrıca Web
                Sitesi’nden ön bilgileri doğruladığını ve eğer opsiyon olarak otomatik teslimat seçeneği seçildi ise
                Otomatik Teslimat Hüküm ve Koşulları çerçevesinde siparişini bu bilgiler üzerine yaptığını kabul ve
                beyan eder. </p>
            <p><strong>3- SÖZLEŞME KONUSU SATIN ALINMIŞ ÜRÜN(LER) VE TESLİMAT BİLGİLERİ </strong></p>
            <div *ngIf="getItems('order')?.length > 0">
                <table style="border-collapse: collapse; text-align: center;" class="table table-bordered">
                    <thead style=" vertical-align:bottom;border-bottom-width:2px;">
                        <tr
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Sipariş Kodu/Numarası</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Ürün adı</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Birim Fiyat</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Miktar</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Ürün Bedeli</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of getItems('order')"
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="orderdata?.OrderID > 0 ? orderdata?.OrderID : ''">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="item.selectedItemName || item.ItemDescription"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(item['PriceType1Value'] || item.PriceEach) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(getQuantityModel('order', item)[item.ItemCode] || item.Quantity)"></td>
                            <!-- {{item | json}} -->
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="((item['PriceType1Value'] || item.PriceEach) * item.Quantity) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>
                <strong>Ödeme Ve Teslimat Detayları</strong>
                <br>Nakliye fiyatı hariç ürün toplam fiyatı: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Subtotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Kargo bedeli: <span
                    [textContent]="(orderService.calculateOrderResponse.ShippingTotal ?  orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Tüm vergiler ve nakliye fiyatı dahil olmak üzere ürün toplam fiyatı: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Total || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Ödeme şekli:
                <span>Kredi Kartı</span>
                <br>
                Taksit sayısı: 0
                <br>Tahsil edilen nakliye bedeli: 0
                <br>Nakliye bedeline işleyen faiz oranı?: 0
                <br>ALICI tarafından ödenecek ek masraf: 0
                <br>Teslimat adresi: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Teslim edilecek kişi: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}
            </p>

            <p>Ürün(ler) ALICI’nın yukarıda belirtilen adresine teslim edilecektir. ALICI teslimat sırasında hazır
                bulunmasa bile, SATICI aşağıdaki yükümlülüklerini tam ve eksiksiz olarak yerine getirmiş sayılacaktır.
                Bu nedenle Ürünün ALICI tarafından geç teslim alınması, kargo şirketine isnat edilebilecek herhangi bir
                gecikme ve/veya Ürünün SATICI’ya iadesinde yaşanacak gecikmelerden ötürü ortaya çıkabilecek bütün
                zararlar, münferiden ALICI tarafından yüklenilecektir.</p>
            <p>ALICI’nın otomatik teslimat opsiyonunu seçmesi halinde, Otomatik Teslimat Hüküm ve Koşulları’nda
                belirtildiği üzere, ALICI tarafından iptal bildirimi yapılıncaya kadar, yukarıda bildirilen ürün bedeli
                her ayın 26 gününde ALICI’nın bildirmiş olduğu kredi kartından tahsil edilecek ve işbu madde kapsamında
                belirtilen miktarda ürün, her ayın 26 gününde ALICI’nın belirtilen adresine otomatik olarak
                gönderilecektir.</p>
            <p><u>Kargo Bedeli</u>: Kargo Bedeli yukarıda belirtildiği gibi
                {{(orderService.calculateOrderResponse.ShippingTotal ?
                orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0) ) | currency:
                configService.selectedMarketConfig.CurrencySymbol}} TL olup, Ürün Bedeline eklenecek ve
                ALICI tarafından ödenecektir. SATICI, sipariş edilen ürünün ALICI’ya teslimatında kendisine isnat
                edilemeyecek bir sebepten dolayı herhangi bir aksaklık yaşanırsa bu durumdan sorumlu tutulamayacaktır.
            </p>
            <p>SATICI, eğer ürünler ALICI’nın kusuru veya ihmali sonucunda belirlenen saatte ve/veya belirlenen kişiye
                teslim edilmemişse, sorumlu tutulmayacaktır.</p>
            <p>Ürün(ler), SATICI tarafından siparişin alındığı tarihten itibaren en geç 30 (otuz) gün içerisinde kargo
                yoluyla yukarıda bilgileri verilen Teslimat Adresine gönderilecektir.</p>
            <p>Taraflar, aşağıdaki koşulların gerçekleşmesi durumunda aynı kalite ve fiyatta olan alternatif
                ürünlerin/ürünün SATICI tarafından ALICI’ya teslimatının yapılabileceği konusunda mutabık kalmışlardır:
            </p>
            <p>
                a) Sözleşme’ye konu olan ürünün haklı sebep yüzünden teslim edilememesi,
                <br>b) ALICI açık bir şekilde bilgilendirilmiş ve rızası alınmış olması.
            </p>
            <p>Sipariş edilen ürünün tedariğinin imkansızlaşması durumunda SATICI, ALICI’yı 3 (gün) içerisinde
                bilgilendirir ve toplam miktarı ALICI’ya 14 (on dört) gün içinde iade eder.</p>
            <p>ALICI’nın, otomatik teslimat opsiyonunu seçmesi durumunda Otomatik Teslimat Hüküm ve Koşulları
                doğrultusunda, dilediği zaman “Rain Türkiye Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt.
                Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul” adresine veya e-mail yoluyla tr@rainintl.com adresine iptal
                bildirimi göndermek suretiyle bu programı sonlandırma hakkına sahiptir. İptal bildiriminin programlanmış
                Otomatik teslimat gönderiminden en az 5 (beş) gün önceden SATICI’ya ulaştırılması gerekmektedir. Aksi
                takdirde, iptak bildirimi, programlanmış gönderimden sonra işleme alınacaktır. </p>
            <p>Ön Bilgilendirme Formu’nun onaylanması ve Mesafeli Satış Sözleşmesi’nin akdedilmesi sonrasında ürün
                bedeli herhangi bir nedenden dolayı ödenmez veya banka ödemesi iptal olursa, SATICI, ALICI ürün bedelini
                ödeyene kadar ürünü teslim etme yükümlülüğünden kaçınacak ve ALICI’yı bu doğrultuda bilgilendirecektir.
            </p>
            <p>SATICI’nın kusurundan kaynaklanan nedenlerle sözleşmede belirtilen nitelikte mal veya hizmet sunulamaması
                halinde tüketici ALICI, 6502 sayılı Tüketicinin Korunması Hakkında Kanun’un 11 ve 15. maddeleri
                kapsamında, hizmetin yeniden görülmesi, hizmet sonucu ortaya çıkan eserin ücretsiz onarımı, ayıp
                oranında bedelden indirim veya sözleşmeden dönme haklarından birini SATICI’ya karşı kullanmakta
                serbesttir. SATICI, ALICI’nın tercih ettiği bu talebi yerine getirmekle yükümlüdür. Seçimlik hakların
                kullanılması nedeniyle ortaya çıkan tüm masraflar SATICI tarafından karşılanır. ALICI, bu seçimlik
                haklarından biri ile birlikte Türk Borçlar Kanunu hükümleri uyarınca tazminat da talep edebilir.
                Ücretsiz onarım veya hizmetin yeniden görülmesinin SATICI için orantısız güçlükleri beraberinde
                getirecek olması hâlinde ALICI bu hakları kullanamaz. </p>
            <p>Orantısızlığın tayininde hizmetin ayıpsız değeri, ayıbın önemi ve diğer seçimlik haklara başvurmanın
                ALICI açısından sorun teşkil edip etmeyeceği gibi hususlar dikkate alınır.</p>
            <p><strong>4- MÜCBİR SEBEP</strong></p>
            <p>Eğer SATICI Sözleşme’ye tabi ürünü/ürünleri, mücbir sebep, teslimatı engelleyen iklim koşulları, ulaşımın
                kesintiye uğraması veya başka olağanüstü koşullar nedeniyle teslim edemezse, ALICI’yı bu durumdan
                haberdar edecektir. Böyle bir durumda ALICI, (i) siparişin iptal edilmesini, (ii) sözleşmeye konu
                ürünün/ürünlerin benzer ürünlerle değiştirilmesini, (iii) teslimatı engelleyen mücbir sebep/olağanüstü
                koşul sona erene kadar teslimat süresinin ertelenmesini talep etmeye hak kazanacaktır. Eğer ALICI
                siparişi iptal ederse, toplam ödenmiş miktarın 14 (on dört) gün içerisinde iade edilmesi gerekmektedir.
                ALICI’nın kredi kartıyla yapmış olduğu herhangi bir ödeme için ürün/ürünlerin bedeli sipariş ALICI
                tarafından iptal edildikten sonra 3 (üç) iş günü içerisinde ilgili bankaya iade edilecektir. Söz konusu
                miktarın bankaya iadesinden sonra ALICI’nın banka hesabına aktarılması tamamen banka işlem süresine
                bağlıdır. Bu sebeple ALICI, SATICI’nın herhangi bir muhtemel gecikmeye müdahale etmesinin imkansız
                olduğunu ve SATICI’nın, SATICI tarafından ALICI’nın kredi kartına iade edilen miktarın ALICI’nın banka
                hesabına transferi için gereken süreden dolayı sorumlu tutulamayacağını anlar ve kabul eder.</p>
            <p><strong>5- ÖDEME YÖNTEMİ VE KOŞULLARI</strong></p>
            <p>ALICI ödemeyi nakit olarak, banka kartı ile ya da kredi kartı ile yapar.</p>
            <p>Bankaya iletilecek sipariş bedeli için kaç taksit kullanılacağı “SÖZLEŞME KONUSU SATIN ALINMIŞ ÜRÜN(LER)
                VE TESLİMAT BİLGİLERİ” bölümünde belirtilmiştir. </p>
            <p>SATICI, satışa konu olan ürünü taksitle satmamaktadır. Taksit işlemlerinde ilgili kartı düzenleyen banka
                ve ALICI arasında akdedilmiş olan sözleşmenin hükümleri uygulanacaktır.</p>
            <p>Kredi kartı ile ödeme halinde, ödeme tarihleri de mutabakatlarının bir parçası olarak banka ve ALICI
                tarafından belirlenecektir. ALICI taksit sayısını ve bu taksitlerin ödemelerini banka tarafından
                gönderilecek hesap özeti üzerinden edecektir. </p>
            <p>Eğer ALICI kredi kartıyla gerçekleştirilen ödeme işlemlerinden herhangi birini yerine getirmezse, faiz
                miktarını ödeyecek ve banka ile arasında akdedilmiş sözleşmeye uygun olarak bankaya karşı sorumlu
                olacaktır. Böyle bir durumda, Banka hukuki çözüm yollarını kullanarak ALICI’dan herhangi bir vekalet
                ücreti veya yargılama giderini talep edebilir. ALICI her halukarda, kendisinin borçlu olduğu edimin
                gecikmiş ifası nedeniyle SATICI tarafından maruz kalınan herhangi bir kayıp ve zararı karşılayacağını
                kabul eder.</p>
            <p>Ön Bilgilendirme Formu onaylandıktan ve Mesafeli Satış Sözleşmesi akdedildikten sonra Ürün bedeli
                herhangi bir sebepten dolayı ödenmediyse veya banka ödemesi iptal edilirse, SATICI, ALICI ürün bedelini
                ödeyip SATICI’yı bu konuda bilgilendirene kadar, ürünü teslimat yükümlülüğünü ifa etmeyi durduracaktır /
                ifayı askıya alacaktır.</p>
            <p>Eğer ilgili banka veya finansal kurum ALICI’nın kredi kartının yetkisiz kişiler tarafından hukuksuz ve
                yasadışı kullanımı yüzünden teslimatı yapılmış ürünün bedelini SATICI’ya ödemezse, ALICI teslim edilmiş
                ürünü 3 (üç) gün içerisinde iade edecektir. Böyle bir durumda kargo bedeli SATICI tarafından
                karşılanmayacaktır.</p>
            <p>ALICI iptal hakkını kullanırsa, Sözleşme’ye konu Ürün herhangi bir sebep yüzünden tedarik edilmezse veya
                yetkili Tüketici Hakem Heyeti tarafından sipariş bedelinin Tüketici’ye iadesine karar verilirse, bedelin
                kredi kartına iade usulü aşağıdaki gibi olacaktır:</p>
            <p>
                a) Banka iadeyi, ALICI’nın ödeme sırasında tercih ettiği taksit sayısıyla doğru orantılı şekilde
                taksitli olarak gerçekleştirebilir.
                <br>b) SATICI, kredi kartıyla ödenmiş mal ve hizmetlerin iadesi durumunda bankayla arasındaki sözleşmeye
                uygun olarak, tahsil edilmiş miktarı nakden iade etmeyebilir. Kredi kartına iade banka tarafından
                yukarıda tanımlandığı gibi SATICI’nın ücreti tek seferde iadesi üzerine iletilecektir. Kapıda ödeme veya
                banka/EFT transferi aracılığıyla gerçekleştirilen ödemelerde, iade Müşteri’nin belirlenmiş banka
                hesabına banka transferi aracılığıyla yapılacaktır (hesap sahibinin faturada belirtilen kişi olması
                gerekmektedir).
            </p>
            <p><strong>6- ALICI’NIN CAYMA HAKKI</strong></p>
            <p>ALICI, SATICI’ya cayma bildiriminin yapıldığı tarihte <strong>CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER OLARAK
                    MADDE 7. KAPSAMINDA OLMAMASI ŞARTIYLA</strong>, ürün/ürünlerin teslim alınma veya üçüncü kişi/kuruma
                belirtilen adreste teslimat tarihinden itibaren 14 (on dört) gün içinde ürünü/ürünleri reddederek veya
                iade ederek Sözleşme’den cayma hakkına sahiptir ve ürünler SATICI tarafından geri alınacaktır. </p>
            <p>Cayma hakkının yukarıda belirtilen zaman diliminde kullanılması için aşağıdaki bildirimin söz konusu
                hakkı kullanma niyetini belirtecek şekilde SATICI’ya gönderilmesi gerekmektedir;</p>
            <p>Cayma bildirimi; yazılı olarak SATICI’nın Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok
                No:8 D İç Kapı No: 7 Ümraniye/İstanbul <strong>(Cayma hakkı kulanılan ürün şu adrese gönderilmelidir:
                    Orta Mahallesi Burgaz Caddesi No:11 Tuzla/İstanbul – Hitittrans)</strong> adresine veya Web
                Sitesinde yer alan İletişim Formu veya kalıcı veri taşıyıcısı aracılığıyla tr@rainintl.com adresine
                e-mail yoluyla iletilebilir.</p>
            <p>ALICI cayma hakkını kullandığında aşağıdakilerin SATICI’ya teslim edilmesi gerekmektedir;</p>
            <p>
                a) ALICI’ya veya ALICI tarafından ürünü teslim alması için atanmış kişiye teslim edilen ürünlerin
                faturası.
                <br>b) Olması halinde kutu, paket ve standart aksesuarlarıyla birlikte, tam ve zarar görmemiş ürünler.
            </p>
            <p>Eğer ALICI gerçek veya tüzel kişi tacirse ve/veya Sözleşme’ye tabi ürünün faturası ALICI tacir adına
                düzenlendiyse ve eğer ALICI ürünü iade etmek istiyorsa, bütün tüketici hakları istisna olmak kaydıyla,
                ürünün ALICI tarafından düzenlenecek iade faturasıyla birlikte iade edilmesi zorunludur. (ALICI’nın
                gerçek veya tüzel kişi tacir olması durumunda ve/veya eğer ürünün faturası tacir ALICI’nın adına
                düzenlenmişse; ALICI, tüketici hakları ve tüketiciye münhasır imkan ve haklar sağlayan Sözleşme ve Kanun
                hükümlerinden yararlanmaya yetkili değildir).</p>
            <p>Cayma hakkı kapsamında ALICI tarafından iade edilen ürünlerin değeri ALICI’nın kusuru yüzünden düşmüşse
                veya bu ürünler ALICI’nın kusuru yüzünden tahrip edilmişse, ALICI, SATICI’nın değer kaybının tümü
                yüzünden uğradığı zararı tazmin etmeli veya ürünlerin toplam değerinin bedelini geri ödemelidir.</p>
            <p>ALICI’nın cayma hakkını kullanması halinde, 5. maddede yer alan hususlar çerçevesinde, SATICI cayma hakkı
                ihbarının kendisine tebliğ edilmesinden itibaren en geç 14 (on dört) gün içinde ürün bedelini ALICI’nın
                banka hesabına yatırmak için gerekli girişimlerde bulunacaktır.</p>
            <p>Cayma hakkı sonucunda iade edilecek ürün, cayma hakkı bildiriminin SATICI’ya tebliğ edilmesinden itibaren
                en geç 10 (on) gün içinde ALICI tarafından belirlenecek kargo firmasınca teslim alınacaktır. Cayma hakkı
                bildirimi gönderilmeden SATICI’ya iade edilen ürünlerin kargolama bedeli ALICI tarafından
                karşılanacaktır. ALICI böyle bir durumda kargolama bedelini ödemeyi kabul eder. </p>
            <p><strong>7- ÜZERİNDE CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</strong></p>
            <p>Aşağıda belirtilen Sözleşmelerde ALICI’nın Cayma hakkı bulunmamaktadır: </p>
            <p>a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve SATICI veya sağlayıcının
                kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler.</p>
            <p>b) Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.
            </p>
            <p>c) Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.</p>
            <p>ç) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi
                sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.</p>
            <p>d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara
                ilişkin sözleşmeler.</p>
            <p>e) Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde
                maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.</p>
            <p>f) Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine
                ilişkin sözleşmeler.</p>
            <p>g) Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama,
                yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine
                ilişkin sözleşmeler.</p>
            <p>ğ) Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi
                mallara ilişkin sözleşmeler.</p>
            <p>h) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin
                sözleşmeler.</p>
            <p><strong>8- EKSİK ÜRÜN TESLİMATI DURUMUNDA RAPOR DÜZENLENMESİ </strong></p>
            <p>ALICI’nın siparişinde birden fazla ürün olması halinde, SATICI malı kısmen gönderme hakkına sahip
                olacaktır. ALICI, Ürün sayısının faturada belirtilen Ürün sayısına uyup uymadığını kontrol etmek
                zorundadır. SATICI her kısmen teslimat için ayrı bir fatura kesecektir. Uygulanabilir faturada
                belirtilen sayı gönderilen sevkiyattaki sayıyla uyuşmuyorsa ALICI, kargo yetkilisinden kayıp teslimat
                için tutanak tutmasını istemek zorundadır. Aksi halde faturada belirtilen sayıda Ürünün ALICI’ya
                herhangi bir kayıp ürün olmadan, tam olarak teslim edildiği kabul edilecektir</p>
            <p><strong>9- UYUŞMAZLIK ÇÖZÜMÜ VE YETKİLİ MAHKEME </strong></p>
            <p>ALICI, satın alınmış mal ve/veya hizmetlerle ilgili şikayetlerini (yukarıda SATICI’NIN Bilgileri
                başlığının altında belirtilmiş iletişim adreslerini kullanarak) doğrudan SATICI’ya bildirebilir.</p>
            <p>Eğer ALICI tarafından şikayette bulunulmuş ise, SATICI sorunu çözmek için mümkün olan bütün desteği
                sağlayacaktır.</p>
            <p>Sözleşme’yle ilgili değeri T.C. Ticaret Bakanlığı’nın Türk hukukuna göre paylaştığı/belirlediği sınıra
                kadar olan bütün uyuşmazlıklar Türkiye Cumhuriyeti Tüketici Hakem Heyetleri ve Tüketici Mahkemeleri
                tarafından çözüme kavuşturulmalıdır. TARİH: {{date}}</p>
            <p><br>SATICI</p>
            <p>Rain International Beslenme A.Ş.</p>
            <p><br><br>ALICI</p>
            <p>{{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}</p>
        </div>
    </div>

    <div id="preliminaryAgreement_en" #preliminaryAgreement_en
        [hidden]="!(selectedLanguage.CultureCode=='en' && PreliminaryAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>PRELIMINARY INFORMATION FORM</strong></p>
            <p>This Preliminary Information Form includes the information that is required to be given by the Seller
                before the execution of the Distant Sales Agreement to be concluded with the Seller whose information is
                given below within the scope of the Law on the Protection of the Consumer No 6502 and the Regulation on
                Distant Sales Agreements. This information is given for commercial purposes, within the framework of the
                principles of good faith, in a way to protect the minors and those who lack the ability to distinguish
                or restricted adults. This Preliminary Information Form is an annex and an integral part of the Distant
                Sales Agreement to be concluded with the Seller whose information is given below.</p>
            <p><strong>Information On The Seller</strong></p>
            <p>
                Title: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ
                <br>Address: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7
                Ümraniye/İstanbul
                <br>Telephone:0384051355400001
                <br>E-mail address : tr@rainintl.com
            </p>
            <p><strong>Information On The Buyer</strong></p>
            <p>
                Buyer’s Full Name/Title: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}
                <br>Buyer’s Address: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Buyer’s Phone: {{userService?.shippingAddress?.Phone}}
                <br>Buyer’s E-mail: {{userService?.shippingAddress?.Email}}
            </p>
            <p><strong>Information On Product And Price</strong></p>
            <div *ngIf="getItems('order')?.length > 0">
                <table style="border-collapse: collapse;" class="table table-bordered">
                    <thead style="vertical-align:bottom;border-bottom-width:2px;">
                        <tr
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Order Code</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Product Name</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Unit Price</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Quantity</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Product Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of getItems('order')"
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="orderdata?.OrderID > 0 ? orderdata?.OrderID : ''">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="item.selectedItemName || item.ItemDescription"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(item['PriceType1Value'] || item.PriceEach) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(getQuantityModel('order', item)[item.ItemCode] || item.Quantity)"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="((item['PriceType1Value'] || item.PriceEach) * item.Quantity) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>
                <strong>Payment And Delivery Details</strong>
                <br>Total product price excluding cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Subtotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.ShippingTotal ?  orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Total product price including all taxes and cargo fee: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Total || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Payment Method:
                <span>Credit/Debit Card</span>
                <br>
                Number of installments: 0
                <br>Received carrying charge: 0
                <br>Interest rate used in carrying charge: 0
                <br>Additional cost to be paid by the Buyer: 0
                <br>Delivery address: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Person to be delivered: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}

            </p>

            <p>In the case where the BUYER prefers to benefit from the AutoShip option, as it is stipulated under the
                AutoShip Terms and Conditions, until the BUYER notifies the SELLER regarding the cancellation of the
                AutoShip, the amount stated above shall be charged automatically from the BUYER’s subscribed credit card
                on the 26 day of each month and the amount of products shall be delivered to the BUYER’s subscribed
                delivery address on the 26 day of each month accordingly.</p>
            <p>The Product(s) will be delivered to the address of the BUYER stated below. Even if the BUYER will not be
                present at the delivery time, the SELLER will be deemed as it has performed its obligations hereunder
                fully and completely. Therefore, any and all losses and damages due to the late receipt of the Product
                by the BUYER, any delay in the delivery thereof attributable to the cargo company and/ or the return of
                the Product to the SELLER shall be solely incurred by the BUYER.</p>
            <p><u>Cargo Fee</u>: Cargo Fee is TRY {{(orderService.calculateOrderResponse.ShippingTotal ?
                orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0) ) | currency:
                configService.selectedMarketConfig.CurrencySymbol}} as stated above and shall be added to the Product
                Price and
                paid by the Buyer In addition to Product Price. The Delivery/Cargo fee shall be paid by the Buyer. The
                Seller may not be held liable for any failure of delivery of the ordered product to the Buyer for any
                reason whatsoever which is not attributable to the Seller.</p>
            <p>Seller shall not be held liable if products are not delivered to within specified time and/or as required
                to the specified person due to fault or negligence of Buyer.</p>
            <p>Product(s) will be delivered to Delivery Address by cargo within latest 30 (thirty) days from the date
                order is received by the Seller. </p>
            <p>Parties agree that alternative product with same quality and price can be delivered to the Buyer in case
                below stated terms are met by the Seller:</p>
            <p>
                a) If it is understood that product under the contract cannot be delivered due to a valid ground.
                <br>b) Buyer is informed clearly and in a comprehensible way and his/her consent is taken.
            </p>
            <p>
                In case it becomes impossible to supply the product ordered, Seller shall inform Buyer within 3 (three)
                days and returns the total amount to the Buyer within 14 (fourteen) days.
            </p>
            <p>After Pre-information Form is approved and Distance Sale Contract is concluded, if the product price is
                not paid for any reason or if bank payment is cancelled, Seller will refrain from fulfilling its
                obligation to deliver the product until Buyer pays the product price and informs the Seller accordingly.
            </p>
            <p><strong>Buyer’s Right Of Withdrawal</strong></p>
            <p>The Buyer may exercise his right of withdrawal within 14 (fourteen) days from the date of receipt of the
                goods by the Buyer or the third party designated by the Buyer in the agreements regarding the delivery
                of the products, and within 14 (fourteen) days from the date of the agreement for the service
                performance, without undertaking any legal and criminal liability and without any justification. The
                Buyer can also use his right of withdrawal within the period starting from the conclusion of the
                agreement until the delivery of the goods.</p>
            <p>The Buyer can exercise his right of withdrawal by filling out the withdrawal form delivered to him/her
                with the order or by delivering an express statement stating the withdrawal decision to the Seller. The
                said form or the express withdrawal statement must be directed to the Seller in writing or with a
                permanent data provider before the expiry of the right of withdrawal. The contact information of the
                Seller where the withdrawal notification can be made is as follows:</p>
            <p>In writing to the SELLER's address at Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8
                D İç Kapı No: 7 Ümraniye/İstanbul <strong>(The product to be returned shall be posted to the : Orta
                    Mahallesi Burgaz Caddesi No:11 Tuzla/İstanbul – Hitittrans)</strong> or; By email sent to
                tr@rainintl.com address via sustainable data bearer or via Contact form on https://rainintl.com/ link)
            </p>
            <p>Within 14 (fourteen) days from the date of receipt of the notification in respect of Buyer’s exercise of
                its right of withdrawal, the Seller shall refund the Buyer all payments made by the Buyer to the Seller
                in respect of the relevant goods or services, including the delivery costs, if any, in a lump in
                accordance with the payment instrument used during the purchase and without imposing any cost or
                liability to the consumer.</p>
            <p>In the event that the Buyer exercises his right of withdrawal, the cargo company specified by the Seller
                that will take the delivery of the product is Aras Cargo. In the event that the right of withdrawal is
                used, if the goods are returned through the cargo company specified herein, the Buyer shall not be
                responsible for the costs related to the return. In the event that the Buyer sends the goods to be
                returned with a cargo company other than Seller’s contracted cargo company set forth hereunder, the
                Seller will not be responsible for the cargo costs for the return and any damages suffered by the goods
                during the cargo process.</p>
            <p>The Buyer is required to return the goods to the Seller within 10 (ten) days from the date of the
                notification in respect of his/her exercise of the withdrawal right. Along with the goods subject to the
                return, the invoice, box, package, standard accessories, if any, of the respective good and other
                products gifted due to the purchase of the said good must be returned to the Seller in full and without
                damage. The Buyer must use the goods in accordance with its operation, technical specifications and
                instructions for use within the withdrawal period, otherwise it will be responsible for any changes and
                deteriorations in the product.</p>
            <p><strong>Circumstances That The Right Of Withdrawal Cannot Be Used</strong></p>
            <p>The Buyer accepts, declares and undertakes that he is aware of the fact that he/she cannot use the right
                of withdrawal in the following cases:</p>
            <p>
            </p>
            <ul style="margin-left:1rem;">
                <li>
                    Agreements for goods or services whose price varies depending on fluctuations in financial markets
                    and which are not under the control of the Seller or supplier,
                </li>
                <li>
                    Agreements related to the products which is prepared in accordance with the consumer’s needs or
                    personal requests,
                </li>
                <li>
                    Agreements related to the delivery of goods that may deteriorate quickly or expire,
                </li>
                <li>
                    Agreements for the delivery of products whose protective elements such as packaging, tape, seal,
                    package have been opened after the delivery and their return is not suitable for health and hygiene,
                </li>
                <li>
                    Agreements for goods that are mixed with other products after delivery and which cannot be separated
                    due to their nature,
                </li>
                <li>
                    Agreements related to books, digital content and computer consumables presented in tangible
                    environment in case protective elements such as packaging, tape, seal, package are opened after
                    delivery of the goods,
                </li>
                <li>
                    Agreements for the delivery of periodical publications such as newspapers and magazines, except
                    those provided under the subscription agreement,
                </li>
                <li>
                    Agreements related to accommodation, transportation of goods, car rental, food and beverage supply
                    and the evaluation of leisure time for entertainment or recreation purposes, which must be carried
                    out on a specific date or period,
                </li>
                <li>
                    Agreements for services performed instantly in electronic environment or for intangible products
                    delivered to consumers instantly,
                </li>
                <li>
                    Agreements for services which are executed with the approval of the consumer before the expiration
                    of right of withdrawal period.
                </li>
            </ul>
            <p></p>
            <p><strong>Seller’s Solution Method For Complaints</strong></p>
            <p>The Buyer may convey his complaints regarding the purchased goods and/or services to the Seller directly
                (using the contact addresses specified under the Seller's Information heading above). If the complaint
                is submitted, the Seller will provide all possible support to resolve the issue.</p>
            <p><strong>Dispute Resolution</strong></p>
            <p>The Consumer Arbitration Committees and the Consumer Courts of Republic of Turkey shall settle any
                dispute between the Parties related to the Agreement up to the value as declared by the Ministry of
                Customs and Trade (TR) according to Turkish Law.</p>
            <p><strong>Other Information:</strong></p>
            <p>As long as there is a reasonable explanation, the Seller may supply another product with equal quality
                and price if Seller notifies the Buyer and obtains Buyer’s express approval before the expiration of its
                contractual obligation.</p>
            <p>In case the Seller fails to fulfill its contractual obligations due to the product or service subject to
                the order becoming impossible to fulfill, the Seller will inform the Buyer in writing or with a
                permanent data storage within 3 (three) days of the date of becoming aware and the Seller will refund
                all charged amount including the delivery costs within 14 (fourteen) days as from the notification at
                the latest.</p>
            <p>By accepting this contract via https://rainintl.com/ website, the Buyer agrees that, this Pre-Information
                Form and Distance Sale Agreement has been read vigilantly and all provisions and the Buyer Rights has
                been understood and accepted without no objection.</p>
            <p>Following the establishment of an agreement between the Buyer and the Seller, a copy of the Preliminary
                Information Form and Distant Sales Agreement will be sent to the Buyer’s e-mail address indicated
                herein.</p>
            <p>Buyer is responsible for the accuracy of his personal information and payment information contained in
                this Preliminary Information Form and Distant Sales Agreement. In the event that this information is
                incomplete or incorrect, the Buyer is obliged to update this information from his account on the website
                https://rainintl.com/ </p>
        </div>
    </div>

    <div id="preliminaryAgreement_tr" #preliminaryAgreement_tr
        [hidden]="!(selectedLanguage.CultureCode=='tr' && PreliminaryAgreement)">
        <div class="col-12 p-l-15 p-r-15">
            <p style="text-align: center;"><strong>ÖN BİLGİLENDİRME FORMU</strong></p>
            <p>İşbu Ön Bilgilendirme Formu, Satıcı tarafından 6502 sayılı Tüketiciyi Koruma Hakkında ve Mesafeli
                Sözleşmeler Yönetmeliği kapsamında paylaşılmış olup, SATICI’yla akdedilecek Mesafeli Satış
                Sözleşmesi’nin ifasından önce verilmesi gereken bilgileri içerir.</p>
            <p>Bilgiler, ticari amaçlar için iyiniyet prensipleri çerçevesinde, küçükleri ve ayırt etme kabiliyeti
                bulunmayanlar veya kısıtlı erginleri koruma hedefiyle/koruyacak şekilde paylaşılmıştır.</p>
            <p>Ön Bilgilendirme Formu, aşağıda bilgileri verilmiş olan ve SATICI’yla ALICI arasında akdedilecek Mesafeli
                Satış Sözleşmesi’nin ayrılmaz bir parçası ve ekidir. </p>
            <p><strong>SATICI Hakkındaki Bilgiler</strong></p>
            <p>
                SATICI’nın Ticaret Unvanı: RAİN INTERNATİONAL BESLENME ANONİM ŞİRKETİ
                <br>SATICI’nın Adresi: Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No:
                7 Ümraniye/İstanbul
                <br>SATICI’nın MERSİS numarası: 0384051355400001
                <br>SATICI’nın telefon numarası: 0850 3407880
                <br>SATICI’nın e-maili: tr@rainintl.com
            </p>
            <p><strong>ALICI Hakkındaki Bilgiler</strong></p>
            <p>
                ALICI’nın Tam Adı/ Unvanı : {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}
                <br>ALICI’nın Adresi : {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>ALICI’nın Telefon Numarası : +90 212 555 12 15
                <br>ALICI’nın E-maili : yess1210@gmail.com
            </p>
            <p><strong>Ürün ve Fiyat Hakkındaki Bilgiler</strong></p>
            <div *ngIf="getItems('order')?.length > 0">
                <table style="border-collapse: collapse;" class="table table-bordered"
                    *ngFor="let item of getItems('order')">
                    <thead style="vertical-align:bottom;border-bottom-width:2px;">
                        <tr
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Sipariş Kodu/Numarası</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Ürün adı</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Birim Fiyat</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Miktar</th>
                            <th
                                style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                                Ürün Bedeli</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of getItems('order')"
                            style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;">
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="orderdata?.OrderID > 0 ? orderdata?.OrderID : ''">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="item.selectedItemName || item.ItemDescription"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(item['PriceType1Value'] || item.PriceEach) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="(getQuantityModel('order', item)[item.ItemCode] || item.Quantity)"></td>
                            <td style="padding: 0.75rem; vertical-align:top; border-top:1px solid #dee2e6;border: 1px solid #dee2e6;"
                                [textContent]="((item['PriceType1Value'] || item.PriceEach) * item.Quantity) | currency: configService?.selectedMarketConfig?.CurrencySymbol">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>
                <strong>Ödeme Ve Teslimat Detayları</strong>
                <br>Nakliye fiyatı hariç ürün toplam fiyatı: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Subtotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Kargo bedeli: <span
                    [textContent]="(orderService.calculateOrderResponse.ShippingTotal ?  orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Tüm vergiler ve nakliye fiyatı dahil olmak üzere ürün toplam fiyatı: <span
                    [textContent]="(orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: ( orderdata?.Order?.Total || 0)  ) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                <br>Ödeme şekli:
                <span>Kredi Kartı</span>
                <br>
                Taksit sayısı: 0
                <br>Tahsil edilen nakliye bedeli: 0
                <br>Nakliye bedeline işleyen faiz oranı?: 0
                <br>ALICI tarafından ödenecek ek masraf: 0
                <br>Teslimat adresi: {{userService?.shippingAddress?.Street1}}
                {{userService?.shippingAddress?.Street2}}, {{userService?.shippingAddress?.City}},
                {{userService?.shippingAddress?.StateName}}, {{userService?.shippingAddress?.PostalCode}},
                {{userService?.shippingAddress?.CountryCode}}
                <br>Teslim edilecek kişi: {{userService?.shippingAddress?.FirstName}}
                {{userService?.shippingAddress?.LastName}}
            </p>

            <p>ALICI’nın otomatik teslimat opsiyonunu seçmesi halinde, Otomatik Teslimat Hüküm ve Koşulları’nda
                belirtildiği üzere, ALICI tarafından iptal bildirimi yapılıncaya kadar, yukarıda bildirilen ürün bedeli
                her ayın 26 gününde ALICI’nın bildirmiş olduğu kredi kartından tahsil edilecek ve işbu madde kapsamında
                belirtilen miktarda ürün, her ayın 26 gününde ALICI’nın belirtilen adresine otomatik olarak
                gönderilecektir.</p>
            <p>SATICI, ALICI teslimat sırasında hazır bulunmasa bile mesafeli satış sözleşmesinden doğan
                yükümlülüklerini tam olarak ifa etmiş sayılacaktır.</p>
            <p>Buna binaen ALICI’nın, Ürünü geç teslim alması durumunda, geç teslimattan kaynaklı herhangi kayıp veya
                zarara, Ürün’ün kargo şirketine ve/veya Satıcı’ya iadesine isnat edilebilecek gecikmenin sonuçlarına
                münferiden ALICI tarafından katlanılacaktır. </p>
            <p><u>Kargo Bedeli</u>: Kargo Bedeli yukarıda belirtildiği gibi
                {{(orderService.calculateOrderResponse.ShippingTotal ?
                orderService.calculateOrderResponse.ShippingTotal: ( orderdata?.Order?.ShippingTotal || 0) ) | currency:
                configService.selectedMarketConfig.CurrencySymbol}} TL’dir. Kargo Bedeli, Ürün Bedeli’ne
                eklenecek ve Ürün Bedeli’ne ek olarak ALICI tarafından ödenecektir.</p>
            <p>Teslimat / Kargo bedeli ALICI tarafından ödenecektir. SATICI, sipariş edilen ürünün kendisine
                yüklenemeyecek bir sebepten dolayı ALICI’ya teslim edilememesi durumunda sorumlu tutulamaz.</p>
            <p>Ürünlerin, ALICI’nın ihmali veya kusuru sonucunda, belirlenmiş zaman içinde ve/veya belirtilen kişiye
                teslim edilememesi durumunda, SATICI’nın sorumluluğuna gidilemez. </p>
            <p>Ürünler, siparişin SATICI’ya ulaştığı tarihten itibaren en geç otuz (30) gün içinde Teslimat Adresi’ne
                teslim edilecektir.</p>
            <p>Taraflar, aşağıdaki koşulların SATICI tahtında meydana gelmesi durumunda aynı kalite ve fiyatta
                alternatif bir ürünün teslimatının yapılabileceğini kabul ederler:</p>
            <p>
                a) Sözleşmeye konu ürünün haklı sebep yüzünden teslim edilemeyeceğinin anlaşılması.
                <br>b) Alıcı’nın açık ve anlaşılır bir biçimde bilgilendirilmiş ve rızasının alınmış olması.
            </p>
            <p>Sipariş edilen ürünün tedarikinin imkansızlaşması durumunda SATICI, ALICI’yı 3 (üç) gün içinde
                bilgilendirip toplam bedeli 14 (on dört) gün içinde ALICI’ya iade etmelidir.</p>
            <p>ALICI, Otomatik Teslimat Hüküm ve Koşulları doğrultusunda, dilediği zaman “Rain Türkiye Necip Fazıl
                Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok No:8 D İç Kapı No: 7 Ümraniye/İstanbul” adresine veya
                e-mail yoluyla tr@rainintl.com adresine iptal bildirimi göndermek suretiyle bu programı sonlandırma
                hakkına sahiptir. İptal bildiriminin programlanmış otomatik teslimat gönderiminden en az 5 (beş) gün
                önceden SATICI’ya ulaştırılması gerekmektedir. Aksi takdirde, iptal bildirimi, programlanmış gönderimden
                sonra işleme alınacaktır. </p>
            <p>Ön Bilgilendirme Formu onaylandıktan ve Mesafeli Satış Sözleşmesi akdedildikten sonra Ürün bedeli
                herhangi bir sebepten dolayı ödenmediyse veya banka ödemesi iptal edilirse, SATICI, ALICI ürün bedelini
                ödeyip SATICI’yı bu konuda bilgilendirene kadar, ürünü teslimat yükümlülüğünü ifa etmeyi durduracaktır /
                ifayı askıya alacaktır.</p>
            <p>SATICI’nın kusurundan kaynaklanan nedenlerle sözleşmede belirtilen nitelikte mal veya hizmet sunulamaması
                halinde tüketici ALICI, 6502 sayılı Tüketicinin Korunması Hakkında Kanun’un 11 ve 15. maddeleri
                kapsamında, hizmetin yeniden görülmesi, hizmet sonucu ortaya çıkan eserin ücretsiz onarımı, ayıp
                oranında bedelden indirim veya sözleşmeden dönme haklarından birini SATICI’ya karşı kullanmakta
                serbesttir. SATICI, ALICI’nın tercih ettiği bu talebi yerine getirmekle yükümlüdür. Seçimlik hakların
                kullanılması nedeniyle ortaya çıkan tüm masraflar SATICI tarafından karşılanır. ALICI, bu seçimlik
                haklarından biri ile birlikte Türk Borçlar Kanunu hükümleri uyarınca tazminat da talep edebilir.
                Ücretsiz onarım veya hizmetin yeniden görülmesinin SATICI için orantısız güçlükleri beraberinde
                getirecek olması hâlinde ALICI bu hakları kullanamaz. Orantısızlığın tayininde hizmetin ayıpsız değeri,
                ayıbın önemi ve diğer seçimlik haklara başvurmanın ALICI açısından sorun teşkil edip etmeyeceği gibi
                hususlar dikkate alınır.</p>
            <p><strong>ALICI’nın Cayma Hakkı </strong></p>
            <p>ALICI, cayma hakkını, herhangi bir hukuki veya cezai sorumluluk altına girmeden ve açıklama olmaksızın,
                ürünlerin ALICI veya ALICI tarafından ürünlerin teslimatı hakkındaki sözleşmelerde belirlenmiş üçüncü
                kişi tarafından teslim alınmasından itibaren ve hizmetin ifası için kurulmuş olan sözleşme tarihinden
                itibaren 14 (on dört) içinde kullanabilir.</p>
            <p>ALICI, cayma hakkını sözleşmenin akdedilmesinden malların teslimatına kadar olan süre zarfında da
                kullanabilir.</p>
            <p>ALICI cayma hakkını, kendisine siparişle birlikte teslim edilmiş cayma formunu doldurarak veya SATICI’ya
                ileteceği, cayma hakkının kullanıldığını belirten açık beyan ile kullanabilir. </p>
            <p>Bahsi geçen form veya açık cayma beyanı yazılı olarak veya sürekli bir veri sağlayıcısı ile cayma
                hakkının süresi dolmadan doğrudan SATICI’ya yöneltilmelidir. </p>
            <p>Cayma bildiriminin yapılacağı SATICI’nın iletişim bilgileri aşağıdaki gibidir:</p>
            <p>Cayma bildirimi; yazılı olarak SATICI’nın Necip Fazıl Mahallesi, Kemerdere Cami Sokak, Ilıcalı Apt. Blok
                No:8 D İç Kapı No: 7 Ümraniye/İstanbul <strong>(Cayma hakkı kulanılan ürün şu adrese gönderilmelidir:
                    Orta Mahallesi Burgaz Caddesi No:11 Tuzla/İstanbul – Hitittrans)</strong> adresine veya Web
                Sitesinde yer alan İletişim Formu veya kalıcı veri taşıyıcısı aracılığıyla tr@rainintl.com adresine
                e-mail yoluyla iletilebilir.</p>
            <p>ALICI’nın cayma bildiriminin alınmasını takip eden 14 (on dört) içinde, SATICI, ALICI’nın SATICI’ya
                ilgili mal ve hizmetler için yaptığı, varsa, teslimat masrafları da dahil olmak üzere tüm ödemeleri
                tüketiciye herhangi bir masraf veya sorumluluk yüklemeden, toptan şekilde ve satın almanın yapıldığı
                ödeme yöntemi ile iade edecektir.</p>
            <p>Eğer ALICI cayma hakkını kullanırsa SATICI tarafından belirtilen ve ürünün teslimatını alacak kargo
                firması Aras Kargo’dur.</p>
            <p>Cayma hakkı kullanıldığı takdirde ürünler yukarıda bahsedilen kargo firması tarafından iade edilmişse
                ALICI iade masraflarından sorumlu olmayacaktır.</p>
            <p>ALICI iade edilecek ürünleri, SATICI’nın burada belirtilen ve anlaşmalı olduğu kargo firması haricinde
                bir kargo firmasıyla gönderirse SATICI ürünlerin sevkiyatta maruz kalacağı hasarlardan ve kargo
                masraflarından sorumlu olmayacaktır.</p>
            <p>ALICI, ürünleri cayma hakkıyla ilgili bildirim tarihinden itibaren 10 (on) gün içinde SATICI’ya teslim
                etmelidir.</p>
            <p>ALICI cayma hakkını kullandığında aşağıdakilerin SATICI’ya teslim edilmesi gerekmektedir;</p>
            <p>
                a) ALICI’ya veya ALICI tarafından ürünü teslim alması için atanmış kişiye teslim edilen ürünlerin
                faturası.
                <br>b) Olması halinde kutu, paket ve standart aksesuarlarıyla birlikte, tam ve zarar görmemiş ürünler.
            </p>
            <p>Satın alınan ürün ile, bahsi geçen ürün satın alındığı için verilen hediye ürünler, varsa, satın alınan
                ürünün standart aksesuarları, paketi, kutusu ve faturası da SATICI’ya eksiksiz ve hasarsız şekilde iade
                edilmelidir.</p>
            <p>ALICI, cayma dönemi zarfında malları kendi faaliyetiyle, teknik özellikleriyle ve talimatlarıyla uyumlu
                şekilde kullanmalıdır, aksi halde üründe meydana gelecek herhangi bir değişiklik veya bozulmadan ALICI
                sorumlu olacaktır.</p>
            <p><strong>Cayma Hakkının Kullanılamayacağı Durumlar </strong></p>
            <p>Aşağıda belirtilen Sözleşmelerde ALICI’nın Cayma hakkı bulunmamaktadır: </p>
            <p>a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve SATICI veya sağlayıcının
                kontrolünde olmayan mal veya hizmetlere ilişkin sözleşmeler.</p>
            <p>b) Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler.
            </p>
            <p>c) Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler.</p>
            <p>ç) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi
                sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler.</p>
            <p>d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara
                ilişkin sözleşmeler.</p>
            <p>e) Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde
                maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.</p>
            <p>f) Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine
                ilişkin sözleşmeler.</p>
            <p>g) Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama,
                yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine
                ilişkin sözleşmeler.</p>
            <p>ğ) Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi
                mallara ilişkin sözleşmeler.</p>
            <p>h) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin
                sözleşmeler.</p>
            <p><strong>SATICI’nın Şikayetler İçin Çözüm Yöntemi</strong></p>
            <p>
            </p>
            <ul style="margin-left: 1rem;">
                <li>
                    ALICI, satın alınmış mal ve/veya hizmetlerle ilgili şikayetlerini (yukarıda SATICI’NIN Bilgileri
                    başlığının altında belirtilmiş iletişim adreslerini kullanarak) doğrudan SATICI’ya bildirebilir.
                </li>
                <li>
                    Eğer ALICI tarafından şikayette bulunulmuş ise, SATICI sorunu çözmek için mümkün olan bütün desteği
                    sağlayacaktır.
                </li>
            </ul>
            <p></p>
            <p><strong>Uyuşmazlık Çözümü</strong></p>
            <p>Sözleşme’yle ilgili, değeri T.C. Ticaret Bakanlığı’nın Türk hukukuna göre paylaştığı sınırlar
                doğrultusunda bütün uyuşmazlıklar Türkiye Cumhuriyeti Tüketici Hakem Heyetleri ve Tüketici Mahkemeleri
                tarafından çözüme kavuşturulmalıdır.</p>
            <p><strong>Diğer Bilgiler</strong></p>
            <p>Makul bir açıklaması olduğu sürece SATICI, ALICI’ya bildirimde bulunmak ve ALICI’nın açık onayını almak
                şartıyla sözleşmesel yükümlülüğünün süresi dolmadan, aynı kalite ve fiyatta başka bir ürün tedarik
                edebilir.</p>
            <p>SATICI’nın sözleşmesel yükümlülüğünü siparişe konu ürün veya hizmetin ifasının imkansızlaşması nedeniyle
                yerine getirememesi durumunda, SATICI durumu öğrenmesinden itibaren 3 (üç) gün içinde yazılı olarak veya
                kalıcı veri saklayıcısı yolu ile ALICI’yı bilgilendirecektir. SATICI kendisine ödenmiş, teslimat
                masrafları da dahil olmak üzere, bütün tutarı bildirimden itibaren en geç 14 (on dört) gün içinde iade
                edecektir.</p>
            <p>ALICI https://rainintl.com/ internet sitesi aracılığıyla bu sözleşmeyi kabul etmekle, yukarıda bilgileri
                yer alan ürün bedeli ve ek masrafları ödeme yükümlülüğü altına girdiğini işbu Ön Bilgilendirme Formu’nu
                ve Mesafeli Satış Sözleşmesi’ni dikkatlice okuduğunu, tüm bu hükümler doğrultusunda haklarını ve
                sorumluluklarını anladığını ve itirazsız şekilde kabul ettiğini beyan eder.</p>
            <p>ALICI ve SATICI arasında kurulan sözleşmeyi müteakip, Ön Bilgilendirme Formu ve Mesafeli Satış
                Sözleşmesi’nin birer kopyası ALICI’nın bu belgede belirtilen e-mail adresine gönderilecektir.</p>
            <p>ALICI, Ön Bilgilendirme Formu ve Mesafeli Satış Sözleşmesi’nde mevcut olan kişisel bilgileri ve ödeme
                bilgilerinin doğruluğundan sorumludur. Söz konusu bilgilerin eksik veya hatalı olmaları durumunda ALICI
                internet sayfasındaki hesabından bahsi geçen bilgiyi güncellemek zorundadır. </p>
        </div>
    </div>
</div>
<section class="container header">
<div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutAlign.lt-md="space-between stretch" style="width: 100%; height: 100%;">

    <div *ngIf="!accountService.isLoggedIn()" fxFlex="40" fxFlex.lt-sm="30" class="first-flex lowercase" fxLayout="row" fxLayoutAlign="start center">
      <a *ngIf="userService.shippingAddress.CountryCode != 'TR'" routerLink="/products" class="nav-text" translate="Shop"></a>
      <a routerLink="/pages/rainrewards" class="nav-text" translate="Rainrewards"></a>
      <a routerLink="/enrollment" class="nav-text" translate="JoinUs"
        *ngIf="!accountService.isLoggedIn() && userService.enrollerInfo?.CustomerStatusID != 6"></a>
      <a routerLink="/pages/learn" class="nav-text" translate="Learn"></a>
      <a routerLink="/pages/aboutus" class="nav-text" translate="WhoWeAre"></a>
      <a routerLink="/pages/contact" class="nav-text" translate="Contact"></a>
    </div>


    <ng-container>
      <div fxFlex="35" fxLayoutAlign="center center" fxFlex.gt-md="20" >
        <img class="logo" routerLink="/home" src="../../../assets/images/logo.png" alt="" class="logo_img">
      </div>
      <div fxFlex="4" fxFlex.gt-md="0"></div>

      
      <div fxLayout="row" fxLayout.lt-md="36" fxLayoutAlign="end center" fxLayoutGap.gt-sm="10px">
        
        <!-- Return To BackOffice -->
        <button fxShow fxHide.lt-md *ngIf="accountService.isLoggedUserDistributorType()" mat-stroked-button (click)="SSOtoBackoffice()"
        class="backofficeRedirectionButton" fxLayoutGap="9px">
          <mat-icon class="mr-10">launch</mat-icon>
          <span translate="ReturnToBackoffice"></span>
        </button>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="end center" fxLayoutGap.lt-md="12px">

          <!-- <button fxHide.lt-sm fxShow mat-stroked-button class="login_btn" (click)="NavigateToLogin()" [textContent]="'Login' | translate"></button> -->


          <!-- shopping_cart -->
          <div fxHide fxShow.lt-sm>
            <mat-icon (click)="sidebarcart.toggle(); cartService.sidebarOpenStatus = !cartService.sidebarOpenStatus"
              aria-hidden="false" aria-label="Example home icon" id="more-vert">shopping_cart</mat-icon>
            <span [ngClass]="{'cartColor': cartCount > 0}" class="badge header-cart xs-header-cart"
              [textContent]="getQuanity()"></span>
          </div>

          <!-- Person -->
          <mat-icon fxHide fxShow.lt-sm *ngIf="accountService.isLoggedIn()" [matMenuTriggerFor]="Account" fxLayoutAlign="center center" aria-hidden="false" aria-label="Example home icon" class="icon_size">account_circle</mat-icon>

          <button *ngIf="!accountService.isLoggedIn()" mat-stroked-button [matMenuTriggerFor]="login" class="login_btn" [textContent]="'Login' | translate"></button>

          <!-- <button *ngIf="accountService.isLoggedIn()" mat-stroked-button (click)="accountService.logout()" class="login_btn"
            [textContent]="'SignOut' | translate"></button>
          <button *ngIf="accountService.isLoggedIn()" mat-stroked-button (click)="SSOtoBackoffice()"
            class="login_btn btn_backoffice" [textContent]="'SSO to backoffice' | translate"></button> -->

          <mat-menu #login="matMenu" class="myHeaderLogin">
            <div fxFlex="100" fxLayoutAlign="center none">
              <div class="login-menu">
                <app-loginmenu (loginSuccess)="onLoginSuccess($event)"></app-loginmenu>
              </div>
            </div>
          </mat-menu>

          <mat-icon *ngIf="accountService.isLoggedIn()" [matMenuTriggerFor]="Account" (menuOpened)="cartService.sidebarSummaryCartDrawer.close(); cartService.sidebarOpenStatus = false;" fxLayoutAlign="center center"
            aria-hidden="false" aria-label="Example home icon" fxHide fxShow.gt-sm
            class="icon_size mr-10">account_circle</mat-icon>

        
          <mat-menu #Account="matMenu" class="myHeaderLogin">

            <!-- Information -->
            <div class="logged-in-menu" *ngIf="this.userService.customerData != '{}' || this.userService.customerData != 'undefined' || this.userService.customerData != 'null'">
              <p [textContent]="'MemberInfo' | translate" class="f-w-700"></p>
              <p [textContent]="('Name' | translate) + ': ' + this.userService.customerData?.FirstName + ' ' + this.userService.customerData?.LastName"></p>
              <p [textContent]="('ID' | translate) + ': ' + this.userService.customerData?.CustomerID"></p>

              <div *ngIf="!accountService.isLoggedUserDistributorType()" [textContent]="('ManageAccount' | translate)" [routerLink]="['/accounts/settings']" class="manage-account-link"></div>
              
              <!-- SSO and Logout -->
              <div fxLayoutGap="10px">
                <!-- <button *ngIf="accountService.isLoggedUserDistributorType()" mat-stroked-button (click)="SSOtoBackoffice()"
                class="login_btn btn_backoffice" [textContent]="'SSO to backoffice' | translate"></button> -->
                <button *ngIf="accountService.isLoggedIn()" mat-stroked-button (click)="accountService.logout()" class="login_btn" [textContent]="'SignOut' | translate"></button>
              </div>
            </div>


            <!-- Menu Links  -->
            <!-- <div fxLayout="column" fxFlex="100" fxLayoutAlign="center stretch" class="account-links">
              <menu mat-menu-item>
                <div class="link" (click)="navigateLink('settings')" [textContent]="'AccountSettings' | translate"></div>
              </menu>
              <menu mat-menu-item>
                <div class="link" (click)="navigateLink('orders')" [textContent]="'Orders' | translate"></div>
              </menu>
              <menu mat-menu-item>
                <div class="link" (click)="navigateLink('subscription')" [textContent]="'Subscription' | translate"></div>
              </menu>
              <menu mat-menu-item>
                <div class="link" (click)="navigateLink('address')" [textContent]="'Addresses' | translate"></div>
              </menu>
              <menu mat-menu-item>
                <div class="link" (click)="navigateLink('paymentmethods')" [textContent]="'YourPaymentMethods' | translate"></div>
              </menu>
              <menu mat-menu-item fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>exit_to_app</mat-icon>
                <div class="link" (click)="navigateLink('logout')" [textContent]="'Logout' | translate"></div>
              </menu>
            </div> -->
            
          </mat-menu>

          <mat-icon (click)="onProdSearch()" fxLayoutAlign="center center" aria-hidden="false"
            aria-label="Example home icon" fxHide fxShow.gt-sm class="icon_size">search</mat-icon>


          <span class="flag-icon flag-icon-{{selectedCountry|| 'us'}} icon_size" (click)="onSelectMarket()"
            aria-hidden="false" aria-label="Example home icon" fxHide fxShow.gt-sm></span>
          <mat-drawer-container class="example-container" hasBackdrop="false">
            <mat-drawer #sidebarcart mode="over" class="ssc_drawer" position="end"
              (ngInit)="this.cartService.sidebarSummaryCartDrawer = sidebarcart">
              <app-sidebar-summary-cart [cartdrawer]="sidebarcart"></app-sidebar-summary-cart>
            </mat-drawer>
            <mat-drawer-content>
              <div fxHide fxShow.gt-sm class="cart"
                (click)="sidebarcart.toggle(); cartService.sidebarOpenStatus = !cartService.sidebarOpenStatus">
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="icon_size">shopping_cart</mat-icon>
                <span [ngClass]="{'cartColor': cartCount > 0, 'cartQuantity': cartCount > 9}" class="badge header-cart"
                  [textContent]="getQuanity()"></span>
              </div>
            </mat-drawer-content>
          </mat-drawer-container>


        </div>
        <div class="first-mobile-flex" fxLayout="row" fxLayoutAlign="end center">
          <mat-drawer-container class="example-container" autosize>
            <mat-drawer class="example-sidenav" #drawer mode="over">
              <div fxLayout="row" class="user_info" fxLayoutAlign="space-between center">
                <div *ngIf="!accountService.isLoggedUserDistributorType()" fxFlex="20" fxLayoutAlign="center center">
                  <mat-icon>person</mat-icon>
                </div>
                <div fxFlex="60" fxLayout="row" fxLayoutAlign="start center">
                  <h3 *ngIf="!accountService.isLoggedUserDistributorType()" class="m-t-15" [textContent]="userService.enrollerInfo?.DisplayName"> </h3>
                  <img *ngIf="accountService.isLoggedUserDistributorType()" class="logo-dark" src="../../../assets/images/logo.png" alt="">
                </div>
                <div fxFlex="20" fxLayoutAlign="center center">
                  <mat-icon (click)="drawer.close()">close</mat-icon>
                </div>
              </div>
              <div class="mobile-menus lowercase" fxLayout="column" fxLayoutAlign="center start">

                <!-- Return To BackOffice -->
                <button fxHide fxShow.lt-md *ngIf="accountService.isLoggedUserDistributorType()" mat-stroked-button (click)="SSOtoBackoffice()"
                class="backofficeRedirectionButton mt-15 mb-10" fxLayoutGap="9px">
                  <mat-icon class="mr-10">launch</mat-icon>
                  <span translate="ReturnToBackoffice"></span>
                </button>

                <a *ngIf="!accountService.isLoggedIn()" routerLink="/" (click)="drawer.close()" class="m-nav-text border-bottom" translate="Home"></a>
                <a *ngIf="!accountService.isLoggedIn() || userService.shippingAddress.CountryCode != 'TR'" routerLink="/products" (click)="drawer.close()" class="m-nav-text border-bottom" translate="Shop"></a>
                <a *ngIf="!accountService.isLoggedIn()" routerLink="/pages/rainrewards" (click)="drawer.close()" class="m-nav-text border-bottom" translate="Rainrewards"></a>
                <a routerLink="/enrollment" (click)="drawer.close()" *ngIf="!accountService.isLoggedIn() && userService.enrollerInfo?.CustomerStatusID != 6" class="m-nav-text border-bottom"  translate="JoinUs"></a>
                <a *ngIf="!accountService.isLoggedIn()" routerLink="/pages/learn" (click)="drawer.close()" class="m-nav-text border-bottom" translate="Learn"></a>
                <a *ngIf="!accountService.isLoggedIn()" routerLink="/pages/aboutus" (click)="drawer.close()" class="m-nav-text border-bottom" translate="WhoWeAre"></a>
                <a *ngIf="!accountService.isLoggedIn()" routerLink="/pages/contact" (click)="drawer.close()" class="m-nav-text border-bottom" translate="Contact"></a>
                <a (click)="sidebarcart.open(); drawer.close();" class="m-nav-text border-bottom">
                  <span translate="Cart" [matBadge]="cartCount" matBadgeOverlap="false" matBadgeColor="primary" class="cart-text" #mobilecart></span>
                </a>
                <a *ngIf="!accountService.isLoggedIn()" (click)="onProdSearch()" class="m-nav-text border-bottom"class="m-nav-text border-bottom" translate="search_"></a>
                <a class="m-nav-text border-bottom" *ngIf="!accountService.isLoggedIn()" routerLink="/login" (click)="drawer.close()" aria-hidden="false" aria-label="Example home icon" [textContent]="'Login' | translate"></a>
                <a class="m-nav-text border-bottom" *ngIf="accountService.isLoggedIn()"  (click)="accountService.logout()" aria-hidden="false" aria-label="Example home icon" [textContent]="'Logout' | translate"></a>
                <!-- <a (click)="onSelectMarket()" class="m-nav-text border-bottom" translate="Language"></a> -->

              </div>

              <!-- Language and Country Selector -->

              <div class="language-country-selector" (click)="onSelectMarket(); drawer.close()">
                <span class="flag-icon flag-icon-{{selectedCountry|| 'us'}} icon_size"></span>
                <span [textContent]="('Country_'+selectedCountry.toUpperCase()) | translate" translate=""></span>
                <span class="border-left-fade" [textContent]="getLanguageName()"></span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
            </mat-drawer>

            <div class="example-sidenav-content">

              <button mat-button (click)="drawer.toggle(); openDrawer()" class="rain_toggle_button">
                <mat-icon>menu</mat-icon>
              </button>
            </div>

          </mat-drawer-container>
        </div>
      </div>
    </ng-container>
  </div>

</section>

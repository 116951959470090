import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Optional, Output } from '@angular/core';
import { UserService } from './user.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notification.service';
import { ActivatedRoute, ActivationStart, ParamMap, Router } from '@angular/router';
import { CompanyService } from './company.service';
import { RestApiService } from '../http/restapi.service';
import { CommonSetting } from 'src/app/core/modals/commonsetting.modal';
import { EnrollmentService } from './enrollment.service';
import { ClientSettings } from 'src/assets/data/clientsetting';
import { distinctUntilChanged, map, skip } from 'rxjs';
import { UtilityService } from './utility.service';
import { CartService } from './cart.service';
import { CookieService } from 'ngx-cookie-service';
//import { registerLocaleData } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  public commonSettings: CommonSetting;
  public localSettings: any;
  public countryLanguageCode: any = {};
  public commonData: any = {};
  public allowedCountries: any;
  public allowedLanguages: any;
  public ngExpressRequest: Request;
  public selectedMarketConfig: any = {};
  @Output() readonly showInitialMarketSelector: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public user: UserService,
    public http: HttpClient,
    public apiService: RestApiService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public activatedRoute: ActivatedRoute,
    public companyService: CompanyService,
    public enroll: EnrollmentService,
    public util: UtilityService,
    private cookieService: CookieService
  ) {
  }
  init(data: any):any {
    const commonPromise = new Promise((resolve, reject) => {
    this.commonSettings = data;
    this.user.init();
    this.apiService.GetCountries().subscribe(data => {

      let isLoggedIn = false;
      let loggedInData;

      try {
        if(this.cookieService.check('X-Auth')) {
          isLoggedIn = true;
          loggedInData = this.user?.userServiceModal?.customerData;
        }
      } catch (error) { }

      this.allowedCountries = data.Data;
      let params=this.util.getQueryStringParams(window.location.search) || {};

      //this.activatedRoute.queryParamMap
       // .pipe(skip(1))
       // .subscribe((params: ParamMap) => {
          if (window.sessionStorage && window.location.search.length > 0) {
            this.commonData.selectedLanguage = (params.language && { LanguageID: params.language, CultureCode: 'en' }) ||
              (window.sessionStorage && JSON.parse(window.sessionStorage.getItem('selectedLanguage'))) || { LanguageID: 0, CultureCode: 'en' };
            this.commonData.selectedLanguageID = params.language || (window.sessionStorage.getItem('selectedLanguageID') != 'undefined') && JSON.parse(window.sessionStorage.getItem('selectedLanguageID')) || this.commonData.selectedLanguage.LanguageID || 0;
            this.setSelectedCountry((params.countrycode || (window.sessionStorage.getItem('selectedCountry') && window.sessionStorage.getItem('selectedCountry')) || (isLoggedIn && loggedInData?.MainCountry && loggedInData.MainCountry) || 'us'), this.commonData.selectedLanguage);
                // this.showInitialMarketSelector.emit();
          } else {
            this.commonData.selectedLanguage = (params.language && { LanguageID: params.language, CultureCode: 'en' }) ||
              (window.sessionStorage && JSON.parse(window.sessionStorage.getItem('selectedLanguage'))) || { LanguageID: 0, CultureCode: 'en' };
            this.commonData.selectedLanguageID = params.language || (window.sessionStorage.getItem('selectedLanguageID') != 'undefined') && JSON.parse(window.sessionStorage.getItem('selectedLanguageID')) || this.commonData.selectedLanguage.LanguageID || 0;
            debugger
            this.setSelectedCountry((params.countrycode || (window.sessionStorage.getItem('selectedCountry') && window.sessionStorage.getItem('selectedCountry')) || (isLoggedIn && loggedInData?.MainCountry && loggedInData.MainCountry) || 'us'), this.commonData.selectedLanguage);
            // this.showInitialMarketSelector.emit();
          }
          resolve(true);
        //});

    });
    });
    return commonPromise;
  }

  getConfig() {
    return this.commonSettings;
  }
  getLocalConfig() {
    return this.localSettings;
  }

  setSelectedCountry(country, selectedLanguage) {
    selectedLanguage = selectedLanguage || this.commonData.selectedLanguage;
    if (window.sessionStorage) {
      sessionStorage.setItem('selectedCountry', country.toLowerCase());
      console.log('sessionStorage.setItem(selectedCountry)',sessionStorage.getItem('selectedCountry'))
      sessionStorage.setItem('selectedLanguage', JSON.stringify(selectedLanguage));
      sessionStorage.setItem('selectedLanguageID', selectedLanguage.LanguageID == 'undefined' ? 0 : selectedLanguage.LanguageID);
      if(this.util.getQueryStringParams(window.location.search)){
        sessionStorage.setItem('contactID', this.util.getQueryStringParams(window.location.search)?.contactid);
      }
      if (window.location.hostname !== 'localhost') {
        sessionStorage.removeItem('items');
      }
      sessionStorage.removeItem('CommonSettings');
    }
    this.commonData.selectedCountry = country.toLowerCase();
    this.user.userServiceModal.selectedCountry = this.enroll.enrollmentServiceModal.selectedCountry = this.commonData.selectedCountry;
    this.apiService.getMarketConfig(this.commonData.selectedCountry).subscribe(data => {
      this.selectedMarketConfig = data.Data;
      sessionStorage.setItem('local_ID', this.selectedMarketConfig.CultureCode.substring(0, 2));
      this.getCommonSetting(country, selectedLanguage || this.commonData.selectedLanguage);
    });
  }

  getCommonSetting(countryCode, languageCode) {
    const commonPromise = new Promise((resolve, reject) => {
      const data = ClientSettings;
      this.setCommonSettingData(data, languageCode, countryCode);
      this.updateLocale(this.commonData.selectedLanguage);
      resolve(this.commonSettings);
      return resolve(data);
    });
    return commonPromise;
  }

  updateLocale(locale) {
    let languageCode = { LanguageID: 0, CultureCode: 'en' };
    try {
      if (locale || parseInt(locale, 10) === 0) {
        if (this.commonData.selectedLanguage) {
          languageCode = this.commonData.selectedLanguage;
        } else if (this.commonData.Language) {
          const language = _.filter(this.commonData.Language, (lang) => {
            return parseInt(lang.LanguageID, 10) === parseInt(locale, 10);
          });
          if (language.length > 0) {
            languageCode = language[0].ISOCode;
          }
        }
        this.translate.use(languageCode.CultureCode == 'undefined' ? ('en') : languageCode.CultureCode);
      }
    }
    catch (ex) {
      console.warn('ex', ex.message);
    }
  }

  getLanguageId(languageData, languageCode) {
    let selectedLanguage = [];
    if (languageCode) {
      selectedLanguage = _.filter(languageData, (lang) => {
        return lang.CultureCode === languageCode.CultureCode;
      });
      if (selectedLanguage.length > 0) {
        return selectedLanguage[0].CultureCode;
      }
      else {
        return 'en';
      }
    }
    else {
      return languageData[0].CultureCode || 'en';
    }
  }

  setCommonSettingData(data, languageCode, countryCode) {
    if (Object.keys(this.commonData).length > 0) {
      Object.assign(this.commonData, data);
    }
    else {
      this.commonData = data;
    }
    const country = _.find(this.allowedCountries, (item) => {
      return (item.CountryCode.toLowerCase() === (countryCode.toLowerCase() || 'us'));
    });
    country && this.apiService.GetLanguages(country?.CountryCode).subscribe({
      next: result => {
        this.allowedLanguages = result.Data;
        // this.selectedLanguage = this.allowedLanguages[0];

        this.commonData.selectedLanguage = this.getLanguageId(this.allowedLanguages, languageCode);
        this.commonData.selectedCountry = countryCode || 'us';
        this.commonData.selectedLanguage = languageCode || { LanguageID: this.allowedLanguages[0].LanguageID, CultureCode: this.allowedLanguages[0].CultureCode } || { LanguageID: 0, CultureCode: 'en' };
        this.commonData.AddressVerification = data.AddressVerification;
        if (data.Countries && data.Countries.length) {
          _.each(data.Countries, (country) => {
            if (country.CountryCodeISO2.toLowerCase() == countryCode.toLowerCase()) {
              this.commonData.selectedCountryDescription = country.CountryName;
            }
          });
        }
        if (window.sessionStorage) {
          sessionStorage.setItem('selectedLanguageID', this.commonData.selectedLanguage.LanguageID == 'undefined' ? 0 : this.commonData.selectedLanguage.LanguageID);
        }

      }, error: () => {

      }, complete: () => { }
    });
  }

  setWebsiteInfo(websiteInfo, verified) {
    this.user.setEnrollerInfo(websiteInfo, verified);
    this.enroll.setEnrollerInfo(websiteInfo, verified);
  }
}

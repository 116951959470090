import { formatNumber, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customeCurrency',
})

export class customeCurrencyPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'EUR', display: | 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol', digitsInfo: string = '3.2-2', locale: string = 'tr-TR'): string | null {
    //if (value) {
      return formatNumber(value, locale, digitsInfo) + '' + "₺";
    //}
  }
}
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { CompanyService } from 'src/app/core/services/company.service';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ProductService } from 'src/app/core/services/product.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { style } from '@angular/animations';
import * as $ from 'jquery';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { UserService } from 'src/app/core/services/user.service';
import { AccountService } from 'src/app/core/authentication/account.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  animations: [],
})
export class ProductComponent implements OnInit,AfterViewInit,OnDestroy {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('individualSelected') private individualSelected: MatSelect;
  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent> 
  searchFormGroup: FormGroup;
  categoryTypeGroup: FormGroup;
  sortByFormGroup: FormGroup;
  productSearchText: string = '';
  public sidenavOpen: boolean = true;
  public animation: any;   // Animation
  public sortByOrder: any;   // sorting
  public page: any;
  public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 34;
  public filterForm: FormGroup;
  public searchProduct: any;
  public items: Array<any> = [];
  public products: Array<any> = [];
  public tags: any[] = [];
  public colors: any[] = [];
  customCssCheckBox: any;
  Checkedall: boolean = false;
  Checkedmagnetic: boolean = false;
  CheckedwhatsHot: boolean = false;
  Checkedenrollment: boolean = false;
  Checkedlips: boolean = false;
  Checkedeyes: boolean = false;
  slides: Array<any> = [];
  sortKeys: Array<any> = [];
  selectedCategory: any = { Category: 'all', CategoryId: -1 };
  isAllunselect: boolean;
  panelOpenState: boolean = true;
  selectedValue:any[]=[]
  Countries:any = [];
  selectedCountry: any;
  userService: UserServiceModal;
  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private productService: ProductService,
    private route: ActivatedRoute,
    public configService: ConfigService,
    public utilityService: UtilityService,
    public itemsListService: ItemsListService,
    public companyService: CompanyService,
    public router:Router,
    private apiService: RestApiService,
    private user: UserService,
    public accountService: AccountService
  ) {

    this.userService = user.userServiceModal;

    this.route.params.subscribe(
      (params: Params) => {
        this.selectedCategory = { Category: params['category'] || 'all' };
        this.fetchInitialProducts();
        this.slides = this.configService.localSettings.Product.PrimaryBanner;
      }
    );
    this.sortKeys = utilityService.getSortKey();

    this.route.queryParams.subscribe(params => {
      if( params['searchquery'] == localStorage.getItem("ProdSearchQuery")){
        this.productSearchText = localStorage.getItem("ProdSearchQuery");
      }
    });

    this.countryInitialization();
    
  }

   countryInitialization(){
    try {
      const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
      this.apiService.GetCountries().subscribe({
        next: (data) => {
          this.Countries = data.Data;
          this.Countries.map((country: any) => {
            if(country.CountryCode.toLowerCase() == selectedCountry.toLowerCase()){
              this.selectedCountry = country.CountryCode;
            }
          });
        }
      });
    } catch {

    }
   }

  fetchInitialProducts(){
    this.productService.getProducts().subscribe(products => {
      this.productService.orders = products.map((item) => {
        item.Price = item.Price || item.PriceType1Value;
        return item;
      });

      const unique = _.uniqBy(products, x => x.CategoryID);

      this.itemsListService.selectedCategories = {};
      this.itemsListService.selectedCategories['all'] = true;
      this.itemsListService.categoryList = _.map(unique, (object) => {
        return _.pick(object, ['CategoryID', 'Category']);
      });
      this.itemsListService.products = this.productService.orders;
      this.itemsListService.type = 'order';
      this.itemsListService.getItemsByCategory(this.selectedCategory.Category);

      this.products = this.itemsListService.productList.slice(0.8);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
           
      $(document).on('click','strong[more]',(event)=> {        
        let ele= $(event.target)
        this.router.navigate([ele.attr("more")]);
      });
    }, 2000);
  this.defaultChoosen()
  }

  ngOnInit() {
    this.sortByOrder = this.utilityService.getSortKey()[0];
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('pagetitle_product') + ' - ' + text);
    });
    this.accountService.ssoValdiatedSuccessfully.subscribe((res) => {
      if(res){
        sessionStorage.removeItem('items');
        this.countryInitialization();
        this.fetchInitialProducts();
      }
    });
  }


  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  onChangeSorting($event) {
    this.sortByOrder = $event.value;
  }

  public onPageChanged(event) {
    this.page = event;
    window.scrollTo(0, 0);
  }
  onChange(selectedCategories, id) {
   let result = true;
    for (const i in selectedCategories) {
      if (selectedCategories[i] === false) {
        if (i !== 'all') {
          result = false;
          break;
        }
      } else {
        result = true;
      }

    }

    if (result) {
      this.itemsListService.selectedCategories['all'] = true;
    } else {
      this.isAllunselect = true;
      for (const k in selectedCategories) {
        if (selectedCategories[k] === true) {
          this.isAllunselect = false;
          break;
        }

      }
      if (this.isAllunselect) {
        this.itemsListService.selectedCategories['all'] = false;
        this.itemsListService.selectedCategories[id] = true;

      } else {
        this.itemsListService.selectedCategories['all'] = false;
      }
    }
    if (!this.isAllunselect) {
      this.itemsListService.getItemsByCategory(selectedCategories);
    }
  }

  onChangeCategory($event) {
    this.selectedValue = $event.index;
    if (this.selectedValue.length != 0) {
      if ($event.index == 0) {
        this.products = this.itemsListService.productList
      }
      else {
        const filterText = $event.tab.textLabel;
        this.products = this.itemsListService.productList.filter((product: any) => {
          return product.Category === filterText;
        });
      }
    }
  }
  defaultChoosen() {
    // this.allSelected.select()
    this.individualSelected?.options.forEach(option => {
      option.select()
    })
  }
  ngOnDestroy(): void {
    
  }

  GetRegions = () => {
    const promise = new Promise((resolve, reject) => {
      this.apiService.GetRegions(this.selectedCountry).subscribe({
        next: (data) => {
          this.userService.defaultState = data.Data[0]?.RegionCode;
          this.userService.shippingAddress.StateName = data?.Data?.filter((state)=> { return state.RegionCode == this.userService.shippingAddress.StateName })[0]?.RegionCode || data.Data[0]?.RegionCode;
          resolve(data);
        }, error: (error) => {
          reject(error);
        }
      });
    });
    return promise;
  }


  fetchNewProducts(choosenCountry: any){

    const oldCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');

    if(oldCountry){
      sessionStorage.removeItem('GetMarketConfig_'+oldCountry.toLowerCase())
    }

    sessionStorage.removeItem('items');
    
    // Country
    this.configService.commonData.selectedCountry = choosenCountry.toLowerCase();
    sessionStorage.setItem('selectedCountry', choosenCountry.toLowerCase());
    this.userService.selectedCountry = choosenCountry = this.configService.commonData.selectedCountry;

    this.apiService.getMarketConfig(this.configService.commonData.selectedCountry).subscribe(data => {
      var selectedMarketConfig = data.Data;
      sessionStorage.setItem('local_ID', selectedMarketConfig.CultureCode.substring(0, 2));
      this.GetRegions().then(() => {
        window.location.reload();
      });
    });

  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { RestApiService } from '../http/restapi.service';
import { EnrollmentServiceModal, PersonalInfo, ShippingAddress, WebOffice } from 'src/app/core/modals/enrollmentservice.modal';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {
  // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  // Add 'implements OnInit' to the class.
  isLoggedIn: Observable<boolean>;
  public enrollmentServiceModal: EnrollmentServiceModal;
  public enrollmentServiceSubject: BehaviorSubject<EnrollmentServiceModal>;
  public enrollmentServiceSubscriber: Subscriber<EnrollmentServiceModal>;
  constructor(
    public activatedRoute: ActivatedRoute,
    public apiService: RestApiService,
    public utilityService: UtilityService,
    public cookieService: CookieService
  ) {
    this.enrollmentServiceModal = new EnrollmentServiceModal();
    this.isLoggedIn = this.apiService.isLoggedIn();
    this.isLoggedIn.subscribe((data) => {
    });
  }
  public service: any = {};

  /** * init * @method  init */
  public init(): void {
    console.log('localStorage.enrollmentService', window.localStorage.getItem('enrollmentService') );
    if (window.localStorage && window.localStorage.getItem('enrollmentService')) {
      this.service = JSON.parse(window.localStorage.getItem('enrollmentService')) as EnrollmentServiceModal;
      console.log('this.service ', this.service );
      this.activatedRoute.queryParams.subscribe(res => {
        this.enrollmentServiceModal.customerTypeID = parseInt(res['type'], 10) || this.service.customerTypeID || 2;
        this.enrollmentServiceModal.referralURL = res['ref'];
        this.enrollmentServiceModal.legName = res['legName'];
        this.enrollmentServiceModal.enrollerId = Number(res['enrollerId'] || this.service.enrollerId) || 0;
        this.enrollmentServiceModal.sponsorId = Number(res['sponsorId'] || this.service.sponsorId) || 0;
        this.enrollmentServiceModal.autologin = this.service.autologin || res['autologin'] || false;
  
      });
      this.enrollmentServiceModal.enrollerInfo = this.service.enrollerInfo || {};
      this.enrollmentServiceModal.guestUserLogin = this.service.guestUserLogin || false;
      this.enrollmentServiceModal.customerData = this.service.customerData || {};
      this.enrollmentServiceModal.guestUserData = this.service.guestUserData || {};
      this.enrollmentServiceModal.shippingAddress = this.service.shippingAddress || {};
      this.enrollmentServiceModal.mailingAddress = this.service.mailingAddress || {};
      this.enrollmentServiceModal.newshippingAddress = this.service.newshippingAddress || {};
      this.enrollmentServiceModal.personalInfo = this.service.personalInfo || {} as PersonalInfo;
      this.enrollmentServiceModal.personalInfo.birthDay = this.service.personalInfo.birthDay || '01';
      this.enrollmentServiceModal.personalInfo.birthMonth = this.service.personalInfo.birthMonth || '01';
      this.enrollmentServiceModal.personalInfo.birthYear = this.service.personalInfo.birthYear || (moment().year() - 18).toString();
      this.enrollmentServiceModal.personalInfo.phoneNumber = this.service.personalInfo.phoneNumber || '';
      this.enrollmentServiceModal.webOffice = this.service.webOffice || {};
      this.enrollmentServiceModal.couponInfo = this.service.couponInfo || {};
      this.enrollmentServiceModal.DynamicCouponCode = this.service.DynamicCouponCode || {};
      this.enrollmentServiceModal.couponInfo.IsAppliedcode = this.service.couponInfo.IsAppliedcode || false;
      this.enrollmentServiceModal.paymentMethods = this.service.paymentMethods || [];
      this.enrollmentServiceModal.restrictedStates = this.service.restrictedStates || [];
      this.enrollmentServiceModal.restrictedShipStates = this.service.restrictedShipStates || '';
      this.enrollmentServiceModal.selectedShippingMethod = this.service.selectedShippingMethod;
      this.enrollmentServiceModal.shipMethods = this.service.shipMethods || [];
      this.enrollmentServiceModal.autoshipCardDetail = this.service.autoshipCardDetail || {};
      this.enrollmentServiceModal.cardDetail = this.service.cardDetail || {};
      this.enrollmentServiceModal.commissionPayment = this.service.commissionPayment || '';
      this.enrollmentServiceModal.licenseNumber = this.service.licenseNumber || '';
     this.enrollmentServiceModal.doNotWishAutoship = this.service.doNotWishAutoship || false;
      this.enrollmentServiceModal.checkItems = this.service.checkItems || false;
      this.enrollmentServiceModal.regionMainState = this.service.regionMainState || '';
      this.enrollmentServiceModal.newUser = this.service.newUser || false;
      this.enrollmentServiceModal.TotalSteps = 6;
      this.enrollmentServiceModal.noOfStepsVerified = this.service.noOfStepsVerified || 0;
      this.enrollmentServiceModal.couponInfo.availableRewards = this.service.couponInfo.availableRewards || [];
      // this.enrollmentServiceModal.selectedLanguageID = this.activatedRoute.snapshot.queryParams['LanguageCode || this.service.selectedLanguageID || 'en';
      // this.enrollmentServiceModal.selectedCountry = this.enrollmentServiceModal.selectedCountry || this.activatedRoute.snapshot.queryParams['countrycode || 'US';
      this.enrollmentServiceModal.WebAlias = this.service.WebAlias || 'rainShop';
      this.enrollmentServiceModal.billingAddress=this.service.billingAddress || {};
      this.enrollmentServiceModal.webOffice=this.service.webOffice || {} as WebOffice;
      this.enrollmentServiceModal.customerContact=this.service.customerContact || {} 
      this.enrollmentServiceModal.defaultState = this.service.defaultState || 'UT';

    } else {
      this.setDefault();
    }
  }
 
  /*** clear Data of application * @method  clearData ***/
  public clearData() {
    window.localStorage.removeItem('enrollmentService');
    this.service = {};
    this.init();
  }

  public clearCustomerData() {
    this.enrollmentServiceModal.customerData = {};
  }

  public setEnrollerInfo(websiteinfo, verified) {
    this.enrollmentServiceModal.enrollerInfo = websiteinfo;
    if (window.localStorage) {
      window.localStorage.setItem('enrollmentService', JSON.stringify(this.enrollmentServiceModal));
    }
  }
  /** * Set Default * @method  setDefault */
  public setDefault() {
    this.activatedRoute.queryParams.subscribe(res => {
      this.enrollmentServiceModal.customerTypeID = parseInt(res['type'], 10) || this.service.customerTypeID || 2;
      this.enrollmentServiceModal.referralURL = res['ref'];
      this.enrollmentServiceModal.legName = res['legName'];
      this.enrollmentServiceModal.enrollerId = Number(res['enrollerId'] || this.service.enrollerId) || 0;
      this.enrollmentServiceModal.sponsorId = Number(res['sponsorId'] || this.service.sponsorId) || 0;
      this.enrollmentServiceModal.autologin = this.service.autologin || res['autologin'] || false;

    });
    this.enrollmentServiceModal.enrollerInfo = {};
    this.enrollmentServiceModal.guestUserLogin = false;
    this.enrollmentServiceModal.shippingAddress = {} as ShippingAddress;
    this.enrollmentServiceModal.mailingAddress = {};
    this.enrollmentServiceModal.newshippingAddress = {};
    this.enrollmentServiceModal.personalInfo = {
      birthDay: 1,
      birthMonth: 1,
      birthYear: Number((moment().year() - 18).toString()),
      phoneNumber: '',
      Email: ''
    };
    this.enrollmentServiceModal.webOffice = {} as WebOffice;
    this.enrollmentServiceModal.paymentMethods = [];
    this.enrollmentServiceModal.autoshipCardDetail = {};
    this.enrollmentServiceModal.cardDetail = {
      CardName:'',
      CardExpirationYear: new Date().getFullYear(),
      CardExpirationMonth: (new Date().getMonth() + 1),
      CardNumber: '',
      CardCvv:'',
      lastFour: '',
      firstSix: ''
    };
    this.enrollmentServiceModal.commissionPayment = '';
    this.enrollmentServiceModal.licenseNumber = '';
  this.enrollmentServiceModal.doNotWishAutoship = false;
    this.enrollmentServiceModal.defaultState = '';
    this.enrollmentServiceModal.couponInfo = {
      IsAppliedcode: false,
      promoCodeValid: undefined,
      promoCode: '',
      Allcoupons: [],
      RewardsForUse: [],
      FreeShipping: {
        activated: false,
        coupon: undefined
      },
      availableRewards: [],
      OrderAllowCoupons: []
    };
    // this.enrollmentServiceModal.autoshipDate = moment().add(1, 'months').toDate();
    this.enrollmentServiceModal.customerData = {};
    this.enrollmentServiceModal.guestUserData = {};
    this.enrollmentServiceModal.restrictedShipStates = '';
    this.enrollmentServiceModal.restrictedStates = [];
    this.enrollmentServiceModal.selectedShippingMethod = 0;
    this.enrollmentServiceModal.shipMethods = [];
    this.enrollmentServiceModal.checkItems = false;
    this.enrollmentServiceModal.regionMainState = '';
    this.enrollmentServiceModal.newUser = false;
    this.enrollmentServiceModal.TotalSteps = 6;
    this.enrollmentServiceModal.noOfStepsVerified = 0;
    // this.enrollmentServiceModal.selectedLanguageID = this.activatedRoute.snapshot.queryParams['LanguageCode || 'en';
    // this.enrollmentServiceModal.selectedCountry = this.enrollmentServiceModal.selectedCountry || 'us';
    this.enrollmentServiceModal.WebAlias = 'rainShop';
    if (window.localStorage) {
      window.localStorage.setItem('enrollmentService', JSON.stringify(this.enrollmentServiceModal));
    }
  }

  /**
   * set Enrollment user shipping address
   * @method  setShippingAddress
   */
  public setShippingAddress() {
    this.enrollmentServiceModal.shippingAddress = this.enrollmentServiceModal.shippingAddress || cloneDeep(this.enrollmentServiceModal.customerData.DefaultShippingAddress);
    this.enrollmentServiceModal.shippingAddress.FullName = (this.enrollmentServiceModal.shippingAddress.FirstName || this.enrollmentServiceModal.customerData.FirstName) + (this.enrollmentServiceModal.shippingAddress.LastName || this.enrollmentServiceModal.customerData.LastName);
    this.enrollmentServiceModal.shippingAddress.FirstName = this.enrollmentServiceModal.shippingAddress.FirstName || this.enrollmentServiceModal.customerData.FirstName;
    this.enrollmentServiceModal.shippingAddress.LastName = this.enrollmentServiceModal.shippingAddress.LastName || this.enrollmentServiceModal.customerData.LastName;
    this.enrollmentServiceModal.shippingAddress.Street1 = this.enrollmentServiceModal.shippingAddress.Street1 || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.Street1 : '');
    this.enrollmentServiceModal.shippingAddress.Street2 = this.enrollmentServiceModal.shippingAddress.Street2 || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.Street2 : '');
    this.enrollmentServiceModal.shippingAddress.Street3 = this.enrollmentServiceModal.shippingAddress.Street3 || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.Street3 : '');
    this.enrollmentServiceModal.shippingAddress.StateName = this.enrollmentServiceModal.shippingAddress.StateName || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.StateName : '');
    this.enrollmentServiceModal.shippingAddress.PostalCode = this.enrollmentServiceModal.shippingAddress.PostalCode || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.PostalCode : '');
    this.enrollmentServiceModal.shippingAddress.CountryCode = this.enrollmentServiceModal.shippingAddress.CountryCode || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.CountryCode : '');
    this.enrollmentServiceModal.shippingAddress.City = this.enrollmentServiceModal.shippingAddress.City || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.City : '');
    this.enrollmentServiceModal.shippingAddress.Phone = this.enrollmentServiceModal.shippingAddress.Phone || (!this.utilityService.isEmptyObject(this.enrollmentServiceModal.customerData.DefaultShippingAddress) ? this.enrollmentServiceModal.customerData.DefaultShippingAddress.Phone : '');
  }

  // Check if User is Authenticated Or not
  public checkIfUserAuthenticatedOrNot(): boolean {
    return this.cookieService.check('access_token');
  }
}

import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from 'src/app/core/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserService } from 'src/app/core/services/user.service';
import { Title } from '@angular/platform-browser';
import { CartService } from 'src/app/core/services/cart.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { MatDialog } from '@angular/material/dialog';
import { BankDepositComponent } from 'src/app/shared/model/bankdeposit/bankdeposit.component';
import { InvoiceComponent } from '../pages/invoice/invoice.component';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { BillingAddress, CardDetail, PersonalInfo, ShippingAddress, WebOffice } from 'src/app/core/modals/enrollmentservice.modal';
import { SellerAgreementComponent } from 'src/app/shared/model/seller-agreement-dialog/seller-agreement-dialog.component';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
@Component({
  selector: 'app-thankyou',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})

export class ThankyouComponent implements OnInit, AfterViewInit, OnDestroy {
  userService: UserServiceModal;
  env: any;
  token: string;
  public orderCompleteResponse: any = {};
  public bankDepositInstructions: any;
  isEnrollment: string
  public enrollmentservice: any;
  selectedLanguage: any;
  constructor(
    public configService: ConfigService,
    public translate: TranslateService,
    public itemsService: ProductService,
    public itemsListService: ItemsListService,
    public router: Router,
    private apiService: RestApiService,
    private route: ActivatedRoute,
    public user: UserService,
    public titleService: Title,
    public cartService: CartService,
    public paymentService: PaymentService,
    public dialog: MatDialog,
  ) {
    this.env = environment;
    this.enrollmentservice = user.userServiceModal;
    this.userService = user.userServiceModal
    if (window.sessionStorage) {
      sessionStorage.removeItem('NewCustomerId');
      sessionStorage.removeItem('NewOrderID');
      sessionStorage.removeItem('NewAutoOrderID');
      var SelectedPaymentTypes = JSON.parse(sessionStorage.getItem('SelectedPaymentTypes'));
      this.paymentService.SelectedPaymentTypes = SelectedPaymentTypes;
    }
  }

  ngOnInit(): void {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Thank You') + ' - ' + text);
    });
    this.route.queryParams.subscribe(params => {
      this.token = params['token']
      this.isEnrollment = params['enrollment'] ?? 'false'
    });

    if (this.token != '') {
      this.apiService.OrderComplete(this.token).subscribe(response => {
        this.orderCompleteResponse = response.Data;
        if (this.userService.shippingAddress.CountryCode == "TR" && (localStorage.getItem('agreementLoaded') != 'true')) {
          var _self = this;
          this.waitForElement("WordSection1", function () {
            _self.uploadsellerAgreement(_self.orderCompleteResponse.CustomerID, _self.orderCompleteResponse.OrderID);
          });
        }
      });
      if (this.paymentService.SelectedPaymentTypes == 1002) {
        this.bankDepositInstructions = {
          'Token': this.token,
          'PaymentType': this.paymentService.SelectedPaymentTypes
        }
      }
      else {
        this.apiService.GetBankDepositInstructions(this.token).subscribe(response => {
          if (response.Data) {
            this.bankDepositInstructions = response.Data;
          }
        })
      }
    }
    //window.onbeforeunload = () => this.ngOnDestroy();
    this.cartService.sidebarSummaryCartDrawer.close();
    this.cartService.clearCart();
    //this.itemsListService.searchProduct=false;
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'));
  }

  ngAfterViewInit(): void {
    /**
     *  Below code is commented because, thank you page was redirecting back to /checkout page
     *  which is a previous page!
     */

    // history.pushState(null, null, window.location.href);
    // history.back();
    // window.onpopstate = () => history.forward();
  }

  print() {
    window.print();
  }

  bankDepositModelOpen() {
    const bankDepositDialogRef = this.dialog.open(BankDepositComponent, {
      data: this.bankDepositInstructions,
      width: '100%',
      maxWidth: '750px',
    });
    bankDepositDialogRef.afterClosed().subscribe(() => {
    });
  }

  invoiceModelOpen(type) {
    const invoiceDialogRef = this.dialog.open(InvoiceComponent, {
      data: { 'orderResponse': this.orderCompleteResponse, 'type': type },
      width: '100%',
      maxWidth: '950px',
    });
    invoiceDialogRef.afterClosed().subscribe(() => {
    });
  }

  openSellerAgreementDialog(sellerAgreementId) {
    const dialogRef = this.dialog.open(SellerAgreementComponent, {
      maxWidth: "800px",
      maxHeight: '800px',
      data: {
        'taxId': this.userService.webOffice.TaxID,
        'agreementId': sellerAgreementId + '_' + this.selectedLanguage?.CultureCode,
        'type': (this.isEnrollment == 'true') ? 'enrollment' : 'checkout',
        'orderdata': this.orderCompleteResponse,
      }
    })
    dialogRef.afterClosed().subscribe(res => {
    })
  }

  uploadsellerAgreement(customerID, orderID) {
    var agreementFile = {};
    if (this.isEnrollment == 'true') {
      agreementFile = [
        {
          "FileHtml": this.selectedLanguage.CultureCode == 'en' ? document.getElementById('enrollAgreement_en')?.innerHTML : document.getElementById('enrollAgreement_tr')?.innerHTML,
          "FileName": "Direct Seller Agreement"
        }
      ]
    }
    else {
      agreementFile =
        [
          {
            "FileHtml": this.selectedLanguage.CultureCode == 'en' ? document.getElementById('distanceAgreement_en')?.innerHTML : document.getElementById('distanceAgreement_tr')?.innerHTML,
            "FileName": "Distance Sale Agreement"
          },
          {
            "FileHtml": this.selectedLanguage.CultureCode == 'en' ? document.getElementById('preliminaryAgreement_en')?.innerHTML : document.getElementById('preliminaryAgreement_tr')?.innerHTML,
            "FileName": "Preliminary Information"
          }
        ]
    }

    let sellerAgreeRequest = {
      "Data": agreementFile,
      "CustomerID": customerID,
      "OrderId": orderID
    }
    this.apiService.uploadFile(sellerAgreeRequest).subscribe((response) => {
      if (response.Data && parseInt(response.Status, 10) == 0) {
        localStorage.setItem('agreementLoaded', 'true');
      }
    });
  }

  ngOnDestroy() {
    let sessionId;
    if (window.localStorage) {
      sessionId = localStorage.getItem('sessionId') == 'null' ? '' : localStorage.getItem('sessionId');
    }
    sessionStorage.removeItem('NewCustomerId');
    sessionStorage.removeItem('NewOrderID');
    sessionStorage.removeItem('NewAutoOrderID');
    sessionStorage.removeItem('exigosessionId');
    localStorage.clear();
    sessionStorage.clear();
    if (window.localStorage) {
      localStorage.setItem('sessionId', sessionId);
    }
    this.enrollmentservice.cardDetail = {} as CardDetail;
    this.enrollmentservice.personalInfo = {} as PersonalInfo;
    this.enrollmentservice.shippingAddress = {} as ShippingAddress;
    this.enrollmentservice.shipMethods = {};
    this.enrollmentservice.webOffice = {} as WebOffice;
    this.enrollmentservice.personalInfo = {} as PersonalInfo;
    this.enrollmentservice.billingAddress = {} as BillingAddress;

    location.reload();
  }


  waitForElement(classname, callback) {
    var poops = setInterval(function () {
      if (document.getElementsByClassName(classname)) {
        clearInterval(poops);
        callback();
      }
    }, 100);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent } from './modules/create-account/create-account.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { ProductDetailsComponent } from './modules/shop/product-details/product-details.component';
import { ProductComponent } from './modules/shop/product/product.component';
import { SignupComponent } from './modules/signup/signup.component';
import { RevieworderComponent } from './shared/revieworder/revieworder.component';
import { CartComponent } from './modules/cart/cart.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { ThankyouComponent } from './modules/thank-you/thank-you.component';
import { PaymentfailComponent } from './modules/paymentfail/paymentfail.component';
import { AuthGuard } from './core/guards/auth.guard.service';
import { LoggedInAuthGuard } from './core/services/loggedin.guard.service';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component:HomeComponent,
    pathMatch:'full'
  },
  {
    path: 'cart',
    component:CartComponent,
    pathMatch:'full'
  },
  {
    path: 'login',
    component:LoginComponent,
    pathMatch:'full'
  },
  // {
  //   path: 'signup',
  //   component:SignupComponent,
  //   pathMatch:'full'
  // },
  {
    path: 'products',
    component:ProductComponent,
    pathMatch:'full'
  },
  {
    path: 'product/:id',
    component:ProductDetailsComponent,
    pathMatch:'full'
  },
  {
    path: 'register',
    component: CreateAccountComponent,
    pathMatch:'full'
  },
  {
    path: 'review',
    component:RevieworderComponent,
    canActivate: [AuthGuard],
    pathMatch:'full'
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    canActivate: [LoggedInAuthGuard],
    pathMatch:'full'
  },
  {
    path: 'paymentfail',
    component: PaymentfailComponent,
    pathMatch:'full'
  },
  {
    path: 'thank-you',
    component: ThankyouComponent,
    pathMatch:'full'
  },
  { path: 'pages', loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)},
  { path: 'accounts', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
  { path: 'enrollment', loadChildren: () => import('./modules/enrollment/enrollment.module').then(m => m.EnrollmentModule)},
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: false, relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' } )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<mat-dialog-content>
  <div class="main-div vertical-bar" *ngIf="!isSmallScreen" [style]="productVeritcalBar" fxLayout="row"
    fxLayout.lt-md="column" id="main">
    <div class="image-div br" fxLayout="row" fxFlex.lt-md="100" fxLayoutAlign="center center"
      fxLayoutAlign.lt-sm="none center">
      <lib-ngx-image-zoom [thumbImage]="env.apiUrl + 'api/Images' + productInfo.LargeImageUrl"
        [fullImage]="env.apiUrl + 'api/Images' + productInfo.LargeImageUrl" [magnification]="1.5"
        [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="300" [lensHeight]="300" [zoomMode]="'hover'">
      </lib-ngx-image-zoom>
    </div>
    <div class="desc-div br" fxFlex.lt-md="100">
      <a id="cross" fxFlexAlign="flex-end" (click)="closeDialog()">
        <mat-icon class="mat-18">close</mat-icon>
      </a>
      <div class="text-container">
        <h2 [textContent]="productInfo.ItemDescription" class="prod_title lowercase"></h2>
        <div class="p-desc" [innerHtml]="productInfo.ShortDetail1"></div>
      </div>
      <div class="layout-row" fxLayout="row" *ngIf="productInfo?.GroupMembers && productInfo?.GroupMembers?.length>0 ">
        <div class="w-100">
          <ng-container>
            <div class="m-0 p-20-0 custom-size">
              <p [textContent]="' flavor: '"></p>
              <mat-select readonly="true" (selectionChange)="checkOptions(productInfo?.GroupMembers);"
                [(ngModel)]="value[0]" name="value.Option" (click)="openSelect($event)" class="md-no-underline-select"
                (ngInit)="value[0]=productInfo?.GroupMembers[0].Item">
                <mat-option *ngFor="let option of productInfo?.GroupMembers" [value]="option.Item"
                  (click)="selectOption(productInfo, productInfo?.GroupMembers, value, false)">
                  {{option.Item?.ItemDescription}} </mat-option>
              </mat-select>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="options" *ngIf="productInfo.GroupMembers.length==0">
        <div *ngIf="hideAddToCart(productInfo, true) && accountService.isLoggedUserRetailType()" class="o-b o-1" id="subscribe" fxLayout="row" fxLayout.lt-sm="column"
          [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? ProductColorOptions['Color5'] : '#000' }"
          fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
          <span class="item-detailprice font-weight-bolder" style="color: #fff;"
            [textContent]="selectedCountry=='tr'?(getSubsPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(getSubsPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)"
            fxFlex="37" fxFlex.sm="25" fxFlex.md="25"></span>
          <div fxLayout="column" fxLayoutAlign="space-between start" class="margin-left" fxFlex="38" fxFlex.sm="55"
            fxFlex.md="55">
            <span class="o-title" style="font-weight: 900; text-transform: lowercase; color: #fff; margin-right: 0;"
              translate="SubscribeToSave"></span>
            <span class="subscribe_label" translate="SubscribeLabel"></span>
          </div>
          <button class="btn-black button bold-text" (click)="addToCart1('autoship'); cartService.sidebarSummaryCartDrawer.open()"
            [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? '#000' : ProductColorOptions['Color5'] }"
            translate="Subscribe" fxFlex="25"></button>
        </div>
        <div class="o-b o-2" id="onetime" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
          <span class="item-detailprice font-weight-bolder"
            [textContent]="selectedCountry=='tr'?(getPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)"
            fxFlex="37" fxFlex.sm="25" fxFlex.md="25"></span>
          <div fxFlex="38" fxFlex.sm="55" fxFlex.md="55" class="margin-left">
            <span class="o-title" translate="OneTimeOrder" style="text-transform: lowercase;"></span>
            <span></span>
          </div>
          <button class="btn-white button" (click)="addToCart1('order'); cartService.sidebarSummaryCartDrawer.open()"
            translate="OneTimeOrder" fxFlex="25"></button>
        </div>
      </div>
      <div class="options" *ngIf="productInfo.GroupMembers.length>0">
        <div *ngIf="hideAddToCart(productInfo, true) && accountService.isLoggedUserRetailType()" class="o-b o-1" id="subscribe" fxLayout="row" fxLayout.lt-sm="column"
          [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? ProductColorOptions['Color5'] : '#000' }"
          fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
          <span class="item-detailprice font-weight-bolder" style="color: #fff;"
            [textContent]="selectedCountry=='tr'?(getSubsPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getSubsPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)"
            fxFlex="37" fxFlex.sm="25" fxFlex.md="25"></span>
          <div fxLayout="column" fxLayoutAlign="space-between start" class="margin-left" fxFlex="38" fxFlex.sm="55"
            fxFlex.md="55">
            <span class="o-title" style="font-weight: 900; text-transform: lowercase; color: #fff; margin-right: 0;"
              translate="SubscribeToSave"></span>
            <span class="subscribe_label" translate="SubscribeLabel"></span>
          </div>
          <button class="btn-black button bold-text" (click)="addToCart1('autoship'); cartService.sidebarSummaryCartDrawer.open()"
            [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? '#000' : ProductColorOptions['Color5'] }"
            translate="Subscribe" fxFlex="25"></button>
        </div>
        <div class="o-b o-2" id="onetime" fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
          <span class="item-detailprice font-weight-bolder"
            [textContent]="selectedCountry=='tr'?(getPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)"
            fxFlex="37" fxFlex.sm="25" fxFlex.md="25"></span>
          <div fxFlex="38" fxFlex.sm="55" fxFlex.md="55" class="margin-left">
            <span class="o-title" translate="OneTimeOrder" style="text-transform: lowercase;"></span>
            <span></span>
          </div>
          <button class="btn-white button" (click)="addToCart1('order'); cartService.sidebarSummaryCartDrawer.open()"
            translate="OneTimeOrder" fxFlex="25"></button>
        </div>
      </div>


    </div>
  </div>


  <!-- ****************** FOR MOBILE DEVICES ************  -->



  <div class="main-div container vertical-bar" [style]="productVeritcalBar" fxLayout="column" *ngIf="isSmallScreen">
    <a id="cross" fxFlexAlign="flex-end" (click)="closeDialog()">
      <mat-icon class="mat-18">close</mat-icon>
    </a>
    <div class="hero_container" fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between stretch" id="backImg">
      <img [src]="productInfo?.ShortDetail4" alt="" style="width:100%; height: auto">
    </div>
    <div class="pd_first_container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
      <div fxFlex=47 class="product-left">
        <div class="product-title">
          <h2 class="font-weight-bolder item-detailDescription lowercase" style="word-wrap: break-word;" [textContent]="productInfo?.ItemDescription">
          </h2>
        </div>
        <div class="layout-row" fxLayout="row"
          *ngIf="productInfo?.GroupMembers  && productInfo?.GroupMembers?.length>0 ">
          <div class="w-100">
            <ng-container>
              <div class="m-0 p-20-0 custom-size">
                <p [textContent]="' flavor: '"></p>
                <mat-select readonly="true" (selectionChange)="checkOptions(productInfo?.GroupMembers);"
                  [(ngModel)]="value[0]" name="value.Option" (click)="openSelect($event)" class="md-no-underline-select"
                  (ngInit)="value[0]=productInfo?.GroupMembers[0].Item">
                  <mat-option *ngFor="let option of productInfo?.GroupMembers" [value]="option.Item"
                    (click)="selectOption(productInfo, productInfo?.GroupMembers, value, false)">
                    {{option.Item?.ItemDescription}} </mat-option>
                </mat-select>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="item-buy-btn-group" fxLayout="column">
          <div *ngIf="hideAddToCart(productInfo, true) && productInfo?.StaticKitChildren?.length == 0 && accountService.isLoggedUserRetailType(), else elseAutoShip" class="subscription-box"
            [style.background-color]="ProductColorOptions['Color5']" fxLayout="row" fxLayout.lt-sm="row column"
            fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-sm="space-between  center" id="subscription-box">
            <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
              <div class="item-detailprice font-weight-bolder text-white"
                [textContent]="selectedCountry=='tr'?(getSubsPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(getSubsPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)">
              </div>
            </div>
            <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column" class="subscribe_cta">
              <h2 translate="SubscribeToSave"></h2>
              <p translate="SubscribeLabel"> </p>
            </div>
            <div fxFlex="23" fxFlex.lt-sm="100">
              <button mat-stroked-button class="dark_btn w-100"
                (click)="addToCart('autoship', productInfo); cartService.sidebarSummaryCartDrawer.toggle()"><span
                  translate="Subscribe" class="font_family bold-text"></span></button>
            </div>
          </div>
          <ng-template #elseAutoShip>
            <div *ngIf="hideAddToCart(productInfo, true) && accountService.isLoggedUserRetailType()" class="subscription-box product_detail_subs_box" fxLayout="row" fxLayout.lt-sm="row column"
              fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-sm="space-between  center" id="subscription-box">
              <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
                <div class="item-detailprice font-weight-bolder text-white"
                  [textContent]="selectedCountry=='tr'?(getSubsPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(getSubsPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)">
                </div>
              </div>
              <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column" class="subscribe_cta">
                <h2 translate="SubscribeToSave"></h2>
                <p translate="SubscribeLabel"> </p>
              </div>
              <div fxFlex="23" fxFlex.lt-sm="100">
                <button class="product_detail_btn w-100" [style.background-color]="ProductColorOptions['Color5']"
                  (click)="addToCart('autoship', productInfo); cartService.sidebarSummaryCartDrawer.open()"><span
                    translate="Subscribe" class="font_family bold-text"></span></button>
              </div>
            </div>
          </ng-template>
          <div class="one-time-order" fxLayout="row" fxLayout.lt-sm="row column" fxLayoutAlign="space-evenly center"
            fxLayoutAlign.lt-sm="space-between  center">
            <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
              <div class="item-detailprice font-weight-bolder"
                [textContent]="selectedCountry=='tr'?(getPrice(productInfo) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getPrice(productInfo) | currency: configService.selectedMarketConfig.CurrencySymbol)">
              </div>
            </div>
            <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column">
              <h2 [translate]="'OneTimePurchase'"></h2>
            </div>
            <div fxFlex="23" fxFlex.lt-sm="100">
              <button class="light_btn w-100 " (click)="addToCart('order', productInfo)"><span translate="OneTimeOrder"
                  class="font_family btn_color"></span></button>
            </div>
          </div>
        </div>
      </div>

      <!-- video slider -->
      <div fxFlex=47 class="product-right" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="productInfo.ItemCode && sliderVideos && (sliderVideos[productInfo?.ItemCode+'_tag_bannervideos']?.length>0 || sliderVideos[productInfo?.ItemCode+'_tag_bannerimages']?.length>0)">
        <div class="product-videos" fxLayoutAlign="start stretch">
          <swiper class="swiper-container" fxFlex=100 [config]="config" (indexChange)="onIndexChange($event)">
            <!-- <div *ngFor="let slide of sliderVideos[productInfo?.ItemCode+'_tag_bannervideos'] | slice:0:3; let i=index" class="swiper-slide">
                    <div fxLayout="column" fxFlex=100 fxLayoutAlign="center center" fxFlexFill>
                      <video controls (play)="video()"  #videoPlayer style="width: 500px">
                        <source [src]="slide" type="video/mp4"  autostart="false"/>
                        Browser not supported
                     </video>
                    </div>
                  </div> -->
            <div *ngFor="let image of sliderVideos[productInfo?.ItemCode+'_tag_bannerimages'] | slice:0:3; let i=index"
              class="swiper-slide">
              <div fxLayout="column" fxFlex=100 fxLayoutAlign="center center" fxFlexFill>
                <img [src]="image" style="width: 500px;max-height:290px;max-width:100%;" class="slider-images" />
              </div>
            </div>
          </swiper>
        </div>

        <div class="item-detail-container">
          <span *ngIf="productInfo?.StaticKitChildren?.length == 0, else elseDesciption"
            [innerHtml]="productInfo?.ShortDetail1 | unsafe" class="product_details_text"></span>
          <ng-template #elseDesciption>
            <div fxLayout="column">
              <span [innerHtml]="productInfo?.ShortDetail1 | unsafe" class="product_details_text"></span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- second container unused code -->
    <ng-template>
      <div class="pd_second_container mt-10" style="border-top: 4px solid var(--theme-dark); padding-top: 10px;">
        <div fxLayout="column row" fxLayoutAlign="start start" *ngIf="productInfo?.LongDetail1, else noLongDetail">
          <mat-tab-group class="product-detail-tabs">
            <mat-tab *ngIf="productInfo?.LongDetail3" [label]="'benefits' | translate"   fxLayoutAlign="start center">
              <div [innerHtml]="productInfo?.LongDetail3 | unsafe" class="product-detail-tabs-content"></div>

            </mat-tab>
            <mat-tab *ngIf="productInfo?.LongDetail1"  [label]="'productinfo' | translate" fxLayoutAlign="start center">
              <div [innerHtml]="productInfo?.LongDetail1 | unsafe" class="product-detail-tabs-content"></div>
            </mat-tab>
            <mat-tab *ngIf="productInfo?.LongDetail2" [label]="'nutritionalinfo' | translate" fxLayoutAlign="start center">
              <div [innerHtml]="productInfo?.LongDetail2 | unsafe" class="product-detail-tabs-content"></div>
            </mat-tab>
            <!-- <mat-tab *ngIf="product?.LongDetail4" label="Q&A" fxLayoutAlign="start center">
                <div [innerHtml]="product?.LongDetail4 | unsafe" class="product-detail-tabs-content"></div>

              </mat-tab> -->
          </mat-tab-group>
          <div class="product-feed" fxLayoutAlign="start stretch">
            <swiper class="swiper-container" fxFlex=100 effect="fade" [config]="testimonialConfig"
              *ngIf="sliderFeeds && sliderFeeds[productInfo?.ItemCode+'_tag_ss']">
              <div *ngFor="let slide of sliderFeeds[productInfo?.ItemCode+'_tag_ss']  let i=index" class="swiper-slide">
                <div fxLayout="column" fxFlex=100 fxLayoutAlign="top center" fxFlexFill>
                  <app-feed style="width: 100%;"
                    *ngIf="productInfo?.ItemCode &&  sliderFeeds[productInfo?.ItemCode+'_tag_ss']?.length>0"
                    [htmlText]="slide?.ss_story | unsafe" [image]="slide?.ss_image" [customername]="slide?.ss_name"
                    [color]="ProductColorOptions['Color3'] === '' ? ProductColorOptions['Color1'] : ProductColorOptions['Color3']">
                  </app-feed>
                </div>
              </div>
            </swiper>
          </div>

        </div>
      </div>
    </ng-template>
  </div>

</mat-dialog-content>

<div class="rain-market-selector-dialog">
    <div fxLayout="row" class="market-selector ms-padding">
      <div fxFlex="100" fxLayoutAlign="space-between center">
        <h2 class="country-selection" mat-dialog-title translate="edit_address"></h2>
        <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
      </div>
    </div>
    <hr class="mb-15">
    <div fxLayout="column" class="ms-padding main-content">
      
        <!-- default address block -->
        <mat-radio-group [(ngModel)]="selectedAddressType" fxLayout="column" fxLayoutGap="25px">
            <div class="ea-radio-btn" fxLayout="row" >
                <mat-radio-button [value]="1"></mat-radio-button><span class="ea-label" [textContent]="'use_default_address' | translate"></span>
            </div>
            <div class="default-address" fxLayout="column" fxLayoutGap="12px" *ngIf="selectedAddressType == 1">
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="14px">
                    <mat-icon>person</mat-icon>
                    <span [textContent]="userService.shippingAddress.FullName"></span><br>
                </div>
                <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="14px">
                    <mat-icon>home</mat-icon>
                    <address class="color-f-s-14 m-b-0 viewAddress">
                        <span [textContent]="userService.shippingAddress.Street1 || 'Street1'"></span>&nbsp;
                        <span [textContent]="userService.shippingAddress.Street2 || 'Street2'"></span>&nbsp;
                        <span [textContent]="userService.shippingAddress.City || 'City'"></span>&nbsp;
                        <br>
                        <span [textContent]="userService.shippingAddress.Region || 'Region'"></span>&nbsp;
                        <span [textContent]="userService.shippingAddress.CountryCode || 'CountryCode'"></span>&nbsp;
                        <span [textContent]="userService.shippingAddress.PostalCode || 'PostalCode'"></span>
                    </address>
                </div>
            </div>
            <div class="ea-radio-btn" fxLayout="row" >
                <mat-radio-button [value]="2"></mat-radio-button><span class="ea-label" [textContent]="'AddANewAddress' | translate"></span>
            </div>
        </mat-radio-group>
        

        <!-- change address form -->
        <ng-container *ngIf="selectedAddressType == 2">
            <section class="b-top" fxLayout="column" fxLayoutAlign="start stretch">
                <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="mt-5">
                    <mat-form-field appearance="outline" fxFlex="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="FirstName"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="newAddress.shippingAddress.FirstName" addressSection="true" name="fname" autocomplete="nope">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" fxFlex="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="LastName"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="newAddress.shippingAddress.LastName" addressSection="true" name="lname" autocomplete="nope">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100 exigo-validate" fxFlex="100">
                        <mat-label translate="StreetAddress"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="newAddress.shippingAddress.Street1" addressSection="true" name="streetaddress" autocomplete="nope">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label translate="Country"></mat-label>
                        <mat-select [disabled]="true"
                            (selectionChange)="countrySelected($event)" [(ngModel)]="newAddress.shippingAddress.CountryCode"
                            name="country" validate validate-select >
                            <mat-option *ngFor="let country of shippingCountries" [value]="country.CountryCode"  addressSection="true" >
                                {{country.CountryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" fxFlex="100"
                        class="w-100 margininput exigo-validate">
                        <mat-label translate="City"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="newAddress.shippingAddress.City" addressSection="true" name="city" autocomplete="nope">
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" fxFlex="100" class="w-100 margininput exigo-validate">
                        <mat-label translate="Select State"></mat-label>
                        <mat-select [(ngModel)]="newAddress.shippingAddress.StateName" name="state" validate
                            validate-select="true" (selectionChange)="orderService.calculateOrder()">
                            <mat-option *ngFor="let region of allowedRegions" [value]="region.RegionCode" addressSection="true" >
                                {{region.RegionName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                   <mat-form-field appearance="outline" fxFlex="100" class="w-100 exigo-validate">
                        <mat-label translate="Zip"></mat-label>
                        <input matInput [attr.regexcountry]="newAddress.shippingAddress.CountryCode" regxvalidzip="true"  validate noempty="true" [(ngModel)]="newAddress.shippingAddress.PostalCode" name="zipcode"  addressSection="true" 
                            (focusout)="orderService.calculateOrder()" autocomplete="nope">
                    </mat-form-field> 

                    <mat-form-field class="w-100 exigo-validate" appearance="outline"  fxFlex="100">
                        <mat-label  translate="MobilePhone"></mat-label>
                        <input matInput validate noempty="true" [(ngModel)]="newAddress.shippingAddress.Phone"
                            name="phone" (focusout)="verifyPhone('telephone')" [attr.placeholder]="'PhoneNumber' | translate" id="telephone" autocomplete="nope" addressSection="true" >
                    </mat-form-field>
                     
               </div>
            </section>
        </ng-container>
    </div>
    <div fxLayout="row" class="save-btn-row" fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button class="sa-button" validate trigger-check-section="true" sectiontype="addressSection"
         (success)="onSubmit()">Submit</button>
    </div>

  </div>
  
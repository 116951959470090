
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { OrderService } from './order.service';
import { UserService } from './user.service';
import { ProductService } from './product.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityService } from './utility.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ItemsListService {
  public isShowViewButton: boolean = false;
  public AddUpgradeItemFlag:boolean = false;
  public totalItem: any = {};
  public buttonText: any = {};
  public productList:Array<any>=[];
  public categoryList:Array<any>=[];
  public products:Array<any>=[];
  public selectedCategory: string = 'all';
  public selectedCategoryType: string = '';
  public selectedCategories: any = {};
  public allProductList:Array<any>=[];
  userServiceModal: UserServiceModal;
  public type: any;
  swalErrorMsg: string;


  constructor(
    private itemsService: ProductService,
    public snackBar: MatSnackBar,
    public orderService: OrderService,
    public userService: UserService,
    public route: Router,
    public notificationService: NotificationService,
    public translate: TranslateService,
    private dialog: MatDialog,
    public utilityService: UtilityService,
    public companyService: CompanyService,
    public configService: ConfigService
  ) {
    this.userServiceModal = this.userService.userServiceModal;
  }

  viewAll(index) {
    if (this.totalItem[index] === 4) {
      this.totalItem[index] = 100;
      this.buttonText[index] = 'view_less';
    } else {
      this.totalItem[index] = 4;
      this.buttonText[index] = 'view_more';
    }
  }

  checkSelect(type) {
    if (type && type != 'all') {
      for (const i in this.selectedCategories) {
        if (this.selectedCategories[i] === true) {
          return;
        }
      }
      this.selectedCategories['all'] = true;
      _.each(this.categoryList, (item) => {
        this.selectedCategories[item.CategoryId] = true;
      });
    } else {

      let checkselectall = false;
      if (this.categoryList.length > 0) {
        _.each(this.categoryList, (item) => {
          if (this.selectedCategories['all'] === true) {
            this.selectedCategories[item.CategoryId] = true;
            checkselectall = true;
          } else {
            this.selectedCategories[item.CategoryId] = false;
            checkselectall = false;
          }
        });
      } else {
        this.selectedCategories['all'] = true;
      }
      if (!checkselectall) {
        if (this.categoryList.length > 0) {
          this.selectedCategories[this.categoryList[0].CategoryId] = true;
        }
        this.productList = _.filter(this.products, (item) => {
          if (typeof type === 'string' || typeof type === 'number') {
            return item.CategoryId == type;
          } else {
            return this.selectedCategories[item.CategoryId];
          }
        });
        return true;
      }
    }
  }
  getItemsByCategory(type = null) {
    this.selectedCategory = type || 'all';
    type = (type === '[object Object]') ? 'all' : type;
    if (type && type != 'all') {
      if (typeof type === 'string' || typeof type === 'number') {
        // this.selectedCategories[type] = true;
        this.selectedCategories['all'] = false;
        _.each(this.categoryList, (item) => {
          if (item.Category.toLowerCase() != type.toString().toLowerCase()) {
            this.selectedCategories[item.CategoryId] = false;
          }
          else if (item.Category.toLowerCase() == type.toString().toLowerCase()) {
            this.selectedCategories[item.CategoryId] = true;
          }
        });
      } else {
        this.checkSelect(type);
      }

      this.productList = _.filter(this.products, (item) => {
        if (typeof type === 'string' || typeof type === 'number') {
          return item.Category.toLowerCase() == type.toString().toLowerCase();
        } else {
          return type[item.CategoryId];
        }
      });
    } else {
      this.isShowViewButton = true;
      if (!this.checkSelect(type)) {
        this.productList = this.products;
      }
    }
    if (this.selectedCategories['all'] == true) {
      this.selectedCategoryType = 'all';
      // if ($location.search().category) {
      //     $location.search('category', null);
      // }
    } else if (this.productList.length > 0) {
      this.selectedCategoryType = this.productList[0].CategoryDescription || '';
    }
  }

  groupByKeepOrder(arr, prop) {
    const newArr = []; // array to return, keeps track of order
    const wrapObj = {}; // temporary object used for grouping

    _.forEach(arr, (item) => {
      // gets property name to group by and converts it to a string for grouping purposes
      let propName = item[prop];
      if (propName) {
        propName = propName.toString();
        // checks if group exists already and creates a new group if not, pushing it into the array to return ('newArr')
        if (!wrapObj[propName]) {
          wrapObj[propName] = [];
          newArr.push(wrapObj[propName]);
        }
        // adds item to the group
        wrapObj[propName].push(item);
      }
    });
    // delete wrapObj; // don't need this anymore
    return newArr;
  }
  clearcart() {
    this.allProductList = [];
    this.itemsService.selectedOrderItems = [];
    this.itemsService.selectedAutoOrderItems = [];
    window.localStorage.setItem('cart.order', JSON.stringify(this.itemsService.selectedOrderItems));
    window.localStorage.setItem('cart.autoship', JSON.stringify(this.itemsService.selectedAutoOrderItems));
    this.orderService.calculateOrderResponse = {};
    this.orderService.calculateAutoOrderResponse = {};
    this.userServiceModal.checkItems = false;
  }



  destory() {
    this.isShowViewButton = true;
    this.totalItem = {};
    this.buttonText = {};
    this.productList = [];
    this.categoryList = [];
    this.products = [];
    // $location.search('category', null);
  }

  redirection() {
    this.route.navigate(['/products']);
  }

}


<mat-dialog-content>
<div class="confirm-subscribe-dialog" fxLayout="row" fxLayoutAlign="center start">
    <div fxFlex="82" fxLayoutAlign="center stretch" class="height">
    <div fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="text-content-dialog" translate="confirmation_text_subscribe">

    </div>
    <div fxLayout="row" fxLayout.lt-md="column" class="side-padding w-100" fxLayoutAlign="space-between center">
      <button class="btn_confirm bgm-black c-white .text-transform" (click)="optionSelect(true)" translate="want_to_subscribe" ></button>
      <button class="btn_confirm bgm-white c-black" (click)="optionSelect(false)" translate="doesn't_subscribe"></button>
    </div>
    <div fxLayout="column" class="side-padding w-100" fxLayoutAlign="space-between center">
      <a class="learn_more" [routerLink]="['/product', data.ItemCode]" [queryParams]="{showMore: true}" [replaceUrl]="true" (click)="closeDialog(data.ItemCode)" translate="LearnMore"></a>
    </div>
    </div>
   </div>
</div>
</mat-dialog-content>




import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from 'src/app/core/services/seo.service';
import { UtilityService } from 'src/app/core/services/utility.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  public OrderData: any;
  public logoImageUrl: any;
  public type: string;
  constructor(
    private translate: TranslateService,
    private titleService: Title,
    public seo: SeoService,
    public invoiceDialogRef: MatDialogRef<InvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    private utilityService: UtilityService
    ) {
    this.seo.setTags({
      title: 'Invoice', // Title
      titleSuffix: '- Rain', // Title Suffix
      description: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.', // Description
      image: 'assets/images/logo.png', // Image
      keywords: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.' // Keywords
    });
    this.OrderData = data.orderResponse;
    this.type = data.type;
  }

  ngOnInit(): void {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Invoice') + ' - ' + text);
    });

    this.utilityService.getBase64ImageFromUrl('../../../../assets/images/logo.png')
      .then((result) => {
        this.logoImageUrl = this.sanitizer.bypassSecurityTrustUrl(result.toString());
      })
      .catch(err => console.error(err));
  }
  closeDailogInvoice() {
    this.invoiceDialogRef.close();
  }
}


import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { AutoshipConfigurationService } from 'src/app/core/services/autoshipConfiguration.service';
import { CartService } from 'src/app/core/services/cart.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { PersistentService } from 'src/app/core/services/persistent.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { AccountService } from 'src/app/core/authentication/account.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit {
  public productVeritcalBar: string = "";

  public ProductColorOptions: Array<any> = [];
  public product: any = {};
  public products: any[] = [];
  public relatedProducts: any[] = [];
  public orderOptions: any;
  public image: any;
  public zoomImage: any;
  ezpModel: any = {};
  event: any;
  activeImage: any = '';
  value: Array<any> = [];
  defaultImage: any = {};
  isOptionsSelected: boolean;
  public counter: number = 1;
  public OrderQuantityCount: any;
  itemTypes: any = {};
  index: number;
  bigProductImageIndex = 0;
  CartType: string;
  itemType: string;
  type: string;
  ItemID: any;
  isShowShareOptions: boolean;
  panelOpenSpecification: boolean = false;
  panelOpenAutoship: boolean = false;
  public selectedOrderType: number;
  isSmallScreen: boolean = false;
  ItemCode: any;
  selectedCountry: any;
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: true,
    pagination: true
  };
  public testimonialConfig: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: true,
    pagination: true
  };
  public slides = [
    '/assets/images/productsideimage.png',
    '/assets/images/productsideimage.png',
    '/assets/images/productsideimage.png'
  ];
  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  env: any;

  orderType: any = [{ typeId: 1, name: 'One Time Order' }, { typeId: 2, name: 'Subscription Order' }];
  extraInfo: boolean;
  dummyDetails: string = '';
  public sliderVideos: any;
  public sliderFeeds: any;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  public userService: UserServiceModal
  quickproduct = {};

  constructor(
    public configService: ConfigService,
    public productsService: ProductService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<QuickViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public cartService: CartService,
    public breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public itemsListService: ItemsListService,
    public activateroute: ActivatedRoute,
    public utilityService: UtilityService,
    public persistentService: PersistentService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public companyService: CompanyService,
    public user: UserService,
    public accountService: AccountService
  ) {
    dialogRef.disableClose = true;
    this.isSmallScreen = breakpointObserver.isMatched('(max-width: 599px)');

    this.userService = user.userServiceModal;
    this.env = environment;
    this.selectedOrderType = 2;

    this.selectedCountry = sessionStorage.getItem('selectedCountry')||'US';
  }

  productInfo: any;

  ngOnInit() {
    this.env = this.data.env;
    this.productInfo = this.data.product;
    

    let Video=this.productInfo?.ShortDetail2.replace(/(\r\n|\n|\r)/gm, "");
    let Feed=this.productInfo?.ShortDetail3.replace(/(\r\n|\n|\r)/gm, "");
    try{
       this.sliderVideos=JSON.parse(Video);
    }catch(e){}

    try{
       this.sliderFeeds=JSON.parse(Feed);
    }catch(e){}

    try {
      this.ProductColorOptions = this.configService.commonSettings['ProductsColor'].filter((item) => { return item.ItemCode == this.productInfo.ItemCode })[0] || this.configService.commonSettings['ProductsColor'][0];

    } catch {
      this.ProductColorOptions = this.configService.commonSettings['ProductsColor'][0];
  }
    if (this.productInfo.StaticKitChildren.length == 0) {
      this.productVeritcalBar = "border-image:linear-gradient(to bottom, " + this.ProductColorOptions['Color1'] + " 0%, " + this.ProductColorOptions['Color2'] + " 25%, " + this.ProductColorOptions['Color3'] + " 50%, " + this.ProductColorOptions['Color4'] + " 75%);";

    }
    else {
      this.productVeritcalBar = "border-color:" + this.ProductColorOptions['Color5'];
    }

    this.productsService.getProductByItemCode(this.productInfo.ItemCode).subscribe((product) => {

      this.quickproduct = [product];
      //this.productInfo = this.quickproduct[0].Item;
      console.log("api prod", this.productInfo.LongDetail1);


      const metaTitle=this.quickproduct[0]?.Item?.ItemDescription;
      this.translate.get('CompanyName').subscribe({next:(text) => {
        this.titleService.setTitle(this.translate.instant(metaTitle) + ' - ' + text);
      }});
    });

    this.onItemsSucces(this.productInfo);
    this.setUpOrderOptions(this.productInfo);
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      scrollbar:false,
      navigation: false,
      autoplay: {
        delay: 7000,
      },
      pagination:  {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 1
        }
      }
    };
    this.testimonialConfig = {
      observer: true,
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      scrollbar:false,
      navigation: false,
      autoplay: {
        delay: 4000,
      },
      effect: 'fade',
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 1
        }
      }
    };
  }

  closeDialog() {
    this.dialogRef.close('close icon');
  }
  optionSelect(option: string) {
    this.dialogRef.close(option);
  }

  hideAddToCart(item, isAutoShip = false) {
    if (!this.utilityService.isEmptyObject(item)) {
      const ItemCode = this.getItemCode(item);
      let isOrderAllow = false;
      if (item.GroupMembers && item.GroupMembers.length > 0 && isAutoShip) {
        _.each(item.GroupMembers, (value) => {
          if (value.ItemCode == ItemCode) {
            if (isAutoShip) {
              isOrderAllow = item.AllowOnAutoOrder; // ? value.AllowAutoship : false;
            } else {
              isOrderAllow = false;
            }

          }
        });
      } else if (isAutoShip) {
        return item.AllowOnAutoOrder;
      } else {
        return false;
      }
      return isOrderAllow;
    }
  }
  
  public addToCart1(type) {
    // if (quantity === 0) {
    //   return false;
    // }

    if (type == 'order') {
      if (!this.hideAddToCart(this.data.product, true)) {
        //For OrderItem Adding
        this.cartService.addToCart(
          this.data.product,
          true,
          this.getItemCode(this.data.product), // product.Sku
          type === 'autoship',
          false,
          type === 'pack',
          false
        );
        this.dialogRef.close();
      this.cartService.sidebarOpenStatus = true;
      this.cartService.sidebarSummaryCartDrawer.open();
      }
      else if (this.accountService.isLoggedUserRetailType())  {
        this.cartService.confrimSubscribe(this.data.product.ItemID, this.data.product.ItemCode).then((result) => {
          if (result == "learnmore") {
            this.cartService.sidebarSummaryCartDrawer.close();
            this.dialog.closeAll();
            return;
          }
          if (result) {
            //For AutoOrderItem Adding
            this.cartService.addToCart(this.data.product, true, this.getItemCode(this.data.product), true, false, false);
            this.cartService.addToCart(
              this.data.product,
              true,
              this.getItemCode(this.data.product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
            this.dialogRef.close();
          }
          else {
            //For OrderItem Adding
            this.cartService.addToCart(
              this.data.product,
              true,
              this.getItemCode(this.data.product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
            this.dialogRef.close();
          }
        });
      } else {
        //For OrderItem Adding
        this.cartService.addToCart( this.data.product, true, this.getItemCode(this.data.product), type === 'autoship', false, type === 'pack', false );
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
        this.dialog.closeAll();
      }
    }
    else {
      this.updateorderwithAutoship(this.data.product);
      this.dialogRef.close();
    }
  }

  // Add to cart
  public addToCart(type, product: any) {
    // if (quantity === 0) {
    //   return false;
    // }
    // this.currentQuantity(type, product);
    if (type === 'order') {

      if (this.accountService.isLoggedUserRetailType())  {
        this.cartService.confrimSubscribe(product.ItemID, product.ItemCode).then((result) => {
          if (result == "learnmore") {
            this.dialog.closeAll();
            return;
          }
          if (result) {
            //For AutoOrderItem Adding
            this.cartService.addToCart(product, true, this.getItemCode(product), true, false, false);
            this.cartService.addToCart(
              product,
              true,
              this.getItemCode(product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
            this.dialog.closeAll();
  
          }
          else {
            //For OrderItem Adding
            this.cartService.addToCart(
              product,
              true,
              this.getItemCode(product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
          }
          this.dialog.closeAll();
        });
      } else {
        //For OrderItem Adding
        this.cartService.addToCart( product, true, this.getItemCode(product), type === 'autoship', false, type === 'pack', false );
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
        this.dialog.closeAll();
      }

      

      this.product.Quantity = this.OrderQuantityCount;
    }
    else {

      this.updateorderwithAutoship(product);
      this.dialog.closeAll();

    }

  }

  updateorderwithAutoship(item: any) {
    //For AutoOrderItem Adding
    this.cartService.addToCart(item, true, this.getItemCode(item), true, false, false);
    //For OrderItem Adding
    this.cartService.addToCart(item, true, this.getItemCode(item), false, false, false);

  }

  selectOption(item, option, value, isKitItem) {
    option.selected = value[0];
    this.orderOptions[this.getOrderOptionKey(item, option)] = value[0];
    const tempOptData = [];
    Object.keys(this.orderOptions).forEach(optdata => {
      tempOptData.push(optdata);
    }),
      item.GroupMembers.forEach(itemOptdata => {
        let tempimgurl = itemOptdata.Item.LargeImageUrl;
        const tempimage = {
          Path: tempimgurl
        };
        this.setActiveImage(tempimage);
      });
    if (isKitItem) {
      item.KitCustomItemCode = this.getItemCode(item);
    }
  }

  setInitialItemOption(option, value) {
    const defaultOption =
      this.orderOptions[this.getOrderOptionKey(this.product, option)] ||
      option.Option;
    if (value.Option === defaultOption) {
      option.selected = value.Option;
    }
  }

  getOrderOptionKey(item, option) {
    return option.selected.ItemCode;
  }

  // Determine what values are available based on what's already been selected
  getValidValues(item, option, values) {
    // Get the already-selected option values (except this one and values from other items)
    const currentOption = this.getOrderOptionKey(item, option);
    const otherOptions = [];
    for (const key in this.orderOptions) {
      if (
        key === currentOption ||
        item.ItemCode.toString() !== key
      ) {
        continue;
      }
      otherOptions.push(this.orderOptions[key]);
    }
    // Whittle down the OptionMaps to the ones that have all the already-selected options
    const optionMapNames = item.GroupMembers
      ? item.GroupMembers.map(optMap => {
        return item.ItemCode;
      })
      : [];
    const validOptionMaps = optionMapNames.filter(optNames => {
      // For each OptionMap, ensure that it contains all the other selected options
      let optionValue1;
      for (const index in otherOptions) {
        if (otherOptions.hasOwnProperty(index)) {
          optionValue1 = otherOptions[index];
          if (~optNames.indexOf(optionValue1)) {
            continue;
          }
          return false;
        }
      }
      return true;
    });

    // Union the validOptionMaps
    const validOptionValues = validOptionMaps.reduce((arr, optMap) => {
      let optionName;
      for (const index in optMap) {
        if (optMap.hasOwnProperty(index)) {
          optionName = optMap[index];
          if (!~arr.indexOf(optionName)) {
            arr.push(optionName);
          }
        }
      }
      return arr;
    }, []);

    // Set `isAvailable: true` on each option that can be found in validOptionNames, and `isAvailable: false` on the others
    let optionValue;
    for (const index in values) {
      if (values.hasOwnProperty(index)) {
        optionValue = values[index];
        optionValue.isAvailable = !!~validOptionValues.indexOf(
          optionValue.Option
        );
      }
    }

    // return the original values
    return values.filter(itm => {
      return itm.isAvailable;
    });
  }

  checkOptions(option) {
    let count = 0;
    this.value.forEach((item) => {
      if (item) {
        count++;
      }
    });

    this.isOptionsSelected = (count === option.length);
  }

  getOptionsText(optionValArray) {
    return '[' + optionValArray.join(', ') + ']';
  }

  getItemCode(item) {
    item.selectedOptions = this.value[0]?.ItemDescription || item.ItemDescription;
    // If there are no customizations, just return the item code
    if (!item.GroupMembers || !item.GroupMembers.length) {
      // return this.product.ItemCode;
      return item.ItemCode
    } else {
      return this.value[0].ItemCode;
    }

  }

  openSelect(event) {
    this.event = event || this.event;
    // this is due to select input fields are not consistant in some browser
    if (window.screen.availWidth < 600 && !event) {
      setTimeout(() => {
        // $('.md-select-menu-container.md-active.md-clickable').css('top', (this.event.clientY - 20 + 'px'));
      }, 100);
    }
  }
  // change active image
  setActiveImage(image) {
    this.ezpModel = {
      small: image.Path || this.defaultImage.Path,
      large: image.Path || this.defaultImage.Path
    };
    setTimeout(() => {
      this.activeImage = image;
    }, 1);
  }

  setUpOrderOptions(item) {
    if (!this.orderOptions) {
      this.orderOptions = {};
    }

    const dict = this.orderOptions;

    // If editing an item with selected customizations, find them and set the dropdowns
    let optionsMap;
    _.each(item.OptionsMap, (val) => {
      val.OptionNames = val.Key.split('|');
    });
    if (item.selectedOptions) {
      item.OptionsMap?.some(optMap => {
        if (optMap.ItemId === (item.KitCustomItemCode || item.ItemID)) {
          optionsMap = optMap.OptionNames;
          return true;
        }
        return false;
      });
    }

    // Otherwise, default to the first options map
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map(optMap => {
        return optMap.OptionNames;
      })
      : [];

    if (!item.selectedOptions || !optionsMap) {
      optionsMap = optionMapNames[0];
    }

    // Loop through the OptionsMap
    let optionVal;
    let optionValues;
    for (const index in optionsMap) {
      if (optionsMap.hasOwnProperty(index)) {
        optionVal = optionsMap[index];
        // For each option in the OptionsMap, loop through the ItemOptions
        for (const itemIndex in item.ItemOptions) {
          // Get all the values of the ItemOption
          if (item.ItemOptions.hasOwnProperty(itemIndex)) {
            optionValues = item.ItemOptions[itemIndex].Values.map(
              getOptionValueName
            );
            // If one of them is the value from the OptionsMap, use it
            const option = item.ItemOptions[itemIndex];
            if (
              !!~optionValues.indexOf(optionVal) &&
              !dict[this.getOrderOptionKey(item, option)]
            ) {
              dict[this.getOrderOptionKey(item, option)] = optionVal;
              break;
            }
          }
        }
      }
    }

    function getOptionValueName(val) {
      return val.Option;
    }
  }

  getPrice(item) {
    if (item != null && item.GroupMembers?.length > 0) {
      return (item?.GroupMembers[0].Item.Price || (item?.GroupMembers[0].Item.Prices && item?.GroupMembers[0].Item.Prices[0].Price));
    } else {
      return (item.Price || (item.Prices && item.Prices[0].Price));
    }
  }
  getSubsPrice(item) {
    if (item != null && item.GroupMembers?.length > 0) {
      return (item?.GroupMembers[0].Item[`PriceType${this.userService.customerTypeID == 3 ? this.userService.customerTypeID : 2}Value`]);
    } else {
      return (item[`PriceType${this.userService.customerTypeID == 3 ? this.userService.customerTypeID : 2}Value`]);
    }
  }

  public transformItem(item) {
    this.isShowShareOptions = false;
    item.Price =
      item.Price || item.Price === 0
        ? item.Price
        : item.Prices && item.Prices[0] && item.Prices[0].Price;
    item.Images = item.Images || [];
    if (
      item.LargeImageUrl &&
      !_.find(item.Images, { Path: item.LargeImageUrl })
    ) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.LargeImageUrl
      });
    } else if (item.Image && !_.find(item.Images, { Path: item.Image })) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.Image
      });
    }
    this.isShowShareOptions = true;
    return item;
  }

  public onItemsSucces(result) {
    if (result) {
      console.log('result', result);
      result = [result];
      this.itemType = this.type === 'order' ? 'order' : this.type == 'pack' ? 'pack' : 'autoship';
      this.productsService[this.itemType] = result.map((item) => {
        item.Price = item.Price || item['PriceType' + this.userService.customerTypeID + 'Value'];
        return item;
      });
      this.itemsListService.products = this.productsService[this.itemType];
      this.itemsListService.type = this.type;
      const selectedItem = this.product;
      this.products = result.map((item) => {
        item.Price = item.Price || item['PriceType' + this.userService.customerTypeID + 'Value'];
        return item;
      });
      if (selectedItem) {
        this.products = _.filter(this.products, (product) => {
          return product.ItemID != selectedItem.ItemID;
        });
      }
      if (this.ItemCode) {
        let isItemExists = false;
        _.each(result, (item) => {
          if (item.HasOptions) {
            _.each(item.OptionsMap, (value) => {
              if (value.ItemCode == this.ItemCode) {
                item.ItemCode = this.ItemCode;
                item.ItemID = value.ItemID;
                return;
              }
            });
          }
          //if (this.ItemCode ? ((item?.ItemDescription || '').toLowerCase().replace(/\s/g, '') == this.ItemCode.toLowerCase().replace(/\s/g, '')) : (item.ItemCode == this.ItemCode)) {
          isItemExists = true;
          this.product = this.transformItem(item);
          this.OrderQuantityCount = selectedItem ? (selectedItem.Quantity || 1) : 1;
          item.Quantity = selectedItem ? (selectedItem.Quantity || 1) : 1;
          localStorage.setItem('params.type', this.type);
          localStorage.setItem('params.productList', JSON.stringify(this.products));
          // $scope.setDefaultValue(item, type);
          //}
        });
        if (!isItemExists) {
          this.cartService.checkoutPaymentButton = false;
          this.router.navigate(['/Products']);
          // $state.go('Products', { WebAlias: $state.params.WebAlias });
        }
      }
    }
  }

  onIndexChange(index: number) {
    console.log('Swiper index: ', this.videoplayer?.nativeElement);
    this.videoplayer?.nativeElement.pause();
  }
}

<div class="rain-market-selector-dialog">
    <div fxLayout="row" class="market-selector ms-padding">
      <div fxFlex="100" fxLayoutAlign="space-between center">
        <h2 class="country-selection" mat-dialog-title translate="CreditCard"></h2>
        <mat-icon mat-button (click)="dialogRef.close(false)" class="close-icon">close</mat-icon>
      </div>
    </div>
    <hr class="mb-15">
    <div fxLayout="column" class="ms-padding main-content">
        <section class="b-top" fxLayout="column" fxLayoutAlign="start stretch">
            <ng-container class="kaxsdc" data-event='load'>
                <section class="kaxsdc" data-event='load' fxLayout="column">
                  <input type="hidden" id="creditcard-token" name="creditcard-token" value="" />
                  <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />
                  <mat-form-field appearance="outline" fxFlex="100" class="exigo-validate" *ngIf="showBillForm">
                    <mat-label translate="NameOnCard"></mat-label>
                    <input type="text" validate noempty="true" matInput cardSection="true"
                      [(ngModel)]="cardDetail.CardName" />
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" class="exigo-validate cardiframe" id="cardiframe" fxFlex="100"
                    *ngIf="showBillForm" (click)="activateInput()">
                    <mat-label translate="CardNumber"></mat-label>
                    <input matInput cardNumber="true" maxlength="24" type="hidden" [(ngModel)]="cardDetail.CardNumber" cardSection="true"/>
                    <div id="cc-wrapper" class="cc-wrapper"></div>
                    <input type="hidden" class="creditcard-token"  id="Token"/>
                    <input type="hidden" class="creditcard-display" id="Display" />
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" fxFlex="100" class="w-100 margininput state exigo-validate"
                    *ngIf="showBillForm">
                    <mat-label translate="ExpirationMonth"></mat-label>
                    <mat-select id="month" name="expirationmonth" [(ngModel)]="cardDetail.CardExpirationMonth" validate
                      validate-select="true" cardSection="true">
                      <mat-option *ngFor="let month of cardExpMonths"
                        (ngInit)="userService.cardDetail.CardExpirationMonth=cardExpMonths[0]?.value" [value]="month.value"
                        [textContent]="month.value + ' - ' + month.text"></mat-option>
                    </mat-select>
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" fxFlex="100" class="exigo-validate" *ngIf="showBillForm">
                    <mat-label translate="ExpirationYear"></mat-label>
                    <mat-select [(ngModel)]="cardDetail.CardExpirationYear" name="expireYear" validate
                      validate-select="true" cardSection="true">
                      <mat-option [value]="year.value" *ngFor="let year of cardExpYears"
                        (ngInit)="userService.cardDetail.CardExpirationYear=cardExpYears[0]?.value">{{
                        year.text
                        }}</mat-option>
                    </mat-select>
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" fxFlex="100" class="exigo-validate" *ngIf="showBillForm">
                    <mat-label translate="CVV"></mat-label>
                    <input type="text" validate inputonlynumber="true" cardSection="true" noempty="true" matInput
                      [(ngModel)]="cardDetail.CardCvv" maxlength="4" />
                  </mat-form-field>
                </section>
              </ng-container>

              <!-- Billing Address -->

              <ng-container>
                <mat-form-field appearance="outline" class="exigo-validate" fxFlex="auto">
                    <mat-label translate="Country"></mat-label>
                    <mat-select (selectionChange)="billingcountrySelected($event)" name="country"
                        [(ngModel)]="userService.billingAddress.CountryCode" cardSection="true" validate
                        validate-select="true">
                        <mat-option [value]="country.CountryCode" *ngFor="let country of billingCountries"
                            [textContent]="('Country_' + (country.CountryCode?.toUpperCase())) | translate"></mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="exigo-validate" fxFlex="auto">
                    <mat-label translate="StreetAddress"></mat-label>
                    <input type="text" cardSection="true" validate noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.Street1">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="City"></mat-label>
                    <input type="text" cardSection="true" validate noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.City">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="Select State"></mat-label>
                    <mat-select [(ngModel)]="userService.billingAddress.StateName" name="state" cardSection="true" validate
                        validate-select="true">
                        <mat-option *ngFor="let region of billingallowedRegions" [value]="region.RegionCode">{{
                            region.RegionName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate">
                    <mat-label translate="Zip"></mat-label>
                    <input type="text" cardSection="true" validate regxvalidzip="true"
                        [attr.regexcountry]="userService.billingAddress.CountryCode" noempty="true" matInput
                        [(ngModel)]="userService.billingAddress.PostalCode" maxlength="9">
                </mat-form-field>

            </ng-container>
        </section>
    </div>

    <div fxLayout="row" class="save-btn-row" fxLayout="row" fxLayoutAlign="end">
        <button mat-raised-button class="sa-button" validate trigger-check-section="true" sectiontype="cardSection"
         (success)="onSubmit()">Submit</button>
    </div>

  </div>
  

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Product } from 'src/app/core/modals/product.modal';
import { RestApiService } from '../http/restapi.service';
import { UserService } from './user.service';
import * as _ from 'lodash';
import { UtilityService } from './utility.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';


// Get product from Localstorage
const products =[];
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public catalogMode: boolean = false;
  public allItems: any;
  private url1: string = 'assets/data/';
  public url = 'assets/data/banners.json';

  public compareProducts: BehaviorSubject<any> = new BehaviorSubject([]);
  public observer: Subscriber<{}>;
  public selectedPacks:Array<any>=[];
  public selectedOrderItems:Array<any>=[];
  public selectedAutoOrderItems:Array<any>=[];
  public packs = [];
  public orders = [];
  public autoship = [];
  public IsAllowDynamicCouponcode: boolean = true;
  previousRequest = {};
  userServiceModal: any;
  StarterKitItemsArray = [];
  
  constructor(
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
    public apiService: RestApiService,
    public user: UserService,
    public utilityService: UtilityService,
    public companyService: CompanyService,
    public configService: ConfigService
    ) {


    products.push((window && window.localStorage)? JSON.parse(window.localStorage.getItem('compareItem')) || []:[]);
    this.compareProducts.subscribe(products => products = products);
    this.userServiceModal = this.user.userServiceModal;
    this.init();
  }

  private products(req?: any): Observable<any> {
    
    const request = {
      CountryCode: req?.CountryCode || sessionStorage.getItem('selectedCountry') || 'us',
      PriceType: req?.PriceGroup || this.userServiceModal.customerTypeID || 2,
      LanguageID: req?.LanguageCode || sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
      CategoryId: req?.CategoryId || 0,
      SearchQuery: ''
    };
    return this.apiService.getItemList(request).pipe(map(items => {
      sessionStorage.setItem('items',JSON.stringify(items.Data));
      this.allItems = items.Data;
      return items.Data;
    }));
  }

  // Get Products
  public getProducts(request?: any ): Observable<Array<Product>> {
    this.previousRequest = this.previousRequest || request;
    
    this.allItems=JSON.parse(sessionStorage.getItem('items') || '[]');
    if (this.allItems && this.allItems.length > 0) {
      const itemsStream = new Observable(observer => {
        observer.next(this.allItems);
        observer.complete();
      });
      return <Observable<any>>itemsStream;
    } else {
      return this.products(request);
    }
  }

  // Get Products By Id
  public getProduct(id: number): Observable<any> {
    
    this.allItems = JSON.parse(sessionStorage.getItem('items'));
    if (this.allItems && this.allItems.length > 0) {
      const product = this.allItems.find((item: any) => {
        return item.ItemID == id;
      });
      const request = {
        CountryCode: sessionStorage.getItem('selectedCountry') || 'us',
        PriceType: this.userServiceModal.customerTypeID || 2,
        LanguageID: sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
        CategoryId: 0,
        SearchQuery: '',
        ItemCodes: product?.ItemCode
      };
      return this.apiService.getItemDetail(request).pipe(map(items => {
        return items.Data;
      }));
    } else {
      return new Observable<any>((observer) => {
        const request = {
          CountryCode:  sessionStorage.getItem('selectedCountry') || 'us',
          PriceType: this.userServiceModal.customerTypeID || 2,
          LanguageID:sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
          CategoryId:0,
          SearchQuery: ''
        };
        this.apiService.getItemList(request).pipe(map(items => {
          const products = items.Data.find((item: any) => {
          return item.ItemID == id;
        });
        const request = {
          CountryCode: sessionStorage.getItem('selectedCountry') || 'us',
          PriceType: this.userServiceModal.customerTypeID || 2,
          LanguageID: sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
          CategoryId: 0,
          SearchQuery: '',
          ItemCodes: products?.ItemCode
        };
          return request;
        })).subscribe((request) => {
          this.apiService.getItemDetail(request).pipe(map(items => {
           return items.Data;
          })).subscribe((items) => {
              observer.next(items);
            });
        });
    });
    }
  }
    // Get Products By ItemCode
  public getProductByItemCode(ItemCode: string): Observable<any> {
    
    this.allItems = JSON.parse(sessionStorage.getItem('items'));
    if (this.allItems && this.allItems.length > 0) {
      const request = {
        CountryCode: sessionStorage.getItem('selectedCountry') || 'us',
        PriceType: this.userServiceModal.customerTypeID || 2,
        LanguageID: sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
        CategoryId: 0,
        SearchQuery: '',
        ItemCodes: ItemCode
      };
      return this.apiService.getItemDetail(request).pipe(map(items => {
        return items.Data;
      }));
    } else {
      return new Observable<any>((observer) => {
        const request = {
          CountryCode: sessionStorage.getItem('selectedCountry') || 'us',
          PriceType: this.userServiceModal.customerTypeID || 2,
          LanguageID: sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
          CategoryId: 0,
          SearchQuery: '',
          ItemCodes: ItemCode
        };
          this.apiService.getItemDetail(request).pipe(map(items => {
           return items.Data;
          })).subscribe((items) => {
              observer.next(items);
            });
          });
    }
  }
  // Get Pack By ItemCode
  public getPackByItemCode(id: any): Observable<any> {
    
      const request = {
        CountryCode: sessionStorage.getItem('selectedCountry')|| 'us',
        PriceType:  this.userServiceModal.customerTypeID || 2,
        LanguageID: sessionStorage.getItem('selectedLanguageID') || this.configService.commonData.selectedLanguageID || 0,
        CategoryId: 0,
        SearchQuery: '',
        ItemCodes: id
      };
      return this.apiService.getSubscriptionItemDetail(request).pipe(map(items => {
        return items.Data;
      }));
  }
  private init() {
    if (window.localStorage && window.localStorage.getItem('cart.packs')) {
      this.selectedPacks = JSON.parse(window.localStorage.getItem('cart.packs'));
    }
    if (window.localStorage && window.localStorage.getItem('cart.order')) {
      this.selectedOrderItems = JSON.parse(window.localStorage.getItem('cart.order'));
    }
    if (window.localStorage && window.localStorage.getItem('cart.autoship')) {
      this.selectedAutoOrderItems = JSON.parse(window.localStorage.getItem('cart.autoship'));
    }
  }
  public totalItemsInCartCount(){
    const totalCount = (this.selectedOrderItems.length + this.selectedAutoOrderItems.length + this.selectedPacks.length)
    return totalCount;
  }
}

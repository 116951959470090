<div class="rain-market-selector-dialog">
  <div fxLayout="row" class="market-selector ms-padding">
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <h2 class="country-selection" mat-dialog-title translate="SelectCountLang"></h2>
      <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
    </div>
  </div>
  <hr class="mb-15">
  <div fxLayout="column" class="ms-padding">
    <div fxFlex="100" fxLayout="column" class="m-b-15" fxLayoutAlign="center start">
      <h2 class="m-0 f-s-15" mat-dialog-title translate="SelectCountry"></h2>
      <mat-form-field [floatLabel]="'never'" class="border w-100 country-margin">
        <mat-select required class="flag-icon flag-icon-{{selectedCountry.CountryCode?.toLowerCase()|| 'us'}}" [(ngModel)]="selectedCountry" (selectionChange)="changedCountry(selectedCountry)" >
          <mat-option [value]="country" *ngFor="let country of allowedCountries" ><span class="flag-icon flag-icon-{{country.CountryCode.toLowerCase()|| 'us'}} popup-option-icon"></span><span  [textContent]="'Country_'+country.CountryCode | translate" translate></span></mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" fxLayout="column" class="m-b-15" fxLayoutAlign="center start" *ngIf="selectedLanguage">
      <h2 class="m-0 f-s-15" mat-dialog-title translate="ChooseLang"></h2>
      <mat-form-field [floatLabel]="'never'" class="border w-100">
        <mat-select [(ngModel)]="selectedLanguage">
          <mat-option [value]="language" *ngFor="let language of allowedLanguages">
            <span [textContent]="'Language_'+(language.LanguageID)" translate></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="m-b-15">
      <div fxLayout="row" fxLayoutAlign="end center">
          <button mat-stroked-button class="mat-stroked-button" (click)="continue()" translate="Submit"></button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private translate:TranslateService,private titleService:Title,public seo:SeoService) {
    this.seo.setTags({
      title: 'Contact', // Title
      titleSuffix: '- Rain', // Title Suffix
      description: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.', // Description
      image: 'assets/images/logo.png', // Image
      keywords: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.' // Keywords
    });
   }

  ngOnInit(): void {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Contact') + ' - ' + text);
    });
  }

}

<div class="login sec-padding">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" ng-cloak flex class="login">
    <mat-card fxLayout-padding fxFlex="50" fxFlex-xs="100" fxFlex-sm="70">
      <div class="p-20">
        <h3 class="f-s-18 header-border">
          <mat-icon class="f-s-18 v-align-middle">lock_open</mat-icon>
          <span translate="Global_LoginToYourAccount" class="f-s-18"></span>
        </h3>
        <form name="Login" class="p-l-10">
          <div class="m-t-30">
            <mat-form-field appearance="outline" class="w-100 exigo-validate">
              <mat-label translate="Username"></mat-label>
              <input name="username" [(ngModel)]="userInfo.LoginName" login="true" [ngModelOptions]="{standalone: true}"
                matInput validate noempty="true">
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field appearance="outline" class="w-100 exigo-validate">
              <mat-label translate="Password"></mat-label>
              <input type="password" [(ngModel)]="userInfo.Password" login="true" [ngModelOptions]="{standalone: true}"
                matInput validate noempty="true">
            </mat-form-field>
          </div>
          <div layout="row" layout-sm="column" class="login-bottom" layout-xs="column" layout-align="start end"
            layout-align-xs="start end" layout-align-sm="start end" layout-wrap>
            <button validate trigger-check-section="true" sectiontype="login" type="submit"
              class="btn-project upper-case w-100" mat-raised-button color="primary" type="submit"
              (success)="loginFunction()"><span translate="SignIn" style="text-transform: uppercase;"></span></button>
          </div>
          <a class="forgot-pass pull-right m-t-10" id="forgot-pass" (click)="redirectToForgotPassword()">
            <mat-icon class="v-align-bottom f-s-18">lock</mat-icon>
            <span translate="ForgotPassword"></span>
          </a>
        </form>
      </div>
      <div class="p-20">
        <h3 class="f-s-18 header-border no-border" style="cursor: pointer;" routerLink="/signup">
          <mat-icon class="f-s-18 v-align-middle">person</mat-icon>
          <span translate="Replicated_Login_NewAccount" class="f-s-18 upper-case"></span>
        </h3>
        <div layout="row" layout-sm="column" class="m-t-20" layout-xs="column" layout-align="start end"
          layout-align-xs="start end" layout-align-sm="start end" layout-wrap>
          <button routerLink="/checkout" type="submit" class="btn-project upper-case w-100" mat-raised-button
            color="primary" type="submit" translate="create customer account"></button>
        </div>
        <div layout="row" layout-sm="column" class="m-t-20" layout-xs="column" layout-align="start end"
          layout-align-xs="start end" layout-align-sm="start end" layout-wrap>
          <button routerLink="/enrollment" type="submit" class="btn-project upper-case w-100" mat-raised-button
            color="primary" type="submit" translate="create affiliate account"></button>
        </div>
      </div>
    </mat-card>
  </div>
</div>

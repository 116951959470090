import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { NotificationService } from './notification.service';
import { RestApiService } from '../http/restapi.service';
import { UserService } from './user.service';
import { UniquePipe } from '../pipes/unique.pipe';
import { UtilityService } from './utility.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    public PaymentDataResponse: any = {};
    public PaymentTypeResponse: Array<any> = [];
    public SavedPaymentTypeResponse: any = [];
    public selectedPaymentTypeName = '';
    public SelectedPaymentTypes: any;
    public OldSelectedPaymentType: any = {};
    public oldSelectedPaymentTypeName: string = '';
    public AllowedMethods = [];
    public SaveMethods = [];
    public IsFrameReload: boolean;
    userService: UserServiceModal;

    isModalOpen = false;
    constructor(public dialog: MatDialog, public notificationService: NotificationService, public user: UserService, public apiService: RestApiService,public utilityService:UtilityService,public companyService:CompanyService, public configService: ConfigService) {
        this.userService = user.userServiceModal;
    }

    public getPaymentType(): Promise<Array<any>> {
        const getPaymentTypePromise = new Promise((resolve, reject) => {
            const request = {
                countrycode: this.configService.commonData.selectedCountry || sessionStorage.getItem('selectedCountry') || 'us',
            };
            this.apiService.getPaymentMerchants(request)
                .subscribe({next:(result) => {
                    try {
                        if (parseInt(result.Status, 10) === 0) {

                          //Added to remove PaymentMethod with PaymentTypeID = 29;
                        //   result.Data["AvailableGeneralPaymentMethods"] = result.Data["AvailableGeneralPaymentMethods"].filter(p => {
                        //     return p.PaymentTypeID !== 29;
                        //   });
                            this.PaymentTypeResponse = result.Data;
                            resolve(this.PaymentTypeResponse);
                        } else {
                            this.notificationService.error('Error', result.Message);
                            reject(result);
                        }
                    } catch (ex) {

                        this.notificationService.error('Error', 'OopsSomethingWentWrong');
                        reject(ex);
                    }
                }, error:(error) => {
                    reject(error);
                }, complete:() => {

                }});
        });
        const res = Promise.all([getPaymentTypePromise]);
        return res;
    }

    public getSavedPayment(): Promise<Array<any>> {
        const getCustomerBillingPromise = new Promise((resolve, reject) => {
            const request = {
                "CustomerID": this.userService.customerData.CustomerID
            };
            this.apiService.GetCustomerBilling(request).subscribe(
                {next: (result) => {
                try {
                    if(parseInt(result.Status, 10) === 0) {
                        var resp = [];
                        resp.push(result.Data['PrimaryCreditCard']);
                        resp.push(result.Data['SecondaryCreditCard']);
                        this.SavedPaymentTypeResponse = resp;
                        console.log('this.SavedPaymentTypeResponse',this.SavedPaymentTypeResponse);
                        return resolve(result.Data)
                    } else {
                        this.notificationService.error('Error', result.Message);
                        return reject(result)
                    }
                } catch (ex) {
                    this.notificationService.error('Error', 'OopsSomethingWentWrong');
                    reject(ex);
                }
            },error:(error) => {
                reject(error);
            }, complete:() => {
            }})
        });
        const res = Promise.all([getCustomerBillingPromise]);
        return res;
    }

    public getCustomerSavedPaymentMethods(): Promise<Array<any>> {
        return new Promise((resolve, reject) => {
            try {
                this.apiService.getCustomerSavedPaymentMethods()
                .subscribe({
                    next: (result) => {
                        
                        // Adding additional key 'paymentMethodID' in object.
                        const paymentMethods = result.Data;
                        paymentMethods.forEach(method => {
                            if(method.hasOwnProperty('Token')){
                                method['PaymentTypeID'] = 1; //Credit Card
                            } else {
                                method['PaymentTypeID'] = 9; //Bank Account
                            }
                        })
                        console.log('paymentMethods', paymentMethods)
                        if(result?.Data){
                            this.SavedPaymentTypeResponse = paymentMethods;
                        }
                        resolve([...paymentMethods]);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public clearPayment() {
        this.PaymentDataResponse = {};
        this.PaymentTypeResponse = [];
        this.selectedPaymentTypeName = '';
        this.SelectedPaymentTypes = {};
        this.OldSelectedPaymentType = {};
        this.AllowedMethods = [];
        this.SaveMethods = [];
       window.localStorage.setItem('paymentService', JSON.stringify({}));
    }
}

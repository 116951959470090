<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20px" fxLayoutAlign="space-between center" class="row">
      <div class="breadcrumb-title pr-15" fxFlex="100" fxFlex.gt-md="45" fxFlex.md="60">
          <h1 [textContent]="title | translate"></h1>
      </div>
      <div class="breadcrumb-path " fxFlex="100" fxFlex.gt-md="45" fxFlex.md="40" *ngIf="showCrumbs">
        <ul class="breadcrumb" fxLayout="row">
          <li class="breadcrumb-item-home"><a [routerLink]="breadcrumb[0]?.route1"
              [textContent]="breadcrumb[0].path1"></a></li>
          <li class="breadcrumb-item" [textContent]="'/'" *ngIf="breadcrumb[1]?.path2"></li>
          <li class="breadcrumb-item-path active" aria-current="page"><a [routerLink]="breadcrumb[1]?.route2"
            [textContent]="breadcrumb[1]?.path2"></a></li>
          <li class="breadcrumb-item" [textContent]="'/'"  *ngIf="breadcrumb[2]?.path3"></li>
          <li class="breadcrumb-item-path active" aria-current="page"><a [routerLink]="breadcrumb[2]?.route3"
            [textContent]="breadcrumb[2]?.path3"></a></li>
        </ul>
      </div>
      <div class="accountMenuSection" *ngIf="showAccountMenu">
        <button type="button" [matMenuTriggerFor]="accountLinksMenu" class="accountMenuButton" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="5px">
            <div [textContent]="('YourAccount' | translate)" ></div>
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #accountLinksMenu>
          <div fxLayout="column" fxFlex="100" fxLayoutAlign="center stretch" class="account-links">
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('settings')" [textContent]="'AccountSettings' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('orders')" [textContent]="'Orders' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('subscription')" [textContent]="'Subscription' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('address')" [textContent]="'Addresses' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('avatar')" [textContent]="'Avatar' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('paymentmethods')" [textContent]="'PaymentMethods' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('notifications')" [textContent]="'Notifications' | translate"></div>
            </menu>
            <menu mat-menu-item>
              <div class="link" (click)="navigateLink('rewards')" [textContent]="'Rewards' | translate"></div>
            </menu>
            <menu mat-menu-item fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-icon>exit_to_app</mat-icon>
              <div class="link" (click)="navigateLink('logout')" [textContent]="'Logout' | translate"></div>
            </menu>
          </div>
        </mat-menu>
      </div>
    </div>
    </div>
  </div>
  <!-- breadcrumb End -->
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, skip, Subscriber } from 'rxjs';
import { RestApiService } from '../http/restapi.service';
import { UserServiceModal, PersonalInfo, WebOffice, ReferralInfo, ShippingAddress } from 'src/app/core/modals/userservice.modal';
import { UtilityService } from './utility.service';
import { getBaseLocation } from 'src/app/baseurl';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  // Add 'implements OnInit' to the class.
  isLoggedIn: Observable<boolean>;
  @Output() showSpouceEntity = new EventEmitter<boolean>();
  public userServiceModal: UserServiceModal;
  public userServiceSubject: BehaviorSubject<UserServiceModal>;
  public userServiceSubscriber: Subscriber<UserServiceModal>;
  constructor(
    public activatedRoute: ActivatedRoute,
    public apiService: RestApiService,
    public utilityService: UtilityService,
    public cookieService: CookieService
  ) {

    this.userServiceModal = new UserServiceModal();
    this.isLoggedIn = this.apiService.isLoggedIn();
    this.isLoggedIn.subscribe((data) => {
    });

  }
  public service: any = {};

  /** * init * @method  init */
  public init(): void {
    if (window.localStorage && window.localStorage.getItem('userService')) {
      this.service = JSON.parse(window.localStorage.getItem('userService')) as UserServiceModal;
      if (window.location.search.length > 0) {
      this.activatedRoute.queryParamMap
      .pipe(skip(1))
      .subscribe((params: ParamMap) => {     
        this.userServiceModal.customerTypeID = parseInt(params.get('type'), 10) || this.userServiceModal.customerTypeID || this.service.customerTypeID || 2;
        this.userServiceModal.selectedCountry = params.get('countrycode') || (sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry')) || this.service.selectedCountry || "US";
        this.userServiceModal.selectedLanguageID = params.get('language') || this.service.selectedLanguageID || 0;
        this.userServiceModal.referralURL = params.get('ref');
        this.userServiceModal.legName = params.get('legName');
        this.userServiceModal.enrollerId = Number(params.get('enrollerId') || this.service.enrollerId) || 0;
        this.userServiceModal.sponsorId = Number(params.get('sponsorId') || this.service.sponsorId) || 0;
        this.userServiceModal.autologin = this.service.autologin || params.get('autologin') || false;

      });
      } else {
        this.userServiceModal.customerTypeID = this.service.customerTypeID || 2;
        this.userServiceModal.selectedCountry = (sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry')) || this.service.selectedCountry || "US";
        this.userServiceModal.selectedLanguageID = this.service.selectedLanguageID || 0;
        this.userServiceModal.referralURL = '';
        this.userServiceModal.legName = '';
        this.userServiceModal.enrollerId = this.service.enrollerId || 0;
        this.userServiceModal.sponsorId = this.service.sponsorId || 0;
        this.userServiceModal.autologin = this.service.autologin || false;
      }
      this.userServiceModal.enrollerInfo = this.service.enrollerInfo || {};
      this.userServiceModal.guestUserLogin = this.service.guestUserLogin || false;
      this.userServiceModal.customerData = this.service.customerData || {};
      this.userServiceModal.guestUserData = this.service.guestUserData || {};
      this.userServiceModal.shippingAddress = this.service.shippingAddress || {} as ShippingAddress;
      this.userServiceModal.mailingAddress = this.service.mailingAddress || {};
      this.userServiceModal.newshippingAddress = this.service.newshippingAddress || {};
      this.userServiceModal.personalInfo = this.service.personalInfo || {} as PersonalInfo;
      this.userServiceModal.personalInfo.birthDay = this.service.personalInfo.birthDay || '01';
      this.userServiceModal.personalInfo.birthMonth = this.service.personalInfo.birthMonth || '01';
      this.userServiceModal.personalInfo.birthYear = this.service.personalInfo.birthYear || (moment().year() - 18).toString();
      this.userServiceModal.personalInfo.phoneNumber = this.service.personalInfo.phoneNumber || '';
      this.userServiceModal.personalInfo.PartnerFirstName=this.service.personalInfo.PartnerFirstName || '';
      this.userServiceModal.personalInfo.PartnerLastName=this.service.personalInfo.PartnerLastName || '';
      this.userServiceModal.personalInfo.PartnerEmail=this.service.personalInfo.PartnerEmail || '';
      this.userServiceModal.personalInfo.PartnerPhone=this.service.personalInfo.PartnerPhone || '';
      this.userServiceModal.personalInfo.showbusinessEntity =this.service.personalInfo.showbusinessEntity || false;
      this.userServiceModal.personalInfo.showPartnerEntity =this.service.personalInfo.showPartnerEntity ||  false;
      this.userServiceModal.personalInfo.Company =this.service.personalInfo.Company ||  '';
      this.userServiceModal.webOffice = this.service.webOffice || {};
      this.userServiceModal.couponInfo = this.service.couponInfo || {};
      this.userServiceModal.promotionOffer = this.service.promotionOffer || false;
      this.userServiceModal.IsshowPromotionOption = this.service.IsshowPromotionOption || false;
      this.userServiceModal.addressCheck = this.service.addressCheck || false;
      this.userServiceModal.DynamicCouponCode = this.service.DynamicCouponCode || {};
      this.userServiceModal.couponInfo.IsAppliedcode = this.service.couponInfo.IsAppliedcode || false;
      this.userServiceModal.paymentMethods = this.service.paymentMethods || [];

      this.userServiceModal.restrictedStates = this.service.restrictedStates || [];
      this.userServiceModal.restrictedShipStates = this.service.restrictedShipStates || '';
      this.userServiceModal.selectedShippingMethod = this.service.selectedShippingMethod;
      this.userServiceModal.shipMethods = this.service.shipMethods || [];
      this.userServiceModal.autoshipCardDetail = this.service.autoshipCardDetail || {};
      this.userServiceModal.cardDetail = this.service.cardDetail || {};
      this.userServiceModal.commissionPayment = this.service.commissionPayment || '';
      this.userServiceModal.licenseNumber = this.service.licenseNumber || '';

      this.userServiceModal.doNotWishAutoship = this.service.doNotWishAutoship || false;
      this.userServiceModal.useSamePaymentForAutoship = this.service.useSamePaymentForAutoship || false;
      this.userServiceModal.selectedfrequencyTypeID = this.service.selectedfrequencyTypeID || 1;
      this.userServiceModal.selectedfrequency = this.service.selectedfrequency || 'Monthly';
      this.userServiceModal.checkItems = this.service.checkItems || false;
      this.userServiceModal.regionMainState = this.service.regionMainState || '';
      this.userServiceModal.newUser = this.service.newUser || false;
      this.userServiceModal.TotalSteps = 6;
      this.userServiceModal.noOfStepsVerified = this.service.noOfStepsVerified || 0;
      this.userServiceModal.couponInfo.availableRewards = this.service.couponInfo.availableRewards || [];
      // this.userServiceModal.selectedLanguageID = this.activatedRoute.snapshot.queryParams['LanguageCode || this.service.selectedLanguageID || 'en';
      // this.userServiceModal.selectedCountry = this.userServiceModal.selectedCountry || this.activatedRoute.snapshot.queryParams['countrycode || 'US';
      this.userServiceModal.WebAlias = this.service.WebAlias || 'rainShop';
      this.userServiceModal.isReferredBy = this.service.isReferredBy || false;
      this.userServiceModal.referralInfo = this.service.referralInfo || {} as ReferralInfo;
      this.userServiceModal.defaultState = this.service.defaultState || 'UT';
      this.service.autoshipDate && (this.userServiceModal.autoshipDate = this.service.autoshipDate);
    } else {
      this.setDefault();
    }
  }

  /*** clear Data of application * @method  clearData ***/
  public clearData() {
    window.localStorage.removeItem('userService');
    this.service = {};
    this.init();
  }

  public clearCustomerData() {
    this.userServiceModal.customerData = {};
  }

  public setEnrollerInfo(websiteinfo, verified) {

    // this.userServiceModal.enrollerInfo = websiteinfo;
    
    const isLoggedIn = this.cookieService.check('X-Auth');
    if(!isLoggedIn) {
      this.userServiceModal.enrollerInfo = websiteinfo;
    }

    this.userServiceModal.WebAlias = websiteinfo.WebAlias;
    if (window.localStorage) {
      window.localStorage.setItem('userService', JSON.stringify(this.userServiceModal));
    }
    if(verified){
      const baseurl = getBaseLocation();
      location.href = (location.href.replace(baseurl, ('/' + (websiteinfo.WebAlias || 'rainShop'))));
    }
    
  }
  /** * Set Default * @method  setDefault */
  public setDefault() {
    if(window.location.search.length > 0){
    this.activatedRoute.queryParamMap
    .pipe(skip(1))
    .subscribe((params: ParamMap) => {     
      this.userServiceModal.customerTypeID = parseInt(params.get('type'), 10) || this.userServiceModal.customerTypeID || this.service.customerTypeID || 2;
      this.userServiceModal.referralURL = params.get('ref') || this.service.referralURL;
      this.userServiceModal.legName = params.get('legName') || this.service.legName;
      this.userServiceModal.selectedCountry = params.get('countrycode') || (sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry')) || this.service.selectedCountry || "US";
      this.userServiceModal.selectedLanguageID = params.get('language') || this.service.selectedLanguageID || 0;
      this.userServiceModal.enrollerId = Number(params.get('enrollerId') || this.service.enrollerId) || 0;
      this.userServiceModal.sponsorId = Number(params.get('sponsorId') || this.service.sponsorId) || 0;
      this.userServiceModal.autologin = this.service.autologin || params.get('autologin') || false;

    });
    }else{
        this.userServiceModal.customerTypeID =this.service.customerTypeID || 2;
        this.userServiceModal.referralURL = this.service.referralURL;
        this.userServiceModal.legName =  this.service.legName;
        this.userServiceModal.selectedCountry = (sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry')) || this.service.selectedCountry || "US";
        this.userServiceModal.selectedLanguageID = this.service.selectedLanguageID || 0;
        this.userServiceModal.enrollerId =this.service.enrollerId || 0;
        this.userServiceModal.sponsorId =  this.service.sponsorId || 0;
        this.userServiceModal.autologin = this.service.autologin || false;
    }
    this.userServiceModal.enrollerInfo = {};
    this.userServiceModal.guestUserLogin = false;
    this.userServiceModal.shippingAddress = {} as ShippingAddress;
    this.userServiceModal.mailingAddress = {};
    this.userServiceModal.newshippingAddress = {};
    this.userServiceModal.personalInfo = {
      birthDay: 1,
      birthMonth: 1,
      birthYear: Number((moment().year() - 18).toString()),
      phoneNumber: '',
      Email: '',
      SendMail: false,
      PartnerFirstName: '',
      PartnerLastName: '',
      PartnerEmail: '',
      PartnerPhone: '',
      showbusinessEntity: false,
      showPartnerEntity: false,
      Company:''
    };
    this.userServiceModal.webOffice = {} as WebOffice;
    this.userServiceModal.paymentMethods = [];
    this.userServiceModal.autoshipCardDetail = {};
    this.userServiceModal.cardDetail = {
      CardExpirationYear: new Date().getFullYear(),
      CardExpirationMonth: (new Date().getMonth() + 1),
      CardNumber: '',
      CardName: '',
      CardCvv: '',
      Token: '',
      fruadGuid: '',
      lastFour: '',
      firstSix: ''
    };
    this.userServiceModal.commissionPayment = '';
    this.userServiceModal.licenseNumber = '';
    this.userServiceModal.doNotWishAutoship = false;
    this.userServiceModal.useSamePaymentForAutoship = true;
    this.userServiceModal.selectedfrequencyTypeID = 1;
    this.userServiceModal.selectedfrequency = 'Monthly';
    this.userServiceModal.defaultState = '';
    this.userServiceModal.promotionOffer = false;
    this.userServiceModal.IsshowPromotionOption = true;
    this.userServiceModal.addressCheck = false;
    this.userServiceModal.couponInfo = {
      IsAppliedcode: false,
      promoCodeValid: undefined,
      promoCode: '',
      Allcoupons: [],
      RewardsForUse: [],
      FreeShipping: {
        activated: false,
        coupon: undefined
      },
      availableRewards: [],
      OrderAllowCoupons: []
    };
    this.userServiceModal.autoshipDate = moment().add(1, 'months').toDate();
    this.userServiceModal.customerData = {};
    this.userServiceModal.guestUserData = {};
    this.userServiceModal.restrictedShipStates = '';
    this.userServiceModal.restrictedStates = [];
    this.userServiceModal.selectedShippingMethod = 0;
    this.userServiceModal.shipMethods = [];
    this.userServiceModal.checkItems = false;
    this.userServiceModal.regionMainState = '';
    this.userServiceModal.newUser = false;
    this.userServiceModal.TotalSteps = 6;
    this.userServiceModal.noOfStepsVerified = 0;
    // this.userServiceModal.selectedLanguageID = this.activatedRoute.snapshot.queryParams['LanguageCode || 'en';
    // this.userServiceModal.selectedCountry = this.userServiceModal.selectedCountry || 'us';
    this.userServiceModal.WebAlias = 'rainShop';
    if (window.localStorage) {
      window.localStorage.setItem('userService', JSON.stringify(this.userServiceModal));
    }
  }

  /**
   * set user shipping address
   * @method  setShippingAddress
   */
  public setShippingAddress() {
    this.userServiceModal.shippingAddress = this.userServiceModal.shippingAddress || cloneDeep(this.userServiceModal.customerData.DefaultShippingAddress);
    this.userServiceModal.shippingAddress.FullName = (this.userServiceModal.shippingAddress.FirstName || this.userServiceModal.customerData.FirstName) + (this.userServiceModal.shippingAddress.LastName || this.userServiceModal.customerData.LastName);
    this.userServiceModal.shippingAddress.FirstName = this.userServiceModal.shippingAddress.FirstName || this.userServiceModal.customerData.FirstName;
    this.userServiceModal.shippingAddress.LastName = this.userServiceModal.shippingAddress.LastName || this.userServiceModal.customerData.LastName;
    this.userServiceModal.shippingAddress.Street1 = this.userServiceModal.shippingAddress.Street1 || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.Street1 : '');
    this.userServiceModal.shippingAddress.Street2 = this.userServiceModal.shippingAddress.Street2 || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.Street2 : '');
    this.userServiceModal.shippingAddress.Street3 = this.userServiceModal.shippingAddress.Street3 || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.Street3 : '');
    this.userServiceModal.shippingAddress.StateName = this.userServiceModal.shippingAddress.StateName || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? (this.userServiceModal.customerData.DefaultShippingAddress.StateName || this.userServiceModal.customerData.DefaultShippingAddress.Region) : '');
    this.userServiceModal.shippingAddress.PostalCode = this.userServiceModal.shippingAddress.PostalCode || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.PostalCode : '');
    this.userServiceModal.shippingAddress.CountryCode = this.userServiceModal.shippingAddress.CountryCode || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.CountryCode : '');
    this.userServiceModal.shippingAddress.City = this.userServiceModal.shippingAddress.City || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.City : '');
    this.userServiceModal.shippingAddress.Phone = this.userServiceModal.shippingAddress.Phone || (!this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ? this.userServiceModal.customerData.DefaultShippingAddress.Phone : '');
  }

  // Check if User is Authenticated Or not
  public checkIfUserAuthenticatedOrNot(): boolean {
    return this.cookieService.check('X-Auth');
  }

  getDateAfterOneMonth(todayDate: any){
    let nextDate: Date;
    const currentDate = new Date(todayDate);
    const DatesToSkipped = ["28", "29", "30", "31"];

    if(DatesToSkipped.indexOf(currentDate.getDate().toString()) > -1){
      nextDate = new Date(moment(currentDate).add(1, 'month').date(27).format('YYYY-MM-DD'));
    } else {
      nextDate = new Date(moment(currentDate).add(1, 'month').format('YYYY-MM-DD'));
    }
    return nextDate;
  }

}

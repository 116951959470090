import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { CardDetail, UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { ProductService } from 'src/app/core/services/product.service';
import { environment } from 'src/environments/environment';
import { OrderService } from 'src/app/core/services/order.service';
import { AutoshipConfigurationService } from 'src/app/core/services/autoshipConfiguration.service';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { ConfigService } from 'src/app/core/services/config.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { PaymentService } from 'src/app/core/services/payment.service';
import { AccountService } from 'src/app/core/authentication/account.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditAddressComponent } from '../model/edit-address/edit-address.component';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { PersistentService } from 'src/app/core/services/persistent.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ShoppingCartService } from 'src/app/core/services/shopping-cart.service';
import { CartItem } from 'src/app/core/modals/cart-item';
import { Observable, of } from 'rxjs';
import { CreateOrderAndAutoShip, OrderShippingAddress } from 'src/app/core/modals/createorderautoorder.modal';
import { CartService } from 'src/app/core/services/cart.service';
import { ConfirmDialogComponent } from '../model/confirm-dialog/confirm-dialog.component';
import { OrderCreditCard, Payment } from 'src/app/core/modals/enrollment.model';
import { AddSymbolWithNumberPipe } from 'src/app/core/pipes/add-symbol-with-number.pipe';
import { cloneDeep } from 'lodash';
import { SharedService } from 'src/app/core/services/shared.service';
import { PaymentComponent } from '../model/payment/payment.component';
import * as $ from 'jquery';
import { RainCashWalletDialogComponent } from '../model/rain-order-dialog/rain-cash-wallet-dialog.component';

declare var kount: any;
declare var r: any;
declare var ka: any;

@Component({
  selector: 'app-revieworder',
  templateUrl: './revieworder.component.html',
  styleUrls: ['./revieworder.component.scss']
})
export class RevieworderComponent implements OnInit {

  userService: UserServiceModal
  customerDetail: any;
  public env: any;
  isStateRestrict: boolean = false;
  public tokenizer: any = {};
  public tokenEx3DSResponse: any = {};
  _marketConfigurations: any = {};

  public cartItems: Observable<CartItem[]> = of([]);
  public buyProducts: CartItem[] = [];
  public commonData: any;
  removable = true;
  amount: number;
  payments: string[] = ['Create an Account?', 'Flat Rate'];
  paymantWay: string[] = ['Direct Bank Transfer', 'PayPal'];
  OrderQuantityCount: number = 10;
  public AutoOrdersData: any[] = [];
  selectedAutoship: any;
  AutoshipEditFlag: boolean = false;
  createOrderRequest: CreateOrderAndAutoShip;
  selectedshipmethod: any = 1;
  isDisabledCreateOrder: boolean = false;
  isFromPaymentDialog: boolean = false;
  selectedCardType: string = 'New';
  selectedCountry: any = sessionStorage.getItem('selectedCountry') ?? 'US';
  UseRainWalletToPayRemainder: boolean = false;
  UseRainCashToPayRemainder: boolean = false;

  cardDetail : CardDetail = {
    CardName: '',
    CardNumber: '',
    CardExpirationMonth: null,
    CardExpirationYear: null,
    CardCvv: '',
    Token: '',
    fruadGuid: '',
    lastFour: '',
    firstSix: ''
  };
  RainPartnerCheckbox:boolean =  false;
  RainWalletCheckbox: boolean = false;
  constructor(
    private router: Router,
    public user: UserService,
    public itemsService: ProductService,
    public orderService: OrderService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    private apiService: RestApiService,
    public configService: ConfigService,
    public translate: TranslateService,
    public titleService: Title,
    public paymentService: PaymentService,
    private accountService: AccountService,
    private notificationService: NotificationService,
    public utilityService: UtilityService,
    public dialog: MatDialog,
    public itemsListService: ItemsListService,
    public persistentService: PersistentService,
    public companyService: CompanyService,
    public shoppingCartService: ShoppingCartService,
    public cartService: CartService,
    public sharedService: SharedService
  ) {
    this.userService = user.userServiceModal;
    this.env = environment;
    this.commonData = this.configService.getConfig();
    this.sharedService.getReviewCheckout().subscribe(() => {
      if (document.getElementById('reviewPayButton')) {
        document.getElementById('reviewPayButton').click();
      }
    });
    
  }

  ngOnInit(): void {

    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Review Order') + ' - ' + text);
    });
    if (window.localStorage) {
      this.customerDetail = JSON.parse(window.localStorage.getItem('customerDetail')) || {};
      if (Object.keys(this.customerDetail).length > 0) {
      }
    }

    // fetch market configuration
    this.getMarketConfig();
    
    this.orderService.resetPointsTriggered.subscribe((res) => {
      if(res) {
        this.PaymentPointAccounts.creditInput = 0;
        this.PaymentPointAccounts.walletInput = 0;
      }
    })
    
    if (this.itemsService.selectedOrderItems.length > 0 || this.itemsService.selectedPacks.length > 0) {
      this.orderService.calculateOrder();
    }
    if (this.itemsService.selectedAutoOrderItems.length > 0)
      this.orderService.calculateAutoOrder();

    // *****************************
    sessionStorage.setItem('IsEnrollment', 'true');
    this.translate.get('global_Company_Title').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('pagetitle_checkout') + ' | ' + text);
    });
    this.cartItems.subscribe(products => this.buyProducts = products);
    // Select Autoship Types
    this.persistentService.retailData.isNewAutoship = this.persistentService.retailData.isNewAutoship || false;

    // Show selected Autoship in dropdown
    this.selectedAutoship = this.persistentService.retailData.CurrentSelectedAutoOrder;

    // check cart not empty
    if ((this.itemsService.selectedPacks.length + this.itemsService.selectedOrderItems.length + this.itemsService.selectedAutoOrderItems.length <= 0) && (!this.utilityService.getAutoshipEditFlag())) {
      this.notificationService.error('message_', 'Please Select Atleast One Item');
      this.cartService.checkoutPaymentButton = false;
      this.router.navigate(['/products']);
    }

    if (this.userService.customerData.DefaultShippingAddress && !this.utilityService.isEmptyObject(this.userService.customerData.DefaultShippingAddress)) {
      this.user.setShippingAddress();
      // Update Shipping Address with Autoship address
      if (this.utilityService.getAutoshipEditFlag()) {
        //this.updateAddressWithAutoshipAddress();
      }
    }

    if (this.userService.isAddressChanged && this.userService.newshippingAddress && this.userService.newshippingAddress.Address && this.userService.newshippingAddress.ZipCode) {
      if (this.utilityService.getAutoshipEditFlag()) {
        if (this.userService.isEditAutoshipAddressChanged) {
          this.userService.newshippingAddress.FullName = this.userService.newshippingAddress.FirstName + ' ' + this.userService.newshippingAddress.LastName;
          this.userService.shippingAddress = cloneDeep(this.userService.newshippingAddress);
        } else {
          //this.updateAddressWithAutoshipAddress();
        }
      } else {
        this.userService.newshippingAddress.FullName = this.userService.newshippingAddress.FirstName + ' ' + this.userService.newshippingAddress.LastName;
        this.userService.shippingAddress = cloneDeep(this.userService.newshippingAddress);
      }
    }

    if (this.utilityService.getAutoshipEditFlag()) {
      if (this.persistentService.retailData.SelectedAutoshipPayment) {
        const autoshipPayment = this.getPaymentByDisplayText(
          this.persistentService.retailData.SelectedAutoshipPayment.PaymentDisplay,
          this.persistentService.retailData.SelectedAutoshipPayment.PaymentTypeID
        );
        this.userService.paymentMethods = autoshipPayment ? [autoshipPayment] : [];
        if (this.userService.paymentMethods.length > 0) {
          this.userService.paymentMethods[0].CardType = this.userService.paymentMethods[0].Name,
            this.userService.paymentMethods[0].Last4 = this.userService.paymentMethods[0].Ending,
            this.userService.paymentMethods[0].ExpireMonth = this.persistentService.retailData.SelectedAutoshipPayment.ExpireMonth,
            this.userService.paymentMethods[0].ExpireYear = this.persistentService.retailData.SelectedAutoshipPayment.ExpireYear,
            this.userService.paymentMethods[0].Token = this.userService.paymentMethods[0].PaymentMethodID,
            this.userService.paymentMethods[0].BillingAddress = this.persistentService.retailData.SelectedAutoshipPayment.BillingAddress,
            this.userService.paymentMethods[0].BillingAddress2 = this.persistentService.retailData.SelectedAutoshipPayment.BillingAddress2,
            this.userService.paymentMethods[0].BillingCity = this.persistentService.retailData.SelectedAutoshipPayment.BillingCity,
            this.userService.paymentMethods[0].BillingCountry = this.persistentService.retailData.SelectedAutoshipPayment.BillingCountry,
            this.userService.paymentMethods[0].BillingState = this.persistentService.retailData.SelectedAutoshipPayment.BillingState,
            this.userService.paymentMethods[0].BillingZip = this.persistentService.retailData.SelectedAutoshipPayment.BillingZip;
        }
      }
      localStorage.setItem('userService', JSON.stringify(this.userService));
    }

    this.paymentService.getPaymentType().then(() => {
      this.userService.paymentMethods = this.userService.paymentMethods || [];
      this.userService.couponInfo.promoCodeValid = undefined;
      this.paymentService.PaymentDataResponse = this.paymentService.PaymentDataResponse || {};
      this.paymentService.SelectedPaymentTypes = null;

      this.paymentService.getCustomerSavedPaymentMethods()
      .then((paymentMethods) => {
        if(paymentMethods.length > 0){
          this.selectedCardType = 'Saved';
          const _primaryCard = paymentMethods[0];
          this.userService.cardDetail.Token = _primaryCard.Token;
          this.userService.cardDetail.CardExpirationMonth = _primaryCard.ExpirationMonth;
          this.userService.cardDetail.CardExpirationYear = _primaryCard.ExpirationYear;
         // this.paymentService.SelectedPaymentTypes = paymentMethods[0].PaymentTypeID;
          this.userService.cardDetail.Type = _primaryCard.Type;
          this.paymentChange();
        }
        console.log('paymentMethods', paymentMethods);
      })
      .catch();
    });

    this.userService.isPaymentChanged = false;
    
  }

  paymentChange() {
    setTimeout(() => {
      this.loadKount();
    }, 1000);
  }

  getMarketConfig(){
    const countryCode = sessionStorage.getItem('selectedCountry') || this.configService.commonData.selectedCountry || 'us';
    this.apiService.getMarketConfig(countryCode).subscribe({
      next: (response) => {
        this._marketConfigurations = response?.Data;
      },
      error: (error) => {
        this._marketConfigurations = JSON.parse(sessionStorage.getItem('GetMarketConfig_'+countryCode) || '{}') ;
      },
      complete: () => { }
    })
  }

  selectPaymentMethod(payment: any, type: any) {
    this.selectedCardType = type;
    if (type === 'Saved' && (payment?.Token || payment?.CreditCardToken)){
      this.userService.cardDetail.Type = payment?.Type ?? ''
      this.userService.cardDetail.CardName =  payment?.NameOnCard ?? '';
      this.userService.cardDetail.CardNumber =  payment?.CardNumber ?? '';
      this.userService.cardDetail.CardExpirationMonth =  payment.ExpirationMonth ?? '';
      this.userService.cardDetail.CardExpirationYear =  payment.ExpirationYear ?? '';
      this.userService.cardDetail.CardCvv = payment?.CVV ?? '';
      this.userService.cardDetail.Token =  payment.Token ?? payment.CreditCardToken ?? '';
      this.userService.cardDetail.firstSix = payment.firstSix ?? '';
      this.userService.cardDetail.lastFour = payment.lastFour ?? ''
      this.paymentChange();
    }
    else if (type == 'New' && (payment?.PaymentTypeID == 1 || payment?.CreditCardType == 1)) {
      let dialogRef = this.dialog.open(PaymentComponent);
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.isFromPaymentDialog = true;
          this.paymentService.SelectedPaymentTypes = payment.PaymentTypeID;
        } else if(res == false) {
          this.paymentService.SelectedPaymentTypes = null;
        }
      })
    }
    else if(type == 'New' && (payment?.PaymentTypeID == 1003 || payment?.PaymentTypeID == 1001)){
      this.paymentChange();
      this.paymentService.SelectedPaymentTypes = payment.PaymentTypeID;
    }
    else { // Not for Credit cards
      this.paymentChange();
      this.paymentService.SelectedPaymentTypes = payment.PaymentTypeID;
    }
  }
  editAddress() {
    let dialogRef = this.dialog.open(EditAddressComponent, { data: '' })
    dialogRef.afterClosed().subscribe(response => {

    });
  }
  updateShipmethod(shipmethodID) {
    this.userService.selectedShippingMethod = shipmethodID;
    if (this.itemsService.selectedOrderItems.length > 0) {
      this.orderService.calculateOrder();
    }
    if (this.itemsService.selectedAutoOrderItems.length) {
      this.orderService.calculateAutoOrder();
    }
  }
  
  getItems(type: string) {
    return this.itemsService[type == 'pack' ? 'selectedPacks' : (type == 'autoship' ? 'selectedAutoOrderItems' : 'selectedOrderItems')].filter(item => item.ItemCode != 'DiscountCode');
  }
  
  getGroupItemPrice(item) {
    if (item != null && item.GroupMembers?.length > 0) {
      let filterItemPrice=0;
      item.GroupMembers.find((f: any) => {
        if (f.Item.ItemCode == item.ItemCode) {
          filterItemPrice= (f.Item['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
        }
      });
      return filterItemPrice;
    } else {
      return (item['PriceType' + this.userService.customerTypeID + 'Value'] * item.Quantity);
    }
  }


  InitiateCheckout(){
    if (window.sessionStorage) {
      window.sessionStorage.setItem('SelectedPaymentTypes', JSON.stringify(this.paymentService.SelectedPaymentTypes));
    }
    if(this.orderService?.pointsPaymentCheckout?.remainderAmount > 0 || this.itemsService.selectedAutoOrderItems.length > 0){
      if(this._marketConfigurations?.ShouldAttemptTokenEx3DS && this.paymentService.SelectedPaymentTypes == 1){
        this.perform3DS();
      } else {
        this.submitCheckout();
      }

    } else {
      this.submitCheckout();
    }

  }


  perform3DS(){

    const tokenEx3DS_Object = {

      //configs
      successful3dsIsRequired: this._marketConfigurations?.Successful3dsIsRequired,
      marketDoesNotRequire3DS_CompleteChallenge: this._marketConfigurations?.MarketDoesNotRequire3DS_CompleteChallenge,
      performAuthOnly: this._marketConfigurations?.PerformAuthOnly,
      enableConsoleLogging: true,
      challengeTimeoutTime: 1200000,
      otherField_TokenEx3DSIdentifier: 1,
      otherField_TokenEx3DSInfo: 2,
      otherField_StoredCredentialInfo: 3,

      //general info
      moneyInSDK_CreditCardType:  this.selectedCardType === 'Saved' ? (this.userService.cardDetail?.Type == 1 ? 1 : 2) : 0,
      willBeSavingCardOnFileAtEndOfFlow: this.selectedCardType === 'Saved' ? false : true,
      moneyInSDK_WebsiteID: 4,
      moneyInSDK_WebPageID: 1, //Shopping
      challengeWindowSize: window.innerWidth <= 500 ? 1 : (window.innerWidth > 500 && window.innerWidth <= 800) ? 2 : 4,
      currentMarketCountryCode: this.userService.shippingAddress.CountryCode,
      customerID: this.userService.customerData?.CustomerID,
      orderID: 0,
      warehouseID: this._marketConfigurations?.Configuration?.Orders?.WarehouseID || 0,
      amount: this.orderService.pointsPaymentCheckout.remainderAmount,
      currencyCode: this._marketConfigurations?.Configuration?.Orders?.CurrencyCode || 'usd',

      //URLs
      tokenEx3DS_ActionsEndpoint: environment.apiUrl + "App/PerformActionsTokenEx3DS",
      deviceFingerprintingWebhook_Endpoint: environment.apiUrl + "App/DeviceFingerprintingWebhookTokenEx3DS",
      challengeResponseWebhook_Endpoint: environment.apiUrl + "App/ChallengeWebhookTokenEx3DS",

      //path information
      currentPageURL: window.location.href,
      clientsPublicSiteURL: window.location.origin,

      //card information
      token: this.userService.cardDetail.CardNumber,
      cardSecurityCode: this.userService.cardDetail.CardCvv,
      expirationMonth: this.userService.cardDetail.CardExpirationMonth,
      expirationYear: this.userService.cardDetail.CardExpirationYear,
      billingName:  this.userService.billingAddress.FirstName ?? this.userService.cardDetail?.CardName ? this.userService.cardDetail.CardName : (this.userService.shippingAddress.FirstName + ' ' + this.userService.shippingAddress.LastName),
      billingAddress1: this.userService.billingAddress.Street1 ?? this.userService.shippingAddress.Street1,
      billingAddress2:this.userService.billingAddress.Street2 ?? this.userService.shippingAddress.Street2,
      billingCity:this.userService.billingAddress.City ?? this.userService.shippingAddress.City,
      billingState: this.userService.billingAddress.StateName ?? this.userService.shippingAddress.StateName,
      billingZip: this.userService.billingAddress.PostalCode ?? this.userService.shippingAddress.PostalCode,
      billingCountry: this.userService.billingAddress.CountryCode ?? this.userService.shippingAddress.CountryCode,

      //shipping address information
      shippingAddress1: this.userService.shippingAddress.Street1,
      shippingAddress2: this.userService.shippingAddress.Street2,
      shippingAddressCity: this.userService.shippingAddress.City,
      shippingAddressState: this.userService.shippingAddress.StateName,
      shippingAddressZip: this.userService.shippingAddress.PostalCode,
      shippingAddressCountry: this.userService.shippingAddress.CountryCode,
      emailAddress: this.userService.shippingAddress.Email,
      phoneNumber: this.userService.shippingAddress.Phone,

    }

    console.log("tokenEx3DS_Object", tokenEx3DS_Object)

    this.orderService.waitForTokenExIframeElement('ExigoTokenEx3DS_Challenge', () => {
      $('.progress-loader').attr('hidden', true); // hide loader
    })

    $('.progress-loader').removeAttr('hidden'); // show loader
    //@ts-ignore
    tokenEx3DS.perform3DS({
      data: tokenEx3DS_Object,
      success: (data: any) => {

        this.tokenEx3DSResponse['allowed'] = true;
        this.tokenEx3DSResponse['data'] = {
          "Identifier" : data?.tokenEx3DS_Identifier,
          "Information" : data?.tokenEx3DS_Information
        }
        $('.progress-loader').attr('hidden', true); // hide loader
        this.submitCheckout();

        console.log("tokenEx3DSResponse perform3DS", data)
      },
      error: (data: any) => {

        if(data.includes("DetermineIfChallengeIsNecessary") || data?.toString()?.toLowerCase().includes("session expired")){
          this.translate.get("TokenEx3DSErrorMessage").subscribe(() => {
            this.notificationService.error("Error", this.translate.instant("TokenEx3DSErrorMessage"))
          });
        } else {
          this.notificationService.error("Error", data)
        }

        $('.progress-loader').attr('hidden', true); // hide loader
        console.log("tokenEx3DSResponse perform3DS", data)
        return;
      }
    });
  }


  submitCheckout() {

    if((!this.userService.shippingAddress.Region && !this.userService.shippingAddress.StateName) || (!this.userService.shippingAddress.PostalCode)) {
      this.notificationService.error("error", "Please fill correct address");
      return;
    }
    
    let request = {
      "Placement": 0,
      "PaymentMethodID": this.paymentService.SelectedPaymentTypes || 0,
      "Customer": {
        "CustomerID": this.userService.customerData.CustomerID,
        "MailingAddress" : {
          "Country": this.userService.shippingAddress.CountryCode
        }
      },
      "NewOrderID": 0,
      "ShippingAddress": {
        "AddressType": "New",
        "Address1": this.userService.shippingAddress.Street1,
        "Address2": this.userService.shippingAddress.Street2,
        "Address3": this.userService.shippingAddress.Street3,
        "City": this.userService.shippingAddress.City,
        "State": this.userService.shippingAddress.StateName || this.userService.shippingAddress.Region,
        "Zip": this.userService.shippingAddress.PostalCode,
        "Country": this.userService.shippingAddress.CountryCode,
        "County": this.userService.shippingAddress.CountryCode,
        "AllowCountrySelection": true,
        "USValidated": true,
        "FirstName": this.userService.shippingAddress.FirstName,
        "MiddleName": "",
        "LastName": this.userService.shippingAddress.LastName,
        "Company": "",
        // "Email": this.userService.shippingAddress.Email,
        "Phone": this.userService.shippingAddress.Phone,
        "Notes": ""
      },
      "ShipMethodID": this.userService.selectedShippingMethod,
      "AutoOrderPaymentMethodSameASOrderPaymentMethod": true,
      "BillingAddressSameAsShipping": this.userService.isAddressChanged,
      //"NewCustomerLoginName": this.userService.webOffice.UserName,
      //"NewCustomerPassword": this.userService.webOffice.Password,
      // "HasEnrollmentItemInCart": true,
      // "IsSubmitting": true,
     // "OrderException": "",
      //"SelectedDistributor": "",
      //"PartyID": 0,
     // "GuestID": 0,
      //"UsingProductCredits": true,
      //"ProductCredits": 0,
      "AlternativePaymentAmount": 0,
      // "SelectedShipMethod": {
      //   "ShipMethodID": 0,
      //   "ShipMethodDescription": "",
      //   "Price": 0,
      //   "Selected": true,
      //   "PriceString": "string"
      // },
      //"SelectedMarketName": "UnitedStates",
      // "WorldPaySessionID": "string",
      // "WorldPayMessageForSubmit": "string",
      // "WorldpaySelectedMarket": {
      //   "Name": "UnitedStates",
      //   "Description": "string",
      //   "CookieValue": "string",
      //   "CultureCode": "string",
      //   "IsDefault": true,
      //   "Countries": [
      //     "string"
      //   ],
      //   "Region": "",
      //   "RPTPointAccountID": 0,
      //   "RainWalletPointAccountID": 0,
      //   "CVVRequired": true,
      //   "HasBackofficeUpgrade": true,
      //   "HasDirectDeposit": true,
      //   "CanCreateOrEditDirectDeposit": true,
      //   "UseCODSubscription": true,
      //   "RequireValidationEmail": true,
      //   "UseWorldpay3DSValidation": true,
      //   "UsePayoneerForPayout": true,
      //   "UseRainWalletEURExchangeRate": true,
      //   "EnforceEuroForSimplePay": true
      // },
      "UpgradedCustomerID": 0,
      "IsOnAlternativePayment": true,
      "AddCustomerContact": false
    };

    if(this.orderService?.pointsPaymentCheckout?.remainderAmount > 0 || this.itemsService.selectedAutoOrderItems.length > 0) {
      if (this.paymentService.SelectedPaymentTypes == 1) {
        let CreditCard = {
          "CreditCard": {
            "CreditCardType": this.selectedCardType === 'Saved' ? (this.userService.cardDetail?.Type == 1 ? 'Primary' : 'Secondary')  : 'New',
            "ShippingAndBilingAddressAreTheSame": this.userService.isAddressChanged,
            "Type": this.selectedCardType === 'Saved' ? (this.userService.cardDetail?.Type == 1 ? 'Primary' : 'Secondary')  : 'New',
            "NameOnCard": this.userService.cardDetail.CardName,
            "CardNumber": this.userService.cardDetail.CardNumber,
            "ExpirationMonth": this.userService.cardDetail.CardExpirationMonth,
            "ExpirationYear": this.userService.cardDetail.CardExpirationYear,
            "Token":  this.userService.cardDetail.Token,
            "Display": "1111",
            "CVV": this.userService.cardDetail.CardCvv,
            "FirstSix": this.userService.cardDetail.firstSix,
            "LastFour": this.userService.cardDetail.lastFour,
            "BillingAddress": {
              "AddressType": "New",
              "Address1": this.userService.isAddressChanged ? this.userService.shippingAddress.Street1 : this.userService.billingAddress.Street1,
              "Address2": this.userService.isAddressChanged ? this.userService.shippingAddress.Street2 : this.userService.billingAddress.Street2,
              "Address3": this.userService.isAddressChanged ? this.userService.shippingAddress.Street3 : this.userService.billingAddress.Street3,
              "City": this.userService.isAddressChanged ? this.userService.shippingAddress.City : (this.userService.billingAddress.City || this.userService.shippingAddress.City),
              "State": this.userService.isAddressChanged ? this.userService.shippingAddress.StateName : (this.userService.billingAddress.StateName || this.userService.shippingAddress.StateName || this.userService.shippingAddress.Region),
              "Zip":this.userService.isAddressChanged ? this.userService.shippingAddress.PostalCode : (this.userService.billingAddress.PostalCode || this.userService.shippingAddress.PostalCode),
              "Country": this.userService.isAddressChanged ? this.userService.shippingAddress.CountryCode : (this.userService.billingAddress.CountryCode || this.userService.shippingAddress.CountryCode),
              "County": this.userService.isAddressChanged ? this.userService.shippingAddress.CountryCode : (this.userService.billingAddress.CountryCode || this.userService.shippingAddress.CountryCode),
            }
          },
          "FraudGuid": this.userService.cardDetail?.fruadGuid || $("#FraudGuid").val() || ""
        };
  
        if(this.tokenEx3DSResponse?.allowed){
          request["tokenEx3DS_Identifier"] = this.tokenEx3DSResponse?.data["Identifier"];
          request["tokenEx3DS_Information"] = this.tokenEx3DSResponse?.data["Information"];
          request["tokenEx3DS_Storedinfo"] = JSON.stringify({
            "MoneyInSDK_CreditCardType": this.selectedCardType === 'Saved' ? (this.userService.cardDetail?.Type == 1 ? 1 : 2) : 0,
            "WillBeSavingCardOnFile": this.selectedCardType === 'Saved' ? false : true,
            "MoneyInSDK_WebsiteID":4,
            "MoneyInSDK_WebPageID":1
          });
        }
  
        request = Object.assign(request, CreditCard);
      }
      if (this.paymentService.SelectedPaymentTypes == 1003  || this.paymentService.SelectedPaymentTypes == 1001) {
        let FraudGuid = {
          "FraudGuid": this.userService.cardDetail?.fruadGuid || $("#FraudGuid").val() || ""
        }
        request = Object.assign(request, FraudGuid);
  
        if(this.tokenEx3DSResponse?.allowed){
          request["tokenEx3DS_Identifier"] = this.tokenEx3DSResponse?.data["Identifier"];
          request["tokenEx3DS_Information"] = this.tokenEx3DSResponse?.data["Information"];
    request["tokenEx3DS_Storedinfo"] = JSON.stringify({
            "MoneyInSDK_CreditCardType": this.selectedCardType === 'Saved' ? (this.userService.cardDetail?.Type == 1 ? 1 : 2) : 0,
            "WillBeSavingCardOnFile": this.selectedCardType === 'Saved' ? false : true,
            "MoneyInSDK_WebsiteID":4,
            "MoneyInSDK_WebPageID":1
          });
        }
      }
    }

    const getQuantityOfRainCashItems = (itemCode:any) => {
      let Quantity = _.filter(this.orderService.pointsPaymentCheckout.finallyRainCashItems, (item: any) => item.ItemCode == itemCode).length;
      return Quantity;
    }

    const productdetails = [];
    //const kititems = [];
    this.itemsService.selectedOrderItems.forEach((item: any, index) => {
      productdetails.push({
        ItemCode: item.ItemCode,
        Quantity: item.Quantity || 1,
        "Type": "Order",
        ...(getQuantityOfRainCashItems(item.ItemCode) > 0 && { "OtherPrice8" : getQuantityOfRainCashItems(item.ItemCode) })
      });
    });

    this.itemsService.selectedPacks.forEach((item, index) => {
      productdetails.push({
        ItemCode: item.ItemCode,
        Quantity: item.Quantity || 1,
        Type: "Order",
      });
    });
    request["Items"] = [];
    if (productdetails && productdetails.length > 0) {
      request["Items"] = productdetails;
    }
    if (this.itemsService.selectedAutoOrderItems && this.itemsService.selectedAutoOrderItems.length>0) {

      this.itemsService.selectedAutoOrderItems.forEach((item, index) => {
        request["Items"].push({
          ItemCode: item.ItemCode,
          Quantity: item.Quantity || 1,
          Type: "AutoOrder",
        });
      });

      // Setting Autoship Date if selected of past date from today 
      if(new Date(this.userService.autoshipDate) <= new Date()){
        const todayDate = new Date();
        this.userService.autoshipDate = this.user.getDateAfterOneMonth(todayDate);
      }
      
      let autoOrder = {
        "NewAutoOrderID": 0,
        "AutoOrderFrequencyType": "Monthly",
        "AutoOrderStartDate": this.userService.autoshipDate,
        "AutoOrderShippingAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1,
          "Address2": this.userService.shippingAddress.Street2,
          "Address3": this.userService.shippingAddress.Street3,
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName || this.userService.shippingAddress.Region,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County": this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          // "Email": this.userService.shippingAddress.Email,
          // "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "AutoOrderBillingAddress": {
          "AddressType": "New",
          "Address1": this.userService.isAddressChanged ? this.userService.shippingAddress.Street1 : this.userService.billingAddress.Street1,
          "Address2": this.userService.isAddressChanged ? this.userService.shippingAddress.Street2 : this.userService.billingAddress.Street2,
          "Address3": this.userService.isAddressChanged ? this.userService.shippingAddress.Street3 : this.userService.billingAddress.Street3,
          "City": this.userService.isAddressChanged ? this.userService.shippingAddress.City : (this.userService.billingAddress.City || this.userService.shippingAddress.City),
          "State": this.userService.isAddressChanged ? this.userService.shippingAddress.StateName : (this.userService.billingAddress.StateName || this.userService.shippingAddress.StateName || this.userService.shippingAddress.Region),
          "Zip": this.userService.isAddressChanged ? this.userService.shippingAddress.PostalCode : (this.userService.billingAddress.PostalCode || this.userService.shippingAddress.PostalCode),
          "Country": this.userService.isAddressChanged ? this.userService.shippingAddress.CountryCode : (this.userService.billingAddress.CountryCode || this.userService.shippingAddress.CountryCode),
          "County": this.userService.isAddressChanged ? this.userService.shippingAddress.CountryCode : (this.userService.billingAddress.CountryCode || this.userService.shippingAddress.CountryCode),
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          // "Email": this.userService.shippingAddress.Email,
          // "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "AutoOrderBillingSameAsShipping": false,
        "AutoOrderShipMethodID":this.userService.selectedShippingMethod,
      };
      request = Object.assign(request, autoOrder);
    }
    console.log(JSON.stringify(request));

    /**
     *  Points Calculations
    */

    if(this.orderService?.pointsPaymentCheckout?.creditAmountInput > 0 && this.orderService?.pointsPaymentCheckout?.selectedAccounts[0]?.selectionStatus == true) {
      request["UseMyRPTCredits"] = true;
      request["QuantityOfRPTCredits"] = this.orderService?.pointsPaymentCheckout?.creditAmountInput;
    }

    if(this.orderService?.pointsPaymentCheckout?.walletAmountInput > 0 && this.orderService?.pointsPaymentCheckout?.selectedAccounts[1]?.selectionStatus == true) {
      request["UseMyRainWalletAccount"] = true;
      request["RainWalletUsedPoints"] = this.orderService?.pointsPaymentCheckout?.walletAmountInput;
    }

    if(this.orderService?.pointsPaymentCheckout?.cashAmountInput > 0 && this.orderService?.pointsPaymentCheckout?.selectedAccounts[2]?.selectionStatus == true) {
      request["IsUsingRainCash"] = true;
      request["QuantityOfRainCash"] = this.orderService?.pointsPaymentCheckout?.cashAmountInput;
    }

    this.UseRainWalletToPayRemainder && (request["UseRainWalletToPayRemainder"] = true);
    this.UseRainCashToPayRemainder && (request["UseRainCashToPayRemainder"] = true);

    /**
     *
     */

    this.apiService.submitCheckout(request).subscribe({
      next: (result) => {
        if (result.Data && result.Data.token) {
          if (this.paymentService.SelectedPaymentTypes != 1002 && result.Data.IsUsingAnAlternativePaymentMethod) {
            if (window.sessionStorage) {
              sessionStorage.setItem('CustomerToken', result.Data.token);
            }
            window.location.href = `${environment.apiUrl}AlternatePayment/index?token=${result.Data.token}`
          } else {
            this.router.navigateByUrl(`/thank-you?token=${result.Data.token}&enrollment=false`);
          }
        } else if (result.Data?.OrderException) {
          this.notificationService.error('Error', result.Data.OrderException);
        }
        else {
          if (result.Message) {
            this.notificationService.error(
              'Error',
              result.Message
            );
          }
        }
      }, error: () => {

      }, complete: () => {

      }
    });

}

  // ******************************************

  updateAddressWithAutoshipAddress() {
    this.userService.shippingAddress.FirstName = this.persistentService.retailData.AutoOrderShippingAddress.FirstName;
    this.userService.shippingAddress.LastName = this.persistentService.retailData.AutoOrderShippingAddress.LastName;
    this.userService.shippingAddress.Street1 = this.persistentService.retailData.AutoOrderShippingAddress.Address;
    this.userService.shippingAddress.Street2 = this.persistentService.retailData.AutoOrderShippingAddress.Address2;
    this.userService.shippingAddress.City = this.persistentService.retailData.AutoOrderShippingAddress.City;
    this.userService.shippingAddress.CountryCode = this.persistentService.retailData.AutoOrderShippingAddress.Country;
    this.userService.shippingAddress.Region = this.persistentService.retailData.AutoOrderShippingAddress.State;
    this.userService.shippingAddress.StateName = this.persistentService.retailData.AutoOrderShippingAddress.State;
    this.userService.shippingAddress.PostalCode = this.persistentService.retailData.AutoOrderShippingAddress.ZipCode;
  }
  getPaymentByDisplayText(text, paymentmethodid) {
    let matchingPayment = null;
    this.paymentService.PaymentTypeResponse['SaveMethods'].some((method) => {
      try {
        let ending = method.Ending || method.CreditCardNumberDisplay || method.Last4 || method.Name;

        if (ending) {
          ending = ending.replace(/\*/g, '');
        }
        if (paymentmethodid == method.PaymentMethodID) {
          matchingPayment = method;
          return true;
        }
        if (!method.PaymentMethodID && ~text.indexOf(ending)) {
          matchingPayment = method;
          return true;
        }
      } catch (e) {
        console.error('Message', e);
        return true;
      }
    });
    return matchingPayment;
  }

  loadKount() {

    var fraudId = "";
    var client = new ka.ClientSDK();
    client.setupCallback(
      {
        'collect-end':
          function (params) {
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
            // this.fruadGuid = fraudId
            //this.userService.cardDetail.fruadGuid  = fraudId
          },
        'collect-begin':
          function (params) {
            console.log("Collect finished");
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
            // this.fruadGuid = fraudId
            //this.userService.cardDetail.fruadGuid  = fraudId
          }
      });
    client.autoLoadEvents();
  };

  /** ---------------------------------------------------
   * Payment Point Accounts
   --------------------------------------------------- */

  public PaymentPointAccounts = {
    creditInput: this.orderService.pointsPaymentCheckout.creditAmountInput > 0 ? this.orderService.pointsPaymentCheckout.creditAmountInput :  0.0,
    walletInput: this.orderService.pointsPaymentCheckout.walletAmountInput > 0 ? this.orderService.pointsPaymentCheckout.walletAmountInput :  0.0,
    cashInput: this.orderService.pointsPaymentCheckout.cashAmountInput > 0 ? this.orderService.pointsPaymentCheckout.cashAmountInput :  0.0,
    getAccountBalance: (type: string) => {
      if(type == 'credit'){
        let Balance = this.userService?.customerData?.UserCreditAccount?.Balance;
        let AmountInput = this.orderService?.pointsPaymentCheckout.creditAmountInput;
        return (parseFloat(Balance) - parseFloat(AmountInput.toString()));
      }

      if(type == 'wallet'){
        let Balance = this.userService?.customerData?.UserWalletAccount?.Balance;
        let AmountInput = this.orderService?.pointsPaymentCheckout.walletAmountInput;
        return (parseFloat(Balance) - parseFloat(AmountInput.toString()));
      }

      if(type == 'cash-account'){
        let Balance = this.userService?.customerData?.UserCashAccount?.Balance;
        let AmountInput = this.orderService?.pointsPaymentCheckout.cashAmountInput;
        return (parseFloat(Balance) - parseFloat(AmountInput.toString()));
      }
    },
    changeSelection : (type: string, status: any) => {
      this.orderService.pointsPaymentCheckout.selectedAccounts.forEach(acc => (acc.selectionType == type) ? acc.selectionStatus = status : "");
    },
    isSelected: (type: string) => {
      var accountType = this.orderService.pointsPaymentCheckout.selectedAccounts.find(acc => acc.selectionType == type);
      return (accountType && accountType.selectionStatus);
    },
    updatePoints: (type: string, value: any) => {
      let amount = (value && !isNaN(value) && parseFloat(value)) || 0.00;

      if(type == 'credit'){
        // Reseting on Load
        this.orderService.pointsPaymentCheckout.creditAmountInput = 0.00;
        this.orderService.pointsPaymentCheckout.calculateRemainder();
        
        let remainderAmount = this.orderService.pointsPaymentCheckout.remainderAmount;
        let AvailableBalance = this.userService.customerData?.UserCreditAccount?.Balance;
        const cartSubtotal = this.orderService.calculateOrderResponse.SubTotal;

        // IF exceeds Subtotal

        if(amount >= cartSubtotal && cartSubtotal <= AvailableBalance) {
          amount = cartSubtotal;
        } else if (amount >= cartSubtotal && cartSubtotal > AvailableBalance){
          amount = AvailableBalance;
        }

        // IF exceeds Available
        if(amount >= AvailableBalance && cartSubtotal <= AvailableBalance) {
          amount = cartSubtotal;
        } else if (amount >= AvailableBalance && cartSubtotal > AvailableBalance){
          amount = AvailableBalance;
        }
        
        // IF exceeds Remainder
        if((remainderAmount > 0) && amount >= remainderAmount && AvailableBalance <= remainderAmount) {
          amount = AvailableBalance;
        } 
        // else if ((remainderAmount > 0) && amount >= remainderAmount && AvailableBalance > remainderAmount){
        //   amount = remainderAmount;
        // }
        
        this.orderService.pointsPaymentCheckout.creditAmountInput = amount;
        this.PaymentPointAccounts.creditInput = parseFloat(amount.toFixed(2));
      }

      if(type == 'wallet'){
        // Reseting on Load
        this.orderService.pointsPaymentCheckout.walletAmountInput = 0.00;
        this.orderService.pointsPaymentCheckout.calculateRemainder();
        
        let remainderAmount = this.orderService.pointsPaymentCheckout.remainderAmount;
        let AvailableBalance = this.userService.customerData?.UserWalletAccount?.Balance;

        // IF exceeds Available
        if((remainderAmount > 0) && amount >= AvailableBalance && remainderAmount <= AvailableBalance) {
          amount = remainderAmount;
        } else if ((remainderAmount > 0) && amount >= AvailableBalance && remainderAmount > AvailableBalance){
          amount = AvailableBalance;
        }

        // IF exceeds Remainder
        if((remainderAmount > 0) && amount >= remainderAmount && AvailableBalance <= remainderAmount) {
          amount = AvailableBalance;
        } else if ((remainderAmount > 0) && amount >= remainderAmount && AvailableBalance > remainderAmount){
          amount = remainderAmount;
        }

        this.orderService.pointsPaymentCheckout.walletAmountInput = amount;
        this.PaymentPointAccounts.walletInput = parseFloat(amount.toFixed(2));
      }

      this.orderService.pointsPaymentCheckout.calculateRemainder();
    }
  }
  
  handlePointsAccountSelection(selectionType: string, event: any){
    this.PaymentPointAccounts.changeSelection(selectionType, event.checked);
    // Handled Single selection case.
    if(selectionType == "credit" && event.checked == true){
      this.PaymentPointAccounts.changeSelection("cash-account", false);
    }
    if(selectionType == "cash-account" && event.checked == true){
      this.PaymentPointAccounts.changeSelection("credit", false);
    }

    this.orderService.pointsPaymentCheckout.calculateRemainder();

    // If any point account is unchecked, then remove it's all calculations, make it 0.

    var currentStatus = event.checked;
    if(currentStatus == false){
      switch (selectionType) {
        case 'credit':
          this.orderService.pointsPaymentCheckout.creditAmountInput = 0.00;
          this.PaymentPointAccounts.creditInput = 0.00;
          break;
        case 'wallet':
          this.orderService.pointsPaymentCheckout.walletAmountInput = 0.00;
          this.PaymentPointAccounts.walletInput = 0.00;
          break;
        case 'cash-account':
          this.orderService.pointsPaymentCheckout.cashAmountInput = 0.00;
          this.PaymentPointAccounts.cashInput = 0.00;
          break;
        default:
          break;
      }
    }

    this.orderService.pointsPaymentCheckout.calculateRemainder();
  }

  handleRainCash() {
    let dialogRef = this.dialog.open(RainCashWalletDialogComponent, { panelClass: 'rain-cash-wallet-dialog' });
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        this.orderService.pointsPaymentCheckout.calculateRemainder();
        if(response) {
          this.UseRainCashToPayRemainder = true;
        }
      },
      error: (error) => {
        this.orderService.pointsPaymentCheckout.calculateRemainder();
      }
    })
  }

  payRemainderWithRainWallet() {
    
    // calculate fresh remainder.
    this.orderService.pointsPaymentCheckout.walletAmountInput = 0;
    this.PaymentPointAccounts.walletInput = 0;
    this.orderService.pointsPaymentCheckout.calculateRemainder();

    const remainderAmount = this.orderService.pointsPaymentCheckout.remainderAmount;
    const UserWalletBalance = this.userService.customerData.UserWalletAccount?.Balance || 0.00;

    // Selecting Rain Wallet with full remainder.

    if(remainderAmount > 0 && UserWalletBalance > 0) {
      this.PaymentPointAccounts.changeSelection("wallet", true);
      if(remainderAmount <= UserWalletBalance) {
        this.orderService.pointsPaymentCheckout.walletAmountInput = +remainderAmount.toFixed(2);
        this.PaymentPointAccounts.walletInput = +remainderAmount.toFixed(2);
      } else if (remainderAmount > UserWalletBalance) {
        this.orderService.pointsPaymentCheckout.walletAmountInput = UserWalletBalance;
        this.PaymentPointAccounts.walletInput = UserWalletBalance;
      }
      this.UseRainWalletToPayRemainder = true;
      this.orderService.pointsPaymentCheckout.calculateRemainder();
    }


    this.translate.get('Success').subscribe(() => {
      this.notificationService.success("Success", this.translate.instant('Success'));
    });



  }

  shouldDisplayLabel(type) {
    let allow = false;
    if(type == 'saved') {
      const allSaved = this.paymentService?.SavedPaymentTypeResponse;
      allSaved.forEach(pm => {
        if(this.is_saved_payment_method_allowed(pm)) {
          return (allow = true);
        }
      })
    }
    return allow;
  }
  
  is_saved_payment_method_allowed(paymentMethod) {
    if(!paymentMethod || !paymentMethod.PaymentTypeID) {
      return false;
    }
    const availableMethods = this.paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods'];
    return availableMethods.some((am) => am.PaymentTypeID == paymentMethod.PaymentTypeID) || false;
  }

  shouldPayMethodAllowedToUse(method) {
    // when no subscription, allow all payment methods.
    if(this.itemsService.selectedAutoOrderItems.length == 0) {
      return true; 
    } 
    else if (this.itemsService.selectedAutoOrderItems.length > 0) {
      return method.AllowInAutoOrders;
    }
  }
}

<div class="sidecart-top">
  <div fxLayoutAlign="end center">
    <mat-icon (click)="cartdrawer.close(); cartService.sidebarOpenStatus = false" class="drawer_closer">close</mat-icon>
  </div>
  <div class="side-box-top" fxLayout="row" fxLayoutAlign="space-between start">
    <h2 class="h2" translate="ShoppingCart"></h2>
    <a routerLink="/products" (click)="cartdrawer.close()" translate="ContinueShopping" class="lowercase"></a>
  </div>
  <!-- <div class="thick-border"></div> -->
</div>
<div class="pay-last-button" fxLayout="column">
  <button [textContent]="('Checkout'|translate)" class="checkout-btn w-100" (click)="goToCheckout()" *ngIf="!cartService.checkoutPaymentButton  && !cartService.enrollmentPaymentButton"></button>

  <!-- -----------------------------------------------------
    Normal Checkout Button ( without point system )
  -----------------------------------------------------  -->

  <!-- <button fxFlexAlign.lt-md="center" id="paybutton" class="btn-b checkout-btn w-100" *ngIf="cartService.checkoutPaymentButton"
  [textContent]="(paymentService.SelectedPaymentTypes==1 ? (('Pay' | translate) + ( selectedCountry=='tr'? ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | currency: configService.selectedMarketConfig.CurrencySymbol))):('Continue' | translate))"
  validate trigger-check="true" (success)="submitCheckout()" ></button> -->

   <!-- -----------------------------------------------------
    Points Checkout Button ( without point system )
  -----------------------------------------------------  -->

  <button fxFlexAlign.lt-md="center" id="paybutton" class="btn-b checkout-btn w-100" *ngIf="cartService.checkoutPaymentButton"
  [textContent]="('Pay' | translate) + ( selectedCountry=='tr'? ((orderService.pointsPaymentCheckout.remainderAmount ? orderService.pointsPaymentCheckout.remainderAmount : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.pointsPaymentCheckout.remainderAmount ? orderService.pointsPaymentCheckout.remainderAmount : 0) | currency: configService.selectedMarketConfig.CurrencySymbol))"
  validate trigger-check="true" (success)="submitCheckout()" ></button>


  <button fxFlexAlign.lt-md="center" id="paybutton" class="btn-b checkout-btn w-100" *ngIf="cartService.enrollmentPaymentButton && cartService.isPackSetup()"
  [textContent]="(paymentService.SelectedPaymentTypes==1 ? (('Pay' | translate) + (selectedCountry=='tr'? ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.Total ? orderService.calculateOrderResponse.Total : 0) | currency: configService.selectedMarketConfig.CurrencySymbol))):('Continue' | translate))"
  validate trigger-check="true" (success)="submitApplication()" ></button>
</div>
<div class="">
  <section class="scrollbar"
  *ngIf="(itemsService.selectedOrderItems.length + itemsService.selectedAutoOrderItems.length + itemsService.selectedPacks.length) > 0">

  <!-- Commented since not needed right now -->
  <!-- <div class="free-shipping-progress" fxLayout="column" fxLayoutAlign="center center" *ngIf="itemsService.selectedOrderItems.length>0">
      <span class="text-2" [textContent]="'You are ' + (remAmt | currency: configService.selectedMarketConfig.CurrencySymbol) + ' away from free shipping'"
        *ngIf="remAmt > 0 && remAmt <= 250"></span>
      <span class="text-2" translate="eligible_text" *ngIf="remAmt == 0"></span>
      <div class="progressBox">
        <div id="main-progress" #mainProgress [ngStyle]="{'width': freeShippingBar + '%' }"></div>
      </div>
    </div> -->

    <!-- packs section  -->
    <div *ngIf="itemsService.selectedPacks.length > 0">
      
      <!-- <ng-container *ngIf="itemsService.selectedPacks.length == 1">
        <app-configurepack></app-configurepack>
      </ng-container> -->

      <!-- -------------------------------------------
        Displaying Packs Sections ( REASON: Packs may be more than 1 [Starter Kit])
      --------------------------------------------- -->

      <div fxLayout="column" fxLayoutAlign="center start" *ngIf="itemsService.selectedPacks.length > 0">
        <div class="item side_padding" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of getItems('pack')">
          <ng-container>
            <div class="itemImage" fxFlex="45"  fxFlex.lt-sm="30" fxLayout="row" fxLayoutAlign="center center" >
              <img [default]="'../.././../../assets/images/noimage.png'" class="m-w-100"
                [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
            </div>
            <div class="mid-sect" fxFlex="30" fxFlex.lt-sm="40" fxLayout="column" fxLayoutAlign="space-around start">
              <span [textContent]="item.selectedItemName || item.ItemDescription" class="text-1 lowercase"></span>
            </div>
            <div class="price-sect" fxFlex="25"  fxFlex.lt-sm="30" fxLayout="column" fxLayoutAlign="space-around flex-end">
              <a *ngIf="cancelAllowed(item)" (click)="removeItem(item, 'pack')">
                <mat-icon class="mat-18">close</mat-icon>
              </a>
              <span  *ngIf="item.GroupMembers?.length>0"
                [textContent]="selectedCountry=='tr'?(getGroupItemPrice(item) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getGroupItemPrice(item) | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2"></span>
                <span *ngIf="item.GroupMembers?.length==0 && userService.customerTypeID != 3 && (item['PriceType1Value'] != item['PriceType' + userService.customerTypeID + 'Value'])"
                [textContent]="selectedCountry=='tr'?((item['PriceType1Value']) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):((item['PriceType1Value']) | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2 strikethrough"></span>
                <span *ngIf="item.GroupMembers?.length==0"
                [textContent]="selectedCountry=='tr'?(item['PriceType' + userService.customerTypeID + 'Value'] | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(item['PriceType' + userService.customerTypeID + 'Value'] | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2"></span>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- -------------------------------------------
      --------------------------------------------- -->

    </div>

    <!-- order section  -->
    <div fxLayout="column" fxLayoutAlign="center start" *ngIf="itemsService.selectedOrderItems.length > 0">
      <div class="item side_padding" fxLayout="row" fxLayoutAlign="space-between center"
        *ngFor="let item of getItems('order')">
        <ng-container>
          <div class="itemImage" fxFlex="45"  fxFlex.lt-sm="30" fxLayout="row" fxLayoutAlign="center center" >
            <img [default]="'../.././../../assets/images/noimage.png'" class="m-w-100"
              [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
          </div>
          <div class="mid-sect" fxFlex="30" fxFlex.lt-sm="40" fxLayout="column" fxLayoutAlign="space-around start">
            <span [textContent]="item.selectedItemName || item.ItemDescription" class="text-1 lowercase"></span>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('order', item)">
                <mat-icon class="mat-18">remove</mat-icon>
              </button>
              <input class="bt " type="text" matInput [(ngModel)]="getQuantityModel('order', item)[item.ItemCode]"
                (change)="checkQuantity('order', item);"
                (keyup)="($event.which === 38) ? increaseQuantiy('order', item) : 0"
                (keydown)="($event.which === 40) ? decreaseQuantiy('order', item) : 0 ;"
                (keypress)="getLastQuantity('order', item)"
                (ngInit)="getQuantityModel('order', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                validate inputonlynumber="true" maxlength="2" />
              <button class="bt b-r-r s-bt" (click)="increaseQuantiy('order', item)">
                <mat-icon class="mat-18">add</mat-icon>
              </button>
            </div>
          </div>
          <div class="price-sect" fxFlex="25"  fxFlex.lt-sm="30" fxLayout="column" fxLayoutAlign="space-around flex-end">
            <a (click)="removeItem(item, 'order')">
              <mat-icon class="mat-18">close</mat-icon>
            </a>
            <span  *ngIf="item.GroupMembers?.length>0"
              [textContent]="selectedCountry=='tr'?(getGroupItemPrice(item) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getGroupItemPrice(item) | currency: configService.selectedMarketConfig.CurrencySymbol)"
              class="text-2"></span>
              <span *ngIf="item.GroupMembers?.length==0 && userService.customerTypeID != 3 && (item['PriceType1Value'] != item['PriceType' + userService.customerTypeID + 'Value'])"
              [textContent]="selectedCountry=='tr'?((item['PriceType1Value']) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):((item['PriceType1Value']) | currency: configService.selectedMarketConfig.CurrencySymbol)"
              class="text-2 strikethrough"></span>
              <span *ngIf="item.GroupMembers?.length==0"
              [textContent]="selectedCountry=='tr'?(item['PriceType' + userService.customerTypeID + 'Value'] | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(item['PriceType' + userService.customerTypeID + 'Value'] | currency: configService.selectedMarketConfig.CurrencySymbol)"
              class="text-2"></span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="promoCodeBox" *ngIf="(itemsService.selectedOrderItems.length + itemsService.selectedPacks.length) > 0 && false">
      <div class="promo_code" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-form-field class="coupon-chip-list" appearance="fill" fxFlex="70">
          <mat-chip-list #chipList fxLayout="row" fxLayoutAlign="start center">
            <input #coupon type="text" id="coupon" onfocus="this.placeholder = ''"
            onblur="this.placeholder = {{'ApplyCoupon' | translate }} " [attr.placeholder]="'ApplyCoupon' | translate" style="padding: 3px 10px;"
              [matChipInputFor]="chipList" class="w-100" [ngClass]="{'couponVerified': verifiedCoupon}">
            <div fxLayoutAlign="end center" class="w-100">
              <mat-chip *ngIf="verifiedCoupon != ''" (removed)="removeCoupon(this)">
                {{verifiedCoupon}}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </div>
          </mat-chip-list>
        </mat-form-field>
        <button class="s-bt c-bt" fxFlex="20" translate="Apply"
          (click)="verifyCoupon(coupon.value)"></button>
      </div>
      <p id="promo-bottom-txt" translate="Onlyoneperorder"></p>
    </div>

    <div class="order_summary side_padding" *ngIf="itemsService.selectedPacks.length || itemsService.selectedOrderItems.length!=0">
      <div>
        <h4 class="o-s" translate="OrderSummary"></h4>
      </div>

      <div id="price" class="sarc global_subtotal">
        <span class="t-3 " translate="Price"></span>
        <span class="t-4"
          [textContent]="selectedCountry=='tr'?((orderService.calculateOrderResponse.OriginalSubTotal ? orderService.calculateOrderResponse.OriginalSubTotal : (orderService.calculateOrderResponse.CurrentSubTotal ? orderService.calculateOrderResponse.CurrentSubTotal : ( orderService.calculateOrderResponse.SubTotal ? orderService.calculateOrderResponse.SubTotal : 0))) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(orderService.calculateOrderResponse.OriginalSubTotal ? orderService.calculateOrderResponse.OriginalSubTotal : (orderService.calculateOrderResponse.CurrentSubTotal ? orderService.calculateOrderResponse.CurrentSubTotal : ( orderService.calculateOrderResponse.SubTotal ? orderService.calculateOrderResponse.SubTotal : 0))) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>        
      </div>
      <div id="discount" class="sarc">
        <span class="t-3" translate="DiscountTotal"></span>
        <span class="t-4"
          [textContent]="selectedCountry=='tr'?((((orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? (orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :((((orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? (orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
      </div>
      <div id="coupon" class="sarc">
        <span class="t-3" translate="PromoCouponDiscount"></span>
        <span class="t-4"
          [textContent]="selectedCountry=='tr'?((userService.promotionOffer && (orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : ((userService.promotionOffer && (orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
      </div>
      <div id="subtotal" class="sarc global_subtotal">
        <span class="t-3 " translate="Subtotal"></span>
        <span class="t-4"
          [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.SubTotal )| customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.SubTotal )| currency: configService.selectedMarketConfig.CurrencySymbol)"></span>        
      </div>
      <div class="sarc" *ngIf="(this.userService?.customerData?.UserCreditAccount?.Balance && this.userService?.customerData?.UserCreditAccount?.Balance >= 0)">
        <span class="t-3" translate="RainPartnerTeamCredits"></span>
        <span class="t-4"> - {{ orderService.pointsPaymentCheckout.creditAmountInput > 0 ? (orderService.pointsPaymentCheckout.creditAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>     
      </div>
      <div class="sarc" *ngIf="(this.userService?.customerData?.UserCashAccount?.Balance && this.userService?.customerData?.UserCashAccount?.Balance >= 0)">
        <span class="t-3" translate="rain_cash"></span>
        <span class="t-4"> - {{ orderService.pointsPaymentCheckout.cashAmountInput > 0 ? (orderService.pointsPaymentCheckout.cashAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>
      </div>
      <div id="shippingtax" class="sarc">
        <span class="t-3" translate="ShippingTotal"></span>
        <span class="t-4" [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        <!-- <span class="t-4" *ngIf="router.url == '/enrollment/checkout' || router.url == '/checkout' || router.url == '/review'"
          [textContent]="((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        <span class="t-4" *ngIf="router.url != '/enrollment/checkout' && router.url != '/checkout' && router.url != '/review'"
        [textContent]="(0 | currency: configService.selectedMarketConfig.CurrencySymbol)"></span> -->
      </div>
      <div id="tax" class="sarc">
        <span class="t-3" translate="TaxTotal"></span>
        <span class="t-4" [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        <!-- <span class="t-4" *ngIf="router.url == '/enrollment/checkout' || router.url == '/checkout'"
          [textContent]="((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        <span class="t-4" *ngIf="router.url !== '/enrollment/checkout' && router.url !== '/checkout'"
        [textContent]="(0 | currency: configService.selectedMarketConfig.CurrencySymbol)"></span> -->
      </div>

      <div id="grand_total" class="sarc grand-total">
        <span class="text-2" translate="Total"></span>
        <span class="text-2"
          [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :((orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
      </div>
      <div class="sarc" *ngIf="(this.userService?.customerData?.UserWalletAccount?.Balance && this.userService?.customerData?.UserWalletAccount?.Balance >= 0)">
        <span class="t-3" translate="RainWallet"></span>
        <span class="t-4"> - {{ orderService.pointsPaymentCheckout.walletAmountInput > 0 ? (orderService.pointsPaymentCheckout.walletAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>      
      </div>
      <div class="sarc">
        <span class="t-3" translate="Remainder"></span>       
        <span class="t-4"> {{ orderService.pointsPaymentCheckout.remainderAmount > 0 ? (orderService.pointsPaymentCheckout.remainderAmount | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>  
      </div>
    </div>

      <!-- ********** we don't need to display this info because above code is commented ********** -->

        <!-- <div fxLayout="column" fxLayoutAlign="center center" style="height: 50px" *ngIf="router.url != '/enrollment/checkout' && router.url != '/checkout'">
          <span class="bottom-tag text-2"  translate="ShippingAndTaxCalculate"></span>
        </div> -->
    <!-- Subscription special section -->
    <div class="checkout-subscribe-order" *ngIf="itemsService.selectedAutoOrderItems.length > 0">
      <mat-expansion-panel [expanded]="true" [disabled]="true">
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="center center" #exppanel>
          <mat-panel-title class="text-1" fxLayout="row" fxLayoutAlign="center center" fxFlex="44">
            <!-- <mat-icon [textContent]="icon ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"></mat-icon> -->
            <!-- <span routerLink="/products" (click)="cartdrawer.close()"  translate="ContinueShopping"></span> -->
            <span  translate="Subscription"></span>
          </mat-panel-title>
          <mat-panel-description fxLayout="row" fxLayoutAlign="end stretch" fxFlex="56" (click)="!exppanel._toggle()">
            <div fxFlex="10" fxLayoutAlign="end center" class="date-picker">
              <input matInput [min]="frequencyDate['startDate']" [max]="frequencyDate['endDate']" [matDatepickerFilter]="FilterDates" [matDatepicker]="picker" [(ngModel)]="userService.autoshipDate">
              <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <div fxFlex="72" fxFlex.lt-sm="80" fxLayout="column" fxLayoutAlign="center center" class="autoship-date" (click)="openDatePicker()">
              <p [textContent]="'NextOrderDate' | translate" class="due-date"></p>
              <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
                <p [textContent]="(userService.autoshipDate | date: 'longDate' : '' : locale) || 'Choose date'"></p>
              </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="side-box-top" fxLayout="row" fxLayoutAlign="center center">
          <a routerLink="/products" (click)="cartdrawer.close()" translate="ContinueShopping" class="lowercase"></a>
        </div>

        <div>
          <div class="item" fxLayout="row" fxLayoutAlign="space-between center"
            *ngFor="let item of getItems('autoship')">

            <div class="itemImage" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
              <img [default]="'../.././../../assets/images/noimage.png'" class="m-w-100"
                [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
            </div>
            <div class="mid-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around start">
              <span [textContent]="item.selectedItemName || item.ItemDescription" class="text-1 lowercase"></span>

              <div fxLayout="row" fxLayoutAlign="center center">
                <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('autoship', item)">
                  <mat-icon class="mat-18">remove</mat-icon>
                </button>
                <input class="bt " type="text" matInput [(ngModel)]="getQuantityModel('autoship', item)[item.ItemCode]"
                  (change)="checkQuantity('autoship', item);"
                  (keyup)="($event.which === 38) ? increaseQuantiy('autoship', item) : 0"
                  (keydown)="($event.which === 40) ? decreaseQuantiy('autoship', item) : 0 ;"
                  (keypress)="getLastQuantity('autoship', item)"
                  (ngInit)="getQuantityModel('autoship', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                  validate inputonlynumber="true" maxlength="2" />
                <button class="bt b-r-r s-bt" (click)="increaseQuantiy('autoship', item)">
                  <mat-icon class="mat-18">add</mat-icon>
                </button>
              </div>
            </div>
            <div class="price-sect p-r-0" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around flex-end">
              <a (click)="removeItem(item, 'autoship')">
                <mat-icon class="mat-18">close</mat-icon>
              </a>
              <span  *ngIf="item.GroupMembers?.length>0"
                [textContent]="selectedCountry=='tr'? (getGroupItemPrice(item) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (getGroupItemPrice(item) | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2"></span>
                <span *ngIf="item.GroupMembers?.length==0"
                [textContent]="selectedCountry=='tr'?((item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                class="text-2"></span>

            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="customerfields_section" *ngIf="itemsService.selectedPacks && itemsService.selectedPacks.length > 0">
    <div class="options-card card">
      <div class="card-head" fxLayout="row" fxLayoutAlign="space-between center">
        <span translate="MoreInfo"></span>
      </div>
      <div class="card-body">
        <li (click)="businessEntityClicked()" style="cursor: pointer" translate="BusinessEntity">
        </li>
        <mat-divider></mat-divider>
        <li (click)="spouseOptionsClicked()" style="cursor: pointer" translate="SpousePartnerAddition"></li>
      </div>
    </div>
    </div>
    

    <div *ngIf="relatedProducts.length">
      <h2 class="rel-h" translate="OthersAlsoPurchased"></h2>
      <mat-divider class="m-d-w"></mat-divider>

      <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="20px" fxLayoutAlign="start stretch"
          style="padding: 20px;">
          <div class="item rel" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px"
              *ngFor="let item of relatedProducts" fxFlex="33">
              <div class="itemImage br" fxLayout="column" fxLayoutAlign="center center">
                  <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>
                  <img [default]="'../.././../../assets/images/noimage.png'"
                   class="m-w-100" [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                      (click)="GoToProduct(item)" />
                  <button class="rel_btn" style="margin-top: 10px" (click)="quickView(item)"  translate="QuickView">
                  </button>
              </div>
              <div class="br" fxLayout="column" fxLayoutAlign="flex-start center" fxFlex="65">
                  <div class="text-2 prod-sub"  [textContent]="'Best Product in Market'">
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                      <div *ngIf="accountService.isLoggedUserRetailType()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                          <span [textContent]="selectedCountry=='tr'? (item.PriceType1Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : item.PriceType1Value | currency: configService.selectedMarketConfig.CurrencySymbol"
                              style="font-weight: 600"></span>
                          <button class="btn btn-b related_btn" (click)="addToCart('autoship', item)" [textContent]="'SubscribeToSave' | translate"></button>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                          <span
                              [textContent]="selectedCountry=='tr'? (item.PriceType2Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : item.PriceType2Value | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                          <button class="btn btn-w related_btn" (click)="addToCart('order', item)" [textContent]="'OneTimeOrder' | translate">One Time</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

  </section>

  <section class="mb-20 mt-20 blank_cart" fxLayoutAlign="center center" *ngIf="(itemsService.selectedOrderItems.length + itemsService.selectedAutoOrderItems.length + itemsService.selectedPacks.length) == 0">
     <h2 translate="YourCartIsEmpty" fxLayoutAlign="center center" class="blank_cart_text"></h2>
  </section>
<!--Related Products-->
</div>



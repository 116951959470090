<div id="bankdeposit" class="bank-deposit-dialog">
  <div fxLayout="row" class="market-selector ms-padding">
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <mat-icon class="close-icon" *ngIf="dialogData?.PaymentType != 1002" (click)="print($event)" mat-button>print</mat-icon>
      <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
    </div>
  </div>
  <div *ngIf="dialogData?.PaymentType != 1002" id="bankdeposit-section" fxLayout="column" class="ms-padding">
    <div fxFlex="100" fxLayout="column" class="pb-5" fxLayoutAlign="center center">
      <img width="400" heigh="200" class="img-fluid" [src]="imageUrl" [default]="imageUrl" alt="" >
      <h4 class="m-0 f-s-15 text-center" mat-dialog-title translate="WorldLeaderInSeedNutrition"></h4>
    </div>
    <div fxFlex="100" fxLayout="column" class="pb-3" fxLayoutAlign="center stretch">
      <h1 class="m-0 text-center" mat-dialog-title translate="BankDepositInstructionsTitle"></h1>
    </div>
    <div fxFlex="100" fxLayout="column" class="pt-3 pl-5 ml-4" fxLayoutAlign="center center">
      <div class="bankInformation">
        <span class="f-s-15" translate="">ToCompleteYourOrderText</span>:<br><br>
        <b translate="">BankName</b>:<br>
        <span [textContent]="dialogData.BankName"></span><br><br>
        <b translate="">AccountHolderName</b>:<br>
        <span [textContent]="dialogData.AccountHolderName"></span><br><br>
        <b translate="">AccountNumber</b>:<br>
        <span [textContent]="dialogData.AccountNumber"></span><br><br>
        <b translate="">BankAddress</b>:<br>
        <span [textContent]="dialogData.BankAddress"></span><br><br>
        <b translate="">SwiftCode</b>:<br>
        <span [textContent]="dialogData.SwiftCode"></span><br><br>
        <b translate="">OrderID</b>:<br>
        <span [textContent]="dialogData.OrderID"></span><br><br>
        <b translate="">Amount</b>:<br>
        <span [textContent]="dialogData.Amount"></span>&nbsp;<span *ngIf="dialogData.Amount >= 0" [textContent]="configService.selectedMarketConfig.CurrencySymbol"></span><br><br>
        <span translate=""> ShouldYouHaveAnyQuestionsText </span><a
          href="mailto:{{dialogData.SupportEmail[0]}}" [textContent]="dialogData.SupportEmail[0]"></a>
        <span translate=""> or </span><a href="mailto:{{dialogData.SupportEmail[1]}}" [textContent]="dialogData.SupportEmail[1]"></a>.
        <br><br>
        <span translate="ThankYouText"></span>,<br>
        <span translate="CompanyName"></span>
      </div>
    </div>
  </div>
  <div>
  </div>
  <div *ngIf="dialogData?.PaymentType == 1002" fxLayout="column" >
    <iframe [src]="iframeUrl" height="380px" width="auto"scrolling="no" frameborder="0" allowtransparency="true" ></iframe>
  </div>
</div>

<section fxLayout="column" class="padding_div">
        <app-breadcrumb [title]="('cart' | translate)" [showCrumbs]="true"
        [breadcrumb]="[{path1: 'Shopping', route1: '/products'},{path2: 'cart'|translate, route2: '/cart'}]"
        ></app-breadcrumb>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="100" fxLayoutAlign="center center">
            <div fxFlex="95" *ngIf="(itemsService.selectedPacks.length + itemsService.selectedOrderItems.length + itemsService.selectedAutoOrderItems.length) > 0" class="main_section">
                <div fxLayout="row" fxLayoutAlign="end center">
                <div  fxFlex="20" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxLayoutAlign="end center" class="checkout_btn">
                   <button mat-stroked-button (click)='navigateToCheckout()' translate="proceed_to_cart"></button>
                    </div>
                </div>
                <!-- Order Item Section -->
                <div fxLayout="column" fxLayoutAlign="space-between none" *ngIf="itemsService.selectedOrderItems.length > 0">
                    <div fxLayout="row wrap">
                        <h2 fxFlex="100" translate="order"></h2>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <div fxFlex="70" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                            fxLayoutAlign="space-around center">
                            <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-around none">
                                <div fxLayout="column">
                                    <div fxLayout="column" *ngFor="let item of getItems('order')">
                                        <div fxLayout="row" fxLayoutAlign="space-around start" class="bottom_padding">
                                            <div fxFlex="30" fxLayoutAlign="center center">
                                                <img [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                                                    class="main_img">
                                            </div>
                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40"
                                                fxLayoutAlign="space-evenly center" class="div_height">
                                                <div fxLayout="column" fxFlex="100">
                                                    <div fxLayout="row" fxLayoutAlign="space-around center">
                                                        <div fxFlex="100" fxLayoutAlign="start start">
                                                            <a href="" [textContent]="item.ItemDescription | slice:0:40"
                                                            [matTooltip]="item.ItemDescription" class="anchor_head"></a>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="row" fxLayout.xs="column"
                                                        fxLayoutAlign="start center"
                                                        fxLayoutAlign.xs="space-around start">
                                                        <div fxFlex="40">
                                                            <div fxLayout="row" fxLayoutAlign="start center" class="cart_button">
                                                                <button mat-stroked-button
                                                                    (click)="decreaseQuantiy('order', item);closeCart()"
                                                                    >
                                                                    <mat-icon class="icon" aria-hidden="false"
                                                                        aria-label="Example home icon"
                                                                        fxLayoutAlign="center center">remove </mat-icon>
                                                                </button>
                                                                <input type="text" matInput
                                                                    [(ngModel)]="getQuantityModel('order', item)[item.ItemCode]"
                                                                    (change)="checkQuantity('order', item); closeCart()"
                                                                    (keyup)="($event.which === 38) ? increaseQuantiy('order', item) : 0"
                                                                    (keydown)="($event.which === 40) ? decreaseQuantiy('order', item) : 0 ; closeCart()"
                                                                    (keypress)="getLastQuantity('order', item)"
                                                                    (ngInit)="getQuantityModel('order', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                                                                    validate inputonlynumber="true" maxlength="2"
                                                                    class="button_mid">
                                                                <button mat-stroked-button
                                                                    (click)="increaseQuantiy('order', item)"
                                                                    >
                                                                    <mat-icon class="icon" aria-hidden="false"
                                                                        aria-label="Example home icon"
                                                                        fxLayoutAlign="center center">add </mat-icon>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div fxFlex="30" fxLayoutAlign="space-between center">
                                                            <div fxLayout="row" fxLayoutAlign="center center"
                                                                class="remove">
                                                                <button mat-icon-button
                                                                    (click)="removeFromCart('order', item);closeCart();">
                                                                    <mat-icon aria-hidden="false"
                                                                        aria-label="Example home icon"
                                                                        fxLayoutAlign="center center">delete_outline</mat-icon>
                                                                </button>
                                                                <p fxLayoutAlign="center center" translate="remove"></p>
                                                            </div>
                                                        </div>
                                                   </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center"
                                            *ngFor="let childitem of item.StaticKitChildren">
                                            <div fxLayout="row" fxLayoutAlign="space-around center" class="bottom_padding" >
                                                <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                                                    fxLayoutAlign="center center" class="product_child">
                                                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
                                                        <div fxLayout="row" fxLayoutAlign="space-around center" class="product_child">
                                                            <div fxFlex="35" fxLayoutAlign="end center">
                                                                <img [src]="env.apiUrl  + 'api/Images' + childitem.TinyImageUrl"
                                                                    alt="" class="product_img">
                                                            </div>
                                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40" 
                                                                fxLayoutAlign="center center">
                                                                <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center">
                                                                            <a href="" [textContent]="childitem.ItemDescription | slice:0:40"
                                                                            [matTooltip]="childitem.ItemDescription" class="child_anchor"></a>                                                                                                                                          </div>
                                                                    </div>
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center"
                                                                        >
                                                                            <p><span class="child_para" translate="Quantity"></span>: <span class="child_para"
                                                                                    [textContent]="getQuantityModel('order', item)[item.ItemCode]"></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="bottom_padding">
                        <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="90" fxLayoutAlign="end center">
                            <div fxFlex="65">
                                <div fxLayout="column">
                                    <div fxLayout="row wrap" fxLayoutAlign="end center">
                                        <p class="price"
                                            [textContent]="orderService.calculateOrderResponse?.Total | currency: configService.selectedMarketConfig.CurrencySymbol">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- SubsCription Item Section -->
                <div fxLayout="column" fxLayoutAlign="space-between none" *ngIf="itemsService.selectedAutoOrderItems.length > 0">
                    <div fxLayout="row wrap">
                        <h2 fxFlex="100" translate="smart_order"></h2>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <div fxFlex="70" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                            fxLayoutAlign="space-around center">
                            <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-around none">
                                <div fxLayout="column">
                                    <div fxLayout="column" *ngFor="let item of getItems('autoship')">
                                        <div fxLayout="row" fxLayoutAlign="space-around start" class="bottom_padding">
                                            <div fxFlex="30" fxLayoutAlign="center center">
                                                <img [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                                                    class="main_img">
                                            </div>
                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40"
                                            fxLayoutAlign="space-evenly center" class="div_height">
                                            <div fxLayout="column" fxFlex="100">
                                                <div fxLayout="row" fxLayoutAlign="space-around center">
                                                    <div fxFlex="100" fxLayoutAlign="start start">
                                                        <a href="" [textContent]="item.ItemDescription | slice:0:40"
                                                        [matTooltip]="item.ItemDescription" class="anchor_head"></a>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayout.xs="column"
                                                    fxLayoutAlign="start center"
                                                    fxLayoutAlign.xs="space-around start">
                                                    <div fxFlex="40">
                                                        <div fxLayout="row" fxLayoutAlign="start center" class="cart_button">
                                                            <button mat-stroked-button
                                                                (click)="decreaseQuantiy('autoship', item);closeCart()"
                                                                >
                                                                <mat-icon class="icon" aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">remove </mat-icon>
                                                            </button>
                                                            <input type="text" matInput
                                                                [(ngModel)]="getQuantityModel('autoship', item)[item.ItemCode]"
                                                                (change)="checkQuantity('autoship', item); closeCart()"
                                                                (keyup)="($event.which === 38) ? increaseQuantiy('autoship', item) : 0"
                                                                (keydown)="($event.which === 40) ? decreaseQuantiy('autoship', item) : 0 ; closeCart()"
                                                                (keypress)="getLastQuantity('autoship', item)"
                                                                (ngInit)="getQuantityModel('autoship', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                                                                validate inputonlynumber="true" maxlength="2"
                                                                class="button_mid">
                                                            <button mat-stroked-button
                                                                (click)="increaseQuantiy('autoship', item)"
                                                                >
                                                                <mat-icon class="icon" aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">add </mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="30" fxLayoutAlign="space-between center">
                                                        <div fxLayout="row" fxLayoutAlign="center center"
                                                            class="remove">
                                                            <button mat-icon-button
                                                                (click)="removeFromCart('autoship', item);closeCart();">
                                                                <mat-icon aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">delete_outline</mat-icon>
                                                            </button>
                                                            <p fxLayoutAlign="center center" translate="remove"></p>
                                                        </div>
                                                    </div>
                                               </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center"
                                            *ngFor="let childitem of item.StaticKitChildren">
                                            <div fxLayout="row" fxLayoutAlign="space-around center" class="bottom_padding" >
                                                <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                                                    fxLayoutAlign="center center" class="product_child">
                                                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
                                                        <div fxLayout="row" fxLayoutAlign="space-around center" class="product_child">
                                                            <div fxFlex="35" fxLayoutAlign="end center">
                                                                <img [src]="env.apiUrl  + 'api/Images' + childitem.TinyImageUrl"
                                                                    alt="" class="product_img">
                                                            </div>
                                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40" 
                                                                fxLayoutAlign="center center">
                                                                <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center">
                                                                            <a href="" [textContent]="childitem.ItemDescription | slice:0:40"
                                                                            [matTooltip]="childitem.ItemDescription" class="child_anchor"></a>                                                                                                                                          </div>
                                                                    </div>
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center"
                                                                        >
                                                                            <p><span class="child_para" translate="Quantity"></span>: <span class="child_para"
                                                                                    [textContent]="getQuantityModel('autoship', item)[item.ItemCode]"></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="bottom_padding">
                        <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="90" fxLayoutAlign="end center">
                            <div fxFlex="65">
                                <div fxLayout="column">
                                    <div fxLayout="row wrap" fxLayoutAlign="end center">
                                        <p  class="price"
                                            [textContent]="orderService.autoorderTotal | currency: configService.selectedMarketConfig.CurrencySymbol">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Packs Selected -->
                <div fxLayout="column" fxLayoutAlign="space-between none" *ngIf="itemsService.selectedPacks.length > 0">
                    <div fxLayout="row wrap">
                        <h2 fxFlex="100" translate="packs"></h2>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                        <div fxFlex="70" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                            fxLayoutAlign="space-around center">
                            <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-around none">
                                <div fxLayout="column">
                                    <div fxLayout="column" *ngFor="let item of getItems('pack')">
                                        <div fxLayout="row" fxLayoutAlign="space-around start" class="bottom_padding">
                                            <div fxFlex="30" fxLayoutAlign="center center">
                                                <img [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                                                    class="main_img">
                                            </div>
                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40"
                                            fxLayoutAlign="space-evenly center" class="div_height">
                                            <div fxLayout="column" fxFlex="100">
                                                <div fxLayout="row" fxLayoutAlign="space-around center">
                                                    <div fxFlex="100" fxLayoutAlign="start start">
                                                        <a href="" [textContent]="item.ItemDescription | slice:0:40"
                                                        [matTooltip]="item.ItemDescription" class="anchor_head"></a>
                                                    </div>
                                                </div>
                                                <div fxLayout="row" fxLayout.xs="column"
                                                    fxLayoutAlign="start center"
                                                    fxLayoutAlign.xs="space-around start">
                                                    <div fxFlex="40">
                                                        <div fxLayout="row" fxLayoutAlign="start center" class="cart_button">
                                                            <button mat-stroked-button
                                                                (click)="decreaseQuantiy('pack', item);closeCart()"
                                                                >
                                                                <mat-icon class="icon" aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">remove </mat-icon>
                                                            </button>
                                                            <input type="text" matInput
                                                                [(ngModel)]="getQuantityModel('pack', item)[item.ItemCode]"
                                                                (change)="checkQuantity('pack', item); closeCart()"
                                                                (keyup)="($event.which === 38) ? increaseQuantiy('pack', item) : 0"
                                                                (keydown)="($event.which === 40) ? decreaseQuantiy('pack', item) : 0 ; closeCart()"
                                                                (keypress)="getLastQuantity('pack', item)"
                                                                (ngInit)="getQuantityModel('pack', item)[item.ItemCode] ? getQuantityModel('pack', item)[item.ItemCode] : getQuantityModel('pack', item)[item.ItemCode] = 1"
                                                                validate inputonlynumber="true" maxlength="2"
                                                                class="button_mid">
                                                            <button mat-stroked-button
                                                                (click)="increaseQuantiy('pack', item)"
                                                                >
                                                                <mat-icon class="icon" aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">add </mat-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="30" fxLayoutAlign="space-between center">
                                                        <div fxLayout="row" fxLayoutAlign="center center"
                                                            class="remove">
                                                            <button mat-icon-button
                                                                (click)="removeFromCart('pack', item);closeCart();">
                                                                <mat-icon aria-hidden="false"
                                                                    aria-label="Example home icon"
                                                                    fxLayoutAlign="center center">delete_outline</mat-icon>
                                                            </button>
                                                            <p fxLayoutAlign="center center" translate="remove"></p>
                                                        </div>
                                                    </div>
                                               </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center"
                                            *ngFor="let childitem of item.StaticKitChildren">
                                            <div fxLayout="row" fxLayoutAlign="space-around center" class="bottom_padding" >
                                                <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="95" fxFlex.md="80"
                                                    fxLayoutAlign="center center" class="product_child">
                                                    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
                                                        <div fxLayout="row" fxLayoutAlign="space-around center" class="product_child">
                                                            <div fxFlex="35" fxLayoutAlign="end center">
                                                                <img [src]="env.apiUrl  + 'api/Images' + childitem.TinyImageUrl"
                                                                    alt="" class="product_img">
                                                            </div>
                                                            <div fxFlex="60" fxFlex.xs="45" fxFlex.sm="40" 
                                                                fxLayoutAlign="center center">
                                                                <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center">
                                                                            <a href="" [textContent]="childitem.ItemDescription | slice:0:40"
                                                                            [matTooltip]="childitem.ItemDescription" class="child_anchor"></a>                                                                                                                                          </div>
                                                                    </div>
                                                                    <div fxLayout="row"
                                                                        fxLayoutAlign="center center">
                                                                        <div fxFlex="100" fxLayoutAlign="center center"
                                                                        >
                                                                            <p><span class="child_para" translate="Quantity"></span>: <span class="child_para"
                                                                                    [textContent]="getQuantityModel('autoship', item)[item.ItemCode]"></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center" class="bottom_padding">
                        <div fxFlex="100" fxFlex.xs="90" fxFlex.sm="90" fxLayoutAlign="end center">
                            <div fxFlex="65">
                                <div fxLayout="column">
                                    <div fxLayout="row wrap" fxLayoutAlign="end center">
                                        <p  class="price"
                                            [textContent]="orderService.autoorderTotal | currency: configService.selectedMarketConfig.CurrencySymbol">
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex="95" *ngIf="(itemsService.selectedPacks.length + itemsService.selectedOrderItems.length + itemsService.selectedAutoOrderItems.length) == 0">
                <div fxLayout="row wrap" fxLayoutAlign="start center">
                    <p translate="YourCartIsEmpty"></p>
                </div>
            </div>
        </div>
    </div>
</section>
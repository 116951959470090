
    <div fxLayout="column">

        <!-- slider section  -->
        <div fxLayout="column" fxFlex="100" class="hideClass slider">
          <div class="home-carousel">
                <swiper class="swiper-wrapper" fxFlex="auto" [config]="config">
                    <div class="swiper-slide">
                        <div class="main" fxLayout="column" fxFlex="100" fxLayoutAlign="center none">
                            <div fxFlex="100"> <img src="../../../assets/images/girl-running.jpg" alt="img1" class="img"></div>
                            <div class="maincontent" >
                                <div class="mainheader">
                                    <p class="mainheaderp" [textContent]="'NutrigenomicsInfusedIntoEachOfOurCreations' | translate"></p>
                                </div>
                                <div class="normaltext">
                                    <p [textContent]="'YourDNAReplicationIsTheFoundationOfHealth' | translate">
                                    </p>
                                    <button class="btn banner_shopnow_btn1" [textContent]="'ShopNow' | translate" (click)="goToShop()"></button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="main" fxLayout="column" fxFlex="100" fxLayoutAlign="center none">
                            <div fxFlex="100"> <img src="../../../assets/images/home-2.png" alt="img1" class="img"></div>
                            <div class="maincontent" fxFlex="75">
                                <div class="mainheader">
                                    <p class="mainheaderp" [textContent]="'EverythingWeDoIsBacked' | translate" ></p>
                                </div>
                                <div class="normaltext">
                                    <p [textContent]="'WeEnsureThatEachOfOurProducts' | translate" >
                                    </p>
                                    <button class="btn banner_shopnow_btn2" [textContent]="'ShopNow' | translate" (click)="goToShop()"></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="swiper-slide">
                        <div class="main" fxLayout="column" fxFlex="100" fxLayoutAlign="center none">
                            <div fxFlex="100"> <img src="../../../assets/images/home-3.png" alt="img1" class="img"></div>
                            <div class="maincontent" fxFlex="75">
                                <div class="mainheaderdiff">
                                    <p class="mainheaderp" [textContent]="'YouAreEnteringTheWorld' | translate"></p>
                                </div>
                                <div class="normaltext" >
                                    <p [textContent]="'DiscoverAbetterEasierWay' | translate">
                                    </p>
                                    <button class="btn banner_shopnow_btn3" [textContent]="'ShopNow' | translate"  (click)="goToShop()"></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </swiper>
          </div>
        </div>

        <!-- below slider section -->
        <app-below-slider></app-below-slider>

        <!-- leaf section -->
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center"  class="overflow" style="margin-top: 20px;">
            <div fxLayout="row" fxFlex="90" fxLayout.lt-md="column" fxLayoutAlign="start center" class="container">
                <div fxFlex="58"  fxLayout="column" fxLayoutAlign="center center">
                    <h2 translate="CommonSenseNutrition"></h2>
                    <p class="textStyle center" translate="WeTakeOurNutrition"></p>
                    <button mat-stroked-button (click)="goToLearn()" translate="LearnMore" class="btn-dg"></button>
                </div>
                <div fxFlex="41" fxLayout="end center">
                    <img src="../../../assets/images/seed-image-home.png" alt="leaf image" class="imgleaf" />
                </div>
            </div>
        </div>


        <!-- bottles section -->

          <div fxFlex="95" fxLayout="column" fxLayoutAlign="center center" class="m-b-20 container">
              <img src="../../../assets/images/pouch-home.png" alt="fifth image" class="bottleimg">
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="container">
              <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
                  <h2 translate="UltimateNutrient"></h2>
                  <p class="textStyle center" translate="SimpleLogic"></p>
                  <button mat-stroked-button translate="ShopNow" (click)="goToShop()"></button>
              </div>
          </div>


          <!-- about us section -->
          <div class="bannerdiv container" fxLayoutAlign="center center" fxLayout="row">
              <div fxLayout="column" fxFlex="40" fxLayoutAlign="center center" fxFlex.sm="45" fxFlex.xs="60">

                      <h1 translate="AboutUs" class="h1_text aboutUs"></h1>
                      <p class="textStyle center aboutUs-para" translate="OurOriginsAreUnique"></p>


                  <div fxFlex="50" fxLayoutAlign="center" class="mt-3">
                      <button mat-stroked-button class="btn-lg btn_size" (click)="goToAbout()" translate="LearnMore"></button>
                  </div>

              </div>
          </div>

          <!-- blog section -->
          <div fxLayout="row" *ngIf="!isLogin" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxLayoutAlign="space-between" class="container">
            <mat-card fxFlex="49" fxFlex.sm="calc(90%-25px)" fxFlex.md="calc(90%-25px)" fxFlex.xs="calc(90%-25px)">
                <img mat-card-image src="../../../../assets/images/join-us.png" alt="Photo of a card">
                <mat-card-content class="center">
                    <h1 translate="JoinUsToday" class="card_h1 text_transform"></h1>
                    <p class="card_text" translate="WereUnitedFamily">

                    </p>
                    <a routerLink="/enrollment">
                        <button mat-stroked-button ><span translate="JoinToday" class="btn_text"></span></button>
                    </a>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex="49" fxFlex.sm="calc(90%-25px)" fxFlex.md="calc(90%-25px)" fxFlex.xs="calc(90%-25px)">
                <img mat-card-image src="../../../../assets/images/blog.png" alt="Photo of a card">
                <mat-card-content class="center">
                    <h1 class="card_h1" translate="BlogSoulRed"></h1>
                    <p class="card_text" translate="TheWorldIsScary">
                    </p>

                    <button mat-stroked-button (click)="goToLearn()"><span translate="LearnMore" class="btn_text"></span></button>

                </mat-card-content>

            </mat-card>
          </div>



    </div>


export const LocalConfig={
    "Company": {
        "Name": "Rain",
        "Address": {
            "Email": "xyz@rainintl.com",
            "Street": "1510 N State St Suite 300",
            "City": "Orem",
            "State": "UT",
            "Zip": "84057",
            "Country": "United States",
            "Phone": "+1 833-544-1315",
            "Domain": "https://rainintl.com"
        },
        "SocialLinks": [
            {
                "link": "https://facebook.com",
                "media": "facebook",
                "icon": "fa fa-facebook"
            },
            {
                "link": "https://pinterest.com/",
                "media": "pinterest",
                "icon": "fa fa-pinterest"
            },
            {
                "link": "https://google.com",
                "media": "google",
                "icon": "fa fa-google-plus"
            },
            {
                "link": "https://vimeo.com/",
                "media": "vimeo",
                "icon": "fa fa-vimeo"
            },
            {
                "link": "https://www.youtube.com/",
                "media": "youtube",
                "icon": "fa fa-youtube"
            },
            {
                "link": "https://www.instagram.com/",
                "media": "instagram",
                "icon": "fa fa-instagram"
            },
            {
                "link": "https://twitter.com/",
                "media": "twitter",
                "icon": "fa fa-twitter"
            }
        ],
        "Images": {
            "footerlogo": "",
            "logo": "",
            "faviconicon": ""
        }
    },
    "Exigo":{
        "ExigoUrl":""
    },
    "Home":{
        "PrimaryBanner": [
            { "title": "Huge sale", "subtitle": "Up to 70%", "image":"assets/images/carousel/shimmers.png" },
            { "title": "Biggest discount", "subtitle": "Check the promotion", "image":"assets/images/carousel/OpenDK.png" },
            { "title": "Biggest sale", "subtitle": "Dont miss it", "image":"assets/images/carousel/lidDk.png" },
            { "title": "Our best products", "subtitle": "Special selection", "image":"assets/images/carousel/velvet.png" },
            { "title": "Massive sale", "subtitle": "Only for today", "image":"assets/images/carousel/bottleblack.png" }
          ]
    },
    "Product":{
        "PrimaryBanner": [
            { "title": "Huge sale", "subtitle": "Up to 70%", "image":"assets/images/carousel/shimmers.png" },
            { "title": "Biggest discount", "subtitle": "Check the promotion", "image":"assets/images/carousel/OpenDK.png" },
            { "title": "Biggest sale", "subtitle": "Dont miss it", "image":"assets/images/carousel/lidDk.png" },
            { "title": "Our best products", "subtitle": "Special selection", "image":"assets/images/carousel/velvet.png" },
            { "title": "Massive sale", "subtitle": "Only for today", "image":"assets/images/carousel/bottleblack.png" }
          ]
    },
    "Global":{
        "blockword": [
            "test",
            "abuse"
        ],
        "ConfigurableCheckout": "true",
        "ShowBirthWarning": true,
        "ShowCancerWarning": true,
        "AllowAutoship":true,
        "showRequiredTermsCheckbox": true,
        "OrderAllowCoupons": true,
        "AllowCVVModel": true,
        "OrderItemRequired" : {
            "1" : true,
            "2" : true,
            "3" : true
        },
        "AutoshipItemRequired" : {
            "1" : false,
            "2" : true,
            "3" : true
        }
    },
    "Autoship": {
        "AllowMultipleAutoship": "true",
        "AutoshipMinDate": "1",
        "AutoshipMaxDate": "1000",
        "DeleteAutoship": true
    }
};
export interface PersonalInfo {
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    phoneNumber: string;
    Email: string;
}
export interface FreeShipping {
    activated: boolean;
    coupon: string;
}
export interface CouponInfo {
    IsAppliedcode: boolean;
    promoCodeValid: boolean;
    promoCode: string;
    Allcoupons: Array<any>;
    RewardsForUse: Array<any>;
    FreeShipping: FreeShipping;
    availableRewards: Array<any>;
    OrderAllowCoupons: [];
}
export interface CardDetail {
    CardName: string;
    CardExpirationYear: number;
    CardExpirationMonth: number;
    CardNumber: string;
    CardCvv: string
    lastFour: string,
    firstSix: string
}
export interface DynamicCouponCode {
    promoCode: string;
}
export interface ShippingAddress {
    AddressId: 0;
    City: string;
    CountryCode: string;
    PostalCode: string;
    Region: string;
    Street1: string;
    Street2: string;
    Street3: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Phone: string;
    StateName: string;
    FullName?: string;
}
export interface WebOffice {
    UserName: string;
    Password: string;
    DomainName: string;
    ConfirmPassword: string;
    TaxID: string
    TaxPayerNumber: string
}
export interface BillingAddress {
    City: string;
    CountryCode: string;
    PostalCode: string;
    Region: string;
    Street1: string;
    Street2: string;
    Street3: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Phone: string;
    StateName: string;
    FullName?: string;
}

export class EnrollmentServiceModal {
    selectedLanguageID: string;
    selectedCountry: string;
    WebAlias: string;
    customerTypeID: number;
    enrollerInfo: any;
    guestUserLogin: boolean;
    customerData: any = {};
    guestUserData: any;
    shippingAddress: ShippingAddress = {} as ShippingAddress;
    mailingAddress: any;
    newshippingAddress: any;
    personalInfo: PersonalInfo = {} as PersonalInfo;
    webOffice: WebOffice = {} as WebOffice;
    couponInfo: CouponInfo = {} as CouponInfo;
    DynamicCouponCode: DynamicCouponCode = {} as DynamicCouponCode;
    paymentMethods: Array<any>;
    referralURL: string;
    restrictedStates: Array<any>;
    restrictedShipStates: string;
    selectedShippingMethod: number;
    shipMethods: Array<any>;
    autoshipCardDetail: any;
    cardDetail: CardDetail;
    commissionPayment: any;
    licenseNumber: string;
    legName: string;
    enrollerId: number;
    sponsorId: number;
    doNotWishAutoship: boolean;
    checkItems: boolean;
    autologin: boolean;
    regionMainState: string;
    newUser: boolean;
    TotalSteps: number;
    noOfStepsVerified: number;
    defaultState: string;
    isAddressChanged: boolean = false;
    isEditAutoshipAddressChanged: boolean = false;
    isPaymentChanged: boolean = false;
    isShipMethodChanged: boolean = false;
    sponsorSectionPanel: boolean = false;
    billingAddress: BillingAddress = {} as BillingAddress;
    customerContact: any = {}
    constructor() {

    }
}

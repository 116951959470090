
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-findenroller-byid',
  templateUrl: './findenroller-byid.component.html',
  styleUrls: ['./findenroller-byid.component.scss']
})
export class FindEnrollerByIDComponent {
  public DIALOGDATA: any;
  selectedSponsorData: {} = {}
  env: any;
  constructor(@Inject(MAT_DIALOG_DATA) public DIALOG_DATA: any,
    @Optional() public dialogRef: MatDialogRef<FindEnrollerByIDComponent>) {
    this.DIALOGDATA = DIALOG_DATA;
    this.env = environment;
  }

  ngOnInit() {
  }

  setSponsor(DIALOGDATA) {
    this.dialogRef.close({ selectedSponsor: DIALOGDATA, event: true })
  }
}

import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { BillingAddress, UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { CartService } from 'src/app/core/services/cart.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrderService } from 'src/app/core/services/order.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { FindEnrollerComponent } from 'src/app/shared/model/findenroller/findenroller.component';
import { QuickViewComponent } from 'src/app/shared/model/quick-view/quick-view.component';
import { environment } from 'src/environments/environment';
import { ValidateKeywordService } from 'src/app/core/services/validatekeyword.service';
import { ConfigService } from 'src/app/core/services/config.service';
import * as $ from 'jquery';
import { SharedService } from 'src/app/core/services/shared.service';
import intlTelInput from "intl-tel-input";
import telUtils from "../../../assets/phoneinput/js/utils";
import { VerificationComponent } from 'src/app/shared/model/verification/verification.component';
import { ReturnPolicyComponent } from 'src/app/shared/model/returnpolicy/returnpolicy.component';
import { AccountService } from 'src/app/core/authentication/account.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RewardDialogComponent, RewardDialogModel } from 'src/app/shared/model/reward-dialog/reward-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SellerAgreementComponent } from 'src/app/shared/model/seller-agreement-dialog/seller-agreement-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/model/confirm-dialog/confirm-dialog.component';
declare var kount: any;
declare var r: any;
declare var ka: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  result: string = '';
  userService: UserServiceModal;
  public shippingCountries: Array<any> = [];
  EmailCheck: boolean;
  public PhoneNumCheck: boolean = false;
  allowedRegions: any[] = [];
  billingallowedRegions: any[] = [];
  public Years: Array<any> = [];
  public cardExpMonths: Array<any> = [];
  public cardExpYears: Array<any> = [];
  env: any;
  crossClick = true;
  icon: boolean = false;
  lastQuantity: any;
  showPass = false;
  click() {
    this.icon = !this.icon;
  }
  verifiedCoupon: string = '';
  public myScriptElement: any = {};
  public tokenizer: any = {};
  public isSmallScreen: any;
  public verifyAddressError: boolean = false;
  public useSameBillingAddress: boolean = false;
  public selectedLanguage: any;
  public viewSaleAgreement: boolean = false;
  public viewPreliminarySaleAgreement: boolean = false;
  sellerAgreementHtml: any;
  selectCountry: any;
  public otherShippingAddress = {
    checked: false,
    data: {
      FirstName: '',
      LastName: '',
      Street1: '',
      Street2: '',
      Street3: '',
      City: '',
      StateName: '',
      PostalCode: '',
      CountryCode: ''
    }
  }
  public tokenEx3DSResponse: any = {};


  constructor(
    private translate: TranslateService, private titleService: Title,
    public apiService: RestApiService,
    public notificationService: NotificationService,
    public router: Router,
    public dialog: MatDialog,
    public user: UserService,
    public companyService: CompanyService,
    public itemsService: ProductService,
    public cartService: CartService,
    public orderService: OrderService,
    public utilityService: UtilityService,
    public paymentService: PaymentService,
    public validateKeyword: ValidateKeywordService,
    public configService: ConfigService,
    private sharedService: SharedService,
    public accountService: AccountService,
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<RewardDialogComponent>
  ) {
    this.sharedService.getSubmitCheckout().subscribe(() => {
      if (document.getElementById('checkoutPaybutton')) {
        document.getElementById('checkoutPaybutton').click();
      }
    });
    this.userService = user.userServiceModal;
    this.apiService.GetCountries().subscribe({
      next: data => {
        this.shippingCountries = data.Data;
        if (window.sessionStorage) {
          const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
          this.userService.shippingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
          this.userService.billingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
        }
      }
    });
    this.env = environment;
    this.Years = utilityService.birthYears();
    this.cardExpYears = utilityService.cardExpYears();
    this.cardExpMonths = utilityService.cardExpMonths();
    this.isSmallScreen = breakpointObserver.isMatched('(max-width: 480px)');
    if (this.isSmallScreen) {
      this.cartService.sidebarSummaryCartDrawer.close();
    }
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'));
    var newcustomerId: any;
    var exigosessionId: any;
    if (window.sessionStorage) {
      newcustomerId = sessionStorage.getItem('NewCustomerId') === 'undefined' ? 0 : sessionStorage.getItem('NewCustomerId');
      exigosessionId = sessionStorage.getItem('exigosessionId') === 'undefined' ? 0 : sessionStorage.getItem('exigosessionId');
      if (newcustomerId && exigosessionId) {
        translate.get('session_accept_title').subscribe(() => this.continueSessionDialog());
      }
    }
    this.selectCountry = sessionStorage.getItem('selectedCountry') || "US";
    this.otherShippingAddress.data.CountryCode = this.userService.shippingAddress.CountryCode || this.selectCountry;

    if(sessionStorage.getItem('otherShippingAddress')){
      this.otherShippingAddress = JSON.parse(sessionStorage.getItem('otherShippingAddress'));
    }
  }

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    sessionStorage.setItem('otherShippingAddress', JSON.stringify(this.otherShippingAddress));
  }

  public PACKS: Array<any> = [];
  theDate: string;
  public relatedProducts: Array<any> = [];
  checkProgress = 0;
  selectedCountry = '';
  public countries: Array<any> = [];

  @Input() payMethodSelectedId: any;
  showBillForm = true;
  showBillAddress = true;
  payMethod = '';
  sameAddress = false;
  dateForSub: string;
  input: any;
  public selectedpaymentType: any;
  _marketConfigurations: any = {};

  ngOnInit(): void {
    if(this.itemsService.selectedPacks.length > 0)
    {
      this.router.navigate(['/enrollment/checkout']);
      return;
    }
    //Redirect to shop if not item in cart
    if((this.itemsService.selectedOrderItems.length + this.itemsService.selectedAutoOrderItems.length + this.itemsService.selectedPacks.length) === 0){
      this.router.navigate(['/products']);
      return;
    }

    // fetch market configuration
    this.getMarketConfig();

    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Checkout') + ' - ' + text);
    });

    if (!this.getItems('order') && this.getItems('order').length == 0) {
      if (this.getItems('autoship').length > 0) {
        this.itemsService.getProduct(this.getItems('autoship')[0]?.ItemID).subscribe(e => {
          this.relatedProducts = e?.FrequentlyBoughtTogetherItems;
        })
      }
    } else {
      this.itemsService.getProduct(this.getItems('order')[0]?.ItemID).subscribe(e => {
        this.relatedProducts = e?.FrequentlyBoughtTogetherItems;
      })
    }

    this.paymentService.getPaymentType().then(() => {
      this.userService.paymentMethods = this.userService.paymentMethods || [];
      this.userService.couponInfo.promoCodeValid = undefined;
      this.paymentService.PaymentDataResponse = this.paymentService.PaymentDataResponse || {};
      // If they've already set up a split payment and try to add autoship items, only allow one payment method
      if (this.countPaymentMethods() > 1) {
        this.userService.paymentMethods = [this.userService.paymentMethods[0]];
      }
      if(this.paymentService?.PaymentTypeResponse['AvailableGeneralPaymentMethods']?.length === 1){
        this.selectedpaymentType = this.paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods'][0];
        this.paymentService.SelectedPaymentTypes = this.paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods'][0].PaymentTypeID;
      }
    });
    this.onCountryChanged();
    this.input = document.getElementById("telephone");
    this.callPhoneInit(this.input);
  }

  ngAfterViewInit(): void {
    this.userService.webOffice.UserName = '';
    this.loadKount();
    setTimeout(() => {
      //@ts-ignore
      this.tokenizer = new TokenExIframeClient("cc-wrapper", {
        styles: {
          base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
          focus: "box-shadow:none;border: none;outline: 0;",
          error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
        }
      });
      this.tokenizer.change(() =>
      {
          this.tokenExErrorHandling();
      });
    }, 5000);
  }

  getBillingAddress = (fieldName: string) => {
    switch(fieldName){
      case 'Address1':
        return this.useSameBillingAddress ? this.userService.shippingAddress.Street1 : this.userService.billingAddress.Street1
      case 'Address2':
        return this.useSameBillingAddress ? this.userService.shippingAddress.Street2 : this.userService.billingAddress.Street2
      case 'Address3':
        return this.useSameBillingAddress ? this.userService.shippingAddress.Street3 : this.userService.billingAddress.Street3
      case 'City':
        return this.useSameBillingAddress ? this.userService.shippingAddress.City : this.userService.billingAddress.City
      case 'State':
        return this.useSameBillingAddress ? this.userService.shippingAddress.StateName : this.userService.billingAddress.StateName
      case 'StateName':
        return this.useSameBillingAddress ? (this.allowedRegions.filter((state) => { return state.RegionCode == this.userService.shippingAddress.StateName })[0]?.RegionName || this.allowedRegions[0]?.RegionName) : (this.billingallowedRegions.filter((state) => { return state.RegionCode == this.userService.billingAddress.StateName })[0]?.RegionName || this.billingallowedRegions[0]?.RegionName)
      case 'Zip':
        return this.useSameBillingAddress ? this.userService.shippingAddress.PostalCode : this.userService.billingAddress.PostalCode
      case 'Country':
        return this.useSameBillingAddress ? this.userService.shippingAddress.CountryCode : this.userService.billingAddress.CountryCode
      case 'County':
        return this.useSameBillingAddress ? this.userService.shippingAddress.CountryCode : this.userService.billingAddress.CountryCode
      default :
        return '';
    }
  }

  getMarketConfig(){
    const countryCode = sessionStorage.getItem('selectedCountry') || this.configService.commonData.selectedCountry || 'us';
    this.apiService.getMarketConfig(countryCode).subscribe({
      next: (response) => {
        this._marketConfigurations = response?.Data;
      },
      error: (error) => {
        this._marketConfigurations = JSON.parse(sessionStorage.getItem('GetMarketConfig_'+countryCode) || '{}') ;
      },
      complete: () => { }
    })
  }

  selectedCountryCode = "";
  selectedBillingCountryCode = "";

  loadKount() {

    var fraudId = "";
    var client = new ka.ClientSDK();
    client.setupCallback(
      {
        'collect-end':
          function (params) {
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
          },
        'collect-begin':
          function (params) {
            console.log("Collect finished");
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
          }
      });
    client.autoLoadEvents();
  };
  activateInput()
  {
    setTimeout(() => {
      $('#cardiframe').click(function (e) {
        $('#tx_iframe_cc-wrapper')[0].focus();
      });
    }, 100)
  }
  fraudPrevention() {
    if (this.userService.shippingAddress.CountryCode == 'US' && !this.userService.addressCheck) {
      this.verifyAddressError = true;
      const target = $('#verifyaddress')[0];
      $('html, body').animate({
        scrollTop: $(target).offset().top - 100
      }, 1000);
      return;
    }
    if(!this.EmailCheck){
      this.notificationService.error(
        'Error',
        'Please verify your email'
      );
      return;
    }
    if(!this.PhoneNumCheck){
      this.notificationService.error(
        'Error',
        'Please verify your Phone Number'
      );
      return;
    }
    if (this.paymentService.SelectedPaymentTypes == 1) {
      this.tokenizer.tokenize({
        success: (data) => {
          console.log('tokenized success', data);
          var token = data.token;
          console.log('token', token)
          $(".creditcard-token").val(token);
          $(".creditcard-display").val(token.substr(token.length - 4, 4));


          if($("#FraudGuid").val() === ""){
            this.loadKount();
          }

          this.userService.cardDetail.CardNumber = token;
          this.userService.cardDetail.firstSix = data.firstSix;
          this.userService.cardDetail.lastFour = data.lastFour
          if(this._marketConfigurations?.ShouldAttemptTokenEx3DS){
            this.perform3DS();
          } else {
            this.submitCheckout();
          }
        },
        error: (data) => {
          console.log("get cc token failed", data);
          this.userService.cardDetail.CardNumber = "";
          this.userService.cardDetail.firstSix = "";
          this.userService.cardDetail.lastFour = "";
          this.notificationService.error(
            'Error',
            'Some Error Occur  ' + data.message
          );
          return;
        }
      });
    }
    else {
      this.submitCheckout();
    }
  }

 
  perform3DS(){

    const tokenEx3DS_Object = {

      //configs
      successful3dsIsRequired: this._marketConfigurations?.Successful3dsIsRequired,
      marketDoesNotRequire3DS_CompleteChallenge: this._marketConfigurations?.MarketDoesNotRequire3DS_CompleteChallenge,
      performAuthOnly: this._marketConfigurations?.PerformAuthOnly,
      enableConsoleLogging: true,
      challengeTimeoutTime: 1200000,
      otherField_TokenEx3DSIdentifier: 1,
      otherField_TokenEx3DSInfo: 2,
      otherField_StoredCredentialInfo: 3,

      //general info
      moneyInSDK_CreditCardType:  0,
      willBeSavingCardOnFileAtEndOfFlow: true,
      moneyInSDK_WebsiteID: 4,
      moneyInSDK_WebPageID: 1, //Shopping
      challengeWindowSize: window.innerWidth <= 500 ? 1 : (window.innerWidth > 500 && window.innerWidth <= 800) ? 2 : 4,
      currentMarketCountryCode: this.userService.shippingAddress.CountryCode,
      customerID: 0,
      orderID: 0,
      warehouseID: this._marketConfigurations?.Configuration?.Orders?.WarehouseID || 0,
      amount: this.orderService?.calculateOrderResponse?.Total,
      currencyCode: this._marketConfigurations?.Configuration?.Orders?.CurrencyCode || 'usd',

      //URLs
      tokenEx3DS_ActionsEndpoint: environment.apiUrl + "App/PerformActionsTokenEx3DS",
      deviceFingerprintingWebhook_Endpoint: environment.apiUrl + "App/DeviceFingerprintingWebhookTokenEx3DS",
      challengeResponseWebhook_Endpoint: environment.apiUrl + "App/ChallengeWebhookTokenEx3DS",

      //path information
      currentPageURL: window.location.href,
      clientsPublicSiteURL: window.location.origin,

      //card information
      token: this.userService.cardDetail.CardNumber,
      cardSecurityCode: this.userService.cardDetail.CardCvv,
      expirationMonth: this.userService.cardDetail.CardExpirationMonth,
      expirationYear: this.userService.cardDetail.CardExpirationYear,
      billingName: this.userService.shippingAddress.FirstName + ' ' + this.userService.shippingAddress.LastName,
      billingAddress1: this.getBillingAddress('Address1'),
      billingAddress2: this.getBillingAddress('Address2'),
      billingCity: this.getBillingAddress('City'),
      billingState: this.getBillingAddress('State'),
      billingZip: this.getBillingAddress('Zip'),
      billingCountry: this.getBillingAddress('Country'),

      //shipping address information
      shippingAddress1: this.userService.shippingAddress.Street1,
      shippingAddress2: this.userService.shippingAddress.Street2,
      shippingAddressCity: this.userService.shippingAddress.City,
      shippingAddressState: this.userService.shippingAddress.StateName,
      shippingAddressZip: this.userService.shippingAddress.PostalCode,
      shippingAddressCountry: this.userService.shippingAddress.CountryCode,
      emailAddress: this.userService.shippingAddress.Email,
      phoneNumber: this.userService.shippingAddress.Phone,

    }

    console.log("tokenEx3DS_Object", tokenEx3DS_Object)

    $('.progress-loader').removeAttr('hidden'); // showing loader

    this.orderService.waitForTokenExIframeElement('ExigoTokenEx3DS_Challenge', () => {
      $('.progress-loader').attr('hidden', true); // hide loader
    })

    //@ts-ignore
    tokenEx3DS.perform3DS({
      data: tokenEx3DS_Object,
      success: (data: any) => {
        this.tokenEx3DSResponse['allowed'] = true;
        this.tokenEx3DSResponse['data'] = {
          "Identifier" : data?.tokenEx3DS_Identifier,
          "Information" : data?.tokenEx3DS_Information
        }
        $('.progress-loader').attr('hidden', true); // hide loader
        this.submitCheckout();
        console.log("tokenEx3DSResponse perform3DS", data)
      },
      error: (data: any) => {

        if(data.includes("DetermineIfChallengeIsNecessary") || data?.toString()?.toLowerCase().includes("session expired")){
          this.translate.get("TokenEx3DSErrorMessage").subscribe(() => {
            this.notificationService.error("Error", this.translate.instant("TokenEx3DSErrorMessage"))
          });
        } else {
          this.notificationService.error("Error", data)
        }

        $('.progress-loader').attr('hidden', true);
        console.log("tokenEx3DSResponse perform3DS", data) // hide loader
        return;
      }
    });
  }

  isRedirectPaymentMethod(){
    if(this.paymentService?.PaymentTypeResponse['AvailableGeneralPaymentMethods']?.length > 0){
      const selectedPaymentMethod = this.paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods'].find((method:any) => method.PaymentTypeID == this.paymentService.SelectedPaymentTypes);
      return selectedPaymentMethod && selectedPaymentMethod.IsRedirect;
    }
  }

  countrySelected(e: any) {
    this.selectedCountryCode = e.value;
    this.apiService.GetRegions(this.selectedCountryCode.toUpperCase()).subscribe((data) => {
      this.allowedRegions = data.Data;
      this.userService.defaultState = this.allowedRegions[0]?.RegionCode;
    });
  }

  billingcountrySelected(e: any) {
    this.selectedBillingCountryCode = e.value;
    this.apiService.GetRegions(this.selectedBillingCountryCode.toUpperCase()).subscribe((data) => {
      this.billingallowedRegions = data.Data;
      this.userService.billingAddress.StateName = this.billingallowedRegions[0]?.RegionCode;
      //this.userService.defaultState = this.billingallowedRegions[0]?.RegionCode;
      this.userService.billingAddress.StateName = "";
    });

  }

  public addToCart(type, item) {
    // if (quantity === 0) {
    //   return false;
    // }
    // this.currentQuantity(type, product);
    if (type === 'order') {
      if (this.accountService.isLoggedUserRetailType())  {
        this.cartService.confrimSubscribe(item.ItemID).then((result) => {
          if (result == "learnmore") {
            this.dialog.closeAll();
            return;
          }
          if (result) {
            //For AutoOrderItem Adding
            this.cartService.addToCart(item, true, '', true, false, false);
            this.cartService.addToCart(
              item,
              true,
              '', // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
  
          }
          else {
            //For OrderItem Adding
            this.cartService.addToCart(
              item,
              true,
              '', // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
          }
          this.dialog.closeAll();
        });
      } else {
        //For OrderItem Adding
        this.cartService.addToCart( item, true, '', type === 'autoship', false, type === 'pack', false );
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
      }
    }
    else {
      this.updateorderwithAutoship(item);
    }

  }

  updateorderwithAutoship(item: any) {
    //For AutoOrderItem Adding
    this.cartService.addToCart(item, true, '', true, false, false);
    //For OrderItem Adding
    this.cartService.addToCart(item, true, '', false, false, false);

  }

  countPaymentMethods() {
    const paymentMethods = this.userService.paymentMethods;
    return paymentMethods ? paymentMethods.length : 0;
  }

  updateShipmethod(shipmethodID) {
    this.userService.selectedShippingMethod = shipmethodID;
    if (this.itemsService.selectedOrderItems.length > 0 || this.itemsService.selectedPacks.length > 0) {
      this.orderService.calculateOrder();
    }
    if (this.itemsService.selectedAutoOrderItems.length) {
      this.orderService.calculateAutoOrder();
    }
  }

  openFindEnroller() {
    this.dialog.open(FindEnrollerComponent, {
      data: {},
      panelClass: 'findenroller-dialog',
    });
  }

  verifyEmail(email: string) {
    var filter = new RegExp('^([\\w-\\.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})(\\]?)$');
    if (this.userService.shippingAddress.Email && filter.test(this.userService.shippingAddress.Email)) {
      var newcustomerId: any
      if (window.sessionStorage) {
        newcustomerId = sessionStorage.getItem('NewCustomerId') === 'undefined' ? 0 : sessionStorage.getItem('NewCustomerId');
      }
      email = this.userService.shippingAddress.Email;
      this.apiService.VerifyEmail(email, newcustomerId).subscribe({
        next: (result) => {
          if (result.Data) {
            this.EmailCheck = true;
            // if(!this.userService.webOffice.UserName){
            //   this.userService.webOffice.UserName=this.userService.shippingAddress.Email;
            // }
            this.notificationService.success('success', 'EmailAvailable');
            this.verifyPhoneForOffer(this.userService.shippingAddress.Phone, this.userService.shippingAddress.Email, this.userService.shippingAddress.Street1);
          } else {
            this.notificationService.error('Error', 'EmailAlreadyInUse');
            this.userService.shippingAddress.Email = '';
          }
        }
      });
    }

  }

  setUserShiping(): void {
    //if (window.localStorage) {
    if (Object.keys(this.userService.shippingAddress).length > 0) {
      this.router.navigate(['checkout/payment']);
    }
    //}
  }

  onCountryChanged() {
    this.apiService.GetRegions(this.userService.shippingAddress.CountryCode).subscribe({
      next: (data) => {
        this.allowedRegions = data.Data;
        this.billingallowedRegions = data.Data;
        this.userService.shippingAddress.StateName = this.allowedRegions.filter((state) => { return state.RegionCode == this.userService.shippingAddress.StateName })[0]?.RegionCode || this.allowedRegions[0]?.RegionCode;
        this.userService.billingAddress.StateName = this.billingallowedRegions.filter((state) => { return state.RegionCode == this.userService.billingAddress.StateName })[0]?.RegionCode || this.billingallowedRegions[0]?.RegionCode;
        this.userService.defaultState = this.allowedRegions[0]?.RegionCode;

        this.userService.shippingAddress.StateName = "";
        this.userService.billingAddress.StateName = "";
      }
    });
  }

  GoToProduct(item) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/product", item.ItemID]));
  }

  getItems(type: string) {
    return this.itemsService[
      type == "pack"
        ? "selectedPacks"
        : type == "autoship"
          ? "selectedAutoOrderItems"
          : "selectedOrderItems"
    ];
  }

  getImage(item) {
    // if(item.SmallImageUrl == undefined){
    //   return undefined;
    // }
    return item?.SmallImageUrl;
  }

  quickView(product) {
    let dialogRef = this.dialog.open(QuickViewComponent, {
      // minWidth: '90vw',
      // minHeight: '33rem',
      // maxWidth:'1300px',
      // width: '72rem',
      // height: '35rem',
      // position: {
      //   top:`calc(50vh - 300px)`,
      //   bottom: '',
      //   left: '',
      //   right: ''
      // },
      data: { env: this.env, product: product },
      panelClass: "center-quickview-dialog"
    });
    dialogRef.afterClosed().subscribe(dataResult => {

    })
  }


  submitCheckout() {
    var newcustomerId: any
    var newOrderID: any
    var newAutoOrderID: any

    if (window.sessionStorage) {
      newcustomerId = sessionStorage.getItem('NewCustomerId') === 'undefined' ? 0 : sessionStorage.getItem('NewCustomerId');
    }

    if(newcustomerId > 0){
      newOrderID = sessionStorage.getItem('NewOrderID') === 'undefined' ? 0 : sessionStorage.getItem('NewOrderID');
      newAutoOrderID = sessionStorage.getItem('NewAutoOrderID') === 'undefined' ? 0 : sessionStorage.getItem('NewAutoOrderID');
    }

    let request = {
      "Placement": 0,
      "PaymentMethodID": this.paymentService.SelectedPaymentTypes,
      "Customer": {
        "CustomerID": newcustomerId ? parseInt(newcustomerId, 10) : 0,
        "FirstName": this.userService.shippingAddress.FirstName,
        "MiddleName": "",
        "LastName": this.userService.shippingAddress.LastName,
        //"Company": "",
        "CustomerTypeID": 0,
        "CustomerStatusID": 0,
        //"DefaultWarehouseID": 0,
        "LanguageID": this.configService.commonData.selectedLanguageID,
        //"CreatedDate": "2022-02-11T07:21:30.408Z",
        //"BirthDate": "",
        //"TaxID": "",
        //"PayableToName": "string",
        //"PayableTypeID": 0,
        "IsOptedIn": this.userService.personalInfo.SendMail,
        "IsSMSSubscribed": this.userService.personalInfo.SendMail,
        "Email": this.userService.shippingAddress.Email,
        "PrimaryPhone": this.userService.shippingAddress.Phone,
        "SecondaryPhone": this.userService.shippingAddress.Phone,
        "MobilePhone": this.userService.shippingAddress.Phone,
        "Fax": "",
        "MainAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1 || '',
          "Address2": this.userService.shippingAddress.Street2 || '',
          "Address3": this.userService.shippingAddress.Street3 || '',
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County": this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true
        },
        "MainShippingAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1 || '',
          "Address2": this.userService.shippingAddress.Street2 || '',
          "Address3": this.userService.shippingAddress.Street3 || '',
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County": this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          "Email": this.userService.shippingAddress.Email,
          "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "MailingAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1 || '',
          "Address2": this.userService.shippingAddress.Street2 || '',
          "Address3": this.userService.shippingAddress.Street3 || '',
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County": this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true
        },
        "MailingShippingAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1 || '',
          "Address2": this.userService.shippingAddress.Street2 || '',
          "Address3": this.userService.shippingAddress.Street3 || '',
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County": this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          "Email": this.userService.shippingAddress.Email,
          "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "Addresses": [
          {
            "Address1": this.userService.shippingAddress.Street1 || '',
            "Address2": this.userService.shippingAddress.Street2 || '',
            "Address3": this.userService.shippingAddress.Street3 || '',
            "City": this.userService.shippingAddress.City,
            "State": this.userService.shippingAddress.StateName,
            "Zip": this.userService.shippingAddress.PostalCode,
            "Country": this.userService.shippingAddress.CountryCode,
            "County": this.userService.shippingAddress.CountryCode,
            "AllowCountrySelection": true,
            "USValidated": true
          }
        ],
        "LoginName": this.userService.webOffice.UserName,
        "Password": this.userService.webOffice.Password,
        "EnrollerID": this.userService.enrollerInfo.CustomerID,
        "SponsorID": this.userService.enrollerInfo.CustomerID,
        // "CurrencyCode": "string",
        "SponsorName": this.userService.enrollerInfo.DisplayName,
        "BinaryPlacementTypeID": 0,
        "UseBinaryHoldingTank": true,
        "IsInBinaryHoldingTank": true,
        // "CustomerTypeDescription": "string",
        // "CustomerStatusDescription": "string",
        // "RankID": 0,
        "CanLogin": true
        // "PayableDescription": "string",
        // "CustomerAttributionSelection": "string",
        // "CustomerAttributionCustomerID": 0
      },
      "NewOrderID": newOrderID || 0,
      "ShippingAddress": {
        "AddressType": "New",
        "Address1": this.otherShippingAddress.checked ? this.otherShippingAddress.data.Street1 : this.userService.shippingAddress.Street1 || '',
        "Address2": this.otherShippingAddress.checked ? this.otherShippingAddress.data.Street2 : this.userService.shippingAddress.Street2 || '',
        "Address3": this.otherShippingAddress.checked ? this.otherShippingAddress.data.Street3 : this.userService.shippingAddress.Street3 || '',
        "City": this.otherShippingAddress.checked ? this.otherShippingAddress.data.City : this.userService.shippingAddress.City,
        "State": this.otherShippingAddress.checked ? this.otherShippingAddress.data.StateName : this.userService.shippingAddress.StateName,
        "Zip": this.otherShippingAddress.checked ? this.otherShippingAddress.data.PostalCode : this.userService.shippingAddress.PostalCode,
        "Country": this.otherShippingAddress.checked ? this.otherShippingAddress.data.CountryCode : this.userService.shippingAddress.CountryCode,
        "County": this.otherShippingAddress.checked ? this.otherShippingAddress.data.CountryCode : this.userService.shippingAddress.CountryCode,
        "AllowCountrySelection": true,
        "USValidated": true,
        "FirstName": this.otherShippingAddress.checked ? this.otherShippingAddress.data.FirstName : this.userService.shippingAddress.FirstName,
        "MiddleName": "",
        "LastName": this.otherShippingAddress.checked ? this.otherShippingAddress.data.LastName : this.userService.shippingAddress.LastName,
        "Company": "",
        "Email": this.userService.shippingAddress.Email,
        "Phone": this.userService.shippingAddress.Phone,
        "Notes": ""
      },
      "BillingAddress": {
        "AddressType": "New",
        "Address1": this.getBillingAddress('Address1'),
        "Address2":  this.getBillingAddress('Address2'),
        "Address3":  this.getBillingAddress('Address3'),
        "City":  this.getBillingAddress('City'),
        "State":  this.getBillingAddress('State'),
        "StateName":  this.getBillingAddress('StateName'),
        "Zip":  this.getBillingAddress('Zip'),
        "Country":  this.getBillingAddress('Country'),
        "County":  this.getBillingAddress('County'),
        "AllowCountrySelection": true,
        "USValidated": true,
        "FirstName": this.userService.cardDetail.CardName || this.userService.shippingAddress.FirstName,
        "MiddleName": "",
        "LastName": this.userService.cardDetail.CardName ? " " : this.userService.shippingAddress.LastName,
        "Company": "",
        "Email": this.userService.shippingAddress.Email,
        "Phone": this.userService.shippingAddress.Phone,
        "Notes": ""
      },
      "ShipMethodID": this.userService.selectedShippingMethod,
      "AutoOrderPaymentMethodSameASOrderPaymentMethod": true,
      "BillingAddressSameAsShipping": this.useSameBillingAddress,
      "NewCustomerLoginName": this.userService.webOffice.UserName,
      "NewCustomerPassword": this.userService.webOffice.Password,
      "HasEnrollmentItemInCart": true,
      "IsSubmitting": true,
      // "OrderException": "",
      //"SelectedDistributor": "",
      //"PartyID": 0,
      // "GuestID": 0,
      //"UsingProductCredits": true,
      //"ProductCredits": 0,
      "AlternativePaymentAmount": 0,
      // "SelectedShipMethod": {
      //   "ShipMethodID": 0,
      //   "ShipMethodDescription": "",
      //   "Price": 0,
      //   "Selected": true,
      //   "PriceString": "string"
      // },
      //"SelectedMarketName": "UnitedStates",
      // "WorldPaySessionID": "string",
      // "WorldPayMessageForSubmit": "string",
      // "WorldpaySelectedMarket": {
      //   "Name": "UnitedStates",
      //   "Description": "string",
      //   "CookieValue": "string",
      //   "CultureCode": "string",
      //   "IsDefault": true,
      //   "Countries": [
      //     "string"
      //   ],
      //   "Region": "",
      //   "RPTPointAccountID": 0,
      //   "RainWalletPointAccountID": 0,
      //   "CVVRequired": true,
      //   "HasBackofficeUpgrade": true,
      //   "HasDirectDeposit": true,
      //   "CanCreateOrEditDirectDeposit": true,
      //   "UseCODSubscription": true,
      //   "RequireValidationEmail": true,
      //   "UseWorldpay3DSValidation": true,
      //   "UsePayoneerForPayout": true,
      //   "UseRainWalletEURExchangeRate": true,
      //   "EnforceEuroForSimplePay": true
      // },
      "UpgradedCustomerID": 0,
      "IsOnAlternativePayment": true,
      "AddCustomerContact": false
    };
    if (this.paymentService.SelectedPaymentTypes == 1) {
      let CreditCard = {
        "CreditCard": {
          "ShippingAndBilingAddressAreTheSame": this.useSameBillingAddress,
          "Type": "New",
          "NameOnCard": this.userService.cardDetail.CardName,
          "CardNumber": this.userService.cardDetail.CardNumber,
          "ExpirationMonth": this.userService.cardDetail.CardExpirationMonth,
          "ExpirationYear": this.userService.cardDetail.CardExpirationYear,
          "Token": this.userService.cardDetail.CardNumber,
          "Display": "1111",
          "CVV": "",
          "FirstSix": this.userService.cardDetail.firstSix,
          "LastFour": this.userService.cardDetail.lastFour,
          "BillingAddress": {
            "AddressType": "New",
            "Address1": this.getBillingAddress('Address1'),
            "Address2":  this.getBillingAddress('Address2'),
            "Address3":  this.getBillingAddress('Address3'),
            "City":  this.getBillingAddress('City'),
            "State":  this.getBillingAddress('State'),
            "Zip":  this.getBillingAddress('Zip'),
            "Country":  this.getBillingAddress('Country'),
            "County":  this.getBillingAddress('County'),
          }
        },
        "FraudGuid": $("#FraudGuid").val()
      };

      if(this.tokenEx3DSResponse?.allowed){
        request["tokenEx3DS_Identifier"] = this.tokenEx3DSResponse?.data["Identifier"];
        request["tokenEx3DS_Information"] = this.tokenEx3DSResponse?.data["Information"];
        request["tokenEx3DS_Storedinfo"] = JSON.stringify({
          "MoneyInSDK_CreditCardType":0,
          "WillBeSavingCardOnFile":true,
          "MoneyInSDK_WebsiteID":4,
          "MoneyInSDK_WebPageID":1
        });
      }

      request = Object.assign(request, CreditCard);
    }
    if (this.paymentService.SelectedPaymentTypes == 1003 || this.paymentService.SelectedPaymentTypes == 1001 ) {
      let FraudGuid = {
        "FraudGuid": $("#FraudGuid").val()
      }
      request = Object.assign(request, FraudGuid);

      if(this.tokenEx3DSResponse?.allowed){
        request["tokenEx3DS_Identifier"] = this.tokenEx3DSResponse?.data["Identifier"];
        request["tokenEx3DS_Information"] = this.tokenEx3DSResponse?.data["Information"];
        request["tokenEx3DS_Storedinfo"] = JSON.stringify({
          "MoneyInSDK_CreditCardType":0,
          "WillBeSavingCardOnFile":true,
          "MoneyInSDK_WebsiteID":4,
          "MoneyInSDK_WebPageID":1
        });
      }

    }
    const productdetails = [];
    this.itemsService.selectedOrderItems.forEach((item, index) => {
      let order = {
        ItemCode: item.ItemCode,
        Quantity: item.Quantity || 1,
        Type: "Order"
      }

      if (item.ItemCode == 'DiscountCode') {
        order['PriceEachOverride'] = item.PriceEachOverride;
        request['Other17'] = this.userService.WebAlias;
      }
      productdetails.push(order);
    });

    this.itemsService.selectedPacks.forEach((item, index) => {
      productdetails.push({
        ItemCode: item.ItemCode,
        Quantity: item.Quantity || 1,
        Type: "Order",
      });
    });
    request["Items"] = [];
    if (productdetails && productdetails.length > 0) {
      request["Items"] = productdetails;
    }
    if (this.itemsService.selectedAutoOrderItems && this.itemsService.selectedAutoOrderItems.length > 0) {
      this.itemsService.selectedAutoOrderItems.forEach((item, index) => {
        request["Items"].push({
          ItemCode: item.ItemCode,
          Quantity: item.Quantity || 1,
          Type: "AutoOrder",
        });
      });

      // Setting Autoship Date if selected of past date from today 
      if(new Date(this.userService.autoshipDate) <= new Date()){
        const todayDate = new Date();
        this.userService.autoshipDate = this.user.getDateAfterOneMonth(todayDate);
      }

      let autoOrder = {
        "NewAutoOrderID": newAutoOrderID || 0,
        "AutoOrderFrequencyType": "Monthly",
        "AutoOrderStartDate": this.userService.autoshipDate,
        "AutoOrderShippingAddress": {
          "AddressType": "New",
          "Address1": this.userService.shippingAddress.Street1 || '',
          "Address2": this.userService.shippingAddress.Street2 || '',
          "Address3": this.userService.shippingAddress.Street3 || '',
          "City": this.userService.shippingAddress.City,
          "State": this.userService.shippingAddress.StateName,
          "Zip": this.userService.shippingAddress.PostalCode,
          "Country": this.userService.shippingAddress.CountryCode,
          "County":this.userService.shippingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          "Email": this.userService.shippingAddress.Email,
          "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "AutoOrderBillingAddress": {
          "AddressType": "New",
          "Address1": this.useSameBillingAddress ? this.userService.shippingAddress.Street1 : this.userService.billingAddress.Street1,
          "Address2": this.useSameBillingAddress ? this.userService.shippingAddress.Street2 : this.userService.billingAddress.Street2,
          "Address3": this.useSameBillingAddress ? this.userService.shippingAddress.Street3 : this.userService.billingAddress.Street3,
          "City": this.useSameBillingAddress ? this.userService.shippingAddress.City : this.userService.billingAddress.City,
          "State": this.useSameBillingAddress ? this.userService.shippingAddress.StateName : this.userService.billingAddress.StateName,
          "Zip": this.useSameBillingAddress ? this.userService.shippingAddress.PostalCode : this.userService.billingAddress.PostalCode,
          "Country": this.useSameBillingAddress ? this.userService.shippingAddress.CountryCode : this.userService.billingAddress.CountryCode,
          "County": this.useSameBillingAddress ? this.userService.shippingAddress.CountryCode : this.userService.billingAddress.CountryCode,
          "AllowCountrySelection": true,
          "USValidated": true,
          "FirstName": this.userService.shippingAddress.FirstName,
          "MiddleName": "",
          "LastName": this.userService.shippingAddress.LastName,
          "Company": "",
          "Email": this.userService.shippingAddress.Email,
          "Phone": this.userService.shippingAddress.Phone,
          "Notes": ""
        },
        "AutoOrderBillingSameAsShipping": this.useSameBillingAddress,
        "AutoOrderShipMethodID": this.userService.selectedShippingMethod,
      };
      request = Object.assign(request, autoOrder);
    }

    if(this.itemsService.totalItemsInCartCount() == 0) {
      this.notificationService.error('Error', "No Items added in cart!");
      return;
    }

    this.apiService.submitCheckout(request).subscribe({
      next: (result) => {

        if(result.Data?.OrderException && result.Data?.IsSubmitting == false) {
          this.notificationService.error('Error', result.Data.OrderException);
          return;
        }

        else if (result.Data) {
          if (this.paymentService.SelectedPaymentTypes != 1002 && result.Data.IsUsingAnAlternativePaymentMethod) {
            if (window.sessionStorage) {
              sessionStorage.setItem('NewCustomerId', result.Data.CustomerID);
              sessionStorage.setItem('NewOrderID', result.Data.OrderID);
              sessionStorage.setItem('NewAutoOrderID', result.Data.AutoOrderID);
              sessionStorage.setItem('CustomerToken', result.Data.token);
            }
            try {
              if (result.Data.OrderID > 0) {
                var orderitems: any = [];
                this.itemsService.selectedOrderItems.forEach((item, index) => {
                  orderitems.push({
                    "itemId": item.ItemID,
                    "productName": item.ItemDescription,
                    "sku": item.SKU || 0,
                    "quantity": item.Quantity || 1,
                    "qv": item.QV || 0,
                    "cv": item.CV || 0,
                    "bonus": item.Bonus || 0,
                    "price": item.Price,
                    "languageCode": this.selectedLanguage.CultureCode || 'en',
                    "currencyCode": item.CurrencyCode,
                  })
                });
                var orderData = [{
                  "orderId": result.Data.OrderID,
                  "customerId": result.Data.CustomerID,
                  "sponsorId": this.userService.enrollerInfo.CustomerID,
                  "orderDetail": {
                    "items": orderitems,
                    "subTotal": this.orderService.calculateOrderResponse.SubTotal || 0,
                    "totalAmount": this.orderService.calculateOrderResponse.Total || 0
                  }
                }];
                window['ZTM'].setStats('RainIntl', "PlaceOrder", JSON.stringify(orderData));
              }
              if (result.Data.AutoOrderID > 0) {
                var autoOrderitems: any = [];
                this.itemsService.selectedAutoOrderItems.forEach((autoitem, index) => {
                  autoOrderitems.push({
                    "itemId": autoitem.ItemID,
                    "productName": autoitem.ItemDescription,
                    "sku": autoitem.SKU || 0,
                    "quantity": autoitem.Quantity || 1,
                    "qv": autoitem.QV || 0,
                    "cv": autoitem.CV || 0,
                    "bonus": autoitem.Bonus || 0,
                    "price": autoitem.Price,
                    "languageCode": this.selectedLanguage.CultureCode,
                    "currencyCode": autoitem.CurrencyCode,
                  })
                });
                var Autodata = [{
                  "orderId": result.Data.AutoOrderID,
                  "customerId": result.Data.CustomerId,
                  "sponsorId": this.userService.enrollerInfo.CustomerID,
                  "orderDetail": {
                    "items": autoOrderitems,
                    "subTotal": this.orderService.calculateAutoOrderResponse.SubTotal || 0,
                    "totalAmount": this.orderService.calculateAutoOrderResponse.Total || 0
                  }
                }];
                window['ZTM'].setStats('RainIntl', "PlaceOrder", JSON.stringify(Autodata));
              }
              if (result.Data.CustomerID) {
                var customerData = [{
                  "customerId": result.Data.CustomerID,
                  "backOfficeId": this.userService.enrollerInfo.BackOfficeId || '',
                  "emailaddress": this.userService.shippingAddress.Email || '',
                  "phoneNumber": this.userService.shippingAddress.Phone || '',
                  "firstName": this.userService.shippingAddress.FirstName || '',
                  "lastName": this.userService.shippingAddress.LastName || '',
                  "customerStatus": 0,
                  "customerType": this.userService.customerTypeID || 0,
                  "username": this.userService.webOffice.UserName || '',
                  "webAlias": this.userService.WebAlias || '',
                  "sponsorId": this.userService.enrollerInfo.CustomerID || '',
                  "enrollerId": this.userService.enrollerInfo.CustomerID || '',
                  "signupDate": new Date().toLocaleDateString(),
                  "primaryAddress": {
                    "id": 0,
                    "line1": this.userService.shippingAddress.Street1 || '',
                    "line2": this.userService.shippingAddress.Street2 || '',
                    "line3": this.userService.shippingAddress.Street3 || '',
                    "city": this.userService.shippingAddress.City || '',
                    "state": this.userService.shippingAddress.StateName || '',
                    "zip": this.userService.shippingAddress.PostalCode || '',
                    "countryCode": this.userService.shippingAddress.CountryCode || '',
                  },
                  "defaultShippingAddress": {
                    "id": 0,
                    "line1": this.userService.shippingAddress.Street1 || '',
                    "line2": this.userService.shippingAddress.Street2 || '',
                    "line3": this.userService.shippingAddress.Street3 || '',
                    "city": this.userService.shippingAddress.City || '',
                    "state": this.userService.shippingAddress.StateName || '',
                    "zip": this.userService.shippingAddress.PostalCode || '',
                    "countryCode": this.userService.shippingAddress.CountryCode || '',
                  },
                  "externalReferenceId": '',
                  "languageCode": this.selectedLanguage?.CultureCode || 'en',
                }]
                window['ZTM'].setStats('RainIntl', 'CreateCustomer', JSON.stringify(customerData));
              }
            } catch (error) {
              console.log('error', error);
            }
            window.location.href = `${environment.apiUrl}AlternatePayment/index?token=${result.Data.token}`
          } else {
            try {
              if (result.Data.OrderID > 0) {
                var orderitems: any = [];
                this.itemsService.selectedOrderItems.forEach((item, index) => {
                  orderitems.push({
                    "itemId": item.ItemID,
                    "productName": item.ItemDescription,
                    "sku": item.SKU || 0,
                    "quantity": item.Quantity || 1,
                    "qv": item.QV || 0,
                    "cv": item.CV || 0,
                    "bonus": item.Bonus || 0,
                    "price": item.Price,
                    "languageCode": this.selectedLanguage.CultureCode || 'en',
                    "currencyCode": item.CurrencyCode,
                  })
                });
                var orderData = [{
                  "orderId": result.Data.OrderID,
                  "customerId": result.Data.CustomerID,
                  "sponsorId": this.userService.enrollerInfo.CustomerID,
                  "orderDetail": {
                    "items": orderitems,
                    "subTotal": this.orderService.calculateOrderResponse.SubTotal || 0,
                    "totalAmount": this.orderService.calculateOrderResponse.Total || 0
                  }
                }];
                window['ZTM'].setStats('RainIntl', "PlaceOrder", JSON.stringify(orderData));
              }
              if (result.Data.AutoOrderID > 0) {
                var autoOrderitems: any = [];
                this.itemsService.selectedAutoOrderItems.forEach((autoitem, index) => {
                  autoOrderitems.push({
                    "itemId": autoitem.ItemID,
                    "productName": autoitem.ItemDescription,
                    "sku": autoitem.SKU || 0,
                    "quantity": autoitem.Quantity || 1,
                    "qv": autoitem.QV || 0,
                    "cv": autoitem.CV || 0,
                    "bonus": autoitem.Bonus || 0,
                    "price": autoitem.Price,
                    "languageCode": this.selectedLanguage.CultureCode,
                    "currencyCode": autoitem.CurrencyCode,
                  })
                });
                var Autodata = [{
                  "orderId": result.Data.AutoOrderID,
                  "customerId": result.Data.CustomerId,
                  "sponsorId": this.userService.enrollerInfo.CustomerID,
                  "orderDetail": {
                    "items": autoOrderitems,
                    "subTotal": this.orderService.calculateAutoOrderResponse.SubTotal || 0,
                    "totalAmount": this.orderService.calculateAutoOrderResponse.Total || 0
                  }
                }];
                window['ZTM'].setStats('RainIntl', "PlaceOrder", JSON.stringify(Autodata));
              }
              if (result.Data.CustomerID) {
                var customerData = [{
                  "customerId": result.Data.CustomerID,
                  "backOfficeId": this.userService.enrollerInfo.BackOfficeId || '',
                  "emailaddress": this.userService.shippingAddress.Email || '',
                  "phoneNumber": this.userService.shippingAddress.Phone || '',
                  "firstName": this.userService.shippingAddress.FirstName || '',
                  "lastName": this.userService.shippingAddress.LastName || '',
                  "customerStatus": 0,
                  "customerType": this.userService.customerTypeID || 0,
                  "username": this.userService.webOffice.UserName || '',
                  "webAlias": this.userService.WebAlias || '',
                  "sponsorId": this.userService.enrollerInfo.CustomerID || '',
                  "enrollerId": this.userService.enrollerInfo.CustomerID || '',
                  "signupDate": new Date().toLocaleDateString(),
                  "primaryAddress": {
                    "id": 0,
                    "line1": this.userService.shippingAddress.Street1 || '',
                    "line2": this.userService.shippingAddress.Street2 || '',
                    "line3": this.userService.shippingAddress.Street3 || '',
                    "city": this.userService.shippingAddress.City || '',
                    "state": this.userService.shippingAddress.StateName || '',
                    "zip": this.userService.shippingAddress.PostalCode || '',
                    "countryCode": this.userService.shippingAddress.CountryCode || '',
                  },
                  "defaultShippingAddress": {
                    "id": 0,
                    "line1": this.userService.shippingAddress.Street1 || '',
                    "line2": this.userService.shippingAddress.Street2 || '',
                    "line3": this.userService.shippingAddress.Street3 || '',
                    "city": this.userService.shippingAddress.City || '',
                    "state": this.userService.shippingAddress.StateName || '',
                    "zip": this.userService.shippingAddress.PostalCode || '',
                    "countryCode": this.userService.shippingAddress.CountryCode || '',
                  },
                  "externalReferenceId": '',
                  "languageCode": this.selectedLanguage?.CultureCode || 'en',
                }]
                window['ZTM'].setStats('RainIntl', 'CreateCustomer', JSON.stringify(customerData));
              }
            } catch (error) {
              console.log('error', error);
            }
            this.router.navigateByUrl(`/thank-you?token=${result.Data.token}&enrollment=false`);
          }
        }

        else {
          if (result.Message) {
            this.notificationService.error(
              'Error',
              result.Message
            );
          }
        }
      }, error: () => {

      }, complete: () => {

      }
    });
  }

  paymentChange($event) {
    this.selectedpaymentType = this.paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods'].find( type => { return type.PaymentTypeID == $event.value });
    if ($event.value == 1) {
      setTimeout(() => {
        this.loadKount();
        //@ts-ignore
        this.tokenizer = new TokenExIframeClient("cc-wrapper", {
          styles: {
            base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
            focus: "box-shadow:none;border: none;outline: 0;",
            error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
          }
        });
        this.tokenizer.change(() =>
        {
            this.tokenExErrorHandling();
        });
        setTimeout(() => {
          $('#cardiframe').click(function (e) {
            $('#tx_iframe_cc-wrapper')[0].focus();
          });
        }, 100)
      }, 1000);
    }
    if ($event.value == 1003 || $event.value == 1001) {
      setTimeout(() => {
        this.loadKount();
      }, 1000);
    }
    if (window.sessionStorage) {
      window.sessionStorage.setItem('SelectedPaymentTypes', JSON.stringify(this.paymentService.SelectedPaymentTypes));
    }
  }
  tokenExErrorHandling()
  {
    this.tokenizer['iframe']?.on('error',function(data) {
      let count = 0;
      if(data.error && count < 1){
          count++;
          alert(data.error + "\n Your session has expired. Would you like to continue?");
          window.location.reload();
      }
    });
  }
  verifyUsername(username: string) {
    const filter = new RegExp('^[a-zA-Z0-9]+$');
    if (username && filter.test(username)) {
      const blockword = ['test,abuse'];
      const isValidPost = this.validateKeyword.CheckValidation(blockword, username);
      if (isValidPost && !isValidPost.isvalid) {
        this.notificationService.error(
          'Error',
          this.translate.instant('LoginNameNotAvailable')
        );
        this.userService.webOffice.UserName = '';
      } else {
        var newcustomerId: any
        if (window.sessionStorage) {
          newcustomerId = sessionStorage.getItem('NewCustomerId') === 'undefined' ? 0 : sessionStorage.getItem('NewCustomerId');
        }
        this.apiService.VerifyUserName(username, newcustomerId).subscribe({
          next: (result) => {
            try {
              if (result.Status == 0 && result.Data) {
                this.notificationService.success(
                  'success',
                  this.translate.instant('UserNameAvailable')
                );
              } else {
                this.userService.webOffice.UserName = '';
                this.notificationService.error(
                  'Error',
                  this.translate.instant('LoginNameNotAvailable')
                );

              }
            } catch (ex) {
              this.userService.webOffice.UserName = '';
              this.notificationService.error(
                'Error',
                this.translate.instant('OopsSomethingWentWrong')
              );
            }
          },
          error: err => {
            // Do stuff whith your error
            // this.loadingDetails = false;
          },
          complete: () => {
            // Do stuff after completion
          }
        });
      }
    }
  }

  @ViewChild('coupon') EnteredCoupon;
  verifyCoupon(coupon: string) {
    if (this.verifiedCoupon != '') {
      this.notificationService.error('Error', 'YouAlreadyAppliedACoupon')
    } else if (coupon === '') {
      this.notificationService.error("Error", "ApplyCoupon")
    } else {
      const req = {
        'couponcode': coupon,
        'country': this.configService.commonData.selectedCountry || 'us',
        'priceType': 1
      }
      this.apiService.ApplyCoupon(req).subscribe({
        next: data => {
          if (data.Data && data.Status == 0) {
            this.verifiedCoupon = coupon;
            this.notificationService.success('success', 'CouponApplied');
            // this.userService.couponInfo.promoCode.push(coupon);
            this.userService.couponInfo.promoCode = coupon;
            this.calculateOrder();
          } else {
            this.notificationService.error('Error', 'CouponIsNotValid')
          }
        }, error: (error) => {

        }
      })
    }
  }

  removeCoupon(option) {

    $('#couponcode').val('');
    this.verifiedCoupon = '';
    this.notificationService.error("Error", "Coupon_Removed");
    // this.userService.couponInfo.promoCode=[];
    this.userService.couponInfo.promoCode = '';
    this.calculateOrder();
  }

  calculateOrder() {
    this.orderService.calculateOrder();
  }

  
  verifyPhone(id: any) {
    var selectedCountry = "";// iti.selectedCountryData();
    var iti = window['intlTelInputGlobals'].getInstance(this.input);
    console.log('iti.selectedCountryData()', '+' + iti.selectedCountryData?.dialCode);

    try {
      selectedCountry = iti.selectedCountryData?.iso2?.toUpperCase() || window['intlTelInputGlobals'].instances[0].selectedCountryData.iso2.toUpperCase();
    } catch {
      selectedCountry = this.configService.commonData.selectedCountry.toUpperCase();
    }

    if (this.userService.shippingAddress.Phone) {
      this.apiService.validatePhone((selectedCountry || this.configService.commonData.selectedCountry), this.userService.shippingAddress.Phone.replace(/[^a-zA-Z0-9]/g, '')).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            if (data.Data.PhoneValid) {
              this.PhoneNumCheck = true;
              this.userService.shippingAddress.Phone = data.Data.InternationalNumber;
              if (!this.userService.promotionOffer) {
                this.verifyPhoneForOffer(this.userService.shippingAddress.Phone, this.userService.shippingAddress.Email, this.userService.shippingAddress.Street1);
              }
              else {
                this.notificationService.success("info", "You_have_already_verified_phone_msg")
              }
            } else {
              this.userService.shippingAddress.Phone = "";
              this.notificationService.error('Error', 'IncorrectPhone');
            }
          } else {
            this.userService.shippingAddress.Phone = "";
            this.notificationService.error('Error', 'IncorrectPhone');
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }

  // availDiscount() {
  //   if (this.userService.shippingAddress.Phone && this.userService.shippingAddress.Email && this.userService.shippingAddress.Street1) {
  //     this.verifyPhone('telephone');
  //   }
  //   else {
  //     this.notificationService.error('Info', "discount_fill_details");
  //   }
  // }

  verifyPhoneForOffer(phonenum: any, email: any, address: any) {
    if (!this.userService.promotionOffer && phonenum && email && address) {
      let req = {
        mobileNumber: phonenum,
        email: email,
        address1: address
      };
      this.apiService.validatePhoneForOffer(req).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            // this.congratsDialog();
            let offerDiscount = this.orderService.applyDiscount(this.orderService.calculateOrderResponse?.SubTotal);
            if (offerDiscount > 0) {
              this.notificationService.success('success', 'discount_applied');
              this.userService.promotionOffer = true;
              this.cartService.addToCart({ ItemDescription: 'Discount Coupon Applied', ItemCode: 'DiscountCode' }, false, '', false, false, false);
            }
            this.userService.IsshowPromotionOption = false;
          }
          else{
            //this.notificationService.error('Info', 'discount_not_applicable');
            this.userService.IsshowPromotionOption = true;
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
    // else {
    //   this.notificationService.error('Info', "discount_fill_details");
    // }
  }


  callPhoneInit(input: any) {
    intlTelInput(input, {
      // whether or not to allow the dropdown
      allowDropdown: true,
      // if there is just a dial code in the input: remove it on blur, and re-add it on focus
      autoHideDialCode: false,
      // add a placeholder in the input with an example number for the selected country
      autoPlaceholder: "polite",
      // modify the auto placeholder
      customPlaceholder: null,
      // append menu to specified element
      dropdownContainer: null,
      // don't display these countries
      excludeCountries: [],
      // format the input value during initialisation and on setNumber
      formatOnDisplay: true,
      // geoIp lookup function
      geoIpLookup: null,
      // inject a hidden input with this name, and on submit, populate it with the result of getNumber
      hiddenInput: "",
      // initial country
      initialCountry: sessionStorage.getItem('selectedCountry'),
      // localized country names e.g. { 'de': 'Deutschland' }
      localizedCountries: null,
      // don't insert international dial codes
      nationalMode: true,
      // display only these countries
      onlyCountries: [],
      // number type to use for placeholders
      placeholderNumberType: "MOBILE",
      // the countries at the top of the list. defaults to united states and united kingdom
      preferredCountries: ["us", "gb"],
      // display the country dial code next to the selected flag so it's not part of the typed number
      separateDialCode: true,
      // specify the path to the libphonenumber script to enable validation/formatting
      utilsScript: telUtils
    });
  }

  onVerifyAddress(obj: any) {
    let req = {
      AddressType: "New",
      Address1: obj.Street1,
      Address2: obj.Street2 || '',
      Address3: obj.Street3 || '',
      City: obj.City,
      State: obj.StateName,
      Zip: obj.PostalCode,
      Country: obj.CountryCode,
      County: obj.CountryCode,
      AllowCountrySelection: true,
      USValidated: true
    };
    this.apiService.VerifyAddress(req).subscribe((data) => {
      if (data.Data.IsValid === true) {
        const dialogRef = this.dialog.open(VerificationComponent, {
          data: data.Data
        });
        dialogRef.afterClosed().subscribe((dialogResult) => {
          // console.log('dialogResult', dialogResult);
          if (dialogResult.Type == 1) {
            obj.Street1 = dialogResult.Addresses.VerifiedAddress.Address1,
            obj.StateName = dialogResult.Addresses.VerifiedAddress.State,
            obj.PostalCode = dialogResult.Addresses.VerifiedAddress.Zip,
            obj.City = dialogResult.Addresses.VerifiedAddress.City
          }
          this.userService.addressCheck = true;
        });
      } else {
        this.notificationService.error("Error", "Please_Enter_Valid_Address")
      }
    });
  }

  setBillingAddress(event: MatCheckboxChange): void {
    if (event.checked) {
      this.userService.billingAddress.Street1 = this.userService.shippingAddress.Street1;
      this.userService.billingAddress.Street2 = this.userService.shippingAddress.Street2;
      this.userService.billingAddress.Street3 = this.userService.shippingAddress.Street3;
      this.userService.billingAddress.City = this.userService.shippingAddress.City;
      this.userService.billingAddress.StateName = this.userService.shippingAddress.StateName;
      this.userService.billingAddress.PostalCode = this.userService.shippingAddress.PostalCode;
      this.userService.billingAddress.CountryCode = this.userService.shippingAddress.CountryCode
    }
    else {
      this.userService.billingAddress = {} as BillingAddress;
    }
  }

  policyModelOpen() {
    const policyDialogRef = this.dialog.open(ReturnPolicyComponent, {
      width: '90%',
      maxWidth: '480px',
      minHeight: '600px',
      height: 'auto'
    });

    policyDialogRef.afterClosed().subscribe(() => {
      console.log('PolicyDialog Closed');
    });
  }

  congratsDialog(): void {
    const dialogData = new RewardDialogModel(
      '',
      {
        "key1": this.translate.instant('congrats_'),
        "key2": 'Congratulations_offer_message',
        "key3": this.translate.instant('click_mobile_code')
      },
      this.translate.instant('Submit')
    );
    const dialogRef = this.dialog.open(RewardDialogComponent, {
      maxWidth: "720px",
      maxHeight: '500px',
      data: dialogData,
      panelClass: ['confirm-reward-dialog', 'center-reward-dialog'],
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (this.result) {
        this.sendOTPMessage(this.userService.shippingAddress.Phone);
      }
    });
  }

  OTPDialog(): void {
    const dialogData = new RewardDialogModel(
      '',
      {
        "key1": this.translate.instant('Enter_promo_code_msg')
      },
      this.translate.instant('Submit'),
      true
    );
    const dialogRef = this.dialog.open(RewardDialogComponent, {
      maxWidth: "720px",
      maxHeight: '500px',
      data: dialogData,
      panelClass: ['confirm-reward-dialog', 'center-reward-dialog'],
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      let result = dialogResult;
      if (result) {
        this.notificationService.success('success', 'Code_verified_msg');
        this.userService.promotionOffer = true;
        let offerDiscount = this.orderService.applyDiscount(this.orderService.calculateOrderResponse?.SubTotal);
        if (offerDiscount > 0) {
          this.cartService.addToCart({ ItemDescription: 'Discount Coupon Applied', ItemCode: 'DiscountCode' }, false, '', false, false, false);
        }
      }
    });
  }

  closeDialog() {
    this.dialogRef.close('close icon');
  }

  sendOTPMessage(phonenum: any) {
    let req = {
      mobileNumber: phonenum
    }
    if (this.userService.shippingAddress.Phone) {
      this.apiService.sendOTPMessage(req).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            this.OTPDialog();
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }


  SaleAgreement(saleAgreement) {
    if (saleAgreement == 'viewSaleAgreement') {
      this.viewSaleAgreement = !this.viewSaleAgreement;
    }

    if (saleAgreement == 'PreliminarySaleAgreement') {
      this.viewPreliminarySaleAgreement = !this.viewPreliminarySaleAgreement
    }

  }

  openSellerAgreementDialog(sellerAgreementId) {
    if (this.userService.shippingAddress && (!this.userService.shippingAddress.Street1 ||
      !this.userService.shippingAddress.City || !this.userService.shippingAddress.StateName ||
      !this.userService.shippingAddress.CountryCode || !this.userService.shippingAddress.FirstName ||
      !this.userService.shippingAddress.LastName)) {
      this.notificationService.error('Error', "Please fill form details");
      return;
    }
    const dialogRef = this.dialog.open(SellerAgreementComponent, {
      maxWidth: "800px",
      maxHeight: '800px',
      data: {
        'taxId': this.userService.webOffice.TaxID,
        'agreementId': sellerAgreementId + '_' + this.selectedLanguage.CultureCode,
        'type': 'checkout'
      }
    })

    dialogRef.afterClosed().subscribe(dialogResult => {

    })
  }

  continueSessionDialog(): void {
    const dialogData = new ConfirmDialogModel(
      this.translate.instant('session_accept_title'),
      this.translate.instant('session_accept_msg'),
      this.translate.instant('CancelOrReset'),
      this.translate.instant('StayAndContinue')
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '100%',
      maxWidth: '750px',
      data: dialogData,
      panelClass: 'findenroller-dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.notificationService.info("info", "Your session has been continued");
      }
      else {
        sessionStorage.removeItem('NewCustomerId');
        sessionStorage.removeItem('NewOrderID');
        sessionStorage.removeItem('NewAutoOrderID');
        sessionStorage.removeItem('exigosessionId');
        this.cartService.clearCart();
        const webAlias = this.userService.WebAlias || 'RainShop';
        window.location.href = `${window.location.origin}/${webAlias}/home`;
      }
    });
  }

  ngOnDestroy(): void {
    this.userService.promotionOffer = false;
    this.userService.IsshowPromotionOption = true;
    this.userService.addressCheck = false;
    this.userService.shippingAddress.Phone = '';
    this.cartService.removeFromCart({ ItemDescription: 'Discount Coupon Applied', ItemCode: 'DiscountCode' }, false, false, false);
    sessionStorage.removeItem('otherShippingAddress');
    if (window.sessionStorage) {
      window.sessionStorage.setItem('SelectedPaymentTypes', JSON.stringify(this.paymentService.SelectedPaymentTypes));
    }
  }
}

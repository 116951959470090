export const DiscountConfig = {
    "Discount": [
        {
            "countryCode": "US",
            "maxDiscount": "50"
        },
        {
            "countryCode": "CA",
            "maxDiscount": "65"
        },
        {
            "countryCode": "MX",
            "maxDiscount": "1000"
        },
        {
            "countryCode": "NG",
            "maxDiscount": "22500"
        },
        {
            "countryCode": "JP",
            "maxDiscount": "50"
        },
        {
            "countryCode": "HK",
            "maxDiscount": "400"
        },
        {
            "countryCode": "MY",
            "maxDiscount": "215"
        },
        {
            "countryCode": "PH",
            "maxDiscount": "2600"
        },
        {
            "countryCode": "SG",
            "maxDiscount": "67.5"
        },
        {
            "countryCode": "TW",
            "maxDiscount": "1550"
        },
        {
            "countryCode": "BA",
            "maxDiscount": "90"
        },
        {
            "countryCode": "BG",
            "maxDiscount": "50"
        },
        {
            "countryCode": "HR",
            "maxDiscount": "50"
        },
        {
            "countryCode": "CY",
            "maxDiscount": "50"
        },
        {
            "countryCode": "CZ",
            "maxDiscount": "50"
        },
        {
            "countryCode": "EE",
            "maxDiscount": "50"
        },
        {
            "countryCode": "GR",
            "maxDiscount": "50"
        },
        {
            "countryCode": "HU",
            "maxDiscount": "20000"
        },
        {
            "countryCode": "LV",
            "maxDiscount": "50"
        },
        {
            "countryCode": "LT",
            "maxDiscount": "50"
        },
        {
            "countryCode": "MT",
            "maxDiscount": "50"
        },
        {
            "countryCode": "RO",
            "maxDiscount": "250"
        },
        {
            "countryCode": "RS",
            "maxDiscount": "6000"
        },
        {
            "countryCode": "SI",
            "maxDiscount": "50"
        },
        {
            "countryCode": "SK",
            "maxDiscount": "50"
        },
        {
            "countryCode": "RU",
            "maxDiscount": "2900"
        },
        {
            "countryCode": "AT",
            "maxDiscount": "1500"
        },
        {
            "countryCode": "BE",
            "maxDiscount": "50"
        },
        {
            "countryCode": "DK",
            "maxDiscount": "50"
        },
        {
            "countryCode": "FI",
            "maxDiscount": "50"
        },
        {
            "countryCode": "DE",
            "maxDiscount": "50"
        },
        {
            "countryCode": "IE",
            "maxDiscount": "50"
        },
        {
            "countryCode": "IT",
            "maxDiscount": "50"
        },
        {
            "countryCode": "NL",
            "maxDiscount": "50"
        },
        {
            "countryCode": "NO",
            "maxDiscount": "50"
        },
        {
            "countryCode": "PL",
            "maxDiscount": "50"
        },
        {
            "countryCode": "PT",
            "maxDiscount": "50"
        },
        {
            "countryCode": "ES",
            "maxDiscount": "50"
        },
        {
            "countryCode": "SE",
            "maxDiscount": "50"
        },
        {
            "countryCode": "CH",
            "maxDiscount": "50"
        },
        {
            "countryCode": "GB",
            "maxDiscount": "50"
        }
        // {
        //     "countryCode": "BW",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "NA",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "ZA",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "KR",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "UA",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "VN",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "BN",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "GH",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "TR",
        //     "maxDiscount": "50"
        // },
        // {
        //     "countryCode": "MK",
        //     "maxDiscount": "50"
        // }
    ]
};

export const discountUnlistedCountries: string[] = [
    "BA", // Bosnia 
    "HR", // Croatia
    "MK", // North Macedonia
    "RS", // Serbia
    "HU", // Hungary
    "RO", // Romania
    "BG", // Bulgaria
    "CZ", // Czech Republic
    "LV", // Latvia
    "LT", // Lithuania
    "SK", // Slovakia
    "EE", // Estonia
    "SI", // Slovenia
    "GR", // Greece
    "CY", // Cyprus
    "MT", // Malta
];

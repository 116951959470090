import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { environment } from 'src/environments/environment';
import { CartService } from 'src/app/core/services/cart.service';
import { getSubdomain } from 'tldts';
import { UserService } from 'src/app/core/services/user.service';
import { Location } from '@angular/common';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { Route, Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  item: number = 0;
  env: any;
  userService: UserServiceModal;
  lastQuantity: number = 0;
  constructor(
    public user: UserService,
    public itemsService: ProductService,
    public cartService: CartService,
    public location: Location,
    public router: Router,
    public orderService: OrderService,
    public companyService: CompanyService,
    public configService: ConfigService
  ) {
    this.userService = user.userServiceModal;
    this.env = environment;
  }

  ngOnInit(): void {
  }

  getItems(type: string) {
    return this.itemsService[type == 'pack' ? 'selectedPacks' : (type == 'autoship' ? 'selectedAutoOrderItems' : 'selectedOrderItems')];
  }

  getQuantityModel(type, item): any {
    return this.cartService[type == 'pack' ? 'packQuantity' : (type == 'autoship' ? 'autoshipQuantity' : 'orderQuantity')];
  }

  increaseQuantiy(type, item) {
    this.cartService.increaseQuantiy(item, type == 'autoship', type == 'pack');
    this.updateQuantity(type, item, undefined);
  }

  decreaseQuantiy(type, item) {
    this.cartService.decreaseQuantiy(item, type == 'autoship', type == 'pack');
    this.updateQuantity(type, item, (item.Quantity === 0));
  }

  removeFromCart(type, item) {
    this.cartService.removeFromCart(item, type == 'autoship', type == 'pack', true);
    this.updateQuantity(type, item, true);
  }

  updateQuantity(type, item, remove) {
    // TODO:: this is bad remove this.
    if (!(type == 'autoship')) {
      // $rootScope.$broadcast('orderQuantity',
      // { item: item, type: (this.isPack ? 'pack' : (this.isAutoship ? 'autoship' : 'order')), remove: !!remove });
    }
  }

  checkQuantity(type, item) {
    const quantity = this.getQuantityModel(type, item)[item.ItemCode];
    if (!Number(quantity)) {
      this.cartService.removeFromCart(item, type == 'autoship', type == 'pack', true);
    } else {
        item.Quantity = quantity;
      if (type == 'autoship') {
        localStorage.setItem('cart.autoship', JSON.stringify(this.itemsService.selectedAutoOrderItems));
        //this.orderService.calculateLocalAutoOrder();
        this.orderService.calculateAutoOrder();
      } else {
        localStorage.setItem((type == 'pack' ? 'cart.packs' : 'cart.order'),
          JSON.stringify(type == 'pack' ? this.itemsService.selectedPacks : this.itemsService.selectedOrderItems));
        //this.orderService.calculateLocalOrder();
        this.orderService.calculateOrder();

      }
    }
    this.updateQuantity(type, item, undefined);
  }

  closeCart() {
    let newUrl: any;
    if (this.itemsService.selectedAutoOrderItems.length === 0 && this.itemsService.selectedOrderItems.length === 0) {
      const subdomain = getSubdomain(window.location.host);
      if (subdomain) {
        newUrl = window.location.href.replace(subdomain, this.userService.WebAlias);
      }
      // window.location.assign(newUrl.replace('signup', 'checkout'));
      if (this.location.path().toLowerCase().indexOf('product') > -1) {
        this.cartService.checkoutPaymentButton = false;
        this.router.navigate(['/products']);
      }
      else if (this.location.path().toLowerCase() === '/enrollment') {
        this.router.navigate(['/enrollment']);
      }
    }
  }

  getLastQuantity(type, item) {
    this.lastQuantity = this.getQuantityModel(type, item)[item.ItemCode];
  }

  navigateToCheckout(){
    if(window.localStorage){

    }
    var customerDetail  = JSON.parse(window.localStorage.getItem('customerDetail')) || {};
    if(Object.keys(customerDetail).length > 0){
      this.router.navigate(['/checkout/shipping']);
    }
    else{
      this.router.navigate(['/login']);
    }
  }
}

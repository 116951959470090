import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SideNavBarService } from 'src/app/core/services/sidenavbar.service';
import { MarketSelectorComponent } from 'src/app/shared/model/market-selector/market-selector.component';
import { ProductSearchDialogComponent } from 'src/app/shared/model/productsearch/productsearch.component';
import { CartService } from 'src/app/core/services/cart.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { CartItem } from 'src/app/core/modals/cart-item';
import * as _ from 'lodash';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartSideMenuComponent } from '../shop/cart-side-menu/cart-side-menu.component';
import { Product } from 'src/app/core/modals/product.modal';
import { UserService } from 'src/app/core/services/user.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { ConfigService } from 'src/app/core/services/config.service';
import { AccountService } from 'src/app/core/authentication/account.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showCart = false;
  selectedCountry: any = {};
  public allowedCountries: Array<any> = [];
  show: string = 'no';
  @Output() sildeMobileNavEvent = new EventEmitter<any>();
  public currency: any;
  public flag: any;
  cartCount: number = 0;
  userService: UserServiceModal;
  constructor(
    public sideNavBarService: SideNavBarService,
    private dialog: MatDialog,
    public cartService: CartService,
    public productService: ProductService,
    public utilityService: UtilityService,
    public apiService: RestApiService,
    private route: ActivatedRoute,
    public user: UserService,
    public configService: ConfigService,
    public accountService: AccountService,
    private translate: TranslateService,
    public notificationService:NotificationService,
    private router: Router
  ) {

    //this.apiService.GetCountries().subscribe({next:data => {
    this.countryInitialization();
    //}, complete:() => { }});
    this.userService = user.userServiceModal;
    
  }

  countryInitialization(){
    this.allowedCountries = this.configService.allowedCountries;
    const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
    this.selectedCountry = (selectedCountry || 'US').toLowerCase();
    console.log('this.selectedCountry', this.selectedCountry);
    console.log('this.selectedCountry', sessionStorage.getItem('selectedCountry'));
  }

  ngOnInit() {
    this.accountService.ssoValdiatedSuccessfully.subscribe(response => {
      if(response){
        const selectedCountry = (sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry')) || 'us';
        const selectedLanguage = (sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'))) || { LanguageID: 0, CultureCode: 'en' };
        this.configService.setSelectedCountry(selectedCountry, selectedLanguage);
        this.countryInitialization();
      }
    });
  }

  openSideCart() {
    const dialogRef = this.dialog.open(CartSideMenuComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  SSOtoBackoffice(){
    const IsValidatedFromSSO = sessionStorage.getItem('IsValidatedFromSSO') === undefined ? null : sessionStorage.getItem('IsValidatedFromSSO');
    if(IsValidatedFromSSO) {
      window.location.href = environment.backOfficeUrl
    } 
    else {
      if(this.userService?.customerData?.RedirectURL){
        let redirectURL = this.userService.customerData.RedirectURL;
        window.location.href = redirectURL;
      }else{
        this.notificationService.error('Error', 'Something went wrong, Please login through Rain Office');
      }
    }

  }

  onProdSearch() {
    const dialogRef = this.dialog.open(ProductSearchDialogComponent, {
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
      localStorage.setItem("ProdSearchQuery", data.productName);
      window.location.reload();
    });
  }
  onSelectMarket() {
    const dialogRef = this.dialog.open(MarketSelectorComponent, {
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
    });
  }

  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  getQuanity() {
    var quantity = 0;
    _.each(this.productService.selectedPacks, (item) => {
      quantity += (item.Quantity ? Number(item.Quantity) : 0);
    });
    _.each(this.productService.selectedOrderItems, (item) => {
      if (item.ItemCode != 'DiscountCode') {
        quantity += (item.Quantity ? Number(item.Quantity) : 0);
      }
    });
    // _.each(this.productService.selectedAutoOrderItems, (item) => {
    //   quantity += (item.Quantity ? Number(item.Quantity) : 0);
    // });

    this.cartCount = quantity;
    return quantity;
  }

  @ViewChild('mobilecart') mobileCart: ElementRef;
  openDrawer() {
    if (this.cartCount > 0) {
      this.mobileCart.nativeElement.lastChild.style.backgroundColor = "#f44596";
    }
  }

  @ViewChild(MatMenuTrigger) loginMenuClosed: MatMenuTrigger;
  onLoginSuccess(event: any) {
    this.loginMenuClosed.closeMenu();
  }

  NavigateToLogin() {
    const webAlias = this.userService?.WebAlias || 'RainShop';
    window.open(`https://rainintl.com/${webAlias}/account/login`, "_blank");
  }

  getLanguageName(): string {
    const LanguageID = sessionStorage.getItem("selectedLanguageID") ?? "0";
    return this.translate.instant(`Language_${LanguageID}`) || "";
  }
  navigateLink(path: string) {
    switch (path) {
      case 'settings':
        this.router.navigate(['/accounts/settings']);
        break;
      case 'orders':
        this.router.navigate(['/accounts/orders']);
        break;
      case 'subscription':
        this.router.navigate(['/accounts/auto-order-list']);
        break;
      case 'address':
        this.router.navigate(['/accounts/address']);
        break;
      case 'paymentmethods':
        this.router.navigate(['/accounts/paymentmethods']);
        break;
      case 'logout':
        this.accountService.logout();
        break;
      default:
        break;
    }
  }
}

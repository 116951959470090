
<div class="parentMain">
    <a class="topclose" (click)="closeDialog()">
        <mat-icon class="mat-18">close</mat-icon>
    </a>
    <div class="side-box-top" fxLayout="row" fxLayoutAlign="space-between start">
        <h2 class="h2" translate="ShoppingCart"></h2>
        <a routerLink="/products">continue shopping</a>
    </div>
    <section class="b-top">
      <div class="free-shipping-progress" fxLayout="column" fxLayoutAlign="center center">
        <span *ngIf="remAmt > 0 && remAmt < 250" class="text-2"
            [textContent]="'You are away ' + (remAmt | currency: configService.selectedMarketConfig.CurrencySymbol) + ' from free shipping'"></span>
        <span *ngIf="remAmt == 0" class="text-2" translate="eligible_text"></span>
        <div class="progressBox">
            <div id="main-progress" #mainProgress [ngStyle]="{'width': freeShippingBar + '%'}"></div>
        </div>
    </div>
        <mat-divider></mat-divider>
        <div class="itemsList">
          <div class="item" fxLayout="row" fxLayoutAlign="space-between center"
          *ngFor="let item of getItems('order')">
          <div class="itemImage" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
              <img [default]="'../.././../../assets/images/noimage.png'"
               class="m-w-100" [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
          </div>
          <div class="mid-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around start">
              <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>

              <div fxLayout="row" fxLayoutAlign="center center">
                  <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('order', item)">
                      <mat-icon class="mat-18">remove</mat-icon>
                  </button>
                  <input class="bt " type="text" matInput
                  [(ngModel)]="getQuantityModel('order', item)[item.ItemCode]"
                                                      (change)="checkQuantity('order', item); closeCart()"
                                                      (keyup)="($event.which === 38) ? increaseQuantiy('order', item) : 0"
                                                      (keydown)="($event.which === 40) ? decreaseQuantiy('order', item) : 0 ; closeCart()"
                                                      (keypress)="getLastQuantity('order', item)"
                                                      (ngInit)="getQuantityModel('order', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                                                      validate inputonlynumber="true" maxlength="2" />
                  <button class="bt b-r-r s-bt" (click)="increaseQuantiy('order', item)">
                      <mat-icon class="mat-18">add</mat-icon>
                  </button>
              </div>
          </div>
          <div class="price-sect" fxFlex="40" fxLayout="column" fxLayoutAlign="space-around flex-end">
              <a (click)="removeFromCart('order', item)">
                  <mat-icon class="mat-18">close</mat-icon>
              </a>
              <div fxLayout="column" fxLayoutAlign="center flex-end">
                <span
                  [textContent]="(item['PriceType'+userService.customerTypeID+'Value'] * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                  class="text-2"></span>
              <span class="text-2">One Time</span>
              </div>
          </div>
      </div>
      <div class="item" fxLayout="row" fxLayoutAlign="space-between center"
      *ngFor="let item of getItems('autoship')">
      <div class="itemImage" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
          <img [default]="'../.././../../assets/images/noimage.png'"
           class="m-w-100" [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
      </div>
      <div class="mid-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around start">
          <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>

          <div fxLayout="row" fxLayoutAlign="center center">
              <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('autoship', item)">
                  <mat-icon class="mat-18">remove</mat-icon>
              </button>
              <input class="bt " type="text" matInput
              [(ngModel)]="getQuantityModel('autoship', item)[item.ItemCode]"
                                                  (change)="checkQuantity('autoship', item); closeCart()"
                                                  (keyup)="($event.which === 38) ? increaseQuantiy('autoship', item) : 0"
                                                  (keydown)="($event.which === 40) ? decreaseQuantiy('autoship', item) : 0 ; closeCart()"
                                                  (keypress)="getLastQuantity('autoship', item)"
                                                  (ngInit)="getQuantityModel('autoship', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                                                  validate inputonlynumber="true" maxlength="2" />
              <button class="bt b-r-r s-bt" (click)="increaseQuantiy('autoship', item)">
                  <mat-icon class="mat-18">add</mat-icon>
              </button>
          </div>
      </div>
      <div class="price-sect" fxFlex="40" fxLayout="column" fxLayoutAlign="space-around flex-end">
          <a (click)="removeFromCart('autoship', item)">
              <mat-icon class="mat-18">close</mat-icon>
          </a>
          <div fxLayout="column" fxLayoutAlign="center flex-end">
            <span class="text-2" style="text-decoration: line-through"
              [textContent]="(item.PriceType1Value * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
          <span class="text-2 itemPriceTxt"
              [textContent]="(item.PriceType2Value * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
          <span class="itemPriceTxt text-2">subscription price</span>
          </div>
      </div>
  </div>

            <!-- New section added for packs if they are added to cart -->
            <mat-divider *ngIf="getItems('pack').length > 0"></mat-divider>
            <div fxLayout="column" fxLayoutAlign="center start">
                <h2 translate="packs" class="pack-heading"
                    *ngIf="getItems('pack').length > 0"></h2>
                    <div class="item" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of getItems('pack')">

                      <div class="itemImage" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
                        <img [default]="'../.././../../assets/images/noimage.png'" class="m-w-100"
                          [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
                      </div>
                      <div class="mid-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around start">
                        <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>

                        <div fxLayout="row" fxLayoutAlign="center center">
                          <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('pack', item)">
                            <mat-icon class="mat-18">remove</mat-icon>
                          </button>
                          <input class="bt " type="text" matInput [(ngModel)]="getQuantityModel('pack', item)[item.ItemCode]"
                            (change)="checkQuantity('pack', item);"
                            (keyup)="($event.which === 38) ? increaseQuantiy('pack', item) : 0"
                            (keydown)="($event.which === 40) ? decreaseQuantiy('pack', item) : 0 ;"
                            (keypress)="getLastQuantity('pack', item)"
                            (ngInit)="getQuantityModel('pack', item)[item.ItemCode] ? getQuantityModel('pack', item)[item.ItemCode] : getQuantityModel('pack', item)[item.ItemCode] = 1"
                            validate inputonlynumber="true" maxlength="2" />
                          <button class="bt b-r-r s-bt" (click)="increaseQuantiy('pack', item)">
                            <mat-icon class="mat-18">add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div class="price-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around flex-end">
                        <a (click)="removeFromCart('pack', item)">
                          <mat-icon class="mat-18">close</mat-icon>
                        </a>
                        <span
                          [textContent]="(item.Price * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                          class="text-2"></span>

                      </div>
                    </div>
            </div>
        </div>
        <mat-divider *ngIf="getItems('pack').length == 0"></mat-divider>
        <div class="promoCodeBox">
          <div class="promo_code" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field class="coupon-chip-list" appearance="fill" fxFlex="70">
              <mat-chip-list #chipList fxLayout="row" fxLayoutAlign="start center">
                <input #coupon type="text" [attr.placeholder]="'ApplyCoupon' | translate" style="padding: 10px;"
                [matChipInputFor]="chipList" class="w-100" [ngClass]="{'couponVerified': verifiedCoupon}">
                <div fxLayoutAlign="end center" class="w-100">
                  <mat-chip *ngIf="verifiedCoupon != ''">
                    {{verifiedCoupon}}
                    <button matChipRemove (click)="removeCoupon()">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip>
                </div>
              </mat-chip-list>
            </mat-form-field>
            <button class="s-bt c-bt" fxFlex="20" translate="Apply" (click)="verifyCoupon(coupon.value)"></button>
          </div>
          <p id="promo-bottom-txt" translate="Onlyoneperorder"></p>
      </div>


        <div class="order_summary">
                <h4 class="text-1" translate="OrderSummary"></h4>
        </div>
        <!-- Subtotal Section -->
    <div class="subtotal" fxLayout="column" fxLayoutAlign="space-between" style="height: 100px">
        <div class="st" fxLayout="row" fxLayoutAlign="space-between center">
            <span [textContent]="'SUBTOTAL'" class="text-2"></span>
            <span [textContent]="sub | currency: configService.selectedMarketConfig.CurrencySymbol" class="text-2"></span>
        </div>
        <span class="bottom-tag text-2" translate="ShippingAndTaxCalculate"></span>
    </div>

        <!-- Subscription special section -->
        <div class="checkout-subscribe-order" *ngIf="getItems('autoship').length > 0">
          <mat-expansion-panel [expanded]="true" hideToggle="true">
            <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="center center" #exppanel >
              <mat-panel-title class="text-1" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon [textContent]="icon ? 'keyboard_arrow_down' : 'keyboard_arrow_up'" (click)="click()"></mat-icon>
                <span class="text-2" translate="autoship_" (click)="!exppanel._toggle()"></span>
              </mat-panel-title>
              <mat-panel-description fxLayout="row" fxLayoutAlign="end stretch" (click)="!exppanel._toggle()">
                <div fxFlex="10" fxLayoutAlign="end center" class="date-picker">
                  <input matInput [matDatepicker]="picker" [(ngModel)]="dateForSub">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div fxFlex="55" fxFlex.lt-sm="60" fxLayout="column" fxLayoutAlign="center center"
                  class="autoship-date">
                  <p [textContent]="'Nextorderdate' | translate" class="due-date"></p>
                  <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
                    <p [textContent]="(dateForSub | date: 'longDate') || ('Choose date' | translate)"></p>
                    <mat-icon class="arrow-date-picker">arrow_drop_down</mat-icon>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div class="item" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of getItems('autoship')">

                <div class="itemImage" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
                  <img [default]="'../.././../../assets/images/noimage.png'" class="m-w-100"
                    [src]="env.apiUrl + 'api/Images' + getImage(item)" alt="" />
                </div>
                <div class="mid-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around start">
                  <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>

                  <div fxLayout="row" fxLayoutAlign="center center">
                    <button class="bt b-r-l s-bt" (click)="decreaseQuantiy('autoship', item)">
                      <mat-icon class="mat-18">remove</mat-icon>
                    </button>
                    <input class="bt " type="text" matInput [(ngModel)]="getQuantityModel('autoship', item)[item.ItemCode]"
                      (change)="checkQuantity('autoship', item);"
                      (keyup)="($event.which === 38) ? increaseQuantiy('autoship', item) : 0"
                      (keydown)="($event.which === 40) ? decreaseQuantiy('autoship', item) : 0 ;"
                      (keypress)="getLastQuantity('autoship', item)"
                      (ngInit)="getQuantityModel('autoship', item)[item.ItemCode] ? getQuantityModel('order', item)[item.ItemCode] : getQuantityModel('order', item)[item.ItemCode] = 1"
                      validate inputonlynumber="true" maxlength="2" />
                    <button class="bt b-r-r s-bt" (click)="increaseQuantiy('autoship', item)">
                      <mat-icon class="mat-18">add</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="price-sect" fxFlex="30" fxLayout="column" fxLayoutAlign="space-around flex-end">
                  <a (click)="removeFromCart('pack', item)">
                    <mat-icon class="mat-18">close</mat-icon>
                  </a>
                  <span
                    [textContent]="(item.PriceType2Value* item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                    class="text-2"></span>

                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>

    </section>

    <!-- Related Products -->
<h2 class="rel-h" translate="OthersAlsoPurchased"></h2>
<mat-divider class="m-d-w"></mat-divider>

<div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="20px" fxLayoutAlign="start stretch"
    style="padding: 20px;">
    <div class="item rel" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px"
        *ngFor="let item of relatedProducts" fxFlex="33">
        <div class="itemImage br" fxLayout="column" fxLayoutAlign="center center">
            <span [textContent]="item.ItemDescription" class="text-1 lowercase"></span>
            <img [default]="'../.././../../assets/images/noimage.png'"
             class="m-w-100" [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt=""
                (click)="GoToProduct(item)" />
            <button class="rel_btn" style="margin-top: 10px" (click)="quickView(item)"  translate="QuickView">
            </button>
        </div>
        <div class="br" fxLayout="column" fxLayoutAlign="flex-start center">
            <div class="text-2 prod-sub"  [textContent]="'Best Product in Market'">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <span [textContent]="item.PriceType1Value | currency: configService.selectedMarketConfig.CurrencySymbol"
                        style="font-weight: 600"></span>
                    <button class="btn btn-b"  (click)="addToCart('autoship', item)" translate="SubscribeToSave"></button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <span
                        [textContent]="item.PriceType2Value | currency: configService.selectedMarketConfig.CurrencySymbol"></span>
                    <button class="btn btn-w" (click)="addToCart('order', item)">One Time</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div fxLayout="column" class="container" style="padding:20px;" >
  <div fxLayout="row" class="mb-15">
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <button mat-stroked-button class="mat-stroked-button"
        printSectionId="invoice-section" [useExistingCss]="true" ngxPrint>
        <mat-icon>print</mat-icon>
        <span translate="Print"></span>
      </button>
      <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
    </div>
  </div>
  <div id="invoice-section">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center" fxLayoutAlign="space-between center"
      class="">
      <div  fxFlex="30" fxFlex.lt.xs="100" fxFlex.sm="25" fxFlex.md="20" fxFlex.lg="20"
        fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start"  fxLayout="column">
        <img class="invoice_logo" width="100%" height="100%" [src]="logoImageUrl" alt="">
      </div>
      <div fxFlex="25" fxFlex.lt.sm="100" fxFlex.sm="45" fxFlex.md="35" fxFlex.lg="35" fxLayoutAlign="center end"
        fxLayoutAlign.lt-sm="center start" fxLayout="column" class="">
        <h2 class="inv_heading" translate="Invoice"></h2>
        <h3 class="f-s-25" translate="GlobalCompanyTitle"></h3>
        <p class="inv_address"> 1365W Business Park Drive Ste. 100. Orem, UT 84058 United States</p>
        <p translate="Phone"><span>: 855-724-6606</span> </p>
        <p translate="Email"><span>: support@rainintl.com</span> </p><br>
      </div>
    </div>
    <mat-card class="mb-20">
      <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 class="f-w-700" translate="to"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 class="mb-15 f-w-700" [textContent]="OrderData?.Order?.Recipient?.FullName"></h4>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 translate="PickupName"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 class="mb-15"
                [textContent]="OrderData?.Order?.Recipient?.FirstName + ' ' + OrderData?.Order?.Recipient?.LastName">
              </h4>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 class="mb-15" translate="PhoneNumber"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 class="mb-15" [textContent]="OrderData?.Order?.Recipient?.Phone"></h4>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 class="mb-15" translate="Email"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 class="mb-15 text-align" [textContent]="OrderData?.Order?.Recipient?.Email"></h4>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100 ">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 class="mb-15" translate="Address"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 class="mb-15"
                [textContent]="OrderData?.Order?.Recipient?.Address1 + ' ' + (OrderData?.Order.Recipient?.Address2 || '' ) + ' ' + (OrderData?.Order?.Recipient?.Address3 || '') +', '+ OrderData?.Order?.Recipient?.State + ' ' + OrderData?.Order?.Recipient?.Zip + ' ' + OrderData?.Order?.Recipient.Country">
              </h4>
            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="35" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.md="35">
              <h4 class="f-w-700" translate="PaymentInfo"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50" fxFlex.md="55">
              <h4 [textContent]="OrderData?.Order?.Recipient?.FullName"></h4>
            </div>
          </div>
        </div>
        <div fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
          <!-- <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="50" fxFlex.lt-sm="30" fxFlex.lt-md="30" fxFlex.lt.xs="100" fxFlex.sm="30">
              <h4 class="mb-15 f-w-700" translate="OrderInfo"> </h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50">
              <h4 class="mb-15 f-w-700" translate="AutoOrder"></h4>
            </div>
          </div> -->
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100 ">
            <div fxFlex="50" fxFlex.lt-sm="30" fxFlex.lt-md="30">
              <h4 class="mb-15" translate="OrderNo"></h4>
              <h4 class="mb-15" translate="Date"></h4>
              <h4 class="mb-15" translate="RainID"></h4>
              <h4 class="mb-15" translate="AccountName"></h4>
              <h4 class="mb-15" translate="ShipMethod"></h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50">
              <h4 class="mb-15" [textContent]="(type == 'Order' ? OrderData?.OrderID : OrderData?.AutoOrderID)"></h4>
              <h4 class="mb-15" [textContent]="(type == 'Order' ? OrderData?.Order?.OrderDate : OrderData[type]?.StartDate) | date : 'longDate' "></h4>
              <h4 class="mb-15" [textContent]="OrderData?.CustomerID"></h4>
              <h4 class="mb-15"
                [textContent]="OrderData?.Order?.Recipient?.FirstName +' ' + OrderData?.Order?.Recipient?.LastName ">
              </h4>
              <h4 class="mb-15" [textContent]="OrderData?.Order?.ShipMethodName"></h4>

            </div>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="space-between start"
            class="w-100">
            <div fxFlex="50" fxFlex.lt-sm="30" fxFlex.lt-md="30">
              <h4 class="mb-15" translate="OrderType"> </h4>
            </div>
            <div fxFlex="50" fxFlex.lt-sm="60" fxFlex.lt-md="50">
              <h4 class="mb-15" [textContent]="'OrderType_' + ((type == 'Order' ? OrderData[type]?.OrderTypeID : OrderData[type]?.AutoOrderPaymentTypeID))" translate>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <div class="printmedia">
      <div fxLayout="row" >
        <div fxFlex="100" class="">
          <section>
            <div>
              <table cellpadding="0" cellspacing="0" class=" border table-responsive ">
                <thead class="tbl-header">
                  <tr>
                    <th class="t-a-l" translate="SKU"></th>
                    <th class="t-a-l" translate="ProductDescription"></th>
                    <th class="t-a-l" translate="Quantity"></th>
                    <th class="t-a-l" translate="Volume"></th>
                    <th class="t-a-l" translate="Price"></th>
                    <th class="t-a-l" translate="GrandTotal"></th>
                  </tr>
                </thead>
                <tbody class="tbl-content">
                  <tr *ngFor="let orderdetail of OrderData[type]?.Details">
                    <td class="t-a-l" [textContent]="orderdetail?.ItemCode"></td>
                    <td class="t-a-l"  [textContent]="orderdetail?.ItemDescription"></td>
                    <td class="t-a-l" [textContent]="orderdetail?.Quantity"></td>
                    <td class="t-a-l" [textContent]="orderdetail?.CV"></td>
                    <td class="t-a-l" [textContent]="orderdetail?.PriceEach | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')"></td>
                    <td class="t-a-l" [textContent]="orderdetail?.PriceTotal | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')"></td>
                  </tr>
                  <tr class="dtr">
                    <td colspan="4"></td>
                    <td class="t-a-l" translate="Subtotal"></td>
                    <td class="t-a-l" [textContent]="OrderData[type]?.Subtotal | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')">
                    </td>
                  </tr>
                  <tr class="dtr">
                    <td colspan="4"></td>
                    <td class="t-a-l" translate="Shipping"></td>
                    <td
                    class="t-a-l" [textContent]="OrderData[type]?.ShippingTotal | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')">
                    </td>
                  </tr>
                  <tr class="dtr">
                    <td colspan="4"></td>
                    <td class="t-a-l" translate="Tax"></td>
                    <td class="t-a-l" [textContent]="OrderData[type]?.TaxTotal | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')">
                    </td>
                  </tr>
                  <tr class="dtr">
                    <td colspan="4"></td>
                    <td class="t-a-l"> <strong   translate="Total"></strong></td>
                    <td  class="t-a-l"><strong
                       [textContent]="OrderData[type]?.Total | currency: (OrderData[type]?.CurrencyCode + ' ' || '$ ')"></strong>
                    </td>
                  </tr>
                  <tr *ngIf="false" class="dtr">
                    <td colspan="4"></td>
                    <td> <strong translate="amount_paid"></strong></td>
                    <td><strong>$0.00</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="top-row mb-15">
      <h3 class="return" translate="return_policy"></h3>
      <p translate="refund_policy_desc"></p>
    </div>
  </div>
</div>

import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import {
  ActivationStart,
  Event,
  NavigationEnd,
  NavigationExtras,
  ResolveEnd,
  Router,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { RestApiService } from './core/http/restapi.service';
import { CartService } from './core/services/cart.service';
import { UserService } from './core/services/user.service';
import { UtilityService } from './core/services/utility.service';
import { AccountService } from './core/authentication/account.service';
import { NotificationService } from './core/services/notification.service';
import { UserServiceModal } from './core/modals/userservice.modal';
import { ConfigService } from './core/services/config.service';
import { ProductService } from './core/services/product.service';
import { Location } from '@angular/common';
import { interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/model/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isShow: boolean;
  topPosToStartShowing = 100;
  isCookieSelected: any;
  userService: UserServiceModal;
  currentUrl:  string;
  constructor(
    private apiService: RestApiService,
    private utilityService: UtilityService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private user: UserService,
    private router: Router,
    private cartService: CartService,
    private configService: ConfigService,
    public accountService: AccountService,
    public notificationService: NotificationService,
    public itemsService: ProductService,
    private location: Location,
    private dialog: MatDialog
  ) {

    /** --------------------------------------------------------------
     *  
     --------------------------------------------------------------*/

     try {
        if(window.location.pathname.includes("/account/login")) {
          this.router.navigate(['/login']);
        }
     } catch (error) {
      
     }

    /** ----------------------------------------------------------*/

    if(this.accountService.isLoggedIn()) {
      this._checkAccountLogout_Cookie();
    }

    /** ----------------------------------------------------------*/

    this.userService = this.user.userServiceModal;
    document.documentElement.style?.setProperty('--theme-default', '#387D55');
    document.documentElement.style?.setProperty('--theme-dark', '#161616');
    if (sessionStorage.getItem('selectedLanguage')) {
      var lang = JSON.parse(
        sessionStorage.getItem('selectedLanguage') ||
          '{"LanguageID":1,"CultureCode":"en"}'
      );
      translate.setDefaultLang(lang.CultureCode);
      translate.use(lang.CultureCode);
    } else {
      translate.setDefaultLang('en');
      translate.use('en');
    }
    if (
      sessionStorage.getItem('exigosessionId') == 'null' ||
      !sessionStorage.getItem('exigosessionId')
    ) {
      this.utilityService.createExigoSession();
    }

    /**
     * It will Re-Check If Customer Data is present
    */
    try {
      if(cookieService.get('X-Auth')){
        const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        if(userDetails.CustomerID && !this.userService.customerData.CustomerID){
          this.userService.customerData = userDetails;
        }
        if(!this.userService.customerData.CustomerID){
          this.accountService.logout();
        }
      }
    } catch(ex) {}
  }
  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    localStorage.setItem('userService', JSON.stringify(this.userService));
    // return false;
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  cookiesAccepted() {
    this.isCookieSelected = true
    this.cookieService.set("cookieAccepted", "true", undefined, "/")
  }

  ngOnInit(): void {

    this.isCookieSelected = this.cookieService.get('cookieAccepted') || false;

    if(window.location.href.includes('SSOtoken')){
      // After SSO Check, Fetching Credit, Wallet and Cash Account Balance
      this.accountService.ssoValdiatedSuccessfully.subscribe(response => {
        if(response){
          this.fetchPoints();
        }
      });
    } else {
      setTimeout(() => {
        this.accountService.isLoggedIn() && this.fetchPoints();
      }, 2000);
    }

    if((!this.accountService.isLoggedIn() && !(location.href.includes('/login')))){
      this.configService.showInitialMarketSelector.emit();
    }
    
    this.router.events.subscribe((ev: Event) => {
      if (ev instanceof NavigationEnd ) {
        this.currentUrl = ev.url;
      }
      // activation start
      if (ev instanceof ActivationStart) {
        if (Object.keys(ev.snapshot.queryParams).includes("SSOtoken")) {
          let parameters: any = ev.snapshot.queryParams;
          let token = parameters?.SSOtoken;
          this._handleSSOValidation(token, false);
        }
        else if ((location.href.includes('/login') || location.href.includes('/silentlogin')) && Object.keys(ev.snapshot.queryParams).includes("token")) {
          let parameters: any = ev.snapshot.queryParams;
          let token = parameters?.token;
          this._handleSSOValidation(encodeURIComponent(token), true);
        }
      }
      // resolve end
      if (ev instanceof ResolveEnd) {
        let url = ev.urlAfterRedirects;
        // if (window.localStorage && (localStorage.getItem('sessionId') != 'null' && localStorage.getItem('sessionId'))) {
        //   this.utilityService.setStats(ev, this.userService.userServiceModal.WebAlias);
        // }
        if (url == '/enrollment/checkout') {
          this.cartService.enrollmentPaymentButton = true;
        } else {
          this.cartService.enrollmentPaymentButton = false;
        }
        if (url == '/checkout' || url == '/review') {
          this.cartService.checkoutPaymentButton = true;
        } else {
          this.cartService.checkoutPaymentButton = false;
        }
      }
      this.gotoTop();
      if (!(ev instanceof NavigationEnd)) {
        return;
      }
      try {
        let pathArray = ev.urlAfterRedirects.split('/');
        let pageName = pathArray[pathArray.length - 1];
        var data = JSON.stringify({
          pageName: pageName,
          pageURL: location.href,
        });
        window['ZTM'].setStats('RainIntl', 'PageView', data);
      } catch (error) {}
    });
  }

  fetchPoints(){
    const countryCode = sessionStorage.getItem('selectedCountry') || this.configService.commonData.selectedCountry || 'us';
    // Fetch Point Account Ids from market configuration
    this.apiService.getMarketConfig(countryCode).subscribe({
        next: (response) => {

          const _MarketConfigurations: any = response.Data;
          let PointAccountIds = {};
    
          PointAccountIds['credit'] = _MarketConfigurations?.RPTPointAccountID;
          PointAccountIds['wallet'] = _MarketConfigurations?.RainWalletPointAccountID;
          PointAccountIds['cash-account'] = _MarketConfigurations?.RainCashPointAccountID;
    
          // this.userService.customerData['UserCashAccount'] = { 'Balance': 452.65 };

          console.log(PointAccountIds);

          // Setting Point Account Balace in userService for logged in customer.

          if (PointAccountIds['credit'] > 0) {
            this.setPointAccountDetails(PointAccountIds['credit'], 'credit');
          }
          if (PointAccountIds['wallet'] > 0) {
            this.setPointAccountDetails(PointAccountIds['wallet'], 'wallet');
          }
          if (PointAccountIds['cash-account'] > 0) {
            this.setPointAccountDetails(PointAccountIds['cash-account'], 'cash-account');
          }
        },
        error: (error) => {
          
        },
        complete: () => { }
      });
  }

  setPointAccountDetails(pointAccountID: number, accountType: string): any {
    try {
      this.apiService.GetCustomerPointAccount(pointAccountID).subscribe({
        next: (response) => {
          const data = (response?.Data || {});
          if(accountType == 'credit'){
            this.userService.customerData['UserCreditAccount'] = data;
          }
          if(accountType == 'wallet'){
            this.userService.customerData['UserWalletAccount'] = data;
          }
          if(accountType == 'cash-account'){
            this.userService.customerData['UserCashAccount'] = data;
          }
        },
        error: (error) => {}
      })
    } catch(err) {

    }
    return {};

  }

  // ValidateSSOToken(token) {
  //   try {
  //     this.apiService.ValidateSSOToken(token).subscribe((response: any) => {
  //       if (response.Data && response?.Message == "Success") {

  //         // IsValidatedFromSSO
  //         sessionStorage.setItem('IsValidatedFromSSO', 'true');
	  
  //         localStorage.setItem('isLoggedIn', 'true');
  //         this.cookieService.set('X-Auth', (response.Data?.access_token), new Date().getSeconds() + 10, '/');
  //         let url = response.Data.RedirectUrl;
  //         this.accountService.getCustomerData().then((data: any) => {
  //           if (data && data.Data) {
  //             localStorage.setItem('user', JSON.stringify(data.Data));
  //             this.userService.customerData = data.Data;
  //             this.userService.customerData.RedirectURL = url;
  //             this.userService.customerTypeID = this.userService.customerData.CustomerType;

  //             if (!this.utilityService.isEmptyObject(this.userService.customerData.DefaultShippingAddress)) {
  //               this.user.setShippingAddress();
  //             } else {
  //               this.userService.shippingAddress = new UserServiceModal().newshippingAddress;
  //             }
  //             if (this.userService.customerData.WebAlias) {
  //               this.apiService.validateWebAlias(this.userService.customerData.WebAlias).subscribe({
  //                 next: (res: any) => {
  //                   if (res.Data) {
  //                     this.userService.WebAlias = this.userService.customerData.WebAlias;
  //                     this.redirect(this.userService.WebAlias);
  //                   }
  //                 }, error: (error) => {
  //                   if (error.Data && !error.Data.WebAlias) {
  //                     this.notificationService.error('Error', 'InvalidWebAlias');
  //                     return false;
  //                   }
  //                 },
  //                 complete: () => {
  //                   // Do stuff after completion
  //                 }
  //               });
  //             }
  //             else if (this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID) {
  //               this.apiService.getSearchCustomerDetail(this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID).subscribe({
  //                 next: (resp: any) => {
  //                   if (resp.Data) {
  //                     this.apiService.validateWebAlias(resp.Data.WebAlias.WebAlias).subscribe({
  //                       next: (res: any) => {
  //                         if (res.Data) {
  //                           this.userService.WebAlias = resp.Data.WebAlias.WebAlias;
  //                           this.redirect(this.userService.WebAlias);
  //                         }
  //                       }, error: (error) => {
  //                         if (error.Data && !error.Data) {
  //                           this.notificationService.error('Error', 'InvalidWebAlias');
  //                           return false;
  //                         }
  //                       },
  //                       complete: () => {
  //                         // Do stuff after completion
  //                       }
  //                     });
  //                   }
  //                 }, error: (error) => {
  //                   if (error.Data && !error.Data.WebAlias) {
  //                     this.notificationService.error('Error', 'InvalidWebAlias');
  //                     return false;
  //                   }
  //                 },
  //                 complete: () => {
  //                   // Do stuff after completion
  //                 }
  //               });
  //             }
  //           } else {
  //             this.notificationService.error('Error', 'OopsSomethingWentWrong');
  //           }
  //         });
  //       } else {
  //         this.notificationService.error('Error', 'OopsSomethingWentWrong');
  //       }
  //     })
  //   } catch (error) {
  //     this.notificationService.error('Error', error);
  //   }
  // }

  private redirect(alias) {
    let newUrl: any;
    newUrl = `${location.origin}/${alias}/products`;
    if (this.checkitem()) {
      this.userService.checkItems = true;
      window.location.assign(newUrl.replace('login', 'review'));
    }
    else if (this.itemsService.selectedAutoOrderItems.length) {
      window.location.assign(newUrl.replace('login', 'products'));
    } else {
      window.location.assign(newUrl.replace('login', 'products'));
    }
  }

  checkitem() {
    return (this.itemsService.selectedOrderItems.length > 0 || this.itemsService.selectedAutoOrderItems.length > 0);
  }

  // SSO Redirection from Back Office

  _handleSSOValidation(token: any, isAdmin: boolean = false) {
    try {
      var methodName = isAdmin ? 'AdminSilentLogin' : 'ValidateSSOToken';
      this.apiService.ValidateSSOToken(token, methodName).subscribe((response: any) => {
        if (response.Data && response?.Message == "Success") {

          sessionStorage.setItem('initalMarketSelectorPopupOpened', 'true');
          
          // IsValidatedFromSSO
          sessionStorage.setItem('IsValidatedFromSSO', 'true');

          localStorage.setItem('isLoggedIn', 'true');

          const expiration = new Date();
          expiration.setMinutes(expiration.getMinutes() + 30);
          
          this.cookieService.set('X-Auth', (response.Data?.access_token), expiration, '/');
          this.cookieService.set('authenticationExpirationDate', (expiration.toString()), expiration, '/');

          let url = response.Data.RedirectUrl;

          let PrefferedLanguage = null;

          if(response.Data?.Identity?.Language?.LanguageID) {
            PrefferedLanguage = response.Data?.Identity?.Language;
          }

          this.accountService.getCustomerData(PrefferedLanguage).then((data: any) => {
            if (data && data.Data) {
              localStorage.setItem('user', JSON.stringify(data.Data));
              this.userService.customerData = data.Data;
              this.userService.customerData.RedirectURL = url;
              this.userService.customerTypeID = this.userService.customerData.CustomerType;
              localStorage.setItem('userService', JSON.stringify(this.userService));

              if (!this.utilityService.isEmptyObject(this.userService.customerData.DefaultShippingAddress)) {
                this.user.setShippingAddress();
              } else {
                this.userService.shippingAddress = new UserServiceModal().newshippingAddress;
              }

              /**
             */

            const sponsorID = this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID || 0;
            if(sponsorID > 0) {
              this.apiService.getSearchCustomerDetail(sponsorID)
              .subscribe((result) => {
                let { Customer, WebAlias } = result && result.Data;
                let EnrollerInfo = Customer, EnrollerWebAlias = WebAlias;
                
                // set enroller info
                if(EnrollerInfo) {
                  this.userService.enrollerInfo = Customer;
                }

                // Page With New Configurations
                // window.location.href = window.location.origin + window.location.pathname;

                // Trigger an event after successfull SSO validation

                let URLpath = this.location.path();
                if(!URLpath.includes('/products')){
                  this.router.navigateByUrl('/products');
                } else {
                  const urlTree: UrlTree = this.router.parseUrl(URLpath);
                  const tree: UrlTree = this.router.createUrlTree([], { queryParams: { ...urlTree.queryParams, SSOtoken: null }, fragment: urlTree.fragment });
                  const navigationExtras: NavigationExtras = { queryParamsHandling: "merge", preserveFragment: true, relativeTo: null };
                  this.router.navigateByUrl(tree, navigationExtras);
                }

                this.accountService.ssoValdiatedSuccessfully.emit(true);
 
              })
            }


            /**
             */   

              
              
            } else {
              this.notificationService.error('Error', 'OopsSomethingWentWrong');
            }
          });
        } else {
          this.notificationService.error('Error', 'OopsSomethingWentWrong');
        }
      })
    } catch (error) {
      this.notificationService.error('Error', error);
    }
  }

  _checkAccountLogout_Cookie() {

    const checkInterval = interval(60000); // Check every minute (60000 milliseconds)
    const intervalSubscription = checkInterval.subscribe(() => {
      const expirationTime = new Date(this.cookieService.get('authenticationExpirationDate')); // Get the expiration time from the cookie
      const currentTime = new Date();

      console.log("authentication Expiration Subscribed");

      if (expirationTime.getTime() - currentTime.getTime() <= 150000) { // 2:30 minute
        intervalSubscription.unsubscribe();
        console.log("authentication Expiration Subscribed");

        const XAuthValue = this.cookieService.get('X-Auth');
        if(XAuthValue) { // Just to make sure, we still have token!
          this._showAccountLogoutPopup(XAuthValue);
        }
      }
    });
  }

  _showAccountLogoutPopup(XAuthValue) {

    const dialogData = new ConfirmDialogModel(
      this.translate.instant('LoginSessionAlertTitle'),
      this.translate.instant('LoginSessionAlertDescription'),
      this.translate.instant('No'),
      this.translate.instant('Yes')
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
      panelClass: '',
    });

    dialogRef.afterClosed().subscribe((res) => {
      
      if(res == true) { // Continue Session
        const expiration = new Date();
        expiration.setMinutes(expiration.getMinutes() + 30);
        this.cookieService.set('X-Auth', (XAuthValue), expiration, '/');
        this.cookieService.set('authenticationExpirationDate', (expiration.toString()), expiration, '/');
      } else {
        // Close Session
        this.accountService.logout();
      }

    });


  }

}

<div class="loginmenubox">
  <div fxLayout="column" >
    <div fxLayout="row" (click)="$event.stopPropagation();" class="p-t-5 margin-left">
      <mat-icon>lock_open</mat-icon>
      <h3 translate="Global_LoginToYourAccount"></h3>
    </div>
    <div fxLayout="column" (click)="$event.stopPropagation();" class="p-all">
      <label translate="Username"></label>
      <mat-form-field appearance="outline" class="lm_input">
        <input name="username" [(ngModel)]="userInfo.LoginName" [ngModelOptions]="{standalone: true}" matInput validate
          noempty="true">
      </mat-form-field>
    </div>
    <div fxLayout="column" (click)="$event.stopPropagation();" class="p-all">
      <label translate="Password"></label>
      <mat-form-field appearance="outline" class="lm_input">
        <input type="password" [(ngModel)]="userInfo.Password" [ngModelOptions]="{standalone: true}" matInput validate
          noempty="true">
      </mat-form-field>
      <button mat-stroked-button class="mb-10 padding" validate trigger-check="true" type="submit" (click)="loginFunction()"
        ><span translate="SignIn" class="SignIn_text"></span></button>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center"  class="p-all p-t-5">
      <div fxFlex="80" fxLayoutAlign="end center">
        <a routerLink="/pages/forgotpassword" >
          <span class="forgot-pswd mb-20 btn_links" fxLayoutAlign="center center">
            <mat-icon class="password">lock</mat-icon><span translate="ForgotPassword" style="font-size: 16px;"></span>
          </span>
        </a>
      </div>
    </div>
    <!-- <mat-divider class="mb-15"></mat-divider>
    <div fxLayout="row" class="btn_links margin-left mb-10" routerLink="/signup">
      <mat-icon class="padding-right">person</mat-icon>
      <strong fxLayoutAlign="start center" translate="Replicated_Login_NewAccount" class="text"></strong>
    </div> -->
  </div>
</div> 
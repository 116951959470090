
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
export class RewardDialogModel {
  constructor(public title: string, public message: any, public takeaction?: string, public isOTPdialog?: boolean) {
  }
}
@Component({
  selector: 'app-reward-dialog',
  templateUrl: './reward-dialog.component.html',
  styleUrls: ['./reward-dialog.component.scss']
})
export class RewardDialogComponent implements OnInit {
  title: string;
  message: any;
  noaction: string;
  takeaction: string;
  otpcode: any;
  email: string;
  isOTPdialog: boolean;
  userService: UserServiceModal;
  invalidCode: boolean = false;
  public show: boolean = false;
  countDownDate: any;
  time: any;
  disableResend: boolean = false;
  emailDisable: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<RewardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RewardDialogModel,
    public user: UserService,
    public notificationService: NotificationService,
    public apiService: RestApiService
    ) {
    this.userService = user.userServiceModal;
    this.title = data.title;
    this.message = data.message;
    this.takeaction = data.takeaction;
    this.isOTPdialog = !!data.isOTPdialog;
    this.email = this.userService.shippingAddress.Email;
    if(this.email){
      this.emailDisable = true;
    }
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    if(this.isOTPdialog){
      this.validateOTP(this.userService.shippingAddress.Phone, this.otpcode);
    }
    else{
      this.dialogRef.close(true);
    }
  }

  sendOtpEmail() {
    let req = {
      mobileNumber: this.userService.shippingAddress.Phone,
      email: this.email
    }
    if (this.userService.shippingAddress.Phone && this.email) {
      this.apiService.sendOTPMessage(req).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            this.show = !this.show;
            this.email = '';
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }

  toggleViewEmail() {
    this.show = !this.show
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  validateOTP(phonenum: string, otp: any) {
    let req = {
      mobileNumber: phonenum,
      code: otp
    }
    if (this.userService.shippingAddress.Phone) {
      this.apiService.validateOTP(req).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            this.userService.IsshowPromotionOption = false;
            this.dialogRef.close(true);
          }
          else{
            this.notificationService.error('error', 'Coupon_Code_errormsg');
            this.otpcode = '';
            this.invalidCode = true;
            this.disableResend = true;
            this.countDownDate = new Date().setSeconds(new Date().getSeconds() + 30);
            var interval = setInterval(() => {
              var now = new Date().getTime();
              var timeleft = this.countDownDate - now;
              this.time = (Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)) + ':' + Math.floor((timeleft % (1000 * 60)) / 1000)) + 's';
              if (timeleft <= 0) {  
                clearInterval(interval);
                this.disableResend = false;
                this.time = ''
              }
            }, 1000);
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }

  resendOTP(phonenum: any) {
    if(this.disableResend){
      return;
    }
    let req = {
      mobileNumber :phonenum
    }
    if (this.userService.shippingAddress.Phone) {
      this.apiService.sendOTPMessage(req).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            this.invalidCode = false;
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }

  matDialogClose(){
    this.userService.IsshowPromotionOption = true;
  }

}



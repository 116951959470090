<div fxLayout="column" fxFlex="100" fxLayoutAlign="start stretch" class="reward-dialog mat-dialog-container">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end center" class="p-r-10 cursor-pointer mt-10">
    <mat-icon mat-button mat-dialog-close class="close-icon c-black" (click)="matDialogClose()">close</mat-icon>
  </div>

  <div fxLayout="column" class="ms-padding">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="center">
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isOTPdialog" class="card1">
        <div fxLayout="column" class="shareGoodSection">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center" class="mt-10">
            <div fxFlex="40" fxFlex.md="48" fxFlex.sm="52" fxFlex.xs="40" fxLayoutAlign="start center">
              <div><img src="../../../../assets/images/Group 23.png" alt="start1" class="image1"></div>
            </div>
          </div>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <div fxLayout="row">
              <div><img src="../../../../assets/images/Group 24.png" alt="start2" class="image2 start1"></div>
            </div>
            <div fxLayout="row">
              <h2 class="m-0 f-s-15 mat-dialog-content mat-drawer-dialog-container">
                <p id="message1" class="c-black f-s-40" [innerHTML]="message.key1"></p>
              </h2>
            </div>
            <div fxLayout="row">
              <div><img src="../../../../assets/images/Group 22.png" alt="start3" class="image3 start2"></div>
            </div>
          </div>
        </div>
        <img src="../../../../assets/images/Group 23.png" alt="img1" class="image4">

        <h2 class="m-0 f-s-15 mat-dialog-content">
          <p id="message2" class="c-black f-s-24 f-s-20"
            [innerHTML]="message.key2 | translate : {webalias: userService.WebAlias}"></p>
        </h2>
        <h2 class="m-t-10 f-s-15 mat-dialog-content">
          <p id="message3" class="c-black f-s-18">
            <a class="click_transform lowercase" translate="ClickHere" (click)="onConfirm()"></a>
            <t [innerHTML]="' ' + message.key3"></t>
          </p>
        </h2>
      </div>

      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start" *ngIf="isOTPdialog" class="mt-20">
        <h2 id="message2" class="m-0 f-s-40 c-black message mat-dialog-content f-s-30" [innerHTML]="message.key1">
        </h2>
      </div>
      <div fxFlex="100" fxLayout="row" fxFlex="100" fxLayoutAlign="center center" *ngIf="isOTPdialog" class="mt-5">
        <mat-form-field class="border w-50 exigo-validate" fxFlex.xs="100" *ngIf="!show">
          <mat-label translate=""></mat-label>
          <input type="text" placeholder="XXXX" name="fname" [maxlength]="6" otpSection="true" matInput validate
            inputonlynumber="true" noempty="true" validname="true" [(ngModel)]="otpcode" autocomplete="given-name">
        </mat-form-field>

        <mat-form-field class="exigo-validate" appearance="outline" fxFlex="80" fxFlex.xs="100" *ngIf="show">
          <mat-label translate="Email"></mat-label>
          <input matInput validate noempty="true" email="true" emailOtpSection="true" [disabled]="emailDisable"
            [(ngModel)]="email" name="email">
        </mat-form-field>
        <span class="c-black textinline" [textContent]="time"></span>
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" mat-dialog-actions class="m-0 pay-last-button" fxLayoutAlign="center space-evenly"
      fxLayoutGap="15px">
      <span *ngIf="isOTPdialog" class="f-s-18 c-black message mat-dialog-content f-s-30"
        [innerHTML]="'promo_valid_msg' | translate">
      </span>
      <a *ngIf="isOTPdialog && !show" sectiontype="otpSection" validate trigger-check-section="true"
        (success)="onConfirm()" class="c-black click_transform f-s-18" id="button2">{{takeaction}}</a>

      <a *ngIf="isOTPdialog && show" sectiontype="emailOtpSection" validate trigger-check-section="true"
        class="c-black click_transform f-s-18" id="button2" (success)="sendOtpEmail()" translate="Send"></a>
      <a *ngIf="isOTPdialog && invalidCode" [disabled]="disableResend" id="button2"
        (click)="resendOTP(userService.shippingAddress.Phone)" class="c-black f-s-18 click_transform mt-15"
        [ngClass]="{'opacity-5': disableResend}" translate="Resend"></a>
      <h2 class="m-t-10 f-s-15 mat-dialog-content" *ngIf="isOTPdialog">
        <p id="message3" class="c-red f-s-18 mt-3">
          <t translate="DontReceiveCode"></t>
          <a class="click_transform lowercase" translate="ClickHere" (click)="toggleViewEmail()"></a>
        </p>
      </h2>
    </div>
  </div>
</div>
// loader-interceptor.service.ts
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];
    constructor(public router: Router, public cookieService: CookieService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const reloadWithDefaultLanguage = () => {
            sessionStorage.setItem('selectedLanguage', JSON.stringify({ LanguageID: 0, CultureCode: 'en' }));
            sessionStorage.setItem('local_ID', 'en');
            // window.location.reload();

            const url = new URL(window.location.href);
            if(url && url.searchParams.get("language")){
                url.searchParams.delete("language");
                history.pushState(null, "");
            }
            window.location.href = url.href;
        }

        try{
        return next.handle(req).pipe(tap(
            () => {

         }, (err) => {
            
            /* Error Block */
            
            if (err instanceof HttpErrorResponse) {
                
                if (Number(err.status) === 401) {
                    
                    this.router.navigate(['/login']).then(() => {
                        if (window.sessionStorage) {
                            window.localStorage.removeItem('user');
                            const cookieAcceptedStatus = this.cookieService.get('cookieAccepted') || "not-accepted";
                            this.cookieService.deleteAll('/');
                            if(cookieAcceptedStatus != "not-accepted") {
                              this.cookieService.set("cookieAccepted", "true", undefined, "/")
                            }
                            sessionStorage.clear();
                            window.localStorage.clear();
                        }
                        window.location.reload();
                    });
                } else if(Number(err.status) === 404){
                    console.log('err',err.url);
                    if(err.url.indexOf('/translation/')){
                        reloadWithDefaultLanguage();
                    }
                }  else if(Number(err.status) === 200) {
                    if(err.url.indexOf('/translation/')){
                        reloadWithDefaultLanguage();
                    }
                }
                else {
                    //console.log('next.handle 37');
                    return;
                }
            }else{
                //console.log('next.handle 39');
                return;
            }
        }));
    }catch(ex){
        //console.log('next.handle 47',ex);
        return new Observable<HttpEvent<any>>();
    }
    }
}

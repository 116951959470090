import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-support-popup',
  templateUrl: './contact-support-popup.component.html',
  styleUrls: ['./contact-support-popup.component.scss']
})
export class ContactSupportPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContactSupportPopupComponent>
  ) {

  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

}

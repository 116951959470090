<div class="product-item" fxLayout="column"   fxLayoutAlign.lt-sm="center start" fxLayoutGap="22px" fxLayoutGap.lt-md="25px">
    <div class="product-image w-100" fxLayoutAlign="center start">
      <a class="product-link" [routerLink]="['/product', product?.ItemCode]">
        <img [default]="'../.././../../assets/images/noimage.png'"
           [src]="env.apiUrl + 'api/Images' + product?.LargeImageUrl" alt="" class="img-fluid">
      </a>
    </div>
    <div class="product-info-box" fxLayout="column">
      <div class="mb-10" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between start">
        <h2 class="mb-0 prod-title lowercase" [textContent]="product.ItemDescription"></h2>
        <!-- <span [textContent]="product.ItemDescription"></span> -->
      </div>
      <div class="other_details">
        <div class="detail-prod" [innerHTML]="product?.ShortDetail1  | limitToPipe: 212:product?.ItemCode | unsafe"></div>
      </div>
    </div>
    <div class="buy-product" fxLayout="column" *ngIf="product.GroupMembers.length==0">
      <!-- <h2 class="pricing" [textContent]="selectedCountry=='tr'?(('AsLowAs' | translate) + (product?.PriceType2Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode)) :('AsLowAs' | translate) +(product?.PriceType2Value | currency:configService.selectedMarketConfig.CurrencySymbol)"></h2> -->
      <div fxlayout="row" fxLayoutAlign="space-between stretch">

        <!-- <button fxFlex="48" id="add-to-cart-btn" mat-raised-button class="btn-black"
        [routerLink]="['/product', product?.ItemCode]" translate [textContent]="(('BuyNow' | translate)  | slice:0:30) + (('BuyNow' | translate).length > 30 ? '...' : '')"></button>
        <button fxFlex="48" mat-raised-button class="btn-white" (click)="quickView(product)" translate="QuickView"></button> -->

        <!-- <button fxFlex="48" id="add-to-cart-btn" mat-raised-button class="btn-black" translate [textContent]="(('Subscribe' | translate)  | slice:0:30) + (('Subscribe' | translate).length > 30 ? '...' : '')" (click)="_handleAddToCart('subscribe', product)">
        </button> -->
        <div class="options">
          <div class="o-b o-1" id="subscribe" fxLayout="row" *ngIf="product.AllowOnAutoOrder && accountService.isLoggedUserRetailType()"
            [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? ProductColorOptions['Color5'] : '#000' }"
            fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutAlign="space-between start" class="margin-left" fxFlex="30" fxFlex.sm="55"
              fxFlex.md="55">
              <h2 class="pricing" [textContent]="selectedCountry=='tr'?(product?.PriceType2Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(product?.PriceType2Value | currency:configService.selectedMarketConfig.CurrencySymbol)"></h2>
            </div>
            <button class="btn-black button bold-text"
              (click)="_handleAddToCart('subscribe',product);"
              translate
              [textContent]="('Click to subscribe' | translate)"
              fxFlex="70"></button>
          </div>
          <div class="o-b o-1" id="onetime" fxLayout="row"
            fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div fxFlex="30" fxFlex.sm="55" fxFlex.md="55" class="margin-left">
              <h2 class="pricing-buy" [textContent]="accountService.isLoggedIn() ? (selectedCountry=='tr'?  (accountService.getLoggedInPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ( accountService.getLoggedInPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)) : selectedCountry=='tr'?(product?.PriceType1Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ( product?.PriceType1Value| currency: configService.selectedMarketConfig.CurrencySymbol)"></h2>
            </div>
            <button class="btn-white button"
              (click)="_handleAddToCart('onetime',product);" translate="BuyNow"
              fxFlex="70"></button>
          </div>
        </div>
        <!-- <button fxFlex="48" id="add-to-cart-btn" mat-raised-button class="btn-black" translate [textContent]="(('Click to subscribe' | translate)  | slice:0:30) + (('Click to subscribe' | translate).length > 30 ? '...' : '')" (click)="_handleAddToCart('subscribe', product)">
        </button>
        <button fxFlex="48" mat-raised-button class="btn-white" translate="BuyNow" (click)="_handleAddToCart('onetime',product); cartService.sidebarSummaryCartDrawer.open()">
        </button> -->
        
      </div>
    </div>
    <div class="buy-product" fxLayout="column" *ngIf="product.GroupMembers.length>0">

      <div fxlayout="row" fxLayoutAlign="space-between stretch">
        <div class="options">
          <div class="o-b o-1" id="subscribe" fxLayout="row"  *ngIf="product.AllowOnAutoOrder && accountService.isLoggedUserRetailType()"
            [ngStyle]="{'background-color' : productInfo?.StaticKitChildren?.length == 0 ? ProductColorOptions['Color5'] : '#000' }"
            fxLayoutAlign.lt-sm="space-around start" fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutAlign="space-between start" class="margin-left" fxFlex="30" fxFlex.sm="55"
              fxFlex.md="55">
              <h2 class="pricing" [textContent]="selectedCountry=='tr'?(product?.GroupMembers[0]?.Item.PriceType2Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(product?.GroupMembers[0]?.Item.PriceType2Value | currency:configService.selectedMarketConfig.CurrencySymbol)"></h2>
            </div>
            <button class="btn-black button bold-text"
              [routerLink]="['/product', product?.ItemCode]" 
              translate
              [textContent]="('Click to subscribe' | translate)"
              fxFlex="70"></button>
          </div>
          <div class="o-b o-1" id="onetime" fxLayout="row"
            fxLayoutAlign="space-around center" fxLayoutGap="10px">
            <div fxFlex="30" fxFlex.sm="55" fxFlex.md="55" class="margin-left">
              <h2 class="pricing-buy" [textContent]="selectedCountry=='tr'?(product?.GroupMembers[0]?.Item.PriceType1Value | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ( product?.GroupMembers[0]?.Item.PriceType1Value| currency: configService.selectedMarketConfig.CurrencySymbol)"></h2>
            </div>
            <button class="btn-white button"
            [routerLink]="['/product', product?.ItemCode]"  translate="BuyNow"
              fxFlex="70"></button>
          </div>
        </div>
      </div>


      <!-- <h2 class="pricing" [textContent]="selectedCountry=='tr'?(('AsLowAs' | translate) + (product?.GroupMembers[0]?.Item.PriceType2Value | currency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode)):(('AsLowAs' | translate) + (product?.GroupMembers[0]?.Item.PriceType2Value | currency:configService.selectedMarketConfig.CurrencySymbol))"></h2> -->


      <!-- <div fxlayout="row" fxLayoutAlign="space-between stretch">
        <button fxFlex="100" id="add-to-cart-btn" mat-raised-button class="btn-black single-buy-now-btn"
        [routerLink]="['/product', product?.ItemCode]" translate [textContent]="(('BuyNow' | translate)  | slice:0:30) + (('BuyNow' | translate).length > 30 ? '...' : '')"></button> -->


        <!-- <button fxFlex="48" mat-raised-button class="btn-white" (click)="quickView(product)" translate="QuickView"></button> -->


        

      <!-- </div> -->
    </div>
</div>

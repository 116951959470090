<div fxLayout="row" fxLayoutAlign="center center">
  <div fxLayout="column" class="verifyInfo">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom p-15">
        <h2 fxFlex="50" fxFlex.lt-sm="60" class="f-w-500 f-s-24 m-0" fxLayoutAlign="start center" translate="AddressValidation"></h2>
        <mat-icon fxFlex="5" mat-button mat-dialog-close class="close-icon" fxLayoutAlign="end center">close</mat-icon>
    </div>
    <div fxLayout="column" fxFlex="100" class="p-15">
      <h3 translate="ReviewAddress"></h3>
      <mat-radio-group [(ngModel)]="selectedAddressType">
        <div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center stretch">
          <div fxFlex="35" fxFlex.lt-sm="70" fxLayout="column">
            <div fxLayoutAlign="start start">
              <mat-radio-button [value]="1"> </mat-radio-button>
              <label translate="SuggestedAddress"></label>
            </div>
            <div class="fetchedAddress">
              <p [textContent]="data.VerifiedAddress.Address1 +' '"></p>
              <p [textContent]="data.VerifiedAddress.City +' '+ data.VerifiedAddress.State +' '+ data.VerifiedAddress.Zip + ' '+ data.VerifiedAddress.Country"></p>
            </div>
          </div>
          <div fxFlex=15 fxFlex.lt-sm="0"></div>
          <div fxFlex="35" fxFlex.lt-sm="70">
            <div fxLayoutAlign="start start">
              <mat-radio-button [value]="2"> </mat-radio-button>
              <label translate="EnteredAddress"></label>
            </div>
            <div class="fetchedAddress">
              <p [textContent]="data.OriginalAddress.Address1 +' '"></p>
              <p [textContent]="data.OriginalAddress.City +' '+ data.OriginalAddress.State +' '+ data.OriginalAddress.Zip + ' '+ data.OriginalAddress.Country"></p>
            </div>
          </div>
          <div fxFlex=15 fxFlex.lt-sm="0"></div>
        </div>
      </mat-radio-group>
      <div fxFlex=row fxLayoutAlign="end center">
        <button fxFlex="5" mat-stroked-button class="ok_btn" translate="OK" (click)="selectedAddress()"></button>
      </div>
    </div>
  </div>
</div>

<!-- <app-stepper></app-stepper> -->


<section class="normal-item product_detail_page">
  <ng-container *ngIf="showMore || showmore">
    <div class="prod_top_heading" fxLayoutAlign="center center">
      <h2 fxFlex="90" fxFlex.lt-sm="90" [textContent]="'SubscribeAndNeverPayFull' | translate" class="text-center"></h2>
    </div>
    <div class="product_detail_item_cta" fxLayoutAlign="center center" fxLayout="column">
      <h3 [textContent]="'SetupMonthlySubscription' | translate" class="text-center"></h3>
      <button mat-stroked-button [textContent]="'setup subscription'" (click)="scrollToSubsciptionBox()"></button>
    </div>
    <div class="product_detail_image_bundle" fxLayoutAlign="center center">
      <img [src]="'assets/images/prod_detail_1.png'" alt="" class="prod_detail_1">
    </div>

    
    <div fxLayout="column">
      <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="15px"
        fxLayoutAlign.xs="space-between" class="mb-30">
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box ">
          <p translate="month_one" class="cardBox-header" fxLayoutAlign="center center"></p>
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image class="img1" src="../../../../assets/images/monthone.png" alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <p translate="SAVE_UP_TO_10%" class="card_h1"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" translate="month_one_para"></p>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box">
          <p translate="month_two" class="cardBox-header" fxLayoutAlign="center center"></p>
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image class="rotate-90" src="../../../../assets/images/fiftycashbouns.png"
              alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <p translate="GET_15_percent_bonus_saving" class="card_h1"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'month_two_para_save10_percent' | translate"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'earn5percent' | translate"></p>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box">
          <p translate="month_three" class="cardBox-header" fxLayoutAlign="center center"></p>
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image class="img1" src="../../../../assets/images/monththree.png" alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <p translate="month_three_recieve_20per" class="card_h1"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'month_two_para_save10_percent' | translate"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'month_three_earn10_percent' |translate"></p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="space-evenly" fxLayoutGap="15px"
        fxLayoutAlign.xs="space-between" class="mb-30 text-center mb-30">
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box">
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image class="img2" src="../../../../assets/images/everydayadvange.png" alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <p translate="everyday_advantage" class="card_h1"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHTML]="'everyday_advantage_para' | translate"></p>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box">
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
              <img mat-card-image class="img3" src="../../../../assets/images/raincash.png" alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" fxLayoutAlign="center center">
                  <p translate="rain_cash_credits" class="card_h1 c-black"></p>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                  <p class="card_text" [innerHTML]="'rain_cash_credits_para' | translate"></p>
              </div>
          </mat-card-content>
      </mat-card>
        <mat-card fxFlex="30" fxFlex.xs="100" class="card-box">
          <div class="image" fxLayout="row" fxLayoutAlign="center center">
            <img mat-card-image class="img2" src="../../../../assets/images/additionalloyality.png"
              alt="Photo of a card">
          </div>
          <mat-card-content fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <p translate="additional_loyality" class="card_h1"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'additinal_loyality_para1' | translate"></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <p class="card_text" [innerHtml]="'additinal_loyality_para2' | translate"></p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div *ngIf="false" class="product_detail_header" fxLayout="column" fxLayoutAlign="center center">
      <h2 [textContent]="'3&free' | translate"></h2>
      <p [textContent]="'3&free_desc' | translate"></p>
      <p [textContent]="'see_tnc' | translate"></p>
    </div>
    <div *ngIf="false" class="customer_circles" fxLayout="row" fxLayoutAlign="space-between stretch">
      <div fxFlex="25" fxFlex.lt-sm="28" fxFlex.gt-md="30" class="inner_circle" fxLayoutAlign="center center">
        <div class="thecircle" fxLayoutAlign="center center" fxLayout="column">
          <h2 [textContent]="1"></h2>
          <p [textContent]="'Customer' | translate" class="lowercase"></p>
        </div>
      </div>
      <div fxFlex="10" fxFlex.ly-sm="6" class="plus" fxLayoutAlign="center center">
        <mat-icon>add</mat-icon>
      </div>
      <div fxFlex="25" fxFlex.lt-sm="28" fxFlex.gt-md="30" class="inner_circle" fxLayoutAlign="center center">
        <div class="thecircle" fxLayoutAlign="center center" fxLayout="column">
          <h2 [textContent]="2"></h2>
          <p [textContent]="'Customer' | translate" class="lowercase"></p>
        </div>
      </div>
      <div fxFlex="10" fxFlex.ly-sm="6" class="plus" fxLayoutAlign="center center">
        <mat-icon>add</mat-icon>
      </div>
      <div fxFlex="25" fxFlex.lt-sm="28" fxFlex.gt-md="30" class="inner_circle" fxLayoutAlign="center center">
        <div class="thecircle" fxLayoutAlign="center center" fxLayout="column">
          <h2 [textContent]="3"></h2>
          <p [textContent]="'Customer' | translate" class="lowercase"></p>
        </div>
      </div>
    </div>
  </ng-container>
  <div fxLayout="column" id="prod-details" class="main-container">
    <!-- <div fxLayout="row" class="header" fxLayoutAlign="end center" routerLink="../../products">
        <div fxLayoutAlign="start center">
           <span class="p-l-15" translate="pagetitle_product"></span>
           <mat-icon class="c-gray">keyboard_arrow_right</mat-icon>
        </div>
        <div>
           <span class="color2-f-s-14" [textContent]="product?.ItemDescription"></span>
        </div>
     </div> -->
    <div class="color-b">

      <div class="container" fxLayout="column">
        <div class="hero_container" fxHide.lt-sm fxLayout="row" fxLayoutAlign="space-between stretch" id="backImg">

          <img [src]="product?.ShortDetail4" alt="" style="width:100%; height: auto">
          <!-- <div fxFlex="15" fxHide fxShow.gt-sm fxLayoutAlign="center center">
                <img src="../../../../assets/images/wheat.png" alt="" class="img-fluid hero_first_img">
              </div>
              <div fxFlex="25">
                <img [src]="env.apiUrl + 'api/Images' +(activeImage.Path || product?.SmallImageUrl)" alt="" class="img-fluid hero_second_img">
              </div>
              <div fxFlex="58" fxHide fxShow.gt-sm fxLayout="column" fxLayoutAlign="center start">
              <h2 class="prod_hero_title" translate="Product_Hero_Title_{ItemCode}"></h2>
              <p translate="Product_Hero_Description_{ItemCode}"></p>
              </div> -->
        </div>
        <div class="pd_first_container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
          <div fxFlex=47 class="product-left">
            <div class="product-title">
              <h2 class="font-weight-bolder item-detailDescription lowercase" [textContent]="product?.ItemDescription">
              </h2>
            </div>
            <div class="item-detail-container" fxHide fxShow.gt-xs>
              <span *ngIf="product?.StaticKitChildren?.length == 0, else elseDesciption"
                [innerHtml]="product?.ShortDetail1 | unsafe" class="product_details_text"></span>
              <ng-template #elseDesciption>
                <div fxLayout="column">
                  <span [innerHtml]="product?.ShortDetail1 | unsafe" class="product_details_text"></span>
                </div>
              </ng-template>
            </div>
            <div class="layout-row" fxLayout="row" *ngIf="product?.GroupMembers  && product?.GroupMembers?.length>0 ">
              <div class="w-100">
                <ng-container>
                  <div class="m-0 p-20-0 custom-size">
                    <p [textContent]="' flavor: '"></p>
                    <mat-select readonly="true" (selectionChange)="checkOptions(product?.GroupMembers);"
                      [(ngModel)]="value[0]" name="value.Option" (click)="openSelect($event)"
                      class="md-no-underline-select" (ngInit)="value[0]=product?.GroupMembers[0].Item">
                      <mat-option *ngFor="let option of product?.GroupMembers" [value]="option.Item"
                        (click)="selectOption(product, product?.GroupMembers, value, false)">
                        {{option.Item?.ItemDescription}} </mat-option>
                    </mat-select>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="item-buy-btn-group" fxLayout="column">
              <div *ngIf="hideAddToCart(product, true) && product?.StaticKitChildren?.length == 0 && accountService.isLoggedUserRetailType(), else elseAutoShip"
                class="subscription-box" [style.background-color]="ProductColorOptions['Color5']" fxLayout="row"
                fxLayout.lt-sm="row column" fxLayoutAlign="space-evenly center"
                fxLayoutAlign.lt-sm="space-between  center" id="subscription-box">
                <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
                  <div class="item-detailprice font-weight-bolder text-white"
                    [textContent]="selectedCountry=='tr'?(getSubsPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(getSubsPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)">
                  </div>
                </div>
                <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column" class="subscribe_cta">
                  <h2 translate="SubscribeToSave"></h2>
                  <p translate="SubscribeLabel"> </p>
                </div>
                <div fxFlex="23" fxFlex.lt-sm="100">
                  <button mat-stroked-button class="dark_btn w-100"
                    (click)="addToCart('autoship', product); cartService.sidebarSummaryCartDrawer.toggle()"><span
                      translate="Subscribe" class="font_family"></span></button>
                </div>
              </div>
              <ng-template #elseAutoShip>
                <div *ngIf="hideAddToCart(product, true) && accountService.isLoggedUserRetailType()" class="subscription-box product_detail_subs_box"
                  fxLayout="row" fxLayout.lt-sm="row column" fxLayoutAlign="space-evenly center"
                  fxLayoutAlign.lt-sm="space-between  center" id="subscription-box">
                  <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
                    <div class="item-detailprice font-weight-bolder text-white"
                      [textContent]="selectedCountry=='tr'?(getSubsPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :(getSubsPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)">
                    </div>
                  </div>
                  <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column" class="subscribe_cta">
                    <h2 translate="SubscribeToSave"></h2>
                    <p translate="SubscribeLabel"> </p>
                  </div>
                  <div fxFlex="23" fxFlex.lt-sm="100">
                    <button class="product_detail_btn w-100" [style.background-color]="ProductColorOptions['Color5']"
                      (click)="addToCart('autoship', product); cartService.sidebarSummaryCartDrawer.open()"><span
                        translate="Subscribe" class="font_family"></span></button>
                  </div>
                </div>
              </ng-template>
              <div class="one-time-order" fxLayout="row" fxLayout.lt-sm="row column" fxLayoutAlign="space-evenly center"
                fxLayoutAlign.lt-sm="space-between  center">
                <div fxFlex="37" fxFlex.lt-sm="100" class="price-column">
                  <div class="item-detailprice font-weight-bolder"
                    [textContent]="selectedCountry=='tr'? (getPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)">
                  </div>
                </div>
                <div fxFlex="40" fxFlex.lt-sm="100" fxLayout="column">
                  <h2 [translate]="'OneTimePurchase'"></h2>
                </div>
                <div fxFlex="23" fxFlex.lt-sm="100">
                  <button class="light_btn w-100 " (click)="addToCart('order', product);"><span translate="OneTimeOrder"
                      class="font_family btn_color"></span></button>
                </div>
              </div>
            </div>
          </div>
          <!-- video slider -->
          <div fxFlex=47 class="product-right" fxLayout="column" fxLayoutAlign="center center"
            *ngIf="product.ItemCode && sliderVideos && (sliderVideos[product?.ItemCode+'_tag_bannervideos']?.length>0 || sliderVideos[product?.ItemCode+'_tag_bannerimages']?.length>0)">
            <div class="product-videos" fxLayoutAlign="start stretch">
              <swiper class="swiper-container" fxFlex=100 [config]="config" (indexChange)="onIndexChange($event)">
                <div *ngFor="let slide of sliderVideos[product?.ItemCode+'_tag_bannervideos'] | slice:0:3; let i=index"
                  class="swiper-slide">
                  <div fxLayout="column" fxFlex=100 fxLayoutAlign="center center" fxFlexFill>
                    <video controls (play)="video()" #videoPlayer style="width: 500px" class="slider-videos">
                      <source [src]="slide" type="video/mp4" autostart="false" />
                      Browser not supported
                    </video>
                  </div>
                </div>
                <div *ngFor="let image of sliderVideos[product?.ItemCode+'_tag_bannerimages'] | slice:0:3; let i=index"
                  class="swiper-slide">
                  <div fxLayout="column" fxFlex=100 fxLayoutAlign="center center" fxFlexFill>
                    <img [src]="image" class="slider-images" style="width: 500px;max-height:290px;max-width:100%;" />
                  </div>
                </div>
              </swiper>
            </div>
          </div>
        </div>
        <div class="item-detail-container" fxShow fxHide.gt-xs>
          <span *ngIf="product?.StaticKitChildren?.length == 0, else elseDesciption"
            [innerHtml]="product?.ShortDetail1 | unsafe" class="product_details_text"></span>
          <ng-template #elseDesciption>
            <div fxLayout="column">
              <span [innerHtml]="product?.ShortDetail1 | unsafe" class="product_details_text"></span>
            </div>
          </ng-template>
        </div>
        <div class="pd_second_container mt-10">
          <div fxLayout="column row" fxLayoutAlign="start start" *ngIf="product?.LongDetail1, else noLongDetail">
            <mat-tab-group class="product-detail-tabs">
              <mat-tab *ngIf="product?.LongDetail3" [label]="'benefits' | translate" fxLayoutAlign="start center">
                <div [innerHtml]="product?.LongDetail3 | unsafe" class="product-detail-tabs-content"></div>

              </mat-tab>
              <mat-tab *ngIf="product?.LongDetail1" [label]="'productinfo' | translate" fxLayoutAlign="start center">
                <div [innerHtml]="product?.LongDetail1 | unsafe" class="product-detail-tabs-content"></div>
              </mat-tab>
              <mat-tab *ngIf="product?.LongDetail2" [label]="'nutritionalinfo' | translate"
                fxLayoutAlign="start center">
                <div [innerHtml]="product?.LongDetail2 | unsafe" class="product-detail-tabs-content"></div>
              </mat-tab>
              <!-- <mat-tab *ngIf="product?.LongDetail4" label="Q&A" fxLayoutAlign="start center">
                     <div [innerHtml]="product?.LongDetail4 | unsafe" class="product-detail-tabs-content"></div>
                  </mat-tab> -->
            </mat-tab-group>
            <div class="product-feed" fxLayoutAlign="start stretch">
              <swiper class="swiper-container" fxFlex=100 effect="fade" [config]="testimonialConfig"
                *ngIf="sliderFeeds && sliderFeeds[product?.ItemCode+'_tag_ss']">
                <div *ngFor="let slide of sliderFeeds[product?.ItemCode+'_tag_ss']  let i=index" class="swiper-slide">
                  <div fxLayout="column" fxFlex=100 fxLayoutAlign="top center" fxFlexFill>
                    <app-feed style="width: 100%; height: 100%;"
                      *ngIf="product.ItemCode &&  sliderFeeds[product?.ItemCode+'_tag_ss']?.length>0"
                      [htmlText]="slide?.ss_story | unsafe" [image]="slide?.ss_image" [customername]="slide?.ss_name"
                      [color]="ProductColorOptions['Color3'] === '' ? ProductColorOptions['Color1'] : ProductColorOptions['Color3']">
                    </app-feed>
                  </div>
                </div>
              </swiper>
            </div>
          </div>
          <ng-template fxLayout="column row" fxLayoutAlign="start start" #noLongDetail>
            <mat-tab-group class="product-detail-tabs">
              <mat-tab [label]="'benefits' | translate" fxLayoutAlign="start center">
                <div [textContent]="dummyDetails"></div>
              </mat-tab>
              <mat-tab [label]="'productinfo' | translate" fxLayoutAlign="start center">
                <div [textContent]="dummyDetails"></div>
              </mat-tab>
              <mat-tab [label]="'nutritionalinfo' | translate" fxLayoutAlign="start center">
                <div [textContent]="dummyDetails"></div>
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </div>
      </div>
      <div *ngIf="relatedProducts" class=" main-container related_products" fxLayout="column">
        <div class="border-bottom mb-30">
          <h2 class="text-center" translate="OthersAlsoBuy"></h2>
        </div>
        <div fxLayout="row column" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
          <div fxFlex="32" fxFlex.lt-lg="50" fxFlex.lt-sm="100" fxLayout="column" fxLayoutAlign="start center"
            class="related_item" *ngFor="let product of relatedProducts">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-10">
              <h2 fxFlex="33" class="lowercase" [textContent]="product?.ItemDescription"></h2>
              <p class="text-muted mb-0" fxFlex="65" [translate]="'Related_Product_Title_{ItemCode}'"
                style="text-align: center;"></p>
            </div>
            <div class="layout-row" fxLayout="row" *ngIf="product?.GroupMembers">
              <div class="w-100">
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="15px">
              <div fxFlex="45" fxLayout="column" fxLayoutAlign="start center">
                <a [routerLink]="['/product', product?.ItemCode]"><img
                    [src]="env.apiUrl + 'api/Images' +(activeImage.Path || product?.SmallImageUrl)" alt=""
                    class="frq_product_image mb-5"></a>
                <button class="rel_btn quick-btn" (click)="quickView(product)" translate="QuickView"></button>
              </div>
              <div fxFlex="54" fxLayout="column" fxLayoutAlign="center stretch">
                <div *ngIf="hideAddToCart(product, true) && accountService.isLoggedUserRetailType()" fxLayout="column" fxLayoutAlign="center start"
                  class="related_subscription">
                  <div fxFlex
                    [textContent]="selectedCountry=='tr'?(getSubsPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(getSubsPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)">
                  </div>
                  <button fxFlex style="z-index: 100;" (click)="addToCart('autoship', product)" class="dark_btn rel_btn"
                    translate="SubscribeToSave"></button>

                </div>
                <div fxLayout="column" fxLayoutAlign="center start" class="mb-10">
                  <p fxFlex
                    [textContent]="selectedCountry=='tr'?(getPrice(product) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): (getPrice(product) | currency: configService.selectedMarketConfig.CurrencySymbol)"></p>
                  <button fxFlex (click)="addToCart('order', product)" class="light_btn rel_btn"
                    translate="OneTimeOrder"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

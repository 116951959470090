import { UtilityService } from 'src/app/core/services/utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-below-slider',
  templateUrl: './below-slider.component.html',
  styleUrls: ['./below-slider.component.scss']
})
export class BelowSliderComponent implements OnInit {
  public ListImages: any[] = [];
  constructor(
    public sanitizer: DomSanitizer, public utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.init().then(() => {
      this.ListImages = _.orderBy(this.ListImages, ['sort'], ['asc'])
    });
  }

  init() {
    var ImageList = [{
      imgPath: '../../../../assets/images/heart.png',
      imgText: 'HeartHealth',
      sort: 1
    },
    {
      imgPath: '../../../../assets/images/inflammation.png',
      imgText: 'InflammationReducers',
      sort: 2
    },
    {
      imgPath: '../../../../assets/images/weight_management.png',
      imgText: 'WeightManagement',
      sort: 3
    },
    {
      imgPath: '../../../../assets/images/antioxidants.png',
      imgText: 'Antioxidants',
      sort: 4
    },
    {
      imgPath: '../../../../assets/images/brain_health.png',
      imgText: 'BrainHealth',
      sort: 5
    },
    {
      imgPath: '../../../../assets/images/gut_health.png',
      imgText: 'GutHealth',
      sort: 6
    }
    ];
    const promise = new Promise((resolve, reject) => {  
      ImageList.forEach((val, index, list) => {
        this.utilityService.getBase64ImageFromUrl(val.imgPath).then((result: any) => {
          this.ListImages.push({ imageData: this.sanitizer.bypassSecurityTrustResourceUrl(result.toString()), text: val.imgText, sort: val.sort });
          if(index == list.length-1){
            resolve(this.ListImages);
          }
        }).catch(() => {
          reject({});
        })
      });
    });
    return promise;
  }
}

import { NgModule } from '@angular/core';
import { ValidateDirective } from '../../core/directive/validation.directive';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { OrderService } from 'src/app/core/services/order.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { CartService } from 'src/app/core/services/cart.service';
import { ProductService } from 'src/app/core/services/product.service';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { VerificationComponent } from './verification/verification.component';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ImagePreloadDirective } from '../directives/default-img.directive';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { GroupByPipe } from 'src/app/core/pipes/group-by-count.pipe';
import { NgInitDirective } from '../directives/ng-init';
import { FeedComponent } from '../feed/feed.component';
import { CommonModule } from '@angular/common';
import { SideBarSummaryCartComponent } from 'src/app/modules/sidebar-summary-cart/sidebar-summary-cart.component';
import { ConfigurepackComponent } from 'src/app/modules/enrollment/configurepack/configurepack.component';
import { MatMenuModule } from '@angular/material/menu';
import { UnsafePipe } from 'src/app/core/pipes/unsafe.pipe';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpgradeProductInfoComponent } from './upgrade-product-info/upgrade-product-info.component';
import { MatInputModule } from '@angular/material/input';
import { PaymentComponent } from './payment/payment.component';
import { RemoveCommaPipe } from 'src/app/core/pipes/remove-comma.pipe';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { customeCurrencyPipe } from 'src/app/core/pipes/custom-currency.pipe';
import { SubscriptionLearnMoreComponent } from './subscription-learn-more/subscription-learn-more.component';
@NgModule({
  declarations: [
    ValidateDirective,
    VerificationComponent,
    ImagePreloadDirective,
    BreadcrumbComponent,
    GroupByPipe,
    NgInitDirective,
    FeedComponent,
    ConfigurepackComponent,
    UnsafePipe,
    EditAddressComponent,
    PaymentComponent,
    RemoveCommaPipe,
    VideoDialogComponent,
    customeCurrencyPipe,
    SubscriptionLearnMoreComponent,
    UpgradeProductInfoComponent
  ],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatSelectModule,
    FormsModule,
    MatProgressBarModule,
    MatRadioModule,
    TranslateModule,
    NgxImageZoomModule,
    CommonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatInputModule
  ],
  exports: [
    ValidateDirective,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatBadgeModule,
    MatSelectModule,
    MatProgressBarModule,
    FormsModule,
    TranslateModule,
    ImagePreloadDirective,
    BreadcrumbComponent,
    GroupByPipe,
    NgInitDirective,
    FeedComponent,
    CommonModule,
    ConfigurepackComponent,
    UnsafePipe,
    MatCheckboxModule,
    MatInputModule,
    RemoveCommaPipe,
    customeCurrencyPipe
  ],
  providers: [
  ],
  entryComponents: [

  ]
})
export class SharedModule {}

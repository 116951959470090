
import { PaymentService } from './payment.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { OrderService } from './order.service';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from './product.service';
import { MatDialog } from '@angular/material/dialog';
import { ShippingAddress, UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { Router } from '@angular/router';
import { UtilityService } from './utility.service';
import { PersistentService } from './persistent.service';
import { Location } from '@angular/common';
import { ItemsListService } from './itemsList.service';
import { SideNavBarService } from './sidenavbar.service';
import { AutoshipConfigurationService } from './autoshipConfiguration.service';
import { CompanyService } from './company.service';
import { ConfigService } from './config.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/model/confirm-dialog/confirm-dialog.component';
import { ConfirmSubscribeComponent } from 'src/app/shared/model/confirm-subscribe/confirm-subscribe.component';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  public isKitAdded = {};
  public packQuantity = {};
  public orderQuantity = {};
  public autoshipQuantity = {};
  userService: UserServiceModal;
  public oldRestrictedState;
  public oldRegionMainState;
  public restrictedOrderItems = [];
  public restrictedAutoOrderItems = [];
  public sidebarOpenStatus: boolean;
  public sidebarSummaryCartDrawer: any;
  public checkoutPaymentButton: any;
  public enrollmentPaymentButton: any;
  public isPackBuild:boolean=false;
  public showCart: boolean;
  @Output() packChange: EventEmitter<any> = new EventEmitter();
  constructor(

    public notificationService: NotificationService,
    public user: UserService,
    private translate: TranslateService,
    private itemsService: ProductService,
    private dialog: MatDialog,
    public paymentService: PaymentService,
    public router: Router,
    public utilityService: UtilityService,
    public persistentService: PersistentService,
    public location: Location,
    public itemsListService: ItemsListService,
    public sideNavBarService: SideNavBarService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public companyService: CompanyService,
    public configService: ConfigService,
    public orderService: OrderService
  ) {
    this.userService = user.userServiceModal;
    setTimeout(() => {
      this.init();
    }, 1000);
    
  }

  // /**
  //  * Increase quantiy of items in cart.
  //  * @method  increaseQuantiy
  //  * @param   {object}  item     Item object.
  //  * @param   {boolean} isAutoship     If it is added to autoship or not.
  //  */
  public increaseQuantiy(item, isAutoship?: boolean, isPack?: boolean, isItemAddedFromList?: boolean) {
    this.persistentService.retailData.isChanged = true;
    if (isAutoship) {
      if (this.autoshipQuantity[item.ItemCode] < 99) {
        this.autoshipQuantity[item.ItemCode] =
          Number(this.autoshipQuantity[item.ItemCode] || 0) + 1;
        item.Quantity = this.autoshipQuantity[item.ItemCode];
        if (isItemAddedFromList) {
          _.each(this.itemsService.selectedAutoOrderItems, (autoItem) => {
            if (autoItem.ItemCode == item.ItemCode) {
              autoItem.Quantity = item.Quantity;
            }
          });
        }
        localStorage.setItem(
          'cart.autoship',
          JSON.stringify(this.itemsService.selectedAutoOrderItems)
        );
      }
      //this.orderService.calculateLocalAutoOrder();
      this.orderService.calculateAutoOrder().then(()=>{
        this.orderService.abandonedCart('Autoship', this.orderService.calculateAutoOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });
    } else if (isPack) {
      this.packQuantity[item.ItemCode] =
        Number(this.packQuantity[item.ItemCode] || 0) + 1;
      item.Quantity = this.packQuantity[item.ItemCode];
      if (isItemAddedFromList) {
        _.each(this.itemsService.selectedPacks, (packItem) => {
          if (packItem.ItemCode == item.ItemCode) {
            packItem.Quantity = item.Quantity;
          }
        });
      }
      localStorage.setItem(
        'cart.packs',
        JSON.stringify(this.itemsService.selectedPacks)
      );
      //this.orderService.calculateLocalOrder();
      this.orderService.calculateOrder().then(()=>{
        this.orderService.abandonedCart('Pack',this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });
    } else {
      this.orderQuantity[item.ItemCode] = this.orderQuantity[item.ItemCode] || 0;
      if (this.orderQuantity[item.ItemCode] < 99) {
        this.orderQuantity[item.ItemCode] =
          Number(this.orderQuantity[item.ItemCode] || 0) + 1;
        item.Quantity = this.orderQuantity[item.ItemCode];
        if (isItemAddedFromList) {
          _.each(this.itemsService.selectedOrderItems, (orderItem) => {
            if (orderItem.ItemCode == item.ItemCode) {
              orderItem.Quantity = item.Quantity;
            }
          });
        }
        localStorage.setItem(
          'cart.order',
          JSON.stringify(this.itemsService.selectedOrderItems)
        );
      }
      //this.orderService.calculateLocalOrder();
      this.orderService.calculateOrder().then(()=>{
        this.orderService.abandonedCart('Standard' ,this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });

      this.orderService.pointsPaymentCheckout.resetPointsCalculation();
    }
  }

  // /**
  //  * Decrease quantiy of items in cart.
  //  * @method  decreaseQuantiy
  //  * @param   {object}  item     Item object.
  //  * @param   {boolean} isAutoship     If it is added to autoship or not.
  //  */
  public decreaseQuantiy(item, isAutoship?: boolean, isPack?: boolean, isItemAddedFromList?: boolean) {

    this.persistentService.retailData.isChanged = true;
    if (isAutoship) {
      this.autoshipQuantity[item.ItemCode] = Number(
        this.autoshipQuantity[item.ItemCode] || 0
      );
      this.autoshipQuantity[item.ItemCode]--;
      item.Quantity = this.autoshipQuantity[item.ItemCode];
      if (isItemAddedFromList) {
        _.each(this.itemsService.selectedAutoOrderItems, (autoItem) => {
          if (autoItem.ItemCode == item.ItemCode) {
            autoItem.Quantity = item.Quantity;
          }
        });
      }
      localStorage.setItem(
        'cart.autoship',
        JSON.stringify(this.itemsService.selectedAutoOrderItems)
      );
      if (item.Quantity !== 0) {
        //this.orderService.calculateLocalAutoOrder();
        this.orderService.calculateAutoOrder().then(()=>{
          this.orderService.abandonedCart('Autoship', this.orderService.calculateAutoOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
        });
      }
    } else if (isPack) {
      this.packQuantity[item.ItemCode] = Number(this.packQuantity[item.ItemCode] || 0);
      this.packQuantity[item.ItemCode]--;
      item.Quantity = this.packQuantity[item.ItemCode];
      if (isItemAddedFromList) {
        _.each(this.itemsService.selectedPacks, (packItem) => {
          if (packItem.ItemCode == item.ItemCode) {
            packItem.Quantity = item.Quantity;
          }
        });
      }
      localStorage.setItem('cart.packs', JSON.stringify(this.itemsService.selectedPacks));
      if (item.Quantity !== 0) {
        //this.orderService.calculateLocalOrder();
        this.orderService.calculateOrder().then(()=>{
          this.orderService.abandonedCart('Pack',this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
        });
      }
    } else {
        this.orderQuantity[item.ItemCode]--;
        item.Quantity = this.orderQuantity[item.ItemCode];
      if (isItemAddedFromList) {
        _.each(this.itemsService.selectedOrderItems, (orderItem) => {
          if (orderItem.ItemCode == item.ItemCode) {
              orderItem.Quantity = item.Quantity;
            }
          });
        }
      localStorage.setItem(
        'cart.order',
        JSON.stringify(this.itemsService.selectedOrderItems)
      );

      if (item.Quantity !== 0) {
        //this.orderService.calculateLocalOrder();
        this.orderService.calculateOrder().then(()=>{
          this.orderService.abandonedCart('Standard', this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
        });
      }
    }
    if (item.Quantity === 0) {
      this.removeFromCart(item, isAutoship, isPack, true);
    }

    this.orderService.pointsPaymentCheckout.resetPointsCalculation();

  }
  // autoAddedCoupon method used to add auto add coupon
  public autoAddedCoupon() {
    if (this.userService.couponInfo.availableRewards.length > 0) {
      if (this.itemsService.IsAllowDynamicCouponcode) {
        _.each(this.userService.couponInfo.availableRewards, (item) =>  {
          if (item.Code == this.userService.DynamicCouponCode.promoCode) {
            this.userService.couponInfo.RewardsForUse = _.filter(this.userService.couponInfo.RewardsForUse, (item1) =>  {
              return item1.Code !== this.userService.DynamicCouponCode.promoCode;
            });
            // $scope.$broadcast('autoAddedCouponEvent', { item: item, flag: true });
          }
        });
      }
    }
  }
  // /**
  //  * Add items to cart.
  //  * @method  addToCart
  //  * @param   {object}  item     Item object.
  //  * @param   {boolean}  isShowNotification     Is show notification message on item added in cart.
  //  * @param   {string}  customOrderCode     Custom order code.
  //  * @param   {boolean} isAutoship     If it is added to autoship or not.
  //  * @param   {object}  fromDialog     if it is added from pop Dialog or not.
  //  * @param   {boolean} isPack     If it is added to pack or not.
  //  */
  public confrimSubscribe(ItemID:string, ItemCode?:string): any {
    const confrimSubscribepromise = new Promise((resolve, reject) => {
      let confirm: boolean;
        var dialogResult = this.dialog.open(ConfirmSubscribeComponent, {
          data: {ItemID:ItemID, ItemCode:ItemCode},
          panelClass: ['confirm-subscribe-dialog','center-subscribe-dialog']
        });
        dialogResult.afterClosed().subscribe((confirm) => {

          resolve(confirm);
        });
    });
    return confrimSubscribepromise;
  }
  public addToCart(
    item,
    isShowNotification,
    customOrderCode,
    isAutoship?: boolean,
    fromDialog?: boolean,
    isPack?: boolean,
    fromApplication?: boolean
  ) {

    this.persistentService.retailData.isChanged = true;
    // if (isPack) {
    //   this.addPack(item, isShowNotification);
    // } else {
      if (typeof isAutoship == 'undefined') {
        isAutoship = false;
      }
      // const qty = fromDialog ? 1 : 0;

      // Use a custom order code (for customization options like color and size) if one is passed in
      const itemCode = (customOrderCode || item.ItemCode).toString();
      // Determine if the item is in the cart, and if so, retrieve it

      // if (!fromDialog && item.HasOptions && item.ItemOptions.length > 0) {
      //   // If there are options (e.g. color/size), show the product details modal
      //   this.showProductDetail(item, isAutoship, item.HasOptions, isPack, fromApplication);
      //   return;
      // } else {
        this.addOrderToCart(
          item,
          itemCode,
          fromDialog,
          isAutoship,
          isShowNotification,
          isPack
        );
      //}
    // }

    if (!isAutoship) {
      this.autoAddedCoupon();
    }
  }

  // /**
  // * show Product Detail.
  // * @method  showProductDetail
  // * @param   {object}  item     Item object.
  // * @param   {boolean}  hasOptions     Is show notification message on item added in cart.
  // * @param   {boolean} isAutoship     If it is added to autoship or not.
  // * @param   {boolean} isPack     If it is added to pack or not.
  // */

  public showProductDetail(item, isAutoship?: boolean, hasOptions?: boolean, isPack?: boolean, fromApplication?: boolean) {
    if (!item) {
      return;
    }
    item = cloneDeep(item);

    // We don't pass HasOptions to the modal, because that property is used to decide behavior when "Add to Cart" is clicked.
    item.Quantity = isAutoship
      ? this.autoshipQuantity[item.ItemCode]
      : isPack
        ? this.packQuantity[item.ItemCode]
        : this.orderQuantity[item.ItemCode];
    if (!hasOptions) {
      item.HasOptions = null;
    }
    if (isPack) {
      item.ItemOptions = [];
      item.OptionsMap = [];
    }

    if (fromApplication) {

    } else {
      this.router.navigate(['/product', item.ItemCode], { queryParams : {itemType: isAutoship ? 'autoship' : isPack ? 'pack' : 'order'}});
    }

  }

  // /**
  //  * Remove items from cart.
  //  * @method  removeFromCart
  //  * @param   {object}  item     Item object.
  //  * @param   {boolean} isAutoship     If it is added to autoship or not.
  //  * @param   {boolean} isPack     If it is added to pack or not.
  //  */
  public removeFromCart(
    item,
    isAutoship?: boolean,
    isPack?: boolean,
    isShowNotification?: boolean
  ) {
    // this.triggerAddRemoveCartItems(item, null, false, isAutoship, isPack, true);
    if (isPack) {
      this.itemsService.selectedPacks = _.without(
        this.itemsService.selectedPacks,
        _.find(this.itemsService.selectedPacks, { ItemCode: item.ItemCode })
      );
      this.isKitAdded[item.ItemCode] = false;
      localStorage.setItem(
        'cart.packs',
        JSON.stringify(this.itemsService.selectedPacks)
      );
      this.itemsService.selectedAutoOrderItems=[];
      localStorage.setItem(
        'cart.autoship',
        JSON.stringify(this.itemsService.selectedAutoOrderItems)
      );

       if(this.itemsService['selectedOrderItems'].length == 1 && this.itemsService['selectedPacks'].length == 0 && this.itemsService['selectedOrderItems'][0].ItemCode == 'DiscountCode'){
        this.itemsService['selectedOrderItems'] = [];
      }
      //this.orderService.calculateLocalOrder()
      this.orderService.calculateOrder().then(() => {
        if (isShowNotification) {
          this.notificationService.success('success', 'ItemRemovedFromPack');
        }
      });
    } else {
      const ItemID = item.ItemCode;
      const selectedItems = isAutoship
        ? 'selectedAutoOrderItems'
        : 'selectedOrderItems';

      this[isAutoship ? 'autoshipQuantity' : 'orderQuantity'][ItemID] = 0;
      item.Quantity = 0;

      // Unselect the item
      this.itemsService[selectedItems] = this.itemsService[selectedItems].filter(
        (item1) =>  {
          if ((item1.ItemCode != ItemID)) {
            return true;
          } else {
            return false; //item1.UsePoints !== item.UsePoints;
          }
        }
      );

      if(this.itemsService[selectedItems].length == 1 && this.itemsService['selectedPacks'].length == 0 && this.itemsService[selectedItems][0].ItemCode == 'DiscountCode'){
        this.itemsService[selectedItems] = [];
      }

      if (isAutoship) {
        localStorage.setItem(
          'cart.autoship',
          JSON.stringify(this.itemsService.selectedAutoOrderItems)
        );
        //this.orderService.calculateLocalAutoOrder()
        this.orderService.calculateAutoOrder().then(() => {
          if (this.itemsService.selectedAutoOrderItems.length == 0) {
            this.orderService.calculateOrder();
            this.autoshipConfigurationService.autoshipDate = '';
            this.autoshipConfigurationService.init();
            if (this.utilityService.getAutoshipEditFlag()) {
              this.utilityService.setAutoshipEditFlag(false);
              this.persistentService.retailData.AddMoreItemInAutoshipFlag = false;
              this.persistentService.retailData.AddMoreItemInAutoshipData = {};
              this.persistentService.retailData.editAutoshipItems = [];
              this.persistentService.retailData.CurrentSelectedAutoOrder = 0;
            }
            if (this.itemsService.selectedOrderItems.length === 0 && this.location.path().toLowerCase() !== '/application') {
              this.checkoutPaymentButton = false;
              this.router.navigate(['/products']);
            }
          }
          if (isShowNotification) {
            this.notificationService.success(
              'success',
              'ItemRemovedFromAutoship'
            );
          }
        });
      } else {
        localStorage.setItem(
          'cart.order',
          JSON.stringify(this.itemsService.selectedOrderItems)
        );
        if(selectedItems=="selectedOrderItems" && this.itemsService.selectedOrderItems.length==0){
          this.itemsService["selectedAutoOrderItems"].filter(
            (item1) =>  {
              if ((item1.ItemCode == ItemID)) {
                this.removeFromCart(item1,true,false,false);
              }
            });
          //Remove Autoorder Item too if Autoship Remove
        }
        //this.orderService.calculateOrdercalculateLocalOrder()
        this.orderService.calculateOrder().then(() => {
          if(selectedItems=="selectedOrderItems"){
            this.itemsService["selectedAutoOrderItems"].filter(
              (item1) =>  {
                if ((item1.ItemCode == ItemID)) {
                  this.removeFromCart(item1,true,false,false);
                }
              });
            //Remove Autoorder Item too if Autoship Remove
          }
          if (isShowNotification) {
            this.notificationService.success('success', 'ItemRemovedFromOrder');            
            if (this.itemsService[selectedItems].length == 0) {
              if (this.location.path().toLowerCase() == '/enrollment') {
                this.router.navigate(['/enrollment']);
              }
              else {
                this.checkoutPaymentButton = false;
                this.router.navigate(['/products']);
              }
            }
          }
        });
      }
    }
    if (this.itemsService.selectedOrderItems.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0) {
      this.userService.selectedShippingMethod = 0;
    }
    if (isPack && this.itemsService.selectedOrderItems.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0 && this.itemsService.selectedPacks.length == 0) {
      this.enrollmentPaymentButton = false;
      this.router.navigate(['/enrollment']);
    }
    if(!isPack && this.itemsService.selectedOrderItems.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0 && this.itemsService.selectedPacks.length == 0){
      this.checkoutPaymentButton = false;
      this.router.navigate(['/products']);
    }
    this.orderService.pointsPaymentCheckout.resetPointsCalculation();
  }

  public clearCart() {
    this.isKitAdded = {};
    this.orderQuantity = {};
    this.autoshipQuantity = {};
    this.itemsService.selectedPacks = [];
    this.itemsService.selectedOrderItems = [];
    this.itemsService.selectedAutoOrderItems = [];
    this.orderService.calculateOrderResponse = {};
    this.orderService.calculateAutoOrderResponse = {};
    localStorage.removeItem('cart.packs');
    localStorage.removeItem('cart.order');
    localStorage.removeItem('cart.autoship');
  }

  public getItemPrice(item) {
    let price = null;
    if (!item.Prices || !item.Prices.length) {
      price = item.Price;
    } else {
      item.Prices.forEach(p => {
        if (p.CurrencyCode == null || (p.CurrencyCode && p.CurrencyCode.toLowerCase() !== 'rwd')) {
          price = p.Price;
        }
      });
    }
    return price;
  }

  public getOrderItems(isAutoship?: boolean, isPack?: boolean) {
    if (isAutoship) {
      return this.itemsService.selectedAutoOrderItems;
    } else if (isPack) {
      return this.itemsService.selectedPacks;
    } else {
      return this.itemsService.selectedOrderItems;
    }
  }

  public addOrderToCart(
    item,
    itemCode,
    fromDialog,
    isAutoship?: boolean,
    isShowNotification?: boolean,
    isPack?: boolean
  ) {
    isAutoship = !!isAutoship;
    
    const cartItems = isAutoship
      ? this.itemsService.selectedAutoOrderItems
      : isPack
        ? this.itemsService.selectedPacks
        : this.itemsService.selectedOrderItems;
    let itemInCart = null;
    const isInCart = cartItems.some((cartItem) =>  {
      if (cartItem.ItemCode == itemCode) {
        itemInCart = cartItem;
        return true;
      }
      return false;
    });

    if (isInCart) {
      // If it is in the cart...
      const itemQuantities = isAutoship
        ? this.autoshipQuantity
        : isPack
          ? this.packQuantity
          : this.orderQuantity;
      let quantity;
      if (!fromDialog) {
        // If not coming from a dialog, increase the quantity
        quantity = itemQuantities[itemCode]
          ? Number(itemQuantities[itemCode]) + 1
          : 1;
      } else {
        // If coming from a dialog, keep the quantity from the dialog
        quantity = itemQuantities[itemCode]
          ? Number(itemQuantities[itemCode])
          : 1;
      }

      itemQuantities[itemCode] = quantity;
      cartItems[cartItems.indexOf(itemInCart)].Quantity = quantity;

    } else {
      // If it is not in the cart, add it
      this.getKitInfo(item);
      this.getSelectedItemInfo(item,itemCode);
      item = cloneDeep(item);
      item.ItemCode = itemCode.toString();
      item.Quantity = isAutoship
        ? this.autoshipQuantity[itemCode] || 1
        : isPack
          ? this.packQuantity[itemCode] || 1
          : this.orderQuantity[itemCode] || 1;
      this[
        isAutoship
          ? 'autoshipQuantity'
          : isPack
            ? 'packQuantity'
            : 'orderQuantity'
      ][itemCode] = item.Quantity || 1;
      if (item.HasOptions) {
        _.each(item.OptionsMap, (value) =>  {
          if (value.ItemCode == item.ItemCode) {
            item.OptionsImage = value.Image;
            return;
          }
        });
      }
      cartItems.push(item);
    }
    if (isPack) {
      item.IsKitItem = true;
      this.orderService.calculateOrder(isShowNotification).then(()=>{
        this.orderService.abandonedCart('Pack', this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });
      this.packChange.emit()
    }

    // this.triggerAddRemoveCartItems(item, itemCode, fromDialog, isAutoship, isPack);

    if (isAutoship) {
      //this.orderService.calculateLocalAutoOrder(isShowNotification);
      this.orderService.calculateAutoOrder(isShowNotification).then(()=>{
        this.orderService.abandonedCart('Autoship', this.orderService.calculateAutoOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });

      // Setting Autoship Date if selected of past date from today 
      let totaItemsQuantity = 0;
      for (let index = 0; index < this.itemsService.selectedAutoOrderItems.length; index++) {
        const qty = this.itemsService.selectedAutoOrderItems[index].Quantity;
        totaItemsQuantity += qty;
      }
      if(totaItemsQuantity === 1){
        const todayDate = new Date();
        this.userService.autoshipDate = this.user.getDateAfterOneMonth(todayDate);
      }
      
    } else {
      //this.orderService.calculateLocalOrder(isShowNotification);
      this.orderService.calculateOrder(isShowNotification).then(()=>{
        this.orderService.abandonedCart('Standard', this.orderService.calculateOrderResponse.Details, this.orderService.calculateOrderResponse.Total);
      });
      this.orderService.pointsPaymentCheckout.resetPointsCalculation();
    }

    localStorage.setItem(
      isAutoship ? 'cart.autoship' : isPack ? 'cart.packs' : 'cart.order',
      JSON.stringify(cartItems)
    );
  }

  public getKitInfo(item) {
    if (!item.HasKitGroups) {
      return;
    }
    const kitItemNames = [];
    _.each(item.KitGroups, (group) =>  {
      let kitItemName = group.selectedItem.Name;
      if (group.selectedItem.HasOptions) {
        kitItemName += ' ' + group.selectedItem.selectedOptions;
      }
      kitItemNames.push(kitItemName);
    });
    item.selectedKitOptions = kitItemNames.join(', ');
  }
  public getSelectedItemInfo(item,itemCode) {
    if (!item.GroupMembers) {
      return;
    }
    const ItemName = [];
    _.each(item.GroupMembers, (group) =>  {
      let itemName = group.ItemDescription ?? "";
      if (group.Item && group.Item.ItemCode == itemCode) {
        itemName = group.Item.ItemDescription;
        ItemName.push(itemName);
      }
      
    });
    item.selectedItemName = ItemName.join(',');
  }

  public addPack(item, isShowNotification?: boolean) {
    if (
      item.HasOptions ||
      (item.HasKitGroups &&
        !_.every(item.KitGroups, (group) =>  {
          return group.selectedItem;
        }))
    ) {
      // If there are options (e.g. color/size), show the product details modal
      this.showProductDetail(item, false, null, true);
      return;
    } else {
      this.isKitAdded[item.ItemCode] = true;

      // If the product is already added, exit the method
      if (~this.itemsService.selectedPacks.indexOf(item)) {
        return;
      }
      let alreadyPackItems = false;
      _.each(this.itemsService.selectedPacks, (items) =>  {
        if (items.ItemCode == item.ItemCode) {
          alreadyPackItems = true;
        }
      });
      if (!alreadyPackItems) {
        this.itemsService.selectedPacks.push(item);
      }
      this.itemsService.selectedPacks.forEach((item1) =>  {
        item1.IsKitItem = true;
        item1.Quantity = 1;
      });

      localStorage.setItem(
        'cart.packs',
        JSON.stringify(this.itemsService.selectedPacks)
      );
      // this.triggerAddRemoveCartItems(item, null, false, false, true);
      this.orderService.calculateOrder(isShowNotification);
      //this.orderService.calculateLocalOrder(isShowNotification);
    }
  }

  public setQuantiy() {
    if (this.itemsService.selectedPacks.length > 0) {
      this.itemsService.selectedPacks.forEach((item) =>  {
        this.isKitAdded[item.ItemCode] = true;
        this.packQuantity[item.ItemCode] = item.Quantity;
      });
    }
    if (this.itemsService.selectedOrderItems.length > 0) {
      this.itemsService.selectedOrderItems.forEach((item) =>  {
        this.orderQuantity[item.ItemCode] = item.Quantity;
      });
    }

    if (this.itemsService.selectedAutoOrderItems.length > 0) {
      this.itemsService.selectedAutoOrderItems.forEach((item) =>  {
        this.autoshipQuantity[item.ItemCode] = item.Quantity;
      });
    }
    if (
      (this.itemsService.selectedPacks.length > 0 ||
        this.itemsService.selectedOrderItems.length > 0) &&
      !this.userService.checkItems
    ) {
      //this.orderService.calculateLocalOrder();
      this.orderService.calculateOrder();
    }
    if (
      this.itemsService.selectedAutoOrderItems.length > 0 &&
      !this.userService.checkItems
    ) {
      //this.orderService.calculateLocalAutoOrder();
      this.orderService.calculateAutoOrder();
    }
  }

  private init() {
    this.setQuantiy();
  }

  public updateCountry(country, language, sideNavCall, shippingCall) {
    if (!(Object.keys(language).length > 0)) {
      language =  JSON.parse(sessionStorage.getItem('selectedLanguage'));
    }
    if (sideNavCall) {
      this.sideNavBarService.closeSidePanel();
    }
    if (this.configService.commonData.selectedCountry.toLowerCase() !== country.CountryCode.toLowerCase() || this.configService.commonData.selectedLanguageID !== language.LanguageID) {
      this.oldRestrictedState = this.userService.restrictedStates;
      this.oldRegionMainState = this.userService.regionMainState;
      this.userService.regionMainState = '';
      const request = {
        LanguageCode: this.configService.commonData.selectedLanguageID,
        PriceGroup: this.userService.customerTypeID,
        CategoryId: 0
      };
      this.itemsService.getProducts(request).subscribe((result) =>  {
        try {
          if (result.length != 0) {
            const countrySwal = this.translate.instant('if_you_selected_other_country');
            if (this.itemsService.selectedOrderItems.length || this.itemsService.selectedAutoOrderItems.length) {
              if (result.length > 0) {
                this.restrictedOrderItems = this.getRestrictedItems(this.itemsService.selectedOrderItems, result, false);
                this.restrictedAutoOrderItems = this.getRestrictedItems(this.itemsService.selectedAutoOrderItems, result, true);

              } else {
                this.itemsService.selectedOrderItems = [];
                this.itemsService.selectedAutoOrderItems = [];
              }

              if (this.restrictedOrderItems.length || this.restrictedAutoOrderItems.length) {
                const swalHtml = this.setRestrictItemsSwal();
                this.marketSwalCheckItems(swalHtml, country, language, shippingCall, true);

              } else {
                const swalText = countrySwal + '\n\n';
                if (shippingCall) {
                  this.userService.newshippingAddress.Country = country.CountryCode;
                  this.marketSwalCheckItems(swalText, country, language, shippingCall, false);
                } else {
                  this.marketSwalCheckItems(swalText, country, language, shippingCall, false);
                }
              }
            } else {

              this.marketSwalCheckItems(countrySwal, country, language, shippingCall, false);
            }
          } else {
            if (sessionStorage.getItem('CommonSettings')) {
              const data = JSON.parse(sessionStorage.getItem('CommonSettings') || '[]');
              this.configService.commonData.selectedCountry = data.selectedCountry || 'us';
              this.configService.commonData.selectedLanguageID = data.selectedLanguageID || 0;
              this.itemsService.selectedOrderItems = [];
              this.itemsService.selectedAutoOrderItems = [];
              this.userService.regionMainState = this.oldRegionMainState;
              this.notificationService.error('Error', 'OopsSomethingWentWrong');
            }
          }
        } catch (ex) {
          this.notificationService.error('Error', 'OopsSomethingWentWrong');
        }
      });
    }
  }

  public getRestrictedItems(selectedItems, OrderItem, isAutoship?: boolean) {
    if (OrderItem.length > 0 && selectedItems.length > 0) {
      return selectedItems.filter((item) =>  {
        return !OrderItem.some((item2) =>  {
          if (item2.HasOptions && (!isAutoship || item2.AllowAutoship)) {
            _.each(item2.OptionsMap, (value) =>  {
              if (value.ItemCode == item.ItemCode) {
                item2.ItemCode = value.ItemCode;
                return;
              }
            });
          }
          if ((item2.ItemCode == item.ItemCode && (!isAutoship || item2.AllowAutoship)) && item.selectedOptions) {
            item2.selectedOptions = item.selectedOptions;
          }
          return (item.ItemCode == item2.ItemCode && (!isAutoship || item2.AllowAutoship));
        });
      });
    } else {
      return [];
    }
  }

  public setRestrictItemsSwal() {
    let swalMessage = this.translate.instant('if_you_selected_other_country') + '\n\n';
    if (this.restrictedOrderItems.length) {
      swalMessage = swalMessage + this.translate.instant('order_item_not_avail_in_this_region') + '\n';
      _.each(this.restrictedOrderItems, (item, key) => {
        const logNumber = (key + 1);
        swalMessage = swalMessage + logNumber + '. ' + item.ItemDescription + ',\n';
      });
    }

    if (this.restrictedAutoOrderItems.length) {
      swalMessage = swalMessage + this.translate.instant('autoship_item_not_avail_new_region');
      _.each(this.restrictedAutoOrderItems, (item, key) => {
        const logNumber = (key + 1);
        swalMessage = swalMessage + logNumber + '. ' + item.ItemDescription + ',\n';
      });
    }
    return swalMessage;
  }

  public marketSwalCheckItems(swalText, country, language, shippingCall, checkRestriction) {
    const dialogData = new ConfirmDialogModel(
      this.translate.instant('trying_change_country'),
      this.translate.instant(swalText),
      this.translate.instant('NO'),
      this.translate.instant('YES')
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true,
      panelClass: '',
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (this.configService.commonData.selectedCountry.toLowerCase() !== country.CountryCode.toLowerCase()) {
          this.userService.newshippingAddress = {};
          this.userService.shippingAddress = {} as ShippingAddress;
          this.userService.regionMainState = '';
          this.userService.restrictedStates = [];
          this.userService.selectedShippingMethod = 0;
          this.userService.shipMethods = [];
          if (this.userService.customerData.DefaultShippingAddress) {
            this.userService.customerData.DefaultShippingAddress = {};
          }
          this.userService.paymentMethods = [];
          this.paymentService.clearPayment();
          localStorage.removeItem('paymentService');
        }

        if (shippingCall) {
            this.updateCountryLanguage(country, language);
        } else {
          this.configService.commonData.selectedCountry = country.CountryCode.toLowerCase();
          this.configService.commonData.selectedLanguage = language;
          this.configService.commonData.selectedLanguageID = language.LanguageID;
          this.configService.setSelectedCountry(country.CountryCode.toLowerCase(), language);


              setTimeout(() => {
                window.location.reload();
              }, 500);
        }
      } else {
        this.userService.restrictedStates = this.oldRestrictedState;
        this.userService.regionMainState = this.oldRegionMainState;
        if (sessionStorage.getItem('CommonSettings')) {
          const data = JSON.parse(sessionStorage.getItem('CommonSettings') || '[]');
          this.configService.commonData.selectedCountry = data.selectedCountry;
          this.configService.commonData.selectedLanguageID = data.selectedLanguage;

        }
        if (shippingCall) {
          this.userService.newshippingAddress.Country = this.configService.commonData.selectedCountry.toUpperCase();
          location.reload();
        }
      }
    });
  }

  updateCountryLanguage(country, language) {
    this.userService.newshippingAddress = {};
    this.userService.newshippingAddress.Country = country.CountryCode;
    if (this.userService.customerData && Object.keys(this.userService.customerData).length > 0 && (this.userService.customerData.PrimaryAddress?.CountryCode.toLowerCase() == this.userService.newshippingAddress.Country.toLowerCase())) {
      this.userService.newshippingAddress.StateName = this.userService.customerData.PrimaryAddress.StateName;
    }
    else {
      this.userService.newshippingAddress.StateName = 'null';
    }
    this.configService.commonData.selectedCountry = country.CountryCode.toLowerCase();
    const allowedCountries = JSON.parse(sessionStorage.getItem('allowedCountries'));
    _.each(allowedCountries.Data, (item) => {
      if (item.CountryCode.toLowerCase() == country.CountryCode.toLowerCase()) {
        this.configService.commonData.selectedLanguage = language;
        this.configService.commonData.selectedLanguageID = language.LanguageID;
      }
    });
    this.configService.setSelectedCountry(country.CountryCode.toLowerCase(), this.configService.commonData.selectedLanguage);
  }

  public clearItems() {
    this.isKitAdded = {};
    this.orderQuantity = {};
    this.autoshipQuantity = {};
    this.itemsService.selectedPacks = [];
    this.itemsService.selectedOrderItems = [];
    this.itemsService.selectedAutoOrderItems = [];
  }

  public getCartItems(cartItems) {
    if (cartItems) {
      if (!JSON.parse(localStorage.getItem('isLoggedIn'))) {
        this.clearItems();
      } else {
        localStorage.removeItem('isLoggedIn');
      }

      _.each(cartItems.Order, (cartItem) =>  {
        const isOrderItemExist = _.some(this.itemsService.selectedOrderItems, (item) =>  {
          if (item.HasOptions) {
            _.each(item.OptionsMap, (value) =>  {
              if (value.ItemCode == item.ItemCode) {
                item.ItemCode = value.ItemCode;
                return;
              }
            });
          }
          return (
            item.ItemCode == cartItem.ItemCode
          );
        });
        if (!isOrderItemExist) {
          this.itemsService.selectedOrderItems.push(cartItem);
          this.orderQuantity[cartItem.ItemCode] = cartItem.Quantity;
        }
      });

      _.each(cartItems.Autoship, (cartItem) =>  {
        const isAutoItemExist = _.some(this.itemsService.selectedAutoOrderItems, (item) =>  {
          if (item.HasOptions) {
            _.each(item.OptionsMap, (value) =>  {
              if (value.ItemCode == item.ItemCode) {
                item.ItemCode = value.ItemCode;
                return;
              }
            });
          }
          return (
            item.ItemCode == cartItem.ItemCode
          );
        });
        if (!isAutoItemExist) {
          this.itemsService.selectedAutoOrderItems.push(cartItem);
          this.autoshipQuantity[cartItem.ItemCode] = cartItem.Quantity;
        }

      });

      _.each(cartItems.Packs, (cartItem) =>  {
        const isPacksItemExist = _.some(this.itemsService.selectedPacks, (item) =>  {
          if (item.HasOptions) {
            _.each(item.OptionsMap, (value) =>  {
              if (value.ItemCode == item.ItemCode) {
                item.ItemCode = value.ItemCode;
                return;
              }
            });
          }
          return (
            item.ItemCode == cartItem.ItemCode
          );
        });
        if (!isPacksItemExist) {
          this.itemsService.selectedPacks.push(cartItem);
          this.packQuantity[cartItem.ItemCode] = cartItem.Quantity;
        }
      });
    }
  }

  public resetItemQuantities() {
    if (this.itemsService.selectedPacks.length > 0) {
      const packtemp = {};
      this.itemsService.selectedPacks.forEach((item) =>  {
        packtemp[item.ItemCode] = item.Quantity;
      });
      this.packQuantity = packtemp;
    }
    else {
      this.packQuantity = {};
    }

    if (this.itemsService.selectedOrderItems.length > 0) {
      const ordertemp = {};
      this.itemsService.selectedOrderItems.forEach((item) =>  {
          ordertemp[item.ItemCode] = item.Quantity;
      });
      this.orderQuantity = ordertemp;
    }
    else {
      this.orderQuantity = {};
    }

    if (this.itemsService.selectedAutoOrderItems.length > 0) {
      const autoordertemp = {};
      this.itemsService.selectedAutoOrderItems.forEach((item) =>  {
        autoordertemp[item.ItemCode] = item.Quantity;
      });
      this.autoshipQuantity = autoordertemp;
    }
    else {
      this.autoshipQuantity = {};
    }
  }
  public isPackSetup() {
    
    let totalqty:Array<any>=[];
    let requiredQty=0;
    if (this.itemsService.selectedPacks && this.itemsService.selectedPacks.length > 0) {
      this.itemsService.selectedPacks.forEach(pack => {
        if (pack.DynamicKitCategories.length > 0) {
          var buildkit = JSON.parse(localStorage.getItem('cart.buildkit')) || [];
          //console.log('buildkit', buildkit);
          pack.DynamicKitCategories.forEach((option, i) => {
            requiredQty+=option.Quantity;
            if (option.Items.length > 1) {
              // console.log('items11', option.Items);
              totalqty[i] = 0;
              if(buildkit.length > 0){
                option.Items.forEach((items) => {
                  // console.log('items', items);
                  buildkit.forEach((buildItem, index) => {
                    buildItem.forEach((selectedbuildItem, index) => {
                    if(selectedbuildItem.ItemCode == items.ItemCode){
                      totalqty[i] += selectedbuildItem.itemqty;
                    }
                  });
                  });            
                })
              }
              
            }
            else {
              totalqty[i] = option.Quantity;
            }
          });
          if(requiredQty==totalqty.reduce((a, b) => a + b, 0)){
            this.isPackBuild = true;
          }else{
            this.isPackBuild = false;
          }
        } else {
          this.isPackBuild = true;
        }
      });
    }else{
      this.isPackBuild = true;
    }
    return this.isPackBuild;
  }
}

import * as moment from "moment";

export interface PersonalInfo {
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    phoneNumber: string;
    Email: string;
    PartnerFirstName: string;
    PartnerLastName: string;
    PartnerEmail: string;
    PartnerPhone: string;
    SendMail: boolean;
    showbusinessEntity: boolean;
    showPartnerEntity: boolean;
    Company: string;
}
export interface FreeShipping {
    activated: boolean;
    coupon: string;
}
export interface CouponInfo {
    IsAppliedcode: boolean;
    promoCodeValid: boolean;
    promoCode: string;
    Allcoupons: Array<any>;
    RewardsForUse: Array<any>;
    FreeShipping: FreeShipping;
    availableRewards: Array<any>;
    OrderAllowCoupons: [];
}
export interface CardDetail {
    CardExpirationYear: number;
    CardExpirationMonth: number;
    CardNumber: string;
    CardName: string;
    CardCvv: string;
    Token: string;
    fruadGuid: string;
    firstSix: string;
    lastFour: string;
    Type?: number | string;
}
export interface DynamicCouponCode {
    promoCode: string;
}
export interface ShippingAddress {
    AddressId: 0;
    City: string;
    CountryCode: string;
    PostalCode: string;
    Region: string;
    Street1: string;
    Street2: string;
    Street3: string;
    FirstName: string;
    LastName: string;
    Phone?: string;
    StateName: string;
    FullName?: string;
    Email?: string;
    Instruction?: string;
    StateNameAsText?: string
}
export interface WebOffice {
    UserName: string;
    Password: string;
    DomainName: string;
    ConfirmPassword: string;
    TaxID: string;
    CURP: string;
    TaxPayerNumber: string;
}

export interface ReferralInfo {
    FirstName: string;
    LastName: string;
    SponsorID?: number;
}
export interface BillingAddress {
    City: string;
    CountryCode: string;
    PostalCode: string;
    Region: string;
    Street1: string;
    Street2: string;
    Street3: string;
    FirstName: string;
    LastName: string;
    Phone?: string;
    StateName: string;
    FullName?: string;
    Email?: string;
    Instruction?: string;
}
export class UserServiceModal {
    selectedLanguageID: string;
    selectedCountry: string;
    WebAlias: string;
    customerTypeID: number;
    enrollerInfo: any;
    guestUserLogin: boolean;
    customerData: any = {};
    guestUserData: any;
    shippingAddress: ShippingAddress;
    mailingAddress: any;
    newshippingAddress: any;
    personalInfo: PersonalInfo;
    webOffice: WebOffice = {} as WebOffice;
    couponInfo: CouponInfo;
    DynamicCouponCode: DynamicCouponCode;
    paymentMethods: Array<any>;
    referralURL: string;
    restrictedStates: Array<any>;
    restrictedShipStates: string;
    selectedShippingMethodName: string;
    selectedShippingMethod: number;
    shipMethods: Array<any>;
    autoshipCardDetail: any;
    cardDetail: CardDetail;
    commissionPayment: any;
    licenseNumber: string;
    legName: string;
    enrollerId: number;
    sponsorId: number;
    doNotWishAutoship: boolean;
    checkItems: boolean;
    autologin: boolean;
    regionMainState: string;
    newUser: boolean;
    TotalSteps: number;
    noOfStepsVerified: number;
    defaultState: string;
    isAddressChanged: boolean = false;
    isEditAutoshipAddressChanged: boolean = false;
    isPaymentChanged: boolean = false;
    isShipMethodChanged: boolean = false;
    sponsorSectionPanel: boolean = false;
    useSamePaymentForAutoship = true;
    selectedfrequencyTypeID: number = 1;
    selectedfrequency: string;
    referralInfo: ReferralInfo;
    isReferredBy: boolean;
    billingAddress: BillingAddress = {} as BillingAddress;
    customerContact: any = {}
    autoshipDate: Date = moment().add(1, 'months').toDate();
    promotionOffer: boolean = false;
    addressCheck: boolean = false;
    IsshowPromotionOption: boolean = true;
    constructor() {

    }
}
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private subject = new Subject<any>();
  private subjectEnroll = new Subject<any>();
  constructor() {}


  sendSubmitCheckout() {
    this.subject.next(void 0);
  }
  getSubmitCheckout():Observable<any> {
    return this.subject.asObservable()
  }
  sendReviewCheckout() {
    this.subject.next(void 0);
  }
  getReviewCheckout():Observable<any> {
    return this.subject.asObservable()
  }
  sendEnrollmentApplication(){
    this.subjectEnroll.next(void 0);
  }
  getEnrollmentApplication(){
    return this.subjectEnroll.asObservable();
  }

}

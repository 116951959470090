import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../core/services/product.service';
import * as  _ from 'lodash';
import { UserService } from '../../core/services/user.service';
import { OrderService } from '../../core/services/order.service';
import { CartService } from '../../core/services/cart.service';
import { RestApiService } from '../../core/http/restapi.service';
import { AccountService } from '../../core/authentication/account.service';
import { Title } from '@angular/platform-browser';
import { UserServiceModal } from '../../core/modals/userservice.modal';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from '../../core/services/utility.service';
import { AutoshipConfigurationService } from '../../core/services/autoshipConfiguration.service';
import { ConfigService } from '../../core/services/config.service';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginDetails:any={};
  accessToken: string;
  userInfo: any = {};

  userService: UserServiceModal;
  public ViewSection = [];
  public SignupSetting = [];
  public guestUser = {};
  public issignin = true;
  public isguest = true;
  public showLoginSection: boolean = false;
  autoLogin: boolean = false;
  previousType: any;

  constructor(
    private titleService: Title,
    private router: Router,
    private accountService: AccountService,
    public user: UserService,
    private route: ActivatedRoute,
    public orderService: OrderService,
    public cartsService: CartService,
    public notificationService: NotificationService,
    public translate: TranslateService,
    public itemsService: ProductService,
    public apiService: RestApiService,
    private cookieService: CookieService,
    public utilityService: UtilityService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public configService: ConfigService,public seo:SeoService
    ) {
      this.userService = user.userServiceModal;
      const cookieAcceptedStatus = this.cookieService.get('cookieAccepted') || "not-accepted";
      this.cookieService.deleteAll('/');
      if(cookieAcceptedStatus != "not-accepted") {
        this.cookieService.set("cookieAccepted", "true", undefined, "/")
      }
      this.seo.setTags({
        title: 'Login', // Title
        titleSuffix: '- Rain', // Title Suffix
        description: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.', // Description
        image: 'assets/images/logo.png', // Image
        keywords: 'World leader in seed based energy and health nutrition supplement products. Prepared with a blend of black cumin and natural seeds. Order here now.' // Keywords
      });
    }

  ngOnInit() {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Login') + ' - ' + text);
    });
  }

  redirectToForgotPassword() {
    this.router.navigateByUrl('/pages/ForgotPassword', { skipLocationChange: false });

  }

  loginFunction() {

    const loginrequest = {
      "LoginName": this.userInfo.LoginName,
      "Password": this.userInfo.Password,
      "RealTime": true,
    };
 
    this.apiService.Login(loginrequest).subscribe(
      {
        next: (result) => {
          if (result && result.status == 200 && result.body.Status != 1) {
            if (result.body && result.body.Data) {
              if (result.body.Data.RedirectUrl && result.body.Data?.Identity?.CustomerTypeID == 3) {
                this.manageDistributorCustomerLogin(result);
                return;
              } else if (result.body.Data.RedirectUrl) {
                window.location.href = result.body.Data.RedirectUrl
              } else {
                this.manageRetailCustomerLogin(result);
              }
            } else {
              this.notificationService.error('Error', 'Authentication Failed');
            }
          } else {
            this.notificationService.error('Error', 'Authentication Failed');
          }
        }, error: err => {
          this.notificationService.error('Error', 'Oops Something Went Wrong');
        },
        complete:() => {
          // Do stuff after completion
        }
    });
    
  }

  checkitem() {
    return (this.itemsService.selectedOrderItems.length > 0 || this.itemsService.selectedAutoOrderItems.length > 0);
  }

  private loginredirect(alias) {
    let newUrl: any;
    //const subdomain = getSubdomain(window.location.host);
    newUrl = `${location.origin}/${alias}/products`;
    // if (subdomain) {
    //   newUrl = window.location.href.replace(subdomain, this.userService.WebAlias);
    // }
    if(this.checkitem()){
      this.userService.checkItems = true;
      window.location.assign(newUrl.replace('login', 'review'));
    }
    else if (this.itemsService.selectedAutoOrderItems.length) {
      //this.userService.checkItems = true;
      window.location.assign(newUrl.replace('login', 'products'));
    } else {
      window.location.assign(newUrl.replace('login', 'products'));
    }
  }


  manageRetailCustomerLogin(result) {
    this.user.clearCustomerData();
    localStorage.setItem('isLoggedIn', 'true');
    this.loginDetails = result.body;
    let url = result.body.Data.RedirectUrl;

    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + 30);
    this.cookieService.set('X-Auth',(this.loginDetails.Data.access_token || ''), expiration, '/' );
    this.cookieService.set('authenticationExpirationDate', (expiration.toString()), expiration, '/');


    let PrefferedLanguage = null;

    if(result.body.Data?.Identity?.Language?.LanguageID) {
      PrefferedLanguage = result.body.Data?.Identity?.Language;
    }

    this.accountService.getCustomerData(PrefferedLanguage).then((data: any) => {
    if (data && data.Data) {
      localStorage.setItem('user', JSON.stringify(data.Data));
      this.userService.customerData = data.Data;
      this.userService.customerData.RedirectURL = url;
      this.userService.customerTypeID = this.userService.customerData.CustomerType;
      if (!this.utilityService.isEmptyObject(this.userService.customerData.DefaultShippingAddress)) {
        this.user.setShippingAddress();
      } else {
        this.userService.shippingAddress = new UserServiceModal().newshippingAddress;
      }

      /**
             */

      const sponsorID = this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID || 0;
      if(sponsorID > 0) {
        this.apiService.getSearchCustomerDetail(sponsorID)
        .subscribe((result) => {
          let { Customer, WebAlias } = result && result.Data;
          let EnrollerInfo = Customer, EnrollerWebAlias = WebAlias;
          
          // set enroller info
          if(EnrollerInfo) {
            this.userService.enrollerInfo = Customer;
          }

          // continue webalias set process
          if (this.userService.customerData.WebAlias) {
            this.apiService.validateWebAlias(this.userService.customerData.WebAlias).subscribe({
              next: (res: any) => {
              if (res.Data) {
                this.userService.WebAlias = this.userService.customerData.WebAlias;
                this.loginredirect(this.userService.WebAlias);
              }
            },error: (error) => {
              if (error.Data && !error.Data.WebAlias) {
                this.notificationService.error('Error', 'InvalidWebAlias');
                return false;
              }
            },
            complete:() => {
              // Do stuff after completion
              }
            });
          } else {
            if (EnrollerWebAlias) {
              this.apiService.validateWebAlias(EnrollerWebAlias.WebAlias).subscribe({
                next: (res: any) => {
                  if (res.Data) {
                    this.userService.WebAlias = EnrollerWebAlias.WebAlias;
                    // checkWebAliasService.checkWebAlias();
                    this.loginredirect(this.userService.WebAlias);
                  }
                }, error:(error) => {
                  if (error.Data && !error.Data) {
                    this.notificationService.error('Error', 'InvalidWebAlias');
                    return false;
                  }
                },
                complete:() => {
                  // Do stuff after completion
                }
              });
            }
          }
        })
      }


      /**
       */   

      // if (this.userService.customerData.WebAlias) {
      //   this.apiService.validateWebAlias(this.userService.customerData.WebAlias).subscribe({next:(res: any) => {
      //     if (res.Data) {
      //       this.userService.WebAlias = this.userService.customerData.WebAlias;
      //       this.loginredirect(this.userService.WebAlias);

      //     }
      //   },error: (error) => {
      //     if (error.Data && !error.Data.WebAlias) {
      //       this.notificationService.error('Error', 'InvalidWebAlias');
      //       return false;
      //     }
      //   },
      //     complete:() => {
      //       // Do stuff after completion
      //     }});
      // }
      // else if (this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID) {
      //   this.apiService.getSearchCustomerDetail(this.userService.customerData.SponsorID || this.userService.customerData.EnrollerID).subscribe({next:(resp: any) => {
      //     if (resp.Data) {
      //       this.apiService.validateWebAlias(resp.Data.WebAlias.WebAlias).subscribe({next:(res: any) => {
      //         if (res.Data) {
      //           this.userService.WebAlias = resp.Data.WebAlias.WebAlias;
      //           this.loginredirect(this.userService.WebAlias);
      //         }
      //       }, error:(error) => {
      //         if (error.Data && !error.Data) {
      //           this.notificationService.error('Error', 'InvalidWebAlias');
      //           return false;
      //         }
      //       },
      //         complete:() => {
      //           // Do stuff after completion
      //         }});
      //     }
      //   }, error:(error) => {
      //     if (error.Data && !error.Data.WebAlias) {
      //       this.notificationService.error('Error', 'InvalidWebAlias');
      //       return false;
      //     }
      //   },
      //     complete:() => {
      //       // Do stuff after completion
      //     }});
      // }
    } else {
      this.notificationService.error('Error', 'OopsSomethingWentWrong');
    }
    }).catch((error) => {
      this.notificationService.error('Error', 'OopsSomethingWentWrong');
      console.error('ex', error);
    });
  }

  manageDistributorCustomerLogin(result) {
    window.location = result.body.Data.RedirectUrl;
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

function getRootDomain() {
  try {
    let url = window.location.origin;
    var domain = new URL(url).hostname;
    if (domain.startsWith('www.')) {
      return 'https://' + domain.substring(4) + '/';
    } else {
      return 'https://' + domain + '/';
    }
  } catch (ex) {}
  return 'https://rainintl.com/';
}

export const environment = {
  production: true,
  siteUrl: getRootDomain(),
  apiUrl: location.host.match(/stage\.*wsicloud\.net/) || location.host.match(/stage\.*seednutrition\.com/)
      ? 'https://shopapi-stage.seednutrition.com/'
    : location.host.match(/.*wsicloud\.net/) || location.hostname === 'localhost' || location.host.match(/dev\.*seednutrition\.com/)
      ? 'https://shopapi-dev.seednutrition.com/'
    : location.host.match(/.*rainintl\.com/)
      ? 'https://apilive.rainintl.com/'
    : 'https://apilive.rainintl.com/',
      // : 'https://retailapi.seednutrition.com/',
  trackingapiUrl: location.host.match(/stage\.*wsicloud\.net/)
      ? 'https://trackingapi-stage.ziplingo.com/'
    : location.host.match(/.*wsicloud\.net/) || location.hostname === 'localhost'
      ? 'https://trackingapi-stage.ziplingo.com/'
    : 'https://trackingapi-stage.ziplingo.com/',
  backOfficeUrl:
    location.host.match(/stage\.*seednutrition.com/) || location.host.match(/stage\.*wsicloud\.net/)      
      ? 'https://rainoffice-stage.seednutrition.com/'
    : location.host.match(/dev\.*seednutrition.com/) || location.host.match(/.*wsicloud\.net/)  || location.hostname === 'localhost'
      ? 'https://rainoffice-dev.seednutrition.com/'
    : location.host.match(/new.*rainintl\.com/)
      ? 'https://rainoffice.seednutrition.com/'
    : location.host.match(/.*rainintl\.com/)
      ? 'https://rainoffice.rainintl.com/'
    : 'https://rainoffice.rainintl.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { cloneDeep } from 'lodash';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { RestApiService } from '../http/restapi.service';
import { User } from 'src/app/core/modals/user.modal';
import { UserServiceModal, ShippingAddress } from 'src/app/core/modals/userservice.modal';
import { ConfigService } from '../services/config.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from '../services/utility.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  userServiceModal: UserServiceModal;
  @Output() ssoValdiatedSuccessfully: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private router: Router,
    private http: HttpClient,
    public apiService: RestApiService,
    public configService: ConfigService,
    public userService: UserService,
    public notificationService: NotificationService,
    private cookieService: CookieService,
    private utilityService: UtilityService

  ) {
    if (window.localStorage) {
      this.userSubject = new BehaviorSubject<User>(JSON.parse((window.localStorage && window.localStorage.getItem('user'))));
    } else {
      this.userSubject = new BehaviorSubject<User>(JSON.parse('{}'));

    }
    this.user = this.userSubject.asObservable();
    this.userServiceModal = this.userService.userServiceModal;
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(loginrequest) {
    return this.apiService.Login(loginrequest)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (window.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(user.body));
        }
        this.userSubject.next(user.body);
        return user;
      }));
  }
  createGuestUser(createGuestRequest) {
    return this.apiService.createCustomer(createGuestRequest)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (window.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(user));
        }
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    this.cookieService.delete('access_token', '/');
    this.cookieService.delete('X-Auth', '/');
    const cookieAcceptedStatus = this.cookieService.get('cookieAccepted') || "not-accepted";
    this.cookieService.deleteAll('/');
    if(cookieAcceptedStatus != "not-accepted") {
      this.cookieService.set("cookieAccepted", "true", undefined, "/")
    }
    sessionStorage.removeItem('isValidatedFromSSO')
    // remove user from local storage and set current user to null
    //this.apiService.Logout().subscribe({next:() => {
      this.router.navigate(['/home']).then(() => {
        if (window.localStorage && window.sessionStorage) {
          window.localStorage.removeItem('user');
          window.localStorage.clear();
          window.sessionStorage.clear();
          this.userService.setDefault();
          this.userSubject.next(null);
        }
        window.location.reload();
      });
    //}});
  }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  getCustomerData(PrefferedLanguage = null) {
    const promise = new Promise((resolve, reject) => {
      this.apiService.getCustomer({"CustomerID" : 0}).subscribe({next:(result) => {
        //console.log('result.Data', result.Data);
        if (result && result.Data) {
          result.Data = result.Data.Customers[0]
          this.userServiceModal.customerData = result.Data;
          this.userServiceModal.customerData.DefaultShippingAddress = {
            AddressId: '',
            City: result.Data.MainCity,
            CountryCode: result.Data.MainCountry,
            PostalCode: result.Data.MainZip,
            Region: result.Data.MainState,
            Street1: result.Data.MainAddress1,
            Street2: result.Data.MainAddress2,
            Street3: result.Data.MainAddress3
          }
          try {
            if (!(Object.keys(this.configService.commonData?.selectedLanguage).length > 0) ||
              ((Object.keys(this.configService.commonData?.selectedLanguage).length > 0) && this.configService.commonData?.selectedCountry.toLowerCase() !== result.Data.MainCountry.toLowerCase())) {
              _.each(this.configService.allowedCountries, (item) => {
                if (item.CountryCode == result.Data.MainCountry &&
                  this.configService.allowedLanguages.filter((val) => {
                    return (val.LanguageID == result.Data.LanguageID);
                  }
                  )) {
                  this.configService.commonData.selectedLanguageID = result.Data.LanguageID || 0;
                }
              });
            }
          } catch {

          }
          // for autologin
          // if (this.userServiceModal.customerData.LanguageCode) {
          //   this.configService.commonData.selectedLanguageID = this.userServiceModal.customerData.LanguageCode;
          // }

          if(PrefferedLanguage) {
            this.configService.setSelectedCountry(this.userServiceModal.customerData.MainCountry || this.userServiceModal.customerData.MainCountry, { LanguageID: PrefferedLanguage.LanguageID, CultureCode: PrefferedLanguage.CultureCode });
          } 
          
          else {
            this.configService.setSelectedCountry(this.userServiceModal.customerData.MainCountry || this.userServiceModal.customerData.MainCountry, { LanguageID: this.configService.commonData.selectedLanguage.LanguageID, CultureCode: 'en' });
          }


          
          // this.configService.this.configServicePromise.then(() => {
          // this.configService.commonData.selectedState = (this.userServiceModal.customerData.DefaultShippingAddress.length && this.userServiceModal.customerData.DefaultShippingAddress[0].MainState) || this.userServiceModal.customerData.MainState;
          // });
          this.userServiceModal.shippingAddress = !this.utilityService.isEmptyObject(this.userServiceModal.customerData.DefaultShippingAddress) ?
            cloneDeep(this.userServiceModal.customerData.DefaultShippingAddress) : {};

          if (this.userServiceModal.customerData.DefaultShippingAddress.Street1 == 'No Address on file') {
            // check if loginned user have address saved
            this.userServiceModal.customerData.DefaultShippingAddress = [];
            this.userServiceModal.shippingAddress = {} as ShippingAddress;
          }

          try {
            resolve(result);
          } catch (ex) {
            reject();
            console.error('ex', ex);
          }
        } else {
          reject();
          this.notificationService.error('Error', result.ErrorDescription);
        }
      }});
    });
    return promise;
  }
  
  isLoggedIn(): boolean {
    let isLoggedIn = false;
    // this.apiService.loginStatus.subscribe(val => {
    //   //alert();
    //   isLoggedIn = val;
    // });
    if(this.apiService.hasToken()) {
      isLoggedIn = true;
    }
    return isLoggedIn;
  }

  LoginIntoBackoffice(LoginName: any, Password: any) {
    const loginrequest = {
      "LoginName": LoginName,
      "Password": Password,
      "RealTime": true,
    };
    this.apiService.Login(loginrequest).subscribe(
      {
        next: (result) => {
          if (result && result.status == 200 && result.body.Status != 1) {
            if (result.body && result.body.Data && result.body.Data.RedirectUrl) {
              window.location = result.body.Data.RedirectUrl;
              return;
            } else {
              this.notificationService.error('Error', 'Authentication Failed');
            }
          } else {
            this.notificationService.error('Error', 'Authentication Failed');
          }
        }, error:err => {
          this.notificationService.error('Error', 'Oops Something Went Wrong');
        },
        complete:() => {
          // Do stuff after completion
        }
    });
  }

  isLoggedUserRetailType() {
    if(this.isLoggedIn()){
      return this.userService.userServiceModal?.customerData?.CustomerType == 1 // Retail
    }
    return true;
  }

  isLoggedUserDistributorType(){
    if(this.isLoggedIn()){
      return this.userService.userServiceModal?.customerData?.CustomerType == 3 // BP or Distributor
    } else {
      return false;
    }
  }

  getLoggedInPrice(item: any) {

    let price = item['Price'];

    const customerData = this.userService.userServiceModal?.customerData || JSON.parse(localStorage.getItem('user') || '{}') || {};
    
    if(customerData?.CustomerType > 0) {
      price = item[`PriceType${customerData.CustomerType}Value`];
    } else if(this.userService.userServiceModal.customerTypeID) {
      price = item[`PriceType${this.userService.userServiceModal.customerTypeID}Value`];
    }

    return price;

  }

}

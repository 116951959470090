<div [style.background-color]="color" fxLayout="column" class="feed" *ngIf="image || htmlText">

    <div [innerHtml]="htmlText" class="feed-inner" *ngIf="htmlText">

    </div>
    <div *ngIf="image">
        <img [src]="image" class="avtar">
        <span class="cus_name" [textContent]="customername">ssssssss</span>
    </div>
</div>

<div class="rain-prod-search-dialog">
  <div fxLayout="row" class="market-selector ms-padding">
    <div fxFlex="100" fxLayoutAlign="space-between center">
      <h2 class="country-selection" mat-dialog-title translate="search_product"></h2>
      <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
    </div>
  </div>
  <hr class="mb-15">
  <div fxLayout="column" class="ms-padding">
    <div fxFlex="100" fxLayout="column" class="m-b-15" fxLayoutAlign="center start">
      <h2 class="m-0 f-s-15" mat-dialog-title translate="search_for_products_by_name"></h2>
      <div fxLayout="row" class="w-100">
        <div fxFlex="95" class="">
          <mat-form-field class="search-prod-input border w-100">
            <mat-label translate="search_product"></mat-label>
            <input matInput #product [(ngModel)]="product.value" (keyup.enter)="searchProduct(product.value)" >
          </mat-form-field>
        </div>
        <button fxFlex="5" [disableRipple]="true" mat-button class="search_btn m-t-10" (click)="searchProduct(product.value)">
          <mat-icon>search</mat-icon>
        </button>
      </div>

    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { CartService } from 'src/app/core/services/cart.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/model/confirm-dialog/confirm-dialog.component';
import { ContactSupportPopupComponent } from 'src/app/shared/model/contact-support-popup/contact-support-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-paymentfail',
  templateUrl: './paymentfail.component.html',
  styleUrls: ['./paymentfail.component.scss']
})
export class PaymentfailComponent implements OnInit, OnDestroy {
  customerToken: any;
  orderNumber: any = "";
  error: any = "";
  constructor(
    public cartService: CartService,
    private apiService: RestApiService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(window.sessionStorage){
      this.customerToken = sessionStorage.getItem('CustomerToken') === 'undefined' ? '' : sessionStorage.getItem('CustomerToken');
    }
    sessionStorage.removeItem('NewCustomerId');
    sessionStorage.removeItem('NewOrderID');
    sessionStorage.removeItem('NewAutoOrderID');
    this.cartService.sidebarSummaryCartDrawer.close();
    this.route.queryParams
      .subscribe((params: any) => {
        this.error = params.error;
      }
    );
    this.getOrderDetails();
  }


  ngOnDestroy() {
    console.log('unload calling');
    if(window.sessionStorage){
      sessionStorage.removeItem('NewCustomerId');
      sessionStorage.removeItem('NewOrderID');
      sessionStorage.removeItem('NewAutoOrderID');
      sessionStorage.removeItem('CustomerToken');
      sessionStorage.removeItem('exigosessionId');
      window.location.reload();
    }
  }

  RetryProcess(){
    if(window.localStorage){
      if(this.customerToken){
        window.location.href = `${environment.apiUrl}AlternatePayment/index?token=${this.customerToken}`;
      }
    }
  }

  getOrderDetails(){
    this.customerToken && this.apiService.OrderComplete(this.customerToken).subscribe((result) => {
      if(result?.Data){
        this.orderNumber = result?.Data?.OrderID;
      }
    }, (error) => {})
  }

  contactSupport(){
    this.dialog.open(ContactSupportPopupComponent, {
      minWidth: '300px',
      width: '1320px',
      maxWidth: '1320px',
      panelClass: "ContactSupport-Dialog_Popup",
      disableClose: false
    });
  }

  SkipToHomepage(){
    let orderRefMessage = '';
    if(this.orderNumber) {
      orderRefMessage = this.translate.instant('OrderStatus_00') + " " + this.translate.instant('OrderNumber') + ': #' + this.orderNumber;
    }
    const dialogData = new ConfirmDialogModel(
      this.translate.instant('SkipToHome_warning'),
      orderRefMessage,
      this.translate.instant('Close'),
      this.translate.instant('Proceed')
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      width: '100%',
      maxWidth: '1020px',
      panelClass: "SkipToHome-Dialog_Popup",
      disableClose: false
    })
    dialogRef.afterClosed().subscribe((response) => {
      if(Boolean(response)){
        this.router.navigate(['/home']);
      }
    })
  }
}

<div class="rain-cash-wallet-dialog">
  <div fxLayout="row" class="ms-padding border-bottom">
    <div fxFlex="100" fxLayoutAlign="start center" fxLayoutGap="7px">
      <span class="m-b-0 Rain-Cash f-w-700 f-s-16" mat-dialog-title>
        Rain Cash Account Balance: 
      </span>
      <span class="f-w-700">{{ userService?.customerData?.UserCashAccount?.Balance  | number:'2.0' }}</span>
    </div>
  </div>
  <div fxLayout="column" class="ms-padding">
    <div fxLayout="row" fxLayout.lt-md="column" class="f-s-15 border-bottom p-b-10 m-b-25" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxLayoutGap="30px" fxLayoutGap.lt-md="0">
      <div fxLayoutAlign="center center" fxLayoutGap="16px">
        <span mat-dialog-title class="m-b-0 f-w-700 f-s-16">
          Rain Cash For this Order: 
        </span>
        <span class="">{{ cashPointsCheckout.cashAmount | number:'1.2-2' }}</span>
      </div>
      <div fxLayoutAlign="center center" fxLayoutGap="16px">
        <span mat-dialog-title class="m-b-0 f-w-700 f-s-16">
          Remainig Rain Cash: 
        </span>
        <span class=""> {{ orderService.calculateOrderResponse.SubTotal <= userService?.customerData.UserCashAccount?.Balance ? ((orderService.calculateOrderResponse.SubTotal -  cashPointsCheckout.cashAmount) | number:'1.2-2') : ((userService?.customerData.UserCashAccount?.Balance -  cashPointsCheckout.cashAmount) | number:'1.2-2') }} </span>
      </div>
    </div>

    <div fxLayout="column" class="m-0 eligible-items-list" fxLayoutAlign="start start">
      <div class="m-b-5 productList" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="space-between center"
        *ngFor="let product of orderService?.pointsPaymentCheckout?.allItemsEligibleForRainCashAmount">
        <mat-checkbox color="primary" checked="false" fxFlex="5" (change)="handleProductSelection(product.PointItemID, product, $event)" [checked]="product?.isUsingRainCash == true" [disabled]="cashPointsCheckout.shouldBeDisabled2(product.Price, product.PointItemID)">
        </mat-checkbox>
        <div fxFlex="95" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="15px" fxLayoutGap.lt-md="0">
          <div fxFlex="20" fxFlex.lt-md="auto" class="max-w-sm-30 ">
            <img [default]="'../.././../../assets/images/noimage.png'"
            [src]="env.apiUrl + 'api/Images' + product?.LargeImageUrl"  alt="" class="img-fluid">
          </div>
          <div fxFlex="70"  fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="15px">
            <span fxFlex="65" class="f-w-700">{{ product.ItemDescription }}</span>
            <span >{{ product.Price | number: '1.2-2' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" class="save-btn-row" fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button class="accept_btn">
      <span [textContent]="('Accept' | translate)" (click)="cashPointsCheckout.accept()"></span>
    </button>
  </div>
</div>
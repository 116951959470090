

import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { ActivatedRoute, RoutesRecognized } from '@angular/router';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { RestApiService } from '../http/restapi.service';
import { Subscription } from 'rxjs';

export const Months = [
    { text: 'January', value: '01' },
    { text: 'February', value: '02' },
    { text: 'March', value: '03' },
    { text: 'April', value: '04' },
    { text: 'May', value: '05' },
    { text: 'June', value: '06' },
    { text: 'July', value: '07' },
    { text: 'August', value: '08' },
    { text: 'September', value: '09' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' }
];
@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(
        public route: ActivatedRoute,
        public http: HttpClient,
        private sanitizer: DomSanitizer,
        private apiService: RestApiService
    ) {

    }

    isPackMultipleQuantity = true;
    isShowSimplifiedheader = false;
    // /**
    // *Get utility
    // * @method  birthDays
    // * @method  birthYears
    // * @method  birthMonths
    // * @method  getSortKey
    // */
    birthDays(BirthYear?: number, BirthMonth?: number) {
        const year = BirthYear ? Number(BirthYear) : (moment().year() - 18);
        const month = BirthMonth ? Number(BirthMonth) : Number('01');
        const num = new Date(year, month, 0).getDate();
        let i;
        const days = [];
        for (i = 1; i <= num; i++) {
            if (String(i).length === 1) {
                days.push('0' + String(i));
            } else {
                days.push(String(i));
            }
        }
        return days;
    }

    birthYears() {
        let years = [];
        const currentyear = moment().year();

        for (let i = currentyear - 100; i <= currentyear - 18; i++) {
            years.push({ text: String(i), value: String(i) });
        }

        years = years.reverse();
        return years;
    }

    birthMonths() {
        const months = Months;
        return months;
    }

    cardExpYears() {
        let years = [];
        const currentyear = moment().year();

        for (let i = currentyear; i <= currentyear + 20; i++) {
            years.push({ text: String(i), value: String(i) });
        }

        //years = years.reverse();
        return years;
    }

    cardExpMonths() {
        const months = [
            { text: 'January', value: '1' },
            { text: 'February', value: '2' },
            { text: 'March', value: '3' },
            { text: 'April', value: '4' },
            { text: 'May', value: '5' },
            { text: 'June', value: '6' },
            { text: 'July', value: '7' },
            { text: 'August', value: '8' },
            { text: 'September', value: '9' },
            { text: 'October', value: '10' },
            { text: 'November', value: '11' },
            { text: 'December', value: '12' }
        ];
        return months;
    }

    getSortKey() {
        return [
            { id: 1, name: 'item_name', lname: 'A-Z', sortby: 'ItemDescription', sorttype: 'desc' },
            { id: 2, name: 'item_name', lname: 'Z-A', sortby: 'ItemDescription', sorttype: 'asc' },
            {
                id: 3,
                name: 'global_qv',
                lname: 'high_to_low',
                sortby: 'QV',
                sorttype: 'asc'
            },
            {
                id: 4,
                name: 'global_qv',
                lname: 'low_to_high',
                sortby: 'QV',
                sorttype: 'desc'
            },
            { id: 5, name: 'price', lname: 'high_to_low', sortby: 'Price', sorttype: 'asc' },
            { id: 6, name: 'price', lname: 'low_to_high', sortby: 'Price', sorttype: 'desc' }
        ];

    }

    getParameterByName(name, url) {
        if (!url) { url = window.location.href; }
        name = name.replace(/[\[\]]/g, '\\$&'); /*eslint no-useless-escape:0*/
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) { return null; }
        if (!results[2]) { return ''; }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    getDefaultTranslation(translated, key, defaultValue) {
        if (translated == key) {
            return defaultValue;
        } else {
            return translated;
        }
    }

    getQueryParam(param) {
        if (param != null) {
            const search = this.route.snapshot.queryParams;
            if (Object.keys(search).length > 0) {
                const newObject = {};
                _.each(search, (value, key) => {
                    newObject[key.toLowerCase()] = value;
                });
                return newObject[param.toLowerCase()] || '';
            } else {
                return null;
            }
        }
    }

    getQueryParamKey(param) {
        if (param != null) {
            const search = this.route.snapshot.queryParams;
            if (Object.keys(search).length > 0) {
                let parmKey;
                _.each(search, (value, key) => {
                    if (key.toLowerCase() == param.toLowerCase()) {
                        parmKey = key;
                    }
                });
                return parmKey;
            } else {
                return null;
            }
        }
    }

    isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }

    setAutoshipEditFlag(value) {
        if (window.localStorage) {
            window.localStorage.setItem('isEditAutoshipFlag', value);
        }
    }

    getAutoshipEditFlag() {
        if (window.localStorage) {
            return window.localStorage.getItem('isEditAutoshipFlag') == 'true' ? true : false;
        } else {
            return true;
        }
    }
    showSimplifiedheader(value = false) {
        //console.log('asdas', value);
        this.isShowSimplifiedheader = value;
    }
    isEnrollment() {
        if (window.sessionStorage) {
            return !!JSON.parse(sessionStorage.getItem('IsEnrollment'));
        } else {
            false;
        }
    }
    getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };

    async getBase64ImageFromUrl(imageUrl) {
        var res = await fetch(imageUrl);
        var blob = await res.blob();

        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }

    createSession() {
        const req = {
            companyKey: 'rain'
        }
        this.apiService.CreateSession(req).subscribe(data => {
            if (data.status == 200) {
                localStorage.setItem('sessionId', data.data);
            }
        })
    }
    createExigoSession() {
        const req = {
            "SessionID": "",
            "SessionData": ""
        }
        this.apiService.CreateExigoSession(req).subscribe(data => {
            if (data.Status == 0) {
                sessionStorage.setItem('exigosessionId', data.Data);
            }
        })
    }

    setStats(ev, webalias) {
        let pathArray = ev.urlAfterRedirects.split('/');
        let pageName = pathArray[pathArray.length - 1];
        const req = {
            sessionId: localStorage.getItem('sessionId'),
            eventKey: "PageView",
            "para1": JSON.stringify({
                "pagename": pageName,
                "pageurl": ((location.origin + '/' + webalias + ev.urlAfterRedirects) || location.href)
            })
        }
        this.apiService.CreateStats(req).subscribe(() => { /* TODO document why this arrow function is empty */ });
    }
}

import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ValidateKeywordService } from 'src/app/core/services/validatekeyword.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  radioYesClicked = 'no';
  ShowPassword = false;
  //public createcustomerForm: CreateCustomer
  public EmailCheck: boolean = true;
  loadingDetails: boolean = true;
  public userService: UserServiceModal;
  public AdverseList = [
    { 'id': 1, 'name': 'Google Search' },
    { 'id': 2, 'name': 'Advertisement' },
    { 'id': 3, 'name': 'Facebook' },
    { 'id': 4, 'name': 'Other Social Media' },
    { 'id': 5, 'name': 'A Friend' }
  ];
  constructor(
    private itemsService: ProductService,
    public apiService: RestApiService,
    public notificationService: NotificationService,
    public validateKeyword: ValidateKeywordService,
    public translate: TranslateService,
    public router: Router,
    private titleService: Title,
    public user: UserService
  ) {
    this.userService = user.userServiceModal;
  }

  ngOnInit(): void {
    this.translate.get('CompanyName').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('Shipping') + ' - ' + text);
    });
  }

  createUser(): void {
    console.log('user', this.userService);
    //if (window.localStorage) {
    //window.localStorage.setItem('customerDetail', JSON.stringify(this.createcustomerForm));
    this.userService.shippingAddress.FirstName = this.userService.customerData.FirstName;
    this.userService.shippingAddress.LastName = this.userService.customerData.LastName;
    this.userService.shippingAddress.Email = this.userService.customerData.Email;
    if ((this.itemsService.selectedOrderItems.length + this.itemsService.selectedAutoOrderItems.length) > 0) {
      this.router.navigate(['/checkout/shipping']);
    }
    else {
      this.router.navigate(['/cart']);
    }
    //}
  }

  verifyUsername(username: string) {
    if (username) {
      const blockword = ['test,abuse'];
      const isValidPost = this.validateKeyword.CheckValidation(blockword, this.userService.webOffice.UserName);
      if (isValidPost && !isValidPost.isvalid) {
        this.notificationService.error(
          'Error',
          this.translate.instant('LoginNameNotAvailable')
        );
        this.userService.webOffice.UserName = '';
      } else {
        this.apiService.VerifyUserName(username).subscribe({
          next:result => {
            try {
              if (result.Status == 0 && result.Data) {
                this.notificationService.success(
                  'success',
                  this.translate.instant('UserNameAvailable')
                );
              } else {
                this.userService.webOffice.UserName = '';
                this.notificationService.error(
                  'Error',
                  this.translate.instant('LoginNameNotAvailable')
                );

              }
            } catch (ex) {
              this.notificationService.error(
                'Error',
                this.translate.instant('OopsSomethingWentWrong')
              );
            }
          },
          error:err => {
            // Do stuff whith your error
            this.loadingDetails = false;
          },
          complete:() => {
            // Do stuff after completion
          }
        });
      }
    }
  }

  verifyEmail(email: string) {
    var filter = new RegExp('^([\\w-\\.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})(\\]?)$');
    if (this.userService.customerData.Email && filter.test(this.userService.customerData.Email)) {
      email = this.userService.customerData.Email;
      this.apiService.VerifyEmail(email).subscribe({next:(result) => {
        if (result.Data) {
          this.EmailCheck = true;
          this.notificationService.success('success', 'EmailAvailable');
        } else {
          this.notificationService.error('Error', 'EmailAlreadyInUse');
          this.userService.customerData.Email = '';
        }
      }});
    }
  }
}

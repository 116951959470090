<section fxLayout="row" class="payment-failed" fxLayoutAlign="center center">
  <div fxFlex.gt-md="60" fxLayoutAlign="center center" fxLayout="column">
    <img src="assets/images/paymentfailed.png" alt="" class="image" />
    <h2
      class="title text-center"
      [textContent]="('payment_fail_title' | translate) + '!'"
    ></h2>
    <h3
      class="subtitle text-center"
      [textContent]="('payment_fail_subtitle' | translate)"
    ></h3>
    <h3 *ngIf="error"
      class="c-red subtitle text-center "
      [textContent]="('Error' | translate) + ' : ' + error"
    ></h3>
    <h3
      *ngIf="orderNumber"
      class="subtitle text-center"
      [textContent]="('YourOrder' | translate) + ' #' + orderNumber"
    ></h3>
    <h3
      class="small-subtitle text-center"
      [textContent]="('FailedPaymentSubtitle' | translate)"
    ></h3>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <a
        fxLayoutAlign="center center"
        fxLayout="row"
        fxLayoutGap="10px"
        class="btn m-10 bg-primary-color"
        (click)="contactSupport()"
      >
        <mat-icon>contact_support</mat-icon>
        <span
          class="button-text"
          [textContent]="
            ('Contact' | translate) + ' ' + ('Support' | translate)
          "
        ></span
      ></a>
      <!-- <a fxLayoutAlign="center space-between" fxLayout="column" class="btn m-10" *ngIf="customerToken" [textContent]="'Retry'" (click)="RetryProcess()"></a> -->
      <a
        fxLayoutAlign="center space-between"
        fxLayout="column"
        class="btn m-10"
        [textContent]="('SkiptoHome' | translate)"
        (click)="SkipToHomepage()"
      ></a>
    </div>
    <!-- <p class="desc text-center" [textContent]="'payment_fail_desc' | translate"></p> -->
  </div>
</section>

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { Product } from 'src/app/core/modals/product.modal';
import { UserServiceModal } from "src/app/core/modals/userservice.modal";
import { CartService } from 'src/app/core/services/cart.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UserService } from "src/app/core/services/user.service";
import { QuickViewComponent } from 'src/app/shared/model/quick-view/quick-view.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cartside',
  templateUrl: './cart-side-menu.component.html',
  styleUrls: ['./cart-side-menu.component.scss']
})
export class CartSideMenuComponent implements OnInit {
  env: any;
  crossClick = true;
  icon: boolean = false;
  userService: UserServiceModal;
  lastQuantity: any;
  dateForSub: string;
click(){
    this.icon = !this.icon;
  }
  verifiedCoupon: string = '';
  constructor(
    private router: Router,
    public cartdialogRef: MatDialogRef<CartSideMenuComponent>,
    public user: UserService,
    public companyService: CompanyService,
    public itemsService: ProductService,
    public cartService: CartService,
    public orderService: OrderService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private apiService: RestApiService,
    public configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.env = environment;
    this.userService = user.userServiceModal;
  }

  public O_PRODUCTS: Array<any> = [];
  public AS_PRODUCTS: Array<any> = [];
  public PACKS: Array<any> = [];

  theDate: string;
  public relatedProducts: Array<any> = [];
  freeShippingBar = 10;
  sub = 0;
  remAmt = 250;


  ngOnInit(): void {
    this.AS_PRODUCTS = this.getItems('autoship');
    this.O_PRODUCTS = this.getItems('order');
    this.PACKS = this.getItems('packs');

    this.sub = 0;
    this.remAmt = 250;
    this.itemsService.selectedOrderItems.forEach((item) => {
        this.sub += (item.PriceType2Value * item.Quantity);
      });
      this.itemsService.selectedAutoOrderItems.forEach((item) => {
        this.sub += (item.PriceType1Value * item.Quantity);
      });

    this.sub >= 250 ? this.remAmt = 0 : this.remAmt = 250 - this.sub;
    this.freeShippingBar = 100 - ((this.remAmt / 250) * 100);


    if(this.O_PRODUCTS.length == 0){
      this.itemsService.getProduct(this.AS_PRODUCTS[0]?.ItemID).subscribe(e => {
        this.relatedProducts = e.FrequentlyBoughtTogetherItems;
      })
    }else{
      this.itemsService.getProduct(this.O_PRODUCTS[0]?.ItemID).subscribe(e => {
        this.relatedProducts = e.FrequentlyBoughtTogetherItems;
      })
    }
  }

  progressBarCheck(){
    this.sub = 0;
    this.remAmt = 250;
    this.getItems('order').forEach((item) => {
      this.sub += (item.PriceType2Value * item.Quantity);
    });
    this.getItems('autoship').forEach((item) => {
      this.sub += (item.PriceType1Value * item.Quantity);
    });
    this.sub >= 250 ? this.remAmt = 0 : this.remAmt = 250 - this.sub;
    this.freeShippingBar = 100 - ((this.remAmt / 250) * 100);
  }

  closeDialog(){
    this.cartdialogRef.close();
  }

  GoToProduct(item) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate(["/product", item.ItemID]));
    this.cartdialogRef.close();
  }

  public addToCart(type, item) {
    this.cartService.addToCart(
      item,
      true,
      '',
      type === 'autoship',
      true,
      type === 'pack',
      false
    );
    this.progressBarCheck();

  }

  removeFromCart(type, item) {
    if(this.O_PRODUCTS.length == 1 && this.AS_PRODUCTS.length == 1){
      this.sub = 0;
      this.remAmt = 250;
    }
    this.cartService.removeFromCart(
      item,
      type == "autoship",
      type == "pack",
      true
    );
    this.progressBarCheck();
  }

  getItems(type: string) {
    return this.itemsService[
      type == "pack"
        ? "selectedPacks"
        : type == "autoship"
        ? "selectedAutoOrderItems"
        : "selectedOrderItems"
    ];
  }
  getImage(item) {
    // if(item.SmallImageUrl == undefined){
    //   return undefined;
    // }
    return item?.SmallImageUrl;
  }

  closeIcon() {
    this.cartdialogRef.close();
  }

  quickView(product){
    let dialogRef = this.dialog.open(QuickViewComponent, {
      // minWidth: '90vw',
      // minHeight: '33rem',
      // maxWidth:'1300px',
      width: '72rem',
      height: '35rem',
      position: {
        top:`calc(50vh - 300px)`,
        bottom: '',
        left: '',
        right: ''
      },
      data: {env: this.env, product: product}
    });
    dialogRef.afterClosed().subscribe(dataResult => {
      
    })
  }

  increaseQuantiy(type, item) {
    this.cartService.increaseQuantiy(item, type == 'autoship', type == 'pack');
    this.progressBarCheck();
  }

  decreaseQuantiy(type, item) {
    this.cartService.decreaseQuantiy(item, type == 'autoship', type == 'pack');
    this.progressBarCheck();
  }



  getQuantityModel(type, item): any {
    return this.cartService[type == 'pack' ? 'packQuantity' : (type == 'autoship' ? 'autoshipQuantity' : 'orderQuantity')];
  }

  checkQuantity(type, item) {
    const quantity = this.getQuantityModel(type, item)[item.ItemCode];
    if (!Number(quantity)) {
      this.cartService.removeFromCart(item, type == 'autoship', type == 'pack', true);
    } else {
        item.Quantity = quantity;
      if (type == 'autoship') {
        localStorage.setItem('cart.autoship', JSON.stringify(this.itemsService.selectedAutoOrderItems));
        //this.orderService.calculateLocalAutoOrder();
        this.orderService.calculateAutoOrder();
      } else {
        localStorage.setItem((type == 'pack' ? 'cart.packs' : 'cart.order'),
          JSON.stringify(type == 'pack' ? this.itemsService.selectedPacks : this.itemsService.selectedOrderItems));
        //this.orderService.calculateLocalOrder();
        this.orderService.calculateOrder();
      }
    }
  }

  closeCart() {

  }

  getLastQuantity(type, item) {
    this.lastQuantity = this.getQuantityModel(type, item)[item.ItemCode];
  }

  @ViewChild('coupon') EnteredCoupon;
  verifyCoupon(coupon:string){
    if (this.verifiedCoupon != ''){
      this.notificationService.error('Error', 'YouAlreadyAppliedACoupon')
    } else if ( coupon === ''){
      this.notificationService.error("Error", "ApplyCoupon")
    } else{
      const req = {
        'couponcode' : coupon,
        'country': this.configService.commonData.selectedCountry || 'us',
        'priceType':  1
      }
      this.apiService.ApplyCoupon(req).subscribe({ next: data => {
        if(data.Data && data.Status == 0 ){
          this.verifiedCoupon = coupon;
          this.notificationService.success('success','CouponApplied');
        } else {
          this.notificationService.error('Error', 'CouponIsNotValid')
        }
      }, error:(error) => {
        
      }
    })
    }
  }

  removeCoupon(){
    this.verifiedCoupon = '';
    this.notificationService.error("Error", "Coupon_Removed")
  }



}

import { UtilityService } from 'src/app/core/services/utility.service';
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ConfigService } from "src/app/core/services/config.service";
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'bank-deposit',
  templateUrl: './bankdeposit.component.html',
  styles: [`img{
      max-width: 100%;
    }
  `],
  styleUrls: ['./bankdeposit.component.scss']
})

export class BankDepositComponent implements OnInit {
  public dialogData: any;
  public imageUrl: any;
  public iframeUrl: any;
  env : any;
  constructor(
    public bankdepositdialogRef: MatDialogRef<BankDepositComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public configService: ConfigService,
    public sanitizer: DomSanitizer,
    private utilityService: UtilityService

  ) {
    this.dialogData = data; 

  }
  ngOnInit() {
    this.utilityService.getBase64ImageFromUrl('../../../../assets/images/logo.png')
      .then((result) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(result.toString());
      })
      .catch(err => console.error(err));
      if (this.dialogData.PaymentType == 1002) {
        var url = `${environment.apiUrl}AlternatePayment/index?token=${this.dialogData.Token}`;
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
  }

  print(evt) {
    evt.preventDefault();
    var prtContent = $('#bankdeposit-section');
    var WinPrint = window.open('', '');
    WinPrint.document.write(prtContent[0].innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }

  closeBankDepositDialog() {
    this.bankdepositdialogRef.close();
  }
}

<app-breadcrumb [title]="('Shop' | translate) + ' / ' + ('Products' | translate)" [showCrumbs]="false"></app-breadcrumb>

<!-- Country Selector -->
<div class="container country-container mt-20" *ngIf="accountService.isLoggedIn()">
  <div fxLayout="column">
    <span class="fw-bold f-s-16 mb-10">Currently Viewing Products For:</span>
    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100">
      <mat-label translate="Country"></mat-label>
      <mat-select 
      (selectionChange)="fetchNewProducts(selectedCountry)"
      [(ngModel)]="selectedCountry">
      <mat-option *ngFor="let country of Countries" [value]="country.CountryCode"> {{country.CountryName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
</div>

<!-- Products -->
<div id="product-no-sidebar" class="right-sidebar-wrapper">
  <div class="container" fxLayout="row">
    <div fxFlex="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout="column"
      class="secondContainer all-products">
      <div fxLayout="row wrap" fxLayoutAlign="flex-start stretch" fxLayoutGap="75px" fxLayoutGap.lt-lg="10px">
        <div fxFlex="27" fxFlex.lt-lg="48" fxFlex.lt-md="95"
          *ngFor="let product of products  | productSearch:productSearchText | productOrderBy:sortByOrder "
          class="product-box">
          <app-product-card [product]="product"></app-product-card>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(products | productSearch : productSearchText).length === 0" fxLayout="column">
    <p class="search-not-found m-t-20" translate="SearchNotFound"></p>
  </div>
</div>

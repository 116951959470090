<section class="container-fluid">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center" class="webalias">
      <p class="text-light-white text-center m-0">
        <span class="f-w-500 alias">
        <span [textContent]="'YouAreOn'| translate"></span>
        <b [textContent]="' '+ userServiceModal.enrollerInfo?.FullName+ ' '"></b> 
        <span [textContent]="'Website'| translate"></span>!
        </span>
      </p>
    </div>
    <!-- <div fxFlex="50"  fxLayoutAlign="end center" [ngClass]="{'hideContact': userServiceModal.enrollerInfo?.FullName}"> -->
      <!-- <a class="text-light-white" fxLayout="row" fxLayoutAlign="end center" href="mailto:support@rainintl.com">
        <p class="m-0 contact" [textContent]="'GetInTouch'| translate"></p> : <mat-icon class="icon" fxLayoutAlign="center center">email</mat-icon>
      </a> -->
    <!-- </div> -->
  </div>
  </section>

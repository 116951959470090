<div fxLayout="row" fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column" fxLayoutAlign="space-between start"
  class="review-container">
  <div fxFlex="70" fxFlex.lg="65" fxFlex.lt-lg="100" fxLayout="column" class="first_box">
    <div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start"
      class="m-b-25 p-15 border">

      <ng-template>
        <div *ngIf="customerDetail?.shipping" fxFlex="30" class="m-b-20">
          <h4 class="" translate="ShippingAddress"></h4>
          <p [textContent]="customerDetail.FirstName + ' ' + customerDetail.LastName"></p>
          <p [textContent]="customerDetail.shipping?.firstname + ' ' + customerDetail.shipping?.lastname"></p>
          <p
            [textContent]="customerDetail.shipping?.streetaddress + ' ' + customerDetail.shipping?.city + ', ' + customerDetail.shipping?.state + ', ' + customerDetail.shipping?.zipcode + ', ' + customerDetail.shipping?.country">
          </p>
          <span>
            <p class="m-t-20" fxLayoutAlign="none center">
              <mat-icon>phone</mat-icon><span [textContent]="customerDetail.shipping?.phone"></span>
            </p>
          </span>
          <span>
            <p fxLayoutAlign="none center">
              <mat-icon>email</mat-icon><span [textContent]="customerDetail.shipping?.email"></span>
            </p>
          </span>
          <p class="edit-btn m-t-15" fxLayoutAlign="none center" routerLink="/checkout/shipping">
            <mat-icon>edit</mat-icon><span translate="Edit"></span>
          </p>
        </div>
      </ng-template>

      <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />

      <div fxLayout="column" fxLayoutAlign="space-between none" style="width: 100%;">
        <div fxFlex="100" fxLayoutAlign="center center">
          <span class="header" translate="Review"></span>
        </div>
        <div fxFlex="100" fxLayout="row" fxLayout.lt-md="column" class="m-t-15" fxLayoutGap="0px" fxLayoutGap.lt-md="20px" fxLayoutAlign="center start" fxLayoutAlign.gt-sm="start center">
          <div fxFlex="30" fxLayout="column" class="w-100 p-l-r-15 m-t-10 h-150">
            <p class="f-bold" translate="ShippingAddress"></p>
            <div>
              <span [textContent]="userService.shippingAddress.FullName"></span><br>
              <address class="color-f-s-14 m-b-0 viewAddress"
                *ngIf="!isStateRestrict && !utilityService.isEmptyObject(userService.shippingAddress)">
                <span [textContent]="userService.shippingAddress.Street1"></span>&nbsp;
                <span [textContent]="userService.shippingAddress.Street2"></span>&nbsp;
                <span [textContent]="userService.shippingAddress.City"></span>&nbsp;
                <br>
                <span [textContent]="userService.shippingAddress?.StateNameAsText"></span>&nbsp;
                <span [textContent]="userService.shippingAddress.CountryCode"></span>&nbsp;
                <span [textContent]="userService.shippingAddress.PostalCode"></span>
                <br>
                <span [textContent]="userService.shippingAddress.Phone"></span>
              </address>
            </div>
            <div class="edit_ship_address">
              <p class="edit-btn edit-address-btn m-t-15" fxLayoutAlign="none center" (click)="editAddress()">
                <mat-icon>edit</mat-icon><span translate="Edit"></span>
              </p>
            </div>
          </div>
          <div fxFlex="30" fxLayout="column" class="w-100 p-l-r-15 m-t-10 h-150">
            <p class="f-bold" translate="choosing_a_shipping"></p>
            <div class="m-t-10">
              <mat-radio-group aria-label="Select an option" fxLayout="column"
                [(ngModel)]="userService.selectedShippingMethod"
                *ngIf="!itemsService.selectedAutoOrderItems.length && itemsService.selectedOrderItems.length">
                <mat-radio-button class="m-b-10" [value]="shipmethod.ShipMethodID"
                  *ngFor="let shipmethod of orderService.calculateOrderResponse.ShipMethods"
                  (click)="updateShipmethod(shipmethod.ShipMethodID)">
                  <div fxLayout="column">
                    <div>
                      <span [textContent]="shipmethod.Description + ' - ' + (selectedCountry=='tr'? (shipmethod.ShippingAmount | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (shipmethod.ShippingAmount | currency: configService.selectedMarketConfig.CurrencySymbol))"></span>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
              <mat-radio-group aria-label="Select an option" fxLayout="column"
                [(ngModel)]="userService.selectedShippingMethod" *ngIf="itemsService.selectedAutoOrderItems.length">
                <mat-radio-button class="m-b-10" [value]="shipmethod.ShipMethodID"
                  *ngFor="let shipmethod of orderService.calculateAutoOrderResponse.ShipMethods"
                  (click)="updateShipmethod(shipmethod.ShipMethodID)">
                  <div fxLayout="column">
                    <div>
                      <span [textContent]="shipmethod.Description + ' - ' + (selectedCountry=='tr'? (shipmethod.ShippingAmount | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (shipmethod.ShippingAmount | currency: configService.selectedMarketConfig.CurrencySymbol))"></span>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="RainPointsPayments" fxFlex="100" fxLayout="column" fxLayoutAlign="" class="m-b-25 p-r-10 border">
      <!-- Rain Partner Team Credit Accout -->
      <ng-container *ngIf="!PaymentPointAccounts.isSelected('cash-account') && (this.userService?.customerData?.UserCreditAccount?.Balance && this.userService?.customerData?.UserCreditAccount?.Balance >= 0)">
        <div fxFlex="100" class="p-15">
          <h4 class="m-b-0 p-b-10 border-bottom" [textContent]="'RainPartnerTeamCredit' | translate"></h4>
        </div>
        <div class="m-l-15 m-r-10">
          <mat-checkbox [checked]="orderService?.pointsPaymentCheckout?.selectedAccounts[0]?.selectionStatus" color="primary" (change)="handlePointsAccountSelection('credit', $event)">
            <span [textContent]="'RainPartnerTeamCreditCheckbox' | translate"></span>
          </mat-checkbox>
        </div>
        <div class="m-t-15 m-l-15" fxLayout="column" *ngIf="PaymentPointAccounts.isSelected('credit')">
          <div class="m-b-10 text-transform">
            <span class="f-s-14" [textContent]="'RainPartnerTeamCreditAccountBalance' | translate"></span>: <strong> {{ PaymentPointAccounts.getAccountBalance('credit') | number:'1.2-2' }}</strong> 
          </div>
          <div class="">
            <span [innerHTML]="('YouMayUseRainPartnerTeamCreditPoint' | translate: { value1 : orderService.calculateOrderResponse.Total })"></span>
          </div>
          <mat-divider class="m-b-10 m-t-20"></mat-divider>
          <div class="m-t-10 m-b-15" fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="25px" fxLayoutGap.lt-md="15px">
            <span class="f-s-14" [textContent]="('WhatAmountWouldYouLikeToUse' | translate)"></span>
            <div class="">
              <input value="" type="text" validate allowonlydecimalsymbol="true" class="amount-input" [placeholder]="('Amount' | translate)" [(ngModel)]="PaymentPointAccounts.creditInput" (focusout)="PaymentPointAccounts.updatePoints('credit', PaymentPointAccounts.creditInput)"/>
            </div>
          </div>
          <!-- <div fxFlex="70" class="m-b-15" fxFlex.lt-md="30">
            <div fxLayout="row" fxLayoutAlign="start center">
              <button mat-stroked-button class="mat-stroked-button white-space-pre-wrap">Pay the remainder with this Rain Partner Team Credits</button>
            </div>
          </div> -->
        </div>
      </ng-container>
    
      <!-- Rain Wallet Account -->

      <ng-container class="m-b-15" *ngIf="(this.userService?.customerData?.UserWalletAccount?.Balance && this.userService?.customerData?.UserWalletAccount?.Balance >= 0)">
        <div fxFlex="100" class="m-t-10 p-15">
          <h4 class="m-b-0 p-b-10 border-bottom" [textContent]="('RainWalletAccount' | translate)">
            <span class="f-w-700">{{ (userService?.customerData?.UserWalletAccount?.Balance - orderService?.pointsPaymentCheckout.walletAmountInput) | number:'1.2-2' }}</span>
          </h4>
        </div>
        <div class="m-l-15 m-b-20">
          <mat-checkbox color="primary" [checked]="orderService?.pointsPaymentCheckout?.selectedAccounts[1]?.selectionStatus" (change)="handlePointsAccountSelection('wallet', $event)">
            <span [textContent]="('IWouldLikeToUseMyRainWalletAccount' | translate)"></span>
          </mat-checkbox>
        </div>
        <div class="m-t-0 m-l-15 m-b-15" fxLayout="column" *ngIf="PaymentPointAccounts.isSelected('wallet')">
          <div class="m-b-10 text-transform">
            <span class="f-s-14" [textContent]="('RainWalletAccountBalance' | translate)"></span>: <strong> {{ PaymentPointAccounts.getAccountBalance('wallet') | number:'1.2-2' }}</strong> 
          </div>
          <div class="">
            <span [innerHTML]="('YouMayUseOnThisOrder' | translate: { value1 : orderService.calculateOrderResponse.Total })"></span>
          </div>
          <mat-divider class="m-b-10 m-t-20"></mat-divider>
          <div class="m-t-10 m-b-15" fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="25px" fxLayoutGap.lt-md="15px">
            <span class="f-s-14" [textContent]="('WhatAmountWouldYouLikeToUse' | translate)"></span>
            <div class="">
              <input value="1" type="text" validate allowonlydecimalsymbol="true" class="amount-input" [placeholder]="('Amount' | translate)"  [(ngModel)]="PaymentPointAccounts.walletInput" (focusout)="PaymentPointAccounts.updatePoints('wallet', PaymentPointAccounts.walletInput)"/>
            </div>
          </div>
          <div fxFlex="70" class="" fxFlex.lt-md="50">
            <div fxLayout="row" fxLayoutAlign="start center">
              <button (click)="payRemainderWithRainWallet()" class="rainCashPartnerBtn" mat-stroked-button class="mat-stroked-button white-space-pre-wrap" [textContent]="('PayRemainderWithThisRainWallet' | translate)"></button>
            </div>
          </div>
        </div>
      </ng-container>
    
      <!-- Rain Cash -->
      <ng-container *ngIf="!PaymentPointAccounts.isSelected('credit') && (this.userService?.customerData?.UserCashAccount?.Balance && this.userService?.customerData?.UserCashAccount?.Balance >= 0)">
        <div fxFlex="100" class="p-15">
          <h4 class="m-b-0 p-b-10 border-bottom" [textContent]="('rain_cash' | translate)"></h4>
        </div>
        <div class="m-l-15">
          <span color="primary" [textContent]="'RainCashAccountBalance' | translate">: </span><strong> {{ PaymentPointAccounts.getAccountBalance('cash-account') | number:'1.2-2' }}</strong>
        </div>
        <div class="m-t-10 m-l-15" fxFlex fxFlex="row">
          <span>
            <span color="primary" [textContent]="'RainCashForOrder' | translate">: </span>
            <strong> {{ orderService?.pointsPaymentCheckout?.cashAmountInput | number:'1.2-2' }}</strong>
          </span>
          <span>
            <span color="primary" class="m-l-10" [textContent]="'RemainingRainCash' | translate">: </span>
            <strong> {{ orderService.calculateOrderResponse.SubTotal <= userService?.customerData.UserCashAccount?.Balance ? ((orderService.calculateOrderResponse.SubTotal - orderService.pointsPaymentCheckout?.cashAmountInput) | number:'1.2-2') : ((userService?.customerData.UserCashAccount?.Balance - orderService.pointsPaymentCheckout?.cashAmountInput) | number:'1.2-2') }}</strong>
          </span>
        </div>
        <div fxFlex="100" class="m-t-10 m-b-25 m-l-15">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-stroked-button class="mat-stroked-button" (click)="handleRainCash()" [textContent]="('IWouldLikeUseRainCash' | translate)"></button>
          </div>
        </div>
      </ng-container>

    </div>

    <div fxFlex="100" fxLayout="column" fxLayoutAlign="" class="m-b-25 border"
    *ngIf="(orderService?.pointsPaymentCheckout?.remainderAmount > 0 || itemsService.selectedAutoOrderItems.length > 0)">
      <!-- Credit Card Section -->
      <ng-container>
        <div fxFlex="100" class="p-15">
          <h4 class="m-b-0 p-b-10 border-bottom" translate="PaymentMethod"></h4>
        </div>
          <div fxLayout="column" class="p-l-15 p-r-15 p-b-20">
          <span *ngIf="paymentService.SelectedPaymentTypes == 1"
            [textContent]="'Card | Expires - ' + userService?.cardDetail.CardExpirationMonth + ' / ' + userService?.cardDetail.CardExpirationYear"></span>
          <mat-form-field appearance="outline" fxFlex="auto" class="exigo-validate"
            *ngIf="paymentService.PaymentTypeResponse">
            <mat-label translate="SelectPaymentMethod"></mat-label>
            <mat-select [(value)]="paymentService.SelectedPaymentTypes" validate validate-select="true"
              paymentSection="true">

              <!-- -------------------------------------------
                *Saved Payment Methods 
              --------------------------------------------->
              <label *ngIf="shouldDisplayLabel('saved')" class="method-label text-capitalize" [textContent]="('SavedPaymentMethods' | translate)"></label>


              <ng-container *ngFor="let addpayments of paymentService.SavedPaymentTypeResponse; index as i">
                <mat-option [value]="addpayments?.PaymentTypeID"
                *ngIf="shouldPayMethodAllowedToUse(addpayments) && is_saved_payment_method_allowed(addpayments)"
                [disabled]="!addpayments.Token"
                (click)="payMethodSelectedId = addpayments.PaymentTypeID; selectPaymentMethod(addpayments, 'Saved')"
                [textContent]="addpayments?.NameOnCard + ' - ' + addpayments?.CardNumber" translate
                class="pay-method">
                </mat-option>
              </ng-container>

              <!-- -------------------------------------------
                *Available Payment Methods 
              --------------------------------------------->
              <label class="method-label" [textContent]="('AvailablePaymentMethods' | translate)"></label>

              <ng-container *ngFor="let addpayments of paymentService.PaymentTypeResponse['AvailableGeneralPaymentMethods']">
                <mat-option [value]="addpayments?.PaymentTypeID"
                  *ngIf="shouldPayMethodAllowedToUse(addpayments)" 
                  (click)="payMethodSelectedId = addpayments.PaymentTypeID; selectPaymentMethod(addpayments, 'New')"
                  [textContent]="'PaymentType_' + (addpayments.PaymentTypeID | removeComma)" translate
                  class="pay-method">
                </mat-option>
              </ng-container>


            </mat-select>
            <ng-container *ngIf="((selectedCardType == 'Saved') &&  (paymentService.SelectedPaymentTypes == 1)) || (paymentService.SelectedPaymentTypes == 1003) ||  (paymentService.SelectedPaymentTypes == 1001)" class="kaxsdc" data-event='load'>
              <section class="kaxsdc" data-event='load'>
                  <input type="hidden" id="creditcard-token" name="creditcard-token" value="" />
                  <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />
              </section>
          </ng-container>
          </mat-form-field>
          <!-- <ng-container *ngIf="paymentService.SelectedPaymentTypes==1" class="kaxsdc" data-event='load'>
                    <section class="kaxsdc" data-event='load'>
                      <input type="hidden" id="creditcard-token" name="creditcard-token" value="" />
                      <input type="hidden" id="FraudGuid" name="FraudGuid" value="" />
                      <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="100" class="exigo-validate" *ngIf="showBillForm">
                        <mat-label translate="NameOnCard"></mat-label>
                        <input type="text" validate noempty="true" matInput paymentSection="true"
                          [(ngModel)]="userService.cardDetail.CardName" />
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="exigo-validate cardiframe" fxFlex="62" fxFlex.lt-md="100"
                        *ngIf="showBillForm">
                        <mat-label translate="CardNumber"></mat-label>
                        <input matInput cardNumber="true" type="hidden" maxlength="24"
                          [(ngModel)]="userService.cardDetail.CardNumber" />
                        <div id="cc-wrapper" class="cc-wrapper"></div>
                        <input type="hidden" class="creditcard-token" id="Token" />
                        <input type="hidden" class="creditcard-display" id="Display" />
                      </mat-form-field>

                      <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="w-100 margininput state exigo-validate"
                        *ngIf="showBillForm">
                        <mat-label translate="ExpirationMonth"></mat-label>
                        <mat-select id="month" name="expirationmonth" [(ngModel)]="userService.cardDetail.CardExpirationMonth" validate
                          validate-select="true">
                          <mat-option *ngFor="let month of cardExpMonths"
                            (ngInit)="userService.cardDetail.CardExpirationMonth=cardExpMonths[0]?.value" [value]="month.value"
                            [textContent]="month.value + ' - ' + month.text"></mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate" *ngIf="showBillForm">
                        <mat-label translate="ExpirationYear"></mat-label>
                        <mat-select [(ngModel)]="userService.cardDetail.CardExpirationYear" name="expireYear" validate
                          validate-select="true" paymentSection="true">
                          <mat-option [value]="year.value" *ngFor="let year of cardExpYears"
                            (ngInit)="userService.cardDetail.CardExpirationYear=cardExpYears[0]?.value">{{
                            year.text
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" class="exigo-validate" *ngIf="showBillForm">
                        <mat-label translate="CVV"></mat-label>
                        <input type="text" validate inputonlynumber="true" paymentSection="true" noempty="true" matInput
                          [(ngModel)]="userService.cardDetail.CardCvv" maxlength="4" />
                      </mat-form-field>
                    </section>
                  </ng-container> -->
        </div>
      </ng-container>
    </div>

    <div fxFlex="100" fxLayout="column" fxLayoutAlign="" class="m-b-25 border"
      *ngIf="itemsService.selectedOrderItems.length > 0">
      <div fxFlex="100" class="p-15 border-bottom">
        <h3 class="m-0 order-title" [textContent]="('Order' | translate)"></h3>
      </div>
    
      <!-- Order Items  -->

      <div fxFlex="100" class="p-15" fxLayout="column" *ngFor="let item of getItems('order')">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="30" class="mr-auto">
            <img [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt="" class="order_img">
          </div>
          <div fxFlex="70" fxLayout="column">
            <h4 [textContent]="item.ItemDescription | slice:0:40"></h4>
            <div class="w-100" fxLayoutAlign="space-between center">
              <!-- Quantity -->
              <span [textContent]="('Quantity' | translate) + ': ' + item.Quantity"></span>
              <!-- Price -->
              <span *ngIf="item.GroupMembers?.length>0"
                [textContent]="selectedCountry=='tr'? (getGroupItemPrice(item) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (getGroupItemPrice(item) | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2 f-w-700">
              </span>
              <span *ngIf="item.GroupMembers?.length==0"
                [textContent]="selectedCountry=='tr'?((item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                class="text-2 f-w-700">
              </span>
            </div>
            <p></p>
          </div>
        </div>
      </div>
    </div>

      <!-- Subscription Items  -->

    <div fxFlex="100" fxLayout="column" fxLayoutAlign="" class="m-b-25 border"
      *ngIf="itemsService.selectedAutoOrderItems.length > 0">
      <div fxFlex="100" class="p-15 border-bottom">
        <h3 class="m-0 order-title" translate="AutoOrder"></h3>
      </div>
      <div fxFlex="100" class="p-15" fxLayout="column" *ngFor="let item of getItems('autoship')">
        <div fxLayout="column" fxLayout.gt-sm="row">
          <div fxFlex="30" class="mr-auto">
            <img [src]="env.apiUrl + 'api/Images' + item?.SmallImageUrl" alt="" class="order_img">
          </div>
          <div fxFlex="70" fxLayout="column">
            <h4 [textContent]="(item.selectedItemName || item.ItemDescription) | slice:0:40"></h4>
            <div class="w-100" fxLayoutAlign="space-between center">
              <!-- Quantity -->
              <span [textContent]="('Quantity' | translate) + ': ' + item.Quantity"></span>
              <!-- Price -->
              <span *ngIf="item.GroupMembers?.length>0"
                [textContent]="selectedCountry=='tr'? (getGroupItemPrice(item) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (getGroupItemPrice(item) | currency: configService.selectedMarketConfig.CurrencySymbol)"
                class="text-2 f-w-700">
              </span>
              <span *ngIf="item.GroupMembers?.length==0"
                [textContent]="selectedCountry=='tr'?((item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : (item['PriceType' + userService.customerTypeID + 'Value'] * item.Quantity) | currency: configService.selectedMarketConfig.CurrencySymbol"
                class="text-2 f-w-700">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Cost Breakdown -->

    <div id="cost-breakdown-summary" fxLayoutAlign="end start">
      <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-sm="35" fxLayout="column" fxLayoutAlign="start between">
        <div id="price" class="sarc global_subtotal">
          <span class="t-3 " translate="Price"></span>
          <span class="t-4"
            [textContent]="selectedCountry=='tr'?((orderService.calculateOrderResponse.OriginalSubTotal ? orderService.calculateOrderResponse.OriginalSubTotal : (orderService.calculateOrderResponse.CurrentSubTotal ? orderService.calculateOrderResponse.CurrentSubTotal : ( orderService.calculateOrderResponse.SubTotal ? orderService.calculateOrderResponse.SubTotal : 0))) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):(orderService.calculateOrderResponse.OriginalSubTotal ? orderService.calculateOrderResponse.OriginalSubTotal : (orderService.calculateOrderResponse.CurrentSubTotal ? orderService.calculateOrderResponse.CurrentSubTotal : ( orderService.calculateOrderResponse.SubTotal ? orderService.calculateOrderResponse.SubTotal : 0))) | currency: configService.selectedMarketConfig.CurrencySymbol"></span>        
        </div>
        <div id="discount" class="sarc">
          <span class="t-3" translate="DiscountTotal"></span>
          <span class="t-4"
            [textContent]="selectedCountry=='tr'?((((orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? (orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :((((orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? (orderService.calculateOrderResponse.OriginalSubTotal - orderService.calculateOrderResponse.CurrentSubTotal) : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        </div>
        <div id="coupon" class="sarc">
          <span class="t-3" translate="PromoCouponDiscount"></span>
          <span class="t-4"
            [textContent]="selectedCountry=='tr'?((userService.promotionOffer && (orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) : ((userService.promotionOffer && (orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) > 0) ? orderService.applyDiscount(orderService.calculateOrderResponse.CurrentSubTotal) : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        </div>
        <div class="sarc" *ngIf="!PaymentPointAccounts.isSelected('cash-account') && (this.userService?.customerData?.UserCreditAccount?.Balance && this.userService?.customerData?.UserCreditAccount?.Balance >= 0)">
          <span class="t-3" [textContent]="('RainPartnerTeamCredits' | translate)"></span>
          <span class="t-4"> - {{ orderService.pointsPaymentCheckout.creditAmountInput > 0 ? (orderService.pointsPaymentCheckout.creditAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>     
        </div>
        <div class="sarc" *ngIf="!PaymentPointAccounts.isSelected('credit') && (this.userService?.customerData?.UserCashAccount?.Balance && this.userService?.customerData?.UserCashAccount?.Balance >= 0)">
          <span class="t-3" translate="rain_cash"></span>
          <span class="t-4"> - {{ orderService.pointsPaymentCheckout.cashAmountInput > 0 ? (orderService.pointsPaymentCheckout.cashAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>
        </div>
        <div id="subtotal" class="sarc global_subtotal">
          <span class="t-3 " translate="Subtotal"></span>
          <span class="t-4"
            [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.SubTotal )| customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.SubTotal )| currency: configService.selectedMarketConfig.CurrencySymbol)"></span>        
        </div>
        <div id="shippingtax" class="sarc">
          <span class="t-3" translate="ShippingTotal"></span>
          <span class="t-4" [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode): ((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
          <!-- <span class="t-4" *ngIf="router.url == '/enrollment/checkout' || router.url == '/checkout' || router.url == '/review'"
            [textContent]="((orderService.calculateOrderResponse.ShippingTotal ? orderService.calculateOrderResponse.ShippingTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
          <span class="t-4" *ngIf="router.url != '/enrollment/checkout' && router.url != '/checkout' && router.url != '/review'"
          [textContent]="(0 | currency: configService.selectedMarketConfig.CurrencySymbol)"></span> -->
        </div>
        <div id="tax" class="sarc">
          <span class="t-3" translate="TaxTotal"></span>
          <span class="t-4" [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode):((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
          <!-- <span class="t-4" *ngIf="router.url == '/enrollment/checkout' || router.url == '/checkout'"
            [textContent]="((orderService.calculateOrderResponse.TaxTotal ? orderService.calculateOrderResponse.TaxTotal : 0) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
          <span class="t-4" *ngIf="router.url !== '/enrollment/checkout' && router.url !== '/checkout'"
          [textContent]="(0 | currency: configService.selectedMarketConfig.CurrencySymbol)"></span> -->
        </div>
        <div id="grand_total" class="sarc grand-total">
          <span class="text-2" translate="Total"></span>
          <span class="text-2"
            [textContent]="selectedCountry=='tr'? ((orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | customeCurrency:'TRY':'symbol':'3.2-2':configService.selectedMarketConfig.CultureCode) :((orderService.calculateOrderResponse.Total ?  orderService.calculateOrderResponse.Total: 0  ) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
        </div>
        <div class="sarc" *ngIf="(this.userService?.customerData?.UserWalletAccount?.Balance && this.userService?.customerData?.UserWalletAccount?.Balance >= 0)">
          <span class="t-3" translate="RainWallet"></span>
          <span class="t-4"> - {{ orderService.pointsPaymentCheckout.walletAmountInput > 0 ? (orderService.pointsPaymentCheckout.walletAmountInput | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>      
        </div>
        <div class="sarc">
          <span class="t-3" translate="Remainder"></span>       
          <span class="t-4"> {{ orderService.pointsPaymentCheckout.remainderAmount > 0 ? (orderService.pointsPaymentCheckout.remainderAmount | currency: configService.selectedMarketConfig.CurrencySymbol) : 0 }} </span>  
        </div>
      </div>
    </div>

    <div fxFlex="100" fxLayout="row" fxLayoutAlign="end stretch" fxLayoutGap="20px" class="m-b-25">
      <button mat-raised-button class="review_pay_btn" id="reviewPayButton" validate trigger-check-section="true"
        sectiontype="paymentSection" (success)="InitiateCheckout()">
        <mat-icon class="icon">payment</mat-icon>
        <span
          [textContent]="('Pay' | translate) + ' ' + ((orderService.pointsPaymentCheckout.remainderAmount ?  orderService.pointsPaymentCheckout.remainderAmount : 0  ) | currency: configService.selectedMarketConfig.CurrencySymbol)"></span>
      </button>
      <!-- <button mat-raised-button class="edit_item_btn" routerLink="/products">
        <mat-icon>edit</mat-icon><span [translate]="('EditItems' | translate)"></span>
      </button> -->
    </div>
  </div>

  <!-- <div fxFlex="30" fxFlex.lg="30" fxFlex.lt-lg="100" fxLayout="column" class="second_box">
    <div fxFlex="100" fxLayout="column" class="m-b-25 border">
      <div fxFlex="100" class="p-15 border-bottom">
        <h3 class="m-0" translate="unifiedapplication_initial_order"></h3>
      </div>
      <div fxLayout="row" class="p-15" fxLayoutAlign="space-between start">
        <div fxFlex=40 class="text-a-l">
          <h4 [textContent]="('subTotal' | translate) + ' :'" ></h4>
          <h4 [textContent]="('Pack Total' | translate) + ' :'" ></h4>
          <h4 [textContent]="('meta_shipping_title' | translate) + ' :'"></h4> <br>
          <h4 [textContent]="('global_total' | translate) + ' :'"></h4>
          <h4 [textContent]="('tax' | translate) + ' :'"></h4> <br>
          <h4 translate="GrandTotal"></h4> :
        </div>
        <div fxFlex=20 class="text-a-l" fxLayout="column" fxLayoutAlign="start end">
          <h4 [textContent]="orderService.calculateOrderResponse.SubTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4>
          <h4 [textContent]=" orderService.packTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4>
          <h4 [textContent]="orderService.calculateOrderResponse.ShippingTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4> <br>
          <h4 [textContent]="orderService.calculateOrderResponse.SubTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4>
          <h4 [textContent]="orderService.calculateOrderResponse.TaxTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4><br>
          <h4 [textContent]="(orderService.calculateOrderResponse.Total  + orderService.packTotal) | currency: configService.selectedMarketConfig.CurrencySymbol"></h4>
        </div>
      </div>
    </div>
    <div fxFlex="100" fxLayout="column" class="m-b-25 border" *ngIf="itemsService.selectedAutoOrderItems.length">
      <div fxFlex="100" class="p-15 border-bottom">
        <h3 class="m-0" translate="smart_order"></h3>
      </div>
      <div fxLayout="row" class="p-15" fxLayoutAlign="space-between start">
        <div fxFlex=40 class="text-a-l">
          <h4 translate="StartDate"></h4>
          <h4 translate="subTotal"> <span>:</span></h4>
        </div>
        <div fxFlex=60 class="text-a-l" fxLayout="column" fxLayoutAlign="start end">
          <h4 [textContent]="autoshipConfigurationService.autoshipDate"></h4>
          <h4 [textContent]="orderService.calculateAutoOrderResponse.SubTotal | currency: configService.selectedMarketConfig.CurrencySymbol"></h4>
        </div>
      </div>
      <div fxLayout="row" class="subs_edit" fxLayoutAlign="end start">
        <div fxFlex=40 fxLayoutAlign="end start">
          <p class="edit-btn" fxLayoutAlign="none center" routerLink="/enrollment/product-list">
            <mat-icon>edit</mat-icon><span [translate]="('EditItems' | translate)"></span>
          </p>
        </div>
      </div>
    </div>
    <div fxFlex="100" fxLayout="column" class="m-b-25 border">
      <button mat-stroked-button (click)="submitApplication()" translate="PlaceOrder"></button>
    </div>
  </div> -->

</div>

<!--This is the div that will gray out the contents behind the iframe and disable clicking on other items.-->
<div id="TokenEx3DS_Challenge_ParentElement" style="display:none; position:fixed; top:0; left:0; width:100vw; height:100vh; background: rgba(128, 128, 128, .5);">

  <!--This is the div that the challenge iframe will be created in.-->
  <div id="TokenEx3DS_Challenge_ChildElement" style="left: 50%;top: 50%;transform:translate(-50%, -50%); border: 2px solid black; position: absolute;"></div>

</div>


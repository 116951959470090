import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../../core/services/config.service';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserService } from '../../../core/services/user.service';
import {  ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { FindEnrollerComponent } from '../findenroller/findenroller.component';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { CompanyService } from '../../../core/services/company.service';
import { BillingAddress, CardDetail, PersonalInfo, ShippingAddress, WebOffice } from 'src/app/core/modals/enrollmentservice.modal';

@Component({
  selector: 'app-market-selector',
  templateUrl: './market-selector.component.html',
  styleUrls: ['./market-selector.component.scss']
})
export class MarketSelectorComponent implements OnInit, OnDestroy {
  selectedCountry: any = {};
  selectedLanguage: any = {};
  //selectedUserType: any = {};
  public allowedCountries:Array<any>=[];
  public allowedLanguages:Array<any>=[];
  customerTypes: any = {};
  userService: UserServiceModal;
  public enrollmentservice: any;

  private _countriesRedirectToRainIntl: string[] = []; //add countries here to redirect

  constructor(
    public dialog: MatDialog,
    public configService: ConfigService,
    public user: UserService,
    public apiService: RestApiService,
    private route: ActivatedRoute,
    public companyService: CompanyService
  ) {
    this.userService = user.userServiceModal;
    this.enrollmentservice = user.userServiceModal;
    const selectedLanguage = sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'));
    this.apiService.GetCountries().subscribe({next:data => {
      this.allowedCountries = data.Data;

      // removed ? Japan country
      this.allowedCountries = this.allowedCountries.filter(country => country.CountryCode.toUpperCase() != "JP");

      const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
      // const selectedCustomerTypeID = sessionStorage.getItem('selectedCustomerTypeID') === 'undefined' ? null : (sessionStorage.getItem('selectedCustomerTypeID') || this.userService.customerTypeID || 2);
      // this.selectedUserType = _.find(this.configService.commonSettings.CustomerTypes, (customerType) => {
      //   return (customerType.ID === (parseInt(this.route.snapshot.queryParams['type'], 10) || selectedCustomerTypeID || 2));
      // });
      this.selectedCountry = _.find(this.allowedCountries, (item) => {
        return (item.CountryCode.toLowerCase() === (this.route.snapshot.queryParams['countrycode'] || selectedCountry || 'us'));
      });

    },complete: () => { }});

    this.apiService.GetLanguages(sessionStorage.getItem('selectedCountry') || 'US').subscribe({next:data => {
      this.allowedLanguages = data.Data;

      /**
       *  #6406 - filter Language
       *  Dutch - 18
       *  Bulgarian - 23
       *  Marcedonian - 230
       */

      this.allowedLanguages = this.allowedLanguages.filter(lang => (lang.LanguageID != 18 && lang.LanguageID != 23 && lang.LanguageID != 230));

      this.selectedLanguage = _.find(this.allowedLanguages, (item) => {
        return (item.LanguageID === (selectedLanguage.LanguageID || 0));
      });
    }, error:() => { }, complete:() => { }});
  }

  ngOnInit(): void {
    let queryCategory;
    if (this.route.snapshot.queryParams['catId']) {
      queryCategory = parseInt(this.route.snapshot.queryParams['catId'], 10);
    } else if (this.route.snapshot.queryParams['category']) {
      queryCategory = parseInt(this.route.snapshot.queryParams['category'], 10);
    }
  }

  changeAffiliate() {
    this.dialog.open(FindEnrollerComponent, {
      data: {},
      panelClass: 'findenroller-dialog',
    });
  }

  continue() {
    if (this.selectedCountry.CountryCode) {
      if(this._countriesRedirectToRainIntl.includes(this.selectedCountry.CountryCode)){
        this.NavigateToRainIntlSite();
        return;
      }

      let sessionId: any;
      let exigosessionId: any;
      let initalMarketSelectorPopupOpened: any;

      /*
      *  Fetching Values from Application Storage
      */

      if(window.localStorage){
        sessionId = localStorage.getItem('sessionId') == 'null' ? '' : localStorage.getItem('sessionId');
      }
      if(window.sessionStorage){
        exigosessionId = sessionStorage.getItem('exigosessionId') == 'null' ? '' : sessionStorage.getItem('exigosessionId');
      }
      if(window.sessionStorage){
        initalMarketSelectorPopupOpened = sessionStorage.getItem('initalMarketSelectorPopupOpened') == 'null' ? '' : sessionStorage.getItem('initalMarketSelectorPopupOpened');
      }

      /*
      *  Application Storage Cleared!
      */

      localStorage.clear();
      sessionStorage.clear();

      /*
      *  Setting Values to Application Storage
      */

      if(window.localStorage){
        localStorage.setItem('sessionId', sessionId);
      }
      if(window.sessionStorage){
        sessionStorage.setItem('exigosessionId', exigosessionId);
      }
      if(window.sessionStorage){
        sessionStorage.setItem('initalMarketSelectorPopupOpened', initalMarketSelectorPopupOpened);
      }

      this.clearPreviousRecord();
      this.userService.customerTypeID = this.userService.customerTypeID || 2;
      this.dialog.closeAll();
      sessionStorage.setItem('IsMarketCountrySelected', 'true');
      sessionStorage.setItem('selectedCountry', (this.selectedCountry.CountryCode.toLowerCase() || this.configService.commonData.selectedCountry));
      sessionStorage.setItem('selectedLanguage', JSON.stringify({ LanguageID: this.selectedLanguage.LanguageID, CultureCode: this.selectedLanguage.CultureCode }));
      sessionStorage.setItem('selectedLanguageID', this.selectedLanguage?.LanguageID);
      //sessionStorage.setItem('selectedCustomerTypeID', this.selectedUserType?.ID || this.userService.customerTypeID || 2);
      this.configService.commonData.selectedCountry = this.selectedCountry.CountryCode.toLowerCase();
      this.userService.selectedCountry = this.enrollmentservice.selectedCountry = this.configService.commonData.selectedCountry;
      this.apiService.getMarketConfig(this.configService.commonData.selectedCountry).subscribe(data => {
        var selectedMarketConfig = data.Data;
        sessionStorage.setItem('local_ID', selectedMarketConfig.CultureCode.substring(0, 2));
        this.GetRegions().then(() => {
          window.location.reload();
        });
      });
    }
  }


  NavigateToRainIntlSite(){
    window.open("https://rainintl.com/"+ this.userService.WebAlias, "_self");
  }

  clearPreviousRecord(){
    this.enrollmentservice.cardDetail = {} as CardDetail;
    this.enrollmentservice.personalInfo = {} as PersonalInfo;
    this.enrollmentservice.shippingAddress = {} as ShippingAddress;
    this.enrollmentservice.shipMethods = {};
    this.enrollmentservice.webOffice = {} as WebOffice;
    this.enrollmentservice.billingAddress = {} as BillingAddress;
  }

  GetRegions = () => {
    const promise = new Promise((resolve, reject) => {
      this.apiService.GetRegions(this.selectedCountry.CountryCode).subscribe({
        next: (data) => {
          this.userService.defaultState = data.Data[0]?.RegionCode;
          this.userService.shippingAddress.StateName = data?.Data?.filter((state)=> { return state.RegionCode == this.userService.shippingAddress.StateName })[0]?.RegionCode || data.Data[0]?.RegionCode;
          resolve(data);
        }, error: (error) => {
          reject(error);
        }
      });
    });
    return promise;
  }

  changeLang(code): void {
    if (code) {
      this.selectedCountry = _.find(this.allowedCountries, (country) => {
        return country.CountryCode.trim().toLowerCase() === code.trim().toLowerCase();
      });
    }
  }
  changedCountry(Country) {
    this.apiService.GetLanguages(Country.CountryCode).subscribe({next:res => {
      this.allowedLanguages = res.Data;
      this.selectedLanguage = this.allowedLanguages.filter((lang)=> { return lang.LanguageID == this.selectedLanguage.LanguageID })[0] || this.allowedLanguages[0];
    }})
  }

  ngOnDestroy(): void {
    
  }
}

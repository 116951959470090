import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-productsearch',
  templateUrl: './productsearch.component.html',
  styleUrls: ['./productsearch.component.scss']
})
export class ProductSearchDialogComponent implements OnInit,OnDestroy {

  constructor(private router: Router,
    @Optional() public dialogRef: MatDialogRef<ProductSearchDialogComponent>) { }

  ngOnInit(): void {
  }

  searchProduct(product: string){
    this.router.navigate(
      ['/products'],
      {queryParams: { searchquery : product}}
    );
      this.dialogRef.close({
        productName: product
      });
  }
  ngOnDestroy(): void {
    
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CardDetail, UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { PaymentService } from 'src/app/core/services/payment.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import * as $ from 'jquery';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RestApiService } from 'src/app/core/http/restapi.service';

declare var kount: any;
declare var r: any;
declare var ka: any;


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  showBillForm = true;
  userService: UserServiceModal
  public cardExpMonths: Array<any> = [];
  public cardExpYears: Array<any> = [];
  public tokenizer: any = {};
  public billingCountries: Array<any> = [];
  public billingallowedRegions: Array<any> = [];
  

  cardDetail : CardDetail = {
    CardName: '',
    CardNumber: '',
    CardExpirationMonth: null,
    CardExpirationYear: null,
    CardCvv: '',
    Token:'',
    fruadGuid: '',
    lastFour: '',
    firstSix: ''
  };

  constructor(
    public paymentService: PaymentService,
    public dialog: MatDialog,
    public user: UserService,
    public utilityService: UtilityService,
    public dialogRef:MatDialogRef<PaymentComponent>,
    public notificationService: NotificationService,
    public apiService: RestApiService
  ) { 
    this.userService = user.userServiceModal;
    this.cardExpMonths = utilityService.cardExpMonths();
    this.cardExpYears = utilityService.cardExpYears();
    this.apiService.GetCountries().subscribe({
      next: data => {
        this.billingCountries = data.Data;
        if (window.sessionStorage) {
          const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
          this.userService.billingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
        }

        this.apiService.GetRegions(this.userService.shippingAddress.CountryCode).subscribe({
          next: (data) => {
            this.billingallowedRegions = data.Data;
            this.userService.billingAddress.StateName = this.billingallowedRegions.filter((state) => { return state.RegionCode == this.userService.billingAddress.StateName })[0]?.RegionCode || this.billingallowedRegions[0]?.RegionCode;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadKount();
      //@ts-ignore
      this.tokenizer = new TokenExIframeClient("cc-wrapper", {
        styles: {
          base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
          focus: "box-shadow:none;border: none;outline: 0;",
          error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
        }
      });
      this.tokenizer.change(() => {
        this.tokenExErrorHandling();
      });
    }, 1000);
  }
  tokenExErrorHandling()
  {
    this.tokenizer['iframe']?.on('error',function(data) {
      let count = 0;
      if(data.error && count < 1){
          count++;
          alert(data.error + "\n Your session has expired. Would you like to continue?");
          window.location.reload();
      }
    });
  }
  activateInput()
  {
    setTimeout(() => {
      $('#cardiframe').click(function (e) {
        $('#tx_iframe_cc-wrapper')[0].focus();
      });
    }, 100)
  }
  billingcountrySelected(e: any) {
    this.apiService.GetRegions(e.value.toUpperCase()).subscribe((data) => {
      this.billingallowedRegions = data.Data;
      this.userService.billingAddress.StateName = data.Data[0]?.RegionCode;
    });
  }


  onSubmit(){

    this.userService.billingAddress.FirstName = this.cardDetail.CardName;
    this.userService.cardDetail.CardName =  this.cardDetail.CardName;
    this.userService.cardDetail.CardNumber =  this.cardDetail.CardNumber;
    this.userService.cardDetail.CardExpirationMonth =  this.cardDetail.CardExpirationMonth;
    this.userService.cardDetail.CardExpirationYear =  this.cardDetail.CardExpirationYear;
    this.userService.cardDetail.CardCvv =  this.cardDetail.CardCvv; 
    this.userService.cardDetail.Token =  ''; 
    this.userService.cardDetail.firstSix = this.cardDetail.firstSix;
    this.userService.cardDetail.lastFour = this.cardDetail.lastFour;
    this.fraudPrevention().then((res) => {
      if(res){
        this.userService.cardDetail.fruadGuid = $("#FraudGuid").val();
        this.dialogRef.close(true)
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadKount();
    setTimeout(() => {

      //@ts-ignore
      this.tokenizer = new TokenExIframeClient("cc-wrapper", {
        styles: {
          base: "font-family: Arial, sans-serif;padding: 0 8px;border:none;margin: 0;width: 100%;font-size: 13px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
          focus: "box-shadow:none;border: none;outline: 0;",
          error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
        }
      });
      this.tokenizer.change(() => {
        this.tokenExErrorHandling();
      });
    }, 5000);
  }

  selectedCountryCode = "";
  loadKount() {

    var fraudId = "";
    var client = new ka.ClientSDK();
    client.setupCallback(
      {
        'collect-end':
          function (params) {
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
            // this.fruadGuid = fraudId
            //this.userService.cardDetail.fruadGuid  = fraudId
          },
        'collect-begin':
          function (params) {
            console.log("Collect finished");
            console.log("Collect started");
            console.log("Session ID: " + params['MercSessId']);
            fraudId = params['MercSessId'];
            $("#FraudGuid").val(fraudId);
            // this.fruadGuid = fraudId
            //this.userService.cardDetail.fruadGuid  = fraudId
          }
      });
    client.autoLoadEvents();
  };
  fraudPrevention(): any {

    const fruadPreventPromise = new Promise((resolve, reject) => {
      if (this.paymentService.SelectedPaymentTypes == 1) {
        this.tokenizer.tokenize({
          success: (data)=> {
            console.log('tokenized success', data);
            var token = data.token;
            $(".creditcard-token").val(token);
            $(".creditcard-display").val(token.substr(token.length - 4, 4));
            this.userService.cardDetail.CardNumber=token;
            this.userService.cardDetail.Token=token;
            this.userService.cardDetail.firstSix = data.firstSix;
            this.userService.cardDetail.lastFour = data.lastFour
            //this.submitCheckout();
            return resolve(true);
          },
          error: (data)=> {
            console.log("get cc token failed", data);
            this.userService.cardDetail.CardNumber="";
            this.userService.cardDetail.firstSix = "";
            this.userService.cardDetail.lastFour = "";
            this.notificationService.error(
              'Error',
              'Some Error Occur  ' + data.message
            );
            return reject(false);
          }
        });
      }
    })
    return fruadPreventPromise;
  }




  }



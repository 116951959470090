import { PipeTransform, Pipe  } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'limitToPipe'
})
export class LimitToPipe implements PipeTransform {
  transValue: any
  constructor(private translate: TranslateService,) {
    this.translate.get('MoreInfo').subscribe((res: string) => {
      this.transValue = res;
    });
  }
  transform(value: string, limit: number,itemcode:string) : SafeHtml {
    let trail = `<strong class="more_word" more="/product/${itemcode}">...${this.transValue? this.transValue: 'More'}</strong>`;
    return value.length > limit ? value.substring(0, limit) + "..." + trail : value;
  }
}


import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartService } from 'src/app/core/services/cart.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { PersistentService } from 'src/app/core/services/persistent.service';
import { ProductService } from 'src/app/core/services/product.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { environment } from 'src/environments/environment';
import { QuickViewComponent } from 'src/app/shared/model/quick-view/quick-view.component';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { AccountService } from 'src/app/core/authentication/account.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  selectedOrderType: any = {};
  env: any;
  selectedCountry: any;
  value: Array<any> = [];
  public OrderQuantityCount: any;
  orderType:any = [{typeId: 1, name: 'One Time Order'},{typeId: 2, name: 'Subscription Order'}]
  @Input() product: any;
  public ProductColorOptions: Array<any> = [];
  public userService: UserServiceModal;

  constructor(
    public configService: ConfigService,
    public cartService: CartService,
    public productsService: ProductService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    public persistentService: PersistentService,
    public utilityService: UtilityService,
    public companyService: CompanyService,
    public user: UserService,
    public accountService: AccountService
  ) {

    this.env = environment;
    this.selectedCountry = sessionStorage.getItem('selectedCountry') ||"us";
    this.userService = user.userServiceModal;

    //this.selectedOrderType = 2;
  }

  ngOnInit() {

    let el = $(".detail-prod").children("p:last-child")
    el.css({ "height" : "97px", "overflow" : "hidden","display": "flex","flex-direction": "column","justify-content": "space-between"});
    try {
      this.ProductColorOptions = this.configService.commonSettings['ProductsColor'].filter((item) => { return item.ItemCode == this.product.ItemCode })[0] || this.configService.commonSettings['ProductsColor'][0];
    } catch {
      this.ProductColorOptions = this.configService.commonSettings['ProductsColor'][0];
    }
  //  $(".detail-prod").children("p:last-child").css({ "height" : "97px","max-height": "110px", "overflow" : "hidden","display": "flex","flex-direction": "column","justify-content": "space-between"});
  }

  quickView(product){
    let dialogRef = this.dialog.open(QuickViewComponent, {
      disableClose: true,
      data: {env: this.env, product: product},
      panelClass: "center-quickview-dialog"
    });
    dialogRef.afterClosed().subscribe(dataResult => {
      

    })
  }

  // addToCart(item: any) {
  //   if (this.selectedOrderType[item.ItemID] == 1) {
  //     this.cartService.addToCart(item, true, '', false, false, false);
  //   }
  //   else {
  //     this.updateorderwithAutoship(item);
  //   }
  // }
 

  updateorderwithAutoship(item: any) {
    //For AutoOrderItem Adding
    this.cartService.addToCart(item, true, '', true, false, false);
    //For OrderItem Adding
    this.cartService.addToCart(item, true, '', false, false, false);
    this.cartService.sidebarOpenStatus = true;
    this.cartService.sidebarSummaryCartDrawer.open();
  }

  addOrdertoCart = (item) => {

    if(this.accountService.isLoggedUserRetailType()){
      if (item.AllowOnAutoOrder) {
        this.cartService.confrimSubscribe(item.ItemID, item.ItemCode).then((result) => {
          if (result == "learnmore") {
            this.cartService.sidebarSummaryCartDrawer.close();
            this.dialog.closeAll();
            return;
          }
          if (result) {
            this.updateorderwithAutoship(item);
          }
          else {
            //For OrderItem Adding
            this.cartService.sidebarSummaryCartDrawer.open();
            this.cartService.sidebarOpenStatus = true;
            this.cartService.addToCart(item, true, '', false, false, false);
          }
        });
      } else {
        //For OrderItem Adding directly
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
        this.cartService.addToCart(item, true, '', false, false, false);
      }
    }
    else {
      //For OrderItem Adding directly
      this.cartService.sidebarSummaryCartDrawer.open();
      this.cartService.sidebarOpenStatus = true;
      this.cartService.addToCart(item, true, '', false, false, false);
    }
  }

  _handleAddToCart(orderType = "", item: any){
    this.cartService.sidebarSummaryCartDrawer.close();
    orderType === 'onetime'
      ? this.addOrdertoCart(item)
      : orderType === 'subscribe'
      ? this.updateorderwithAutoship(item)
      : '';
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Cart } from 'src/app/core/modals/shoppingcart.modal';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    shoppingCart: any=[];
    constructor(private http: HttpClient, public user: UserService) {

    }

    setShoppingCart(region: number, customerType?: number) {
        customerType = customerType || this.user.userServiceModal.customerTypeID || 2;
            this.shoppingCart = [];
       
    }

    getShoppingCart(orderType: number): Array<Cart> {
        return this.shoppingCart.filter((cart: Cart) => {
            return cart.OrderTypeID == orderType;
        });
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  selectedAddressType: number = 1;
  public data: any = {};
  OriginalAddress: string;
  OriginalZip: string;
  OriginalState: string;
  OriginalCity: string;
  OriginalCountry: string;

  VerifiedAddress: string;
  VerifiedZip: string;
  VerifiedState: string;
  VerifiedCity: string;
  VerifiedCountry: string;
  constructor(@Inject(MAT_DIALOG_DATA) public DIALOG_DATA: any, public dialogRef: MatDialogRef<VerificationComponent>) {
    this.data = DIALOG_DATA;
  }

  ngOnInit(): void {
  }
  selectedAddress() {
    let selectedAddressData = {
      Addresses: this.data,
      Type: this.selectedAddressType
    };

    this.dialogRef.close(selectedAddressData);
  }
}



import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Currency } from 'src/app/core/modals/currency.modal';
import { UserService } from './user.service';
import { APP_BASE_HREF } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    public defaultCurrency = {
        CurrencyCode: 'USD',
        DecimalLength: 2,
        Description: 'United States Dollar',
        ExchangeRate: 1,
        Symbol: '$'
    } as Currency;
    public selectedCurrency: Currency;
    constructor(
        public route: ActivatedRoute,
        public http: HttpClient,
        public userService: UserService
    ) {


    }
    setAutoshipEditFlag(value) {
        if (window.localStorage) {
            window.localStorage.setItem('isEditAutoshipFlag', value);
        }
    }

    getAutoshipEditFlag() {
        if (window.localStorage) {
        return window.localStorage.getItem('isEditAutoshipFlag') == 'true' ? true : false;
        }else{
            return false;
        }
    }
}

export function getBaseLocation() {
    let registerRoute = ['/home','/enrollment/packs', '/enrollment', '/enrollmentcheckout', '/enrollment/enrolleinfo', '/enrollment/enrollcheckout',
        '/cart', '/login', '/signup', '/products', '/product/[a-zA-Z0-9]', '/application', '/register', '/registration',
        '/review', '/enrollment/oldpacks', '/enrollment/enrollmentconfiguration','/enrollment/onetime/[a-zA-Z0-9]', '/enrollment/product-list',
        '/pages/[A-Za-z]', '/checkout/[A-Za-z]', '/thank-you', '/paymentfailed'];
    if (registerRoute.includes(location.pathname)) {
        return '/rainShop';
    }
    if (location.pathname.includes('pages/') && location.pathname.includes('checkout/')) {
    let routeMatch = registerRoute.some((item)=> { 
            return new RegExp(item).test(location.pathname);
    });
    if (routeMatch) {
        return '/rainShop';
        }
    }
    let paths: string[] = location.pathname.split('/').splice(1, 1);
    let basePath: string = (paths && paths[0]) || 'rainShop'; // Default: my-account
    return '/' + basePath;
}
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RestApiService } from 'src/app/core/http/restapi.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { ConfigService } from 'src/app/core/services/config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrderService } from 'src/app/core/services/order.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {

  selectedAddressType: any = 1;
  userService: UserServiceModal
  selectedCountryCode:any
  allowedRegions: any[] = [];
  public shippingCountries:Array<any>=[];
  newAddress : any;
  public PhoneNumCheck: boolean = false;

  constructor(
    public user: UserService,
    public orderService: OrderService,
    private apiService: RestApiService,
    private dialog: MatDialog,
    public configService: ConfigService,
    public notificationService: NotificationService,
    ) {
    this.userService = user.userServiceModal;
    this.apiService.GetCountries().subscribe({
      next: data => {
        this.shippingCountries = data.Data;
        if (window.sessionStorage) {
          const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
          this.userService.shippingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
          this.userService.billingAddress.CountryCode = (selectedCountry || 'US').toUpperCase();
        }
      }
    });
   }
   
  ngOnInit(): void {
    this.newAddress = {
      shippingAddress : {
        FirstName: '',
        LastName: '',
        Street1: '',
        Street2: '',
        Street3: '',
        City: '',
        StateName: this.userService.shippingAddress.StateName,
        CountryCode: this.userService.shippingAddress.CountryCode,
        PostalCode: ''
      }
    }
    this.onCountryChanged();
  }

  countrySelected(e: any) {
    this.selectedCountryCode = e.value;
    this.apiService.GetRegions(this.selectedCountryCode.toUpperCase()).subscribe((data) => {
      this.allowedRegions = data.Data;
      this.userService.defaultState = this.allowedRegions[0]?.RegionCode;
    });
  }

  onCountryChanged() {
    this.apiService.GetRegions(this.userService.shippingAddress.CountryCode).subscribe({
      next: (data) => {
        this.allowedRegions = data.Data;
        this.userService.defaultState = this.allowedRegions[0]?.RegionCode;
      }
    })
  }

  getStateNameAsText(regionCode): string {
    const state = this.allowedRegions.find((region) => region.RegionCode == regionCode);
    if(state) {
      return state.RegionName;
    }
    return '';
  }

  onSubmit = () => {
    if(this.selectedAddressType == 2){
      if (typeof this.userService.shippingAddress === 'object') {
        this.userService.shippingAddress.FirstName = this.newAddress?.shippingAddress?.FirstName
        this.userService.shippingAddress.LastName = this.newAddress?.shippingAddress?.LastName
        this.userService.shippingAddress.FullName = this.newAddress?.shippingAddress?.FirstName + ' ' + this.newAddress?.shippingAddress?.LastName;
        this.userService.shippingAddress.Street1 = this.newAddress?.shippingAddress?.Street1
        this.userService.shippingAddress.Street2 = this.newAddress?.shippingAddress?.Street2
        this.userService.shippingAddress.Street3 = this.newAddress?.shippingAddress?.Street3
        this.userService.shippingAddress.City = this.newAddress?.shippingAddress?.City
        this.userService.shippingAddress.StateName = this.newAddress?.shippingAddress?.StateName
        this.userService.shippingAddress.Region = this.newAddress?.shippingAddress?.StateName
        this.userService.shippingAddress.CountryCode = this.newAddress?.shippingAddress?.CountryCode
        this.userService.shippingAddress.PostalCode = this.newAddress?.shippingAddress?.PostalCode
        this.userService.shippingAddress.Phone = this.newAddress?.shippingAddress?.Phone
        this.userService.shippingAddress.StateNameAsText = this.getStateNameAsText(this.newAddress?.shippingAddress?.StateName);
      }
    }
    this.dialog.closeAll();
  }
  verifyPhone(id: any) {
    var selectedCountry = "";
    try {
      selectedCountry = this.selectedCountryCode.toUpperCase();
    } catch {
      selectedCountry = this.configService.commonData.selectedCountry.toUpperCase();
    }

    if (this.newAddress.shippingAddress.Phone) {
      this.apiService.validatePhone((selectedCountry || this.configService.commonData.selectedCountry), this.newAddress.shippingAddress.Phone.replace(/[^a-zA-Z0-9]/g, '')).subscribe({
        next: (data) => {
          if (data.Data && data.Status == 0) {
            if (data.Data.PhoneValid) {
              this.PhoneNumCheck = true;
              this.newAddress.shippingAddress.Phone = data.Data.InternationalNumber;
            } else {
              this.newAddress.shippingAddress.Phone = "";
              this.notificationService.error('Error', 'IncorrectPhone');
            }
          } else {
            this.newAddress.shippingAddress.Phone = "";
            this.notificationService.error('Error', 'IncorrectPhone');
          }
        }, error: (error) => {

        },
        complete: () => {

        }
      });
    }
  }
}

<div class="parent" fxLayout="column">
  <app-breadcrumb [title]="'create_customer_account' | translate" [showCrumbs]="false"></app-breadcrumb>
  <div class="container" fxFlex="50">
    <div class="referral">
      <p class="form-title" translate="referral_information"></p>
      <mat-divider></mat-divider>
      <div class="refer-question">
        <p class="f-s-16" translate="are_you_sponsored_by_member"></p>
          <mat-radio-group class="radio-grp" [(ngModel)]="userService.isReferredBy" fxLayout="column">
            <mat-radio-button [value]="false" ><span translate="not_reffered_by_anyone"></span></mat-radio-button>
            <mat-radio-button [value]="true"><span translate="reffered_by_someone"></span></mat-radio-button>
          </mat-radio-group>
      </div>
      
      <div class="hidden-form" *ngIf="userService.isReferredBy">
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" fxFlex="100">
            <mat-label for="referFirstName" translate="FirstName"></mat-label>
            <input matInput type="text" name="referFirstName" validate noempty="true"
              [(ngModel)]="userService.referralInfo.FirstName" id="referFirstName" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="referLastName" translate="LastName"></mat-label>
            <input matInput type="text" name="referLastName" validate noempty="true"
              [(ngModel)]="userService.referralInfo.LastName" id="referLastName" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="sponserId" translate="sponsor_id"><span></span></mat-label>
            <input matInput type="text" name="sponserId" [(ngModel)]="userService.referralInfo.SponsorID"
              id="sponserId" />
          </mat-form-field>
        </div>
      </div>
    </div>


    <!-- Main form -->
    <div class="main-form">
      <p class="form-title" translate="your_information"></p>
      <mat-divider></mat-divider>
      <form class="form">
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="firstName" translate="FirstName"></mat-label>
            <input matInput type="text" name="firstName" validate noempty="true"
              [(ngModel)]="userService.customerData.FirstName" id="firstName" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="lastName" translate="LastName"></mat-label>
            <input matInput type="text" name="lastName" validate noempty="true"
              [(ngModel)]="userService.customerData.LastName" id="lastName" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="email" translate="Email"></mat-label>
            <input matInput type="email" name="email" validate noempty="true" email="true"
              (focusout)="verifyEmail(userService.customerData.Email);" [(ngModel)]="userService.customerData.Email" id="email" />
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" fxFlex="100">
            <mat-label translate="WhereDidYouHear"></mat-label>
            <mat-select [(ngModel)]="userService.customerData.AdverseName" [(value)]="userService.customerData.AdverseName"
              name="adverse" (ngInit)="userService.customerData.AdverseName = AdverseList[0]?.name" validate validate-select
              checkOutSection="true">
              <mat-option *ngFor="let adverse of AdverseList" [value]="adverse.name"> {{adverse.name}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="UserName" translate="Username"></mat-label>
            <input matInput type="text" name="UserName" validate noempty="true" inputspace="true" validname="true"
              (paste)="$event.preventDefault()" (focusout)="verifyUsername(userService.webOffice.UserName);"
              [(ngModel)]="userService.webOffice.UserName" id="username" />
          </mat-form-field>
        </div>
        <div class="field" fxLayout="column">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label translate="Password"></mat-label>
            <input matInput [type]="ShowPassword ? 'text' : 'password'" name="password" validate
              noempty="true" regxvalid="true" regxvalidvalue="((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})" nospaceallow="true"
              [(ngModel)]="userService.webOffice.Password" id="txtPassword" />
          </mat-form-field>
          <mat-password-strength #passwordComponent class="green" [password]="userService.webOffice.Password">
          </mat-password-strength>
          <mat-checkbox class="checkbox m-t-15" validate noempty="true" [(ngModel)]="ShowPassword"
            color="primary"> <span translate="ShowMyPassword"></span></mat-checkbox>
        </div>
        <div class="field">
          <mat-form-field class="exigo-validate" appearance="outline" color="primary" fxFlex="100">
            <mat-label for="repassword" translate="ConfirmPassword"></mat-label>
            <input matInput [type]="ShowPassword ? 'text' : 'password'" validate noempty="true"
              password-match="true" [(ngModel)]="userService.webOffice.ConfirmPassword" name="repassword"
              id="repassword" />
          </mat-form-field>
        </div>
        <div class="field promotion-field" fxFlexAlign="start">
          <div class="mat-form-field exigo-validate checkbox">
            <mat-checkbox color="primary" class="m-t-15" validate checkbox-validate="true"
              [(ngModel)]="userService.personalInfo.SendMail"> <span translate="IWouldLikeToRecieveEmails"></span>
            </mat-checkbox>
          </div>
        </div>
      </form>
    </div>
    <div class="field">
      <button class="submit-btn w-100" mat-raised-button type="submit" validate trigger-check="true" (success)="createUser();"
        translate="SubmitRegistration"></button>
    </div>

  </div>
</div>
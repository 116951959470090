import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { ProductService } from 'src/app/core/services/product.service';
import { CartService } from 'src/app/core/services/cart.service';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { ItemsListService } from 'src/app/core/services/itemsList.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { PersistentService } from 'src/app/core/services/persistent.service';
import { AutoshipConfigurationService } from 'src/app/core/services/autoshipConfiguration.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/services/user.service';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { QuickViewComponent } from 'src/app/shared/model/quick-view/quick-view.component';
import { ConfirmSubscribeComponent } from 'src/app/shared/model/confirm-subscribe/confirm-subscribe.component';
import $ from 'jquery';
import { AccountService } from 'src/app/core/authentication/account.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: true,
    pagination: true
  };
  public testimonialConfig: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: true,
    pagination: true
  };
  public slides = [
    '/assets/images/productsideimage.png',
    '/assets/images/productsideimage.png',
    '/assets/images/productsideimage.png'
  ];
  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  env: any;

  public product: any = {};
  public products: any[] = [];
  public relatedProducts: any[] = [];
  public orderOptions: any;
  public image: any;
  public zoomImage: any;
  ezpModel: any = {};
  event: any;
  activeImage: any = '';
  value:Array<any>=[];
  defaultImage: any = {};
  isOptionsSelected: boolean;
  public counter: number = 1;
  public OrderQuantityCount: any;
  itemTypes: any = {};
  index: number;
  bigProductImageIndex = 0;
  CartType: string;
  itemType: string;
  type: string;
  ItemCode: any;
  isShowShareOptions: boolean;
  panelOpenSpecification: boolean = false;
  panelOpenAutoship: boolean = false;
  public selectedOrderType: number;
  public userService: UserServiceModal
  orderType:any = [{typeId: 1, name: 'One Time Order'},{typeId: 2, name: 'Subscription Order'}];
  extraInfo: boolean;
  dummyDetails: string = '';
  public sliderVideos:any;
  public sliderFeeds:any;
  public ProductColorOptions:any=[];
  selectedCountry: any;

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  public showMore:boolean=false;
  public showmore:boolean=false;
  constructor(
    private titleService: Title,
    private translate: TranslateService,
    public configService: ConfigService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    public cartService: CartService,
    public itemsService: ProductService,
    public itemsListService: ItemsListService,
    public activateroute: ActivatedRoute,
    public utilityService: UtilityService,
    public persistentService: PersistentService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public companyService:CompanyService,
    public user: UserService,
    public accountService: AccountService
  ) {
    this.route.params.subscribe(params => {
      this.ItemCode = params['id'];
      this.activateroute.queryParams.subscribe((queryparam:any) => {
        this.itemType = queryparam.itemtype || queryparam.itemType;
        this.showMore =Boolean(queryparam.showMore || false);
        this.showmore =Boolean(queryparam.showmore || false);
        if(this.showMore === false){
          let newQuery = Object.assign({}, queryparam, { showMore : (this.showmore == true ? true :null)});
          this.router.navigate(['.'], {relativeTo: this.route, queryParams: newQuery, replaceUrl: true});
        }
        let newQuery = Object.assign({}, queryparam, { showMore : (this.showMore == true ? true :null)});

        this.router.navigate(['.'], {relativeTo: this.route, queryParams: newQuery, replaceUrl: true});
      });
      // this.productsService.getProduct(this.ItemCode).subscribe(product => {
      //   this.product = product;
      //   this.OrderQuantityCount = this.product && this.product.Quantity ? this.product.Quantity : 1;
      // });
      this.defaultImage = {
        Path: '../.././../../assets/images/noimage.png',
        Description: this.product ? this.product.Description : ''
      };
      this.CartType = this.itemType || 'order';
      this.type = this.CartType;
    });

    this.selectedCountry = sessionStorage.getItem('selectedCountry') ||"US";
    this.itemTypes = {
      pack: {
        getItemsCall: 'GetEnrollmentKitItems',
        itemQuantity: 'packQuantity',
        cartItems: 'selectedPacks',
        itemserviceCall: 'getPackItems'
      },
      order: {
        getItemsCall: 'GetProducts',
        itemQuantity: 'orderQuantity',
        cartItems: 'selectedOrderItems',
        itemserviceCall: 'getOrderItems'
      },
      autoship: {
        getItemsCall: 'GetAutoshipItems',
        itemQuantity: 'autoshipQuantity',
        cartItems: 'selectedAutoOrderItems',
        itemserviceCall: 'getAutoshipItems'
      }
    };
    this.userService = user.userServiceModal;
    this.env = environment;
    this.selectedOrderType = 2;
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.autoshipConfigurationService.init();
    this.itemsService.getProductByItemCode(this.ItemCode).subscribe((product) => {
      if(!product){
        this.router.navigate(['/products']);
      }
      this.scrollToTop();
      this.product = [product];
      let Video=this.product[0]?.Item?.ShortDetail2.replace(/(\r\n|\n|\r)/gm, "");
      let Feed=this.product[0]?.Item?.ShortDetail3.replace(/(\r\n|\n|\r)/gm, "");
      try{
       this.sliderVideos=JSON.parse(Video);
      }catch(e){

         // this.sliderVideos={
        //   '2020000_tag_bannerimages':['https://ms.z1p.bz/rain-products/slider-image-1.png','https://ms.z1p.bz/rain-products/slider-image-2.png'],
        //   '2020000_tag_bannervideos':['https://ms.z1p.bz/rain-products/2020000_soul_benefits.mp4']
        // };
      }
      try{
       this.sliderFeeds=JSON.parse(Feed);

      }catch(e){

      //  this.sliderFeeds={
      //   '2020000_tag_ss': [
      //     {
      //       'ss_image': 'https://ms.z1p.bz/rain-products/Soul_Vernon_Connor_circle.png',
        //       'ss_story': 'Had it not been for SOUL, I probably would still be using traditional instead of natural things everyday.',
        //   'ss_name':'Vernon C.'
      //     },
      //     {
      //       'ss_image': 'https://ms.z1p.bz/rain-products/Soul_Starr_Moffet_circle.png',
        //       'ss_story': 'I have noticed that not only my focus and concentration are on point again, but my body just feels... balanced!',
        //   'ss_name':'Star M.'
      //     }, {
      //       'ss_image': 'https://ms.z1p.bz/rain-products/Soul_Ritz_serofia_circle.png',
        //       'ss_story':'I feel good and my heart was relaxed. I have more energy than before.',
        //   'ss_name':'Ritz S.'
      //     }]
      //};
      }

      const metaTitle=this.product[0]?.Item?.ItemDescription;
      this.translate.get('CompanyName').subscribe({next:(text) => {
        this.titleService.setTitle(this.translate.instant(metaTitle) + ' - ' + text);
      }});
      this.relatedProducts = product?.FrequentlyBoughtTogetherItems;
      try{

        this.ProductColorOptions=this.configService.commonSettings['ProductsColor'].filter((item)=>{ return item.ItemCode==product.Item.ItemCode})[0] || this.configService.commonSettings['ProductsColor'][0];
        }catch{
          this.ProductColorOptions=this.configService.commonSettings['ProductsColor'][0];
        }
      this.OrderQuantityCount = product && product.Quantity ? product.Quantity : 1;
      this.onItemsSucces(this.product);
      this.setUpOrderOptions(this.product);
    });
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      scrollbar:false,
      navigation: false,
      autoplay: {
        delay: 7000,
      },
      pagination:  {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 1
        }
      }
    };
    this.testimonialConfig = {
      observer: true,
      direction: 'horizontal',
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      scrollbar:false,
      navigation: false,
      autoplay: {
        delay: 4000,
      },
      effect: 'fade',
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 1
        },
        1280: {
          slidesPerView: 1
        }
      }
    };
  } 

  public onItemsSucces(result) {
    if (result) {
  
    result = [result[0].Item];
    this.itemType = this.type === 'order' ? 'order' : this.type == 'pack' ? 'pack' : 'autoship';
    this.itemsService[this.itemType] = result.map((item) => {
      item.Price = item.Price || item['PriceType' + this.userService.customerTypeID + 'Value'];
      return item;
    });
    this.itemsListService.products = this.itemsService[this.itemType];
    this.itemsListService.type = this.type;
    const selectedItem = this.product;
    this.products = result.map((item) => {
      item.Price = item.Price || item['PriceType' + this.userService.customerTypeID + 'Value'];
      return item;
    });
    if (selectedItem) {
      this.products = _.filter(this.products, (product) => {
        return product.ItemID != selectedItem.ItemID;
      });
    }
    if (this.ItemCode) {
      let isItemExists = false;
      _.each(result, (item) => {
        if (item.HasOptions) {
          _.each(item.OptionsMap, (value) => {
            if (value.ItemCode == this.ItemCode) {
              item.ItemCode = this.ItemCode;
              item.ItemID = value.ItemID;
              return;
            }
          });
        }
        //if (this.ItemCode ? ((item?.ItemDescription || '').toLowerCase().replace(/\s/g, '') == this.ItemCode.toLowerCase().replace(/\s/g, '')) : (item.ItemCode == this.ItemCode)) {
          isItemExists = true;
          this.product = this.transformItem(item);
          this.OrderQuantityCount = selectedItem ? (selectedItem.Quantity || 1) : 1;
          item.Quantity = selectedItem ? (selectedItem.Quantity || 1) : 1;
          localStorage.setItem('params.type', this.type);
          localStorage.setItem('params.productList', JSON.stringify(this.products));
          // $scope.setDefaultValue(item, type);
        //}
      });
      if (!isItemExists) {
        this.cartService.checkoutPaymentButton = false;
        this.router.navigate(['/Products']);
        // $state.go('Products', { WebAlias: $state.params.WebAlias });
      }
    }
    }
  }
  public transformItem(item) {
    this.isShowShareOptions = false;
    item.Price =
      item.Price || item.Price === 0
        ? item.Price
        : item.Prices && item.Prices[0] && item.Prices[0].Price;
    item.Images = item.Images || [];
    if (
      item.LargeImageUrl &&
      !_.find(item.Images, { Path: item.LargeImageUrl })
    ) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.LargeImageUrl
      });
    } else if (item.Image && !_.find(item.Images, { Path: item.Image })) {
      item.Images.unshift({
        Description: item.Description,
        Path: item.Image
      });
    }
    this.isShowShareOptions = true;
    return item;
  }

  public selectImage(index, image) {
    this.bigProductImageIndex = index;
  }

  public increaseQuantity() {
    if (this.OrderQuantityCount < 99) {
      this.OrderQuantityCount++;
    }
  }

  public decreaseQuantity() {
    if (this.OrderQuantityCount > 1) {
      this.OrderQuantityCount--;
    }
  }

  // Add to cart
  public addToCart(type, product: any) {
    // if (quantity === 0) {
    //   return false;
    // }
    // this.currentQuantity(type, product);
    if (type === 'order') {
      this.cartService.sidebarSummaryCartDrawer.close();
      if (!this.hideAddToCart(product, true)) {
        //For OrderItem Adding
        this.cartService.addToCart(
          product,
          true,
          this.getItemCode(product), // product.Sku
          type === 'autoship',
          false,
          type === 'pack',
          false
        );
        this.dialog.closeAll();
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
      }
      else if (this.accountService.isLoggedUserRetailType())  {
        this.cartService.confrimSubscribe(product.ItemID, product.ItemCode).then((result) => {
          if (result == "learnmore") {
            //this.cartService.sidebarSummaryCartDrawer.open()
            this.dialog.closeAll();
            return;
          }
          if (result) {
            //For AutoOrderItem Adding
            this.cartService.addToCart(product, true, this.getItemCode(product), true, false, false);
            this.cartService.addToCart(
              product,
              true,
              this.getItemCode(product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
          }
          else {
            //For OrderItem Adding
            this.cartService.addToCart(
              product,
              true,
              this.getItemCode(product), // product.Sku
              type === 'autoship',
              false,
              type === 'pack',
              false
            );
          }
          this.cartService.sidebarSummaryCartDrawer.open();
          this.cartService.sidebarOpenStatus = true;
          this.dialog.closeAll();
        });
      } else {
        //For OrderItem Adding
        this.cartService.addToCart( product, true, this.getItemCode(product), type === 'autoship', false, type === 'pack', false );
        this.cartService.sidebarSummaryCartDrawer.open();
        this.cartService.sidebarOpenStatus = true;
      }

      this.product.Quantity = this.OrderQuantityCount;
    }
    else {
      this.updateorderwithAutoship(product);
    }
  }
  updateorderwithAutoship(item: any) {
    //For AutoOrderItem Adding
    this.cartService.addToCart(item, true, this.getItemCode(item), true, false, false);
    //For OrderItem Adding
    this.cartService.addToCart(item, true, this.getItemCode(item), false, false, false);

  }
  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      let image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = (offsetX / image.offsetWidth) * 100;
      y = (offsetY / image.offsetHeight) * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = 'block';
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = 'none';
  }

  openZoomViewer() {
    // this.dialog.open(ProductZoomComponent, {
    //   data: this.zoomImage,
    //   panelClass: 'zoom-dialog'
    // });
  }

  getPrice(item) {

    // If logged In
    if(this.accountService.isLoggedIn()){
      if (item!=null && item.GroupMembers?.length>0) {
        return (item?.GroupMembers[0].Item[`PriceType${this.userService.customerTypeID}Value`] || (item?.GroupMembers[0].Item.Prices && item?.GroupMembers[0].Item.Prices[0].Price));
      } else {
        return (item[`PriceType${this.userService.customerTypeID}Value`]);
      }
    }

    if (item!=null && item.GroupMembers?.length>0) {
      return (item?.GroupMembers[0].Item.Price || (item?.GroupMembers[0].Item.Prices && item?.GroupMembers[0].Item.Prices[0].Price));
    }else{
      return (item.Price || (item.Prices && item.Prices[0].Price));
    }
  }
  getSubsPrice(item) {
    if (item!=null && item.GroupMembers?.length>0) {
      return (item?.GroupMembers[0].Item[`PriceType${this.userService.customerTypeID==3?this.userService.customerTypeID:2}Value`]);
    }else{
      return (item[`PriceType${this.userService.customerTypeID==3?this.userService.customerTypeID:2}Value`]);
    }
  }

  checkOptions(option) {
    let count = 0;
    this.value.forEach((item) => {
      if (item) {
        count++;
      }
    });

    this.isOptionsSelected = (count === option.length);
  }
  currentQuantity(type, item) {
    type = type || this.itemType;
    this.cartService[this.itemTypes[type].itemQuantity] =
    this.cartService[this.itemTypes[type].itemQuantity] || {}; // TODO::check if needed
    this.cartService[this.itemTypes[type].itemQuantity][this.getItemCode(item)] =
      this.OrderQuantityCount;
  }

  setUpOrderOptions(item) {
    if (!this.orderOptions) {
      this.orderOptions = {};
    }

    const dict = this.orderOptions;

    // If editing an item with selected customizations, find them and set the dropdowns
    let optionsMap;
    _.each(item.OptionsMap, (val) => {
      val.OptionNames = val.Key.split('|');
    });
    if (item.selectedOptions) {
      item.OptionsMap.some(optMap => {
        if (optMap.ItemId === (item.KitCustomItemCode || item.ItemID)) {
          optionsMap = optMap.OptionNames;
          return true;
        }
        return false;
      });
    }

    // Otherwise, default to the first options map
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map(optMap => {
          return optMap.OptionNames;
        })
      : [];

    if (!item.selectedOptions || !optionsMap) {
      optionsMap = optionMapNames[0];
    }

    // Loop through the OptionsMap
    let optionVal;
    let optionValues;
    for (const index in optionsMap) {
      if (optionsMap.hasOwnProperty(index)) {
        optionVal = optionsMap[index];
        // For each option in the OptionsMap, loop through the ItemOptions
        for (const itemIndex in item.ItemOptions) {
          // Get all the values of the ItemOption
          if (item.ItemOptions.hasOwnProperty(itemIndex)) {
            optionValues = item.ItemOptions[itemIndex].Values.map(
              getOptionValueName
            );
            // If one of them is the value from the OptionsMap, use it
            const option = item.ItemOptions[itemIndex];
            if (
              !!~optionValues.indexOf(optionVal) &&
              !dict[this.getOrderOptionKey(item, option)]
            ) {
              dict[this.getOrderOptionKey(item, option)] = optionVal;
              break;
            }
          }
        }
      }
    }

    function getOptionValueName(val) {
      return val.Option;
    }
  }

  // change active image
  setActiveImage(image) {
    this.ezpModel = {
      small: image.Path || this.defaultImage.Path,
      large: image.Path || this.defaultImage.Path
    };
    setTimeout(() => {
      this.activeImage = image;
    }, 1);
  }

  selectOption(item, option, value, isKitItem) {
    option.selected = value[0];
    this.orderOptions[this.getOrderOptionKey(item, option)] = value[0];
    const tempOptData = [];
    Object.keys(this.orderOptions).forEach(optdata => {
      tempOptData.push(optdata);
    }),
      item.GroupMembers.forEach(itemOptdata => {
        let tempimgurl = itemOptdata.Item.LargeImageUrl;
          const tempimage = {
            Path: tempimgurl
          };
          this.setActiveImage(tempimage);
      });
    if (isKitItem) {
      item.KitCustomItemCode = this.getItemCode(item);
    }
  }

  setInitialItemOption(option, value) {
    const defaultOption =
      this.orderOptions[this.getOrderOptionKey(this.product, option)] ||
      option.Option;
    if (value.Option === defaultOption) {
      option.selected = value.Option;
    }
  }

  getOrderOptionKey(item, option) {
    return option.selected.ItemID;
  }

  // Determine what values are available based on what's already been selected
  getValidValues(item, option, values) {
    // Get the already-selected option values (except this one and values from other items)
    const currentOption = this.getOrderOptionKey(item, option);
    const otherOptions = [];
    for (const key in this.orderOptions) {
      if (
        key === currentOption ||
        item.ItemID.toString() !== key
      ) {
        continue;
      }
      otherOptions.push(this.orderOptions[key]);
    }
    // Whittle down the OptionMaps to the ones that have all the already-selected options
    const optionMapNames = item.GroupMembers
      ? item.GroupMembers.map(optMap => {
        return item.ItemID;
        })
      : [];
    const validOptionMaps = optionMapNames.filter(optNames => {
      // For each OptionMap, ensure that it contains all the other selected options
      let optionValue1;
      for (const index in otherOptions) {
        if (otherOptions.hasOwnProperty(index)){
          optionValue1 = otherOptions[index];
          if (~optNames.indexOf(optionValue1)) {
            continue;
          }
          return false;
        }
      }
      return true;
    });

    // Union the validOptionMaps
    const validOptionValues = validOptionMaps.reduce((arr, optMap) => {
      let optionName;
      for (const index in optMap) {
        if (optMap.hasOwnProperty(index)){
          optionName = optMap[index];
          if (!~arr.indexOf(optionName)) {
            arr.push(optionName);
          }
        }
      }
      return arr;
    }, []);

    // Set `isAvailable: true` on each option that can be found in validOptionNames, and `isAvailable: false` on the others
    let optionValue;
    for (const index in values) {
      if (values.hasOwnProperty(index)) {
        optionValue = values[index];
        optionValue.isAvailable = !!~validOptionValues.indexOf(
          optionValue.Option
        );
      }
    }

    // return the original values
    return values.filter(itm => {
      return itm.isAvailable;
    });
  }

  getOptionsText(optionValArray) {
    return '[' + optionValArray.join(', ') + ']';
  }

  getItemCode(item) {
    item.selectedOptions = item?.ItemDescription;
    // If there are no customizations, just return the item code
    if (!item.GroupMembers || !item.GroupMembers.length) {
      // return this.product.ItemID;
      return item.ItemCode
    } else {
      return this.value[0]?.ItemCode;
    }

    // // If there are customizations, find the right OptionsMap and use its code
    // const optionValues = [];
    // for (const key in this.orderOptions) {
    //   if (
    //     !this.orderOptions.hasOwnProperty(key) ||
    //     item.ItemID.toString() !== key
    //   ) {
    //     continue;
    //   }

    //   optionValues.push(this.orderOptions[key]);
    // }

    // optionValues.sort();



    // if (item.HasKitOptions) {
    //   item.KitGroups.forEach(kitGroup => {
    //     kitGroup.Items.forEach(this.getItemCode);
    //   });
    // }

    // let mapping;
    // for (const index in item.OptionsMap) {
    //   if (item.OptionsMap.hasOwnProperty(index)){
    //     mapping = item.OptionsMap[index];
    //     mapping.OptionNames.sort();
    //     if (_.isEqual(optionValues, mapping.OptionNames)) {
    //       return mapping.ItemID;
    //     }
    //   }
    // }

    // throw new Error(
    //   'Error: ' +
    //     item.ItemDescription +
    //     ' does not have a mapping for ' +
    //     optionValues.toString() +
    //     '.'
    // );
  }

  openSelect(event) {
    this.event = event || this.event;
    // this is due to select input fields are not consistant in some browser
    if (window.screen.availWidth < 600 && !event) {
      setTimeout(() => {
        // $('.md-select-menu-container.md-active.md-clickable').css('top', (this.event.clientY - 20 + 'px'));
      }, 100);
    }
  }

  hideAddToCart(item, isAutoShip = false) {
    if (!this.utilityService.isEmptyObject(item)) {
      const ItemCode = this.getItemCode(item);
      let isOrderAllow = false;
      if (item.GroupMembers && item.GroupMembers.length > 0 && isAutoShip) {
        _.each(item.GroupMembers, (value) => {
          if (value.ItemCode == ItemCode) {
            if (isAutoShip) {
              isOrderAllow = item.AllowOnAutoOrder; // ? value.AllowAutoship : false;
            } else {
              isOrderAllow = false;
            }

          }
        });
      } else if (isAutoShip) {
        return item.AllowOnAutoOrder;
      } else {
        return false;
      }
      return isOrderAllow;
    }
  }

  ngOnDestroy() {
    this.OrderQuantityCount = 1;
    this.cartService.resetItemQuantities();
  }

  quickView(product) {
    let dialogRef = this.dialog.open(QuickViewComponent, {
      // minWidth: '90vw',
      // minHeight: '33rem',
      // maxWidth:'1300px',
      // width: '72rem',
      // height: '35rem',
      // position: {
      //   top: `calc(50vh - 300px)`,
      //   bottom: '',
      //   left: '',
      //   right: ''
      // },
      data: {env: this.env, product: product},
      panelClass: "center-quickview-dialog"
    });
    dialogRef.afterClosed().subscribe(dataResult => {

    })
  }
  video() {
    this.videoplayer?.nativeElement.play();
    this.config.autoplay = false;
  }
  scrollToSubsciptionBox(){
    document.getElementById('subscription-box').scrollIntoView(false);
  }
  onIndexChange(index: number) {
    this.videoplayer?.nativeElement.pause();
  }

  scrollToTop(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}



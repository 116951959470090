import { ApplicationInitStatus, EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { UserServiceModal } from 'src/app/core/modals/userservice.modal';
import { NotificationService } from './notification.service';
import { ProductService } from './product.service';
import { RestApiService } from '../http/restapi.service';
import { UserService } from './user.service';
import { ConfigService } from './config.service';
import { UtilityService } from './utility.service';
import { CompanyService } from './company.service';
import { AccountService } from '../authentication/account.service';
import { DiscountConfig, discountUnlistedCountries } from 'src/assets/data/discount';
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public calculateOrderResponse: any = {};
  public calculateAutoOrderResponse: any = {};
  public lastRequest = {};
  public lastAutoOrderRequest = {};
  public packTotal = 0;
  public packBusinessVolume = 0;
  public orderTotal = 0;
  public autoorderTotal = 0;
  public packsTotal = 0;
  public orderBusinessVolume = 0;
  public autoorderBusinessVolume = 0;
  public PreventTaxJarCalculation: boolean = true;
  public calculateOrderCall: boolean;
  public userService: UserServiceModal;
  public selectedLanguage: any;
  @Output() resetPointsTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Payment with Points Methods
  public pointsPaymentCheckout =  {
    creditAmountInput: 0.00,
    walletAmountInput: 0.00,
    cashAmountInput: 0.00,
    remainderAmount: 0.00,
    allItemsEligibleForRainCashAmount : [],
    finallyRainCashItems : [],
    selectedAccounts : [
      { selectionType: "credit", selectionStatus: false},
      { selectionType: "wallet", selectionStatus: false},
      { selectionType: "cash-account", selectionStatus: false},
    ],
    calculateRemainder: () => {
      
      let usingCreditOrCash: string;
      this.pointsPaymentCheckout.selectedAccounts.forEach((account) => {
        if(account.selectionType == 'credit' && account.selectionStatus == true){
          usingCreditOrCash = 'credit';
          return;
        }
        if(account.selectionType == 'cash-account' && account.selectionStatus == true){
          usingCreditOrCash = 'cash-account';
          return;
        }
      });

      usingCreditOrCash === 'credit'
        ? (this.pointsPaymentCheckout.cashAmountInput = 0.0)
        : usingCreditOrCash === 'cash-account'
        ? (this.pointsPaymentCheckout.creditAmountInput = 0.0)
        : '';

      const creditUsing = this.pointsPaymentCheckout.creditAmountInput;
      const walletUsing = this.pointsPaymentCheckout.walletAmountInput;
      const cashUsing = this.pointsPaymentCheckout.cashAmountInput;
      const GrandTotal = this.calculateOrderResponse.Total;
      
      const totalDeduction = ( creditUsing +  walletUsing + cashUsing);
      let rem = GrandTotal - (+totalDeduction.toFixed(2));

      // If remainder goes in negative ( may happen if cart items quantity is updated )
      if(rem < 0 && !isNaN(rem)){
        this.pointsPaymentCheckout.resetPointsCalculation();
      } else {
        this.pointsPaymentCheckout.remainderAmount = rem;
      }

      console.log("remainder calculated");
      
    },
    hasPointAccount: (accountType: string) => {

      try {
        switch (accountType) {
          case "credit":
            return (this.userService?.customerData?.UserCreditAccount?.Balance >= 0);
          case "wallet":
            return (this.userService?.customerData?.UserWalletAccount?.Balance >= 0);
          case "cash-account":
          return (this.userService?.customerData?.UserCashAccount?.Balance >= 0);
          default:
            return false;
        }
      } catch(ex) {
        return false;
      }
      
    },
    resetPointsCalculation: () => {
      // Reset Everything related to points manipulation.
      this.pointsPaymentCheckout.creditAmountInput = 0.00
      this.pointsPaymentCheckout.walletAmountInput = 0.00
      this.pointsPaymentCheckout.cashAmountInput = 0.00

      // Set all accounts to false
      this.pointsPaymentCheckout.selectedAccounts.forEach(acc => acc.selectionStatus = false);

      //Making all selected items empty
      this.pointsPaymentCheckout.allItemsEligibleForRainCashAmount = [];
      this.pointsPaymentCheckout.finallyRainCashItems = [];

      const creditUsing = this.pointsPaymentCheckout.creditAmountInput;
      const walletUsing = this.pointsPaymentCheckout.walletAmountInput;
      const cashUsing = this.pointsPaymentCheckout.cashAmountInput;
      const GrandTotal = this.calculateOrderResponse.Total;

      const totalDeduction = ( creditUsing +  walletUsing + cashUsing);
      this.pointsPaymentCheckout.remainderAmount = GrandTotal - totalDeduction;
      
      // Emitting Event to verify "points reseted"!
      this.resetPointsTriggered.emit(true);
    }
  }

  @Output() changeInOrder: EventEmitter<any> = new EventEmitter();
  constructor(
    public user: UserService,
    public notificationService: NotificationService,
    public route: ActivatedRoute,
    public apiService: RestApiService,
    public translate: TranslateService,
    public itemsService: ProductService,
    public configService: ConfigService,
    public appInit: ApplicationInitStatus,
    public utilityService: UtilityService,
    public companyService: CompanyService,
    public accountService: AccountService
  ) {
    this.userService = this.user.userServiceModal;
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage') === 'undefined' ? null : JSON.parse(sessionStorage.getItem('selectedLanguage'));
    //appInit.donePromise.then(() => this.onInit());
  }

  onInit(): void {

    if (this.itemsService.selectedOrderItems && this.itemsService.selectedOrderItems.length > 0) {
      this.calculateOrder();
      //this.calculateLocalOrder();
    }
    if (this.itemsService.selectedAutoOrderItems && this.itemsService.selectedAutoOrderItems.length > 0) {
      this.calculateAutoOrder();
      //this.calculateLocalAutoOrder();
    }
  }
  /*** calculate Order ***/
  public calculateOrder(showNotification?: boolean): any {
    const promise = new Promise((resolve, reject) => {
      const productdetails = [];

      if (!this.accountService.isLoggedIn()) {
        if (this.itemsService.selectedPacks.length == 0 && this.userService.customerTypeID == 3) {
          this.userService.customerTypeID = 2;
        }
        if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length > 0) {
          this.userService.customerTypeID = 2;
        }
        if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0) {
          this.userService.customerTypeID = 1;
        }
      }

      //const kititems = [];
      this.orderTotal = 0;
      this.packsTotal = 0;
      this.itemsService.selectedPacks.forEach(val => {
        if (val.ItemCode != 'DiscountCode') {
          this.packsTotal = this.packsTotal + (val.Quantity * (val['PriceType' + this.userService.customerTypeID + 'Value'] || val.PriceEach || 0));
        }
      })

      this.itemsService.selectedOrderItems.forEach(val => {
        if (val.ItemCode != 'DiscountCode') {
          this.orderTotal = this.orderTotal + (val.Quantity * (val['PriceType' + this.userService.customerTypeID + 'Value'] || val.PriceEach || 0));
        }
      })

      const orderAndPacksCombinedTotal = this.orderTotal + this.packsTotal;

      this.itemsService.selectedOrderItems.forEach((item) => {
        if (item.ItemCode == 'DiscountCode' && (this.applyDiscount(orderAndPacksCombinedTotal) > 0)) {
          productdetails.push({
            ItemCode: item.ItemCode,
            Quantity: 1,
            PriceEachOverride: (this.applyDiscount(orderAndPacksCombinedTotal) * -1)
          });
        } else {
          productdetails.push({
            ItemCode: item.ItemCode,
            Quantity: item.Quantity || 1
          });
        }
      });

      
      this.itemsService.selectedPacks.forEach((item) => {
        if(!this.accountService.isLoggedIn()) {
          this.userService.customerTypeID = 3;
        }
        productdetails.push({
          ItemCode: item.ItemCode,
          Quantity: item.Quantity || 1
        });
      });

      if (productdetails.length == 0) {
        return false;
      }

      console.log("Pricing calculation changes for Retail & Preffered C.");

      /***************************************************/

      if(this.accountService.isLoggedIn()) {

        // Retail Customer
        if(this.userService?.customerData.CustomerType == 1) {
          if (this.itemsService.selectedPacks.length > 0) {
            this.userService.customerTypeID = 3;
          }
          if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length > 0) {
            this.userService.customerTypeID = 2;
          }
          if(this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0) {
            this.userService.customerTypeID = 1;
          }
        }

        // Preffered Customer
        if(this.userService?.customerData.CustomerType == 2) {
          if (this.itemsService.selectedPacks.length > 0) {
            this.userService.customerTypeID = 3;
          } else if (this.itemsService.selectedPacks.length == 0) {
            this.userService.customerTypeID = 2;
          }
        }

      }

      /**************************************************/
      
      // if (this.userService.couponInfo.promoCode) {
      //   const isInCart = this.userService.couponInfo.Allcoupons.some((code) =>  {
      //     if (code == this.userService.couponInfo.promoCode) {
      //       return true;
      //     }
      //     return false;
      //   });
      //   if (!isInCart) {
      //     this.userService.couponInfo.Allcoupons.push(this.userService.couponInfo.promoCode);
      //   }
      // }
      // const token = null; // cookieService.getAuthToken();
      // const guestLogin = window.localStorage.getItem('guestLogin') == 'true' ? true : false;
      // if (token && !guestLogin) {
      //   const selectedorders = JSON.parse(window.localStorage.getItem('cart.order'));
      //   if (!selectedorders || !selectedorders.length) {
      //     this.userService.couponInfo.RewardsForUse = [];
      //     this.userService.couponInfo.promoCode = '';
      //     this.userService.couponInfo.promoCodeValid = true;
      //     this.userService.couponInfo.IsAppliedcode = false;
      //     this.userService.couponInfo.Allcoupons = [];
      //   }
      // }
      const calculateOrderRequest = {
        ShipMethodID: this.userService.selectedShippingMethod,
        PriceType: this.userService.customerTypeID || 1,
        Address1: this.userService.shippingAddress.Street1 || '',
        Address2: this.userService.shippingAddress.Street2 || '',
        Address3: '',
        City: this.userService.shippingAddress.City || '',
        State: this.userService.shippingAddress.StateName
          ? this.userService.shippingAddress.StateName : (this.userService.defaultState || this.userService.regionMainState),
        Zip: this.userService.shippingAddress.PostalCode || this.userService.shippingAddress.PostalCode,
        Country: this.configService.commonData.selectedCountry || sessionStorage.getItem('selectedCountry') || 'us',
        CustomerID: this.accountService.isLoggedIn() ? (this.userService.customerData?.CustomerID || 0) : 0,
        OrderType: this.itemsService.selectedPacks.length > 0 ? 0 : 2,
        Items: productdetails,
        Details: productdetails,
        ReturnShipMethods: true,
        PartyID: 0
      };
      _.each(productdetails, (product) => {
        if (product.ItemCode == 'DiscountCode') {
          calculateOrderRequest['Other17'] = this.userService.WebAlias;
        }
      })

      if (_.isEqual(this.lastRequest, calculateOrderRequest)) {
        resolve(this.calculateOrderResponse);
      } else {
        this.lastRequest = cloneDeep(calculateOrderRequest);
        this.apiService.calculateOrder(calculateOrderRequest).subscribe({
          next: (result) => {
            this.calculateOrderCall = false;
            try {
              if (parseInt(result.Status, 10) === 0) {
                this.calculateOrderResponse = result.Data;
                this.calculateOrderResponse.OriginalSubTotal = 0;
                this.calculateOrderResponse.CurrentSubTotal = 0;
                if (this.calculateOrderResponse.CouponResults) {
                  this.calculateOrderResponse.CouponResults.forEach((item, index) => {
                    const isInCart = this.userService.couponInfo.Allcoupons.some((code) => {
                      if (code.toLowerCase() == item.Code.toLowerCase()) {
                        return true;
                      }
                      return false;
                    });

                    if (item.IsValid) {
                      if (!isInCart) {
                        this.userService.couponInfo.Allcoupons.push(item.Code);
                      }
                      if (this.userService.couponInfo.promoCode == item.Code) {
                        this.notificationService.success('success', 'coupon_added_success');
                      }

                      this.userService.couponInfo.promoCodeValid = true;

                    } else {
                      this.userService.couponInfo.promoCodeValid = false;
                      this.userService.couponInfo.IsAppliedcode = false;
                      this.userService.couponInfo.promoCode = '';
                      if (isInCart && this.itemsService.selectedOrderItems.length) {
                        this.userService.couponInfo.Allcoupons = this.userService.couponInfo.Allcoupons.filter((code) => {
                          return code.toLowerCase() != item.Code.toLowerCase();
                        });
                        this.userService.couponInfo.RewardsForUse = this.userService.couponInfo.RewardsForUse.filter((e) => {
                          return e.Code.toLowerCase() != item.Code.toLowerCase();
                        });

                      }
                    }

                  });
                }
                this.userService.couponInfo.promoCode = '';
                setTimeout(() => {
                  this.userService.shipMethods = result.Data.ShipMethods;

                  if (this.userService.shipMethods && this.userService.shipMethods.length) {
                    const selectedShipmethod = this.userService.shipMethods.filter((shipMethod) => {
                      return (shipMethod.ShipMethodID === this.userService.selectedShippingMethod);

                    });
                    if (selectedShipmethod.length > 0) {
                      this.userService.selectedShippingMethod = this.userService.selectedShippingMethod || selectedShipmethod[0].ShipMethodID;
                      this.userService.selectedShippingMethodName = selectedShipmethod[0].Description;

                    } else {
                      this.userService.selectedShippingMethod = this.userService.shipMethods[0].ShipMethodID;
                      this.userService.selectedShippingMethodName = this.userService.shipMethods[0].Description;

                      // Recalculate Order ( to get new shipping amount )
                      this.calculateOrder();
                    }
                  }
                }, 100);

                // pack total
                if (this.itemsService.selectedPacks.length) {
                  this.packTotal = 0;
                  this.packBusinessVolume = 0;

                  this.itemsService.selectedPacks.forEach(item => {
                    this.calculateOrderResponse.Details.forEach((item1) => {
                      if (item.ItemCode == item1.ItemCode && !item1.ParentItemCode) {
                        this.packTotal = this.packTotal + (item.Quantity * item.Price);
                        console.log('this.packTotal', this.packTotal);
                        this.packBusinessVolume = this.packBusinessVolume + (item.Quantity * (item.BusinessVolume || item.PV));
                        item1.ItemID = item.ItemID;
                        item1.CurrencyCode = item.CurrencyCode;
                      }
                    });
                  });
                  //this.abandonedCart('Pack', this.itemsService.selectedPacks, this.packTotal);
                }
                // order total
                if (this.itemsService.selectedOrderItems.length) {
                  this.orderBusinessVolume = 0;
                  const selectedOrderItems = cloneDeep(this.itemsService.selectedOrderItems);
                  this.itemsService.selectedOrderItems = [];
                  selectedOrderItems.forEach((item) => {
                    this.calculateOrderResponse.Details.forEach((item1) => {
                      if (item.ItemCode == item1.ItemCode && !item1.ParentItemCode) {
                        item.Price = item1.PriceTotal || item1.PriceEach || item.Price;
                        item.PriceEachOverride = item1.PriceTotal || item1.PriceEach || item.Price;
                        item1.BusinessVolumeTotal = this.orderBusinessVolume + (item1.Quantity * (item1.CommissionableVolume || item1.BusinesVolume || 0));
                        this.orderBusinessVolume = this.orderBusinessVolume + (item1.Quantity * (item1.CommissionableVolume || item.BusinesVolume || 0));
                        item1.ItemID = item.ItemID;
                        item1.CurrencyCode = item.CurrencyCode;
                        this.itemsService.selectedOrderItems.push(item);
                      }
                    });
                  });
                  if (this.userService.customerTypeID != 3) {
                    _.each(this.itemsService.selectedOrderItems, (valItem) => {
                      if (valItem.ItemCode != "DiscountCode") {
                        this.calculateOrderResponse.OriginalSubTotal += (valItem.PriceType1Value * valItem.Quantity);
                        this.calculateOrderResponse.CurrentSubTotal += (valItem['PriceType' + this.userService.customerTypeID + 'Value'] * valItem.Quantity);
                      }
                    })
                  }
                }
                if (showNotification) {
                  //this.abandonedCart((this.itemsService.selectedPacks.length > 0 ? 'Pack' : 'Standard'), this.calculateOrderResponse.Details, result.Data.Total);
                  this.notificationService.success('success', this.translate.instant('ItemAdded'));
                }
                resolve(result);
                this.pointsPaymentCheckout.calculateRemainder();
              } else {
                const getMessage = result.Message.toLowerCase().includes('Api returned 404 Not Found'.toLowerCase());
                let getItemName;
                if (getMessage && getMessage !== null) {
                  const getNumber = result.Message;
                  const getItemCode = getNumber.match(new RegExp('Item with Id' + '\\s(\\w+)'))[1]; // getNumber.replace(/\D/g, '');
                  let getItemDetails = this.itemsService.selectedOrderItems;
                  if (this.itemsService.selectedPacks.length > 0) {
                    getItemDetails = getItemDetails.concat(this.itemsService.selectedPacks);
                  }
                  getItemDetails.forEach((item, index) => {
                    if (item.ItemCode == getItemCode) {
                      getItemName = item.ItemDescription;
                    }
                  });

                  this.translate.get('error_item_not_found').subscribe((text: string) => {
                    this.notificationService.error('Error', this.translate.instant('error_item_not_found') + ' ' + getItemName);
                  });

                } else {
                  this.notificationService.error('Error', result.Message);
                }

                reject(result);
              }
            } catch (ex) {

              this.notificationService.error('Error', 'OopsSomethingWentWrong');
              reject(ex);
            }
          }, error: (error) => {
            // this.calculateOrderCall = false;
            reject(error);
          }
        });
      }

    });
    this.changeInOrder.emit()
    return promise;
  }

  /*** calculate Local Order ***/
  public calculateLocalOrder(showNotification?: boolean): any {
    const promise = new Promise((resolve, reject) => {
      const productdetails = [];
      const kititems = [];
      this.itemsService.selectedOrderItems.forEach((item, index) => {
        productdetails.push({
          ItemId: item.ItemCode,
          Quantity: item.Quantity || 1
        });
      });

      this.itemsService.selectedPacks.forEach((item, index) => {
        kititems.push({
          ItemId: item.ItemCode,
          Quantity: item.Quantity || 1
        });
      });
      try {
        // pack total
        if (this.itemsService.selectedPacks.length) {
          this.packTotal = 0;
          this.packBusinessVolume = 0;
          this.itemsService.selectedPacks.forEach(item => {

            this.packTotal = this.packTotal + (item.Quantity * item.Price);
            this.packBusinessVolume = this.packBusinessVolume + (item.Quantity * (item.BusinessVolume || item.PV));
          });
        }
        // order total
        if (this.itemsService.selectedOrderItems.length) {
          this.orderBusinessVolume = 0;
          const selectedOrderItems = cloneDeep(this.itemsService.selectedOrderItems);
          this.itemsService.selectedOrderItems = [];
          selectedOrderItems.forEach((item) => {
            item.BusinessVolumeTotal = this.orderBusinessVolume + (item.Quantity * (item.Cv || item.Qv || 0));
            this.orderBusinessVolume = this.orderBusinessVolume + (item.Quantity * (item.Cv || item.Qv || 0));
            this.itemsService.selectedOrderItems.push(item);
          });
        }
        if (showNotification) {
          this.notificationService.success('success', this.translate.instant('ItemAdded'));
        }
        resolve(this.itemsService.selectedOrderItems);
      }
      catch (ex) {
        reject(this.itemsService.selectedOrderItems);
      }
    });
    return promise;
  }

  /***calculate Auto Order***/

  public calculateAutoOrder(showNotification?: boolean): any {

    const promise = new Promise((resolve, reject) => {
      this.PreventTaxJarCalculation = true;
      const productdetails = [];
      this.itemsService.selectedAutoOrderItems.forEach((item, index) => {
        productdetails.push({
          ItemCode: item.ItemCode,
          Quantity: item.Quantity
        });
      });

      if (!this.accountService.isLoggedIn()) {
        if (this.itemsService.selectedPacks.length == 0 && this.userService.customerTypeID == 3) {
          this.userService.customerTypeID = 2;
        }
        if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length > 0) {
          this.userService.customerTypeID = 2;
        }
        if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0) {
          this.userService.customerTypeID = 1;
        }
        if (this.itemsService.selectedPacks.length > 0) {
          this.userService.customerTypeID = 3;
        }
      }

      /***************************************************/

      if(this.accountService.isLoggedIn()) {

        // Retail Customer
        if(this.userService?.customerData.CustomerType == 1) {
          if (this.itemsService.selectedPacks.length > 0) {
            this.userService.customerTypeID = 3;
          }
          if (this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length > 0) {
            this.userService.customerTypeID = 2;
          }
          if(this.itemsService.selectedPacks.length == 0 && this.itemsService.selectedAutoOrderItems.length == 0) {
            this.userService.customerTypeID = 1;
          }
        }

        // Preffered Customer
        if(this.userService?.customerData.CustomerType == 2) {
          if (this.itemsService.selectedPacks.length > 0) {
            this.userService.customerTypeID = 3;
          } else if (this.itemsService.selectedPacks.length == 0) {
            this.userService.customerTypeID = 2;
          }
        }

      }

      /**************************************************/
      
      if (productdetails.length == 0) {
        return resolve(this.calculateAutoOrderResponse);
      }
      const calculateOrderRequest = {
        ShipMethodID: this.userService.selectedShippingMethod,
        PriceType: this.userService.customerTypeID || 2,
        Address1: this.userService.shippingAddress.Street1 || '',
        Address2: this.userService.shippingAddress.Street2 || '',
        Address3: '',
        City: this.userService.shippingAddress.City || '',
        State: this.userService.shippingAddress.StateName
          ? this.userService.shippingAddress.StateName : (this.userService.defaultState || this.userService.regionMainState),
        Zip: this.userService.shippingAddress.PostalCode || this.userService.shippingAddress.PostalCode,
        Country: this.configService.commonData.selectedCountry || sessionStorage.getItem('selectedCountry') || 'us',
        CustomerID: this.accountService.isLoggedIn() ? (this.userService.customerData?.CustomerID || 0) : 0,
        OrderType: 4,
        Details: productdetails,
        Items: productdetails,
        ReturnShipMethods: true,
        PartyID: 0,
      };

      if (_.isEqual(this.lastAutoOrderRequest, calculateOrderRequest)) {
        resolve(this.calculateAutoOrderResponse);

      } else {

        this.lastAutoOrderRequest = cloneDeep(calculateOrderRequest);
        this.apiService.calculateOrder(calculateOrderRequest).subscribe({
          next: (result) => {
            try {
              if (parseInt(result.Status, 10) === 0) {
                this.calculateAutoOrderResponse = result.Data;
                if (showNotification) {
                  this.notificationService.success('success', this.translate.instant('ItemAdded'));
                }

                this.autoorderBusinessVolume = 0;
                const selectedAutoOrderItems = cloneDeep(this.itemsService.selectedAutoOrderItems);
                this.itemsService.selectedAutoOrderItems = [];
                selectedAutoOrderItems.forEach((autoitem, index) => {
                  this.calculateAutoOrderResponse.Details.forEach((item1) => {
                    if (autoitem.ItemCode == item1.ItemCode && !item1.ParentItemCode) {
                      autoitem.Price = item1.PriceTotal || item1.PriceEach;
                      autoitem.BusinessVolumeTotal = this.autoorderBusinessVolume + (item1.Quantity * (item1.CommissionableVolume || item1.BusinesVolume || 0));
                      this.autoorderBusinessVolume = this.autoorderBusinessVolume + (item1.Quantity * (item1.CommissionableVolume || item1.BusinesVolume || 0));
                      item1.ItemID = autoitem.ItemID;
                      item1.CurrencyCode = autoitem.CurrencyCode;
                    }
                  });
                });
                this.userService.shipMethods = result.Data.ShipMethods;
                if (this.userService.shipMethods && this.userService.shipMethods.length) {
                  const selectedShipmethod = this.userService.shipMethods.filter((shipMethod) => {
                    return (shipMethod.ShipMethodID === this.userService.selectedShippingMethod);
                  });
                  if (selectedShipmethod.length > 0) {
                    this.userService.selectedShippingMethod = this.userService.selectedShippingMethod || selectedShipmethod[0].ShipMethodID;
                  }
                  else {
                    this.userService.selectedShippingMethod = this.userService.selectedShippingMethod ||
                      this.userService.shipMethods[0].ShipMethodID;

                    // Recalculate AutoOrder ( to get new shipping amount )
                    setTimeout(() => {
                      this.calculateAutoOrder();
                    }, 300);
                  }
                }
                this.itemsService.selectedAutoOrderItems = selectedAutoOrderItems;
                //this.abandonedCart('Autoship', this.calculateAutoOrderResponse.Details, result.Data.Total);
                resolve(result);
                this.pointsPaymentCheckout.calculateRemainder();
              } else {
                const getMessage = result.Message.toLowerCase().includes('Api returned 404 Not Found'.toLowerCase());
                let getItemName;
                if (getMessage && getMessage !== null) {
                  const getNumber = result.Message;
                  const getItemCode = getNumber.match(new RegExp('Item with Id' + '\\s(\\w+)'))[1]; // getNumber.replace(/\D/g, '');
                  const getItemDetails = this.itemsService.selectedAutoOrderItems;
                  getItemDetails.forEach((item, index) => {
                    if (item.ItemCode == getItemCode) {
                      getItemName = item.ItemDescription;
                    }
                  });

                  this.translate.get('error_item_not_found').subscribe((text: string) => {
                    this.notificationService.error('Error', this.translate.instant('error_item_not_found') + ' ' + getItemName);
                  });
                } else {
                  this.notificationService.error('Error', result.Message);
                }
                reject(result);
              }
            } catch (ex) {

              this.notificationService.error('Error', 'OopsSomethingWentWrong');
              reject(ex);
            }
          }, error: (error) => {
            reject(error);
          }
        });
      }
    });
    this.changeInOrder.emit()
    return promise;
  }

  /***calculate Local Auto Order***/
  public calculateLocalAutoOrder(showNotification?: boolean): any {

    const promise = new Promise((resolve, reject) => {
      const productdetails = [];
      this.itemsService.selectedAutoOrderItems.forEach((item, index) => {
        productdetails.push({
          ItemId: item.ItemCode,
          Quantity: item.Quantity,
          IsKitItem: false
        });
      });
      try {
        this.autoorderTotal = 0;
        this.autoorderBusinessVolume = 0;
        const selectedAutoOrderItems = cloneDeep(this.itemsService.selectedAutoOrderItems);
        this.itemsService.selectedAutoOrderItems = [];
        selectedAutoOrderItems.forEach((autoitem, index) => {
          autoitem.BusinessVolumeTotal = this.autoorderBusinessVolume + (autoitem.Quantity * (autoitem.Cv || autoitem.Qv || 0));
          this.autoorderBusinessVolume = this.autoorderBusinessVolume + (autoitem.Quantity * (autoitem.Cv || autoitem.Qv || 0));
          this.autoorderTotal = this.autoorderTotal + (autoitem.Quantity * (autoitem.Price || autoitem.PriceEach || 0));
        });
        this.itemsService.selectedAutoOrderItems = selectedAutoOrderItems;
        if (showNotification) {
          this.notificationService.success('success', this.translate.instant('ItemAdded'));
        }
        resolve(this.itemsService.selectedAutoOrderItems);
      } catch (ex) {
        reject(ex);
      }
    });
    return promise;
  }
  /** *Reset last requests
   * @method  resetLastRequests
   * Reset last requests in case of continue shoppings.
   */


  public abandonedCart(type, items, total) {
    try {
      var data: any;
      var cartItems: any = [];
      if (items && items.length > 0) {
        items.forEach(item => {
          cartItems.push({
            "itemId": item.ItemID || 0,
            "productName": item.Description,
            "sku": item.ItemCode || 0,
            "quantity": item.Quantity,
            "qv": item.BusinessVolumeEach,
            "cv": item.CommissionableVolumeEach,
            "bonus": item.Bonus || 0,
            "price": item.PriceEach,
            "languageCode": this.selectedLanguage?.CultureCode || 'en',
            "currencyCode": item.CurrencyCode
          });
        });
      }
      if (Object.keys(this.userService.customerData).length > 0) {
        data = [{
          "type": type,
          "cartItem": cartItems,
          "price": total,
          "enrollerInfo": {
            "customerId": this.userService.enrollerInfo.CustomerID || this.userService.enrollerInfo.CustomerId,
            "backOfficeId": this.userService.enrollerInfo.BackOfficeId,
            "firstName": this.userService.enrollerInfo.FirstName,
            "lastName": this.userService.enrollerInfo.LastName,
            "webAlias": this.userService.enrollerInfo.WebAlias,
            "phoneNumber": this.userService.enrollerInfo.Phone || this.userService.enrollerInfo.PrimaryPhone,
            "emailAddress": this.userService.enrollerInfo.Email || this.userService.enrollerInfo.EmailAddress,
            "country": this.userService.enrollerInfo.Country,
            "region": this.userService.enrollerInfo.State || this.userService.enrollerInfo.Region
          },
          "customerInfo": {
            "customerId": this.userService.customerData.CustomerID ? this.userService.customerData.CustomerID : '',
            "backOfficeId": this.userService.customerData.BackOfficeID ? this.userService.customerData.BackOfficeID : '',
            "firstName": this.userService.customerData.FirstName ? this.userService.customerData.FirstName : '',
            "lastName": this.userService.customerData.LastName ? this.userService.customerData.LastName : '',
            "webAlias": this.userService.customerData.WebAlias ? this.userService.customerData.WebAlias : '',
            "phoneNumber": this.userService.customerData.Phone ? this.userService.customerData.Phone : '',
            "emailAddress": this.userService.customerData.Email ? this.userService.customerData.Email : '',
            "country": this.userService.customerData.DefaultShippingAddress?.CountryCode ? this.userService.customerData.DefaultShippingAddress?.CountryCode : '',
            "region": this.userService.customerData.DefaultShippingAddress?.Region ? this.userService.customerData.DefaultShippingAddress?.Region : this.userService.customerData.DefaultShippingAddress?.Region,
          }
        }]
      }
      else {
        data = [{
          "type": type,
          "cartItem": cartItems,
          "price": total,
          "enrollerInfo": {
            "customerId": this.userService.enrollerInfo.CustomerID || this.userService.enrollerInfo.CustomerId,
            "backOfficeId": this.userService.enrollerInfo.BackOfficeId,
            "firstName": this.userService.enrollerInfo.FirstName,
            "lastName": this.userService.enrollerInfo.LastName,
            "webAlias": this.userService.enrollerInfo.WebAlias,
            "phoneNumber": this.userService.enrollerInfo.Phone || this.userService.enrollerInfo.PrimaryPhone,
            "emailAddress": this.userService.enrollerInfo.Email || this.userService.enrollerInfo.EmailAddress,
            "country": this.userService.enrollerInfo.Country,
            "region": this.userService.enrollerInfo.State || this.userService.enrollerInfo.Region
          },
          "customerInfo": {
          }
        }]
      }
      window['ZTM'].setStats('RainIntl', 'AbandonedCart', JSON.stringify(data));
    } catch (error) {
      console.log('error', error);
    }
  }

  public resetLastRequests(): any {
    this.lastRequest = {};
    this.lastAutoOrderRequest = {};
  }

  public applyDiscount(subtotal) {
    subtotal = subtotal || this.calculateOrderResponse?.SubTotal || 0;
    var selectedCountry = sessionStorage.getItem('selectedCountry');

    /* Returing 0 for Discount Unlisted Countries */
    if(discountUnlistedCountries.find((country: string) => country === selectedCountry.toUpperCase())){
      return 0;
    }

    if (subtotal > 0) {
      let discountval = (subtotal * 10) / 100;
      var DiscountList = DiscountConfig.Discount;
      var limitDiscount = DiscountList.filter((disc) => { return disc.countryCode == selectedCountry?.toUpperCase() })[0];
      if (limitDiscount && limitDiscount['maxDiscount'] && (discountval > parseInt(limitDiscount['maxDiscount'], 10))) {
        discountval = parseInt(limitDiscount['maxDiscount'], 10);
      }
      return discountval;
    }
    return 0;
  }

  waitForTokenExIframeElement(elementID, callback) {
    var poops = setInterval(function () {
      if (document.getElementById(elementID)) {
        clearInterval(poops);
        callback();
      }
    }, 100);
  }

}

<div class="findenroller-dialog" fxLayout="column">
    <div fxLayout="column" class="ms-padding" fxFlex="100">
        <div fxFlex="100" fxLayoutAlign="space-between center">
            <h4 class="m-0" mat-dialog-title>Find Your Sponsor</h4>
            <mat-icon mat-button mat-dialog-close class="close-icon">close</mat-icon>
        </div>
    </div>
    <hr class="mb-10">
    <div fxFlex="100" class="text-content">
        <p [textContent]="'Search for your referrer by entering their Customer ID, First or Last name.'"></p>
    </div>
    <div fxFlex="100" fxLayout="row" class="side-padding">
        <mat-form-field class="w-100 border-bottom">
            <input matInput [(ngModel)]="searchText">
            <button mat-stroked-button matSuffix mat-icon-button class="btn" (click)="searchEnroller(searchText)">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>